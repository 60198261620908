// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productsNav {
  border-bottom: 1px solid var(--divider, #0000001F);
  color: #5A6874;
  /* padding: 12px; */
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
}
.productsNav ul {
  margin: 0;
  padding: 0;
  /* list-style: none; */
  width: 100%; 
}
.productsNav h3 {
  font-size: 11px;
  color: #000000DE;
  padding: 12px;
  margin: 0;
  font-family: Averta-Regular;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
}
.productsNav li {
  list-style: none;
  padding: 8px 12px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.18px;
  text-align: left;
  width: 100%; 
  box-sizing: border-box; 
}
.productsNav li:hover {
  background-color: #e0e0e0; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}
.heading {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/common/userinfo/ProductsNav/productsNav.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,wCAAwC;AAC1C;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".productsNav {\n  border-bottom: 1px solid var(--divider, #0000001F);\n  color: #5A6874;\n  /* padding: 12px; */\n  font-weight: 600;\n  line-height: 14px;\n  letter-spacing: 1px;\n  text-align: left;\n}\n.productsNav ul {\n  margin: 0;\n  padding: 0;\n  /* list-style: none; */\n  width: 100%; \n}\n.productsNav h3 {\n  font-size: 11px;\n  color: #000000DE;\n  padding: 12px;\n  margin: 0;\n  font-family: Averta-Regular;\n  font-size: 11px;\n  font-weight: 600;\n  line-height: 14px;\n  letter-spacing: 1px;\n  text-align: left;\n}\n.productsNav li {\n  list-style: none;\n  padding: 8px 12px;\n  font-family: Roboto;\n  font-weight: 400;\n  line-height: 20px; \n  letter-spacing: 0.18px;\n  text-align: left;\n  width: 100%; \n  box-sizing: border-box; \n}\n.productsNav li:hover {\n  background-color: #e0e0e0; \n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); \n}\n.heading {\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

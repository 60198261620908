// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: account_authorization_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Text } from "./common/shared_1";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "account_authorization_2";

/** Messages for accounts that require separate authorization process */

/** Account level Logon Request. One-step logon procedure. */
export interface AccountLogon {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** User login name for this account. */
  username: string;
  /** User's password for this account. */
  password: string;
}

/** Account level Logon result. */
export interface AccountLogonResult {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /**
   * One of the logon result codes.
   * This field is associated with ResultCode enum type.
   */
  resultCode: number;
  /** Optional logon result message for failure details. */
  textMessage?:
    | Text
    | undefined;
  /** Time when the message was sent. */
  serverUtcTimestamp: Date | undefined;
}

export enum AccountLogonResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - // success codes
   * User is logged in to the system. Account connection status becomes CONNECTED.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_PASSWORD_CHANGE_REQUIRED - Account password change required after the first logon of new account.
   * Account connection status remains CONNECTING.
   */
  RESULT_CODE_PASSWORD_CHANGE_REQUIRED = 1,
  /** RESULT_CODE_PASSWORD_EXPIRED - Account password expired. Account connection status remains CONNECTING. */
  RESULT_CODE_PASSWORD_EXPIRED = 2,
  /**
   * RESULT_CODE_FAILURE - // failure codes (100+), account connection status is reset to DISCONNECTED.
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function accountLogonResult_ResultCodeFromJSON(object: any): AccountLogonResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return AccountLogonResult_ResultCode.RESULT_CODE_SUCCESS;
    case 1:
    case "RESULT_CODE_PASSWORD_CHANGE_REQUIRED":
      return AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_CHANGE_REQUIRED;
    case 2:
    case "RESULT_CODE_PASSWORD_EXPIRED":
      return AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_EXPIRED;
    case 101:
    case "RESULT_CODE_FAILURE":
      return AccountLogonResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountLogonResult_ResultCode.UNRECOGNIZED;
  }
}

export function accountLogonResult_ResultCodeToJSON(object: AccountLogonResult_ResultCode): string {
  switch (object) {
    case AccountLogonResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_CHANGE_REQUIRED:
      return "RESULT_CODE_PASSWORD_CHANGE_REQUIRED";
    case AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_EXPIRED:
      return "RESULT_CODE_PASSWORD_EXPIRED";
    case AccountLogonResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case AccountLogonResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Account level password change request. */
export interface AccountPasswordChange {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** Currently used password. */
  oldPassword: string;
  /** New password that user wants to use. */
  newPassword: string;
}

/** Account level password change result. */
export interface AccountPasswordChangeResult {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** Optional failure details. */
  textMessage?: Text | undefined;
}

/** Password change result. */
export enum AccountPasswordChangeResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - / success codes
   * User password is changed successfully.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - / failure codes (100+)
   * Most likely incorrect user name or password.
   */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function accountPasswordChangeResult_ResultCodeFromJSON(object: any): AccountPasswordChangeResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return AccountPasswordChangeResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case "RESULT_CODE_FAILURE":
      return AccountPasswordChangeResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountPasswordChangeResult_ResultCode.UNRECOGNIZED;
  }
}

export function accountPasswordChangeResult_ResultCodeToJSON(object: AccountPasswordChangeResult_ResultCode): string {
  switch (object) {
    case AccountPasswordChangeResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case AccountPasswordChangeResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case AccountPasswordChangeResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Account level Logoff request. */
export interface AccountLogoff {
  /** Account ID in CQG trade routing system. */
  accountId: number;
}

/** Account level Logoff result. */
export interface AccountLogoffResult {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** Optional failure description. */
  textMessage?:
    | Text
    | undefined;
  /** Time when the message was sent. */
  serverUtcTimestamp: Date | undefined;
}

/** Logoff result. */
export enum AccountLogoffResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - / success codes
   * Logoff was successful.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - / failure codes (100+)
   * Logoff failed.
   */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function accountLogoffResult_ResultCodeFromJSON(object: any): AccountLogoffResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return AccountLogoffResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case "RESULT_CODE_FAILURE":
      return AccountLogoffResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountLogoffResult_ResultCode.UNRECOGNIZED;
  }
}

export function accountLogoffResult_ResultCodeToJSON(object: AccountLogoffResult_ResultCode): string {
  switch (object) {
    case AccountLogoffResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case AccountLogoffResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case AccountLogoffResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseAccountLogon(): AccountLogon {
  return { accountId: 0, username: "", password: "" };
}

export const AccountLogon = {
  encode(message: AccountLogon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.username !== "") {
      writer.uint32(18).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(26).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogon {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountLogon {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: AccountLogon): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogon>, I>>(base?: I): AccountLogon {
    return AccountLogon.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountLogon>, I>>(object: I): AccountLogon {
    const message = createBaseAccountLogon();
    message.accountId = object.accountId ?? 0;
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseAccountLogonResult(): AccountLogonResult {
  return { accountId: 0, resultCode: 0, textMessage: undefined, serverUtcTimestamp: undefined };
}

export const AccountLogonResult = {
  encode(message: AccountLogonResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      Text.encode(message.textMessage, writer.uint32(26).fork()).ldelim();
    }
    if (message.serverUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.serverUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogonResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogonResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textMessage = Text.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serverUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountLogonResult {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? Text.fromJSON(object.textMessage) : undefined,
      serverUtcTimestamp: isSet(object.serverUtcTimestamp) ? fromJsonTimestamp(object.serverUtcTimestamp) : undefined,
    };
  },

  toJSON(message: AccountLogonResult): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      obj.textMessage = Text.toJSON(message.textMessage);
    }
    if (message.serverUtcTimestamp !== undefined) {
      obj.serverUtcTimestamp = message.serverUtcTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogonResult>, I>>(base?: I): AccountLogonResult {
    return AccountLogonResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountLogonResult>, I>>(object: I): AccountLogonResult {
    const message = createBaseAccountLogonResult();
    message.accountId = object.accountId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.textMessage = (object.textMessage !== undefined && object.textMessage !== null)
      ? Text.fromPartial(object.textMessage)
      : undefined;
    message.serverUtcTimestamp = object.serverUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseAccountPasswordChange(): AccountPasswordChange {
  return { accountId: 0, oldPassword: "", newPassword: "" };
}

export const AccountPasswordChange = {
  encode(message: AccountPasswordChange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.oldPassword !== "") {
      writer.uint32(18).string(message.oldPassword);
    }
    if (message.newPassword !== "") {
      writer.uint32(26).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPasswordChange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPasswordChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.oldPassword = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountPasswordChange {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      oldPassword: isSet(object.oldPassword) ? globalThis.String(object.oldPassword) : "",
      newPassword: isSet(object.newPassword) ? globalThis.String(object.newPassword) : "",
    };
  },

  toJSON(message: AccountPasswordChange): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.oldPassword !== "") {
      obj.oldPassword = message.oldPassword;
    }
    if (message.newPassword !== "") {
      obj.newPassword = message.newPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPasswordChange>, I>>(base?: I): AccountPasswordChange {
    return AccountPasswordChange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountPasswordChange>, I>>(object: I): AccountPasswordChange {
    const message = createBaseAccountPasswordChange();
    message.accountId = object.accountId ?? 0;
    message.oldPassword = object.oldPassword ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

function createBaseAccountPasswordChangeResult(): AccountPasswordChangeResult {
  return { accountId: 0, resultCode: 0, textMessage: undefined };
}

export const AccountPasswordChangeResult = {
  encode(message: AccountPasswordChangeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      Text.encode(message.textMessage, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPasswordChangeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPasswordChangeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textMessage = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountPasswordChangeResult {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? Text.fromJSON(object.textMessage) : undefined,
    };
  },

  toJSON(message: AccountPasswordChangeResult): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      obj.textMessage = Text.toJSON(message.textMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPasswordChangeResult>, I>>(base?: I): AccountPasswordChangeResult {
    return AccountPasswordChangeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountPasswordChangeResult>, I>>(object: I): AccountPasswordChangeResult {
    const message = createBaseAccountPasswordChangeResult();
    message.accountId = object.accountId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.textMessage = (object.textMessage !== undefined && object.textMessage !== null)
      ? Text.fromPartial(object.textMessage)
      : undefined;
    return message;
  },
};

function createBaseAccountLogoff(): AccountLogoff {
  return { accountId: 0 };
}

export const AccountLogoff = {
  encode(message: AccountLogoff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogoff {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogoff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountLogoff {
    return { accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0 };
  },

  toJSON(message: AccountLogoff): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogoff>, I>>(base?: I): AccountLogoff {
    return AccountLogoff.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountLogoff>, I>>(object: I): AccountLogoff {
    const message = createBaseAccountLogoff();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseAccountLogoffResult(): AccountLogoffResult {
  return { accountId: 0, resultCode: 0, textMessage: undefined, serverUtcTimestamp: undefined };
}

export const AccountLogoffResult = {
  encode(message: AccountLogoffResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      Text.encode(message.textMessage, writer.uint32(26).fork()).ldelim();
    }
    if (message.serverUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.serverUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogoffResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogoffResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textMessage = Text.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serverUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountLogoffResult {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? Text.fromJSON(object.textMessage) : undefined,
      serverUtcTimestamp: isSet(object.serverUtcTimestamp) ? fromJsonTimestamp(object.serverUtcTimestamp) : undefined,
    };
  },

  toJSON(message: AccountLogoffResult): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      obj.textMessage = Text.toJSON(message.textMessage);
    }
    if (message.serverUtcTimestamp !== undefined) {
      obj.serverUtcTimestamp = message.serverUtcTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogoffResult>, I>>(base?: I): AccountLogoffResult {
    return AccountLogoffResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountLogoffResult>, I>>(object: I): AccountLogoffResult {
    const message = createBaseAccountLogoffResult();
    message.accountId = object.accountId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.textMessage = (object.textMessage !== undefined && object.textMessage !== null)
      ? Text.fromPartial(object.textMessage)
      : undefined;
    message.serverUtcTimestamp = object.serverUtcTimestamp ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

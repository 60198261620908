import { Instrument } from "../models/Instrument";
import { RealTimeMarketData } from "../proto/market_data_2";
import { ContractMetadata } from "../proto/metadata_2";

/**
 * InstrumentsManager contains
 * - Resolved instruments by resolved contract id.
 * Example: instruments[contractId: number] = Instrument
 */
export class InstrumentsManager {
  private static instruments: { [key: number]: Instrument } = {}; // dictionary<contractId, Instrument>

  static getAllInstruments = (): { [key: string]: Instrument } => {
    return this.instruments;
  };

  static getInstrument = (contractId: number): Instrument | undefined => {
    return this.instruments[contractId];
  };

  static addOrUpdate = (contractMetadata: ContractMetadata): Instrument => {
    let instrument = this.instruments[contractMetadata.contractId];
    if (instrument) {
      instrument.update(contractMetadata);
    } else {
      instrument = new Instrument(contractMetadata);

      if (instrument.contractId) {
        this.instruments[instrument.contractId] = instrument;
      }
    }
    return instrument;
  };

  static processRealTimeMarketData = (realTimeMarketData: RealTimeMarketData[]): Instrument[] => {
    const instruments: Instrument[] = [];
    realTimeMarketData.forEach((dataItem) => {
      const instrument = this.processRealTimeMarketDataItem(dataItem);
      if (instrument) {
        instruments.push(instrument);
      }
    });
    return instruments;
  };

  private static processRealTimeMarketDataItem = (realTimeMarketData: RealTimeMarketData): Instrument | null => {
    const contractId = realTimeMarketData.contractId;
    const instrument = this.instruments[contractId];
    if (!instrument) {
      return null;
    }

    if (realTimeMarketData.marketValues.length > 0) {
      realTimeMarketData.marketValues.forEach((values) => {
        instrument.applyMarketValues(values);
      });
    }

    if (realTimeMarketData.isSnapshot === true) {
      instrument.clearDepth();
    }

    instrument.applyQuotes(realTimeMarketData.quotes);
    instrument.finalizeDepth();
    return instrument;
  };

  static createEmptyInstrumentState = (): Instrument => {
    return new Instrument(null);
  };

  static clear = (): void => {
    this.instruments = {};
  };
}

import { useEffect, useMemo } from "react";
import { useFetchProducts } from "../../../hooks/api/product";
import { GridColDef } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { useOrdersList } from "../../../cqg-api/hooks/ServiceHooks";

const Orders = (props: { workspaceClassName: string; workspaceId: number }) => {
  const { loadProducts } = useFetchProducts();
  const { ordersList } = useOrdersList();

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "symbol",
        headerName: "Symbol",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "side",
        headerName: "Side",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "mo",
        headerName: "Mo",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "strike",
        headerName: "Strike",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: (params) => <span>{params.value === 0 ? "" : params.value}</span>,
      },
      {
        field: "cp",
        headerName: "C/P",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "qty",
        headerName: "Qty",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "leaves",
        headerName: "Leaves",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "fillPX",
        headerName: "Fill PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "limitPX",
        headerName: "Limit PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "stopPX",
        headerName: "Stop PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
    ],
    [],
  );

  const dataRows = useMemo(() => {
    return ordersList.map((order, i) => ({
      id: i,
      side: order.displaySide,
      symbol: order.displayContract,
      mo: order.month,
      strike: order.strikePrice,
      cp: order.putCall,
      qty: order.displaySize,
      leaves: order.displayUnfilledSize,
      type: order.displayType,
      fillPX: order.displayAvgFillPrice,
      limitPX: order.displayLimitPrice,
      stopPX: order.displayStopPrice,
    }));
  }, [ordersList]);

  return (
    <>
      <div className="widget-parent-container">
        <div className="draggableCancelSelector widget-grid-container">
          <DataGridTable columns={columns} rows={dataRows} />
        </div>
      </div>
    </>
  );
};

export default Orders;

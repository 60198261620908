export const privateRoutes = [
  {
    path: "/",
    loader: () => import("../components/home/Home"),
    permissionRequired: null,
    exact: true,
  },
];

export const publicRoutes = [
  {
    path: "/auth/signin",
    loader: () => import("../components/auth/SigninPage"),
  },
];


import { saveCurrentUserToLS } from "../../storage";
import { Account } from "../../types";

const prefix = "AUTH";

const authActions = {
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  doInit: () => async (dispatch: any) => {
    try {
      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload: {
          auth: "",
        },
      });
    } catch (error) {
      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  dispatchLoginSuccess:
    (data: { authToken?: string; id?: string; accounts: Account[]; selectedWorkspace: string; firstName?: string; lastName?: string }) =>
    async (dispatch: any) => {
      const currentUser = {
        accessToken: data.authToken,
        id: data.id,
        accounts: data.accounts,
        selectedWorkspace: data.selectedWorkspace,
        firstName: data.firstName,
        lastName: data.lastName
      };
      saveCurrentUserToLS(currentUser);
      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    },

  dispatchLoginFailure: (error: any) => async (dispatch: any) => {
    dispatch({
      type: authActions.AUTH_ERROR,
      payload: error,
    });
  },

  doSignout: () => async (dispatch: any) => {
    // Reloading the whole application to clear our existing Data, Connections, Events and Static Instances etc.
    window.location.href = "/auth/signin";

    // TODO: Review and remove the following code block
    /* 
    try {
      dispatch({ type: authActions.AUTH_START });
      saveToLS("currentUser", null);
      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
        },
      });
    } catch (error) {
      console.log(error);

      dispatch({
        type: authActions.AUTH_ERROR,
        payload: error,
      });
    } 
    */
  },
};

export default authActions;

import * as TradeRouting from "../proto/trade_routing_2";
import { TradeSubscription } from "../models/TradeSubscription";
import { TradeSubscriptionParams } from "../models/TradeSubscriptionParams";
import { CQGServiceMessageManager } from "../message-managers/ServiceMessageManager";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";

export class TradeSubscriptionsManager {
  subscriptions: { [key: number]: TradeSubscription } = {}; //dictionary<id,tradeSubscription>
  serviceMessageManager: CQGServiceMessageManager;
  serviceMessageEventManager: CQGServiceMessageEventManager;
  constructor(serviceMessageManager: CQGServiceMessageManager, serviceMessageEventManager: CQGServiceMessageEventManager) {
    this.serviceMessageManager = serviceMessageManager;
    this.serviceMessageEventManager= serviceMessageEventManager;

    this.serviceMessageEventManager?.onTradeSubscriptionStatus(this.processTradeSubscriptionStatus);
  }

  subscribe = async (params: TradeSubscriptionParams) => {
    let tradeSubscription = TradeRouting.TradeSubscription.create();
    tradeSubscription.id = params.id;
    tradeSubscription.subscribe = true;
    tradeSubscription.subscriptionScopes = params.subscriptionScope;
    tradeSubscription.publicationType = params.publicationType;
    tradeSubscription.accountIds.push(params.accountId);
    tradeSubscription.skipOrdersSnapshot = params.skipOrdersSnapshot;

    this.serviceMessageManager.sendTradeSubscription([tradeSubscription]);
    this.subscriptions[params.id] = new TradeSubscription(params.id);
  };

  unsubscribe = (id: number) => {
    var subscription = this.subscriptions[id];
    if (!subscription) {
      console.warn("Trade subscription not found for unsubscription: " + id);
      return;
    }

    let tradeSubscription = TradeRouting.TradeSubscription.create();
    tradeSubscription.id = id;
    tradeSubscription.subscribe = false;

    this.serviceMessageManager.sendTradeSubscription([tradeSubscription]);
    delete this.subscriptions[id];
  };

  processTradeSubscriptionStatus = (tradeSubscriptionStatus: TradeRouting.TradeSubscriptionStatus[]) => {
    tradeSubscriptionStatus.forEach((item: TradeRouting.TradeSubscriptionStatus) => {
      this.processTradeSubscriptionStatusItem(item);
    });
  };

  processTradeSubscriptionStatusItem = (tradeSubscriptionStatus: TradeRouting.TradeSubscriptionStatus) => {
    var subscription = this.subscriptions[tradeSubscriptionStatus.id];
    if (!subscription || !subscription.isPending) {
      return;
    }
    if (
      tradeSubscriptionStatus.statusCode === TradeRouting.TradeSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED
    ) {
      console.warn("Disconnected status code was received for trade subscription.");
      return;
    }
    subscription.processTradeSubscriptionStatus(tradeSubscriptionStatus);
  };

  clear = () => {
    this.subscriptions = {};
  };
}

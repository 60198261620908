import { Account } from "../models/Account";
import * as TradeRouting from "../proto/trade_routing_2";
import * as TradingAccount from "../proto/trading_account_2";

export class AccountSummary {
  public account: Account;
  public currency: string;
  public yesterdayBalance: number = 0;
  public yesterdayOte: number = 0;
  public collateral: number = 0;
  public cashExcess: number = 0;
  public currencyRate: number = 0;
  public purchasingPower: number = 0;
  public oteMvo: number = 0;
  public profitLoss: number = 0;
  public currentBalance: number = 0;
  public oteMvoAndPl: number = 0;
  public upl: number = 0;
  public mvo: number = 0;
  public nlv: number = 0;
  public adjustedPurchasingPower: number = 0;
  public initialMargin: number = 0;
  public totalMargin: number = 0;
  public lastUpdateTimestamp: Date | null = null;
  public totalPl: number = 0;

  constructor(account: Account, currency: string) {
    this.account = account;
    this.currency = currency;
  }

  public getAccount() {
    return this.account;
  }

  public updateFromLastStatementBalance(lastStatementBalance: TradingAccount.Balance) {
    this.yesterdayBalance = lastStatementBalance.balance;
    this.yesterdayOte = lastStatementBalance.ote;
    this.collateral = lastStatementBalance.collateral;
    this.cashExcess = lastStatementBalance.cashExcess;
    this.initialMargin = lastStatementBalance.initialMargin;
  }

  public updateFromCollateralStatus(collateralStatus: TradeRouting.CollateralStatus) {
    this.purchasingPower = collateralStatus.purchasingPower;
    this.totalMargin = collateralStatus.totalMargin;
  }
}

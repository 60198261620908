import { useEffect, useMemo } from "react";
import { useFetchProducts } from "../../../hooks/api/product";
import { GridColDef } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { usePositionsList } from "../../../cqg-api/hooks/ServiceHooks";
import { numberNullable } from "../../../cqg-api/types/Types";

const Positions = (props: { workspaceClassName: string; workspaceId: number }) => {
  const { loadProducts } = useFetchProducts();
  const { positionsList } = usePositionsList();

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "symbol",
        headerName: "Symbol",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "contract",
        headerName: "Contract",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "month",
        headerName: "Mo",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "strike",
        headerName: "Strike",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: (params) => <span>{params.value === 0 ? "" : params.value}</span>,
      },
      {
        field: "cp",
        headerName: "C/P",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "position",
        headerName: "Position",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "buys",
        headerName: "Buys",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "sells",
        headerName: "Sells",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "averagePX",
        headerName: "Average PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "unrealizedPL",
        headerName: "Unrealized P/L",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "realizedPL",
        headerName: "Realized P/L",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
    ],
    [],
  );

  const getPosition = (long: numberNullable, short: numberNullable) => {
    if (long) {
      return `Long ${long}`;
    } else if (short) {
      return `Short ${short}`;
    }
  };
  const dataRows: any = positionsList.map((item) => {
    return {
      id: item.contractId,
      trade: item?.instrument?.displayName,
      symbol: item.displaySymbol,
      contract: item?.instrument?.cmeSymbolName,
      month: item.instrument?.month,
      strike: item.instrument?.strikePrice,
      cp: item.instrument?.putCallAbbrev(),
      position: getPosition(item.displayLong, item.displayShort),
      buys: item.buys,
      sells: item.sells,
      averagePX: item.displayPrice,
      unrealizedPL: item.displayOteMvo.toFixed(2),
      realizedPL: item.displayRpl.toFixed(2),
      flatten: "",
    };
  });

  return (
    <>
      <div className="widget-parent-container">
        <div className="draggableCancelSelector widget-grid-container">
          <DataGridTable columns={columns} rows={dataRows} />
        </div>
      </div>
    </>
  );
};

export default Positions;

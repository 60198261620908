import { dateNullable } from "../types/Types";
import { TimeUtil } from "../utils/TimeUtil";
import * as TradingAccount from "../proto/trading_account_2";

export class CurrencyRate {
  currency: string;
  rate!: number;
  statementDate: dateNullable = null;

  constructor(brokerageCurrencyRate: TradingAccount.BrokerageCurrencyRates, currencyRate: TradingAccount.CurrencyRate) {
    this.currency = currencyRate.currency;
    this.update(brokerageCurrencyRate, currencyRate);
  }

  update = (
    brokerageCurrencyRate: TradingAccount.BrokerageCurrencyRates,
    currencyRate: TradingAccount.CurrencyRate,
  ) => {
    this.rate = currencyRate.rate;
    this.statementDate = TimeUtil.toOverchukUtcBasedDate(brokerageCurrencyRate.statementDate);
  };
}

import UserInfo from "../../../common/userinfo";
import ProductsNav from "../../../common/userinfo/ProductsNav";
import ToolsNav from "../../../common/userinfo/Tools";
import { Drawer, Switch } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import "./sidebar.css";

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}

const Sidebar = ({ isOpen, onToggle }: SidebarProps) => {
  return (
    <>
      <nav>
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={onToggle}
          variant="persistent"
          sx={{
            width: isOpen ? 160 : 0,
            transition: "width 0.1s ease",
            "& .MuiDrawer-paper": {
              width: isOpen ? 160 : 0,
              top: 2,
              position: "relative",
              overflow: "hidden",
            },
          }}
        >
          <div className="sidebar-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "12px",
                borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
                alignItems: "center",
                paddingBlock: "5px",
                cursor: "pointer",
              }}
              onClick={onToggle}
            >
              <UserInfo />
              <ChevronLeft />
            </div>
            <ProductsNav />
            <ToolsNav/>
          </div>
        </Drawer>
        <div className="switch-container">
          <Switch className="toggle-switch" />
          <span className="toggle-label">1-Click</span>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;

import { IDockviewHeaderActionsProps } from "dockview";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { useState, useCallback } from "react";
import { Watchlist, Widget, WidgetType, Workspace } from "../../types";
import { addPanels } from "./utils";
import WidgetSelectionDialog from "../widget-selection/WidgetSelectionDialog";
import { useCreateWatchlist } from "../../hooks/api/watchlist";

interface LeftHeaderControlsProps {
  activeWorkspace: Workspace;
  onWidgetsAdded: (widgets: Widget[]) => void;
  props: IDockviewHeaderActionsProps;
}

const LeftHeaderControls = ({ activeWorkspace, onWidgetsAdded, props }: LeftHeaderControlsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showWidgetSelectionDialog, setShowWidgetSelectionDialog] = useState(false);
  const { createWatchlistAsync } = useCreateWatchlist();

  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowWidgetSelectionDialog(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setShowWidgetSelectionDialog(false);
  }, []);

  const handleWidgetsSelection = useCallback(
    (widgets: Widget[]) => {
      const clonedWidgets = JSON.parse(JSON.stringify(widgets));
      const watchlist = clonedWidgets.find((widget: Widget) => widget.WidgetType === WidgetType.Watchlist);

      if (watchlist && activeWorkspace.id) {
        createWatchlistAsync(activeWorkspace.id, (response: Watchlist) => {
          watchlist.widgetId = response.id;
          onWidgetsAdded(clonedWidgets);
          addPanels(activeWorkspace, props.containerApi, clonedWidgets, props.group);
          handleClose();
        });
      } else {
        onWidgetsAdded(clonedWidgets);
        addPanels(activeWorkspace, props.containerApi, clonedWidgets, props.group);
        handleClose();
      }
    },
    [activeWorkspace, createWatchlistAsync, handleClose, onWidgetsAdded, props.containerApi, props.group],
  );

  return (
    <div className="group-control" style={styles.container}>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AddIcon style={styles.icon} />
      </IconButton>
      {showWidgetSelectionDialog && (
        <WidgetSelectionDialog
          title="Select Widgets"
          show={true}
          onOkay={handleWidgetsSelection}
          onCancel={handleClose}
          isShowTitle={false}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    padding: "0px 8px",
    height: "100%",
    color: "var(--dv-activegroup-visiblepanel-tab-color)",
  },
  icon: {
    cursor: "pointer",
    height: "15px",
    width: "15px",
  },
};

export default LeftHeaderControls;

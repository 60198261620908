import { isNullOrUndefined, isNumber, toNumber } from "./lib";
import { CQGConstants } from "../constants/CQGConstants";
import { MathUtil } from "./MathUtil";

type numberOrNull = number | null;
type stringOrNull = string | null;

export class PriceUtil {
  private static intToQuarters: number[] = [0, 0, 0.25, 0.25, 0.5, 0.5, 0.75, 0.75, 0.75, 0.75];
  private static decimalDivisors: number[] = [1, 0.1, 1e-2, 1e-3, 1e-4, 1e-5, 1e-6, 1e-7, 1e-8, 1e-9, 1e-10, 1e-11, 1e-12, 1e-13, 1e-14, 1e-15];
  private static fractionalWidth: number[] = [0, 1, 1, 1, 2, 2, 2, 3, 3, 0, 0, 0, 3, 0, 3, 3];
  private static halvesRounded: number[] = [0, 0, 0, 5, 5, 5, 5, 5, 10, 10];
  private static quartersRounded: number[] = [0, 0, 2, 2, 5, 5, 5, 7, 7, 10];
  private static fractionalMultipliers: number[] = [1, 2, 4, 8, 16, 32, 64, 128, 256, 1, 1, 1, 64, 1, 32, 32];
  private static eightsConverted: number[] = [0, 1, 2, 4, 5, 6, 7, 9];

  static padPriceToTickSize = (price: numberOrNull, tickSize: numberOrNull): stringOrNull => {
    if (price == null || tickSize == null) {
      return null;
    }

    if (tickSize >= 1) {
      return price.toString();
    }

    const decimals = tickSize.toString().match(/\.(\d+)/)?.[1];
    return price.toFixed(decimals ? decimals.length : 0);
  };

  // TODO Unit tests w/ all cases are required for this method.
  static toDisplayPrice = (price: numberOrNull, scale: numberOrNull, tickSize: numberOrNull): numberOrNull => {
    if (price == null || scale == null || tickSize == null) {
      return null;
    }

    if (scale > 15) {
      // fractional
      if (scale === 109 || scale === 110 || scale === 203 || scale === 205) {
        // scales are never used
        return null;
      }

      return this.toDisplayFractionalPrice(price, this.getFractionalScale(scale));
    } else {
      return this.toDisplayDecimalPrice(price, this.getDecimalScale(scale), tickSize);
    }
  };

  static fromDisplayPrice = (displayPrice: stringOrNull, scale: numberOrNull, tickSize: numberOrNull): numberOrNull => {
    if (displayPrice == null || scale == null || tickSize == null) {
      return null;
    }
    displayPrice = displayPrice.toString();
    // check if integer
    if (!isNumber(displayPrice)) {
      return null;
    }

    if (
      scale < 15 &&
      this.isMinTickSizeHalvesOfPriceScale(tickSize, this.decimalDivisors[scale]) &&
      (displayPrice.lastIndexOf("2") === displayPrice.length - 1 || displayPrice.lastIndexOf("7") === displayPrice.length - 1)
    ) {
      displayPrice += "5";
      ++scale;
    }
    let price: number;
    if (scale > 15) {
      // fractional
      if (scale === 109 || scale === 110 || scale === 203 || scale === 205) {
        // scales are never used
        return null;
      }
      price = this.toScaledFractionalPrice(displayPrice, this.getFractionalScale(scale)) as number;
    } else {
      price = this.toScaledDecimalPrice(displayPrice, this.getDecimalScale(scale));
    }
    // check that the value can be represented in MinTicks
    return MathUtil.areEqualFloat(Math.round(price / tickSize) * tickSize, price) ? price : null;
  };

  static changePriceByTicks = (price: numberOrNull, tickSize: numberOrNull, tickCount: numberOrNull): numberOrNull => {
    /// <summary>Changes price depending on a number of ticks.</summary>
    /// <param name="price" type="Number">Correct price.</param>
    /// <param name="tickSize" type="Number">Instrument tick size.</param>
    /// <param name="tickCount" type="Number">Number of ticks for change price.
    /// If tickCount is a positive, price will increase, otherwise decrease.</param>
    /// <returns type="Number">Changed corect price.</returns>
    if (price == null || !tickSize || !tickCount) {
      return price;
    }

    const resultPrice = price + tickSize * tickCount;
    if (resultPrice <= CQGConstants.Price.MAX_PRICE && resultPrice >= CQGConstants.Price.MIN_PRICE) {
      return resultPrice;
    }
    return price;
  };

  static toScaledDecimalPrice = (displayPrice: string, decimalScale: number): number => {
    const divisor = this.decimalDivisors[decimalScale];
    const displayPriceNumber = parseInt(displayPrice);
    const price = displayPriceNumber * divisor;
    return price;
  };

  static toScaledFractionalPrice = (displayPrice: string, fractionalScale: number): numberOrNull => {
    let negativePrice = false;
    if (displayPrice.indexOf("-") === 0) {
      displayPrice = displayPrice.substr(1);
      negativePrice = true;
    }
    const denominatorWidth = this.fractionalWidth[fractionalScale];
    const denominator = this.fractionalMultipliers[fractionalScale];
    let numerator: number;
    if (denominatorWidth === 0) {
      numerator = 0;
    } else if (displayPrice.length <= denominatorWidth) {
      numerator = parseInt(displayPrice);
    } else {
      numerator = parseInt(displayPrice.substr(displayPrice.length - denominatorWidth));
    }
    let integralPart = 0;
    if (displayPrice.length > denominatorWidth) {
      integralPart = parseInt(displayPrice.substr(0, displayPrice.length - denominatorWidth));
    }
    if (this.isInvalidNumberOfTicks(numerator / 10, fractionalScale)) {
      return null;
    }
    let price: number;
    switch (fractionalScale) {
      case 1:
        price = this.lastDigitToHalves(numerator);
        break;
      case 2:
        price = this.lastDigitToQuarters(numerator);
        break;
      case 12:
      case 15:
        price = (this.lastDigitToHalves(numerator % 10) + this.truncate(numerator / 10)) / denominator;
        break;
      case 14:
        price = (this.lastDigitToQuarters(numerator % 10) + this.truncate(numerator / 10)) / denominator;
        break;
      default:
        price = numerator / denominator;
        break;
    }
    price += integralPart;
    if (negativePrice) {
      price = -price;
    }
    return price;
  };

  static isInvalidNumberOfTicks = (fractionalSize: number, fractionalScale: number): boolean => {
    return ((fractionalScale === 14 || fractionalScale === 15) && fractionalSize / 32 >= 1) || (fractionalScale === 12 && fractionalSize / 64 >= 1);
  };

  static lastDigitToHalves = (lastDigit: number): number => {
    return lastDigit < 5 ? 0 : 0.5;
  };

  static lastDigitToQuarters = (lastDigit: number): number => {
    return this.intToQuarters[lastDigit];
  };

  static getFractionalScale = (displayScale: number): number => {
    // TODO: Shall be corrected in accordance with a real server.
    if (displayScale < 200) {
      return displayScale - 100;
    } else {
      return 216 - displayScale;
    }
  };

  static getDecimalScale = (displayScale: number): number => {
    // TODO: Shall be corrected in accordance with a real server.
    return displayScale;
  };

  static toDisplayDecimalPrice = (price: number, decimalDisplayScale: number, tickSize: number): number => {
    const priceScaleValue = this.decimalDivisors[decimalDisplayScale];
    let displayPriceRaw = price / priceScaleValue;
    if (tickSize && this.isMinTickSizeHalvesOfPriceScale(priceScaleValue, tickSize)) {
      tickSize /= priceScaleValue;
      displayPriceRaw = this.truncate(this.roundDecimalPrice(displayPriceRaw / tickSize) * tickSize);
    }
    const result = this.roundDecimalPrice(displayPriceRaw);
    return result;
  };

  static sign = (x: number): number => {
    return x ? (x < 0 ? -1 : 1) : 0;
  };

  static isMinTickSizeHalvesOfPriceScale = (priceScaleValue: number, tickSize: number): boolean => {
    const scaleFactor = tickSize / priceScaleValue;
    return scaleFactor - Math.floor(scaleFactor) === 0.5;
  };

  static roundDecimalPrice = (price: number): number => {
    return this.sign(price) * Math.floor(Math.abs(price) + 0.499999);
  };

  static toDisplayFractionalPrice = (price: number, fractionalDisplayScale: number): number => {
    const absolutePrice = Math.abs(price);

    const integral = Math.floor(absolutePrice);

    const remainder = absolutePrice - integral;

    let fractionalPrice;
    switch (fractionalDisplayScale) {
      case 1:
      case 2:
        fractionalPrice = this.getFractionalPartForHalvesOrQuarters(integral, remainder, fractionalDisplayScale);
        break;
      case 12:
      case 14:
      case 15:
        fractionalPrice = this.getFractionalPartForHalvesOrQuartersOfParts(integral, remainder, fractionalDisplayScale);
        break;
      default:
        fractionalPrice = this.getFractionalPartForHalvesOrQuartersOfParts2(integral, remainder, fractionalDisplayScale);
        break;
    }

    let absoluteValue;
    if (fractionalPrice.integral !== 0) {
      absoluteValue =
        fractionalDisplayScale === 0 ? fractionalPrice.integral : fractionalPrice.integral / this.decimalDivisors[this.fractionalWidth[fractionalDisplayScale]] + fractionalPrice.fractional;
    } else {
      absoluteValue = fractionalPrice.fractional;
    }

    const result = this.sign(price) * absoluteValue;
    return result;
  };

  static getFractionalPartForHalvesOrQuarters = (integral: number, remainder: number, fractionalDisplayScale: number): { integral: number; fractional: number } => {
    let numerator = this.roundDecimalPrice(remainder * 10);
    const isHalves = fractionalDisplayScale === 1;
    numerator = isHalves ? this.halvesRounded[numerator] : this.quartersRounded[numerator];
    const result = this.checkOverflow(integral, numerator, 10);
    return result;
  };

  static getFractionalPartForHalvesOrQuartersOfParts = (integral: number, remainder: number, fractionalDisplayScale: number): { integral: number; fractional: number } => {
    const denominator = this.fractionalMultipliers[fractionalDisplayScale];
    let normalizedRemainder = remainder * denominator;

    let secondRemainder = normalizedRemainder - this.truncate(normalizedRemainder);
    secondRemainder = this.truncate(8 * secondRemainder + 0.499999);
    if (secondRemainder >= 8) {
      secondRemainder = 0;
      normalizedRemainder++;
    }

    const intFracValue = this.truncate(normalizedRemainder);
    const result = this.checkOverflow(integral, intFracValue, denominator);
    result.fractional = result.fractional * 10 + this.eightsConverted[this.truncate(secondRemainder)];
    return result;
  };

  static getFractionalPartForHalvesOrQuartersOfParts2 = (integral: number, remainder: number, fractionalDisplayScale: number): { integral: number; fractional: number } => {
    const denominator = this.fractionalMultipliers[fractionalDisplayScale];
    const normalizedRemainder = remainder * denominator;
    const intFracValue = this.roundDecimalPrice(normalizedRemainder);
    const result = this.checkOverflow(integral, intFracValue, denominator);
    return result;
  };

  static checkOverflow = (integral: number, fractional: number, numberOfParts: number): { integral: number; fractional: number } => {
    while (fractional >= numberOfParts) {
      fractional -= numberOfParts;
      integral++;
    }
    const result = {
      integral: integral,
      fractional: fractional,
    };
    return result;
  };

  static truncate = (value: number): number => {
    return value >= 0 ? Math.floor(value) : Math.ceil(value);
  };

  static isFractional = (scale: number): boolean => {
    return scale > 200;
  };

  static getFractionalBy = (displayScale: number): number => {
    if (displayScale > 200) {
      return 32;
    } else {
      return 1;
    }
  };

  /*
      Removes any exponential decimal and gives a nice looking decimal number string
  
      Examples:
      5e-7  becomes "0.0000005"
      23    becomes "23"
      0.005 becomes "0.005"
      0.050 becomes "0.05"
      */
  static toPrettyDecimalString = (decimalValue: number): string => {
    return parseFloat(decimalValue + "")
      .toFixed(7)
      .replace(/0+$/, "")
      .replace(/\.$/, "");
  };

  // Wrapper to give UT access to the above function
  static toPrettyDecimalString_1 = (decimalValue: number): string => {
    return this.toPrettyDecimalString(decimalValue);
  };

  static getNumerator = (decimalValue: number): number => {
    return parseInt(this.toPrettyDecimalString(decimalValue).toString().replace(".", ""), 10);
  };

  static getDecimalPlaces = (decimalValue: number): number => {
    if (isNullOrUndefined(decimalValue) || decimalValue === Math.floor(decimalValue)) {
      return 0;
    } else {
      const splitted = this.toPrettyDecimalString(decimalValue).split(".");

      if (splitted.length === 2) {
        return splitted[1].length;
      } else {
        return 0;
      }
    }
  };

  static getDecimalMultiplier = (tickSize: number): number => {
    return Math.pow(10, this.getDecimalPlaces(tickSize));
  };

  static getTickSize = (displayScale: number, rawTickSize: number): number => {
    if (this.isFractional(displayScale)) {
      return rawTickSize / (100 / this.getFractionalBy(displayScale));
    } else {
      return rawTickSize;
    }
  };

  static getRawTickSize = (displayScale: number, tickSize: number): number => {
    if (this.isFractional(displayScale)) {
      return ((this.getNumerator(tickSize) / this.getFractionalBy(displayScale)) * 100) / this.getDecimalMultiplier(tickSize);
    } else {
      return tickSize;
    }
  };

  static correctPrecision = (value: number, scale: number): number | null => {
    if (isNullOrUndefined(value)) {
      return value;
    } else {
      return parseFloat(value.toFixed(this.getDecimalPlaces(scale)));
    }
  };

  static invalid = (param: stringOrNull | numberOrNull): boolean => {
    return isNullOrUndefined(param);
  };

  static rawDecimalsToPrice = (rawPrice: number, displayScale: number, tickSize: number, returnPriceObject: boolean = false): number  | null => {
    if (this.invalid(rawPrice) || this.invalid(displayScale) || this.invalid(tickSize)) {
      return null;
    }

    let sign = 1;

    if (rawPrice < 0) {
      sign *= -1;
    }

    returnPriceObject = returnPriceObject === true;
    let converted = Math.abs(rawPrice);
    let fractional = 0;

    if (this.isFractional(displayScale)) {
      const rawTickSize = this.getRawTickSize(displayScale, tickSize);
      const intPart = Math.floor(converted);
      const decimalPart = converted - intPart;
      const decimalTicks = Math.round(decimalPart / rawTickSize); // round to nearest tick
      fractional = 1 / rawTickSize;

      converted = intPart + decimalTicks * tickSize;
    }

    let result: number = sign * converted;
    result = this.correctPrecision(result, tickSize) as number;
    // const before = result;

    if (fractional && fractional > 32) {
      result = toNumber(this.truncateDecimals(result, 3));
    }

    // if (returnPriceObject) {
    //   return { displayPrice: result, price: before };
    // } else {
    //   return result;
    // }

    return result;
  };

  static truncateDecimals = (value: number, decimalPlaces: number): string => {
    const intPart = Math.floor(value);
    const dot = value.toString().indexOf(".");
    if (dot === -1) {
      return intPart + "." + Math.pow(10, decimalPlaces);
    } else {
      return intPart + value.toString().substr(dot, decimalPlaces + 1);
    }
  };

  static priceToRawDecimals = (price: number, displayScale: number, tickSize: number): numberOrNull => {
    if (this.invalid(price) || this.invalid(displayScale) || this.invalid(tickSize)) {
      return null;
    }

    let sign = 1;

    if (price < 0) {
      // this price conversion can be used for price info like net_change, which can be negative
      sign *= -1;
    }

    let converted = Math.abs(price);
    let rawTickSize = tickSize;

    if (this.isFractional(displayScale)) {
      const fractional = this.getFractionalBy(displayScale);
      const intPart = Math.floor(converted);
      const decimalPart = converted - intPart;
      const decimalTicks = Math.round(decimalPart / tickSize);

      rawTickSize = this.getRawTickSize(displayScale, tickSize);
      converted = intPart + decimalTicks * rawTickSize;
    }

    return this.correctPrecision(sign * converted, rawTickSize);
  };

  static stepPrice = (price: number, displayScale: number, tickSize: number, step: number): number => {
    let ticksPerPoint = 0;

    if (this.isFractional(displayScale)) {
      const rawTickSize = this.getRawTickSize(displayScale, tickSize);
      ticksPerPoint = 1 / rawTickSize;
    } else {
      ticksPerPoint = 1 / tickSize;
    }

    return parseFloat(this.convertTicksToPrice(this.convertPriceToTicks(price, ticksPerPoint, tickSize) + step, ticksPerPoint, tickSize).toFixed(this.getDecimalPlaces(tickSize)));
  };

  static rawStepPrice = (price: number, displayScale: number, tickSize: number, step: number): number => {
    const ticksPerPoint = 1 / tickSize;
    return parseFloat(this.convertTicksToPrice(this.convertPriceToTicks(price, ticksPerPoint, tickSize) + step, ticksPerPoint, tickSize).toFixed(this.getDecimalPlaces(tickSize)));
  };

  //
  // tickSize = RawTickSize when price is raw. Fractional tickSize when price is on fractions (1.30, 1.31, 2.0....)
  //
  static convertPriceToTicks = (price: number, ticksPerPoint: number, tickSize: number): number => {
    const sign = price < 0 ? -1 : 1;
    const converted = Math.abs(price);
    const intPart = Math.floor(converted);
    const decimalPart = converted - intPart;
    const decimalTicks = Math.round(decimalPart / tickSize); // round to nearest tick

    return sign * (intPart * ticksPerPoint + decimalTicks);
  };

  //
  // tickSize = RawTickSize when need RawPrice back. Fractional tickSize when fractional price is needed
  //
  static convertTicksToPrice = (ticks: number, ticksPerPoint: number, tickSize: number): number => {
    const sign = ticks < 0 ? -1 : 1;
    const converted = Math.abs(ticks);
    return sign * (Math.floor(converted / ticksPerPoint) + (converted % ticksPerPoint) * tickSize);
  };
}

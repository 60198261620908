import { CQGEnvironment } from "../services/CQGEnvironment";
import { isNumber, toNumber } from "./lib";

export class TimeUtil {
  private static baseTime: number = 0;
  private static baseTimeStr: string | undefined;

  public static getBaseTime = () => {
    var newBaseTimeStr = CQGEnvironment.Instance.baseTimeStr;
    if (!newBaseTimeStr || newBaseTimeStr === this.baseTimeStr) {
      return this.baseTime;
    }
    this.baseTimeStr = newBaseTimeStr;
    try {
      if (!this.baseTimeStr?.match(/Z$/)) {
        this.baseTimeStr = this.baseTimeStr + "Z";
      }
      this.baseTime = Date.parse(this.baseTimeStr);
    } catch (ex) {
      console.error("Failed to parse server base time: %s. %o", this.baseTimeStr, ex);
    }
    return this.baseTime;
  };

  public static toUtcDate = (milliseconds: number) => {
    if (!isNumber(milliseconds)) {
      milliseconds = toNumber(milliseconds);
    }

    var time = this.getBaseTime() + milliseconds;
    return new Date(time);
  };

  public static changeKindToUtc = (time: any) => {
    var utcTime = new Date(time);
    utcTime.setMinutes(utcTime.getMinutes() - utcTime.getTimezoneOffset());
    return utcTime;
  };

  public static toOverchukUtcBasedDate = (milliseconds: number) => {
    /// <signature>
    /// <summary>Converts time represented as millisconds (base on some base time) utc time taking
    /// into account client timezone offset to make formmatted display string look as original time.
    /// Kludge forced by System Architect Taras Overchuk. </summary>
    /// <param name="milliseconds" type="Number">Number of milliseconds from some base time</param>
    /// <returns type="Date" />
    /// </signature>
    var res = this.toUtcDate(milliseconds);
    res.setMinutes(res.getMinutes() + res.getTimezoneOffset());
    return res;
  };

  public static toBaseTimeMsOffset = (date: Date) => {
    return date.getTime() - this.getBaseTime();
  };

  public static toAppTimestampFormat = (date: Date) => {
    if (!(date instanceof Date)) {
      return "";
    }

    // Format `h:mm:ss a z M/D/YY`
    return date;

    // return moment(date).tz("America/Chicago").format("h:mm:ss a z M/D/YY");
  };
}

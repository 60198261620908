import axios from "axios";
import { useCallback, useState } from "react";
import { User } from "../../types";
import Notification from "../../components/shared/notification/Notification";
import { useSelector } from "react-redux";
import authSelectors from "../../redux/auth/authSelector";
import { getCurrentUserFromLS } from "../../storage";

export const useAuthenticateUser = () => {
  const [error, setError] = useState<Error>();
  const [authenticateUserLoaded, setAuthenticateUserLoaded] = useState(false);

  const authenticateUser = useCallback(
    async (login: string, password: string, onSuccess?: (response: User) => void, onFail?: () => void) => {
      try {
        setAuthenticateUserLoaded(true);
        const response = await axios.post("users/sign-in", {
          cms_user: {
            login: login,
            password: password,
          },
        });
        if (response?.data.authToken) {
          onSuccess?.(response.data);
        } else {
          Notification.error("username or password is not correct.");
        }
      } catch (error: any) {
        console.error(error);
        Notification.error(`Authentication failed, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setAuthenticateUserLoaded(false);
      }
    },
    [],
  );

  return { authenticateUser, error, authenticateUserLoaded };
};

export const useGetCurrentUser = () => {
  return useSelector(authSelectors.currentUser) || getCurrentUserFromLS();
};

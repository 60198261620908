import { InstrumentSubscription } from "./InstrumentSubscription";
import * as MarketData from "../proto/market_data_2";

export class InstrumentSubscriptions {
  private static instance: InstrumentSubscriptions;
  constructor() {
    if (InstrumentSubscriptions.instance) {
      return InstrumentSubscriptions.instance;
    }
    InstrumentSubscriptions.instance = this;
  }

  static get Instance() {
    return InstrumentSubscriptions.instance || new InstrumentSubscriptions();
  }

  subscriptions: { [key: string]: InstrumentSubscription } = {}; //dictionary<contractId,instrumentSubscription>

  add = (contractId: number, level: number) => {
    var subscription = new InstrumentSubscription(this, contractId, level);
    subscription.addConsumer(level);

    this.subscriptions[contractId] = subscription;
  };

  remove = (contractId: number) => {
    var subscription = this.subscriptions[contractId];
    if (subscription) {
      delete this.subscriptions[contractId];
    }
  };

  changeLevel(subscription: InstrumentSubscription, oldLevel: number, newLevel: number) {
    if (newLevel !== oldLevel) {
      subscription.isPending = true;
    }
  }

  getByContractId = (contractId: number) => {
    return this.subscriptions[contractId];
  };

  processMarketDataSubscriptionStatus = (marketDataSubscriptionStatus: MarketData.MarketDataSubscriptionStatus[]) => {
    marketDataSubscriptionStatus.forEach((item) => {
      this.processMarketDataSubscriptionStatusItem(item);
    });
  };

  processMarketDataSubscriptionStatusItem = (marketDataSubscriptionStatus: MarketData.MarketDataSubscriptionStatus) => {
    var subscription = this.subscriptions[marketDataSubscriptionStatus.contractId];
    if (!subscription || !subscription.isPending) {
      return;
    }
    if (
      marketDataSubscriptionStatus.statusCode ===
      MarketData.MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED
    ) {
      console.warn("Disconnected status code was received for instrument subscription.");
      return;
    }
    subscription.processMarketDataSubscriptionStatus(marketDataSubscriptionStatus);
  };

  clear = () => {
    this.subscriptions = {};
  };
}

const prefix = "PRODUCT";

export const productsActions = {
  PRODUCT_GET_SUCCESS: `${prefix}_GET_SUCCESS`,
  PRODUCT_SET_SUCCESS: `${prefix}_SET_SUCCESS`,
  
  dispatchSetProduct:
  (data) =>
  async (dispatch) => {
    dispatch({
      type: productsActions.PRODUCT_SET_SUCCESS,
      payload: data,
    });
  },

};

export default productsActions;


export const WSErrorCodes: { [key: number]: { msg: string; help: string } } = {};
WSErrorCodes[1000] = {
  msg: "Normal Closure",
  help: "The connection successfully completed the purpose for which it was created.",
};
WSErrorCodes[1001] = {
  msg: "Going Away",
  help: "The endpoint is going away, either because of a server failure or because the browser is navigating away from the page that opened the connection.",
};
WSErrorCodes[1002] = {
  msg: "Protocol error",
  help: "The endpoint is terminating the connection due to a protocol error.",
};
WSErrorCodes[1003] = {
  msg: "Unsupported Data",
  help: "The connection is being terminated because the endpoint received data of a type it cannot accept. (For example, a text-only endpoint received binary data.)",
};
WSErrorCodes[1004] = { msg: "Reserved", help: "Reserved. A meaning might be defined in the future." };
WSErrorCodes[1005] = {
  msg: "No Status Rcvd",
  help: "Reserved. Indicates that no status code was provided even though one was expected.",
};
WSErrorCodes[1006] = {
  msg: "Abnormal Closure",
  help: "Reserved. Indicates that a connection was closed abnormally (that is, with no close frame being sent) when a status code is expected.",
};
WSErrorCodes[1007] = {
  msg: "Invalid frame payload data",
  help: "The endpoint is terminating the connection because a message was received that contained inconsistent data (e.g., non-UTF-8 data within a text message).",
};
WSErrorCodes[1008] = {
  msg: "Policy Violation",
  help: "The endpoint is terminating the connection because it received a message that violates its policy. This is a generic status code, used when codes 1003 and 1009 are not suitable.",
};
WSErrorCodes[1009] = {
  msg: "Message Too Big",
  help: "The endpoint is terminating the connection because a data frame was received that is too large.",
};
WSErrorCodes[1010] = {
  msg: "Mandatory Ext.",
  help: "The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn't.",
};
WSErrorCodes[1011] = {
  msg: "Internal Error",
  help: "The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.",
};
WSErrorCodes[1012] = {
  msg: "Service Restart",
  help: "The server is terminating the connection because it is restarting.",
};
WSErrorCodes[1013] = {
  msg: "Try Again Later",
  help: "The server is terminating the connection due to a temporary condition, e.g. it is overloaded and is casting off some of its clients.",
};
WSErrorCodes[1014] = {
  msg: "Bad Gateway",
  help: "The server was acting as a gateway or proxy and received an invalid response from the upstream server. This is similar to 502 HTTP Status Code.",
};
WSErrorCodes[1015] = {
  msg: "TLS handshake",
  help: "Reserved. Indicates that the connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).",
};
// 1016–2999 		For definition by future revisions of the WebSocket Protocol specification, and for definition by extension specifications.
// 3000–3999 		For use by libraries, frameworks, and applications. These status codes are registered directly with IANA. The interpretation of these codes is undefined by the WebSocket protocol.
// 4000–4999 		For private use, and thus can't be registered. Such codes can be used by prior agreements between WebSocket applications. The interpretation of these codes is undefined by the WebSocket protocol.

import { CQGEnvironment } from "../services/CQGEnvironment";
import { AccountsLoadedWatcher } from "./AccountsLoadedWatcher";
import { ServiceReadyWatcher } from "./ServiceReadyWatcher";

/**
 * Represents a service watcher for various states.
 * And allows for hooking code if some action needs to be triggered when a state occurs.
 */
export class ServiceWatcher {
  serviceReadyObserver: ServiceReadyWatcher;
  accountsLoadedObserver: AccountsLoadedWatcher;

  constructor(cqgEnvironment: CQGEnvironment) {
    this.serviceReadyObserver = new ServiceReadyWatcher(cqgEnvironment);
    this.accountsLoadedObserver = new AccountsLoadedWatcher(cqgEnvironment);
  }
}

import { useCallback, useState } from "react";
import "dockview/dist/styles/dockview.css";
import Dockview from "../../../dockview/Dockview";
import { Widget, Workspace } from "../../../../types";
import "./Dashboard.css";
import WorkspaceTabs from "./WorkspaceTabs";
import { Button } from "@mui/material";

export type DashboardProps = {
  activeWorkspace?: Workspace;
  workspaceLoading: boolean;
  workSpaces: Workspace[];
  onActiveWorkSpaceChange: (workspace: Workspace) => void;
  onNewWorkSpaceCreated: (workspace: Workspace, widgets: Widget[]) => void;
  onDeleteWorkspace: (workspace: Workspace) => Promise<void>;
  deleteWorkspaceLoading: boolean;
  onNewWorkspaceRequested: () => void
  onSetDefaultWorkspace: (workspace: Workspace) => void;
  handleRenameWorkspace: (workspace: Workspace, newTitle: string) => Promise<void>;
  updateWorkspaceLoaded: boolean;
  onWorkSpaceUpdated:(workspace: Workspace) => void;
  onDuplicateWorkspace: (workspace: Workspace) => void;
  isSidebarOpen: boolean;
  onSidebarToggle: () => void; 
};

const Dashboard = ({
  activeWorkspace,
  workspaceLoading,
  workSpaces,
  onActiveWorkSpaceChange,
  onNewWorkSpaceCreated,
  onDeleteWorkspace,
  deleteWorkspaceLoading,
  onNewWorkspaceRequested,
  onSetDefaultWorkspace,
  handleRenameWorkspace,
  updateWorkspaceLoaded,
  onWorkSpaceUpdated,
  onDuplicateWorkspace,
  isSidebarOpen,
  onSidebarToggle
}: DashboardProps) => {
  const [newworkspaceWidgets, setNewworkspaceWidgets] = useState<Widget[]>([]);
  const [newWorkspace, setNewWorkspace] = useState(false);
  const [showWidgetSelectionDialog, setShowWidgetSelectionDialog] = useState(false);

  const handleNewWorkspaceCreated = useCallback(
    (workspace: Workspace, widgets: Widget[]) => {
      setNewworkspaceWidgets(widgets);
      onNewWorkSpaceCreated(workspace, widgets);
    },
    [onNewWorkSpaceCreated],
  );

  const onAddWidgetClick = () => {
    setShowWidgetSelectionDialog(true);
  };

  return (
    <>
      <div className="TabsLayout">
        <WorkspaceTabs
          workSpaces={workSpaces}
          activeWorkspace={activeWorkspace}
          onActiveWorkSpaceChange={onActiveWorkSpaceChange}
          onDeleteWorkspace={onDeleteWorkspace}
          deleteWorkspaceLoading={deleteWorkspaceLoading}
          handleNewWorkspaceCreated={handleNewWorkspaceCreated}
          newWorkspace={newWorkspace}
          setNewWorkspace={setNewWorkspace}
          showWidgetSelectionDialog={showWidgetSelectionDialog}
          setShowWidgetSelectionDialog={setShowWidgetSelectionDialog}
          onNewWorkspaceRequested={onNewWorkspaceRequested}
          onSetDefaultWorkspace={onSetDefaultWorkspace}
          handleRenameWorkspace={handleRenameWorkspace}
          updateWorkspaceLoaded={updateWorkspaceLoaded}
          onDuplicateWorkspace={onDuplicateWorkspace}
          onSidebarToggle={onSidebarToggle}
          isSidebarOpen={isSidebarOpen}
        />
        <div className="TabsContent">
          {activeWorkspace && !newWorkspace ? (
            <Dockview
              activeWorkspace={activeWorkspace}
              workspaceLoading={workspaceLoading}
              newworkspaceWidgets={newworkspaceWidgets}
              onWorkSpaceUpdated={onWorkSpaceUpdated}
            />
          ) : (
            <div className="emptyWorkspace">
              <img src="/images/icons/workspace.svg" alt="Workspace" height="48" width="46" style={{color:"#5A6874"}} />
              <h3>New Workspace</h3>
              <p>Welcome to your new workspace</p>
              <p>Add a new widget from the menu on the left to get started</p>
              <Button variant="contained" onClick={onAddWidgetClick}>
                Add Widget
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import AES from "crypto-js/aes";
import Crypto from "crypto-js";
import { User } from "./types";

export const saveToLS = (key: string, value: any) => {
  if (global.localStorage) {
    global.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getFromLS = (key: string) => {
  var ls;
  if (global.localStorage) {
    try {
      const value = global.localStorage.getItem(key);
      if (!value) return null;
      ls = JSON.parse(value) || null;
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls;
};

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "default_key_cme"; // Use environment variables for better security

const currentUserKey = "info";

export const saveCurrentUserToLS = (currentUser: User) => {
  try {
    const encrypted = AES.encrypt(JSON.stringify(currentUser), ENCRYPTION_KEY).toString();
    localStorage.setItem(currentUserKey, encrypted);
    console.log('saved current user to ls', encrypted);
  } catch (error) {
    console.error("Failed to save user to localStorage:", error);
  }
};

export const getCurrentUserFromLS = (): User | null => {
  try {
    const serializedUser = localStorage.getItem(currentUserKey);
    if (!serializedUser) return null;

    const decryptedBytes = AES.decrypt(serializedUser, ENCRYPTION_KEY);
    const decryptedUser = decryptedBytes.toString(Crypto.enc.Utf8);

    return JSON.parse(decryptedUser);
  } catch (error) {
    console.error("Failed to retrieve user from localStorage:", error);
    return null;
  }
};

export const removeCurrentUserFromLS = () => {
  try {
    localStorage.removeItem(currentUserKey);
  } catch (error) {
    console.error("Failed to remove user from localStorage:", error);
  }
};

import * as TradingAccount from "../proto/trading_account_2";
import { Account } from "./Account";
import { CurrencyRate } from "./CurrencyRate";

export class Fcm {
  id: number;
  name: string;
  accounts: Account[] = [];
  currencyRates: CurrencyRate[] = [];

  constructor(brokerageData: TradingAccount.Brokerage) {
    this.id = brokerageData.id;
    this.name = brokerageData.name;
    this.accounts = [];
  }

  getAccounts = () => {
    return this.accounts;
  };

  getCurrencyRates = () => {
    return this.currencyRates;
  };
}

import * as WebAPI from "../proto/webapi_2";
import * as TradingAccount from "../proto/trading_account_2";
import { AccountsSubscription } from "../models/AccountsSubscription";
import { Fcm } from "../models/Fcm";
import { Account } from "../models/Account";
import { CQGServiceMessageManager } from "../message-managers/ServiceMessageManager";
import { Deferred } from "../utils/Deferred";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";

export class AccountsManager {
  self = this;
  accounts: { [key: number | string]: Account } = {}; //dictionary<accountId, account>
  fcms: Fcm[] = [];
  accountsLoadedDeferred = new Deferred();
  subscription: AccountsSubscription | null = null;
  tempAccountData: TradingAccount.Brokerage[] = []; //array<brokerage>
  serviceMessageEventManager: CQGServiceMessageEventManager;
  serviceMessageManager: CQGServiceMessageManager;

  constructor(
    serviceMessageManager: CQGServiceMessageManager,
    serviceMessageEventManager: CQGServiceMessageEventManager,
  ) {
    this.serviceMessageManager = serviceMessageManager;
    this.serviceMessageEventManager = serviceMessageEventManager;

    this.accountsLoadedDeferred.state = "pending";

    this.serviceMessageEventManager?.onInformationReports(this.processInformationReport);

    this.serviceMessageEventManager?.onServiceReady(() => {
      this.subscribe(CQGServiceMessageManager.nextRequestId() as number);
    });
  }

  getAccount = (id: number | string) => {
    return this.accounts[id];
  };

  getFcm = (id: number) => {
    let foundFcms = this.fcms.filter((fcm: Fcm) => {
      return fcm.id === id;
    });

    return foundFcms[0];
  };

  getAccountsLoadedPromise = () => {
    return this.accountsLoadedDeferred.promise;
  };

  subscribe = (requestId: number) => {
    console.log("AccountsManager: Subscribing ...");
    if (this.subscription) {
      return this.accountsLoadedDeferred.promise;
    }

    let informationRequest = WebAPI.InformationRequest.create();
    informationRequest.id = requestId;
    informationRequest.subscribe = true;
    informationRequest.accountsRequest = TradingAccount.AccountsRequest.create();

    this.serviceMessageManager?.sendInformationRequest(informationRequest);

    this.tempAccountData = [];
    this.subscription = new AccountsSubscription(requestId);

    return this.accountsLoadedDeferred.promise;
  };

  processInformationReport = (informationReport: WebAPI.InformationReport[]) => {
    // console.log("AccountsManager: processInformationReport", informationReport);

    informationReport.forEach((item: WebAPI.InformationReport) => {
      this.processInformationReportItem(item);
    });
  };

  processInformationReportItem = (informationReport: WebAPI.InformationReport) => {
    let accountsReport: TradingAccount.AccountsReport | undefined = informationReport.accountsReport;

    if (!this.subscription || this.subscription.id !== informationReport.id) {
      return;
    }

    if (informationReport.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_DISCONNECTED) {
      console.warn("Disconnected status code was received for account report.");
      return;
    }

    let statusCode = informationReport.statusCode;
    this.subscription.statusCode = statusCode;
    this.subscription.textMessage = informationReport.textMessage as string;

    if (statusCode >= WebAPI.InformationReport_StatusCode.STATUS_CODE_FAILURE) {
      this.accountsLoadedDeferred.reject();
      this.accountsLoadedDeferred.state = "rejected";
    } else if (
      statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS ||
      statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED
    ) {
      accountsReport?.brokerages.forEach((brokerage: TradingAccount.Brokerage) => {
        this.tempAccountData.push(brokerage);
      });
    }

    if (informationReport.isReportComplete && this.accountsLoadedDeferred.state !== "rejected") {
      const fcms: { [key: number]: Fcm } = {};
      this.tempAccountData.forEach((brokerageData: TradingAccount.Brokerage) => {
        let fcm = fcms[brokerageData.id];
        if (!fcm) {
          fcm = new Fcm(brokerageData);
          fcms[brokerageData.id] = fcm;
        }
        let fcmAccounts = fcm.getAccounts();
        brokerageData.salesSeries.forEach((salesSeries: TradingAccount.SalesSeries) => {
          salesSeries.accounts.forEach((accountData: TradingAccount.Account) => {
            if (!accountData.isUnauthorized) {
              const account = new Account(fcm, accountData);
              fcmAccounts.push(account);
              this.accounts[account.id] = account;
            }
          });
        });
      });

      this.tempAccountData = [];
      let sortedFcms: Fcm[] = [];

      for (let key in fcms) {
        let fcm = fcms[key];
        sortedFcms.push(fcm);
      }

      sortedFcms = sortedFcms.sort((a: Fcm, b: Fcm) => {
        return a.name.localeCompare(b.name);
      });

      sortedFcms.forEach((fcm: Fcm) => {
        this.fcms.push(fcm);
      });

      this.accountsLoadedDeferred.resolve(this.accounts);
      this.accountsLoadedDeferred.state = "resolved";
    }
    //TODO: implement updates
  };

  clear = () => {
    this.accounts = {};
    this.accountsLoadedDeferred = new Deferred();
    this.accountsLoadedDeferred.state = "pending";
    this.subscription = null;
    this.fcms = [];
  };
}

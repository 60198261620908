import { toast } from "react-toastify";
export default class Notification {
  static success(message: string) {
    toast.success(`${message}`, {});
  }
  static error(message: string) {
    const toastId = "error-toast";
    if (!toast.isActive(toastId)) {
      toast.error(`${message}`, {
        toastId: toastId,
      });
    }
  }
  static warning(message: string) {
    toast.warn(message, {});
  }
  static info(message: string) {
    toast.info(message, {});
  }
}
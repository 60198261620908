import * as WebAPI from "../proto/webapi_2";
import * as TradeRouting from "../proto/trade_routing_2";
import * as MarketData from "../proto/market_data_2";
import * as UserSession from "../proto/user_session_2";
import * as Order2 from "../proto/order_2";

// import { CQGEnvironment } from "./CQGEnvironment";
import { SymbolResolutionSubscriptions } from "../services/SymbolResolutionSubscriptions";

import { Instrument } from "../models/Instrument";
import { InstrumentsManager } from "../services/InstrumentsManager";
import { onServiceMessageEvent } from "../types/Types";
import { CQGEnvironment } from "../services/CQGEnvironment";
import { CurrencyRatesManager } from "../services/CurrencyRatesManager";

export class CQGServiceMessageEventManager {
  private onServiceReadyEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onConnectionCloseEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onOrderStatusesEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onTradeSnapshotCompletionsEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onTradeSubscriptionStatusEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onCollateralStatusesEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onPositionStatusesEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onInformationReportsEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onMarketDataSubscriptionStatusesEvent: onServiceMessageEvent = new onServiceMessageEvent();
  private onRealTimeMarketDataEvent: onServiceMessageEvent = new onServiceMessageEvent();

  public onServiceReady(listnerFn: (logonResult: UserSession.LogonResult) => void) {
    this.onServiceReadyEvent.on("serviceReady", listnerFn);
  }

  public onConnectionClose(listnerFn: () => void) {
    this.onConnectionCloseEvent.on("connectionClose", listnerFn);
  }

  public onCollateralStatuses(listnerFn: (collateralStatus: TradeRouting.CollateralStatus[]) => void) {
    this.onCollateralStatusesEvent.on("collateralStatuses", listnerFn);
  }

  public onOrderStatuses(listnerFn: (orderStatus: Order2.OrderStatus[]) => void) {
    this.onOrderStatusesEvent.on("orderStatuses", listnerFn);
  }

  public onTradeSnapshotCompletions(listnerFn: (tradeSnapshot: TradeRouting.TradeSnapshotCompletion[]) => void) {
    this.onTradeSnapshotCompletionsEvent.on("tradeSnapshotCompletions", listnerFn);
  }

  public onTradeSubscriptionStatus(
    listnerFn: (tradeSubscriptionStatus: TradeRouting.TradeSubscriptionStatus[]) => void,
  ) {
    this.onTradeSubscriptionStatusEvent.on("tradeSubscriptionStatus", listnerFn);
  }

  public onPositionStatuses(listnerFn: (positionStatus: TradeRouting.PositionStatus[]) => void) {
    this.onPositionStatusesEvent.on("positionStatuses", listnerFn);
  }

  public onInformationReports(listnerFn: (informationReport: WebAPI.InformationReport[]) => void) {
    this.onInformationReportsEvent.on("informationReports", listnerFn);
  }

  public onMarketDataSubscriptionStatuses(
    listnerFn: (marketDataSubscriptionStatuses: MarketData.MarketDataSubscriptionStatus[]) => void,
  ) {
    this.onMarketDataSubscriptionStatusesEvent.on("marketDataSubscriptionStatuses", listnerFn);
  }

  public offMarketDataSubscriptionStatuses(
    listnerFn: (marketDataSubscriptionStatuses: MarketData.MarketDataSubscriptionStatus[]) => void,
  ) {
    this.onMarketDataSubscriptionStatusesEvent.off("marketDataSubscriptionStatuses", listnerFn);
  }

  public onRealTimeMarketData(listnerFn: (realTimeMarketData: Instrument[]) => void) {
    this.onRealTimeMarketDataEvent.on("realTimeMarketData", listnerFn);
    this.onRealTimeMarketDataEventRegister();
  }

  public offRealTimeMarketData(listnerFn: (realTimeMarketData: Instrument[]) => void) {
    this.onRealTimeMarketDataEvent.off("realTimeMarketData", listnerFn);
  }

  public serviceReadyEventEmit = (data: UserSession.LogonResult) => {
    // this.scheduleMessage();
    this.onServiceReadyEvent.emit("serviceReady", data);
  };

  public connectionCloseEventEmit = () => {
    this.onConnectionCloseEvent.emit("connectionClose");
    alert("Service Connection closed!");
  };

  public orderStatusesEventEmit = (orderStatuses: Order2.OrderStatus[]) => {
    this.onOrderStatusesEvent.emit("orderStatuses", orderStatuses);
  };

  public tradeSnapshotCompletionsEventEmit = (data: TradeRouting.TradeSnapshotCompletion[]) => {
    this.onTradeSnapshotCompletionsEvent.emit("tradeSnapshotCompletions", data);
  };

  public tradeSubscriptionStatusEventEmit = (data: TradeRouting.TradeSubscriptionStatus[]) => {
    this.onTradeSubscriptionStatusEvent.emit("tradeSubscriptionStatus", data);
  };

  public collateralStatusesEventEmit = (data: TradeRouting.CollateralStatus[]) => {
    this.onCollateralStatusesEvent.emit("collateralStatuses", data);
  };

  public positionStatusesEventEmit = (data: TradeRouting.PositionStatus[]) => {
    this.onPositionStatusesEvent.emit("positionStatuses", data);
  };

  public informationReportsEventEmit = (informationReport: WebAPI.InformationReport[]) => {
    const env = CQGEnvironment.Instance;

    try {
      SymbolResolutionSubscriptions.processInformationReport(informationReport);

      // symbolsByUnderlyingSubscriptions.processInformationReport(informationReport);
      // childSymbolsSubscriptions.processInformationReport(informationReport);
      // atTheMoneyStrikeSubscriptions.processInformationReport(informationReport);

      env.accountSummariesManager?.processInformationReport(informationReport);
      CurrencyRatesManager.processInformationReport(informationReport);
    } catch (e) {
      console.error("Error in informationReportsEventEmit", e);
    }

    this.onInformationReportsEvent.emit("informationReports", informationReport);
  };

  public marketDataSubscriptionStatusesEventEmit = (
    marketDataSubscriptionStatuses: MarketData.MarketDataSubscriptionStatus[],
  ) => {
    try {
      CQGEnvironment.Instance.cqgService.processMarketDataSubscriptionStatus(marketDataSubscriptionStatuses);
    } catch (e) {
      console.error("Error in marketDataSubscriptionStatusesEventEmit", e);
    }

    this.onMarketDataSubscriptionStatusesEvent.emit("marketDataSubscriptionStatuses", marketDataSubscriptionStatuses);
  };

  public realTimeMarketDataEventEmit = (realTimeMarketData: MarketData.RealTimeMarketData[]) => {
    let updates = CQGEnvironment.Instance.cqgService.processRealTimeMarketData(realTimeMarketData) as Instrument[];
    this.onRealTimeMarketDataEvent.emit("realTimeMarketData", updates);
  };

  private onRealTimeMarketDataEventRegister = () => {
    let insts = InstrumentsManager.getAllInstruments();
    let instsArray: Instrument[] = [];

    for (const key in insts) {
      if (Object.prototype.hasOwnProperty.call(insts, key)) {
        const element = insts[key];
        instsArray.push(element);
      }
    }

    this.onRealTimeMarketDataEvent.emit("realTimeMarketData", instsArray);
  };
}

import { LayoutProps } from "./types";
import './layout.css';

const Layout = ({ children }: LayoutProps) => (
  <div className="layout">
    {children}
  </div>
);

export default Layout;

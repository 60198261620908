import * as TradeRouting from "../proto/trade_routing_2";
import { TradeSubscriptionParams } from "../models/TradeSubscriptionParams";
import { InstrumentsManager } from "./InstrumentsManager";
import { Position } from "../models/Position";
import * as _ from "../../vendors/underscore-esm";
import { Account } from "../models/Account";
import { PurchaseAndSalesGroup } from "../models/PurchaseAndSalesGroup";
import { AccountsManager } from "./AccountsManager";
import { TradeSubscriptionsManager } from "./TradeSubscriptionsManager";
import { AppMessageManager } from "../message-managers/AppMessageManager";
import { AccountSummariesManager } from "./AccountSummariesManager";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";

export class PositionsManager {
  positions: { [key: string]: { [key: string]: Position } } = {}; //dictionary<{accountId-contractId}, dictionary<id, position>>
  salesGroups: { [key: string]: { [key: number]: PurchaseAndSalesGroup } } = {}; //dictionary<{accountId-contractId}, dictionary<id, salesGroup>>
  tempSnapshotData: { [key: number]: TradeRouting.PositionStatus[] } = {}; //dictionary<subscriptionId,positionStatus[]>
  zeroId = 0;

  tradeSubscriptionsManager: TradeSubscriptionsManager;
  accountsManager: AccountsManager;
  appMessageManager: AppMessageManager;
  serviceMessageEventManager: CQGServiceMessageEventManager;
  accountSummariesManager: AccountSummariesManager;

  constructor(
    appMessageManager: AppMessageManager,
    tradeSubscriptionsManager: TradeSubscriptionsManager,
    accountsManager: AccountsManager,
    serviceMessageEventManager: CQGServiceMessageEventManager,
    accountSummariesManager: AccountSummariesManager,
  ) {
    this.appMessageManager = appMessageManager;
    this.tradeSubscriptionsManager = tradeSubscriptionsManager;
    this.accountsManager = accountsManager;
    this.serviceMessageEventManager = serviceMessageEventManager;
    this.accountSummariesManager = accountSummariesManager;

    this.serviceMessageEventManager?.onPositionStatuses(this.processPositionStatusUpdates);
    this.serviceMessageEventManager?.onTradeSnapshotCompletions(this.processTradeSnapshotCompletion);
  }

  subscribe = (requestId: number, accountId: number | string) => {
    console.log("Subscribing to positions ... Account:", accountId);
    const account = this.accountsManager?.getAccount(accountId);
    if (!account || account.positionDataRequested) {
      return;
    }

    const params = new TradeSubscriptionParams(requestId, accountId as number);
    params.subscriptionScope = [TradeRouting.TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_POSITIONS];
    params.publicationType = TradeRouting.TradeSubscription_PublicationType.PUBLICATION_TYPE_ACCOUNTS;

    this.tradeSubscriptionsManager?.subscribe(params);

    account.positionDataRequested = true;
  };

  processPositionStatusUpdates = (updates: TradeRouting.PositionStatus[]) => {
    updates.forEach((positionsStatus) => {
      this.processPositionsStatus(positionsStatus);
    });
  };

  processTradeSnapshotCompletion = (tradeSnapshotCompletion: TradeRouting.TradeSnapshotCompletion[]) => {
    tradeSnapshotCompletion.forEach((item) => {
      this.processTradeSnapshotCompletionItem(item);
    });
  };

  processTradeSnapshotCompletionItem = (tradeSnapshotCompletion: TradeRouting.TradeSnapshotCompletion) => {
    if (
      tradeSnapshotCompletion.subscriptionScopes.indexOf(
        TradeRouting.TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_POSITIONS,
      ) < 0
    ) {
      return;
    }

    const pendingPositionsBySubscription = this.tempSnapshotData[tradeSnapshotCompletion.subscriptionId];

    if (!pendingPositionsBySubscription) {
      return;
    }

    this.clearPositionsAndSalesGroups(pendingPositionsBySubscription);
    this.applyPositionsStatusUpdates(pendingPositionsBySubscription);

    delete this.tempSnapshotData[tradeSnapshotCompletion.subscriptionId];
  };

  processPositionsStatus = (positionsStatus: TradeRouting.PositionStatus) => {
    if (positionsStatus.contractMetadata) {
      InstrumentsManager.addOrUpdate(positionsStatus.contractMetadata);
    }

    if (positionsStatus.isSnapshot) {
      this.processPositionsStatusSnapshot(positionsStatus);
    } else {
      this.applyPositionsStatusUpdates([positionsStatus]);
    }
  };

  processPositionsStatusSnapshot = (positionsStatus: TradeRouting.PositionStatus) => {
    positionsStatus.subscriptionIds.forEach((subscriptionId) => {
      let pendingPositionsBySubscription = this.tempSnapshotData[subscriptionId];
      if (!pendingPositionsBySubscription) {
        pendingPositionsBySubscription = [];
        this.tempSnapshotData[subscriptionId] = pendingPositionsBySubscription;
      }
      pendingPositionsBySubscription.push(positionsStatus);
    });
  };

  clearPositionsAndSalesGroups = (updates: TradeRouting.PositionStatus[]) => {
    const updateAccounts: Account[] = _.map(
      _.uniq(updates, (positionStatus: TradeRouting.PositionStatus) => {
        return positionStatus.accountId;
      }),
      (positionStatus: TradeRouting.PositionStatus) => {
        return this.accountsManager?.getAccount(positionStatus.accountId);
      },
    );

    this.positions = _.omit(this.positions, (positionList: { [key: string]: Position }) => {
      let account;
      _.forEach(positionList, (position: Position) => {
        account = position.getAccount();
        return false;
      });
      return account && updateAccounts.indexOf(account) >= 0;
    });

    this.salesGroups = _.omit(this.salesGroups, (salesGroupsList: { [key: number]: PurchaseAndSalesGroup }) => {
      let account;
      _.forEach(salesGroupsList, (group: PurchaseAndSalesGroup) => {
        account = group.getAccount();
        return false;
      });

      return account && updateAccounts.indexOf(account) >= 0;
    });
    _.forEach(updateAccounts, (account: Account) => {
      if (account) {
        account.clearPositions();
        account.clearPurchaseAndSalesGroups();
      }
    });
  };

  applyPositionsStatusUpdates = (updates: TradeRouting.PositionStatus[]) => {
    const newPositions: { [key: number]: Position[] } = {};
    const newGroups: { [key: number]: PurchaseAndSalesGroup[] } = {};
    updates.forEach((update: TradeRouting.PositionStatus) => {
      const accountId = update.accountId;

      const account = this.accountsManager?.getAccount(accountId);
      if (!account) {
        return;
      }

      const positionKey = this.getPositionKey(update);
      let positionsToUpdate: { [key: string]: Position } = this.positions[positionKey];
      if (!positionsToUpdate) {
        positionsToUpdate = {};
        this.positions[positionKey] = positionsToUpdate;
      }

      const positionUpdates = update.openPositions.slice(0);

      if (positionUpdates.length === 0 && update.purchaseAndSalesGroups.length > 0) {
        // This means we have a position status update for a closed position.
        // Create a zero position with negative Id so that it doesnt conflict with any CQG's update
        // This will allow the system to display closed positions.
        positionUpdates.push(this.generateAppZeroPosition());
      }

      positionUpdates.forEach((position: TradeRouting.OpenPosition) => {
        const positionToUpdate = positionsToUpdate[position.id];

        if (!positionToUpdate) {
          const newPosition = new Position(account!, update, position);
          positionsToUpdate[position.id] = newPosition;
          this.accountSummariesManager?.ensureAccountSummaryExist(
            account!,
            newPosition.getInstrument()?.currency!,
          );

          let accountNewPositions = newPositions[accountId];
          if (!accountNewPositions) {
            accountNewPositions = [];
            newPositions[accountId] = accountNewPositions;
          }
          accountNewPositions.push(newPosition);
        } else {
          positionToUpdate.updateFromPositionStatus(update, position);
        }
        //TODO: implement chunked updates
      });

      let groupsToUpdate = this.salesGroups[positionKey];
      if (!groupsToUpdate) {
        groupsToUpdate = {};
        this.salesGroups[positionKey] = groupsToUpdate;
      }
      _.forEach(update.purchaseAndSalesGroups, (group: TradeRouting.PurchaseAndSalesGroup) => {
        const groupToUpdate = groupsToUpdate[group.id];
        if (!groupToUpdate) {
          const newGroup = new PurchaseAndSalesGroup(update, group, account!);
          groupsToUpdate[group.id] = newGroup;
          this.accountSummariesManager?.ensureAccountSummaryExist(
            account!,
            newGroup.getInstrument()?.currency!,
          );

          let accountNewGroups: PurchaseAndSalesGroup[] = newGroups[accountId];
          if (!accountNewGroups) {
            accountNewGroups = [];
            newGroups[accountId] = accountNewGroups;
          }
          accountNewGroups.push(newGroup);
        } else {
          groupToUpdate.update(update, group);
        }
      });
    });
    _.forEach(newPositions, (accountNewPositions: Position[], accountId: number) => {
      const account = this.accountsManager?.getAccount(accountId);
      account?.addPositions(accountNewPositions);
      this.appMessageManager.accountPositionsChangeEmit(accountNewPositions);
    });

    _.forEach(newGroups, (accountNewGroups: PurchaseAndSalesGroup[], accountId: number) => {
      const account = this.accountsManager?.getAccount(accountId);
      account?.addPurchaseAndSalesGroups(accountNewGroups);
    });
  };

  generateAppZeroPosition = () => {
    const zero = TradeRouting.OpenPosition.create();
    zero.id = --this.zeroId;
    zero.tradeDate = 0;
    zero.statementDate = 0;
    zero.tradeUtcTime = 0;

    return zero;
  };

  getPositionKey = (positionStatus: TradeRouting.PositionStatus) => {
    return positionStatus.accountId + "-" + positionStatus.contractId;
  };

  clear = () => {
    this.positions = {};
    this.tempSnapshotData = {};
    this.salesGroups = {};
  };

  getAggregateContractPosition = (contractPositions: any) => {
    //:: return new AggregateContractPosition(contractPositions);
  };

  getAggregateContractPurchaseAndSales = (group: any) => {
    //:: return new AggregateContractPurchaseAndSales(group);
  };
}

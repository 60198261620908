import React, { useEffect, useState, useCallback, memo } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress } from "@mui/material";

type RenameDialogProps = {
  open: boolean;
  onClose: () => void;
  onRename: (newTitle: string) => void;
  currentTitle: string;
  loading: boolean;
};

const RenameDialog = memo(({ open, onClose, onRename, currentTitle, loading }: RenameDialogProps) => {
  const [newTitle, setNewTitle] = useState(currentTitle);

  useEffect(() => {
    setNewTitle(currentTitle);
  }, [currentTitle]);

  const handleRename = useCallback(() => newTitle.trim() && onRename(newTitle), [newTitle, onRename]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleRename();
    }
  }, [handleRename]);

  const inputStyles = {
    "& .MuiInputBase-root": {
      "& input": { color: "black" },
      "&:before": { borderBottom: "1px solid black" },
      "&:hover:before": { borderBottom: "2px solid black" },
      "&.Mui-focused:before": { borderBottom: "2px solid black" },
      "&.Mui-focused:after": { borderBottom: "2px solid black" },
    },
    "& .MuiFormLabel-root": { color: "black" },
    "& .MuiFormLabel-root.Mui-focused": { color: "black" },
  };

  const buttonStyles = {
    marginX: 1,
    border: "1px solid black",
    color: "black",
    "&:hover": { backgroundColor: "#f5f5f5", borderColor: "#333" },
  };

  return (
    <Dialog open={open} onClose={onClose} 
      PaperProps={{ sx: { padding: 2 }}}
      >
      <DialogTitle>Rename Workspace</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Workspace Title"
          type="text"
          fullWidth
          variant="standard"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={inputStyles}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-evenly" }}>
        <Button onClick={onClose} sx={buttonStyles}>
          Cancel
        </Button>
        <Button
          onClick={handleRename}
          disabled={loading || newTitle.trim() === ""}
          sx={{
            ...buttonStyles,
            display: "flex",
            alignItems: "center",
            "&:disabled": { borderColor: "#ddd", color: "#aaa" },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ marginRight: 1, color: "black" }} /> : "Rename"}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default RenameDialog;

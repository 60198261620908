import Logo from "../../Logo";
import "./header.css";
import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Notifications} from "@mui/icons-material";
import { useCollateralStatus } from "../../../../cqg-api/hooks/ServiceHooks";
import { formatCurremcy } from "../../../../cqg-api/utils/lib";
import { removeCurrentUserFromLS } from "../../../../storage";
import { useDispatch } from "react-redux";
import authActions from "../../../../redux/auth/authActions";
import { useGetCurrentUser } from "../../../../hooks/api/authentication";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Header = () => {
  const { collateralStatus } = useCollateralStatus();
  const dispatch = useDispatch();
  const currentUser = useGetCurrentUser();
  const firstName = currentUser?.firstName || "";
  const lastName = currentUser?.lastName || "";
  const userName = `${firstName} ${lastName}`;
  const profileInitial = `${firstName.charAt(0).toUpperCase()}`;
  // console.log("collateralStatus", collateralStatus);
  const [anchorShow, setAnchorShow] = useState(null);
  const open = Boolean(anchorShow);

  const handleNotificationsClick = () => {
    console.log("Notifications icon clicked");
  };

  const handleAccountClick = (event: any) => {
    setAnchorShow(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorShow(null);
  };
  const handleMenuItemClick = () => {
    removeCurrentUserFromLS();
    dispatch(authActions.doSignout());

    handleClose();
  };
  const handleMenuItem = (action: string) => {
    console.log(`${action} clicked`);
  };

  const defaultStatus = {
    purchasingPower: 0,
    positionMargin: 0,
  };

  const displayStatus = collateralStatus.length > 0 ? collateralStatus[0] : defaultStatus;

  return (
    <header>
      <div className="header-left">
        <Logo />
        <div className="header-right">
          <div className="info-item">
            <span>FUNDS</span>
            <strong>{formatCurremcy(displayStatus.purchasingPower)}</strong>
          </div>
          <div className="info-item">
            <span>PROFIT/LOSS</span>
            <strong>$0.00</strong>
          </div>
          <div className="info-item">
            <span>MARGIN</span>
            <strong>{formatCurremcy(displayStatus.positionMargin)}</strong>
          </div>
          <div className="info-item">
            <span>AVAILABLE</span>
            <strong>$0.00</strong>
          </div>
        </div>
      </div>

      <div className="header-controls">
        <div className="icon-group">
          <IconButton className="menu-icon" onClick={handleNotificationsClick}>
            <Notifications />
          </IconButton>
          <div className="profile-section">
            <div className="profile-icon">{profileInitial}</div>
            <div className="profile-text">
              <div className="profile-sec-top">
                  <span className="profile-name">{userName}</span>
                <IconButton onClick={handleAccountClick} className="dropdown-button" sx={{ padding: 0, fontSize: 'inherit', '& svg': {fontSize: '1.3rem'} }}> 
                  {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <div className="profile-sec-bottom">
                <span>Practice Mode</span>
              </div>
            </div>
          </div>
        </div>
        <Menu anchorEl={anchorShow} open={open} onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{ vertical: 'top', horizontal: 'center'}}
          PaperProps={{ style: { width: '40ch', marginTop: '20px'} }}
          className="menu-paper"
          >
          <div className="account-section" style={{ display: 'flex',padding: '0px 12px'}}>
            <div className="profile-icon">{profileInitial}</div>
            <div className="profile-text">
              <span className="profile-name">{userName}</span>
              <div className="acc-sec">
                <span>Challenge account</span>
              </div>
            </div>
          </div>
          <hr className="divider-menu" />
          <MenuItem onClick={() => handleMenuItem("Settings")}>
            <span className="menu-item-text">Account Settings</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick()}>
            <span className="menu-item-text">Logout</span>
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default Header;

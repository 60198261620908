import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Adjust header height and row height here
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  height: 450,
  width: '100%',
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#ffffff",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.selected-product-row": {
    backgroundColor: "#fff",
  },
  '& td, & th': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
  }
}));

export const StyledTableRowTitle = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#ffffff",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.selected-product-row": {
    backgroundColor: "#fff",
  },
  // Ensure font family and size for rows
  '& td, & th': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
  }
}));

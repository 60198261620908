// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: trade_routing_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Decimal } from "./common/decimal";
import { Text } from "./common/shared_1";
import { Timestamp } from "./google/protobuf/timestamp";
import { ContractMetadata } from "./metadata_2";
import { Commission, OrderStatus } from "./order_2";
import Long from "long";

export const protobufPackage = "trade_routing_2";

/** Trading feature entitlements. */
export enum TradingFeatureEntitlement {
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_FAST_CLICK_CONFIRMATIONS - Disallow skipping non-fast click confirmations. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_FAST_CLICK_CONFIRMATIONS = 3,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_DDT_FAST_CLICK_CONFIRMATIONS - Disallow skipping Responsive DOMTrader fast click confirmations. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_DDT_FAST_CLICK_CONFIRMATIONS = 4,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_HEADS_UP - Disallow Heads Up. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_HEADS_UP = 5,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_ORDER_VIEW - Disallow Order View. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_ORDER_VIEW = 6,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_RESPONSIVE_DOM_TRADER - Disallow Responsive DOMTrader. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_RESPONSIVE_DOM_TRADER = 8,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_DYNAMIC_DOM_TRADER - Disallow Dynamic DOMTrader. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_DYNAMIC_DOM_TRADER = 9,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_BUY_SELL_FAST_CLICK - Disallow buy/sell fast click. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_BUY_SELL_FAST_CLICK = 11,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_DDT_FAST_CLICK_CONFIRMATIONS - Disallow skipping Dynamic DOMTrader fast click confirmations. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_DDT_FAST_CLICK_CONFIRMATIONS = 12,
  /** TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_FORCE_ORDER_CONFIRMATION - Disallow skipping force order confirmation. */
  TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_FORCE_ORDER_CONFIRMATION = 13,
  UNRECOGNIZED = -1,
}

export function tradingFeatureEntitlementFromJSON(object: any): TradingFeatureEntitlement {
  switch (object) {
    case 3:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_FAST_CLICK_CONFIRMATIONS":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_FAST_CLICK_CONFIRMATIONS;
    case 4:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_DDT_FAST_CLICK_CONFIRMATIONS":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_DDT_FAST_CLICK_CONFIRMATIONS;
    case 5:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_HEADS_UP":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_HEADS_UP;
    case 6:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_ORDER_VIEW":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_ORDER_VIEW;
    case 8:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_RESPONSIVE_DOM_TRADER":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_RESPONSIVE_DOM_TRADER;
    case 9:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_DYNAMIC_DOM_TRADER":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_DYNAMIC_DOM_TRADER;
    case 11:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_BUY_SELL_FAST_CLICK":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_BUY_SELL_FAST_CLICK;
    case 12:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_DDT_FAST_CLICK_CONFIRMATIONS":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_DDT_FAST_CLICK_CONFIRMATIONS;
    case 13:
    case "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_FORCE_ORDER_CONFIRMATION":
      return TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_FORCE_ORDER_CONFIRMATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradingFeatureEntitlement.UNRECOGNIZED;
  }
}

export function tradingFeatureEntitlementToJSON(object: TradingFeatureEntitlement): string {
  switch (object) {
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_FAST_CLICK_CONFIRMATIONS:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_FAST_CLICK_CONFIRMATIONS";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_DDT_FAST_CLICK_CONFIRMATIONS:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_NON_DDT_FAST_CLICK_CONFIRMATIONS";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_HEADS_UP:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_HEADS_UP";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_ORDER_VIEW:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_ORDER_VIEW";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_RESPONSIVE_DOM_TRADER:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_RESPONSIVE_DOM_TRADER";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_DYNAMIC_DOM_TRADER:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_DYNAMIC_DOM_TRADER";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_BUY_SELL_FAST_CLICK:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_BUY_SELL_FAST_CLICK";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_DDT_FAST_CLICK_CONFIRMATIONS:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_DDT_FAST_CLICK_CONFIRMATIONS";
    case TradingFeatureEntitlement.TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_FORCE_ORDER_CONFIRMATION:
      return "TRADING_FEATURE_ENTITLEMENT_DISALLOW_SKIP_FORCE_ORDER_CONFIRMATION";
    case TradingFeatureEntitlement.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Subscription to trade routing data and notifications about trading information updates.
 * Client can be subscribed to several publications.
 * If some account is subscribed by several publications then client will receive a separate snapshot per subscription
 * but one real time update with a list of subscriptions.
 */
export interface TradeSubscription {
  /** ID of a subscription that should be unique enough to match responses and updates with corresponding requests. */
  id: number;
  /**
   * This field is associated with SubscriptionScope enum type.
   * Request is rejected if subscription_scopes contains duplicates.
   */
  subscriptionScopes: number[];
  /** This field is associated with PublicationType enum type. */
  publicationType?:
    | number
    | undefined;
  /** Account IDs when publicationType = PUBLICATION_TYPE_ACCOUNTS. */
  accountIds: number[];
  /** Sales series number when publicationType = PUBLICATION_TYPE_SALES_SERIES. */
  salesSeriesNumber?:
    | string
    | undefined;
  /** Brokerage ID when publicationType = PUBLICATION_TYPE_BROKERAGE or PUBLICATION_TYPE_SALES_SERIES. */
  brokerageId?:
    | number
    | undefined;
  /** True to subscribe, false to unsubscribe (only id value is used to unsubscribe). */
  subscribe: boolean;
  /**
   * Note: use last_order_update_utc_timestamp field instead.
   *
   * @deprecated
   */
  lastOrderUpdateUtcTime?:
    | number
    | undefined;
  /**
   * Optionally limit request to receive information about orders
   * that were updated/added after specified server related time (inclusive).
   * It is used to reduce amount of information necessary to send after re-connection.
   * Client should be ready for duplicates that have to be detected by corresponding IDs.
   * If specified it's used instead of last_order_update_utc_time field.
   */
  lastOrderUpdateUtcTimestamp?:
    | Date
    | undefined;
  /**
   * True means sending only real time data and skip sending an initial orders snapshot,
   * send initial snapshot otherwise.
   * NOTE: do not set this attribute after restoring session since some events might be missed to be delivered,
   * use last_order_update_utc_time instead.
   * NOTE: when set to True, WebAPI server does not send order snapshots for accounts authorized during
   * the subscription.
   */
  skipOrdersSnapshot?:
    | boolean
    | undefined;
  /**
   * True means sending only FCM confirmed positions and their updates (if subscribed)
   * without matching with current day fills, send matched net positions otherwise.
   */
  confirmedPositionsOnly?:
    | boolean
    | undefined;
  /**
   * This field is associated with MatchingAlgorithm enum type.
   * MATCHING_ALGORITHM_FIFO is used if omitted.
   * Applicable only for contracts with ContractMetadata.position_tracking = POSITION_TRACKING_TYPE_NET_POSITION.
   * For other contract types it is always MATCHING_ALGORITHM_FIFO.
   */
  matchingAlgorithm?:
    | number
    | undefined;
  /**
   * If true or omitted then intraday trades are matched first
   * and then intraday leftover is matched against previous close positions.
   * If false then previous day open positions and intraday fills are matched in a single pass.
   * Applicable only for contracts with ContractMetadata.position_tracking = POSITION_TRACKING_TYPE_NET_POSITION.
   * For other contract types it is always false.
   */
  matchIntradayFirst?:
    | boolean
    | undefined;
  /**
   * This field is associated with MatchingAlgorithm enum type.
   * Historical positions Matching algorithm. The same as matching_algorithm if omitted.
   * It is ignored if match_intraday_first is false.
   * Applicable only for contracts with ContractMetadata.position_tracking = POSITION_TRACKING_TYPE_NET_POSITION.
   */
  historicalMatchingAlgorithm?:
    | number
    | undefined;
  /**
   * If this field set to true, statuses on orders with ORDER_TYPE_CROSS type will be
   * included into responses. See CrossOrderParameters message.
   * Note that order statuses with cross order type are excluded from snapshot only if
   * include_cross_orders set to false (or empty) and ORDER_TYPE_CROSS type is not included
   * into order_snapshot_filter.order_types.
   */
  includeCrossOrders?:
    | boolean
    | undefined;
  /**
   * If this field set to true, child orders of algo strategies will be included
   * into responses. Algo strategy order is order that has Order.algo_strategy field set.
   * It can cause placement of other orders. Some of these subordinate orders will reference initial order
   * (see SyntheticStrategyProperties.root_order_id), such orders are algo strategy "child" orders. Unlike synthetic
   * strategy case, algo strategy child orders are not included in subscription's snapshot or updates by default.
   * Note that unlike synthetic strategy case, fill transactions of child orders are duplicated
   * in algo strategy parent order, but shall be counted only once in calculations of position, collaterals, etc.
   * Note that algo strategy child order doesn't correspond to any "leg" or "node" in "synthetic strategy" sense, so for
   * algo strategy child order SyntheticStrategyProperties.node_index is not set.
   */
  includeAlgoStrategyChildOrders?:
    | boolean
    | undefined;
  /**
   * Only order statuses that match the filter are included in a snapshot.
   * By default no filtration is done by the filter.
   * Affects result for scope SUBSCRIPTION_SCOPE_ORDERS.
   * Note that snapshot might still include additional data in responses (e.g. when multiple trade
   * subscriptions with different snapshot filters are requested).
   */
  orderSnapshotFilter?:
    | OrderSnapshotFilter
    | undefined;
  /**
   * Parameters for account summary trade subscription.
   * It is requred field for subscriptions with SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY scope.
   */
  accountSummaryParameters?: AccountSummaryParameters | undefined;
}

/** Scope of the subscription (provide a list). */
export enum TradeSubscription_SubscriptionScope {
  /** SUBSCRIPTION_SCOPE_ORDERS - Subscribe to order updates. */
  SUBSCRIPTION_SCOPE_ORDERS = 1,
  /** SUBSCRIPTION_SCOPE_POSITIONS - Subscribe to open positions and matched trades updates. */
  SUBSCRIPTION_SCOPE_POSITIONS = 2,
  /** SUBSCRIPTION_SCOPE_COLLATERAL - Subscribe to collateral updates (current margin and purchasing power). */
  SUBSCRIPTION_SCOPE_COLLATERAL = 3,
  /**
   * SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY - Subscribe to account summary updates (current margin, purchasing power,
   * total filled quantity etc).
   */
  SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY = 4,
  UNRECOGNIZED = -1,
}

export function tradeSubscription_SubscriptionScopeFromJSON(object: any): TradeSubscription_SubscriptionScope {
  switch (object) {
    case 1:
    case "SUBSCRIPTION_SCOPE_ORDERS":
      return TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ORDERS;
    case 2:
    case "SUBSCRIPTION_SCOPE_POSITIONS":
      return TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_POSITIONS;
    case 3:
    case "SUBSCRIPTION_SCOPE_COLLATERAL":
      return TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_COLLATERAL;
    case 4:
    case "SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY":
      return TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradeSubscription_SubscriptionScope.UNRECOGNIZED;
  }
}

export function tradeSubscription_SubscriptionScopeToJSON(object: TradeSubscription_SubscriptionScope): string {
  switch (object) {
    case TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ORDERS:
      return "SUBSCRIPTION_SCOPE_ORDERS";
    case TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_POSITIONS:
      return "SUBSCRIPTION_SCOPE_POSITIONS";
    case TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_COLLATERAL:
      return "SUBSCRIPTION_SCOPE_COLLATERAL";
    case TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY:
      return "SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY";
    case TradeSubscription_SubscriptionScope.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Type of the publication to subscribe. */
export enum TradeSubscription_PublicationType {
  /** PUBLICATION_TYPE_ACCOUNTS - Subscribe to a single account or list of accounts. */
  PUBLICATION_TYPE_ACCOUNTS = 1,
  /** PUBLICATION_TYPE_SALES_SERIES - Subscribe to all accounts of a specific sales series. */
  PUBLICATION_TYPE_SALES_SERIES = 2,
  /** PUBLICATION_TYPE_BROKERAGE - Subscribe to all accounts of a specific brokerage. */
  PUBLICATION_TYPE_BROKERAGE = 3,
  /** PUBLICATION_TYPE_ALL_AUTHORIZED - Subscribe to all accounts this user is authorized for (default). */
  PUBLICATION_TYPE_ALL_AUTHORIZED = 4,
  UNRECOGNIZED = -1,
}

export function tradeSubscription_PublicationTypeFromJSON(object: any): TradeSubscription_PublicationType {
  switch (object) {
    case 1:
    case "PUBLICATION_TYPE_ACCOUNTS":
      return TradeSubscription_PublicationType.PUBLICATION_TYPE_ACCOUNTS;
    case 2:
    case "PUBLICATION_TYPE_SALES_SERIES":
      return TradeSubscription_PublicationType.PUBLICATION_TYPE_SALES_SERIES;
    case 3:
    case "PUBLICATION_TYPE_BROKERAGE":
      return TradeSubscription_PublicationType.PUBLICATION_TYPE_BROKERAGE;
    case 4:
    case "PUBLICATION_TYPE_ALL_AUTHORIZED":
      return TradeSubscription_PublicationType.PUBLICATION_TYPE_ALL_AUTHORIZED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradeSubscription_PublicationType.UNRECOGNIZED;
  }
}

export function tradeSubscription_PublicationTypeToJSON(object: TradeSubscription_PublicationType): string {
  switch (object) {
    case TradeSubscription_PublicationType.PUBLICATION_TYPE_ACCOUNTS:
      return "PUBLICATION_TYPE_ACCOUNTS";
    case TradeSubscription_PublicationType.PUBLICATION_TYPE_SALES_SERIES:
      return "PUBLICATION_TYPE_SALES_SERIES";
    case TradeSubscription_PublicationType.PUBLICATION_TYPE_BROKERAGE:
      return "PUBLICATION_TYPE_BROKERAGE";
    case TradeSubscription_PublicationType.PUBLICATION_TYPE_ALL_AUTHORIZED:
      return "PUBLICATION_TYPE_ALL_AUTHORIZED";
    case TradeSubscription_PublicationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Positions matching algorithm (for position subscription). */
export enum TradeSubscription_MatchingAlgorithm {
  /**
   * MATCHING_ALGORITHM_FIFO - First In, First Out.
   * The first order filled is the first order offset when an order on the opposite side of the market is executed.
   */
  MATCHING_ALGORITHM_FIFO = 1,
  /** MATCHING_ALGORITHM_HBHS - High Buy, High Sell. The highest buy is matched with the highest sell. */
  MATCHING_ALGORITHM_HBHS = 2,
  /**
   * MATCHING_ALGORITHM_LIFO - Last In, First Out.
   * The last order filled is the first order offset when an order on the opposite side of the market is executed.
   */
  MATCHING_ALGORITHM_LIFO = 3,
  UNRECOGNIZED = -1,
}

export function tradeSubscription_MatchingAlgorithmFromJSON(object: any): TradeSubscription_MatchingAlgorithm {
  switch (object) {
    case 1:
    case "MATCHING_ALGORITHM_FIFO":
      return TradeSubscription_MatchingAlgorithm.MATCHING_ALGORITHM_FIFO;
    case 2:
    case "MATCHING_ALGORITHM_HBHS":
      return TradeSubscription_MatchingAlgorithm.MATCHING_ALGORITHM_HBHS;
    case 3:
    case "MATCHING_ALGORITHM_LIFO":
      return TradeSubscription_MatchingAlgorithm.MATCHING_ALGORITHM_LIFO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradeSubscription_MatchingAlgorithm.UNRECOGNIZED;
  }
}

export function tradeSubscription_MatchingAlgorithmToJSON(object: TradeSubscription_MatchingAlgorithm): string {
  switch (object) {
    case TradeSubscription_MatchingAlgorithm.MATCHING_ALGORITHM_FIFO:
      return "MATCHING_ALGORITHM_FIFO";
    case TradeSubscription_MatchingAlgorithm.MATCHING_ALGORITHM_HBHS:
      return "MATCHING_ALGORITHM_HBHS";
    case TradeSubscription_MatchingAlgorithm.MATCHING_ALGORITHM_LIFO:
      return "MATCHING_ALGORITHM_LIFO";
    case TradeSubscription_MatchingAlgorithm.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Server status for a trade subscription.
 * It is sent before or along with a snapshot of initial status information.
 */
export interface TradeSubscriptionStatus {
  /** Subscription Id this result is sent for. */
  id: number;
  /**
   * Subscription result.
   * This field is associated with StatusCode enum type.
   */
  statusCode: number;
  /**
   * Note: use 'details' instead.
   *
   * @deprecated
   */
  textMessage?:
    | string
    | undefined;
  /** Optional details. */
  details?: Text | undefined;
}

/** Possible result codes. */
export enum TradeSubscriptionStatus_StatusCode {
  /** STATUS_CODE_SUCCESS - success codes (0 - 99) */
  STATUS_CODE_SUCCESS = 0,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is [partially] disconnect because of communication issues.
   * NOTE: Clients should not resubscribe in this case, the server will restore subscription with
   * sending SUCCESS status once communication issues are resolved following with all necessary data updates.
   */
  STATUS_CODE_DISCONNECTED = 1,
  /** STATUS_CODE_FAILURE - failure codes (100+) */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The limit of the subscriptions has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 102,
  /** STATUS_CODE_INVALID_PUBLICATION_ID - Unknown or ambiguous account, sales series number, or brokerage id in the subscription. */
  STATUS_CODE_INVALID_PUBLICATION_ID = 103,
  /** STATUS_CODE_SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION - The limit of subscribed accounts has been violated. */
  STATUS_CODE_SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION = 104,
  UNRECOGNIZED = -1,
}

export function tradeSubscriptionStatus_StatusCodeFromJSON(object: any): TradeSubscriptionStatus_StatusCode {
  switch (object) {
    case 0:
    case "STATUS_CODE_SUCCESS":
      return TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS;
    case 1:
    case "STATUS_CODE_DISCONNECTED":
      return TradeSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case "STATUS_CODE_FAILURE":
      return TradeSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION":
      return TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 103:
    case "STATUS_CODE_INVALID_PUBLICATION_ID":
      return TradeSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PUBLICATION_ID;
    case 104:
    case "STATUS_CODE_SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION":
      return TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradeSubscriptionStatus_StatusCode.UNRECOGNIZED;
  }
}

export function tradeSubscriptionStatus_StatusCodeToJSON(object: TradeSubscriptionStatus_StatusCode): string {
  switch (object) {
    case TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS:
      return "STATUS_CODE_SUCCESS";
    case TradeSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED:
      return "STATUS_CODE_DISCONNECTED";
    case TradeSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE:
      return "STATUS_CODE_FAILURE";
    case TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION";
    case TradeSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PUBLICATION_ID:
      return "STATUS_CODE_INVALID_PUBLICATION_ID";
    case TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION:
      return "STATUS_CODE_SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION";
    case TradeSubscriptionStatus_StatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Indicator that a snapshot of requested information is delivered and corresponding data is complete. */
export interface TradeSnapshotCompletion {
  /** ID of a corresponding trade subscription. */
  subscriptionId: number;
  /**
   * List of subscription scopes whose snapshots are completed.
   * Completion message for one scope is sent only once but they can be combined
   * (e.g. one completion message might come for ORDERS and another one later for POSITIONS and COLLATERAL).
   * This field is associated with TradeSubscription.SubscriptionScope enum type.
   */
  subscriptionScopes: number[];
}

/** Parameters for account summary trade subscription. */
export interface AccountSummaryParameters {
  /**
   * Identiers of AccountSummaryStatus fields to be included into response messages.
   * E.g. to get total_margin in AccountSummaryStatus server messages, include value 6 into this collection.
   * Service fields "is_snapshot", "account_id" and "cleared_fields" are always included.
   * At least one field needs to be specified.
   * Server might include more fields in responses than requested (e.g. in case when two trade subscriptions with
   * different list of fields are requested).
   */
  requestedFields: number[];
  /**
   * Account filter. Affects trade subscriptions with publication_type = PUBLICATION_TYPE_ALL_AUTHORIZED.
   * Only accounts that match the filter are included in response messages.
   */
  accountFilter?: AccountFilter | undefined;
}

/**
 * These rules are only applied for the snapshot.
 * See order_2.OrderStatus.is_snapshot.
 */
export interface OrderSnapshotFilter {
  /**
   * Order statuses with these values of OrderStatus.status field are included in a snapshot.
   * This field is associated with shared_1.OrderStatus.Status enum type.
   * If empty, there is no filtration by the field.
   */
  orderStatuses: number[];
  /**
   * Order statuses include transactions that match the filter.
   * If empty, no filtration is done by the filter.
   */
  transactionStatusFilter?:
    | TransactionStatusFilter
    | undefined;
  /**
   * Order statuses with these values of Order.order_type field are included in a snapshot.
   * This field is associated with OrderType enum type.
   * If empty, there is no filtration by the field.
   */
  orderTypes: number[];
}

export interface TransactionStatusFilter {
  /**
   * No transactions are sent.
   * Request is rejected if skip_transactions is true and transaction_statuses field is not empty.
   */
  skipTransactions?:
    | boolean
    | undefined;
  /**
   * Transaction statuses with these values of TransactionStatus.status are sent.
   * If empty, there is no filtration by the field.
   * This field is associated with shared_1.TransactionStatus.Status enum type.
   * Request is rejected if skip_transactions is true and transaction_statuses field is not empty.
   */
  transactionStatuses: number[];
}

/** Account filter. */
export interface AccountFilter {
  /** True if omnibus accounts shall be excluded. */
  excludeOmnibus?:
    | boolean
    | undefined;
  /** True if a group accounts member shall be excluded. */
  excludeGroupMember?:
    | boolean
    | undefined;
  /** True if accounts related to non-derivative brokerages shall be excluded. */
  excludeNonDerivative?: boolean | undefined;
}

/**
 * Status of a contract open positions and purchase and sales for a specific account for the current day
 * (contractId and accountId are used as a key for updates).
 * Contract position is deleted when all open positions and purchase and sales groups are deleted.
 */
export interface PositionStatus {
  /** List of trade subscription IDs this status is related to. */
  subscriptionIds: number[];
  /**
   * True if this is a snapshot related message.
   * Since snapshot might be sent in several messages (including none),
   * client should use TradeSnapshotCompletion message as an indicator of complete snapshot delivery.
   */
  isSnapshot?:
    | boolean
    | undefined;
  /** Account this position belongs to. */
  accountId: number;
  /** Contract ID assigned by server. */
  contractId: number;
  /**
   * True if open positions are short (result of sell operations), long otherwise.
   * In case of separated long and short positions the value is true if the cumulative position is short or flat,
   * the value is false if the cumulative position is long.
   * The attribute is deprecated, refer to OpenPosition.is_short instead.
   *
   * @deprecated
   */
  isShortOpenPosition: boolean;
  /**
   * List of new/ updated or deleted open positions.
   * NOTE: full list is sent only in a snapshot, updates include only added, changed and deleted records.
   */
  openPositions: OpenPosition[];
  /**
   * List of purchase and sales groups. This group represent offset trades (usually one sell and one buy).
   * NOTE: full list is sent only in a snapshot, updates include only added, changed and deleted records.
   */
  purchaseAndSalesGroups: PurchaseAndSalesGroup[];
  /**
   * Multiple OrderStatus and PositionStatus messages in a single ServerMsg can reference the same contract.
   * If the contract is unknown to the client before this ServerMsg,
   * contract meta-data will be added in at least one of them.
   * If in your code you process orders first and positions second then during processing orders you may
   * encounter order with yet unknown contract_id.
   * In this case you should look for matching contract metadata in positions.
   * The opposite is also true: contract metadata for positions can be in order status messages.
   */
  contractMetadata?:
    | ContractMetadata
    | undefined;
  /**
   * Sum of today fill commissions per currency for the contract.
   * Aggregated value from TransactionStatus.fill_commission for current day orders.
   */
  todayFillCommissions: Commission[];
}

/**
 * Account and contract open position.
 * There could be more than one position per account and contract.
 * (id is used as a key for updates).
 */
export interface OpenPosition {
  /** Surrogate id as a key for updates. */
  id: number;
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty?:
    | number
    | undefined;
  /**
   * Position size, zero means that this position is deleted.
   * Note: quantity can be safely compared to zero, because this is an integral number of
   * ContractMetadata.volume_scale units.
   */
  qty?:
    | Decimal
    | undefined;
  /**
   * Position average price.
   * NOTE: Since it could be an aggregated position price is sent in correct format directly.
   */
  priceCorrect: number;
  /** Exchange specific trade date when the position was open or last changed (date only value). */
  tradeDate: number;
  /** Statement date (date value only). */
  statementDate: number;
  /**
   * UTC trade time (including date) if available, it might not be available e.g. for the previous day positions.
   * Note: use trade_utc_timestamp field instead.
   *
   * @deprecated
   */
  tradeUtcTime?:
    | number
    | undefined;
  /** UTC trade time (including date) if available, it might not be available e.g. for the previous day positions. */
  tradeUtcTimestamp?:
    | Date
    | undefined;
  /** True if the price is an aggregated position price. */
  isAggregated: boolean;
  /**
   * True if the open position is short (result of a sell operation), long otherwise.
   * Undefined for deleted position (qty is 0).
   */
  isShort: boolean;
  /**
   * Whether it is a yesterday or a today position.
   * NOTE: where available, this attribute is from the exchange trade date perspective. It is used for
   * position tracking and open/close instructions. It is not the same as previous day (associated
   * with brokerage statement) vs. intraday. It is also not static. For example, an intraday fill
   * with open_close_effect=OPEN will appear, when it is received during the trading session, in an open
   * position or matched trade with is_yesterday=false. After the exchange trade date rolls over for
   * that contract, and before the brokerage statement arrives reflecting it as a previous day position,
   * the same open position or matched trade will contain is_yesterday=true.
   */
  isYesterday?:
    | boolean
    | undefined;
  /** Speculation type of the position. One of SpeculationType enum. */
  speculationType?: number | undefined;
}

/** Purchase and sales group that represents offset trades (usually one sell and one buy trade). */
export interface PurchaseAndSalesGroup {
  /** Surrogate id as a key for updates. */
  id: number;
  /** Profit/ loss (in contract currency) of the group. */
  realizedProfitLoss: number;
  /**
   * List of matched trades in a group.
   * In case of group updates the whole list is sent.
   */
  matchedTrades: MatchedTrade[];
}

/**
 * Specific trade or position that is a part of a purchase and sales group.
 * (id is used as a key for updates).
 */
export interface MatchedTrade {
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty?:
    | number
    | undefined;
  /**
   * Matched size.
   * Zero means matched trade is deleted.
   * Note: quantity can be safely compared to zero, because this is an integral number of
   * ContractMetadata.volume_scale units.
   */
  qty?:
    | Decimal
    | undefined;
  /** True if this is a short trade (e.g. result of sell operation), long otherwise. */
  isShort?:
    | boolean
    | undefined;
  /**
   * Trade or position average price.
   * NOTE: Since it could be an aggregated position price is sent in correct format directly.
   */
  price: number;
  /** Trade date (date value only). */
  tradeDate: number;
  /** Statement date (date value only). */
  statementDate: number;
  /**
   * UTC trade time (including date) if available, it might not be available e.g. for the previous day positions.
   * Note: use trade_utc_timestamp field instead.
   *
   * @deprecated
   */
  tradeUtcTime?:
    | number
    | undefined;
  /** UTC trade time (including date) if available, it might not be available e.g. for the previous day positions. */
  tradeUtcTimestamp?:
    | Date
    | undefined;
  /** True if the price is an aggregated position price. */
  isAggregated: boolean;
  /**
   * Whether the trade is of a yesterday or a today position.
   * NOTE: where available, this attribute is from the exchange trade date perspective. It is used for
   * position tracking and open/close instructions. It is not the same as previous day (associated
   * with brokerage statement) vs. intraday. It is also not static. For example, an intraday fill
   * with open_close_effect=OPEN will appear, when it is received during the trading session, in an open
   * position or matched trade with is_yesterday=false. After the exchange trade date rolls over for
   * that contract, and before the brokerage statement arrives reflecting it as a previous day position,
   * the same open position or matched trade will contain is_yesterday=true.
   */
  isYesterday?:
    | boolean
    | undefined;
  /**
   * Whether the trade closed a new position or opened a new one.
   * Applicable only if ContractMetadata.position_tracking is not POSITION_TRACKING_TYPE_NET_POSITION.
   */
  isClose?:
    | boolean
    | undefined;
  /** Speculation type of the trade. One of SpeculationType enum. */
  speculationType?: number | undefined;
}

/**
 * Status of the collateral for a specific account.
 * An update is sent once it is changed, updates might be consolidated in case of frequent changes.
 */
export interface CollateralStatus {
  /** List of trade subscription IDs this status is related to. */
  subscriptionIds: number[];
  /**
   * True if this is a snapshot related message.
   * Since snapshot might be sent in several messages (including none),
   * client should use TradeSnapshotCompletion message as
   * an indicator of complete snapshot delivery for a particular subscription.
   */
  isSnapshot?:
    | boolean
    | undefined;
  /** Account id of this status. */
  accountId: number;
  /** Currency code of margin and PP values (ISO 4217 based). */
  currency: string;
  /** Margin requirement calculated for worst-case based on open positions and working orders. */
  totalMargin: number;
  /** Margin requirement based on current positions only. */
  positionMargin?:
    | number
    | undefined;
  /**
   * Available account funds including balance, realized profit (or loss), collateral and credits.
   * OTE and MVO are included regarding the account risk parameters.
   * For a group account, purchasing power is a recent snapshot calculated by the server.
   * It uses data from all accounts in the group, so it will not be synchronized with values
   * reported for only this account. Also, for group accounts, OTE and MVO components of
   * purchasing power will not be synchronized with market data updates.
   * See trading_account_2.Account.is_group_member.
   */
  purchasingPower: number;
  /**
   * Open trade equity, or potential profit (or loss) from futures and future-style options positions
   * based on opening price of the position and the current future trade/best bid/best ask
   * (regarding to the risk account settings) or settlement price if trade is not available.
   * Included if applicable.
   */
  ote?:
    | number
    | undefined;
  /**
   * Market value of options calculated as the current market trade/best bid/best ask of the option
   * (regarding to the risk account settings) times the number of options
   * (positive for long options and negative for short options) in the portfolio.
   * Included if applicable.
   */
  mvo?:
    | number
    | undefined;
  /**
   * Market value of futures calculated as the current market trade/best bid/best ask
   * (regarding to the risk account settings) times the number of futures
   * (positive for long and negative for short) in the portfolio.
   * Included if applicable.
   */
  mvf?:
    | number
    | undefined;
  /** Allowable margin credit of the account. */
  marginCredit?:
    | number
    | undefined;
  /** UTC server time (including date) when the status was formed. */
  statusUtcTimestamp?: Date | undefined;
}

/**
 * Account summary status.
 * An update is sent once it is changed. Updates might be consolidated in case of frequent changes.
 * Only changed account summary values are included into updates.
 * Some fields may be not set when a calculation error occurs.
 */
export interface AccountSummaryStatus {
  /** List of trade subscription IDs this status is related to. */
  subscriptionIds: number[];
  /**
   * True if this is a snapshot related message.
   * Since snapshot might be sent in several messages (including none), client should use
   * TradeSnapshotCompletion message as an indicator of complete snapshot delivery for a particular subscription.
   */
  isSnapshot?:
    | boolean
    | undefined;
  /**
   * Account id of this status.
   * It is required field.
   */
  accountId?:
    | number
    | undefined;
  /**
   * Currency code of account values (ISO 4217 based).
   * It is required field in snapshot and included into updates only if changed.
   */
  currency?:
    | string
    | undefined;
  /**
   * Identifiers of fields being cleared.
   * E.g. to clear total_margin server will include value 6 into the collection.
   */
  clearedFields: number[];
  /** Margin requirement calculated for worst-case based on open positions and working orders. */
  totalMargin?:
    | number
    | undefined;
  /** Margin requirement based on current positions only. */
  positionMargin?:
    | number
    | undefined;
  /**
   * Available account funds including balance, realized profit (or loss), collateral and credits.
   * OTE and MVO are included regarding the account risk parameters.
   * For a group account, purchasing power is a recent snapshot calculated by the server.
   * It uses data from all accounts in the group, so it will not be synchronized with values
   * reported for only this account. Also, for group accounts, OTE and MVO components of
   * purchasing power will not be synchronized with market data updates.
   * See trading_account_2.Account.is_group_member.
   */
  purchasingPower?:
    | number
    | undefined;
  /**
   * Open trade equity, or potential profit (or loss) from futures and future-style options positions
   * based on opening price of the position and the current future trade/best bid/best ask
   * (regarding to the risk account settings) or settlement price if trade is not available.
   */
  ote?:
    | number
    | undefined;
  /**
   * Market value of options calculated as the current market trade/best bid/best ask of the option
   * (regarding to the risk account settings) times the number of options
   * (positive for long options and negative for short options) in the portfolio.
   */
  mvo?:
    | number
    | undefined;
  /**
   * Market value of futures calculated as the current market trade/best bid/best ask
   * (regarding to the risk account settings) times the number of futures
   * (positive for long and negative for short) in the portfolio.
   */
  mvf?:
    | number
    | undefined;
  /** Allowable margin credit of the account. */
  marginCredit?:
    | number
    | undefined;
  /** Cash Excess. */
  cashExcess?:
    | number
    | undefined;
  /**
   * Current account's balance. In particular includes: yesterday balance, profit/loss, option premium,
   * commission and Forex instrument positions.
   */
  currentBalance?:
    | number
    | undefined;
  /** Realized profit/loss. */
  profitLoss?:
    | number
    | undefined;
  /** Unrealized profit/loss for options. */
  unrealizedProfitLoss?:
    | number
    | undefined;
  /** Cash balance from the last statement. */
  yesterdayBalance?:
    | number
    | undefined;
  /** Open trade equity for futures and futures-style options from the last statement. */
  yesterdayOte?:
    | number
    | undefined;
  /** Market value of premium-style options and fixed income from the last statement. */
  yesterdayMvo?:
    | number
    | undefined;
  /** Collateral on deposit. */
  yesterdayCollateral?:
    | number
    | undefined;
  /** (profit_loss / abs(yesterday_balance)) in percentage. */
  netChangePc?:
    | number
    | undefined;
  /** Sum of all fill sizes for the current day. */
  totalFilledQty?:
    | Decimal
    | undefined;
  /** Count of filled orders for the current day. */
  totalFilledOrders?:
    | number
    | undefined;
  /**
   * Count of parked orders on the account.
   * Note: strategy orders and their child orders are counted,
   *       except for child orders of algo strategies.
   */
  totalParkedOrders?:
    | number
    | undefined;
  /**
   * Count of working orders on the account.
   * Note: strategy orders and their child orders are counted,
   *       except for child orders of algo strategies.
   */
  totalWorkingOrders?:
    | number
    | undefined;
  /** Sum of position quantities among all long open positions on the account. */
  longOpenPositionsQty?:
    | Decimal
    | undefined;
  /** Sum of position quantities among all short open positions on the account. */
  shortOpenPositionsQty?:
    | Decimal
    | undefined;
  /**
   * Minimal value of days till contract expiration (in calendar days, not trading) among
   * all open positions on contracts with expiration date on the account.
   * Not set if there are no open positions on the account.
   */
  minDaysTillPositionContractExpiration?:
    | number
    | undefined;
  /**
   * Limit of the maximum value of purchasing power for the account.
   * Can be empty e.g. when the account is a group account member.
   * See trading_account_2.Account.is_group_member.
   */
  purchasingPowerLimit?: number | undefined;
}

/** Historical orders request. */
export interface HistoricalOrdersRequest {
  /**
   * Only orders from specified business date (inclusively) are to be returned
   * (date only value in time format).
   * Working and parked orders of the current day are returned despite the
   * from_date, to_date values.
   * If order was not cleared for several days, it will be present in
   * a report if one of those days hits the requested range. E.g., order was
   * filled on Friday, statement happened on Monday, if request is done for
   * a Saturday, order will be present in the report.
   * Note: Starting date must not be more than 30 days back from now.
   */
  fromDate: number;
  /**
   * Only orders till specified business date (inclusively) is to be returned (date only value in time format).
   * Current business day if the field is omitted.
   */
  toDate?:
    | number
    | undefined;
  /** Filter orders by account. It is required to leave account_ids list empty to select all accounts of the user. */
  accountIds: number[];
  /**
   * If this field set to true, statuses on orders with ORDER_TYPE_CROSS type will be
   * included into responses. See CrossOrderParameters message.
   * Note that order statuses with cross order type are excluded only if
   * include_cross_orders set to false (or empty) and ORDER_TYPE_CROSS type is not included
   * into order_snapshot_filter.order_types.
   */
  includeCrossOrders?:
    | boolean
    | undefined;
  /**
   * Only order statuses, transaction statuses that match the filter are included in a report.
   * By default no filtration is done by the filter.
   */
  orderSnapshotFilter?:
    | OrderSnapshotFilter
    | undefined;
  /**
   * No more than specified number of order statuses will be returned.
   * Zero or omitted means no limit.
   * If there are more order statuses than specified, HistoricalOrdersReport.order_status_limit_reached is set in true
   * in last report for the request.
   */
  maxOrderStatusCount?:
    | number
    | undefined;
  /**
   * No more than specified number of transaction statuses within all order statuses will be returned.
   * Zero or omitted means no limit.
   * If there are more transaction statuses than specified, HistoricalOrdersReport.transaction_status_limit_reached
   * is set in true in last report for the request.
   * Note: Limitation is aligned on order status. So, if next order status transaction count leads to limit violation
   * whole order status is not included into the report.
   */
  maxTransactionStatusCount?:
    | number
    | undefined;
  /**
   * If this field set (to the original order_id, not any modifies), only child orders associated with that parent
   * order will be returned. Results do not include the parent order itself. See OrderStatus.chain_order_id.
   * To get algo strategy child orders, set HistoricalOrdersRequest.include_algo_strategy_child_orders to true.
   */
  parentChainOrderId?:
    | string
    | undefined;
  /**
   * If this field set to true, child orders of algo strategies will be included
   * into responses.
   * See TradeSubscription.include_algo_strategy_child_orders.
   */
  includeAlgoStrategyChildOrders?: boolean | undefined;
}

/** Response for historical orders request. */
export interface HistoricalOrdersReport {
  /** List of order statuses matching historical order filter. */
  orderStatuses: OrderStatus[];
  /**
   * Indicator that not all order statuses are returned.
   * Limit by HistoricalOrdersRequest.max_order_status_count is reached.
   */
  orderStatusLimitReached?:
    | boolean
    | undefined;
  /**
   * Indicator that not all order statuses are returned.
   * Limit by HistoricalOrdersRequest.max_transaction_status_count is reached.
   */
  transactionStatusLimitReached?: boolean | undefined;
}

/** Request for trading feature entitlements. */
export interface BrokerageTradingFeatureEntitlementRequest {
  /**
   * Account ID in CQG trade routing system.
   * If no account supplied, data is returned for ALL accounts trader is authorized on.
   */
  accountIds: number[];
}

/** Report with trading feature entitlements. */
export interface BrokerageTradingFeatureEntitlementReport {
  /** Set of trading feature entitlements. */
  tradingFeatureEntitlements: TradingFeatureEntitlementEntry[];
}

/**
 * Account/brokerage-specific trading feature entitlement.
 * For future compatibility unknown entitlements should be ignored.
 */
export interface TradingFeatureEntitlementEntry {
  /** Account ID in CQG trade routing system that corresponds to trading feature entitlement. */
  accountId?:
    | number
    | undefined;
  /**
   * Trading feature entitlement.
   * This field is associated with TradingFeatureEntitlement enum type.
   */
  entitlement?: number | undefined;
}

/** Provides care auto activation parameters for the user. */
export interface CareAutoActivationParametersRequest {
}

/** In case of an update the report contains full list of available parameters. */
export interface CareAutoActivationParametersReport {
  /** Care auto activation parameters for the user. */
  parameters: CareAutoActivationParameters[];
}

export interface CareAutoActivationParameters {
  /** True if the user is authorized for auto activation care order for the brokerage. */
  isCurrentUserResponsibleForAutoActivation?:
    | boolean
    | undefined;
  /** Brokerage ID. */
  brokerageId?:
    | number
    | undefined;
  /**
   * Max order quantity allowed for Care Auto Activation.
   * Uninitialized value means no limit and auto activate orders of any size.
   */
  maxOrderQty?: number | undefined;
}

function createBaseTradeSubscription(): TradeSubscription {
  return {
    id: 0,
    subscriptionScopes: [],
    publicationType: 0,
    accountIds: [],
    salesSeriesNumber: "",
    brokerageId: 0,
    subscribe: false,
    lastOrderUpdateUtcTime: 0,
    lastOrderUpdateUtcTimestamp: undefined,
    skipOrdersSnapshot: false,
    confirmedPositionsOnly: false,
    matchingAlgorithm: 0,
    matchIntradayFirst: false,
    historicalMatchingAlgorithm: 0,
    includeCrossOrders: false,
    includeAlgoStrategyChildOrders: false,
    orderSnapshotFilter: undefined,
    accountSummaryParameters: undefined,
  };
}

export const TradeSubscription = {
  encode(message: TradeSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    writer.uint32(18).fork();
    for (const v of message.subscriptionScopes) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.publicationType !== undefined && message.publicationType !== 0) {
      writer.uint32(24).uint32(message.publicationType);
    }
    writer.uint32(34).fork();
    for (const v of message.accountIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      writer.uint32(42).string(message.salesSeriesNumber);
    }
    if (message.brokerageId !== undefined && message.brokerageId !== 0) {
      writer.uint32(48).uint32(message.brokerageId);
    }
    if (message.subscribe !== false) {
      writer.uint32(56).bool(message.subscribe);
    }
    if (message.lastOrderUpdateUtcTime !== undefined && message.lastOrderUpdateUtcTime !== 0) {
      writer.uint32(64).sint64(message.lastOrderUpdateUtcTime);
    }
    if (message.lastOrderUpdateUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.lastOrderUpdateUtcTimestamp), writer.uint32(114).fork()).ldelim();
    }
    if (message.skipOrdersSnapshot !== undefined && message.skipOrdersSnapshot !== false) {
      writer.uint32(72).bool(message.skipOrdersSnapshot);
    }
    if (message.confirmedPositionsOnly !== undefined && message.confirmedPositionsOnly !== false) {
      writer.uint32(80).bool(message.confirmedPositionsOnly);
    }
    if (message.matchingAlgorithm !== undefined && message.matchingAlgorithm !== 0) {
      writer.uint32(88).uint32(message.matchingAlgorithm);
    }
    if (message.matchIntradayFirst !== undefined && message.matchIntradayFirst !== false) {
      writer.uint32(96).bool(message.matchIntradayFirst);
    }
    if (message.historicalMatchingAlgorithm !== undefined && message.historicalMatchingAlgorithm !== 0) {
      writer.uint32(104).uint32(message.historicalMatchingAlgorithm);
    }
    if (message.includeCrossOrders !== undefined && message.includeCrossOrders !== false) {
      writer.uint32(120).bool(message.includeCrossOrders);
    }
    if (message.includeAlgoStrategyChildOrders !== undefined && message.includeAlgoStrategyChildOrders !== false) {
      writer.uint32(144).bool(message.includeAlgoStrategyChildOrders);
    }
    if (message.orderSnapshotFilter !== undefined) {
      OrderSnapshotFilter.encode(message.orderSnapshotFilter, writer.uint32(130).fork()).ldelim();
    }
    if (message.accountSummaryParameters !== undefined) {
      AccountSummaryParameters.encode(message.accountSummaryParameters, writer.uint32(138).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradeSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradeSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag === 16) {
            message.subscriptionScopes.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.subscriptionScopes.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.publicationType = reader.uint32();
          continue;
        case 4:
          if (tag === 32) {
            message.accountIds.push(reader.uint32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.accountIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.salesSeriesNumber = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.brokerageId = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.subscribe = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.lastOrderUpdateUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.lastOrderUpdateUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.skipOrdersSnapshot = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.confirmedPositionsOnly = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.matchingAlgorithm = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.matchIntradayFirst = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.historicalMatchingAlgorithm = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.includeCrossOrders = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.includeAlgoStrategyChildOrders = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.orderSnapshotFilter = OrderSnapshotFilter.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.accountSummaryParameters = AccountSummaryParameters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradeSubscription {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      subscriptionScopes: globalThis.Array.isArray(object?.subscriptionScopes)
        ? object.subscriptionScopes.map((e: any) => globalThis.Number(e))
        : [],
      publicationType: isSet(object.publicationType) ? globalThis.Number(object.publicationType) : 0,
      accountIds: globalThis.Array.isArray(object?.accountIds)
        ? object.accountIds.map((e: any) => globalThis.Number(e))
        : [],
      salesSeriesNumber: isSet(object.salesSeriesNumber) ? globalThis.String(object.salesSeriesNumber) : "",
      brokerageId: isSet(object.brokerageId) ? globalThis.Number(object.brokerageId) : 0,
      subscribe: isSet(object.subscribe) ? globalThis.Boolean(object.subscribe) : false,
      lastOrderUpdateUtcTime: isSet(object.lastOrderUpdateUtcTime)
        ? globalThis.Number(object.lastOrderUpdateUtcTime)
        : 0,
      lastOrderUpdateUtcTimestamp: isSet(object.lastOrderUpdateUtcTimestamp)
        ? fromJsonTimestamp(object.lastOrderUpdateUtcTimestamp)
        : undefined,
      skipOrdersSnapshot: isSet(object.skipOrdersSnapshot) ? globalThis.Boolean(object.skipOrdersSnapshot) : false,
      confirmedPositionsOnly: isSet(object.confirmedPositionsOnly)
        ? globalThis.Boolean(object.confirmedPositionsOnly)
        : false,
      matchingAlgorithm: isSet(object.matchingAlgorithm) ? globalThis.Number(object.matchingAlgorithm) : 0,
      matchIntradayFirst: isSet(object.matchIntradayFirst) ? globalThis.Boolean(object.matchIntradayFirst) : false,
      historicalMatchingAlgorithm: isSet(object.historicalMatchingAlgorithm)
        ? globalThis.Number(object.historicalMatchingAlgorithm)
        : 0,
      includeCrossOrders: isSet(object.includeCrossOrders) ? globalThis.Boolean(object.includeCrossOrders) : false,
      includeAlgoStrategyChildOrders: isSet(object.includeAlgoStrategyChildOrders)
        ? globalThis.Boolean(object.includeAlgoStrategyChildOrders)
        : false,
      orderSnapshotFilter: isSet(object.orderSnapshotFilter)
        ? OrderSnapshotFilter.fromJSON(object.orderSnapshotFilter)
        : undefined,
      accountSummaryParameters: isSet(object.accountSummaryParameters)
        ? AccountSummaryParameters.fromJSON(object.accountSummaryParameters)
        : undefined,
    };
  },

  toJSON(message: TradeSubscription): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.subscriptionScopes?.length) {
      obj.subscriptionScopes = message.subscriptionScopes.map((e) => Math.round(e));
    }
    if (message.publicationType !== undefined && message.publicationType !== 0) {
      obj.publicationType = Math.round(message.publicationType);
    }
    if (message.accountIds?.length) {
      obj.accountIds = message.accountIds.map((e) => Math.round(e));
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      obj.salesSeriesNumber = message.salesSeriesNumber;
    }
    if (message.brokerageId !== undefined && message.brokerageId !== 0) {
      obj.brokerageId = Math.round(message.brokerageId);
    }
    if (message.subscribe !== false) {
      obj.subscribe = message.subscribe;
    }
    if (message.lastOrderUpdateUtcTime !== undefined && message.lastOrderUpdateUtcTime !== 0) {
      obj.lastOrderUpdateUtcTime = Math.round(message.lastOrderUpdateUtcTime);
    }
    if (message.lastOrderUpdateUtcTimestamp !== undefined) {
      obj.lastOrderUpdateUtcTimestamp = message.lastOrderUpdateUtcTimestamp.toISOString();
    }
    if (message.skipOrdersSnapshot !== undefined && message.skipOrdersSnapshot !== false) {
      obj.skipOrdersSnapshot = message.skipOrdersSnapshot;
    }
    if (message.confirmedPositionsOnly !== undefined && message.confirmedPositionsOnly !== false) {
      obj.confirmedPositionsOnly = message.confirmedPositionsOnly;
    }
    if (message.matchingAlgorithm !== undefined && message.matchingAlgorithm !== 0) {
      obj.matchingAlgorithm = Math.round(message.matchingAlgorithm);
    }
    if (message.matchIntradayFirst !== undefined && message.matchIntradayFirst !== false) {
      obj.matchIntradayFirst = message.matchIntradayFirst;
    }
    if (message.historicalMatchingAlgorithm !== undefined && message.historicalMatchingAlgorithm !== 0) {
      obj.historicalMatchingAlgorithm = Math.round(message.historicalMatchingAlgorithm);
    }
    if (message.includeCrossOrders !== undefined && message.includeCrossOrders !== false) {
      obj.includeCrossOrders = message.includeCrossOrders;
    }
    if (message.includeAlgoStrategyChildOrders !== undefined && message.includeAlgoStrategyChildOrders !== false) {
      obj.includeAlgoStrategyChildOrders = message.includeAlgoStrategyChildOrders;
    }
    if (message.orderSnapshotFilter !== undefined) {
      obj.orderSnapshotFilter = OrderSnapshotFilter.toJSON(message.orderSnapshotFilter);
    }
    if (message.accountSummaryParameters !== undefined) {
      obj.accountSummaryParameters = AccountSummaryParameters.toJSON(message.accountSummaryParameters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeSubscription>, I>>(base?: I): TradeSubscription {
    return TradeSubscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradeSubscription>, I>>(object: I): TradeSubscription {
    const message = createBaseTradeSubscription();
    message.id = object.id ?? 0;
    message.subscriptionScopes = object.subscriptionScopes?.map((e) => e) || [];
    message.publicationType = object.publicationType ?? 0;
    message.accountIds = object.accountIds?.map((e) => e) || [];
    message.salesSeriesNumber = object.salesSeriesNumber ?? "";
    message.brokerageId = object.brokerageId ?? 0;
    message.subscribe = object.subscribe ?? false;
    message.lastOrderUpdateUtcTime = object.lastOrderUpdateUtcTime ?? 0;
    message.lastOrderUpdateUtcTimestamp = object.lastOrderUpdateUtcTimestamp ?? undefined;
    message.skipOrdersSnapshot = object.skipOrdersSnapshot ?? false;
    message.confirmedPositionsOnly = object.confirmedPositionsOnly ?? false;
    message.matchingAlgorithm = object.matchingAlgorithm ?? 0;
    message.matchIntradayFirst = object.matchIntradayFirst ?? false;
    message.historicalMatchingAlgorithm = object.historicalMatchingAlgorithm ?? 0;
    message.includeCrossOrders = object.includeCrossOrders ?? false;
    message.includeAlgoStrategyChildOrders = object.includeAlgoStrategyChildOrders ?? false;
    message.orderSnapshotFilter = (object.orderSnapshotFilter !== undefined && object.orderSnapshotFilter !== null)
      ? OrderSnapshotFilter.fromPartial(object.orderSnapshotFilter)
      : undefined;
    message.accountSummaryParameters =
      (object.accountSummaryParameters !== undefined && object.accountSummaryParameters !== null)
        ? AccountSummaryParameters.fromPartial(object.accountSummaryParameters)
        : undefined;
    return message;
  },
};

function createBaseTradeSubscriptionStatus(): TradeSubscriptionStatus {
  return { id: 0, statusCode: 0, textMessage: "", details: undefined };
}

export const TradeSubscriptionStatus = {
  encode(message: TradeSubscriptionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(26).string(message.textMessage);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradeSubscriptionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradeSubscriptionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.statusCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.details = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradeSubscriptionStatus {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      statusCode: isSet(object.statusCode) ? globalThis.Number(object.statusCode) : 0,
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: TradeSubscriptionStatus): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.statusCode !== 0) {
      obj.statusCode = Math.round(message.statusCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.details !== undefined) {
      obj.details = Text.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeSubscriptionStatus>, I>>(base?: I): TradeSubscriptionStatus {
    return TradeSubscriptionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradeSubscriptionStatus>, I>>(object: I): TradeSubscriptionStatus {
    const message = createBaseTradeSubscriptionStatus();
    message.id = object.id ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.textMessage = object.textMessage ?? "";
    message.details = (object.details !== undefined && object.details !== null)
      ? Text.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseTradeSnapshotCompletion(): TradeSnapshotCompletion {
  return { subscriptionId: 0, subscriptionScopes: [] };
}

export const TradeSnapshotCompletion = {
  encode(message: TradeSnapshotCompletion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subscriptionId !== 0) {
      writer.uint32(8).uint32(message.subscriptionId);
    }
    writer.uint32(18).fork();
    for (const v of message.subscriptionScopes) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradeSnapshotCompletion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradeSnapshotCompletion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.subscriptionId = reader.uint32();
          continue;
        case 2:
          if (tag === 16) {
            message.subscriptionScopes.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.subscriptionScopes.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradeSnapshotCompletion {
    return {
      subscriptionId: isSet(object.subscriptionId) ? globalThis.Number(object.subscriptionId) : 0,
      subscriptionScopes: globalThis.Array.isArray(object?.subscriptionScopes)
        ? object.subscriptionScopes.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: TradeSnapshotCompletion): unknown {
    const obj: any = {};
    if (message.subscriptionId !== 0) {
      obj.subscriptionId = Math.round(message.subscriptionId);
    }
    if (message.subscriptionScopes?.length) {
      obj.subscriptionScopes = message.subscriptionScopes.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeSnapshotCompletion>, I>>(base?: I): TradeSnapshotCompletion {
    return TradeSnapshotCompletion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradeSnapshotCompletion>, I>>(object: I): TradeSnapshotCompletion {
    const message = createBaseTradeSnapshotCompletion();
    message.subscriptionId = object.subscriptionId ?? 0;
    message.subscriptionScopes = object.subscriptionScopes?.map((e) => e) || [];
    return message;
  },
};

function createBaseAccountSummaryParameters(): AccountSummaryParameters {
  return { requestedFields: [], accountFilter: undefined };
}

export const AccountSummaryParameters = {
  encode(message: AccountSummaryParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.requestedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.accountFilter !== undefined) {
      AccountFilter.encode(message.accountFilter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSummaryParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSummaryParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.requestedFields.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.requestedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountFilter = AccountFilter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSummaryParameters {
    return {
      requestedFields: globalThis.Array.isArray(object?.requestedFields)
        ? object.requestedFields.map((e: any) => globalThis.Number(e))
        : [],
      accountFilter: isSet(object.accountFilter) ? AccountFilter.fromJSON(object.accountFilter) : undefined,
    };
  },

  toJSON(message: AccountSummaryParameters): unknown {
    const obj: any = {};
    if (message.requestedFields?.length) {
      obj.requestedFields = message.requestedFields.map((e) => Math.round(e));
    }
    if (message.accountFilter !== undefined) {
      obj.accountFilter = AccountFilter.toJSON(message.accountFilter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSummaryParameters>, I>>(base?: I): AccountSummaryParameters {
    return AccountSummaryParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSummaryParameters>, I>>(object: I): AccountSummaryParameters {
    const message = createBaseAccountSummaryParameters();
    message.requestedFields = object.requestedFields?.map((e) => e) || [];
    message.accountFilter = (object.accountFilter !== undefined && object.accountFilter !== null)
      ? AccountFilter.fromPartial(object.accountFilter)
      : undefined;
    return message;
  },
};

function createBaseOrderSnapshotFilter(): OrderSnapshotFilter {
  return { orderStatuses: [], transactionStatusFilter: undefined, orderTypes: [] };
}

export const OrderSnapshotFilter = {
  encode(message: OrderSnapshotFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.orderStatuses) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.transactionStatusFilter !== undefined) {
      TransactionStatusFilter.encode(message.transactionStatusFilter, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.orderTypes) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderSnapshotFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderSnapshotFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.orderStatuses.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.orderStatuses.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionStatusFilter = TransactionStatusFilter.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.orderTypes.push(reader.uint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.orderTypes.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderSnapshotFilter {
    return {
      orderStatuses: globalThis.Array.isArray(object?.orderStatuses)
        ? object.orderStatuses.map((e: any) => globalThis.Number(e))
        : [],
      transactionStatusFilter: isSet(object.transactionStatusFilter)
        ? TransactionStatusFilter.fromJSON(object.transactionStatusFilter)
        : undefined,
      orderTypes: globalThis.Array.isArray(object?.orderTypes)
        ? object.orderTypes.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: OrderSnapshotFilter): unknown {
    const obj: any = {};
    if (message.orderStatuses?.length) {
      obj.orderStatuses = message.orderStatuses.map((e) => Math.round(e));
    }
    if (message.transactionStatusFilter !== undefined) {
      obj.transactionStatusFilter = TransactionStatusFilter.toJSON(message.transactionStatusFilter);
    }
    if (message.orderTypes?.length) {
      obj.orderTypes = message.orderTypes.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderSnapshotFilter>, I>>(base?: I): OrderSnapshotFilter {
    return OrderSnapshotFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderSnapshotFilter>, I>>(object: I): OrderSnapshotFilter {
    const message = createBaseOrderSnapshotFilter();
    message.orderStatuses = object.orderStatuses?.map((e) => e) || [];
    message.transactionStatusFilter =
      (object.transactionStatusFilter !== undefined && object.transactionStatusFilter !== null)
        ? TransactionStatusFilter.fromPartial(object.transactionStatusFilter)
        : undefined;
    message.orderTypes = object.orderTypes?.map((e) => e) || [];
    return message;
  },
};

function createBaseTransactionStatusFilter(): TransactionStatusFilter {
  return { skipTransactions: false, transactionStatuses: [] };
}

export const TransactionStatusFilter = {
  encode(message: TransactionStatusFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.skipTransactions !== undefined && message.skipTransactions !== false) {
      writer.uint32(8).bool(message.skipTransactions);
    }
    writer.uint32(18).fork();
    for (const v of message.transactionStatuses) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionStatusFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionStatusFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.skipTransactions = reader.bool();
          continue;
        case 2:
          if (tag === 16) {
            message.transactionStatuses.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.transactionStatuses.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionStatusFilter {
    return {
      skipTransactions: isSet(object.skipTransactions) ? globalThis.Boolean(object.skipTransactions) : false,
      transactionStatuses: globalThis.Array.isArray(object?.transactionStatuses)
        ? object.transactionStatuses.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: TransactionStatusFilter): unknown {
    const obj: any = {};
    if (message.skipTransactions !== undefined && message.skipTransactions !== false) {
      obj.skipTransactions = message.skipTransactions;
    }
    if (message.transactionStatuses?.length) {
      obj.transactionStatuses = message.transactionStatuses.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TransactionStatusFilter>, I>>(base?: I): TransactionStatusFilter {
    return TransactionStatusFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionStatusFilter>, I>>(object: I): TransactionStatusFilter {
    const message = createBaseTransactionStatusFilter();
    message.skipTransactions = object.skipTransactions ?? false;
    message.transactionStatuses = object.transactionStatuses?.map((e) => e) || [];
    return message;
  },
};

function createBaseAccountFilter(): AccountFilter {
  return { excludeOmnibus: false, excludeGroupMember: false, excludeNonDerivative: false };
}

export const AccountFilter = {
  encode(message: AccountFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.excludeOmnibus !== undefined && message.excludeOmnibus !== false) {
      writer.uint32(8).bool(message.excludeOmnibus);
    }
    if (message.excludeGroupMember !== undefined && message.excludeGroupMember !== false) {
      writer.uint32(16).bool(message.excludeGroupMember);
    }
    if (message.excludeNonDerivative !== undefined && message.excludeNonDerivative !== false) {
      writer.uint32(24).bool(message.excludeNonDerivative);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.excludeOmnibus = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.excludeGroupMember = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.excludeNonDerivative = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountFilter {
    return {
      excludeOmnibus: isSet(object.excludeOmnibus) ? globalThis.Boolean(object.excludeOmnibus) : false,
      excludeGroupMember: isSet(object.excludeGroupMember) ? globalThis.Boolean(object.excludeGroupMember) : false,
      excludeNonDerivative: isSet(object.excludeNonDerivative)
        ? globalThis.Boolean(object.excludeNonDerivative)
        : false,
    };
  },

  toJSON(message: AccountFilter): unknown {
    const obj: any = {};
    if (message.excludeOmnibus !== undefined && message.excludeOmnibus !== false) {
      obj.excludeOmnibus = message.excludeOmnibus;
    }
    if (message.excludeGroupMember !== undefined && message.excludeGroupMember !== false) {
      obj.excludeGroupMember = message.excludeGroupMember;
    }
    if (message.excludeNonDerivative !== undefined && message.excludeNonDerivative !== false) {
      obj.excludeNonDerivative = message.excludeNonDerivative;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountFilter>, I>>(base?: I): AccountFilter {
    return AccountFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountFilter>, I>>(object: I): AccountFilter {
    const message = createBaseAccountFilter();
    message.excludeOmnibus = object.excludeOmnibus ?? false;
    message.excludeGroupMember = object.excludeGroupMember ?? false;
    message.excludeNonDerivative = object.excludeNonDerivative ?? false;
    return message;
  },
};

function createBasePositionStatus(): PositionStatus {
  return {
    subscriptionIds: [],
    isSnapshot: false,
    accountId: 0,
    contractId: 0,
    isShortOpenPosition: false,
    openPositions: [],
    purchaseAndSalesGroups: [],
    contractMetadata: undefined,
    todayFillCommissions: [],
  };
}

export const PositionStatus = {
  encode(message: PositionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.subscriptionIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      writer.uint32(16).bool(message.isSnapshot);
    }
    if (message.accountId !== 0) {
      writer.uint32(24).sint32(message.accountId);
    }
    if (message.contractId !== 0) {
      writer.uint32(32).uint32(message.contractId);
    }
    if (message.isShortOpenPosition !== false) {
      writer.uint32(40).bool(message.isShortOpenPosition);
    }
    for (const v of message.openPositions) {
      OpenPosition.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.purchaseAndSalesGroups) {
      PurchaseAndSalesGroup.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.todayFillCommissions) {
      Commission.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.subscriptionIds.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.subscriptionIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSnapshot = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isShortOpenPosition = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.openPositions.push(OpenPosition.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.purchaseAndSalesGroups.push(PurchaseAndSalesGroup.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.todayFillCommissions.push(Commission.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionStatus {
    return {
      subscriptionIds: globalThis.Array.isArray(object?.subscriptionIds)
        ? object.subscriptionIds.map((e: any) => globalThis.Number(e))
        : [],
      isSnapshot: isSet(object.isSnapshot) ? globalThis.Boolean(object.isSnapshot) : false,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      isShortOpenPosition: isSet(object.isShortOpenPosition) ? globalThis.Boolean(object.isShortOpenPosition) : false,
      openPositions: globalThis.Array.isArray(object?.openPositions)
        ? object.openPositions.map((e: any) => OpenPosition.fromJSON(e))
        : [],
      purchaseAndSalesGroups: globalThis.Array.isArray(object?.purchaseAndSalesGroups)
        ? object.purchaseAndSalesGroups.map((e: any) => PurchaseAndSalesGroup.fromJSON(e))
        : [],
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
      todayFillCommissions: globalThis.Array.isArray(object?.todayFillCommissions)
        ? object.todayFillCommissions.map((e: any) => Commission.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PositionStatus): unknown {
    const obj: any = {};
    if (message.subscriptionIds?.length) {
      obj.subscriptionIds = message.subscriptionIds.map((e) => Math.round(e));
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      obj.isSnapshot = message.isSnapshot;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.isShortOpenPosition !== false) {
      obj.isShortOpenPosition = message.isShortOpenPosition;
    }
    if (message.openPositions?.length) {
      obj.openPositions = message.openPositions.map((e) => OpenPosition.toJSON(e));
    }
    if (message.purchaseAndSalesGroups?.length) {
      obj.purchaseAndSalesGroups = message.purchaseAndSalesGroups.map((e) => PurchaseAndSalesGroup.toJSON(e));
    }
    if (message.contractMetadata !== undefined) {
      obj.contractMetadata = ContractMetadata.toJSON(message.contractMetadata);
    }
    if (message.todayFillCommissions?.length) {
      obj.todayFillCommissions = message.todayFillCommissions.map((e) => Commission.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionStatus>, I>>(base?: I): PositionStatus {
    return PositionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionStatus>, I>>(object: I): PositionStatus {
    const message = createBasePositionStatus();
    message.subscriptionIds = object.subscriptionIds?.map((e) => e) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    message.isShortOpenPosition = object.isShortOpenPosition ?? false;
    message.openPositions = object.openPositions?.map((e) => OpenPosition.fromPartial(e)) || [];
    message.purchaseAndSalesGroups = object.purchaseAndSalesGroups?.map((e) => PurchaseAndSalesGroup.fromPartial(e)) ||
      [];
    message.contractMetadata = (object.contractMetadata !== undefined && object.contractMetadata !== null)
      ? ContractMetadata.fromPartial(object.contractMetadata)
      : undefined;
    message.todayFillCommissions = object.todayFillCommissions?.map((e) => Commission.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOpenPosition(): OpenPosition {
  return {
    id: 0,
    uint32Qty: 0,
    qty: undefined,
    priceCorrect: 0,
    tradeDate: 0,
    statementDate: 0,
    tradeUtcTime: 0,
    tradeUtcTimestamp: undefined,
    isAggregated: false,
    isShort: false,
    isYesterday: false,
    speculationType: 0,
  };
}

export const OpenPosition = {
  encode(message: OpenPosition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      writer.uint32(16).uint32(message.uint32Qty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(98).fork()).ldelim();
    }
    if (message.priceCorrect !== 0) {
      writer.uint32(25).double(message.priceCorrect);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(32).sint64(message.tradeDate);
    }
    if (message.statementDate !== 0) {
      writer.uint32(40).sint64(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      writer.uint32(48).sint64(message.tradeUtcTime);
    }
    if (message.tradeUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.tradeUtcTimestamp), writer.uint32(66).fork()).ldelim();
    }
    if (message.isAggregated !== false) {
      writer.uint32(56).bool(message.isAggregated);
    }
    if (message.isShort !== false) {
      writer.uint32(72).bool(message.isShort);
    }
    if (message.isYesterday !== undefined && message.isYesterday !== false) {
      writer.uint32(80).bool(message.isYesterday);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(88).uint32(message.speculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpenPosition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.uint32Qty = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.priceCorrect = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tradeDate = longToNumber(reader.sint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.statementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.tradeUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tradeUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isAggregated = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isShort = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isYesterday = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenPosition {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      uint32Qty: isSet(object.uint32Qty) ? globalThis.Number(object.uint32Qty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      priceCorrect: isSet(object.priceCorrect) ? globalThis.Number(object.priceCorrect) : 0,
      tradeDate: isSet(object.tradeDate) ? globalThis.Number(object.tradeDate) : 0,
      statementDate: isSet(object.statementDate) ? globalThis.Number(object.statementDate) : 0,
      tradeUtcTime: isSet(object.tradeUtcTime) ? globalThis.Number(object.tradeUtcTime) : 0,
      tradeUtcTimestamp: isSet(object.tradeUtcTimestamp) ? fromJsonTimestamp(object.tradeUtcTimestamp) : undefined,
      isAggregated: isSet(object.isAggregated) ? globalThis.Boolean(object.isAggregated) : false,
      isShort: isSet(object.isShort) ? globalThis.Boolean(object.isShort) : false,
      isYesterday: isSet(object.isYesterday) ? globalThis.Boolean(object.isYesterday) : false,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
    };
  },

  toJSON(message: OpenPosition): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      obj.uint32Qty = Math.round(message.uint32Qty);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.priceCorrect !== 0) {
      obj.priceCorrect = message.priceCorrect;
    }
    if (message.tradeDate !== 0) {
      obj.tradeDate = Math.round(message.tradeDate);
    }
    if (message.statementDate !== 0) {
      obj.statementDate = Math.round(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      obj.tradeUtcTime = Math.round(message.tradeUtcTime);
    }
    if (message.tradeUtcTimestamp !== undefined) {
      obj.tradeUtcTimestamp = message.tradeUtcTimestamp.toISOString();
    }
    if (message.isAggregated !== false) {
      obj.isAggregated = message.isAggregated;
    }
    if (message.isShort !== false) {
      obj.isShort = message.isShort;
    }
    if (message.isYesterday !== undefined && message.isYesterday !== false) {
      obj.isYesterday = message.isYesterday;
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenPosition>, I>>(base?: I): OpenPosition {
    return OpenPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpenPosition>, I>>(object: I): OpenPosition {
    const message = createBaseOpenPosition();
    message.id = object.id ?? 0;
    message.uint32Qty = object.uint32Qty ?? 0;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.priceCorrect = object.priceCorrect ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.statementDate = object.statementDate ?? 0;
    message.tradeUtcTime = object.tradeUtcTime ?? 0;
    message.tradeUtcTimestamp = object.tradeUtcTimestamp ?? undefined;
    message.isAggregated = object.isAggregated ?? false;
    message.isShort = object.isShort ?? false;
    message.isYesterday = object.isYesterday ?? false;
    message.speculationType = object.speculationType ?? 0;
    return message;
  },
};

function createBasePurchaseAndSalesGroup(): PurchaseAndSalesGroup {
  return { id: 0, realizedProfitLoss: 0, matchedTrades: [] };
}

export const PurchaseAndSalesGroup = {
  encode(message: PurchaseAndSalesGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.realizedProfitLoss !== 0) {
      writer.uint32(17).double(message.realizedProfitLoss);
    }
    for (const v of message.matchedTrades) {
      MatchedTrade.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchaseAndSalesGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchaseAndSalesGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.sint32();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.realizedProfitLoss = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.matchedTrades.push(MatchedTrade.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchaseAndSalesGroup {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      realizedProfitLoss: isSet(object.realizedProfitLoss) ? globalThis.Number(object.realizedProfitLoss) : 0,
      matchedTrades: globalThis.Array.isArray(object?.matchedTrades)
        ? object.matchedTrades.map((e: any) => MatchedTrade.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PurchaseAndSalesGroup): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.realizedProfitLoss !== 0) {
      obj.realizedProfitLoss = message.realizedProfitLoss;
    }
    if (message.matchedTrades?.length) {
      obj.matchedTrades = message.matchedTrades.map((e) => MatchedTrade.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchaseAndSalesGroup>, I>>(base?: I): PurchaseAndSalesGroup {
    return PurchaseAndSalesGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PurchaseAndSalesGroup>, I>>(object: I): PurchaseAndSalesGroup {
    const message = createBasePurchaseAndSalesGroup();
    message.id = object.id ?? 0;
    message.realizedProfitLoss = object.realizedProfitLoss ?? 0;
    message.matchedTrades = object.matchedTrades?.map((e) => MatchedTrade.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMatchedTrade(): MatchedTrade {
  return {
    uint32Qty: 0,
    qty: undefined,
    isShort: false,
    price: 0,
    tradeDate: 0,
    statementDate: 0,
    tradeUtcTime: 0,
    tradeUtcTimestamp: undefined,
    isAggregated: false,
    isYesterday: false,
    isClose: false,
    speculationType: 0,
  };
}

export const MatchedTrade = {
  encode(message: MatchedTrade, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      writer.uint32(8).uint32(message.uint32Qty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(98).fork()).ldelim();
    }
    if (message.isShort !== undefined && message.isShort !== false) {
      writer.uint32(16).bool(message.isShort);
    }
    if (message.price !== 0) {
      writer.uint32(25).double(message.price);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(32).sint64(message.tradeDate);
    }
    if (message.statementDate !== 0) {
      writer.uint32(40).sint64(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      writer.uint32(48).sint64(message.tradeUtcTime);
    }
    if (message.tradeUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.tradeUtcTimestamp), writer.uint32(66).fork()).ldelim();
    }
    if (message.isAggregated !== false) {
      writer.uint32(56).bool(message.isAggregated);
    }
    if (message.isYesterday !== undefined && message.isYesterday !== false) {
      writer.uint32(72).bool(message.isYesterday);
    }
    if (message.isClose !== undefined && message.isClose !== false) {
      writer.uint32(80).bool(message.isClose);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(88).uint32(message.speculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MatchedTrade {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMatchedTrade();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.uint32Qty = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isShort = reader.bool();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.price = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tradeDate = longToNumber(reader.sint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.statementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.tradeUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tradeUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isAggregated = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isYesterday = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isClose = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MatchedTrade {
    return {
      uint32Qty: isSet(object.uint32Qty) ? globalThis.Number(object.uint32Qty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      isShort: isSet(object.isShort) ? globalThis.Boolean(object.isShort) : false,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      tradeDate: isSet(object.tradeDate) ? globalThis.Number(object.tradeDate) : 0,
      statementDate: isSet(object.statementDate) ? globalThis.Number(object.statementDate) : 0,
      tradeUtcTime: isSet(object.tradeUtcTime) ? globalThis.Number(object.tradeUtcTime) : 0,
      tradeUtcTimestamp: isSet(object.tradeUtcTimestamp) ? fromJsonTimestamp(object.tradeUtcTimestamp) : undefined,
      isAggregated: isSet(object.isAggregated) ? globalThis.Boolean(object.isAggregated) : false,
      isYesterday: isSet(object.isYesterday) ? globalThis.Boolean(object.isYesterday) : false,
      isClose: isSet(object.isClose) ? globalThis.Boolean(object.isClose) : false,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
    };
  },

  toJSON(message: MatchedTrade): unknown {
    const obj: any = {};
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      obj.uint32Qty = Math.round(message.uint32Qty);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.isShort !== undefined && message.isShort !== false) {
      obj.isShort = message.isShort;
    }
    if (message.price !== 0) {
      obj.price = message.price;
    }
    if (message.tradeDate !== 0) {
      obj.tradeDate = Math.round(message.tradeDate);
    }
    if (message.statementDate !== 0) {
      obj.statementDate = Math.round(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      obj.tradeUtcTime = Math.round(message.tradeUtcTime);
    }
    if (message.tradeUtcTimestamp !== undefined) {
      obj.tradeUtcTimestamp = message.tradeUtcTimestamp.toISOString();
    }
    if (message.isAggregated !== false) {
      obj.isAggregated = message.isAggregated;
    }
    if (message.isYesterday !== undefined && message.isYesterday !== false) {
      obj.isYesterday = message.isYesterday;
    }
    if (message.isClose !== undefined && message.isClose !== false) {
      obj.isClose = message.isClose;
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MatchedTrade>, I>>(base?: I): MatchedTrade {
    return MatchedTrade.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MatchedTrade>, I>>(object: I): MatchedTrade {
    const message = createBaseMatchedTrade();
    message.uint32Qty = object.uint32Qty ?? 0;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.isShort = object.isShort ?? false;
    message.price = object.price ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.statementDate = object.statementDate ?? 0;
    message.tradeUtcTime = object.tradeUtcTime ?? 0;
    message.tradeUtcTimestamp = object.tradeUtcTimestamp ?? undefined;
    message.isAggregated = object.isAggregated ?? false;
    message.isYesterday = object.isYesterday ?? false;
    message.isClose = object.isClose ?? false;
    message.speculationType = object.speculationType ?? 0;
    return message;
  },
};

function createBaseCollateralStatus(): CollateralStatus {
  return {
    subscriptionIds: [],
    isSnapshot: false,
    accountId: 0,
    currency: "",
    totalMargin: 0,
    positionMargin: 0,
    purchasingPower: 0,
    ote: 0,
    mvo: 0,
    mvf: 0,
    marginCredit: 0,
    statusUtcTimestamp: undefined,
  };
}

export const CollateralStatus = {
  encode(message: CollateralStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.subscriptionIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      writer.uint32(16).bool(message.isSnapshot);
    }
    if (message.accountId !== 0) {
      writer.uint32(24).sint32(message.accountId);
    }
    if (message.currency !== "") {
      writer.uint32(34).string(message.currency);
    }
    if (message.totalMargin !== 0) {
      writer.uint32(41).double(message.totalMargin);
    }
    if (message.positionMargin !== undefined && message.positionMargin !== 0) {
      writer.uint32(89).double(message.positionMargin);
    }
    if (message.purchasingPower !== 0) {
      writer.uint32(49).double(message.purchasingPower);
    }
    if (message.ote !== undefined && message.ote !== 0) {
      writer.uint32(57).double(message.ote);
    }
    if (message.mvo !== undefined && message.mvo !== 0) {
      writer.uint32(65).double(message.mvo);
    }
    if (message.mvf !== undefined && message.mvf !== 0) {
      writer.uint32(81).double(message.mvf);
    }
    if (message.marginCredit !== undefined && message.marginCredit !== 0) {
      writer.uint32(73).double(message.marginCredit);
    }
    if (message.statusUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.statusUtcTimestamp), writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CollateralStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollateralStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.subscriptionIds.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.subscriptionIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSnapshot = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.totalMargin = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.positionMargin = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.purchasingPower = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.ote = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.mvo = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.mvf = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.marginCredit = reader.double();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.statusUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollateralStatus {
    return {
      subscriptionIds: globalThis.Array.isArray(object?.subscriptionIds)
        ? object.subscriptionIds.map((e: any) => globalThis.Number(e))
        : [],
      isSnapshot: isSet(object.isSnapshot) ? globalThis.Boolean(object.isSnapshot) : false,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      totalMargin: isSet(object.totalMargin) ? globalThis.Number(object.totalMargin) : 0,
      positionMargin: isSet(object.positionMargin) ? globalThis.Number(object.positionMargin) : 0,
      purchasingPower: isSet(object.purchasingPower) ? globalThis.Number(object.purchasingPower) : 0,
      ote: isSet(object.ote) ? globalThis.Number(object.ote) : 0,
      mvo: isSet(object.mvo) ? globalThis.Number(object.mvo) : 0,
      mvf: isSet(object.mvf) ? globalThis.Number(object.mvf) : 0,
      marginCredit: isSet(object.marginCredit) ? globalThis.Number(object.marginCredit) : 0,
      statusUtcTimestamp: isSet(object.statusUtcTimestamp) ? fromJsonTimestamp(object.statusUtcTimestamp) : undefined,
    };
  },

  toJSON(message: CollateralStatus): unknown {
    const obj: any = {};
    if (message.subscriptionIds?.length) {
      obj.subscriptionIds = message.subscriptionIds.map((e) => Math.round(e));
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      obj.isSnapshot = message.isSnapshot;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.totalMargin !== 0) {
      obj.totalMargin = message.totalMargin;
    }
    if (message.positionMargin !== undefined && message.positionMargin !== 0) {
      obj.positionMargin = message.positionMargin;
    }
    if (message.purchasingPower !== 0) {
      obj.purchasingPower = message.purchasingPower;
    }
    if (message.ote !== undefined && message.ote !== 0) {
      obj.ote = message.ote;
    }
    if (message.mvo !== undefined && message.mvo !== 0) {
      obj.mvo = message.mvo;
    }
    if (message.mvf !== undefined && message.mvf !== 0) {
      obj.mvf = message.mvf;
    }
    if (message.marginCredit !== undefined && message.marginCredit !== 0) {
      obj.marginCredit = message.marginCredit;
    }
    if (message.statusUtcTimestamp !== undefined) {
      obj.statusUtcTimestamp = message.statusUtcTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollateralStatus>, I>>(base?: I): CollateralStatus {
    return CollateralStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollateralStatus>, I>>(object: I): CollateralStatus {
    const message = createBaseCollateralStatus();
    message.subscriptionIds = object.subscriptionIds?.map((e) => e) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    message.accountId = object.accountId ?? 0;
    message.currency = object.currency ?? "";
    message.totalMargin = object.totalMargin ?? 0;
    message.positionMargin = object.positionMargin ?? 0;
    message.purchasingPower = object.purchasingPower ?? 0;
    message.ote = object.ote ?? 0;
    message.mvo = object.mvo ?? 0;
    message.mvf = object.mvf ?? 0;
    message.marginCredit = object.marginCredit ?? 0;
    message.statusUtcTimestamp = object.statusUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseAccountSummaryStatus(): AccountSummaryStatus {
  return {
    subscriptionIds: [],
    isSnapshot: false,
    accountId: 0,
    currency: "",
    clearedFields: [],
    totalMargin: 0,
    positionMargin: 0,
    purchasingPower: 0,
    ote: 0,
    mvo: 0,
    mvf: 0,
    marginCredit: 0,
    cashExcess: 0,
    currentBalance: 0,
    profitLoss: 0,
    unrealizedProfitLoss: 0,
    yesterdayBalance: 0,
    yesterdayOte: 0,
    yesterdayMvo: 0,
    yesterdayCollateral: 0,
    netChangePc: 0,
    totalFilledQty: undefined,
    totalFilledOrders: 0,
    totalParkedOrders: 0,
    totalWorkingOrders: 0,
    longOpenPositionsQty: undefined,
    shortOpenPositionsQty: undefined,
    minDaysTillPositionContractExpiration: 0,
    purchasingPowerLimit: 0,
  };
}

export const AccountSummaryStatus = {
  encode(message: AccountSummaryStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.subscriptionIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      writer.uint32(16).bool(message.isSnapshot);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(24).sint32(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      writer.uint32(34).string(message.currency);
    }
    writer.uint32(42).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.totalMargin !== undefined && message.totalMargin !== 0) {
      writer.uint32(49).double(message.totalMargin);
    }
    if (message.positionMargin !== undefined && message.positionMargin !== 0) {
      writer.uint32(57).double(message.positionMargin);
    }
    if (message.purchasingPower !== undefined && message.purchasingPower !== 0) {
      writer.uint32(65).double(message.purchasingPower);
    }
    if (message.ote !== undefined && message.ote !== 0) {
      writer.uint32(73).double(message.ote);
    }
    if (message.mvo !== undefined && message.mvo !== 0) {
      writer.uint32(81).double(message.mvo);
    }
    if (message.mvf !== undefined && message.mvf !== 0) {
      writer.uint32(89).double(message.mvf);
    }
    if (message.marginCredit !== undefined && message.marginCredit !== 0) {
      writer.uint32(97).double(message.marginCredit);
    }
    if (message.cashExcess !== undefined && message.cashExcess !== 0) {
      writer.uint32(105).double(message.cashExcess);
    }
    if (message.currentBalance !== undefined && message.currentBalance !== 0) {
      writer.uint32(121).double(message.currentBalance);
    }
    if (message.profitLoss !== undefined && message.profitLoss !== 0) {
      writer.uint32(129).double(message.profitLoss);
    }
    if (message.unrealizedProfitLoss !== undefined && message.unrealizedProfitLoss !== 0) {
      writer.uint32(137).double(message.unrealizedProfitLoss);
    }
    if (message.yesterdayBalance !== undefined && message.yesterdayBalance !== 0) {
      writer.uint32(145).double(message.yesterdayBalance);
    }
    if (message.yesterdayOte !== undefined && message.yesterdayOte !== 0) {
      writer.uint32(193).double(message.yesterdayOte);
    }
    if (message.yesterdayMvo !== undefined && message.yesterdayMvo !== 0) {
      writer.uint32(201).double(message.yesterdayMvo);
    }
    if (message.yesterdayCollateral !== undefined && message.yesterdayCollateral !== 0) {
      writer.uint32(113).double(message.yesterdayCollateral);
    }
    if (message.netChangePc !== undefined && message.netChangePc !== 0) {
      writer.uint32(209).double(message.netChangePc);
    }
    if (message.totalFilledQty !== undefined) {
      Decimal.encode(message.totalFilledQty, writer.uint32(154).fork()).ldelim();
    }
    if (message.totalFilledOrders !== undefined && message.totalFilledOrders !== 0) {
      writer.uint32(160).uint32(message.totalFilledOrders);
    }
    if (message.totalParkedOrders !== undefined && message.totalParkedOrders !== 0) {
      writer.uint32(224).uint32(message.totalParkedOrders);
    }
    if (message.totalWorkingOrders !== undefined && message.totalWorkingOrders !== 0) {
      writer.uint32(232).uint32(message.totalWorkingOrders);
    }
    if (message.longOpenPositionsQty !== undefined) {
      Decimal.encode(message.longOpenPositionsQty, writer.uint32(170).fork()).ldelim();
    }
    if (message.shortOpenPositionsQty !== undefined) {
      Decimal.encode(message.shortOpenPositionsQty, writer.uint32(178).fork()).ldelim();
    }
    if (
      message.minDaysTillPositionContractExpiration !== undefined && message.minDaysTillPositionContractExpiration !== 0
    ) {
      writer.uint32(184).uint32(message.minDaysTillPositionContractExpiration);
    }
    if (message.purchasingPowerLimit !== undefined && message.purchasingPowerLimit !== 0) {
      writer.uint32(217).double(message.purchasingPowerLimit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSummaryStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSummaryStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.subscriptionIds.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.subscriptionIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSnapshot = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 5:
          if (tag === 40) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.totalMargin = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.positionMargin = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.purchasingPower = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.ote = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.mvo = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.mvf = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.marginCredit = reader.double();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.cashExcess = reader.double();
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.currentBalance = reader.double();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.profitLoss = reader.double();
          continue;
        case 17:
          if (tag !== 137) {
            break;
          }

          message.unrealizedProfitLoss = reader.double();
          continue;
        case 18:
          if (tag !== 145) {
            break;
          }

          message.yesterdayBalance = reader.double();
          continue;
        case 24:
          if (tag !== 193) {
            break;
          }

          message.yesterdayOte = reader.double();
          continue;
        case 25:
          if (tag !== 201) {
            break;
          }

          message.yesterdayMvo = reader.double();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.yesterdayCollateral = reader.double();
          continue;
        case 26:
          if (tag !== 209) {
            break;
          }

          message.netChangePc = reader.double();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.totalFilledQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.totalFilledOrders = reader.uint32();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.totalParkedOrders = reader.uint32();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.totalWorkingOrders = reader.uint32();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.longOpenPositionsQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.shortOpenPositionsQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.minDaysTillPositionContractExpiration = reader.uint32();
          continue;
        case 27:
          if (tag !== 217) {
            break;
          }

          message.purchasingPowerLimit = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSummaryStatus {
    return {
      subscriptionIds: globalThis.Array.isArray(object?.subscriptionIds)
        ? object.subscriptionIds.map((e: any) => globalThis.Number(e))
        : [],
      isSnapshot: isSet(object.isSnapshot) ? globalThis.Boolean(object.isSnapshot) : false,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      totalMargin: isSet(object.totalMargin) ? globalThis.Number(object.totalMargin) : 0,
      positionMargin: isSet(object.positionMargin) ? globalThis.Number(object.positionMargin) : 0,
      purchasingPower: isSet(object.purchasingPower) ? globalThis.Number(object.purchasingPower) : 0,
      ote: isSet(object.ote) ? globalThis.Number(object.ote) : 0,
      mvo: isSet(object.mvo) ? globalThis.Number(object.mvo) : 0,
      mvf: isSet(object.mvf) ? globalThis.Number(object.mvf) : 0,
      marginCredit: isSet(object.marginCredit) ? globalThis.Number(object.marginCredit) : 0,
      cashExcess: isSet(object.cashExcess) ? globalThis.Number(object.cashExcess) : 0,
      currentBalance: isSet(object.currentBalance) ? globalThis.Number(object.currentBalance) : 0,
      profitLoss: isSet(object.profitLoss) ? globalThis.Number(object.profitLoss) : 0,
      unrealizedProfitLoss: isSet(object.unrealizedProfitLoss) ? globalThis.Number(object.unrealizedProfitLoss) : 0,
      yesterdayBalance: isSet(object.yesterdayBalance) ? globalThis.Number(object.yesterdayBalance) : 0,
      yesterdayOte: isSet(object.yesterdayOte) ? globalThis.Number(object.yesterdayOte) : 0,
      yesterdayMvo: isSet(object.yesterdayMvo) ? globalThis.Number(object.yesterdayMvo) : 0,
      yesterdayCollateral: isSet(object.yesterdayCollateral) ? globalThis.Number(object.yesterdayCollateral) : 0,
      netChangePc: isSet(object.netChangePc) ? globalThis.Number(object.netChangePc) : 0,
      totalFilledQty: isSet(object.totalFilledQty) ? Decimal.fromJSON(object.totalFilledQty) : undefined,
      totalFilledOrders: isSet(object.totalFilledOrders) ? globalThis.Number(object.totalFilledOrders) : 0,
      totalParkedOrders: isSet(object.totalParkedOrders) ? globalThis.Number(object.totalParkedOrders) : 0,
      totalWorkingOrders: isSet(object.totalWorkingOrders) ? globalThis.Number(object.totalWorkingOrders) : 0,
      longOpenPositionsQty: isSet(object.longOpenPositionsQty)
        ? Decimal.fromJSON(object.longOpenPositionsQty)
        : undefined,
      shortOpenPositionsQty: isSet(object.shortOpenPositionsQty)
        ? Decimal.fromJSON(object.shortOpenPositionsQty)
        : undefined,
      minDaysTillPositionContractExpiration: isSet(object.minDaysTillPositionContractExpiration)
        ? globalThis.Number(object.minDaysTillPositionContractExpiration)
        : 0,
      purchasingPowerLimit: isSet(object.purchasingPowerLimit) ? globalThis.Number(object.purchasingPowerLimit) : 0,
    };
  },

  toJSON(message: AccountSummaryStatus): unknown {
    const obj: any = {};
    if (message.subscriptionIds?.length) {
      obj.subscriptionIds = message.subscriptionIds.map((e) => Math.round(e));
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      obj.isSnapshot = message.isSnapshot;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.totalMargin !== undefined && message.totalMargin !== 0) {
      obj.totalMargin = message.totalMargin;
    }
    if (message.positionMargin !== undefined && message.positionMargin !== 0) {
      obj.positionMargin = message.positionMargin;
    }
    if (message.purchasingPower !== undefined && message.purchasingPower !== 0) {
      obj.purchasingPower = message.purchasingPower;
    }
    if (message.ote !== undefined && message.ote !== 0) {
      obj.ote = message.ote;
    }
    if (message.mvo !== undefined && message.mvo !== 0) {
      obj.mvo = message.mvo;
    }
    if (message.mvf !== undefined && message.mvf !== 0) {
      obj.mvf = message.mvf;
    }
    if (message.marginCredit !== undefined && message.marginCredit !== 0) {
      obj.marginCredit = message.marginCredit;
    }
    if (message.cashExcess !== undefined && message.cashExcess !== 0) {
      obj.cashExcess = message.cashExcess;
    }
    if (message.currentBalance !== undefined && message.currentBalance !== 0) {
      obj.currentBalance = message.currentBalance;
    }
    if (message.profitLoss !== undefined && message.profitLoss !== 0) {
      obj.profitLoss = message.profitLoss;
    }
    if (message.unrealizedProfitLoss !== undefined && message.unrealizedProfitLoss !== 0) {
      obj.unrealizedProfitLoss = message.unrealizedProfitLoss;
    }
    if (message.yesterdayBalance !== undefined && message.yesterdayBalance !== 0) {
      obj.yesterdayBalance = message.yesterdayBalance;
    }
    if (message.yesterdayOte !== undefined && message.yesterdayOte !== 0) {
      obj.yesterdayOte = message.yesterdayOte;
    }
    if (message.yesterdayMvo !== undefined && message.yesterdayMvo !== 0) {
      obj.yesterdayMvo = message.yesterdayMvo;
    }
    if (message.yesterdayCollateral !== undefined && message.yesterdayCollateral !== 0) {
      obj.yesterdayCollateral = message.yesterdayCollateral;
    }
    if (message.netChangePc !== undefined && message.netChangePc !== 0) {
      obj.netChangePc = message.netChangePc;
    }
    if (message.totalFilledQty !== undefined) {
      obj.totalFilledQty = Decimal.toJSON(message.totalFilledQty);
    }
    if (message.totalFilledOrders !== undefined && message.totalFilledOrders !== 0) {
      obj.totalFilledOrders = Math.round(message.totalFilledOrders);
    }
    if (message.totalParkedOrders !== undefined && message.totalParkedOrders !== 0) {
      obj.totalParkedOrders = Math.round(message.totalParkedOrders);
    }
    if (message.totalWorkingOrders !== undefined && message.totalWorkingOrders !== 0) {
      obj.totalWorkingOrders = Math.round(message.totalWorkingOrders);
    }
    if (message.longOpenPositionsQty !== undefined) {
      obj.longOpenPositionsQty = Decimal.toJSON(message.longOpenPositionsQty);
    }
    if (message.shortOpenPositionsQty !== undefined) {
      obj.shortOpenPositionsQty = Decimal.toJSON(message.shortOpenPositionsQty);
    }
    if (
      message.minDaysTillPositionContractExpiration !== undefined && message.minDaysTillPositionContractExpiration !== 0
    ) {
      obj.minDaysTillPositionContractExpiration = Math.round(message.minDaysTillPositionContractExpiration);
    }
    if (message.purchasingPowerLimit !== undefined && message.purchasingPowerLimit !== 0) {
      obj.purchasingPowerLimit = message.purchasingPowerLimit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSummaryStatus>, I>>(base?: I): AccountSummaryStatus {
    return AccountSummaryStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSummaryStatus>, I>>(object: I): AccountSummaryStatus {
    const message = createBaseAccountSummaryStatus();
    message.subscriptionIds = object.subscriptionIds?.map((e) => e) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    message.accountId = object.accountId ?? 0;
    message.currency = object.currency ?? "";
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.totalMargin = object.totalMargin ?? 0;
    message.positionMargin = object.positionMargin ?? 0;
    message.purchasingPower = object.purchasingPower ?? 0;
    message.ote = object.ote ?? 0;
    message.mvo = object.mvo ?? 0;
    message.mvf = object.mvf ?? 0;
    message.marginCredit = object.marginCredit ?? 0;
    message.cashExcess = object.cashExcess ?? 0;
    message.currentBalance = object.currentBalance ?? 0;
    message.profitLoss = object.profitLoss ?? 0;
    message.unrealizedProfitLoss = object.unrealizedProfitLoss ?? 0;
    message.yesterdayBalance = object.yesterdayBalance ?? 0;
    message.yesterdayOte = object.yesterdayOte ?? 0;
    message.yesterdayMvo = object.yesterdayMvo ?? 0;
    message.yesterdayCollateral = object.yesterdayCollateral ?? 0;
    message.netChangePc = object.netChangePc ?? 0;
    message.totalFilledQty = (object.totalFilledQty !== undefined && object.totalFilledQty !== null)
      ? Decimal.fromPartial(object.totalFilledQty)
      : undefined;
    message.totalFilledOrders = object.totalFilledOrders ?? 0;
    message.totalParkedOrders = object.totalParkedOrders ?? 0;
    message.totalWorkingOrders = object.totalWorkingOrders ?? 0;
    message.longOpenPositionsQty = (object.longOpenPositionsQty !== undefined && object.longOpenPositionsQty !== null)
      ? Decimal.fromPartial(object.longOpenPositionsQty)
      : undefined;
    message.shortOpenPositionsQty =
      (object.shortOpenPositionsQty !== undefined && object.shortOpenPositionsQty !== null)
        ? Decimal.fromPartial(object.shortOpenPositionsQty)
        : undefined;
    message.minDaysTillPositionContractExpiration = object.minDaysTillPositionContractExpiration ?? 0;
    message.purchasingPowerLimit = object.purchasingPowerLimit ?? 0;
    return message;
  },
};

function createBaseHistoricalOrdersRequest(): HistoricalOrdersRequest {
  return {
    fromDate: 0,
    toDate: 0,
    accountIds: [],
    includeCrossOrders: false,
    orderSnapshotFilter: undefined,
    maxOrderStatusCount: 0,
    maxTransactionStatusCount: 0,
    parentChainOrderId: "",
    includeAlgoStrategyChildOrders: false,
  };
}

export const HistoricalOrdersRequest = {
  encode(message: HistoricalOrdersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fromDate !== 0) {
      writer.uint32(8).sint64(message.fromDate);
    }
    if (message.toDate !== undefined && message.toDate !== 0) {
      writer.uint32(16).sint64(message.toDate);
    }
    writer.uint32(26).fork();
    for (const v of message.accountIds) {
      writer.sint32(v);
    }
    writer.ldelim();
    if (message.includeCrossOrders !== undefined && message.includeCrossOrders !== false) {
      writer.uint32(32).bool(message.includeCrossOrders);
    }
    if (message.orderSnapshotFilter !== undefined) {
      OrderSnapshotFilter.encode(message.orderSnapshotFilter, writer.uint32(42).fork()).ldelim();
    }
    if (message.maxOrderStatusCount !== undefined && message.maxOrderStatusCount !== 0) {
      writer.uint32(48).uint32(message.maxOrderStatusCount);
    }
    if (message.maxTransactionStatusCount !== undefined && message.maxTransactionStatusCount !== 0) {
      writer.uint32(56).uint32(message.maxTransactionStatusCount);
    }
    if (message.parentChainOrderId !== undefined && message.parentChainOrderId !== "") {
      writer.uint32(66).string(message.parentChainOrderId);
    }
    if (message.includeAlgoStrategyChildOrders !== undefined && message.includeAlgoStrategyChildOrders !== false) {
      writer.uint32(72).bool(message.includeAlgoStrategyChildOrders);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoricalOrdersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalOrdersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.fromDate = longToNumber(reader.sint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.toDate = longToNumber(reader.sint64() as Long);
          continue;
        case 3:
          if (tag === 24) {
            message.accountIds.push(reader.sint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.accountIds.push(reader.sint32());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeCrossOrders = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orderSnapshotFilter = OrderSnapshotFilter.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.maxOrderStatusCount = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.maxTransactionStatusCount = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.parentChainOrderId = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.includeAlgoStrategyChildOrders = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalOrdersRequest {
    return {
      fromDate: isSet(object.fromDate) ? globalThis.Number(object.fromDate) : 0,
      toDate: isSet(object.toDate) ? globalThis.Number(object.toDate) : 0,
      accountIds: globalThis.Array.isArray(object?.accountIds)
        ? object.accountIds.map((e: any) => globalThis.Number(e))
        : [],
      includeCrossOrders: isSet(object.includeCrossOrders) ? globalThis.Boolean(object.includeCrossOrders) : false,
      orderSnapshotFilter: isSet(object.orderSnapshotFilter)
        ? OrderSnapshotFilter.fromJSON(object.orderSnapshotFilter)
        : undefined,
      maxOrderStatusCount: isSet(object.maxOrderStatusCount) ? globalThis.Number(object.maxOrderStatusCount) : 0,
      maxTransactionStatusCount: isSet(object.maxTransactionStatusCount)
        ? globalThis.Number(object.maxTransactionStatusCount)
        : 0,
      parentChainOrderId: isSet(object.parentChainOrderId) ? globalThis.String(object.parentChainOrderId) : "",
      includeAlgoStrategyChildOrders: isSet(object.includeAlgoStrategyChildOrders)
        ? globalThis.Boolean(object.includeAlgoStrategyChildOrders)
        : false,
    };
  },

  toJSON(message: HistoricalOrdersRequest): unknown {
    const obj: any = {};
    if (message.fromDate !== 0) {
      obj.fromDate = Math.round(message.fromDate);
    }
    if (message.toDate !== undefined && message.toDate !== 0) {
      obj.toDate = Math.round(message.toDate);
    }
    if (message.accountIds?.length) {
      obj.accountIds = message.accountIds.map((e) => Math.round(e));
    }
    if (message.includeCrossOrders !== undefined && message.includeCrossOrders !== false) {
      obj.includeCrossOrders = message.includeCrossOrders;
    }
    if (message.orderSnapshotFilter !== undefined) {
      obj.orderSnapshotFilter = OrderSnapshotFilter.toJSON(message.orderSnapshotFilter);
    }
    if (message.maxOrderStatusCount !== undefined && message.maxOrderStatusCount !== 0) {
      obj.maxOrderStatusCount = Math.round(message.maxOrderStatusCount);
    }
    if (message.maxTransactionStatusCount !== undefined && message.maxTransactionStatusCount !== 0) {
      obj.maxTransactionStatusCount = Math.round(message.maxTransactionStatusCount);
    }
    if (message.parentChainOrderId !== undefined && message.parentChainOrderId !== "") {
      obj.parentChainOrderId = message.parentChainOrderId;
    }
    if (message.includeAlgoStrategyChildOrders !== undefined && message.includeAlgoStrategyChildOrders !== false) {
      obj.includeAlgoStrategyChildOrders = message.includeAlgoStrategyChildOrders;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalOrdersRequest>, I>>(base?: I): HistoricalOrdersRequest {
    return HistoricalOrdersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalOrdersRequest>, I>>(object: I): HistoricalOrdersRequest {
    const message = createBaseHistoricalOrdersRequest();
    message.fromDate = object.fromDate ?? 0;
    message.toDate = object.toDate ?? 0;
    message.accountIds = object.accountIds?.map((e) => e) || [];
    message.includeCrossOrders = object.includeCrossOrders ?? false;
    message.orderSnapshotFilter = (object.orderSnapshotFilter !== undefined && object.orderSnapshotFilter !== null)
      ? OrderSnapshotFilter.fromPartial(object.orderSnapshotFilter)
      : undefined;
    message.maxOrderStatusCount = object.maxOrderStatusCount ?? 0;
    message.maxTransactionStatusCount = object.maxTransactionStatusCount ?? 0;
    message.parentChainOrderId = object.parentChainOrderId ?? "";
    message.includeAlgoStrategyChildOrders = object.includeAlgoStrategyChildOrders ?? false;
    return message;
  },
};

function createBaseHistoricalOrdersReport(): HistoricalOrdersReport {
  return { orderStatuses: [], orderStatusLimitReached: false, transactionStatusLimitReached: false };
}

export const HistoricalOrdersReport = {
  encode(message: HistoricalOrdersReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.orderStatuses) {
      OrderStatus.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.orderStatusLimitReached !== undefined && message.orderStatusLimitReached !== false) {
      writer.uint32(16).bool(message.orderStatusLimitReached);
    }
    if (message.transactionStatusLimitReached !== undefined && message.transactionStatusLimitReached !== false) {
      writer.uint32(24).bool(message.transactionStatusLimitReached);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoricalOrdersReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalOrdersReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderStatuses.push(OrderStatus.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.orderStatusLimitReached = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transactionStatusLimitReached = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalOrdersReport {
    return {
      orderStatuses: globalThis.Array.isArray(object?.orderStatuses)
        ? object.orderStatuses.map((e: any) => OrderStatus.fromJSON(e))
        : [],
      orderStatusLimitReached: isSet(object.orderStatusLimitReached)
        ? globalThis.Boolean(object.orderStatusLimitReached)
        : false,
      transactionStatusLimitReached: isSet(object.transactionStatusLimitReached)
        ? globalThis.Boolean(object.transactionStatusLimitReached)
        : false,
    };
  },

  toJSON(message: HistoricalOrdersReport): unknown {
    const obj: any = {};
    if (message.orderStatuses?.length) {
      obj.orderStatuses = message.orderStatuses.map((e) => OrderStatus.toJSON(e));
    }
    if (message.orderStatusLimitReached !== undefined && message.orderStatusLimitReached !== false) {
      obj.orderStatusLimitReached = message.orderStatusLimitReached;
    }
    if (message.transactionStatusLimitReached !== undefined && message.transactionStatusLimitReached !== false) {
      obj.transactionStatusLimitReached = message.transactionStatusLimitReached;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalOrdersReport>, I>>(base?: I): HistoricalOrdersReport {
    return HistoricalOrdersReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalOrdersReport>, I>>(object: I): HistoricalOrdersReport {
    const message = createBaseHistoricalOrdersReport();
    message.orderStatuses = object.orderStatuses?.map((e) => OrderStatus.fromPartial(e)) || [];
    message.orderStatusLimitReached = object.orderStatusLimitReached ?? false;
    message.transactionStatusLimitReached = object.transactionStatusLimitReached ?? false;
    return message;
  },
};

function createBaseBrokerageTradingFeatureEntitlementRequest(): BrokerageTradingFeatureEntitlementRequest {
  return { accountIds: [] };
}

export const BrokerageTradingFeatureEntitlementRequest = {
  encode(message: BrokerageTradingFeatureEntitlementRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.accountIds) {
      writer.sint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerageTradingFeatureEntitlementRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerageTradingFeatureEntitlementRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.accountIds.push(reader.sint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.accountIds.push(reader.sint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BrokerageTradingFeatureEntitlementRequest {
    return {
      accountIds: globalThis.Array.isArray(object?.accountIds)
        ? object.accountIds.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: BrokerageTradingFeatureEntitlementRequest): unknown {
    const obj: any = {};
    if (message.accountIds?.length) {
      obj.accountIds = message.accountIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerageTradingFeatureEntitlementRequest>, I>>(
    base?: I,
  ): BrokerageTradingFeatureEntitlementRequest {
    return BrokerageTradingFeatureEntitlementRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BrokerageTradingFeatureEntitlementRequest>, I>>(
    object: I,
  ): BrokerageTradingFeatureEntitlementRequest {
    const message = createBaseBrokerageTradingFeatureEntitlementRequest();
    message.accountIds = object.accountIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseBrokerageTradingFeatureEntitlementReport(): BrokerageTradingFeatureEntitlementReport {
  return { tradingFeatureEntitlements: [] };
}

export const BrokerageTradingFeatureEntitlementReport = {
  encode(message: BrokerageTradingFeatureEntitlementReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tradingFeatureEntitlements) {
      TradingFeatureEntitlementEntry.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerageTradingFeatureEntitlementReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerageTradingFeatureEntitlementReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tradingFeatureEntitlements.push(TradingFeatureEntitlementEntry.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BrokerageTradingFeatureEntitlementReport {
    return {
      tradingFeatureEntitlements: globalThis.Array.isArray(object?.tradingFeatureEntitlements)
        ? object.tradingFeatureEntitlements.map((e: any) => TradingFeatureEntitlementEntry.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BrokerageTradingFeatureEntitlementReport): unknown {
    const obj: any = {};
    if (message.tradingFeatureEntitlements?.length) {
      obj.tradingFeatureEntitlements = message.tradingFeatureEntitlements.map((e) =>
        TradingFeatureEntitlementEntry.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerageTradingFeatureEntitlementReport>, I>>(
    base?: I,
  ): BrokerageTradingFeatureEntitlementReport {
    return BrokerageTradingFeatureEntitlementReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BrokerageTradingFeatureEntitlementReport>, I>>(
    object: I,
  ): BrokerageTradingFeatureEntitlementReport {
    const message = createBaseBrokerageTradingFeatureEntitlementReport();
    message.tradingFeatureEntitlements =
      object.tradingFeatureEntitlements?.map((e) => TradingFeatureEntitlementEntry.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTradingFeatureEntitlementEntry(): TradingFeatureEntitlementEntry {
  return { accountId: 0, entitlement: 0 };
}

export const TradingFeatureEntitlementEntry = {
  encode(message: TradingFeatureEntitlementEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.entitlement !== undefined && message.entitlement !== 0) {
      writer.uint32(16).uint32(message.entitlement);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingFeatureEntitlementEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingFeatureEntitlementEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.entitlement = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradingFeatureEntitlementEntry {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      entitlement: isSet(object.entitlement) ? globalThis.Number(object.entitlement) : 0,
    };
  },

  toJSON(message: TradingFeatureEntitlementEntry): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.entitlement !== undefined && message.entitlement !== 0) {
      obj.entitlement = Math.round(message.entitlement);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingFeatureEntitlementEntry>, I>>(base?: I): TradingFeatureEntitlementEntry {
    return TradingFeatureEntitlementEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradingFeatureEntitlementEntry>, I>>(
    object: I,
  ): TradingFeatureEntitlementEntry {
    const message = createBaseTradingFeatureEntitlementEntry();
    message.accountId = object.accountId ?? 0;
    message.entitlement = object.entitlement ?? 0;
    return message;
  },
};

function createBaseCareAutoActivationParametersRequest(): CareAutoActivationParametersRequest {
  return {};
}

export const CareAutoActivationParametersRequest = {
  encode(_: CareAutoActivationParametersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CareAutoActivationParametersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCareAutoActivationParametersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CareAutoActivationParametersRequest {
    return {};
  },

  toJSON(_: CareAutoActivationParametersRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CareAutoActivationParametersRequest>, I>>(
    base?: I,
  ): CareAutoActivationParametersRequest {
    return CareAutoActivationParametersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CareAutoActivationParametersRequest>, I>>(
    _: I,
  ): CareAutoActivationParametersRequest {
    const message = createBaseCareAutoActivationParametersRequest();
    return message;
  },
};

function createBaseCareAutoActivationParametersReport(): CareAutoActivationParametersReport {
  return { parameters: [] };
}

export const CareAutoActivationParametersReport = {
  encode(message: CareAutoActivationParametersReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.parameters) {
      CareAutoActivationParameters.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CareAutoActivationParametersReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCareAutoActivationParametersReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parameters.push(CareAutoActivationParameters.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CareAutoActivationParametersReport {
    return {
      parameters: globalThis.Array.isArray(object?.parameters)
        ? object.parameters.map((e: any) => CareAutoActivationParameters.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CareAutoActivationParametersReport): unknown {
    const obj: any = {};
    if (message.parameters?.length) {
      obj.parameters = message.parameters.map((e) => CareAutoActivationParameters.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CareAutoActivationParametersReport>, I>>(
    base?: I,
  ): CareAutoActivationParametersReport {
    return CareAutoActivationParametersReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CareAutoActivationParametersReport>, I>>(
    object: I,
  ): CareAutoActivationParametersReport {
    const message = createBaseCareAutoActivationParametersReport();
    message.parameters = object.parameters?.map((e) => CareAutoActivationParameters.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCareAutoActivationParameters(): CareAutoActivationParameters {
  return { isCurrentUserResponsibleForAutoActivation: false, brokerageId: 0, maxOrderQty: 0 };
}

export const CareAutoActivationParameters = {
  encode(message: CareAutoActivationParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (
      message.isCurrentUserResponsibleForAutoActivation !== undefined &&
      message.isCurrentUserResponsibleForAutoActivation !== false
    ) {
      writer.uint32(8).bool(message.isCurrentUserResponsibleForAutoActivation);
    }
    if (message.brokerageId !== undefined && message.brokerageId !== 0) {
      writer.uint32(16).uint32(message.brokerageId);
    }
    if (message.maxOrderQty !== undefined && message.maxOrderQty !== 0) {
      writer.uint32(24).uint32(message.maxOrderQty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CareAutoActivationParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCareAutoActivationParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isCurrentUserResponsibleForAutoActivation = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.brokerageId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxOrderQty = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CareAutoActivationParameters {
    return {
      isCurrentUserResponsibleForAutoActivation: isSet(object.isCurrentUserResponsibleForAutoActivation)
        ? globalThis.Boolean(object.isCurrentUserResponsibleForAutoActivation)
        : false,
      brokerageId: isSet(object.brokerageId) ? globalThis.Number(object.brokerageId) : 0,
      maxOrderQty: isSet(object.maxOrderQty) ? globalThis.Number(object.maxOrderQty) : 0,
    };
  },

  toJSON(message: CareAutoActivationParameters): unknown {
    const obj: any = {};
    if (
      message.isCurrentUserResponsibleForAutoActivation !== undefined &&
      message.isCurrentUserResponsibleForAutoActivation !== false
    ) {
      obj.isCurrentUserResponsibleForAutoActivation = message.isCurrentUserResponsibleForAutoActivation;
    }
    if (message.brokerageId !== undefined && message.brokerageId !== 0) {
      obj.brokerageId = Math.round(message.brokerageId);
    }
    if (message.maxOrderQty !== undefined && message.maxOrderQty !== 0) {
      obj.maxOrderQty = Math.round(message.maxOrderQty);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CareAutoActivationParameters>, I>>(base?: I): CareAutoActivationParameters {
    return CareAutoActivationParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CareAutoActivationParameters>, I>>(object: I): CareAutoActivationParameters {
    const message = createBaseCareAutoActivationParameters();
    message.isCurrentUserResponsibleForAutoActivation = object.isCurrentUserResponsibleForAutoActivation ?? false;
    message.brokerageId = object.brokerageId ?? 0;
    message.maxOrderQty = object.maxOrderQty ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

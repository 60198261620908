import { isNullOrUndefined, toNumber } from "../utils/lib";
import { CMESymbolService } from "../services/CMESymbolService";
import { DisplayUtil } from "../utils/DisplayUtil";
import { PriceUtil } from "../utils/PriceUtil";
import { TimeUtil } from "../utils/TimeUtil";
import * as MarketData from "../proto/market_data_2";
import { ContractMetadata } from "../proto/metadata_2";
import { numberNullable, stringNullable } from "../types/Types";
import { CMEInstrument } from "./CMEInstrument";

const DEFAULT_STRIKE_ROWS: number = 10;

type numberUndefined = number | null | undefined;

export class Instrument {
  contractMetadata: ContractMetadata | null = null;
  MONTH_CODES: { [key: string]: string } = {};

  constructor(contractMetadata: ContractMetadata | null) {
    this.contractMetadata = contractMetadata;

    this.MONTH_CODES["F"] = "Jan";
    this.MONTH_CODES["G"] = "Feb";
    this.MONTH_CODES["H"] = "Mar";
    this.MONTH_CODES["J"] = "Apr";
    this.MONTH_CODES["K"] = "May";
    this.MONTH_CODES["M"] = "Jun";
    this.MONTH_CODES["N"] = "Jul";
    this.MONTH_CODES["Q"] = "Aug";
    this.MONTH_CODES["U"] = "Sep";
    this.MONTH_CODES["V"] = "Oct";
    this.MONTH_CODES["X"] = "Nov";
    this.MONTH_CODES["Z"] = "Dec";

    if (contractMetadata) {
      this.update(contractMetadata);
    }

    this.isResolved = !!contractMetadata;
  }

  // real-time data
  openPrice: number | null = null;
  highPrice: number | null = null;
  lowPrice: number | null = null;
  lastPrice: number | null = null;
  bestBid: number | null = null;
  bestAsk: number | null = null;
  bids: any[] = [];
  asks: any[] = [];
  yesterdaySettlement: number | null = null;
  lastUpdateTimestamp: Date | null = null;

  // contract metadata
  contractId: number | null = null;
  contractSymbol: string | undefined;
  correctPriceScale: number | null = null;
  displayPriceScale: number | null = null;
  title: string | undefined;
  rawTickSize: number = 0; // as provided by CQG
  tickSize: number = 0; // same as rawTickSize unless instrument is marked to be processed as fractional (see priceUtil.isFractional)
  tickValue: number = 0;
  currency: string | null = null;
  marginStyle: numberUndefined = null;
  description: string | null = null;
  underlyingContractSymbol: string | undefined;
  putCall: string | undefined;
  strikePrice: numberNullable = null;
  month: string | null | undefined;

  // label data
  labelPrice: number | null = null;
  labelPriceNetChange: number | null = null;
  labelPriceNetChangePercent: number | null = null;
  labelOpen: number | null = null;
  labelHigh: number | null = null;
  labelLow: number | null = null;
  labelSettlement: number | null = null;
  labelBid: number | null = null;
  labelAsk: number | null = null;
  labelBidVolume: number | null = null;
  labelAskVolume: number | null = null;
  labelTotalVolume: number = 0;
  labelTrade: number | null = null;
  labelTradeVolume: number | null = null;
  labelTradeNetChange: number | null = null;
  labelTradeNetChangePercent: number | null = null;
  labelQuote: number | null = null;
  labelQuoteNetChange: number | null = null;
  labelQuoteNetChangePercent: number | null = null;
  labelTradeOrSettle: number | null = null;
  labelTradeOrSettleVolume: number | null = null;

  // CmeSymbol
  displayName: string | undefined;
  name: string | null = null;
  cmeSymbol: string | null = null;
  cqgSymbol: string | null = null;
  cqgOptionRoot: string | null = null;
  marginInitialRate: number | null = null;
  multiplier: number | null = null;
  cqgOptionSymbol: string | null = null;
  quoteboardMonthsToFetch: number | null = null;
  numStrikeRows: number = DEFAULT_STRIKE_ROWS;
  last_trading_date: numberUndefined = null;
  cmeSymbolName: string | null = null;
  contractSize: number | null = null;
  assetClassName: string | null = null;

  isResolved: boolean;

  constructDisplayName = (): string | undefined => {
    if (this.title && this.name) {
      let title = this.title;

      if (this.isOption() && this.underlyingContractSymbol) {
        const splitted = this.underlyingContractSymbol.split(".");
        title = splitted[splitted.length - 1];
        title = title.substr(0, title.length - 2) + title.substr(title.length - 1, 1);
      }

      this.displayName = this.name + title.substr(title.length - 3, 1) + title.substr(title.length - 1);
    }
    return this.displayName;
  };

  constructPutCall = (): void => {
    if (this.isCallOption()) {
      this.putCall = "Call";
    } else if (this.isPutOption()) {
      this.putCall = "Put";
    }
  };

  constructStrikePriceFromContractSymbol = (): string | null => {
    let strike: string | null = null;

    if (this.contractSymbol) {
      const match = this.contractSymbol.match(/[PC]\.US\.[A-Z]*\d{2}(\d*)/);
      strike = match && match?.length > 1 ? match[1] : null;
    }

    return strike;
  };

  putCallAbbrev = (): string | null => {
    if (this.putCall === "Call") {
      return "C";
    } else if (this.putCall === "Put") {
      return "P";
    } else {
      return null;
    }
  };

  constructMonth = (): void => {
    let monthCode: string | null = null;

    if (this.contractSymbol) {
      const match = this.contractSymbol.match(/[FPC]\.US\.[A-Z0-9]*([A-Z])\d*/);
      monthCode = match && match.length > 1 ? match[1] : null;
    }

    this.month = monthCode ? this.MONTH_CODES[monthCode] : null;
  };

  isFuture = (): boolean => {
    return this.contractSymbol ? this.contractSymbol.indexOf("F.US.") === 0 : false;
  };

  isCallOption = (): boolean => {
    return this.contractSymbol ? this.contractSymbol.indexOf("C.US.") === 0 : false;
  };

  isPutOption = (): boolean => {
    return this.contractSymbol ? this.contractSymbol.indexOf("P.US.") === 0 : false;
  };

  isOption = (): boolean => {
    return this.isCallOption() || this.isPutOption();
  };

  getCqgOptionSymbol = (): string | null => {
    return this.cqgOptionRoot ? this.cqgOptionRoot : this.cqgSymbol;
  };

  constructFutureTitleFromUnderlyingContractSymbol = (): string | null => {
    const splitted = this.underlyingContractSymbol?.split(".");
    return splitted ? splitted[splitted.length - 1] : null;
  };

  isCmeResolved = (): boolean => {
    return this.displayName != null;
  };

  getPriceChange = (): number | null => {
    const lastPrice = this.lastPrice;
    const yesterdaySettlement = this.yesterdaySettlement;
    if (lastPrice == null || yesterdaySettlement == null) {
      return null;
    }
    return lastPrice - yesterdaySettlement;
  };

  getOpenPriceDisplay = () => {
    return DisplayUtil.toDisplayPrice(this.openPrice as number, this);
  };

  getHighPriceDisplay = () => {
    return DisplayUtil.toDisplayPrice(this.highPrice as number, this);
  };

  getLowPriceDisplay = () => {
    return DisplayUtil.toDisplayPrice(this.lowPrice as number, this);
  };

  getLastPriceDisplay = () => {
    return DisplayUtil.toDisplayPrice(this.lastPrice as number, this);
  };

  getPriceChangeDisplay = () => {
    return DisplayUtil.toDisplayPrice(this.getPriceChange() as number, this);
  };

  getCQGRootSymbol = (): string | null => {
    if (isNullOrUndefined(this.title) || (this.title && this.title.length < 4)) {
      return null;
    }

    return this.title?.slice(0, -3) as string;
  };

  update = (contractMetadata: ContractMetadata): void => {
    this.contractId = contractMetadata.contractId;
    this.contractSymbol = contractMetadata.contractSymbol;
    this.correctPriceScale = contractMetadata.correctPriceScale;
    this.displayPriceScale = contractMetadata.displayPriceScale;
    this.title = contractMetadata.title;
    this.rawTickSize = contractMetadata.tickSize;
    this.tickSize = PriceUtil.getTickSize(this.displayPriceScale as number, this.rawTickSize as number);
    this.tickValue = contractMetadata.tickValue;
    this.currency = contractMetadata.currency;
    this.marginStyle = contractMetadata.marginStyle;
    this.description = contractMetadata.description;
    this.underlyingContractSymbol = contractMetadata.underlyingContractSymbol;
    this.strikePrice = contractMetadata.strikePrice;
    this.last_trading_date = contractMetadata.lastTradingDate;
    this.constructDisplayName();
    this.constructPutCall();
    this.constructMonth();
  };

  static fromSnapshot = (snapshot: any, name: string): Instrument => {
    snapshot.type = 0;
    snapshot.close_price = snapshot.close_price || snapshot.last_price;
    const instrument = new Instrument(snapshot);
    const cachedPriceScale: number | null = instrument.correctPriceScale;
    instrument.correctPriceScale = 1;
    instrument.applyQuote(snapshot);
    instrument.applyMarketValues(snapshot);
    instrument.correctPriceScale = cachedPriceScale;

    // TOBEENABLED instrument.resolveFromCmeSymbol();
    return instrument;
  };

  applyMarketValues = (values: MarketData.MarketValues): void => {
    const scale: number = this.correctPriceScale as number;
    if (values.scaledOpenPrice) {
      this.openPrice = values.scaledOpenPrice * scale;
    }
    if (values.scaledHighPrice) {
      this.highPrice = values.scaledHighPrice * scale;
    }
    if (values.scaledLowPrice) {
      this.lowPrice = values.scaledLowPrice * scale;
    }
    if (values.scaledLastPrice) {
      this.lastPrice = values.scaledLastPrice * scale;
    }
    if (values.scaledYesterdaySettlement) {
      this.yesterdaySettlement = values.scaledYesterdaySettlement * scale;
      this.labelSettlement = this.yesterdaySettlement;
    }
    if (values.scaledTotalVolume) {
      this.labelTotalVolume = values.scaledTotalVolume;
    }
    this.calcLabels();
  };

  applyQuotes = (quotes: MarketData.Quote[]): boolean => {
    if (!quotes) {
      return false;
    }

    let anyUpdates: boolean = false;
    const self = this;
    quotes.forEach((quote) => {
      const updated = self.applyQuote(quote);
      anyUpdates = anyUpdates || updated;
      if (updated) {
        self.calcLabels(quote);
      }
    });
    return anyUpdates;
  };

  finalizeDepth = (): void => {
    const sortAndTrim = (depth: any[], isSell: boolean): any[] => {
      depth = depth.sort((a, b) => {
        return isSell ? a.price - b.price : b.price - a.price;
      });

      depth = depth.slice(0, 10);

      return depth;
    };

    this.asks = sortAndTrim(this.asks, true);
    this.bids = sortAndTrim(this.bids, false);
  };

  updateLastUpdateTimestamp = (quote: MarketData.Quote): void => {
    if (quote && quote.quoteUtcTime) {
      this.lastUpdateTimestamp = TimeUtil.toUtcDate(quote.quoteUtcTime);
    }
  };

  applyQuote = (quote: MarketData.Quote): boolean => {
    const scale: number = this.correctPriceScale as number;
    // const sessionOhlcIndicators: any[] = quote.session_ohlc_indicator;
    const price: number = quote.scaledPrice * scale;

    this.updateLastUpdateTimestamp(quote);

    switch (quote.type) {
      case MarketData.Quote_Type.TYPE_TRADE:
        this.lastPrice = price;
        // sessionOhlcIndicators.forEach((sessionOhlcIndicator) => {
        //   switch (sessionOhlcIndicator) {
        //     case MarketData.Quote_Indicator.INDICATOR_HIGH:
        //       this.highPrice = price;
        //       break;
        //     case MarketData.Quote_Indicator.INDICATOR_LOW:
        //       this.lowPrice = price;
        //       break;
        //     case MarketData.Quote_Indicator.INDICATOR_OPEN:
        //       this.openPrice = price;
        //       break;
        //     case MarketData.Quote_Indicator.INDICATOR_CLOSE:
        //       // do nothing, last price is already there.
        //       break;

        //     default:
        //       throw new Error("Unknown session OHLC indicator: " + sessionOhlcIndicator);
        //   }
        // }, this);
        return true;
      case MarketData.Quote_Type.TYPE_BESTBID:
        this.bestBid = price;
        return true;
      case MarketData.Quote_Type.TYPE_BESTASK:
        this.bestAsk = price;
        return true;
      case MarketData.Quote_Type.TYPE_BID:
        return this.applyDepthQuote(false, quote, price);
      case MarketData.Quote_Type.TYPE_ASK:
        return this.applyDepthQuote(true, quote, price);
      default:
        return false;
    }

    this.calcLabels();
  };

  applyDepthQuote = (isSell: boolean, quote: MarketData.Quote, price: number): boolean => {
    const depth: any[] = isSell ? this.asks : this.bids;
    const displayPrice = DisplayUtil.toDisplayPrice(price, this);

    if (this.getVolume(quote) === 0) {
      for (let i = 0; i < depth.length; i++) {
        if (depth[i].price === price) {
          depth.splice(i, 1);
          break;
        }
      }
    } else {
      for (let i = 0; i < depth.length; i++) {
        if (depth[i].price === price) {
          depth[i].volume = this.getVolume(quote);
          return false;
        }
      }
      depth.push({ price: price, displayPrice: displayPrice, volume: this.getVolume(quote) });
    }
    return false;
  };

  clearDepth = (): void => {
    this.bids = [];
    this.asks = [];
  };

  resolveFromCmeSymbol = () => {
    const self = this;

    return new Promise<stringNullable>((resolve, reject) => {
      if (!this.isCmeResolved()) {
        let title = this.title;

        if (this.isOption()) {
          title = this.constructFutureTitleFromUnderlyingContractSymbol() as string;
        }

        CMESymbolService.getCmeInstrument(title as string)
          .then((cmeInstrument: CMEInstrument | null) => {
            if (cmeInstrument && !isNullOrUndefined(cmeInstrument)) {
              self.cmeSymbol = cmeInstrument.cme_symbol;
              self.cqgSymbol = cmeInstrument.cqg_symbol;
              self.name = cmeInstrument.cme_symbol;
              self.cqgOptionSymbol = cmeInstrument.cqg_option_symbol;
              self.cmeSymbolName = cmeInstrument.name;
              self.displayName = self.constructDisplayName();
              self.cqgOptionRoot = cmeInstrument.cqg_option_root;
              self.contractSize = cmeInstrument.contract_size;
              self.multiplier = cmeInstrument.notional_value_multiplier;
              self.assetClassName = cmeInstrument.asset_class.name;
              self.marginInitialRate = cmeInstrument.margin_initial_rate;
              self.quoteboardMonthsToFetch = cmeInstrument.quoteboard_months_to_fetch;
              self.numStrikeRows = cmeInstrument.num_strike_rows || DEFAULT_STRIKE_ROWS;
            }

            resolve(self.displayName);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(this.displayName);
      }
    });
  };

  calcLabels = (quote?: MarketData.Quote): void => {
    this.labelPrice = DisplayUtil.toDisplayPrice(this.lastPrice as number, this);
    this.labelPriceNetChange = this.calcNetChange(this.lastPrice, this.labelSettlement);
    this.labelPriceNetChangePercent = this.calcNetChangePercent(this.labelSettlement, this.labelPriceNetChange);
    this.labelPriceNetChange = DisplayUtil.toDisplayPrice(this.labelPriceNetChange as number, this);

    this.labelOpen = DisplayUtil.toDisplayPrice(this.openPrice as number, this);
    this.labelHigh = DisplayUtil.toDisplayPrice(this.highPrice as number, this);
    this.labelLow = DisplayUtil.toDisplayPrice(this.lowPrice as number, this);

    this.labelBid = DisplayUtil.toDisplayPrice(this.bestBid as number, this);
    this.labelAsk = DisplayUtil.toDisplayPrice(this.bestAsk as number, this);
    this.labelSettlement = DisplayUtil.toDisplayPrice(this.labelSettlement as number, this);

    if (quote == null) {
      return;
    }

    const scale: number = this.correctPriceScale as number;
    const price: number = quote.scaledPrice * scale;

    switch (quote.type) {
      case MarketData.Quote_Type.TYPE_TRADE:
        this.labelTrade = price;
        this.labelTradeVolume = this.getVolume(quote);
        this.labelTradeNetChange = this.calcNetChange(price, this.labelSettlement);
        this.labelTradeNetChangePercent = this.calcNetChangePercent(this.labelSettlement, this.labelTradeNetChange);
        this.labelTradeOrSettle = price;
        this.labelTradeOrSettleVolume = this.getVolume(quote);
        if (quote && quote.scaledVolume !== null) {
          this.labelTotalVolume += toNumber(quote?.scaledVolume);
        }
        break;
      case MarketData.Quote_Type.TYPE_BESTBID:
        this.bestBid = price;
        this.labelBidVolume = this.getVolume(quote);
        break;
      case MarketData.Quote_Type.TYPE_BESTASK:
        this.bestAsk = price;
        this.labelAskVolume = this.getVolume(quote);
        break;
      case MarketData.Quote_Type.TYPE_SETTLEMENT:
        this.labelSettlement = price;
        this.labelTradeOrSettle = price;
        this.labelTradeOrSettleVolume = this.getVolume(quote);
        break;
    }

    this.labelQuote = price;
    this.labelQuoteNetChange = this.calcNetChange(price, this.labelSettlement);
    this.labelQuoteNetChangePercent = this.calcNetChangePercent(this.labelSettlement, this.labelQuoteNetChange);

    this.calcLabels();
  };

  getTicksPerDecimalPoint = (): number => {
    return 1 / (this.rawTickSize as number);
  };

  // TODO [pzhukov] DSI #725594301 Implement NetChange calculation as in CQG IC.
  calcNetChange = (price: number | null, settlement: number | null): number | null => {
    if (price === null || settlement === null) {
      return null;
    }
    return price - settlement;
  };

  calcNetChangePercent = (settlement: number | null, netChange: number | null): number | null => {
    if (settlement === null || netChange === null) {
      return null;
    }
    return settlement === 0 ? null : (100 * netChange) / settlement;
  };

  getVolume = (quote: MarketData.Quote): number | null => {
    return quote.scaledVolume ? quote.scaledVolume : null;
  };
}

// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: instrument_definition_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";
import { ContractMetadata } from "./metadata_2";

export const protobufPackage = "instrument_definition_2";

/**
 * Message describing a user-defined (non-strategy) instrument, e.g. a flex option.
 * Which attributes may be specified, and in what combination, is exchange-specific.
 * Description of usage conventions can be found in document InstrumentDefinitionConventions_WebAPI.adoc.
 */
export interface InstrumentDefinition {
  /**
   * Symbol of the underlying contract, in exchange-specific format.
   * This is a required field.
   */
  underlyingContractSymbol?:
    | string
    | undefined;
  /**
   * CQG base instrument type.
   * One of metadata_2.CQGInstrumentType enums.
   * This is a required field.
   */
  cqgInstrumentType?:
    | number
    | undefined;
  /**
   * Maturity timestamp.
   * (local exchange date in timestamp format, use date part only).
   * This is a required field.
   */
  maturityTimestamp?:
    | Date
    | undefined;
  /**
   * Exchange-specific instrument type, if required by exchange.
   * Allowed type strings depend on exchange convention.
   */
  exchangeInstrumentType?:
    | string
    | undefined;
  /**
   * Settlement method, if required by exchange.
   * One of metadata_2.SettlementMethod enums.
   */
  settlementMethod?:
    | number
    | undefined;
  /**
   * Option strike value, for options only.
   * Value will be passed as-is to exchange.
   */
  strike?:
    | number
    | undefined;
  /**
   * Exercise style, for options only, if required by exchange.
   * One of metadata_2.ExerciseStyle enums.
   */
  exerciseStyle?:
    | number
    | undefined;
  /**
   * Pricing convention, if required by exchange.
   * One of metadata_2.PricingConvention enums.
   */
  pricingConvention?: number | undefined;
}

/** Request to define a non-strategy as a contract. */
export interface InstrumentDefinitionRequest {
  /**
   * Instrument to define.
   * This is a required field.
   */
  instrumentDefinition?:
    | InstrumentDefinition
    | undefined;
  /**
   * Account ID in CQG trade routing system.
   * Some exchanges require an account ID in the process of defining an instrument.
   * For those exchanges this is a required field.
   */
  accountId?: number | undefined;
}

/** Report of the contract metadata assigned to the instrument. */
export interface InstrumentDefinitionReport {
  /**
   * Assigned instrument metadata.
   * This is a required field.
   */
  contractMetadata?: ContractMetadata | undefined;
}

function createBaseInstrumentDefinition(): InstrumentDefinition {
  return {
    underlyingContractSymbol: "",
    cqgInstrumentType: 0,
    maturityTimestamp: undefined,
    exchangeInstrumentType: "",
    settlementMethod: 0,
    strike: 0,
    exerciseStyle: 0,
    pricingConvention: 0,
  };
}

export const InstrumentDefinition = {
  encode(message: InstrumentDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.underlyingContractSymbol !== undefined && message.underlyingContractSymbol !== "") {
      writer.uint32(10).string(message.underlyingContractSymbol);
    }
    if (message.cqgInstrumentType !== undefined && message.cqgInstrumentType !== 0) {
      writer.uint32(16).uint32(message.cqgInstrumentType);
    }
    if (message.maturityTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.maturityTimestamp), writer.uint32(26).fork()).ldelim();
    }
    if (message.exchangeInstrumentType !== undefined && message.exchangeInstrumentType !== "") {
      writer.uint32(34).string(message.exchangeInstrumentType);
    }
    if (message.settlementMethod !== undefined && message.settlementMethod !== 0) {
      writer.uint32(40).uint32(message.settlementMethod);
    }
    if (message.strike !== undefined && message.strike !== 0) {
      writer.uint32(49).double(message.strike);
    }
    if (message.exerciseStyle !== undefined && message.exerciseStyle !== 0) {
      writer.uint32(56).uint32(message.exerciseStyle);
    }
    if (message.pricingConvention !== undefined && message.pricingConvention !== 0) {
      writer.uint32(64).uint32(message.pricingConvention);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentDefinition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.underlyingContractSymbol = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cqgInstrumentType = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maturityTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.exchangeInstrumentType = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.settlementMethod = reader.uint32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.strike = reader.double();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.exerciseStyle = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.pricingConvention = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstrumentDefinition {
    return {
      underlyingContractSymbol: isSet(object.underlyingContractSymbol)
        ? globalThis.String(object.underlyingContractSymbol)
        : "",
      cqgInstrumentType: isSet(object.cqgInstrumentType) ? globalThis.Number(object.cqgInstrumentType) : 0,
      maturityTimestamp: isSet(object.maturityTimestamp) ? fromJsonTimestamp(object.maturityTimestamp) : undefined,
      exchangeInstrumentType: isSet(object.exchangeInstrumentType)
        ? globalThis.String(object.exchangeInstrumentType)
        : "",
      settlementMethod: isSet(object.settlementMethod) ? globalThis.Number(object.settlementMethod) : 0,
      strike: isSet(object.strike) ? globalThis.Number(object.strike) : 0,
      exerciseStyle: isSet(object.exerciseStyle) ? globalThis.Number(object.exerciseStyle) : 0,
      pricingConvention: isSet(object.pricingConvention) ? globalThis.Number(object.pricingConvention) : 0,
    };
  },

  toJSON(message: InstrumentDefinition): unknown {
    const obj: any = {};
    if (message.underlyingContractSymbol !== undefined && message.underlyingContractSymbol !== "") {
      obj.underlyingContractSymbol = message.underlyingContractSymbol;
    }
    if (message.cqgInstrumentType !== undefined && message.cqgInstrumentType !== 0) {
      obj.cqgInstrumentType = Math.round(message.cqgInstrumentType);
    }
    if (message.maturityTimestamp !== undefined) {
      obj.maturityTimestamp = message.maturityTimestamp.toISOString();
    }
    if (message.exchangeInstrumentType !== undefined && message.exchangeInstrumentType !== "") {
      obj.exchangeInstrumentType = message.exchangeInstrumentType;
    }
    if (message.settlementMethod !== undefined && message.settlementMethod !== 0) {
      obj.settlementMethod = Math.round(message.settlementMethod);
    }
    if (message.strike !== undefined && message.strike !== 0) {
      obj.strike = message.strike;
    }
    if (message.exerciseStyle !== undefined && message.exerciseStyle !== 0) {
      obj.exerciseStyle = Math.round(message.exerciseStyle);
    }
    if (message.pricingConvention !== undefined && message.pricingConvention !== 0) {
      obj.pricingConvention = Math.round(message.pricingConvention);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentDefinition>, I>>(base?: I): InstrumentDefinition {
    return InstrumentDefinition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentDefinition>, I>>(object: I): InstrumentDefinition {
    const message = createBaseInstrumentDefinition();
    message.underlyingContractSymbol = object.underlyingContractSymbol ?? "";
    message.cqgInstrumentType = object.cqgInstrumentType ?? 0;
    message.maturityTimestamp = object.maturityTimestamp ?? undefined;
    message.exchangeInstrumentType = object.exchangeInstrumentType ?? "";
    message.settlementMethod = object.settlementMethod ?? 0;
    message.strike = object.strike ?? 0;
    message.exerciseStyle = object.exerciseStyle ?? 0;
    message.pricingConvention = object.pricingConvention ?? 0;
    return message;
  },
};

function createBaseInstrumentDefinitionRequest(): InstrumentDefinitionRequest {
  return { instrumentDefinition: undefined, accountId: 0 };
}

export const InstrumentDefinitionRequest = {
  encode(message: InstrumentDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instrumentDefinition !== undefined) {
      InstrumentDefinition.encode(message.instrumentDefinition, writer.uint32(10).fork()).ldelim();
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.instrumentDefinition = InstrumentDefinition.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstrumentDefinitionRequest {
    return {
      instrumentDefinition: isSet(object.instrumentDefinition)
        ? InstrumentDefinition.fromJSON(object.instrumentDefinition)
        : undefined,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
    };
  },

  toJSON(message: InstrumentDefinitionRequest): unknown {
    const obj: any = {};
    if (message.instrumentDefinition !== undefined) {
      obj.instrumentDefinition = InstrumentDefinition.toJSON(message.instrumentDefinition);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentDefinitionRequest>, I>>(base?: I): InstrumentDefinitionRequest {
    return InstrumentDefinitionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentDefinitionRequest>, I>>(object: I): InstrumentDefinitionRequest {
    const message = createBaseInstrumentDefinitionRequest();
    message.instrumentDefinition = (object.instrumentDefinition !== undefined && object.instrumentDefinition !== null)
      ? InstrumentDefinition.fromPartial(object.instrumentDefinition)
      : undefined;
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseInstrumentDefinitionReport(): InstrumentDefinitionReport {
  return { contractMetadata: undefined };
}

export const InstrumentDefinitionReport = {
  encode(message: InstrumentDefinitionReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentDefinitionReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentDefinitionReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstrumentDefinitionReport {
    return {
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
    };
  },

  toJSON(message: InstrumentDefinitionReport): unknown {
    const obj: any = {};
    if (message.contractMetadata !== undefined) {
      obj.contractMetadata = ContractMetadata.toJSON(message.contractMetadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentDefinitionReport>, I>>(base?: I): InstrumentDefinitionReport {
    return InstrumentDefinitionReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentDefinitionReport>, I>>(object: I): InstrumentDefinitionReport {
    const message = createBaseInstrumentDefinitionReport();
    message.contractMetadata = (object.contractMetadata !== undefined && object.contractMetadata !== null)
      ? ContractMetadata.fromPartial(object.contractMetadata)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import actions from "./layoutActions";

const initialData = {
  width: 0,
  defaultLayout: [],
  onLayoutChanged: null,
  selectedWorkspace: [],
};

export const layoutReducer = (state = initialData, { type, payload }) => {
  if (type === actions.UPDATE_LAYOUT) {
    return {
      ...state,
      width: payload.width,
      defaultLayout: payload.defaultLayout,
      onLayoutChanged: payload.onLayoutChanged,
      selectedWorkspace: payload.selectedWorkspace,
    };
  }

  return state;
};

// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: user_session_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Text } from "./common/shared_1";
import Long from "long";


export const protobufPackage = "user_session_2";

/**
 * Real-time Collapsing level enumeration.
 * Collapsing is a mechanism of eliminating some intermediate quotes to present more up-to-date market data
 * instead of falling behind.
 * It is started dynamically by server if client is not able to consume all requested feed timely
 * e.g. because of a narrow network channel or client side hardware/ software issues.
 *
 * * When real time price data in a session queue becomes >=1 seconds old (configurable) DOM collapsing is applied
 *   so per contract snapshot is sent at the end of the contract data messages.
 * * When real time price data in a session queue is >=4 seconds old (configurable) BBA collapsing is done
 *   so only last per contract BBA quotes are delivered.
 * * When real time price data in a session queue is >=8 seconds old (configurable) Trade collapsing is done
 *   so only last per contract Trade quotes are delivered.
 * * Quote representing Open, High or Low is always sent to the client regardless of current collapsing level.
 * * Quote representing Settlement is always sent to the client regardless of current collapsing level.
 * * If the difference between first and last quote in the queue reaches 60 (current setting) seconds
 * (configurable with per user overrides for VIP access) the connection is closed with sending a special user message.
 *
 * The user can specify maximum allowed collapsing level,
 * see Logon/RestoreOrJoinSession message's max_collapsing_level field, or SetCollapsingLevelRequest message.
 * Server will honor the setting, but still disconnect the user
 * if the data in outgoing queue becomes 60 sec(current setting) old.
 *
 * CONCLUSION: In case of bad network conditions, the client has a choice:
 *
 * a) To receive more complete realtime data but risk being disconnected:
 *    the lower chosen max_collapsing_level - the higher risk of disconnections.
 *
 * b) To receive collapsed but up-to-date realtime data and stay connected:
 *    it is advised to leave field max_collapsing_level out, or set it to REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES.
 */
export enum RealTimeCollapsingLevel {
  /** REAL_TIME_COLLAPSING_LEVEL_NONE - Collapsing is not not preferred. */
  REAL_TIME_COLLAPSING_LEVEL_NONE = 0,
  /** REAL_TIME_COLLAPSING_LEVEL_DOM - DOM data is collapsed. */
  REAL_TIME_COLLAPSING_LEVEL_DOM = 1,
  /** REAL_TIME_COLLAPSING_LEVEL_DOM_BBA - DOM and best bid/ ask quotes are collapsed delivering only last BBA but all trades. */
  REAL_TIME_COLLAPSING_LEVEL_DOM_BBA = 2,
  /** REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES - DOM, best bid/ ask and trades quotes are collapsed delivering only last values. */
  REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES = 3,
  UNRECOGNIZED = -1,
}

export function realTimeCollapsingLevelFromJSON(object: any): RealTimeCollapsingLevel {
  switch (object) {
    case 0:
    case "REAL_TIME_COLLAPSING_LEVEL_NONE":
      return RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_NONE;
    case 1:
    case "REAL_TIME_COLLAPSING_LEVEL_DOM":
      return RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_DOM;
    case 2:
    case "REAL_TIME_COLLAPSING_LEVEL_DOM_BBA":
      return RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_DOM_BBA;
    case 3:
    case "REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES":
      return RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RealTimeCollapsingLevel.UNRECOGNIZED;
  }
}

export function realTimeCollapsingLevelToJSON(object: RealTimeCollapsingLevel): string {
  switch (object) {
    case RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_NONE:
      return "REAL_TIME_COLLAPSING_LEVEL_NONE";
    case RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_DOM:
      return "REAL_TIME_COLLAPSING_LEVEL_DOM";
    case RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_DOM_BBA:
      return "REAL_TIME_COLLAPSING_LEVEL_DOM_BBA";
    case RealTimeCollapsingLevel.REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES:
      return "REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES";
    case RealTimeCollapsingLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Logon into the system and opening a new session.
 * Clients should either use user_name/password or access_token to enter the system.
 */
export interface Logon {
  /**
   * User login name.
   * It is not used along with access_token.
   */
  userName?:
    | string
    | undefined;
  /**
   * User's password.
   * It is not used along with access_token.
   */
  password?:
    | string
    | undefined;
  /**
   * Access token.
   * It is not used along with user_name, password and one_time_password.
   */
  accessToken?:
    | string
    | undefined;
  /**
   * Token of the user externally authenticated by CQG partner.
   * It is not used along with user_name, password and one_time_password.
   * Mutually exclusive with access_token.
   */
  partnerToken?:
    | string
    | undefined;
  /** User's one time password, might be required according to the user's settings. */
  oneTimePassword?:
    | string
    | undefined;
  /**
   * Private label identifies the Firm or Vendor that connects to the server. The value is ignored and taken from
   * access_token if it's specified. If neither access_token nor private_label is specified, the private_label is set
   * equal to client_app_id field value.
   */
  privateLabel?:
    | string
    | undefined;
  /**
   * Identifier of the client application as assigned by CQG. The value is ignored and taken from access_token
   * if it's specified; otherwise the field value is required to be provided.
   */
  clientAppId?:
    | string
    | undefined;
  /** Version of a client application. */
  clientVersion: string;
  /**
   * Optional field that indicates if possible concurrent sessions for this user should be forcedly dropped.
   * If it is set to false then Logon may fail with CONCURRENT_SESSION code.
   * If it is omitted or set to true then concurrent sessions for this user will be dropped.
   */
  dropConcurrentSession?:
    | boolean
    | undefined;
  /**
   * Maximum allowed real-time market data collapsing level.
   * REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES is the default allowed collapsing level.
   * This field is associated with RealTimeCollapsingLevel enum type.
   * Only one of max_collapsing_level and market_data_bandwidth can be specified.
   */
  maxCollapsingLevel?:
    | number
    | undefined;
  /**
   * Current protocol version that is used by the client application.
   * It is strongly recommended for client applications
   * to put PROTOCOL_VERSION_MAJOR and PROTOCOL_VERSION_MINOR values from a used protocol file.
   * If version information is not provided then Server assumes client uses 0.x version of the protocol
   * and may disable some new protocol features.
   */
  protocolVersionMinor?: number | undefined;
  protocolVersionMajor?:
    | number
    | undefined;
  /**
   * List of settings for a new session.
   * This field is associated with SessionSetting enum type.
   */
  sessionSettings: number[];
  /**
   * Real-time market data bandwidth limit (in KB per second).
   * Only one of max_collapsing_level and market_data_bandwidth can be specified.
   */
  marketDataBandwidth?:
    | number
    | undefined;
  /**
   * Dialect ID for used symbols.
   * CQG dialect is used if omitted.
   * Id for CQG dialect is 0.
   * Please contact CQG to get the list of supported dialects.
   */
  dialectId?:
    | string
    | undefined;
  /**
   * Fingerprint of a client application installation on a particular device.
   * This is just a string from the server perspective. Clients should make
   * sure it stays the same across runs of the application installation on
   * a specific device or Web application in a specific browser on this
   * device. Possible approaches to get the value on the client side:
   * http://clientjs.org/
   * https://developer.android.com/training/articles/user-data-ids.html
   */
  fingerprint?:
    | string
    | undefined;
  /** Client's operating system (with version, e.g. "Windows 7" or "Android"). */
  operatingSystem?:
    | string
    | undefined;
  /** Client's browser name (if applicable, without version, e.g. "Chrome" or "Firefox"). */
  browser?:
    | string
    | undefined;
  /** Client's device model (specific names for mobile, like "iPhone 7", or "PC" if not mobile/tablet). */
  deviceModel?: string | undefined;
}

export enum Logon_SessionSetting {
  /**
   * SESSION_SETTING_ALLOW_SESSION_RESTORE - Allows restoring session in case of an accidental disconnect (see RestoreOrJoinSession message).
   * If the setting is not specified then termination of the last connection
   * also terminates the session without ability to restore it.
   * For security reasons clients should not specify this setting without using the restore session functionality.
   */
  SESSION_SETTING_ALLOW_SESSION_RESTORE = 1,
  /**
   * SESSION_SETTING_ALLOW_SESSION_JOIN - Allows other new connections to join the session (see RestoreOrJoinSession message) and work concurrently.
   * If the setting is not specified then the session can have only one connection at each moment
   * and a new connection pushes the old one out (if the session restore is allowed).
   * For security reasons clients should not specify this setting without using join session functionality.
   */
  SESSION_SETTING_ALLOW_SESSION_JOIN = 2,
  UNRECOGNIZED = -1,
}

export function logon_SessionSettingFromJSON(object: any): Logon_SessionSetting {
  switch (object) {
    case 1:
    case "SESSION_SETTING_ALLOW_SESSION_RESTORE":
      return Logon_SessionSetting.SESSION_SETTING_ALLOW_SESSION_RESTORE;
    case 2:
    case "SESSION_SETTING_ALLOW_SESSION_JOIN":
      return Logon_SessionSetting.SESSION_SETTING_ALLOW_SESSION_JOIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Logon_SessionSetting.UNRECOGNIZED;
  }
}

export function logon_SessionSettingToJSON(object: Logon_SessionSetting): string {
  switch (object) {
    case Logon_SessionSetting.SESSION_SETTING_ALLOW_SESSION_RESTORE:
      return "SESSION_SETTING_ALLOW_SESSION_RESTORE";
    case Logon_SessionSetting.SESSION_SETTING_ALLOW_SESSION_JOIN:
      return "SESSION_SETTING_ALLOW_SESSION_JOIN";
    case Logon_SessionSetting.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Result of the logon operation (for Logon and LogonRoutineClient messages). */
export interface LogonResult {
  /**
   * One of the logon result codes.
   * This field is associated with ResultCode enum type.
   */
  resultCode: number;
  /**
   * Base time as ISO 8601 string (e.g. '2013-04-03T14:23:20')
   * all other times are number of milliseconds from this base time.
   */
  baseTime: string;
  /** Token of a new session if logon succeeded. */
  sessionToken?:
    | string
    | undefined;
  /** Optional logon result message for failure details. */
  textMessage?:
    | string
    | undefined;
  /** URL for a client to reconnect in case of redirection. */
  redirectUrl?:
    | string
    | undefined;
  /** Current protocol version of server. Normally client application need nothing to do with it. */
  protocolVersionMinor: number;
  protocolVersionMajor: number;
  /** Unique user identification. */
  userId?:
    | number
    | undefined;
  /** Time when the message was sent. */
  serverTime: number;
  /**
   * Timeout of session inactivity, number of minutes. This is clients'
   * responsibility to track activity and disable/close the session after
   * the timeout.
   */
  inactivityTimeout?: number | undefined;
}

export enum LogonResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - // success codes
   * User is logged in to the system.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - // failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  /**
   * RESULT_CODE_NO_ONETIME_PASSWORD - One-time password is required for this user but it was not sent, repeat logon
   * with one-time password.
   */
  RESULT_CODE_NO_ONETIME_PASSWORD = 103,
  /** RESULT_CODE_PASSWORD_EXPIRED - User password is expired, only change password operation is allowed. */
  RESULT_CODE_PASSWORD_EXPIRED = 104,
  /**
   * RESULT_CODE_CONCURRENT_SESSION - Failed because this user has a concurrent session that is
   * not allowed for this application and drop was not requested.
   */
  RESULT_CODE_CONCURRENT_SESSION = 105,
  /** RESULT_CODE_REDIRECTED - Client is redirected to a different server, new server is specified in redirectUrl. */
  RESULT_CODE_REDIRECTED = 106,
  /**
   * RESULT_CODE_ROUTINE_ERROR - The negotiation rules for LogonRoutineClient have been violated,
   * e.g. user has specified several fields at once in one message.
   */
  RESULT_CODE_ROUTINE_ERROR = 107,
  /** RESULT_CODE_ACCESS_TOKEN_EXPIRED - Access token is expired already. */
  RESULT_CODE_ACCESS_TOKEN_EXPIRED = 108,
  UNRECOGNIZED = -1,
}

export function logonResult_ResultCodeFromJSON(object: any): LogonResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return LogonResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case "RESULT_CODE_FAILURE":
      return LogonResult_ResultCode.RESULT_CODE_FAILURE;
    case 103:
    case "RESULT_CODE_NO_ONETIME_PASSWORD":
      return LogonResult_ResultCode.RESULT_CODE_NO_ONETIME_PASSWORD;
    case 104:
    case "RESULT_CODE_PASSWORD_EXPIRED":
      return LogonResult_ResultCode.RESULT_CODE_PASSWORD_EXPIRED;
    case 105:
    case "RESULT_CODE_CONCURRENT_SESSION":
      return LogonResult_ResultCode.RESULT_CODE_CONCURRENT_SESSION;
    case 106:
    case "RESULT_CODE_REDIRECTED":
      return LogonResult_ResultCode.RESULT_CODE_REDIRECTED;
    case 107:
    case "RESULT_CODE_ROUTINE_ERROR":
      return LogonResult_ResultCode.RESULT_CODE_ROUTINE_ERROR;
    case 108:
    case "RESULT_CODE_ACCESS_TOKEN_EXPIRED":
      return LogonResult_ResultCode.RESULT_CODE_ACCESS_TOKEN_EXPIRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LogonResult_ResultCode.UNRECOGNIZED;
  }
}

export function logonResult_ResultCodeToJSON(object: LogonResult_ResultCode): string {
  switch (object) {
    case LogonResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case LogonResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case LogonResult_ResultCode.RESULT_CODE_NO_ONETIME_PASSWORD:
      return "RESULT_CODE_NO_ONETIME_PASSWORD";
    case LogonResult_ResultCode.RESULT_CODE_PASSWORD_EXPIRED:
      return "RESULT_CODE_PASSWORD_EXPIRED";
    case LogonResult_ResultCode.RESULT_CODE_CONCURRENT_SESSION:
      return "RESULT_CODE_CONCURRENT_SESSION";
    case LogonResult_ResultCode.RESULT_CODE_REDIRECTED:
      return "RESULT_CODE_REDIRECTED";
    case LogonResult_ResultCode.RESULT_CODE_ROUTINE_ERROR:
      return "RESULT_CODE_ROUTINE_ERROR";
    case LogonResult_ResultCode.RESULT_CODE_ACCESS_TOKEN_EXPIRED:
      return "RESULT_CODE_ACCESS_TOKEN_EXPIRED";
    case LogonResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Message for multi-step logon which user should use in case if it is required to use encoded password.
 * Multi-step logon sequence has the following steps:
 *      1) Client initiates logon negotiation by sending LogonRoutineClient.logon_init message to the server.
 *      2) Server sends one or more LogonRoutineServer.password_request messages (this step may repeat after step #3).
 *      3) Client sends list of LogonRoutineClient.password_response back to the server.
 *      4) Server optionally asks accept trader's agreement (LogonRoutineServer.trader_agreement_url).
 *      5) Client responds with LogonRoutineClient.trader_agreement_accepted equal to True
 *         if the user accepts agreement, False or LogonRoutineClient.abort_logon otherwise.
 *      6) Server sends LogonRoutineServer.logon_result as the final message of the negotiation with the results.
 *   Client may stop the negotiation by sending LogonRoutineClient.abort_logon message in response to any server
 *      LogonRoutineServer.password_request or LogonRoutineServer.trader_agreement_url requests.
 * NOTE: only one of fields has to be specified.
 */
export interface LogonRoutineClient {
  /** Initiating of multi-step logon process by client. */
  logonInit?:
    | LogonInit
    | undefined;
  /**
   * List of passwords in response to request from server.
   * The number of passwords in response should be equal to the number of passwords in request from server.
   */
  passwordResponses: PasswordResponse[];
  /** True means user has accepted the trader agreement. */
  traderAgreementAccepted?:
    | boolean
    | undefined;
  /** Aborting of multi-step logon process by client. */
  abortLogon?: AbortRoutine | undefined;
}

/** Response to the LogonRoutineClient message. Only one of fields is specified. */
export interface LogonRoutineServer {
  /** Final message of the negotiation with the results. */
  logonResult?:
    | LogonResult
    | undefined;
  /** List of requested passwords. */
  passwordRequests: PasswordRequest[];
  /**
   * URL pointing to the location of help information for this version of agreement.
   * Specified if user should accept trader's agreement in order to continue logon process.
   */
  traderAgreementUrl?: string | undefined;
}

/**
 * Message for multi-step password change which user should use in case if it is required to use encoded password.
 * Multi-step password change sequence has the following steps:
 *      1) Client initiates password change procedure by sending
 *         PasswordChangeRoutineClient.init_password_change to the server.
 *      2) Server sends one or more PasswordChangeRoutineServer.password_request messages
 *         (this step may repeat after step #3).
 *      3) Client sends list of PasswordChangeRoutineClient.password_response back to the server.
 *      4) Server sends PasswordChangeRoutineServer.password_change_result
 *         as the final message of the negotiation with the results.
 *         Client may stop the negotiation by sending
 *         PasswordChangeRoutineClient.abort_password_change message in response to any server
 *         PasswordChangeRoutineServer.password_request message.
 * NOTE: only one of fields has to be specified.
 */
export interface PasswordChangeRoutineClient {
  /** True means user has decided to initiate password changing procedure. */
  initPasswordChange?:
    | boolean
    | undefined;
  /**
   * List of passwords in response to request from server.
   * The number of passwords in response should be equal to the number of passwords in request from server.
   */
  passwordResponses: PasswordResponse[];
  /** Aborting of multi-step password change process by client. */
  abortPasswordChange?: AbortRoutine | undefined;
}

/** Response to the PasswordChangeRoutineClient message. Only one of fields is specified. */
export interface PasswordChangeRoutineServer {
  /** List of requested passwords. */
  passwordRequests: PasswordRequest[];
  /** Final message of the negotiation with the results. */
  passwordChangeResult?: PasswordChangeResult | undefined;
}

/** This message indicates that user has decided to abort the logon or password change processes. */
export interface AbortRoutine {
  /** Reason of logon or password change routine abort. */
  comment?: string | undefined;
}

/** Initiating of multi-step logon process by client. See description of fields in Logon message. */
export interface LogonInit {
  userName: string;
  privateLabel?: string | undefined;
  clientAppId: string;
  clientVersion: string;
  dropConcurrentSession?: boolean | undefined;
  maxCollapsingLevel?: number | undefined;
  protocolVersionMinor?: number | undefined;
  protocolVersionMajor?: number | undefined;
  sessionSettings: number[];
  marketDataBandwidth?: number | undefined;
  dialectId?: string | undefined;
  fingerprint?: string | undefined;
  operatingSystem?: string | undefined;
  browser?: string | undefined;
  deviceModel?: string | undefined;
}

/** Parameters of requesting password from user. */
export interface PasswordRequest {
  /** ID of a request. */
  requestId: number;
  /**
   * Type of password which server requests from the user.
   * This field is associated with PasswordType enum type.
   */
  passwordType: number;
  /** Commonly understood code that can be used to show an additional localized prompt for certain password types. */
  userPromptType?:
    | string
    | undefined;
  /**
   * Alternate English text for clients which do not recognize user_prompt_type.
   * This text can be displayed as-is to the user.
   * NOTE: user_prompt_type and user_prompt_type_alt are a pair (so either none or both are provided).
   */
  userPromptTypeAlt?:
    | string
    | undefined;
  /**
   * Text that should be displayed to the user as-is along with the localized prompt implied by user_prompt_type.
   * If user_prompt_type_alt is used instead, this text should be displayed after it.
   * user_prompt will only be provided if user_prompt_type and user_prompt_type_alt are provided,
   * but it is optional even then.
   */
  userPrompt?:
    | string
    | undefined;
  /**
   * Set of supported encodings for this request.
   * Client should choose the encoding with highest priority from among those it implements.
   */
  encodings: Encoding[];
}

export enum PasswordRequest_PasswordType {
  /** PASSWORD_TYPE_STATIC - Currently used password. */
  PASSWORD_TYPE_STATIC = 1,
  /** PASSWORD_TYPE_ONE_TIME - One-time password. */
  PASSWORD_TYPE_ONE_TIME = 2,
  /** PASSWORD_TYPE_NEW_STATIC - New password that user wants to use. Can be specified only in result of PasswordChangeRoutineClient. */
  PASSWORD_TYPE_NEW_STATIC = 3,
  /**
   * PASSWORD_TYPE_CUR_AND_NEW_STATIC - Currently used password and new password that user wants to use.
   * Can be specified only in result of PasswordChangeRoutineClient.
   */
  PASSWORD_TYPE_CUR_AND_NEW_STATIC = 4,
  /** PASSWORD_TYPE_TEXT - Non-password request with question e.g. "What is your favorite pet?". */
  PASSWORD_TYPE_TEXT = 5,
  UNRECOGNIZED = -1,
}

export function passwordRequest_PasswordTypeFromJSON(object: any): PasswordRequest_PasswordType {
  switch (object) {
    case 1:
    case "PASSWORD_TYPE_STATIC":
      return PasswordRequest_PasswordType.PASSWORD_TYPE_STATIC;
    case 2:
    case "PASSWORD_TYPE_ONE_TIME":
      return PasswordRequest_PasswordType.PASSWORD_TYPE_ONE_TIME;
    case 3:
    case "PASSWORD_TYPE_NEW_STATIC":
      return PasswordRequest_PasswordType.PASSWORD_TYPE_NEW_STATIC;
    case 4:
    case "PASSWORD_TYPE_CUR_AND_NEW_STATIC":
      return PasswordRequest_PasswordType.PASSWORD_TYPE_CUR_AND_NEW_STATIC;
    case 5:
    case "PASSWORD_TYPE_TEXT":
      return PasswordRequest_PasswordType.PASSWORD_TYPE_TEXT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PasswordRequest_PasswordType.UNRECOGNIZED;
  }
}

export function passwordRequest_PasswordTypeToJSON(object: PasswordRequest_PasswordType): string {
  switch (object) {
    case PasswordRequest_PasswordType.PASSWORD_TYPE_STATIC:
      return "PASSWORD_TYPE_STATIC";
    case PasswordRequest_PasswordType.PASSWORD_TYPE_ONE_TIME:
      return "PASSWORD_TYPE_ONE_TIME";
    case PasswordRequest_PasswordType.PASSWORD_TYPE_NEW_STATIC:
      return "PASSWORD_TYPE_NEW_STATIC";
    case PasswordRequest_PasswordType.PASSWORD_TYPE_CUR_AND_NEW_STATIC:
      return "PASSWORD_TYPE_CUR_AND_NEW_STATIC";
    case PasswordRequest_PasswordType.PASSWORD_TYPE_TEXT:
      return "PASSWORD_TYPE_TEXT";
    case PasswordRequest_PasswordType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Response to the PasswordRequest message. */
export interface PasswordResponse {
  /** ID of corresponding password request. */
  requestId: number;
  /** Commonly understood string that identifies the password encoding method. */
  encodingType: string;
  /** User's password encoded with specified encoding method. */
  password: string;
}

/** Description of the encoding method. */
export interface Encoding {
  /**
   * Commonly understood string that identifies the encoding method.
   * The following encodings are available: "none", "ds3_rsa", "isprint_e2ee".
   */
  encodingType: string;
  /** The priority for this encoding, value 1 is highest priority, 2 is next priority, etc. */
  priorityRank: number;
  /** Encoding parameters for this encoding method. */
  encodingParameters: EncodingParameter[];
}

/** The encoding parameter. */
export interface EncodingParameter {
  /** Encoding parameter's name. */
  name: string;
  /** Encoding parameter's value. */
  value: string;
}

/**
 * Client request to restore session because of accidental disconnect
 * or to join additional concurrent connection to existing session.
 * This message allows to avoid prompting user for [one-time] password.
 * In case of session restore client application has to [re-]subscribe and [re-]request necessary data.
 * Permissions to use this functionality is regulated
 * by ALLOW_SESSION_RESTORE/ALLOW_SESSION_JOIN session settings which are specified in Logon or LogonInit message.
 */
export interface RestoreOrJoinSession {
  /** Token of the session to restore/join, it has limited lifetime after disconnect of the last connection. */
  sessionToken: string;
  /**
   * Private label identifies the Firm or Vendor that connects to the server,
   * client_app_id field value is taken by default.
   */
  privateLabel?:
    | string
    | undefined;
  /**
   * Identifier of the client application as assigned by CQG.
   * This field is required to be specified and left optional only for backward compatibility.
   */
  clientAppId?:
    | string
    | undefined;
  /**
   * Maximum allowed real-time market data collapsing level.
   * REAL_TIME_COLLAPSING_LEVEL_DOM_BBA_TRADES is the default allowed collapsing level.
   * This field is associated with RealTimeCollapsingLevel enum type.
   * Only one of max_collapsing_level and market_data_bandwidth can be specified.
   */
  maxCollapsingLevel?:
    | number
    | undefined;
  /**
   * Current protocol version that is used by the client application.
   * It is strongly recommended for client applications
   * to put PROTOCOL_VERSION_MAJOR and PROTOCOL_VERSION_MINOR values from a used protocol file.
   * If version information is not provided
   * then Server assumes client uses 0.x version of the protocol and may disable some new protocol features.
   */
  protocolVersionMinor?: number | undefined;
  protocolVersionMajor?:
    | number
    | undefined;
  /**
   * Real-time market data bandwidth limit (in KB per second).
   * Only one of max_collapsing_level and market_data_bandwidth can be specified.
   */
  marketDataBandwidth?: number | undefined;
}

/** Result of session restore or join attempt. */
export interface RestoreOrJoinSessionResult {
  /**
   * Session restore/join result.
   * This field is associated with ResultCode enum type.
   */
  resultCode: number;
  /** Session base time (see LogonResult.base_time). */
  baseTime: string;
  /** Optional failure details. */
  textMessage?:
    | string
    | undefined;
  /** Time when the message was sent. */
  serverTime: number;
  /** Session default dialect ID for used symbols (see Logon.dialect_id). */
  dialectId?:
    | string
    | undefined;
  /**
   * Timeout of session inactivity, number of minutes. This is clients'
   * responsibility to track activity and disable/close the session after
   * the timeout (see LogonResult.inactivity_timeout).
   */
  inactivityTimeout?: number | undefined;
}

export enum RestoreOrJoinSessionResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - // success codes
   * User is logged in to the system.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - // failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 104,
  /** RESULT_CODE_UNKNOWN_SESSION - Session is unknown to a server, client must perform standard logon procedure. */
  RESULT_CODE_UNKNOWN_SESSION = 101,
  /** RESULT_CODE_ACCESS_DENIED - User is not allowed to perform session restore/join or the maximum connection number has been exceeded. */
  RESULT_CODE_ACCESS_DENIED = 102,
  /** RESULT_CODE_INVALID_PARAMS - Some RestoreOrJoinSession message fields were not filled. */
  RESULT_CODE_INVALID_PARAMS = 103,
  UNRECOGNIZED = -1,
}

export function restoreOrJoinSessionResult_ResultCodeFromJSON(object: any): RestoreOrJoinSessionResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_SUCCESS;
    case 104:
    case "RESULT_CODE_FAILURE":
      return RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_FAILURE;
    case 101:
    case "RESULT_CODE_UNKNOWN_SESSION":
      return RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_UNKNOWN_SESSION;
    case 102:
    case "RESULT_CODE_ACCESS_DENIED":
      return RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_ACCESS_DENIED;
    case 103:
    case "RESULT_CODE_INVALID_PARAMS":
      return RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_INVALID_PARAMS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RestoreOrJoinSessionResult_ResultCode.UNRECOGNIZED;
  }
}

export function restoreOrJoinSessionResult_ResultCodeToJSON(object: RestoreOrJoinSessionResult_ResultCode): string {
  switch (object) {
    case RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_UNKNOWN_SESSION:
      return "RESULT_CODE_UNKNOWN_SESSION";
    case RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_ACCESS_DENIED:
      return "RESULT_CODE_ACCESS_DENIED";
    case RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_INVALID_PARAMS:
      return "RESULT_CODE_INVALID_PARAMS";
    case RestoreOrJoinSessionResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Notifies about joining of a concurrent connection to the session (see RestoreOrJoinSession). */
export interface ConcurrentConnectionJoin {
  /**
   * Shows if new connection has been initiated by an application of the same type
   * (with the same private_label and client_app_id fields).
   */
  isSameAppType: boolean;
}

/** Client request to logoff from the system and close the session. */
export interface Logoff {
  /** Optional logoff reason. */
  textMessage?: string | undefined;
}

/**
 * Server notification about closing user's session,
 * server closes connection after this message.
 */
export interface LoggedOff {
  /**
   * One of the logoff reasons.
   * This field is associated with LogoffReason enum type.
   */
  logoffReason: number;
  /** Optional logoff details. */
  textMessage?:
    | string
    | undefined;
  /** URL for redirection. */
  redirectUrl?: string | undefined;
}

export enum LoggedOff_LogoffReason {
  /** LOGOFF_REASON_BY_REQUEST - User is logged off by client request. */
  LOGOFF_REASON_BY_REQUEST = 1,
  /** LOGOFF_REASON_REDIRECTED - User is redirected to another URL. */
  LOGOFF_REASON_REDIRECTED = 2,
  /** LOGOFF_REASON_FORCED - System logged off the user because of some server side reason. */
  LOGOFF_REASON_FORCED = 3,
  /**
   * LOGOFF_REASON_REASSIGNED - System logged off the user because this session was re-assigned to another connection
   * that took it over by 'restore session' operation.
   */
  LOGOFF_REASON_REASSIGNED = 4,
  UNRECOGNIZED = -1,
}

export function loggedOff_LogoffReasonFromJSON(object: any): LoggedOff_LogoffReason {
  switch (object) {
    case 1:
    case "LOGOFF_REASON_BY_REQUEST":
      return LoggedOff_LogoffReason.LOGOFF_REASON_BY_REQUEST;
    case 2:
    case "LOGOFF_REASON_REDIRECTED":
      return LoggedOff_LogoffReason.LOGOFF_REASON_REDIRECTED;
    case 3:
    case "LOGOFF_REASON_FORCED":
      return LoggedOff_LogoffReason.LOGOFF_REASON_FORCED;
    case 4:
    case "LOGOFF_REASON_REASSIGNED":
      return LoggedOff_LogoffReason.LOGOFF_REASON_REASSIGNED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LoggedOff_LogoffReason.UNRECOGNIZED;
  }
}

export function loggedOff_LogoffReasonToJSON(object: LoggedOff_LogoffReason): string {
  switch (object) {
    case LoggedOff_LogoffReason.LOGOFF_REASON_BY_REQUEST:
      return "LOGOFF_REASON_BY_REQUEST";
    case LoggedOff_LogoffReason.LOGOFF_REASON_REDIRECTED:
      return "LOGOFF_REASON_REDIRECTED";
    case LoggedOff_LogoffReason.LOGOFF_REASON_FORCED:
      return "LOGOFF_REASON_FORCED";
    case LoggedOff_LogoffReason.LOGOFF_REASON_REASSIGNED:
      return "LOGOFF_REASON_REASSIGNED";
    case LoggedOff_LogoffReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Message to change user's password. */
export interface PasswordChange {
  /** Currently used password. */
  oldPassword: string;
  /** New password that user wants to use. */
  newPassword: string;
}

/** Result of user password change (for PasswordChange and PasswordChangeRoutineClient messages). */
export interface PasswordChangeResult {
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** Optional failure details. */
  textMessage?: string | undefined;
}

/** Password change result. */
export enum PasswordChangeResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - / success codes
   * User password is changed successfully.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - / failure codes (100+)
   * Incorrect user name or password.
   */
  RESULT_CODE_FAILURE = 101,
  /**
   * RESULT_CODE_ROUTINE_ERROR - The negotiation rules for PasswordChangeRoutineClient have been violated,
   * e.g. user has specified several fields at once in one message.
   */
  RESULT_CODE_ROUTINE_ERROR = 102,
  UNRECOGNIZED = -1,
}

export function passwordChangeResult_ResultCodeFromJSON(object: any): PasswordChangeResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return PasswordChangeResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case "RESULT_CODE_FAILURE":
      return PasswordChangeResult_ResultCode.RESULT_CODE_FAILURE;
    case 102:
    case "RESULT_CODE_ROUTINE_ERROR":
      return PasswordChangeResult_ResultCode.RESULT_CODE_ROUTINE_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PasswordChangeResult_ResultCode.UNRECOGNIZED;
  }
}

export function passwordChangeResult_ResultCodeToJSON(object: PasswordChangeResult_ResultCode): string {
  switch (object) {
    case PasswordChangeResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case PasswordChangeResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case PasswordChangeResult_ResultCode.RESULT_CODE_ROUTINE_ERROR:
      return "RESULT_CODE_ROUTINE_ERROR";
    case PasswordChangeResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Request for temporary user name and password for demo.
 * Clients of ObtainDemoCredentials are responsible to verify provided e-mail
 * (e.g. by sending passwords by e-mail only) and prevent possible abuse.
 * ObtainDemoCredentials requests are accepted
 * only if client IP address is in the list of allowed IP addresses for service requests.
 */
export interface ObtainDemoCredentials {
  /** Identifier of the client application as assigned by CQG. */
  clientAppId: string;
  /** User first name. */
  firstName: string;
  /** User second name. */
  secondName: string;
  /** E-mail address of a user who requested demo access. */
  eMail: string;
  /**
   * Private label identifies the Firm or Vendor that connects to the server,
   * client_app_id field value is taken by default.
   */
  privateLabel?: string | undefined;
}

export interface ObtainDemoCredentialsResult {
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** demo user name. */
  userName?:
    | string
    | undefined;
  /** demo password. */
  password?:
    | string
    | undefined;
  /** Optional details. */
  textMessage?:
    | string
    | undefined;
  /**
   * Unique user identification.
   * Required in case of success.
   */
  userId?:
    | number
    | undefined;
  /** Expiration date/time in UTC as ISO 8601 string (e.g. '2013-04-03T14:23:20Z'). */
  expirationUtc?: string | undefined;
}

/** Password change result. */
export enum ObtainDemoCredentialsResult_ResultCode {
  /** RESULT_CODE_SUCCESS - / success codes (0 - 100) */
  RESULT_CODE_SUCCESS = 0,
  /** RESULT_CODE_FAILURE - / failure codes (100+) */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function obtainDemoCredentialsResult_ResultCodeFromJSON(object: any): ObtainDemoCredentialsResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return ObtainDemoCredentialsResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case "RESULT_CODE_FAILURE":
      return ObtainDemoCredentialsResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ObtainDemoCredentialsResult_ResultCode.UNRECOGNIZED;
  }
}

export function obtainDemoCredentialsResult_ResultCodeToJSON(object: ObtainDemoCredentialsResult_ResultCode): string {
  switch (object) {
    case ObtainDemoCredentialsResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case ObtainDemoCredentialsResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case ObtainDemoCredentialsResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Text message from server. */
export interface UserMessage {
  /** This field is associated with MessageType enum type. */
  messageType: number;
  /** Information about source of the message. */
  source: string;
  /** Message subject. */
  subject?:
    | string
    | undefined;
  /** Message text. */
  text: string;
  /** Optional time when this message is expired, it should be hidden from user after this time (UTC). */
  expirationUtcTime?: number | undefined;
}

/** Type of the message. */
export enum UserMessage_MessageType {
  /**
   * MESSAGE_TYPE_CRITICAL_ERROR - Critical error message. Informs the user of a significant problem on an exchange or within CQG infrastructure.
   * Possible problems: incomplete order or position information; a critical message from CQG Customer support;
   * technical problems or news from an exchange; a critical message from exchange; do not logoff when this occurs.
   * a critical problem loading trading or data enablements; protocol violation.
   */
  MESSAGE_TYPE_CRITICAL_ERROR = 1,
  /**
   * MESSAGE_TYPE_WARNING - Warning. Informs the user of an unexpected situation that does not affect the correctness of data, or a warning
   * message from CQG Customer support.
   */
  MESSAGE_TYPE_WARNING = 2,
  /**
   * MESSAGE_TYPE_INFO - General information. Informs the user of an intermittent problem with the realtime data,
   * or an informational message from CQG Customer support.
   */
  MESSAGE_TYPE_INFO = 3,
  /** MESSAGE_TYPE_LOG - This message shouldn't be visible for users, only dumped into a log file. */
  MESSAGE_TYPE_LOG = 4,
  UNRECOGNIZED = -1,
}

export function userMessage_MessageTypeFromJSON(object: any): UserMessage_MessageType {
  switch (object) {
    case 1:
    case "MESSAGE_TYPE_CRITICAL_ERROR":
      return UserMessage_MessageType.MESSAGE_TYPE_CRITICAL_ERROR;
    case 2:
    case "MESSAGE_TYPE_WARNING":
      return UserMessage_MessageType.MESSAGE_TYPE_WARNING;
    case 3:
    case "MESSAGE_TYPE_INFO":
      return UserMessage_MessageType.MESSAGE_TYPE_INFO;
    case 4:
    case "MESSAGE_TYPE_LOG":
      return UserMessage_MessageType.MESSAGE_TYPE_LOG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserMessage_MessageType.UNRECOGNIZED;
  }
}

export function userMessage_MessageTypeToJSON(object: UserMessage_MessageType): string {
  switch (object) {
    case UserMessage_MessageType.MESSAGE_TYPE_CRITICAL_ERROR:
      return "MESSAGE_TYPE_CRITICAL_ERROR";
    case UserMessage_MessageType.MESSAGE_TYPE_WARNING:
      return "MESSAGE_TYPE_WARNING";
    case UserMessage_MessageType.MESSAGE_TYPE_INFO:
      return "MESSAGE_TYPE_INFO";
    case UserMessage_MessageType.MESSAGE_TYPE_LOG:
      return "MESSAGE_TYPE_LOG";
    case UserMessage_MessageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Request information about user session.
 * UserSessionState requests are accepted
 * only if client ip address is in the list of allowed IP addresses for service requests.
 */
export interface UserSessionStateRequest {
  sessionToken: string;
}

export interface UserSessionStateResult {
  /** Session token from UserSessionStateRequest. */
  sessionToken: string;
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** / User session information. */
  userId?: number | undefined;
  username?: string | undefined;
}

export enum UserSessionStateResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - // success codes
   * Data for requested session is found.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - // failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  /** RESULT_CODE_UNKNOWN_SESSION - Session is unknown to a server. */
  RESULT_CODE_UNKNOWN_SESSION = 102,
  /** RESULT_CODE_ACCESS_DENIED - The user is not allowed to access this data. */
  RESULT_CODE_ACCESS_DENIED = 103,
  UNRECOGNIZED = -1,
}

export function userSessionStateResult_ResultCodeFromJSON(object: any): UserSessionStateResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return UserSessionStateResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case "RESULT_CODE_FAILURE":
      return UserSessionStateResult_ResultCode.RESULT_CODE_FAILURE;
    case 102:
    case "RESULT_CODE_UNKNOWN_SESSION":
      return UserSessionStateResult_ResultCode.RESULT_CODE_UNKNOWN_SESSION;
    case 103:
    case "RESULT_CODE_ACCESS_DENIED":
      return UserSessionStateResult_ResultCode.RESULT_CODE_ACCESS_DENIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserSessionStateResult_ResultCode.UNRECOGNIZED;
  }
}

export function userSessionStateResult_ResultCodeToJSON(object: UserSessionStateResult_ResultCode): string {
  switch (object) {
    case UserSessionStateResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case UserSessionStateResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case UserSessionStateResult_ResultCode.RESULT_CODE_UNKNOWN_SESSION:
      return "RESULT_CODE_UNKNOWN_SESSION";
    case UserSessionStateResult_ResultCode.RESULT_CODE_ACCESS_DENIED:
      return "RESULT_CODE_ACCESS_DENIED";
    case UserSessionStateResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Ping request which can be initiated by any side to ensure connection is alive. Both client and
 * server sides must respond such request with Pong.
 */
export interface Ping {
  /** Token routed back in Pong response. */
  token?:
    | string
    | undefined;
  /** UTC time when this request was composed. Used for network latency diagnostics. */
  pingUtcTime: number;
}

/** Pong response which must be sent in response on Ping request. */
export interface Pong {
  /** Token passed in Ping request. */
  token?:
    | string
    | undefined;
  /** Corresponding value from Ping request. */
  pingUtcTime: number;
  /** UTC time when this response was composed. */
  pongUtcTime: number;
}

/**
 * Message to change max collapsing level for real-time market data.
 * If "market_data_bandwidth" was set for the session in Logon or RestoreOrJoinSession message, the request will fail.
 */
export interface SetCollapsingLevelRequest {
  /**
   * Max collapsing level.
   * This field is associated with RealTimeCollapsingLevel enum type.
   * If max_collapsing_level is omitted then REAL_TIME_COLLAPSING_LEVEL_NONE will be used.
   */
  maxCollapsingLevel?: number | undefined;
}

/** Response to SetCollapsingLevelRequest */
export interface SetCollapsingLevelResult {
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** Possible details of status or failure. */
  textMessage?:
    | Text
    | undefined;
  /**
   * Current value of max collapsing level.
   * Field is set if result_code is RESULT_CODE_SUCCESS.
   * If result_code is RESULT_CODE_SUCCESS and max_collapsing_level is omitted
   * it should be treated as a REAL_TIME_COLLAPSING_LEVEL_NONE value.
   */
  maxCollapsingLevel?: number | undefined;
}

export enum SetCollapsingLevelResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - // success codes
   * Maximum collapsing level has been successfully changed.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - // failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  /**
   * RESULT_CODE_BANDWIDTH_IS_SET - market_data_bandwidth was set for the session in Logon or RestoreOrJoinSession message.
   * Collapsing level cannot be set when market_data_bandwidth is set in Logon or RestoreOrJoinSession message.
   */
  RESULT_CODE_BANDWIDTH_IS_SET = 102,
  UNRECOGNIZED = -1,
}

export function setCollapsingLevelResult_ResultCodeFromJSON(object: any): SetCollapsingLevelResult_ResultCode {
  switch (object) {
    case 0:
    case "RESULT_CODE_SUCCESS":
      return SetCollapsingLevelResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case "RESULT_CODE_FAILURE":
      return SetCollapsingLevelResult_ResultCode.RESULT_CODE_FAILURE;
    case 102:
    case "RESULT_CODE_BANDWIDTH_IS_SET":
      return SetCollapsingLevelResult_ResultCode.RESULT_CODE_BANDWIDTH_IS_SET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SetCollapsingLevelResult_ResultCode.UNRECOGNIZED;
  }
}

export function setCollapsingLevelResult_ResultCodeToJSON(object: SetCollapsingLevelResult_ResultCode): string {
  switch (object) {
    case SetCollapsingLevelResult_ResultCode.RESULT_CODE_SUCCESS:
      return "RESULT_CODE_SUCCESS";
    case SetCollapsingLevelResult_ResultCode.RESULT_CODE_FAILURE:
      return "RESULT_CODE_FAILURE";
    case SetCollapsingLevelResult_ResultCode.RESULT_CODE_BANDWIDTH_IS_SET:
      return "RESULT_CODE_BANDWIDTH_IS_SET";
    case SetCollapsingLevelResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseLogon(): Logon {
  return {
    userName: "",
    password: "",
    accessToken: "",
    partnerToken: "",
    oneTimePassword: "",
    privateLabel: "",
    clientAppId: "",
    clientVersion: "",
    dropConcurrentSession: false,
    maxCollapsingLevel: 0,
    protocolVersionMinor: 0,
    protocolVersionMajor: 0,
    sessionSettings: [],
    marketDataBandwidth: 0,
    dialectId: "",
    fingerprint: "",
    operatingSystem: "",
    browser: "",
    deviceModel: "",
  };
}

export const Logon = {
  encode(message: Logon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userName !== undefined && message.userName !== "") {
      writer.uint32(10).string(message.userName);
    }
    if (message.password !== undefined && message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    if (message.accessToken !== undefined && message.accessToken !== "") {
      writer.uint32(114).string(message.accessToken);
    }
    if (message.partnerToken !== undefined && message.partnerToken !== "") {
      writer.uint32(154).string(message.partnerToken);
    }
    if (message.oneTimePassword !== undefined && message.oneTimePassword !== "") {
      writer.uint32(26).string(message.oneTimePassword);
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      writer.uint32(34).string(message.privateLabel);
    }
    if (message.clientAppId !== undefined && message.clientAppId !== "") {
      writer.uint32(42).string(message.clientAppId);
    }
    if (message.clientVersion !== "") {
      writer.uint32(50).string(message.clientVersion);
    }
    if (message.dropConcurrentSession !== undefined && message.dropConcurrentSession !== false) {
      writer.uint32(56).bool(message.dropConcurrentSession);
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      writer.uint32(64).uint32(message.maxCollapsingLevel);
    }
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      writer.uint32(72).uint32(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      writer.uint32(80).uint32(message.protocolVersionMajor);
    }
    writer.uint32(90).fork();
    for (const v of message.sessionSettings) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.marketDataBandwidth !== undefined && message.marketDataBandwidth !== 0) {
      writer.uint32(96).uint32(message.marketDataBandwidth);
    }
    if (message.dialectId !== undefined && message.dialectId !== "") {
      writer.uint32(106).string(message.dialectId);
    }
    if (message.fingerprint !== undefined && message.fingerprint !== "") {
      writer.uint32(122).string(message.fingerprint);
    }
    if (message.operatingSystem !== undefined && message.operatingSystem !== "") {
      writer.uint32(130).string(message.operatingSystem);
    }
    if (message.browser !== undefined && message.browser !== "") {
      writer.uint32(138).string(message.browser);
    }
    if (message.deviceModel !== undefined && message.deviceModel !== "") {
      writer.uint32(146).string(message.deviceModel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Logon {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.partnerToken = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.oneTimePassword = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.privateLabel = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientAppId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.clientVersion = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.dropConcurrentSession = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.maxCollapsingLevel = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.protocolVersionMinor = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.protocolVersionMajor = reader.uint32();
          continue;
        case 11:
          if (tag === 88) {
            message.sessionSettings.push(reader.uint32());

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sessionSettings.push(reader.uint32());
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.marketDataBandwidth = reader.uint32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.dialectId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.operatingSystem = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.browser = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.deviceModel = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Logon {
    return {
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      accessToken: isSet(object.accessToken) ? globalThis.String(object.accessToken) : "",
      partnerToken: isSet(object.partnerToken) ? globalThis.String(object.partnerToken) : "",
      oneTimePassword: isSet(object.oneTimePassword) ? globalThis.String(object.oneTimePassword) : "",
      privateLabel: isSet(object.privateLabel) ? globalThis.String(object.privateLabel) : "",
      clientAppId: isSet(object.clientAppId) ? globalThis.String(object.clientAppId) : "",
      clientVersion: isSet(object.clientVersion) ? globalThis.String(object.clientVersion) : "",
      dropConcurrentSession: isSet(object.dropConcurrentSession)
        ? globalThis.Boolean(object.dropConcurrentSession)
        : false,
      maxCollapsingLevel: isSet(object.maxCollapsingLevel) ? globalThis.Number(object.maxCollapsingLevel) : 0,
      protocolVersionMinor: isSet(object.protocolVersionMinor) ? globalThis.Number(object.protocolVersionMinor) : 0,
      protocolVersionMajor: isSet(object.protocolVersionMajor) ? globalThis.Number(object.protocolVersionMajor) : 0,
      sessionSettings: globalThis.Array.isArray(object?.sessionSettings)
        ? object.sessionSettings.map((e: any) => globalThis.Number(e))
        : [],
      marketDataBandwidth: isSet(object.marketDataBandwidth) ? globalThis.Number(object.marketDataBandwidth) : 0,
      dialectId: isSet(object.dialectId) ? globalThis.String(object.dialectId) : "",
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      operatingSystem: isSet(object.operatingSystem) ? globalThis.String(object.operatingSystem) : "",
      browser: isSet(object.browser) ? globalThis.String(object.browser) : "",
      deviceModel: isSet(object.deviceModel) ? globalThis.String(object.deviceModel) : "",
    };
  },

  toJSON(message: Logon): unknown {
    const obj: any = {};
    if (message.userName !== undefined && message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.password !== undefined && message.password !== "") {
      obj.password = message.password;
    }
    if (message.accessToken !== undefined && message.accessToken !== "") {
      obj.accessToken = message.accessToken;
    }
    if (message.partnerToken !== undefined && message.partnerToken !== "") {
      obj.partnerToken = message.partnerToken;
    }
    if (message.oneTimePassword !== undefined && message.oneTimePassword !== "") {
      obj.oneTimePassword = message.oneTimePassword;
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      obj.privateLabel = message.privateLabel;
    }
    if (message.clientAppId !== undefined && message.clientAppId !== "") {
      obj.clientAppId = message.clientAppId;
    }
    if (message.clientVersion !== "") {
      obj.clientVersion = message.clientVersion;
    }
    if (message.dropConcurrentSession !== undefined && message.dropConcurrentSession !== false) {
      obj.dropConcurrentSession = message.dropConcurrentSession;
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      obj.maxCollapsingLevel = Math.round(message.maxCollapsingLevel);
    }
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      obj.protocolVersionMinor = Math.round(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      obj.protocolVersionMajor = Math.round(message.protocolVersionMajor);
    }
    if (message.sessionSettings?.length) {
      obj.sessionSettings = message.sessionSettings.map((e) => Math.round(e));
    }
    if (message.marketDataBandwidth !== undefined && message.marketDataBandwidth !== 0) {
      obj.marketDataBandwidth = Math.round(message.marketDataBandwidth);
    }
    if (message.dialectId !== undefined && message.dialectId !== "") {
      obj.dialectId = message.dialectId;
    }
    if (message.fingerprint !== undefined && message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.operatingSystem !== undefined && message.operatingSystem !== "") {
      obj.operatingSystem = message.operatingSystem;
    }
    if (message.browser !== undefined && message.browser !== "") {
      obj.browser = message.browser;
    }
    if (message.deviceModel !== undefined && message.deviceModel !== "") {
      obj.deviceModel = message.deviceModel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Logon>, I>>(base?: I): Logon {
    return Logon.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Logon>, I>>(object: I): Logon {
    const message = createBaseLogon();
    message.userName = object.userName ?? "";
    message.password = object.password ?? "";
    message.accessToken = object.accessToken ?? "";
    message.partnerToken = object.partnerToken ?? "";
    message.oneTimePassword = object.oneTimePassword ?? "";
    message.privateLabel = object.privateLabel ?? "";
    message.clientAppId = object.clientAppId ?? "";
    message.clientVersion = object.clientVersion ?? "";
    message.dropConcurrentSession = object.dropConcurrentSession ?? false;
    message.maxCollapsingLevel = object.maxCollapsingLevel ?? 0;
    message.protocolVersionMinor = object.protocolVersionMinor ?? 0;
    message.protocolVersionMajor = object.protocolVersionMajor ?? 0;
    message.sessionSettings = object.sessionSettings?.map((e) => e) || [];
    message.marketDataBandwidth = object.marketDataBandwidth ?? 0;
    message.dialectId = object.dialectId ?? "";
    message.fingerprint = object.fingerprint ?? "";
    message.operatingSystem = object.operatingSystem ?? "";
    message.browser = object.browser ?? "";
    message.deviceModel = object.deviceModel ?? "";
    return message;
  },
};

function createBaseLogonResult(): LogonResult {
  return {
    resultCode: 0,
    baseTime: "",
    sessionToken: "",
    textMessage: "",
    redirectUrl: "",
    protocolVersionMinor: 0,
    protocolVersionMajor: 0,
    userId: 0,
    serverTime: 0,
    inactivityTimeout: 0,
  };
}

export const LogonResult = {
  encode(message: LogonResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCode !== 0) {
      writer.uint32(8).uint32(message.resultCode);
    }
    if (message.baseTime !== "") {
      writer.uint32(18).string(message.baseTime);
    }
    if (message.sessionToken !== undefined && message.sessionToken !== "") {
      writer.uint32(26).string(message.sessionToken);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(34).string(message.textMessage);
    }
    if (message.redirectUrl !== undefined && message.redirectUrl !== "") {
      writer.uint32(42).string(message.redirectUrl);
    }
    if (message.protocolVersionMinor !== 0) {
      writer.uint32(48).uint32(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== 0) {
      writer.uint32(56).uint32(message.protocolVersionMajor);
    }
    if (message.userId !== undefined && message.userId !== 0) {
      writer.uint32(64).sint32(message.userId);
    }
    if (message.serverTime !== 0) {
      writer.uint32(72).sint64(message.serverTime);
    }
    if (message.inactivityTimeout !== undefined && message.inactivityTimeout !== 0) {
      writer.uint32(80).uint32(message.inactivityTimeout);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogonResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogonResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sessionToken = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.redirectUrl = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.protocolVersionMinor = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.protocolVersionMajor = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.userId = reader.sint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.serverTime = longToNumber(reader.sint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.inactivityTimeout = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogonResult {
    return {
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      baseTime: isSet(object.baseTime) ? globalThis.String(object.baseTime) : "",
      sessionToken: isSet(object.sessionToken) ? globalThis.String(object.sessionToken) : "",
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      redirectUrl: isSet(object.redirectUrl) ? globalThis.String(object.redirectUrl) : "",
      protocolVersionMinor: isSet(object.protocolVersionMinor) ? globalThis.Number(object.protocolVersionMinor) : 0,
      protocolVersionMajor: isSet(object.protocolVersionMajor) ? globalThis.Number(object.protocolVersionMajor) : 0,
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      serverTime: isSet(object.serverTime) ? globalThis.Number(object.serverTime) : 0,
      inactivityTimeout: isSet(object.inactivityTimeout) ? globalThis.Number(object.inactivityTimeout) : 0,
    };
  },

  toJSON(message: LogonResult): unknown {
    const obj: any = {};
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.baseTime !== "") {
      obj.baseTime = message.baseTime;
    }
    if (message.sessionToken !== undefined && message.sessionToken !== "") {
      obj.sessionToken = message.sessionToken;
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.redirectUrl !== undefined && message.redirectUrl !== "") {
      obj.redirectUrl = message.redirectUrl;
    }
    if (message.protocolVersionMinor !== 0) {
      obj.protocolVersionMinor = Math.round(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== 0) {
      obj.protocolVersionMajor = Math.round(message.protocolVersionMajor);
    }
    if (message.userId !== undefined && message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.serverTime !== 0) {
      obj.serverTime = Math.round(message.serverTime);
    }
    if (message.inactivityTimeout !== undefined && message.inactivityTimeout !== 0) {
      obj.inactivityTimeout = Math.round(message.inactivityTimeout);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogonResult>, I>>(base?: I): LogonResult {
    return LogonResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogonResult>, I>>(object: I): LogonResult {
    const message = createBaseLogonResult();
    message.resultCode = object.resultCode ?? 0;
    message.baseTime = object.baseTime ?? "";
    message.sessionToken = object.sessionToken ?? "";
    message.textMessage = object.textMessage ?? "";
    message.redirectUrl = object.redirectUrl ?? "";
    message.protocolVersionMinor = object.protocolVersionMinor ?? 0;
    message.protocolVersionMajor = object.protocolVersionMajor ?? 0;
    message.userId = object.userId ?? 0;
    message.serverTime = object.serverTime ?? 0;
    message.inactivityTimeout = object.inactivityTimeout ?? 0;
    return message;
  },
};

function createBaseLogonRoutineClient(): LogonRoutineClient {
  return { logonInit: undefined, passwordResponses: [], traderAgreementAccepted: false, abortLogon: undefined };
}

export const LogonRoutineClient = {
  encode(message: LogonRoutineClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logonInit !== undefined) {
      LogonInit.encode(message.logonInit, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.passwordResponses) {
      PasswordResponse.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.traderAgreementAccepted !== undefined && message.traderAgreementAccepted !== false) {
      writer.uint32(24).bool(message.traderAgreementAccepted);
    }
    if (message.abortLogon !== undefined) {
      AbortRoutine.encode(message.abortLogon, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogonRoutineClient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogonRoutineClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.logonInit = LogonInit.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.passwordResponses.push(PasswordResponse.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.traderAgreementAccepted = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.abortLogon = AbortRoutine.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogonRoutineClient {
    return {
      logonInit: isSet(object.logonInit) ? LogonInit.fromJSON(object.logonInit) : undefined,
      passwordResponses: globalThis.Array.isArray(object?.passwordResponses)
        ? object.passwordResponses.map((e: any) => PasswordResponse.fromJSON(e))
        : [],
      traderAgreementAccepted: isSet(object.traderAgreementAccepted)
        ? globalThis.Boolean(object.traderAgreementAccepted)
        : false,
      abortLogon: isSet(object.abortLogon) ? AbortRoutine.fromJSON(object.abortLogon) : undefined,
    };
  },

  toJSON(message: LogonRoutineClient): unknown {
    const obj: any = {};
    if (message.logonInit !== undefined) {
      obj.logonInit = LogonInit.toJSON(message.logonInit);
    }
    if (message.passwordResponses?.length) {
      obj.passwordResponses = message.passwordResponses.map((e) => PasswordResponse.toJSON(e));
    }
    if (message.traderAgreementAccepted !== undefined && message.traderAgreementAccepted !== false) {
      obj.traderAgreementAccepted = message.traderAgreementAccepted;
    }
    if (message.abortLogon !== undefined) {
      obj.abortLogon = AbortRoutine.toJSON(message.abortLogon);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogonRoutineClient>, I>>(base?: I): LogonRoutineClient {
    return LogonRoutineClient.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogonRoutineClient>, I>>(object: I): LogonRoutineClient {
    const message = createBaseLogonRoutineClient();
    message.logonInit = (object.logonInit !== undefined && object.logonInit !== null)
      ? LogonInit.fromPartial(object.logonInit)
      : undefined;
    message.passwordResponses = object.passwordResponses?.map((e) => PasswordResponse.fromPartial(e)) || [];
    message.traderAgreementAccepted = object.traderAgreementAccepted ?? false;
    message.abortLogon = (object.abortLogon !== undefined && object.abortLogon !== null)
      ? AbortRoutine.fromPartial(object.abortLogon)
      : undefined;
    return message;
  },
};

function createBaseLogonRoutineServer(): LogonRoutineServer {
  return { logonResult: undefined, passwordRequests: [], traderAgreementUrl: "" };
}

export const LogonRoutineServer = {
  encode(message: LogonRoutineServer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logonResult !== undefined) {
      LogonResult.encode(message.logonResult, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.passwordRequests) {
      PasswordRequest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.traderAgreementUrl !== undefined && message.traderAgreementUrl !== "") {
      writer.uint32(26).string(message.traderAgreementUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogonRoutineServer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogonRoutineServer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.logonResult = LogonResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.passwordRequests.push(PasswordRequest.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.traderAgreementUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogonRoutineServer {
    return {
      logonResult: isSet(object.logonResult) ? LogonResult.fromJSON(object.logonResult) : undefined,
      passwordRequests: globalThis.Array.isArray(object?.passwordRequests)
        ? object.passwordRequests.map((e: any) => PasswordRequest.fromJSON(e))
        : [],
      traderAgreementUrl: isSet(object.traderAgreementUrl) ? globalThis.String(object.traderAgreementUrl) : "",
    };
  },

  toJSON(message: LogonRoutineServer): unknown {
    const obj: any = {};
    if (message.logonResult !== undefined) {
      obj.logonResult = LogonResult.toJSON(message.logonResult);
    }
    if (message.passwordRequests?.length) {
      obj.passwordRequests = message.passwordRequests.map((e) => PasswordRequest.toJSON(e));
    }
    if (message.traderAgreementUrl !== undefined && message.traderAgreementUrl !== "") {
      obj.traderAgreementUrl = message.traderAgreementUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogonRoutineServer>, I>>(base?: I): LogonRoutineServer {
    return LogonRoutineServer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogonRoutineServer>, I>>(object: I): LogonRoutineServer {
    const message = createBaseLogonRoutineServer();
    message.logonResult = (object.logonResult !== undefined && object.logonResult !== null)
      ? LogonResult.fromPartial(object.logonResult)
      : undefined;
    message.passwordRequests = object.passwordRequests?.map((e) => PasswordRequest.fromPartial(e)) || [];
    message.traderAgreementUrl = object.traderAgreementUrl ?? "";
    return message;
  },
};

function createBasePasswordChangeRoutineClient(): PasswordChangeRoutineClient {
  return { initPasswordChange: false, passwordResponses: [], abortPasswordChange: undefined };
}

export const PasswordChangeRoutineClient = {
  encode(message: PasswordChangeRoutineClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.initPasswordChange !== undefined && message.initPasswordChange !== false) {
      writer.uint32(8).bool(message.initPasswordChange);
    }
    for (const v of message.passwordResponses) {
      PasswordResponse.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.abortPasswordChange !== undefined) {
      AbortRoutine.encode(message.abortPasswordChange, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordChangeRoutineClient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordChangeRoutineClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.initPasswordChange = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.passwordResponses.push(PasswordResponse.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.abortPasswordChange = AbortRoutine.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasswordChangeRoutineClient {
    return {
      initPasswordChange: isSet(object.initPasswordChange) ? globalThis.Boolean(object.initPasswordChange) : false,
      passwordResponses: globalThis.Array.isArray(object?.passwordResponses)
        ? object.passwordResponses.map((e: any) => PasswordResponse.fromJSON(e))
        : [],
      abortPasswordChange: isSet(object.abortPasswordChange)
        ? AbortRoutine.fromJSON(object.abortPasswordChange)
        : undefined,
    };
  },

  toJSON(message: PasswordChangeRoutineClient): unknown {
    const obj: any = {};
    if (message.initPasswordChange !== undefined && message.initPasswordChange !== false) {
      obj.initPasswordChange = message.initPasswordChange;
    }
    if (message.passwordResponses?.length) {
      obj.passwordResponses = message.passwordResponses.map((e) => PasswordResponse.toJSON(e));
    }
    if (message.abortPasswordChange !== undefined) {
      obj.abortPasswordChange = AbortRoutine.toJSON(message.abortPasswordChange);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PasswordChangeRoutineClient>, I>>(base?: I): PasswordChangeRoutineClient {
    return PasswordChangeRoutineClient.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordChangeRoutineClient>, I>>(object: I): PasswordChangeRoutineClient {
    const message = createBasePasswordChangeRoutineClient();
    message.initPasswordChange = object.initPasswordChange ?? false;
    message.passwordResponses = object.passwordResponses?.map((e) => PasswordResponse.fromPartial(e)) || [];
    message.abortPasswordChange = (object.abortPasswordChange !== undefined && object.abortPasswordChange !== null)
      ? AbortRoutine.fromPartial(object.abortPasswordChange)
      : undefined;
    return message;
  },
};

function createBasePasswordChangeRoutineServer(): PasswordChangeRoutineServer {
  return { passwordRequests: [], passwordChangeResult: undefined };
}

export const PasswordChangeRoutineServer = {
  encode(message: PasswordChangeRoutineServer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.passwordRequests) {
      PasswordRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.passwordChangeResult !== undefined) {
      PasswordChangeResult.encode(message.passwordChangeResult, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordChangeRoutineServer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordChangeRoutineServer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.passwordRequests.push(PasswordRequest.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.passwordChangeResult = PasswordChangeResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasswordChangeRoutineServer {
    return {
      passwordRequests: globalThis.Array.isArray(object?.passwordRequests)
        ? object.passwordRequests.map((e: any) => PasswordRequest.fromJSON(e))
        : [],
      passwordChangeResult: isSet(object.passwordChangeResult)
        ? PasswordChangeResult.fromJSON(object.passwordChangeResult)
        : undefined,
    };
  },

  toJSON(message: PasswordChangeRoutineServer): unknown {
    const obj: any = {};
    if (message.passwordRequests?.length) {
      obj.passwordRequests = message.passwordRequests.map((e) => PasswordRequest.toJSON(e));
    }
    if (message.passwordChangeResult !== undefined) {
      obj.passwordChangeResult = PasswordChangeResult.toJSON(message.passwordChangeResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PasswordChangeRoutineServer>, I>>(base?: I): PasswordChangeRoutineServer {
    return PasswordChangeRoutineServer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordChangeRoutineServer>, I>>(object: I): PasswordChangeRoutineServer {
    const message = createBasePasswordChangeRoutineServer();
    message.passwordRequests = object.passwordRequests?.map((e) => PasswordRequest.fromPartial(e)) || [];
    message.passwordChangeResult = (object.passwordChangeResult !== undefined && object.passwordChangeResult !== null)
      ? PasswordChangeResult.fromPartial(object.passwordChangeResult)
      : undefined;
    return message;
  },
};

function createBaseAbortRoutine(): AbortRoutine {
  return { comment: "" };
}

export const AbortRoutine = {
  encode(message: AbortRoutine, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.comment !== undefined && message.comment !== "") {
      writer.uint32(10).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AbortRoutine {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAbortRoutine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AbortRoutine {
    return { comment: isSet(object.comment) ? globalThis.String(object.comment) : "" };
  },

  toJSON(message: AbortRoutine): unknown {
    const obj: any = {};
    if (message.comment !== undefined && message.comment !== "") {
      obj.comment = message.comment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AbortRoutine>, I>>(base?: I): AbortRoutine {
    return AbortRoutine.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AbortRoutine>, I>>(object: I): AbortRoutine {
    const message = createBaseAbortRoutine();
    message.comment = object.comment ?? "";
    return message;
  },
};

function createBaseLogonInit(): LogonInit {
  return {
    userName: "",
    privateLabel: "",
    clientAppId: "",
    clientVersion: "",
    dropConcurrentSession: false,
    maxCollapsingLevel: 0,
    protocolVersionMinor: 0,
    protocolVersionMajor: 0,
    sessionSettings: [],
    marketDataBandwidth: 0,
    dialectId: "",
    fingerprint: "",
    operatingSystem: "",
    browser: "",
    deviceModel: "",
  };
}

export const LogonInit = {
  encode(message: LogonInit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userName !== "") {
      writer.uint32(10).string(message.userName);
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      writer.uint32(18).string(message.privateLabel);
    }
    if (message.clientAppId !== "") {
      writer.uint32(26).string(message.clientAppId);
    }
    if (message.clientVersion !== "") {
      writer.uint32(34).string(message.clientVersion);
    }
    if (message.dropConcurrentSession !== undefined && message.dropConcurrentSession !== false) {
      writer.uint32(40).bool(message.dropConcurrentSession);
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      writer.uint32(48).uint32(message.maxCollapsingLevel);
    }
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      writer.uint32(56).uint32(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      writer.uint32(64).uint32(message.protocolVersionMajor);
    }
    writer.uint32(74).fork();
    for (const v of message.sessionSettings) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.marketDataBandwidth !== undefined && message.marketDataBandwidth !== 0) {
      writer.uint32(80).uint32(message.marketDataBandwidth);
    }
    if (message.dialectId !== undefined && message.dialectId !== "") {
      writer.uint32(90).string(message.dialectId);
    }
    if (message.fingerprint !== undefined && message.fingerprint !== "") {
      writer.uint32(98).string(message.fingerprint);
    }
    if (message.operatingSystem !== undefined && message.operatingSystem !== "") {
      writer.uint32(106).string(message.operatingSystem);
    }
    if (message.browser !== undefined && message.browser !== "") {
      writer.uint32(114).string(message.browser);
    }
    if (message.deviceModel !== undefined && message.deviceModel !== "") {
      writer.uint32(122).string(message.deviceModel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogonInit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogonInit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.privateLabel = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientAppId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clientVersion = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.dropConcurrentSession = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.maxCollapsingLevel = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.protocolVersionMinor = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.protocolVersionMajor = reader.uint32();
          continue;
        case 9:
          if (tag === 72) {
            message.sessionSettings.push(reader.uint32());

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sessionSettings.push(reader.uint32());
            }

            continue;
          }

          break;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.marketDataBandwidth = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.dialectId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.operatingSystem = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.browser = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.deviceModel = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogonInit {
    return {
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      privateLabel: isSet(object.privateLabel) ? globalThis.String(object.privateLabel) : "",
      clientAppId: isSet(object.clientAppId) ? globalThis.String(object.clientAppId) : "",
      clientVersion: isSet(object.clientVersion) ? globalThis.String(object.clientVersion) : "",
      dropConcurrentSession: isSet(object.dropConcurrentSession)
        ? globalThis.Boolean(object.dropConcurrentSession)
        : false,
      maxCollapsingLevel: isSet(object.maxCollapsingLevel) ? globalThis.Number(object.maxCollapsingLevel) : 0,
      protocolVersionMinor: isSet(object.protocolVersionMinor) ? globalThis.Number(object.protocolVersionMinor) : 0,
      protocolVersionMajor: isSet(object.protocolVersionMajor) ? globalThis.Number(object.protocolVersionMajor) : 0,
      sessionSettings: globalThis.Array.isArray(object?.sessionSettings)
        ? object.sessionSettings.map((e: any) => globalThis.Number(e))
        : [],
      marketDataBandwidth: isSet(object.marketDataBandwidth) ? globalThis.Number(object.marketDataBandwidth) : 0,
      dialectId: isSet(object.dialectId) ? globalThis.String(object.dialectId) : "",
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      operatingSystem: isSet(object.operatingSystem) ? globalThis.String(object.operatingSystem) : "",
      browser: isSet(object.browser) ? globalThis.String(object.browser) : "",
      deviceModel: isSet(object.deviceModel) ? globalThis.String(object.deviceModel) : "",
    };
  },

  toJSON(message: LogonInit): unknown {
    const obj: any = {};
    if (message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      obj.privateLabel = message.privateLabel;
    }
    if (message.clientAppId !== "") {
      obj.clientAppId = message.clientAppId;
    }
    if (message.clientVersion !== "") {
      obj.clientVersion = message.clientVersion;
    }
    if (message.dropConcurrentSession !== undefined && message.dropConcurrentSession !== false) {
      obj.dropConcurrentSession = message.dropConcurrentSession;
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      obj.maxCollapsingLevel = Math.round(message.maxCollapsingLevel);
    }
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      obj.protocolVersionMinor = Math.round(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      obj.protocolVersionMajor = Math.round(message.protocolVersionMajor);
    }
    if (message.sessionSettings?.length) {
      obj.sessionSettings = message.sessionSettings.map((e) => Math.round(e));
    }
    if (message.marketDataBandwidth !== undefined && message.marketDataBandwidth !== 0) {
      obj.marketDataBandwidth = Math.round(message.marketDataBandwidth);
    }
    if (message.dialectId !== undefined && message.dialectId !== "") {
      obj.dialectId = message.dialectId;
    }
    if (message.fingerprint !== undefined && message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.operatingSystem !== undefined && message.operatingSystem !== "") {
      obj.operatingSystem = message.operatingSystem;
    }
    if (message.browser !== undefined && message.browser !== "") {
      obj.browser = message.browser;
    }
    if (message.deviceModel !== undefined && message.deviceModel !== "") {
      obj.deviceModel = message.deviceModel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogonInit>, I>>(base?: I): LogonInit {
    return LogonInit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogonInit>, I>>(object: I): LogonInit {
    const message = createBaseLogonInit();
    message.userName = object.userName ?? "";
    message.privateLabel = object.privateLabel ?? "";
    message.clientAppId = object.clientAppId ?? "";
    message.clientVersion = object.clientVersion ?? "";
    message.dropConcurrentSession = object.dropConcurrentSession ?? false;
    message.maxCollapsingLevel = object.maxCollapsingLevel ?? 0;
    message.protocolVersionMinor = object.protocolVersionMinor ?? 0;
    message.protocolVersionMajor = object.protocolVersionMajor ?? 0;
    message.sessionSettings = object.sessionSettings?.map((e) => e) || [];
    message.marketDataBandwidth = object.marketDataBandwidth ?? 0;
    message.dialectId = object.dialectId ?? "";
    message.fingerprint = object.fingerprint ?? "";
    message.operatingSystem = object.operatingSystem ?? "";
    message.browser = object.browser ?? "";
    message.deviceModel = object.deviceModel ?? "";
    return message;
  },
};

function createBasePasswordRequest(): PasswordRequest {
  return { requestId: 0, passwordType: 0, userPromptType: "", userPromptTypeAlt: "", userPrompt: "", encodings: [] };
}

export const PasswordRequest = {
  encode(message: PasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.passwordType !== 0) {
      writer.uint32(16).uint32(message.passwordType);
    }
    if (message.userPromptType !== undefined && message.userPromptType !== "") {
      writer.uint32(26).string(message.userPromptType);
    }
    if (message.userPromptTypeAlt !== undefined && message.userPromptTypeAlt !== "") {
      writer.uint32(34).string(message.userPromptTypeAlt);
    }
    if (message.userPrompt !== undefined && message.userPrompt !== "") {
      writer.uint32(42).string(message.userPrompt);
    }
    for (const v of message.encodings) {
      Encoding.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.passwordType = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userPromptType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.userPromptTypeAlt = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userPrompt = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.encodings.push(Encoding.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasswordRequest {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      passwordType: isSet(object.passwordType) ? globalThis.Number(object.passwordType) : 0,
      userPromptType: isSet(object.userPromptType) ? globalThis.String(object.userPromptType) : "",
      userPromptTypeAlt: isSet(object.userPromptTypeAlt) ? globalThis.String(object.userPromptTypeAlt) : "",
      userPrompt: isSet(object.userPrompt) ? globalThis.String(object.userPrompt) : "",
      encodings: globalThis.Array.isArray(object?.encodings)
        ? object.encodings.map((e: any) => Encoding.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PasswordRequest): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.passwordType !== 0) {
      obj.passwordType = Math.round(message.passwordType);
    }
    if (message.userPromptType !== undefined && message.userPromptType !== "") {
      obj.userPromptType = message.userPromptType;
    }
    if (message.userPromptTypeAlt !== undefined && message.userPromptTypeAlt !== "") {
      obj.userPromptTypeAlt = message.userPromptTypeAlt;
    }
    if (message.userPrompt !== undefined && message.userPrompt !== "") {
      obj.userPrompt = message.userPrompt;
    }
    if (message.encodings?.length) {
      obj.encodings = message.encodings.map((e) => Encoding.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PasswordRequest>, I>>(base?: I): PasswordRequest {
    return PasswordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordRequest>, I>>(object: I): PasswordRequest {
    const message = createBasePasswordRequest();
    message.requestId = object.requestId ?? 0;
    message.passwordType = object.passwordType ?? 0;
    message.userPromptType = object.userPromptType ?? "";
    message.userPromptTypeAlt = object.userPromptTypeAlt ?? "";
    message.userPrompt = object.userPrompt ?? "";
    message.encodings = object.encodings?.map((e) => Encoding.fromPartial(e)) || [];
    return message;
  },
};

function createBasePasswordResponse(): PasswordResponse {
  return { requestId: 0, encodingType: "", password: "" };
}

export const PasswordResponse = {
  encode(message: PasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.encodingType !== "") {
      writer.uint32(18).string(message.encodingType);
    }
    if (message.password !== "") {
      writer.uint32(26).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.encodingType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasswordResponse {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      encodingType: isSet(object.encodingType) ? globalThis.String(object.encodingType) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: PasswordResponse): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.encodingType !== "") {
      obj.encodingType = message.encodingType;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PasswordResponse>, I>>(base?: I): PasswordResponse {
    return PasswordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordResponse>, I>>(object: I): PasswordResponse {
    const message = createBasePasswordResponse();
    message.requestId = object.requestId ?? 0;
    message.encodingType = object.encodingType ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseEncoding(): Encoding {
  return { encodingType: "", priorityRank: 0, encodingParameters: [] };
}

export const Encoding = {
  encode(message: Encoding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.encodingType !== "") {
      writer.uint32(10).string(message.encodingType);
    }
    if (message.priorityRank !== 0) {
      writer.uint32(16).uint32(message.priorityRank);
    }
    for (const v of message.encodingParameters) {
      EncodingParameter.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Encoding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEncoding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.encodingType = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.priorityRank = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encodingParameters.push(EncodingParameter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Encoding {
    return {
      encodingType: isSet(object.encodingType) ? globalThis.String(object.encodingType) : "",
      priorityRank: isSet(object.priorityRank) ? globalThis.Number(object.priorityRank) : 0,
      encodingParameters: globalThis.Array.isArray(object?.encodingParameters)
        ? object.encodingParameters.map((e: any) => EncodingParameter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Encoding): unknown {
    const obj: any = {};
    if (message.encodingType !== "") {
      obj.encodingType = message.encodingType;
    }
    if (message.priorityRank !== 0) {
      obj.priorityRank = Math.round(message.priorityRank);
    }
    if (message.encodingParameters?.length) {
      obj.encodingParameters = message.encodingParameters.map((e) => EncodingParameter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Encoding>, I>>(base?: I): Encoding {
    return Encoding.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Encoding>, I>>(object: I): Encoding {
    const message = createBaseEncoding();
    message.encodingType = object.encodingType ?? "";
    message.priorityRank = object.priorityRank ?? 0;
    message.encodingParameters = object.encodingParameters?.map((e) => EncodingParameter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEncodingParameter(): EncodingParameter {
  return { name: "", value: "" };
}

export const EncodingParameter = {
  encode(message: EncodingParameter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EncodingParameter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEncodingParameter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EncodingParameter {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: EncodingParameter): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EncodingParameter>, I>>(base?: I): EncodingParameter {
    return EncodingParameter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EncodingParameter>, I>>(object: I): EncodingParameter {
    const message = createBaseEncodingParameter();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseRestoreOrJoinSession(): RestoreOrJoinSession {
  return {
    sessionToken: "",
    privateLabel: "",
    clientAppId: "",
    maxCollapsingLevel: 0,
    protocolVersionMinor: 0,
    protocolVersionMajor: 0,
    marketDataBandwidth: 0,
  };
}

export const RestoreOrJoinSession = {
  encode(message: RestoreOrJoinSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionToken !== "") {
      writer.uint32(10).string(message.sessionToken);
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      writer.uint32(18).string(message.privateLabel);
    }
    if (message.clientAppId !== undefined && message.clientAppId !== "") {
      writer.uint32(26).string(message.clientAppId);
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      writer.uint32(32).uint32(message.maxCollapsingLevel);
    }
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      writer.uint32(40).uint32(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      writer.uint32(48).uint32(message.protocolVersionMajor);
    }
    if (message.marketDataBandwidth !== undefined && message.marketDataBandwidth !== 0) {
      writer.uint32(56).uint32(message.marketDataBandwidth);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreOrJoinSession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreOrJoinSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.privateLabel = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientAppId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxCollapsingLevel = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.protocolVersionMinor = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.protocolVersionMajor = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.marketDataBandwidth = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RestoreOrJoinSession {
    return {
      sessionToken: isSet(object.sessionToken) ? globalThis.String(object.sessionToken) : "",
      privateLabel: isSet(object.privateLabel) ? globalThis.String(object.privateLabel) : "",
      clientAppId: isSet(object.clientAppId) ? globalThis.String(object.clientAppId) : "",
      maxCollapsingLevel: isSet(object.maxCollapsingLevel) ? globalThis.Number(object.maxCollapsingLevel) : 0,
      protocolVersionMinor: isSet(object.protocolVersionMinor) ? globalThis.Number(object.protocolVersionMinor) : 0,
      protocolVersionMajor: isSet(object.protocolVersionMajor) ? globalThis.Number(object.protocolVersionMajor) : 0,
      marketDataBandwidth: isSet(object.marketDataBandwidth) ? globalThis.Number(object.marketDataBandwidth) : 0,
    };
  },

  toJSON(message: RestoreOrJoinSession): unknown {
    const obj: any = {};
    if (message.sessionToken !== "") {
      obj.sessionToken = message.sessionToken;
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      obj.privateLabel = message.privateLabel;
    }
    if (message.clientAppId !== undefined && message.clientAppId !== "") {
      obj.clientAppId = message.clientAppId;
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      obj.maxCollapsingLevel = Math.round(message.maxCollapsingLevel);
    }
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      obj.protocolVersionMinor = Math.round(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      obj.protocolVersionMajor = Math.round(message.protocolVersionMajor);
    }
    if (message.marketDataBandwidth !== undefined && message.marketDataBandwidth !== 0) {
      obj.marketDataBandwidth = Math.round(message.marketDataBandwidth);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreOrJoinSession>, I>>(base?: I): RestoreOrJoinSession {
    return RestoreOrJoinSession.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreOrJoinSession>, I>>(object: I): RestoreOrJoinSession {
    const message = createBaseRestoreOrJoinSession();
    message.sessionToken = object.sessionToken ?? "";
    message.privateLabel = object.privateLabel ?? "";
    message.clientAppId = object.clientAppId ?? "";
    message.maxCollapsingLevel = object.maxCollapsingLevel ?? 0;
    message.protocolVersionMinor = object.protocolVersionMinor ?? 0;
    message.protocolVersionMajor = object.protocolVersionMajor ?? 0;
    message.marketDataBandwidth = object.marketDataBandwidth ?? 0;
    return message;
  },
};

function createBaseRestoreOrJoinSessionResult(): RestoreOrJoinSessionResult {
  return { resultCode: 0, baseTime: "", textMessage: "", serverTime: 0, dialectId: "", inactivityTimeout: 0 };
}

export const RestoreOrJoinSessionResult = {
  encode(message: RestoreOrJoinSessionResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCode !== 0) {
      writer.uint32(8).uint32(message.resultCode);
    }
    if (message.baseTime !== "") {
      writer.uint32(18).string(message.baseTime);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(26).string(message.textMessage);
    }
    if (message.serverTime !== 0) {
      writer.uint32(32).sint64(message.serverTime);
    }
    if (message.dialectId !== undefined && message.dialectId !== "") {
      writer.uint32(42).string(message.dialectId);
    }
    if (message.inactivityTimeout !== undefined && message.inactivityTimeout !== 0) {
      writer.uint32(48).uint32(message.inactivityTimeout);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreOrJoinSessionResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreOrJoinSessionResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.serverTime = longToNumber(reader.sint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dialectId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.inactivityTimeout = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RestoreOrJoinSessionResult {
    return {
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      baseTime: isSet(object.baseTime) ? globalThis.String(object.baseTime) : "",
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      serverTime: isSet(object.serverTime) ? globalThis.Number(object.serverTime) : 0,
      dialectId: isSet(object.dialectId) ? globalThis.String(object.dialectId) : "",
      inactivityTimeout: isSet(object.inactivityTimeout) ? globalThis.Number(object.inactivityTimeout) : 0,
    };
  },

  toJSON(message: RestoreOrJoinSessionResult): unknown {
    const obj: any = {};
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.baseTime !== "") {
      obj.baseTime = message.baseTime;
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.serverTime !== 0) {
      obj.serverTime = Math.round(message.serverTime);
    }
    if (message.dialectId !== undefined && message.dialectId !== "") {
      obj.dialectId = message.dialectId;
    }
    if (message.inactivityTimeout !== undefined && message.inactivityTimeout !== 0) {
      obj.inactivityTimeout = Math.round(message.inactivityTimeout);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreOrJoinSessionResult>, I>>(base?: I): RestoreOrJoinSessionResult {
    return RestoreOrJoinSessionResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreOrJoinSessionResult>, I>>(object: I): RestoreOrJoinSessionResult {
    const message = createBaseRestoreOrJoinSessionResult();
    message.resultCode = object.resultCode ?? 0;
    message.baseTime = object.baseTime ?? "";
    message.textMessage = object.textMessage ?? "";
    message.serverTime = object.serverTime ?? 0;
    message.dialectId = object.dialectId ?? "";
    message.inactivityTimeout = object.inactivityTimeout ?? 0;
    return message;
  },
};

function createBaseConcurrentConnectionJoin(): ConcurrentConnectionJoin {
  return { isSameAppType: false };
}

export const ConcurrentConnectionJoin = {
  encode(message: ConcurrentConnectionJoin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isSameAppType !== false) {
      writer.uint32(8).bool(message.isSameAppType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConcurrentConnectionJoin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConcurrentConnectionJoin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isSameAppType = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConcurrentConnectionJoin {
    return { isSameAppType: isSet(object.isSameAppType) ? globalThis.Boolean(object.isSameAppType) : false };
  },

  toJSON(message: ConcurrentConnectionJoin): unknown {
    const obj: any = {};
    if (message.isSameAppType !== false) {
      obj.isSameAppType = message.isSameAppType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConcurrentConnectionJoin>, I>>(base?: I): ConcurrentConnectionJoin {
    return ConcurrentConnectionJoin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConcurrentConnectionJoin>, I>>(object: I): ConcurrentConnectionJoin {
    const message = createBaseConcurrentConnectionJoin();
    message.isSameAppType = object.isSameAppType ?? false;
    return message;
  },
};

function createBaseLogoff(): Logoff {
  return { textMessage: "" };
}

export const Logoff = {
  encode(message: Logoff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(10).string(message.textMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Logoff {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.textMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Logoff {
    return { textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "" };
  },

  toJSON(message: Logoff): unknown {
    const obj: any = {};
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Logoff>, I>>(base?: I): Logoff {
    return Logoff.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Logoff>, I>>(object: I): Logoff {
    const message = createBaseLogoff();
    message.textMessage = object.textMessage ?? "";
    return message;
  },
};

function createBaseLoggedOff(): LoggedOff {
  return { logoffReason: 0, textMessage: "", redirectUrl: "" };
}

export const LoggedOff = {
  encode(message: LoggedOff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logoffReason !== 0) {
      writer.uint32(8).uint32(message.logoffReason);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(18).string(message.textMessage);
    }
    if (message.redirectUrl !== undefined && message.redirectUrl !== "") {
      writer.uint32(26).string(message.redirectUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoggedOff {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoggedOff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.logoffReason = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.redirectUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoggedOff {
    return {
      logoffReason: isSet(object.logoffReason) ? globalThis.Number(object.logoffReason) : 0,
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      redirectUrl: isSet(object.redirectUrl) ? globalThis.String(object.redirectUrl) : "",
    };
  },

  toJSON(message: LoggedOff): unknown {
    const obj: any = {};
    if (message.logoffReason !== 0) {
      obj.logoffReason = Math.round(message.logoffReason);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.redirectUrl !== undefined && message.redirectUrl !== "") {
      obj.redirectUrl = message.redirectUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoggedOff>, I>>(base?: I): LoggedOff {
    return LoggedOff.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoggedOff>, I>>(object: I): LoggedOff {
    const message = createBaseLoggedOff();
    message.logoffReason = object.logoffReason ?? 0;
    message.textMessage = object.textMessage ?? "";
    message.redirectUrl = object.redirectUrl ?? "";
    return message;
  },
};

function createBasePasswordChange(): PasswordChange {
  return { oldPassword: "", newPassword: "" };
}

export const PasswordChange = {
  encode(message: PasswordChange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.oldPassword !== "") {
      writer.uint32(10).string(message.oldPassword);
    }
    if (message.newPassword !== "") {
      writer.uint32(18).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordChange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.oldPassword = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasswordChange {
    return {
      oldPassword: isSet(object.oldPassword) ? globalThis.String(object.oldPassword) : "",
      newPassword: isSet(object.newPassword) ? globalThis.String(object.newPassword) : "",
    };
  },

  toJSON(message: PasswordChange): unknown {
    const obj: any = {};
    if (message.oldPassword !== "") {
      obj.oldPassword = message.oldPassword;
    }
    if (message.newPassword !== "") {
      obj.newPassword = message.newPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PasswordChange>, I>>(base?: I): PasswordChange {
    return PasswordChange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordChange>, I>>(object: I): PasswordChange {
    const message = createBasePasswordChange();
    message.oldPassword = object.oldPassword ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

function createBasePasswordChangeResult(): PasswordChangeResult {
  return { resultCode: 0, textMessage: "" };
}

export const PasswordChangeResult = {
  encode(message: PasswordChangeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCode !== 0) {
      writer.uint32(8).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(18).string(message.textMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordChangeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordChangeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.textMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasswordChangeResult {
    return {
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
    };
  },

  toJSON(message: PasswordChangeResult): unknown {
    const obj: any = {};
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PasswordChangeResult>, I>>(base?: I): PasswordChangeResult {
    return PasswordChangeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordChangeResult>, I>>(object: I): PasswordChangeResult {
    const message = createBasePasswordChangeResult();
    message.resultCode = object.resultCode ?? 0;
    message.textMessage = object.textMessage ?? "";
    return message;
  },
};

function createBaseObtainDemoCredentials(): ObtainDemoCredentials {
  return { clientAppId: "", firstName: "", secondName: "", eMail: "", privateLabel: "" };
}

export const ObtainDemoCredentials = {
  encode(message: ObtainDemoCredentials, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientAppId !== "") {
      writer.uint32(10).string(message.clientAppId);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.secondName !== "") {
      writer.uint32(26).string(message.secondName);
    }
    if (message.eMail !== "") {
      writer.uint32(34).string(message.eMail);
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      writer.uint32(42).string(message.privateLabel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObtainDemoCredentials {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObtainDemoCredentials();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientAppId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secondName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.eMail = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.privateLabel = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObtainDemoCredentials {
    return {
      clientAppId: isSet(object.clientAppId) ? globalThis.String(object.clientAppId) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      secondName: isSet(object.secondName) ? globalThis.String(object.secondName) : "",
      eMail: isSet(object.eMail) ? globalThis.String(object.eMail) : "",
      privateLabel: isSet(object.privateLabel) ? globalThis.String(object.privateLabel) : "",
    };
  },

  toJSON(message: ObtainDemoCredentials): unknown {
    const obj: any = {};
    if (message.clientAppId !== "") {
      obj.clientAppId = message.clientAppId;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.secondName !== "") {
      obj.secondName = message.secondName;
    }
    if (message.eMail !== "") {
      obj.eMail = message.eMail;
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      obj.privateLabel = message.privateLabel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ObtainDemoCredentials>, I>>(base?: I): ObtainDemoCredentials {
    return ObtainDemoCredentials.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObtainDemoCredentials>, I>>(object: I): ObtainDemoCredentials {
    const message = createBaseObtainDemoCredentials();
    message.clientAppId = object.clientAppId ?? "";
    message.firstName = object.firstName ?? "";
    message.secondName = object.secondName ?? "";
    message.eMail = object.eMail ?? "";
    message.privateLabel = object.privateLabel ?? "";
    return message;
  },
};

function createBaseObtainDemoCredentialsResult(): ObtainDemoCredentialsResult {
  return { resultCode: 0, userName: "", password: "", textMessage: "", userId: 0, expirationUtc: "" };
}

export const ObtainDemoCredentialsResult = {
  encode(message: ObtainDemoCredentialsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCode !== 0) {
      writer.uint32(8).uint32(message.resultCode);
    }
    if (message.userName !== undefined && message.userName !== "") {
      writer.uint32(18).string(message.userName);
    }
    if (message.password !== undefined && message.password !== "") {
      writer.uint32(26).string(message.password);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(34).string(message.textMessage);
    }
    if (message.userId !== undefined && message.userId !== 0) {
      writer.uint32(40).sint32(message.userId);
    }
    if (message.expirationUtc !== undefined && message.expirationUtc !== "") {
      writer.uint32(50).string(message.expirationUtc);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObtainDemoCredentialsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObtainDemoCredentialsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.password = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.userId = reader.sint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.expirationUtc = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObtainDemoCredentialsResult {
    return {
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      expirationUtc: isSet(object.expirationUtc) ? globalThis.String(object.expirationUtc) : "",
    };
  },

  toJSON(message: ObtainDemoCredentialsResult): unknown {
    const obj: any = {};
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.userName !== undefined && message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.password !== undefined && message.password !== "") {
      obj.password = message.password;
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.userId !== undefined && message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.expirationUtc !== undefined && message.expirationUtc !== "") {
      obj.expirationUtc = message.expirationUtc;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ObtainDemoCredentialsResult>, I>>(base?: I): ObtainDemoCredentialsResult {
    return ObtainDemoCredentialsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObtainDemoCredentialsResult>, I>>(object: I): ObtainDemoCredentialsResult {
    const message = createBaseObtainDemoCredentialsResult();
    message.resultCode = object.resultCode ?? 0;
    message.userName = object.userName ?? "";
    message.password = object.password ?? "";
    message.textMessage = object.textMessage ?? "";
    message.userId = object.userId ?? 0;
    message.expirationUtc = object.expirationUtc ?? "";
    return message;
  },
};

function createBaseUserMessage(): UserMessage {
  return { messageType: 0, source: "", subject: "", text: "", expirationUtcTime: 0 };
}

export const UserMessage = {
  encode(message: UserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.messageType !== 0) {
      writer.uint32(8).uint32(message.messageType);
    }
    if (message.source !== "") {
      writer.uint32(18).string(message.source);
    }
    if (message.subject !== undefined && message.subject !== "") {
      writer.uint32(26).string(message.subject);
    }
    if (message.text !== "") {
      writer.uint32(34).string(message.text);
    }
    if (message.expirationUtcTime !== undefined && message.expirationUtcTime !== 0) {
      writer.uint32(40).sint64(message.expirationUtcTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.messageType = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subject = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.text = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.expirationUtcTime = longToNumber(reader.sint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMessage {
    return {
      messageType: isSet(object.messageType) ? globalThis.Number(object.messageType) : 0,
      source: isSet(object.source) ? globalThis.String(object.source) : "",
      subject: isSet(object.subject) ? globalThis.String(object.subject) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      expirationUtcTime: isSet(object.expirationUtcTime) ? globalThis.Number(object.expirationUtcTime) : 0,
    };
  },

  toJSON(message: UserMessage): unknown {
    const obj: any = {};
    if (message.messageType !== 0) {
      obj.messageType = Math.round(message.messageType);
    }
    if (message.source !== "") {
      obj.source = message.source;
    }
    if (message.subject !== undefined && message.subject !== "") {
      obj.subject = message.subject;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.expirationUtcTime !== undefined && message.expirationUtcTime !== 0) {
      obj.expirationUtcTime = Math.round(message.expirationUtcTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMessage>, I>>(base?: I): UserMessage {
    return UserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMessage>, I>>(object: I): UserMessage {
    const message = createBaseUserMessage();
    message.messageType = object.messageType ?? 0;
    message.source = object.source ?? "";
    message.subject = object.subject ?? "";
    message.text = object.text ?? "";
    message.expirationUtcTime = object.expirationUtcTime ?? 0;
    return message;
  },
};

function createBaseUserSessionStateRequest(): UserSessionStateRequest {
  return { sessionToken: "" };
}

export const UserSessionStateRequest = {
  encode(message: UserSessionStateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionToken !== "") {
      writer.uint32(10).string(message.sessionToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSessionStateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSessionStateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSessionStateRequest {
    return { sessionToken: isSet(object.sessionToken) ? globalThis.String(object.sessionToken) : "" };
  },

  toJSON(message: UserSessionStateRequest): unknown {
    const obj: any = {};
    if (message.sessionToken !== "") {
      obj.sessionToken = message.sessionToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSessionStateRequest>, I>>(base?: I): UserSessionStateRequest {
    return UserSessionStateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserSessionStateRequest>, I>>(object: I): UserSessionStateRequest {
    const message = createBaseUserSessionStateRequest();
    message.sessionToken = object.sessionToken ?? "";
    return message;
  },
};

function createBaseUserSessionStateResult(): UserSessionStateResult {
  return { sessionToken: "", resultCode: 0, userId: 0, username: "" };
}

export const UserSessionStateResult = {
  encode(message: UserSessionStateResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionToken !== "") {
      writer.uint32(10).string(message.sessionToken);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.userId !== undefined && message.userId !== 0) {
      writer.uint32(24).sint32(message.userId);
    }
    if (message.username !== undefined && message.username !== "") {
      writer.uint32(34).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSessionStateResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSessionStateResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionToken = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userId = reader.sint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSessionStateResult {
    return {
      sessionToken: isSet(object.sessionToken) ? globalThis.String(object.sessionToken) : "",
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      username: isSet(object.username) ? globalThis.String(object.username) : "",
    };
  },

  toJSON(message: UserSessionStateResult): unknown {
    const obj: any = {};
    if (message.sessionToken !== "") {
      obj.sessionToken = message.sessionToken;
    }
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.userId !== undefined && message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.username !== undefined && message.username !== "") {
      obj.username = message.username;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSessionStateResult>, I>>(base?: I): UserSessionStateResult {
    return UserSessionStateResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserSessionStateResult>, I>>(object: I): UserSessionStateResult {
    const message = createBaseUserSessionStateResult();
    message.sessionToken = object.sessionToken ?? "";
    message.resultCode = object.resultCode ?? 0;
    message.userId = object.userId ?? 0;
    message.username = object.username ?? "";
    return message;
  },
};

function createBasePing(): Ping {
  return { token: "", pingUtcTime: 0 };
}

export const Ping = {
  encode(message: Ping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== undefined && message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.pingUtcTime !== 0) {
      writer.uint32(16).sint64(message.pingUtcTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pingUtcTime = longToNumber(reader.sint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ping {
    return {
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      pingUtcTime: isSet(object.pingUtcTime) ? globalThis.Number(object.pingUtcTime) : 0,
    };
  },

  toJSON(message: Ping): unknown {
    const obj: any = {};
    if (message.token !== undefined && message.token !== "") {
      obj.token = message.token;
    }
    if (message.pingUtcTime !== 0) {
      obj.pingUtcTime = Math.round(message.pingUtcTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ping>, I>>(base?: I): Ping {
    return Ping.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ping>, I>>(object: I): Ping {
    const message = createBasePing();
    message.token = object.token ?? "";
    message.pingUtcTime = object.pingUtcTime ?? 0;
    return message;
  },
};

function createBasePong(): Pong {
  return { token: "", pingUtcTime: 0, pongUtcTime: 0 };
}

export const Pong = {
  encode(message: Pong, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== undefined && message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.pingUtcTime !== 0) {
      writer.uint32(16).sint64(message.pingUtcTime);
    }
    if (message.pongUtcTime !== 0) {
      writer.uint32(24).sint64(message.pongUtcTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pong {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePong();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pingUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pongUtcTime = longToNumber(reader.sint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pong {
    return {
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      pingUtcTime: isSet(object.pingUtcTime) ? globalThis.Number(object.pingUtcTime) : 0,
      pongUtcTime: isSet(object.pongUtcTime) ? globalThis.Number(object.pongUtcTime) : 0,
    };
  },

  toJSON(message: Pong): unknown {
    const obj: any = {};
    if (message.token !== undefined && message.token !== "") {
      obj.token = message.token;
    }
    if (message.pingUtcTime !== 0) {
      obj.pingUtcTime = Math.round(message.pingUtcTime);
    }
    if (message.pongUtcTime !== 0) {
      obj.pongUtcTime = Math.round(message.pongUtcTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pong>, I>>(base?: I): Pong {
    return Pong.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pong>, I>>(object: I): Pong {
    const message = createBasePong();
    message.token = object.token ?? "";
    message.pingUtcTime = object.pingUtcTime ?? 0;
    message.pongUtcTime = object.pongUtcTime ?? 0;
    return message;
  },
};

function createBaseSetCollapsingLevelRequest(): SetCollapsingLevelRequest {
  return { maxCollapsingLevel: 0 };
}

export const SetCollapsingLevelRequest = {
  encode(message: SetCollapsingLevelRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      writer.uint32(8).uint32(message.maxCollapsingLevel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetCollapsingLevelRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetCollapsingLevelRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.maxCollapsingLevel = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetCollapsingLevelRequest {
    return { maxCollapsingLevel: isSet(object.maxCollapsingLevel) ? globalThis.Number(object.maxCollapsingLevel) : 0 };
  },

  toJSON(message: SetCollapsingLevelRequest): unknown {
    const obj: any = {};
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      obj.maxCollapsingLevel = Math.round(message.maxCollapsingLevel);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetCollapsingLevelRequest>, I>>(base?: I): SetCollapsingLevelRequest {
    return SetCollapsingLevelRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetCollapsingLevelRequest>, I>>(object: I): SetCollapsingLevelRequest {
    const message = createBaseSetCollapsingLevelRequest();
    message.maxCollapsingLevel = object.maxCollapsingLevel ?? 0;
    return message;
  },
};

function createBaseSetCollapsingLevelResult(): SetCollapsingLevelResult {
  return { resultCode: 0, textMessage: undefined, maxCollapsingLevel: 0 };
}

export const SetCollapsingLevelResult = {
  encode(message: SetCollapsingLevelResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCode !== 0) {
      writer.uint32(8).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      Text.encode(message.textMessage, writer.uint32(18).fork()).ldelim();
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      writer.uint32(24).uint32(message.maxCollapsingLevel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetCollapsingLevelResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetCollapsingLevelResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.textMessage = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxCollapsingLevel = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetCollapsingLevelResult {
    return {
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? Text.fromJSON(object.textMessage) : undefined,
      maxCollapsingLevel: isSet(object.maxCollapsingLevel) ? globalThis.Number(object.maxCollapsingLevel) : 0,
    };
  },

  toJSON(message: SetCollapsingLevelResult): unknown {
    const obj: any = {};
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      obj.textMessage = Text.toJSON(message.textMessage);
    }
    if (message.maxCollapsingLevel !== undefined && message.maxCollapsingLevel !== 0) {
      obj.maxCollapsingLevel = Math.round(message.maxCollapsingLevel);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetCollapsingLevelResult>, I>>(base?: I): SetCollapsingLevelResult {
    return SetCollapsingLevelResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetCollapsingLevelResult>, I>>(object: I): SetCollapsingLevelResult {
    const message = createBaseSetCollapsingLevelResult();
    message.resultCode = object.resultCode ?? 0;
    message.textMessage = (object.textMessage !== undefined && object.textMessage !== null)
      ? Text.fromPartial(object.textMessage)
      : undefined;
    message.maxCollapsingLevel = object.maxCollapsingLevel ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

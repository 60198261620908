import { TradeSubscriptionStatus } from "../proto/trade_routing_2";

export class TradeSubscription {
  id: number;
  isPending: boolean;
  statusCode?: number;
  textMessage?: string;

  constructor(id: number) {
    this.id = id;
    this.isPending = true;
  }

  processTradeSubscriptionStatus(tradeSubscriptionStatus: TradeSubscriptionStatus): void {
    this.statusCode = tradeSubscriptionStatus.statusCode;
    this.textMessage = tradeSubscriptionStatus.textMessage;
    this.isPending = false;
  }
}

import { MarketDataSubscription_Level } from "./proto/market_data_2";
import { CMESymbolService } from "./services/CMESymbolService";
import { CQGEnvironment } from "./services/CQGEnvironment";
import { InstrumentsManager } from "./services/InstrumentsManager";
import { SymbolResolver } from "./services/SymbolResolver";

export class CQGApi {
  static instance: CQGApi;
  constructor() {
    if (CQGApi.instance) {
      return CQGApi.instance;
    }

    CQGApi.instance = this;
  }

  static get Instance() {
    if (CQGApi.instance) {
      return CQGApi.instance;
    }

    return new CQGApi();
  }

  fetchInstruments = () => {
    return CMESymbolService.fetchInstruments();
  };

  resolveCMESymbols = (cmeSymbol: string) => {
    return SymbolResolver.resolveSymbols(cmeSymbol);
  };

  resolveCQGSymbols = (cqgSymbol: string) => {
    return SymbolResolver.resolveCQGSymbols(cqgSymbol);
  };

  subscribeToMarketData = (contractIds: number[], level: MarketDataSubscription_Level) => {
    CQGEnvironment.Instance.cqgService.subscribeToInstruments(contractIds, level);
  };

  unSubscribeFromMarketData = (contractIds: number[], level: MarketDataSubscription_Level) => {
    CQGEnvironment.Instance.cqgService.unsubscribeFromInstruments(contractIds, level);
  };

  getAllInstruments = () => {
    return InstrumentsManager.getAllInstruments();
  };
}

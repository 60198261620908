import { CMEInstrument } from "../models/CMEInstrument";
import { Instrument } from "../models/Instrument";
import { InstrumentLoadService } from "./InstrumentLoadService";
import { TimeUtil } from "../utils/TimeUtil";

export interface CMEInstruments {
  [key: string]: { [key: string]: CMEInstrument };
}

export class CMESymbolService {
  private static resolvedInstruments: Instrument[] = [];
  private static currentInstrument: Instrument | null = null;
  private static symbolsResolved = true;
  private static instruments: CMEInstruments | null = null;
  private static assetClasses: string[] | null = null;
  private static instrumentsFetch: Promise<CMEInstruments> | null = null;

  static fetchInstruments(): Promise<CMEInstruments> {
    if (!this.instrumentsFetch) {
      this.instrumentsFetch = new Promise(async (resolve, reject) => {

        const baseURL = process.env.REACT_APP_API_URL ?? window.REACT_APP_API_URL;
        fetch(`${baseURL}/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
        })
          .then(async (response) => {
            if (!response.ok) {
              throw new Error("HTTP error " + response.status);
            }

            const respData = (await response.json()) as CMEInstrument[];
            const instruments: CMEInstruments = {};
            this.assetClasses = [];

            respData.forEach((ins) => {
              if (!this.assetClasses?.includes(ins.asset_class.name)) {
                this.assetClasses!.push(ins.asset_class.name);
              }
              if (!instruments[ins.asset_class.name]) {
                instruments[ins.asset_class.name] = {};
              }
              instruments[ins.asset_class.name][ins.cqg_symbol] = ins;
            });

            this.instruments = instruments;
            resolve(instruments);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    return this.instrumentsFetch;
  }

  static findCmeInstrument(cqgSymbol: string): CMEInstrument | null {
    let ins: CMEInstrument | null = null;
    const extractedCqgSymbol = cqgSymbol.slice(0, -3);
    if (this.instruments) {
      for (let key in this.instruments) {
        if (this.instruments.hasOwnProperty(key)) {
          let value = this.instruments[key];

          if (ins) {
            break; // exit the outer loop early
          }

          for (let subKey in value) {
            if (value.hasOwnProperty(subKey)) {
              let v = value[subKey];

              if (extractedCqgSymbol === v.cqg_symbol) {
                ins = v;
                break; // exit the inner loop early
              }
            }
          }
        }
      }
    }
    return ins;
  }

  static processInstrumentFromCmeSymbolRequest(
    cmeSymbol: string,
    resultPromise: { resolve: (value: CMEInstrument) => void; reject: (reason?: any) => void },
  ): void {
    let ins: CMEInstrument | null = null;
    if (this.instruments) {
      for (let key in this.instruments) {
        if (this.instruments.hasOwnProperty(key)) {
          let value = this.instruments[key];

          if (ins) {
            break; // exit the outer loop early
          }

          for (let subKey in value) {
            if (value.hasOwnProperty(subKey)) {
              let v = value[subKey];

              if (cmeSymbol === v.cme_symbol || cmeSymbol.slice(0, -2) === v.cme_symbol) {
                ins = v;
                break; // exit the inner loop early
              }
            }
          }
        }
      }
    }

    if (ins === null) {
      resultPromise.reject(`Instrument for symbol '${cmeSymbol}' not found`);
    } else {
      resultPromise.resolve(ins);
    }
  }

  static findInstrumentsForAssetClass(assetClass: string) {
    let ins: { [key: string]: CMEInstrument } | null = null;
    if (this.instruments) {
      ins = this.instruments[assetClass];
    }
    return ins;
  }

  static getAssetClasses(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      if (this.assetClasses) {
        resolve(this.assetClasses);
      } else {
        this.fetchInstruments()
          .then(() => resolve(this.assetClasses!))
          .catch((error) => reject(error));
      }
    });
  }

  static getInstrumentsForAssetClass(assetClass: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.instruments) {
        resolve(this.findInstrumentsForAssetClass(assetClass));
      } else {
        this.fetchInstruments()
          .then(() => resolve(this.findInstrumentsForAssetClass(assetClass)))
          .catch((error) => reject(error));
      }
    });
  }

  static getCmeInstrument(cqgSymbol: string): Promise<CMEInstrument | null> {
    return new Promise((resolve, reject) => {
      if (this.instruments) {
        resolve(this.findCmeInstrument(cqgSymbol));
      } else {
        this.fetchInstruments()
          .then(() => resolve(this.findCmeInstrument(cqgSymbol)))
          .catch((error) => reject(error));
      }
    });
  }

  static getInstrumentFromCmeSymbol(cmeSymbol: string): Promise<CMEInstrument> {
    return new Promise((resolve, reject) => {
      if (this.instruments) {
        this.processInstrumentFromCmeSymbolRequest(cmeSymbol, { resolve, reject });
      } else {
        this.fetchInstruments()
          .then(() => this.processInstrumentFromCmeSymbolRequest(cmeSymbol, { resolve, reject }))
          .catch((error) => reject(error));
      }
    });
  }

  static isInstrumentActive(instrument: Instrument) {
    return TimeUtil.toUtcDate(instrument.last_trading_date as number) >= new Date();
  }

  static resolveSymbols(cmeSymbol: string) {
    this.getInstrumentFromCmeSymbol(cmeSymbol).then((instrument: CMEInstrument | null) => {
      InstrumentLoadService.loadMonthsFromCqgRoot(instrument!.cqg_symbol, instrument!.quoteboard_months_to_fetch).then(
        (instruments: Instrument[]) => {
          this.resolvedInstruments = instruments.filter((instrument: Instrument) => {
            return this.isInstrumentActive(instrument);
          });
          // instruments = instruments.filter( ( instrument:Instrument) => { return true } );
          // $scope.instruments = instruments;
          // $scope.instruments = instruments;

          if (this.resolvedInstruments.length > 0) {
            this.currentInstrument = this.resolvedInstruments[0];
            this.symbolsResolved = true;
          }
        },
      );
    });
  }
}

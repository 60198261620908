import { DockviewApi, IDockviewPanelProps } from "dockview";
import { DockerviewPanelProps, Widget, WidgetType, Workspace } from "../../types";
import AccountValue from "../widgets/accountValue/AccountValue";
import AvailableProducts from "../widgets/availableProducts/AvailableProducts";
import Orders from "../widgets/orders/Orders";
import Positions from "../widgets/positions/Positions";
import Watchlist from "../widgets/watchlist/Watchlist";
import EnergyChart from "../chart/EnergyChart";
import BarChartDemo from "../chart/BarChartDemo";
import LineChartDemo from "../chart/LineChartDemo";
import TradeLog from "../widgets/TradeLog/index";
import BarChart from "../chart/BarChart";

let panelCount = 0;

export const addPanels = (workspace: Workspace, dockviewApi: DockviewApi, widgets: Widget[], referenceGroup: any) => {
  let referencePanel: any = undefined;
  let referencePanel2: any = undefined;
  widgets?.forEach((widget, index) => {
    const panel = dockviewApi.addPanel<DockerviewPanelProps>({
      id: `id${Date.now().toString()}${(panelCount++).toString()}}`,
      component: "default",
      renderer: "always",
      title: widget.WidgetType,
      params: {
        widget,
        workspace,
      },
      position: {
        referencePanel: index % 2 === 0 && !referenceGroup ? referencePanel2 : referencePanel,
        referenceGroup: referenceGroup,
        direction: !referenceGroup
          ? index > 0
            ? index % 2 === 0
              ? "below"
              : index < 2
                ? "right"
                : "below"
            : "within"
          : undefined,
      },
    });

    if (!referenceGroup) {
      if (index % 2 === 0) {
        referencePanel2 = panel;
      } else {
        referencePanel = panel;
      }
    } else {
      if (index % 2 === 0) {
        referencePanel = panel;
      }
    }
  });
};

export const componentFactory = (widget: Widget, workspace: Workspace) => {
  const workspaceClassName = "grid-layout-workspace";
  const componentName = widget.title;
  if (!workspace?.id) return <>No workspace found</>;
  switch (componentName) {
    case WidgetType.Watchlist:
      return <Watchlist widget={widget} workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.AccountInfo:
      return <AccountValue selectedWorkspace={workspace} workspaceClassName={workspaceClassName} />;
    case WidgetType.AvailableProducts:
      return <AvailableProducts selectedWorkspace={workspace} workspaceClassName={workspaceClassName} />;
    case WidgetType.Orders:
      return <Orders workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.Positions:
      return <Positions workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.TradeLogs:
      return <TradeLog />;

    case WidgetType.EnergyChart:
      return (
        <EnergyChart selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
      );
    case WidgetType.BarChart:
      return (
        <BarChart selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
      );
    case WidgetType.LineChart:
      return <LineChartDemo selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined}  />;
    case WidgetType.DoubleBarChart:
      return <BarChartDemo selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />;

    default:
      return <div>No widget found</div>;
  }
};

export const components = {
  default: (props: IDockviewPanelProps<DockerviewPanelProps>) => {
    return (
      <div
        style={{
          height: "100%",
          overflow: "auto",
          color: "white",
          position: "relative",
          width: "100%",
        }}
      >
        {componentFactory(props.params.widget, props.params.workspace)}
      </div>
    );
  },
};

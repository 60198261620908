import { InstrumentsManager } from "../services/InstrumentsManager";
import { MatchedTrade } from "./MatchedTrade";
import * as TradeRouting from "../proto/trade_routing_2";
import { Account } from "./Account";
import { Instrument } from "./Instrument";

export class PurchaseAndSalesGroup {
  id: number;
  private account: Account;
  private instrument: Instrument | undefined;
  realizedProfitLoss: number = 0;
  private matchedTrades: MatchedTrade[];

  constructor(positionStatus: TradeRouting.PositionStatus, group: TradeRouting.PurchaseAndSalesGroup, account: Account) {
    this.id = group.id;
    this.account = account;
    this.instrument = InstrumentsManager.getInstrument(positionStatus.contractId);
    this.realizedProfitLoss = 0;
    this.matchedTrades = [];

    this.update(positionStatus, group);
  }

  getAccount(): Account {
    return this.account;
  }

  getInstrument() {
    return this.instrument;
  }

  getMatchedTrades(): MatchedTrade[] {
    return this.matchedTrades;
  }

  // TODO: Trace Type of value
  setMatchedTrades(value: any[]): void {
    this.matchedTrades = value;
  }

  update(positionStatus: TradeRouting.PositionStatus, group: TradeRouting.PurchaseAndSalesGroup): void {
    this.realizedProfitLoss = group.realizedProfitLoss;
    this.matchedTrades = group.matchedTrades.map((matchedTrade: TradeRouting.MatchedTrade) => {
      return new MatchedTrade(matchedTrade, this.getInstrument()!);
    });
  }
}

import * as Order from "../proto/order_2";
import * as Shared from "../proto/common/shared_1";
import { numberNullable } from "../types/Types";

export class CQGConstants {
  static inClientOrderStatus = 0;

  static DerivedOrderStatuses = {
    PARKED: -1,
    SECONDARY: -2,
  };

  static Order = {
    MIN_SIZE: 1,
    MAX_SIZE: 2140000000,
  };

  static Price = {
    MIN_PRICE: -2147483648,
    MAX_PRICE: 2147483647,
  };

  static DialogType = {
    ORDER_ALERT: 1,
    LEARNING_MOMENT: 2,
  };

  static API_MAPPING_NAME = "CQG_Trader_COM";

  static getOrderType(orderType: numberNullable): string {
    switch (orderType) {
      case Order.Order_OrderType.ORDER_TYPE_MKT:
        return "MKT";
      case Order.Order_OrderType.ORDER_TYPE_LMT:
        return "LMT";
      case Order.Order_OrderType.ORDER_TYPE_STP:
        return "STP";
      case Order.Order_OrderType.ORDER_TYPE_STL:
        return "STL";
      case Order.Order_OrderType.ORDER_TYPE_CROSS:
        return "CROSS";
      default:
        console.warn("Invalid order type: " + orderType);
        return "UNRECOGNIZED";
    }
  }

  static getOrderSide(side: numberNullable): string {
    switch (side) {
      case Order.Order_Side.SIDE_BUY:
        return "BUY";
      case Order.Order_Side.SIDE_SELL:
        return "SELL";
      default:
        console.warn("Invalid order side: " + side);
        return "UNRECOGNIZED";
    }
  }

  static getOrderDuration(duration: numberNullable): string {
    switch (duration) {
      case Order.Order_Duration.DURATION_DAY:
        return "DAY";
      case Order.Order_Duration.DURATION_GTC:
        return "GTC";
      case Order.Order_Duration.DURATION_GTD:
        return "GTD";
      case Order.Order_Duration.DURATION_GTT:
        return "GTT";
      case Order.Order_Duration.DURATION_FAK:
        return "FAK";
      case Order.Order_Duration.DURATION_FOK:
        return "FOK";
      case Order.Order_Duration.DURATION_ATO:
        return "ATO";
      case Order.Order_Duration.DURATION_ATC:
        return "ATC";
      default:
        console.warn("Invalid order duration: " + duration);
        return "UNRECOGNIZED";
    }
  }

  static getOrderStatus(status: numberNullable): string {
    switch (status) {
      case this.inClientOrderStatus:
        return "IN_CLIENT";
      case Shared.OrderStatus_Status.IN_TRANSIT:
        return "IN_TRANSIT";
      case Shared.OrderStatus_Status.REJECTED:
        return "REJECTED";
      case Shared.OrderStatus_Status.WORKING:
        return "WORKING";
      case Shared.OrderStatus_Status.EXPIRED:
        return "EXPIRED";
      case Shared.OrderStatus_Status.IN_CANCEL:
        return "IN_CANCEL";
      case Shared.OrderStatus_Status.IN_MODIFY:
        return "IN_MODIFY";
      case Shared.OrderStatus_Status.CANCELLED:
        return "CANCELLED";
      case Shared.OrderStatus_Status.FILLED:
        return "FILLED";
      case Shared.OrderStatus_Status.SUSPENDED:
        return "SUSPENDED";
      case Shared.OrderStatus_Status.DISCONNECTED:
        return "DISCONNECTED";
      case Shared.OrderStatus_Status.ACTIVEAT:
        return "ACTIVEAT";
      case this.DerivedOrderStatuses.SECONDARY:
        return "SECONDARY";
      case this.DerivedOrderStatuses.PARKED:
        return "PARKED";
      default:
        console.warn("Invalid order status: " + status);
        return "UNRECOGNIZED";
    }
  }
}

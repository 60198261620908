// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: trading_account_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";
import Long from "long";

export const protobufPackage = "trading_account_2";

/** Request for a list of accounts this user is authorized to use and/ or monitor, empty message. */
export interface AccountsRequest {
}

/**
 * Response with a list of authorized accounts grouped by brokerage firms and internal sales series (account groups),
 * the same account can not belong to more than one account group.
 */
export interface AccountsReport {
  /** List or brokerages with accounts the user works with. */
  brokerages: Brokerage[];
}

/** List of brokerage accounts. */
export interface Brokerage {
  /** Brokerage firm ID in CQG trade routing system. */
  id: number;
  /** Brokerage firm name. */
  name: string;
  /** This field is associated with BrokerageType enum type. */
  type?:
    | number
    | undefined;
  /** List of sales series. */
  salesSeries: SalesSeries[];
}

/** Brokerage type. */
export enum Brokerage_BrokerageType {
  /** BROKERAGE_TYPE_REGULAR - Regular. */
  BROKERAGE_TYPE_REGULAR = 1,
  /** BROKERAGE_TYPE_SIM - Simulation. */
  BROKERAGE_TYPE_SIM = 2,
  /** BROKERAGE_TYPE_DEMO - Demo. */
  BROKERAGE_TYPE_DEMO = 3,
  UNRECOGNIZED = -1,
}

export function brokerage_BrokerageTypeFromJSON(object: any): Brokerage_BrokerageType {
  switch (object) {
    case 1:
    case "BROKERAGE_TYPE_REGULAR":
      return Brokerage_BrokerageType.BROKERAGE_TYPE_REGULAR;
    case 2:
    case "BROKERAGE_TYPE_SIM":
      return Brokerage_BrokerageType.BROKERAGE_TYPE_SIM;
    case 3:
    case "BROKERAGE_TYPE_DEMO":
      return Brokerage_BrokerageType.BROKERAGE_TYPE_DEMO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Brokerage_BrokerageType.UNRECOGNIZED;
  }
}

export function brokerage_BrokerageTypeToJSON(object: Brokerage_BrokerageType): string {
  switch (object) {
    case Brokerage_BrokerageType.BROKERAGE_TYPE_REGULAR:
      return "BROKERAGE_TYPE_REGULAR";
    case Brokerage_BrokerageType.BROKERAGE_TYPE_SIM:
      return "BROKERAGE_TYPE_SIM";
    case Brokerage_BrokerageType.BROKERAGE_TYPE_DEMO:
      return "BROKERAGE_TYPE_DEMO";
    case Brokerage_BrokerageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List of accounts in a sales series group. */
export interface SalesSeries {
  /** Sales series number in CQG trade routing system. */
  number: string;
  /** Sales series name. */
  name: string;
  /** List of accounts. */
  accounts: Account[];
}

/** Trade routing account data. */
export interface Account {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** Account ID in Brokerage Firm. */
  brokerageAccountId: string;
  /** Account name in CQG trade routing system. */
  name: string;
  /**
   * Last statement date for this account
   * (brokerage local date in time format, use date part only).
   */
  lastStatementDate: number;
  /** True if user can only monitor this account without trading. */
  isViewOnly?:
    | boolean
    | undefined;
  /** True if user was unauthorized and is not able to use/ see this account anymore. */
  isUnauthorized?:
    | boolean
    | undefined;
  /**
   * Current account connection status.
   * It is set for only accounts that require authorization.
   */
  accountConnectionStatus?:
    | number
    | undefined;
  /**
   * Account status last update time.
   * It is set for only accounts that require authorization.
   */
  accountConnectionStatusUtcTimestamp?:
    | Date
    | undefined;
  /** Optional list of authorized OTC contributors. */
  contributorIds: string[];
  /**
   * This flag shows if pre trade mid market mark should be calculated by client
   * and provided via order extra attribute "PreTradeMidMarketMark" during order placement or modification.
   */
  preTradeMidMarketMarkRequired?:
    | boolean
    | undefined;
  /**
   * This flag shows if current account can be used to place orders into external accounts.
   * See order_2.Order.external_account_number.
   */
  allowExternalAccounts?:
    | boolean
    | undefined;
  /** True if it is omnibus account. */
  isOmnibus?:
    | boolean
    | undefined;
  /** True if it is a group account member. This field and fields dependent on it are not updated until re-logon. */
  isGroupMember?:
    | boolean
    | undefined;
  /** If true, all orders for the account will be placed as care orders. */
  forceCareOrders?: boolean | undefined;
}

/**
 * Account connection status list.
 * It is used for accounts that require additional authorization.
 */
export enum Account_AccountConnectionStatus {
  /** ACCOUNT_CONNECTION_STATUS_OFFLINE - Route is offline, connection is impossible. */
  ACCOUNT_CONNECTION_STATUS_OFFLINE = 1,
  /** ACCOUNT_CONNECTION_STATUS_DISCONNECTED - Connection is not established but can be initiated by a logon message. */
  ACCOUNT_CONNECTION_STATUS_DISCONNECTED = 2,
  /** ACCOUNT_CONNECTION_STATUS_CONNECTING - Connection is initiated but is not fully established. */
  ACCOUNT_CONNECTION_STATUS_CONNECTING = 3,
  /** ACCOUNT_CONNECTION_STATUS_CONNECTED - Connection is established, trading is possible. */
  ACCOUNT_CONNECTION_STATUS_CONNECTED = 4,
  UNRECOGNIZED = -1,
}

export function account_AccountConnectionStatusFromJSON(object: any): Account_AccountConnectionStatus {
  switch (object) {
    case 1:
    case "ACCOUNT_CONNECTION_STATUS_OFFLINE":
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_OFFLINE;
    case 2:
    case "ACCOUNT_CONNECTION_STATUS_DISCONNECTED":
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_DISCONNECTED;
    case 3:
    case "ACCOUNT_CONNECTION_STATUS_CONNECTING":
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTING;
    case 4:
    case "ACCOUNT_CONNECTION_STATUS_CONNECTED":
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Account_AccountConnectionStatus.UNRECOGNIZED;
  }
}

export function account_AccountConnectionStatusToJSON(object: Account_AccountConnectionStatus): string {
  switch (object) {
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_OFFLINE:
      return "ACCOUNT_CONNECTION_STATUS_OFFLINE";
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_DISCONNECTED:
      return "ACCOUNT_CONNECTION_STATUS_DISCONNECTED";
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTING:
      return "ACCOUNT_CONNECTION_STATUS_CONNECTING";
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTED:
      return "ACCOUNT_CONNECTION_STATUS_CONNECTED";
    case Account_AccountConnectionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request for balances from the last statement for all authorized accounts. */
export interface LastStatementBalancesRequest {
}

/**
 * Report with balances from the last statement for all authorized accounts.
 * Will be sent as a response to a corresponding request
 * and after each statement processing (if subscribed).
 * Each initial report contains a full snapshot of all balances on all accounts.
 * Each report for an active subscription contains updates only.
 */
export interface LastStatementBalancesReport {
  balances: Balance[];
}

/**
 * Balance record for one of the accounts and one of the currencies (id is used as a key for updates).
 * Values like OTE, UPL and MVO are from statements and won't be updated in real time but after each next statement.
 * Money related values are in the specified currency.
 * NOTE: Not all values for a balance record are provided by all statements,
 *       it is up to Brokerage to include such values and only
 *       currency and ending cash balance are required to be valid.
 */
export interface Balance {
  /** Surrogate id as a key for updates. */
  id: number;
  /** Account of this balance record. */
  accountId: number;
  /** Statement date this positions is related. */
  statementDate: number;
  /** Currency code (ISO 4217 based). */
  currency: string;
  /** Ending Cash Balance. */
  balance: number;
  /** Total Account Value. */
  totalValue: number;
  /** Open Trade Equity for Futures and futures-style Options. */
  ote: number;
  /** Unrealized Profit and Loss for premium-style Options and Fixed Income. */
  upl: number;
  /** Market Value of premium-style Options and Fixed Income. */
  mvo: number;
  /** Cash Excess. */
  cashExcess: number;
  /** Collateral on Deposit. */
  collateral: number;
  /** Initial Margin Requirements. */
  initialMargin: number;
  /** If true the balance should be removed from the balances snapshot. */
  deleted?: boolean | undefined;
}

/** Request for currency rates for all brokerages user's accounts belong to. */
export interface CurrencyRatesRequest {
}

/**
 * Report with currency rates per brokerage.
 * It will be sent as a response to a corresponding request
 * and after each statement processing (if subscribed).
 */
export interface CurrencyRatesReport {
  /** List of rates per brokerage the user is authorized to. */
  brokerageCurrencyRates: BrokerageCurrencyRates[];
}

/** Currency rates of one brokerage. */
export interface BrokerageCurrencyRates {
  /** Brokerage ID. */
  brokerageId: number;
  /** Master currency code to which rates are provided (ISO 4217 based). */
  masterCurrency: string;
  /** Statement date. */
  statementDate: number;
  /** List of brokerage rates. */
  currencyRates: CurrencyRate[];
}

/** Rate of one currency to a master currency for the brokerage. */
export interface CurrencyRate {
  /** Currency code (ISO 4217 based). */
  currency: string;
  /** Exchange rate. */
  rate: number;
  /** Number of decimals in 'rate' field. */
  decimalPlaces?: number | undefined;
}

function createBaseAccountsRequest(): AccountsRequest {
  return {};
}

export const AccountsRequest = {
  encode(_: AccountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AccountsRequest {
    return {};
  },

  toJSON(_: AccountsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountsRequest>, I>>(base?: I): AccountsRequest {
    return AccountsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountsRequest>, I>>(_: I): AccountsRequest {
    const message = createBaseAccountsRequest();
    return message;
  },
};

function createBaseAccountsReport(): AccountsReport {
  return { brokerages: [] };
}

export const AccountsReport = {
  encode(message: AccountsReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerages) {
      Brokerage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountsReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountsReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerages.push(Brokerage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountsReport {
    return {
      brokerages: globalThis.Array.isArray(object?.brokerages)
        ? object.brokerages.map((e: any) => Brokerage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountsReport): unknown {
    const obj: any = {};
    if (message.brokerages?.length) {
      obj.brokerages = message.brokerages.map((e) => Brokerage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountsReport>, I>>(base?: I): AccountsReport {
    return AccountsReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountsReport>, I>>(object: I): AccountsReport {
    const message = createBaseAccountsReport();
    message.brokerages = object.brokerages?.map((e) => Brokerage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBrokerage(): Brokerage {
  return { id: 0, name: "", type: 0, salesSeries: [] };
}

export const Brokerage = {
  encode(message: Brokerage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(32).uint32(message.type);
    }
    for (const v of message.salesSeries) {
      SalesSeries.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Brokerage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.salesSeries.push(SalesSeries.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Brokerage {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      salesSeries: globalThis.Array.isArray(object?.salesSeries)
        ? object.salesSeries.map((e: any) => SalesSeries.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Brokerage): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.type !== undefined && message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.salesSeries?.length) {
      obj.salesSeries = message.salesSeries.map((e) => SalesSeries.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Brokerage>, I>>(base?: I): Brokerage {
    return Brokerage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Brokerage>, I>>(object: I): Brokerage {
    const message = createBaseBrokerage();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.type = object.type ?? 0;
    message.salesSeries = object.salesSeries?.map((e) => SalesSeries.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSalesSeries(): SalesSeries {
  return { number: "", name: "", accounts: [] };
}

export const SalesSeries = {
  encode(message: SalesSeries, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.accounts) {
      Account.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SalesSeries {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSalesSeries();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accounts.push(Account.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SalesSeries {
    return {
      number: isSet(object.number) ? globalThis.String(object.number) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      accounts: globalThis.Array.isArray(object?.accounts) ? object.accounts.map((e: any) => Account.fromJSON(e)) : [],
    };
  },

  toJSON(message: SalesSeries): unknown {
    const obj: any = {};
    if (message.number !== "") {
      obj.number = message.number;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.accounts?.length) {
      obj.accounts = message.accounts.map((e) => Account.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesSeries>, I>>(base?: I): SalesSeries {
    return SalesSeries.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SalesSeries>, I>>(object: I): SalesSeries {
    const message = createBaseSalesSeries();
    message.number = object.number ?? "";
    message.name = object.name ?? "";
    message.accounts = object.accounts?.map((e) => Account.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccount(): Account {
  return {
    accountId: 0,
    brokerageAccountId: "",
    name: "",
    lastStatementDate: 0,
    isViewOnly: false,
    isUnauthorized: false,
    accountConnectionStatus: 0,
    accountConnectionStatusUtcTimestamp: undefined,
    contributorIds: [],
    preTradeMidMarketMarkRequired: false,
    allowExternalAccounts: false,
    isOmnibus: false,
    isGroupMember: false,
    forceCareOrders: false,
  };
}

export const Account = {
  encode(message: Account, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.brokerageAccountId !== "") {
      writer.uint32(18).string(message.brokerageAccountId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.lastStatementDate !== 0) {
      writer.uint32(32).sint64(message.lastStatementDate);
    }
    if (message.isViewOnly !== undefined && message.isViewOnly !== false) {
      writer.uint32(40).bool(message.isViewOnly);
    }
    if (message.isUnauthorized !== undefined && message.isUnauthorized !== false) {
      writer.uint32(48).bool(message.isUnauthorized);
    }
    if (message.accountConnectionStatus !== undefined && message.accountConnectionStatus !== 0) {
      writer.uint32(64).uint32(message.accountConnectionStatus);
    }
    if (message.accountConnectionStatusUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.accountConnectionStatusUtcTimestamp), writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.contributorIds) {
      writer.uint32(82).string(v!);
    }
    if (message.preTradeMidMarketMarkRequired !== undefined && message.preTradeMidMarketMarkRequired !== false) {
      writer.uint32(88).bool(message.preTradeMidMarketMarkRequired);
    }
    if (message.allowExternalAccounts !== undefined && message.allowExternalAccounts !== false) {
      writer.uint32(96).bool(message.allowExternalAccounts);
    }
    if (message.isOmnibus !== undefined && message.isOmnibus !== false) {
      writer.uint32(104).bool(message.isOmnibus);
    }
    if (message.isGroupMember !== undefined && message.isGroupMember !== false) {
      writer.uint32(112).bool(message.isGroupMember);
    }
    if (message.forceCareOrders !== undefined && message.forceCareOrders !== false) {
      writer.uint32(120).bool(message.forceCareOrders);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokerageAccountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lastStatementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isViewOnly = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isUnauthorized = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.accountConnectionStatus = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.accountConnectionStatusUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.contributorIds.push(reader.string());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.preTradeMidMarketMarkRequired = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.allowExternalAccounts = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isOmnibus = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.isGroupMember = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.forceCareOrders = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Account {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      brokerageAccountId: isSet(object.brokerageAccountId) ? globalThis.String(object.brokerageAccountId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      lastStatementDate: isSet(object.lastStatementDate) ? globalThis.Number(object.lastStatementDate) : 0,
      isViewOnly: isSet(object.isViewOnly) ? globalThis.Boolean(object.isViewOnly) : false,
      isUnauthorized: isSet(object.isUnauthorized) ? globalThis.Boolean(object.isUnauthorized) : false,
      accountConnectionStatus: isSet(object.accountConnectionStatus)
        ? globalThis.Number(object.accountConnectionStatus)
        : 0,
      accountConnectionStatusUtcTimestamp: isSet(object.accountConnectionStatusUtcTimestamp)
        ? fromJsonTimestamp(object.accountConnectionStatusUtcTimestamp)
        : undefined,
      contributorIds: globalThis.Array.isArray(object?.contributorIds)
        ? object.contributorIds.map((e: any) => globalThis.String(e))
        : [],
      preTradeMidMarketMarkRequired: isSet(object.preTradeMidMarketMarkRequired)
        ? globalThis.Boolean(object.preTradeMidMarketMarkRequired)
        : false,
      allowExternalAccounts: isSet(object.allowExternalAccounts)
        ? globalThis.Boolean(object.allowExternalAccounts)
        : false,
      isOmnibus: isSet(object.isOmnibus) ? globalThis.Boolean(object.isOmnibus) : false,
      isGroupMember: isSet(object.isGroupMember) ? globalThis.Boolean(object.isGroupMember) : false,
      forceCareOrders: isSet(object.forceCareOrders) ? globalThis.Boolean(object.forceCareOrders) : false,
    };
  },

  toJSON(message: Account): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.brokerageAccountId !== "") {
      obj.brokerageAccountId = message.brokerageAccountId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.lastStatementDate !== 0) {
      obj.lastStatementDate = Math.round(message.lastStatementDate);
    }
    if (message.isViewOnly !== undefined && message.isViewOnly !== false) {
      obj.isViewOnly = message.isViewOnly;
    }
    if (message.isUnauthorized !== undefined && message.isUnauthorized !== false) {
      obj.isUnauthorized = message.isUnauthorized;
    }
    if (message.accountConnectionStatus !== undefined && message.accountConnectionStatus !== 0) {
      obj.accountConnectionStatus = Math.round(message.accountConnectionStatus);
    }
    if (message.accountConnectionStatusUtcTimestamp !== undefined) {
      obj.accountConnectionStatusUtcTimestamp = message.accountConnectionStatusUtcTimestamp.toISOString();
    }
    if (message.contributorIds?.length) {
      obj.contributorIds = message.contributorIds;
    }
    if (message.preTradeMidMarketMarkRequired !== undefined && message.preTradeMidMarketMarkRequired !== false) {
      obj.preTradeMidMarketMarkRequired = message.preTradeMidMarketMarkRequired;
    }
    if (message.allowExternalAccounts !== undefined && message.allowExternalAccounts !== false) {
      obj.allowExternalAccounts = message.allowExternalAccounts;
    }
    if (message.isOmnibus !== undefined && message.isOmnibus !== false) {
      obj.isOmnibus = message.isOmnibus;
    }
    if (message.isGroupMember !== undefined && message.isGroupMember !== false) {
      obj.isGroupMember = message.isGroupMember;
    }
    if (message.forceCareOrders !== undefined && message.forceCareOrders !== false) {
      obj.forceCareOrders = message.forceCareOrders;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Account>, I>>(base?: I): Account {
    return Account.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Account>, I>>(object: I): Account {
    const message = createBaseAccount();
    message.accountId = object.accountId ?? 0;
    message.brokerageAccountId = object.brokerageAccountId ?? "";
    message.name = object.name ?? "";
    message.lastStatementDate = object.lastStatementDate ?? 0;
    message.isViewOnly = object.isViewOnly ?? false;
    message.isUnauthorized = object.isUnauthorized ?? false;
    message.accountConnectionStatus = object.accountConnectionStatus ?? 0;
    message.accountConnectionStatusUtcTimestamp = object.accountConnectionStatusUtcTimestamp ?? undefined;
    message.contributorIds = object.contributorIds?.map((e) => e) || [];
    message.preTradeMidMarketMarkRequired = object.preTradeMidMarketMarkRequired ?? false;
    message.allowExternalAccounts = object.allowExternalAccounts ?? false;
    message.isOmnibus = object.isOmnibus ?? false;
    message.isGroupMember = object.isGroupMember ?? false;
    message.forceCareOrders = object.forceCareOrders ?? false;
    return message;
  },
};

function createBaseLastStatementBalancesRequest(): LastStatementBalancesRequest {
  return {};
}

export const LastStatementBalancesRequest = {
  encode(_: LastStatementBalancesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LastStatementBalancesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLastStatementBalancesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LastStatementBalancesRequest {
    return {};
  },

  toJSON(_: LastStatementBalancesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LastStatementBalancesRequest>, I>>(base?: I): LastStatementBalancesRequest {
    return LastStatementBalancesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LastStatementBalancesRequest>, I>>(_: I): LastStatementBalancesRequest {
    const message = createBaseLastStatementBalancesRequest();
    return message;
  },
};

function createBaseLastStatementBalancesReport(): LastStatementBalancesReport {
  return { balances: [] };
}

export const LastStatementBalancesReport = {
  encode(message: LastStatementBalancesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.balances) {
      Balance.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LastStatementBalancesReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLastStatementBalancesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.balances.push(Balance.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LastStatementBalancesReport {
    return {
      balances: globalThis.Array.isArray(object?.balances) ? object.balances.map((e: any) => Balance.fromJSON(e)) : [],
    };
  },

  toJSON(message: LastStatementBalancesReport): unknown {
    const obj: any = {};
    if (message.balances?.length) {
      obj.balances = message.balances.map((e) => Balance.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LastStatementBalancesReport>, I>>(base?: I): LastStatementBalancesReport {
    return LastStatementBalancesReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LastStatementBalancesReport>, I>>(object: I): LastStatementBalancesReport {
    const message = createBaseLastStatementBalancesReport();
    message.balances = object.balances?.map((e) => Balance.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBalance(): Balance {
  return {
    id: 0,
    accountId: 0,
    statementDate: 0,
    currency: "",
    balance: 0,
    totalValue: 0,
    ote: 0,
    upl: 0,
    mvo: 0,
    cashExcess: 0,
    collateral: 0,
    initialMargin: 0,
    deleted: false,
  };
}

export const Balance = {
  encode(message: Balance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.statementDate !== 0) {
      writer.uint32(24).sint64(message.statementDate);
    }
    if (message.currency !== "") {
      writer.uint32(34).string(message.currency);
    }
    if (message.balance !== 0) {
      writer.uint32(41).double(message.balance);
    }
    if (message.totalValue !== 0) {
      writer.uint32(49).double(message.totalValue);
    }
    if (message.ote !== 0) {
      writer.uint32(57).double(message.ote);
    }
    if (message.upl !== 0) {
      writer.uint32(65).double(message.upl);
    }
    if (message.mvo !== 0) {
      writer.uint32(73).double(message.mvo);
    }
    if (message.cashExcess !== 0) {
      writer.uint32(81).double(message.cashExcess);
    }
    if (message.collateral !== 0) {
      writer.uint32(89).double(message.collateral);
    }
    if (message.initialMargin !== 0) {
      writer.uint32(97).double(message.initialMargin);
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      writer.uint32(104).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Balance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.statementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.balance = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.totalValue = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.ote = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.upl = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.mvo = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.cashExcess = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.collateral = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.initialMargin = reader.double();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.deleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Balance {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      statementDate: isSet(object.statementDate) ? globalThis.Number(object.statementDate) : 0,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      balance: isSet(object.balance) ? globalThis.Number(object.balance) : 0,
      totalValue: isSet(object.totalValue) ? globalThis.Number(object.totalValue) : 0,
      ote: isSet(object.ote) ? globalThis.Number(object.ote) : 0,
      upl: isSet(object.upl) ? globalThis.Number(object.upl) : 0,
      mvo: isSet(object.mvo) ? globalThis.Number(object.mvo) : 0,
      cashExcess: isSet(object.cashExcess) ? globalThis.Number(object.cashExcess) : 0,
      collateral: isSet(object.collateral) ? globalThis.Number(object.collateral) : 0,
      initialMargin: isSet(object.initialMargin) ? globalThis.Number(object.initialMargin) : 0,
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
    };
  },

  toJSON(message: Balance): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.statementDate !== 0) {
      obj.statementDate = Math.round(message.statementDate);
    }
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.balance !== 0) {
      obj.balance = message.balance;
    }
    if (message.totalValue !== 0) {
      obj.totalValue = message.totalValue;
    }
    if (message.ote !== 0) {
      obj.ote = message.ote;
    }
    if (message.upl !== 0) {
      obj.upl = message.upl;
    }
    if (message.mvo !== 0) {
      obj.mvo = message.mvo;
    }
    if (message.cashExcess !== 0) {
      obj.cashExcess = message.cashExcess;
    }
    if (message.collateral !== 0) {
      obj.collateral = message.collateral;
    }
    if (message.initialMargin !== 0) {
      obj.initialMargin = message.initialMargin;
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Balance>, I>>(base?: I): Balance {
    return Balance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Balance>, I>>(object: I): Balance {
    const message = createBaseBalance();
    message.id = object.id ?? 0;
    message.accountId = object.accountId ?? 0;
    message.statementDate = object.statementDate ?? 0;
    message.currency = object.currency ?? "";
    message.balance = object.balance ?? 0;
    message.totalValue = object.totalValue ?? 0;
    message.ote = object.ote ?? 0;
    message.upl = object.upl ?? 0;
    message.mvo = object.mvo ?? 0;
    message.cashExcess = object.cashExcess ?? 0;
    message.collateral = object.collateral ?? 0;
    message.initialMargin = object.initialMargin ?? 0;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseCurrencyRatesRequest(): CurrencyRatesRequest {
  return {};
}

export const CurrencyRatesRequest = {
  encode(_: CurrencyRatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CurrencyRatesRequest {
    return {};
  },

  toJSON(_: CurrencyRatesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRatesRequest>, I>>(base?: I): CurrencyRatesRequest {
    return CurrencyRatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CurrencyRatesRequest>, I>>(_: I): CurrencyRatesRequest {
    const message = createBaseCurrencyRatesRequest();
    return message;
  },
};

function createBaseCurrencyRatesReport(): CurrencyRatesReport {
  return { brokerageCurrencyRates: [] };
}

export const CurrencyRatesReport = {
  encode(message: CurrencyRatesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerageCurrencyRates) {
      BrokerageCurrencyRates.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRatesReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRatesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerageCurrencyRates.push(BrokerageCurrencyRates.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CurrencyRatesReport {
    return {
      brokerageCurrencyRates: globalThis.Array.isArray(object?.brokerageCurrencyRates)
        ? object.brokerageCurrencyRates.map((e: any) => BrokerageCurrencyRates.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CurrencyRatesReport): unknown {
    const obj: any = {};
    if (message.brokerageCurrencyRates?.length) {
      obj.brokerageCurrencyRates = message.brokerageCurrencyRates.map((e) => BrokerageCurrencyRates.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRatesReport>, I>>(base?: I): CurrencyRatesReport {
    return CurrencyRatesReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CurrencyRatesReport>, I>>(object: I): CurrencyRatesReport {
    const message = createBaseCurrencyRatesReport();
    message.brokerageCurrencyRates = object.brokerageCurrencyRates?.map((e) => BrokerageCurrencyRates.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseBrokerageCurrencyRates(): BrokerageCurrencyRates {
  return { brokerageId: 0, masterCurrency: "", statementDate: 0, currencyRates: [] };
}

export const BrokerageCurrencyRates = {
  encode(message: BrokerageCurrencyRates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerageId !== 0) {
      writer.uint32(8).uint32(message.brokerageId);
    }
    if (message.masterCurrency !== "") {
      writer.uint32(18).string(message.masterCurrency);
    }
    if (message.statementDate !== 0) {
      writer.uint32(24).sint64(message.statementDate);
    }
    for (const v of message.currencyRates) {
      CurrencyRate.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerageCurrencyRates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerageCurrencyRates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.brokerageId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.masterCurrency = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.statementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currencyRates.push(CurrencyRate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BrokerageCurrencyRates {
    return {
      brokerageId: isSet(object.brokerageId) ? globalThis.Number(object.brokerageId) : 0,
      masterCurrency: isSet(object.masterCurrency) ? globalThis.String(object.masterCurrency) : "",
      statementDate: isSet(object.statementDate) ? globalThis.Number(object.statementDate) : 0,
      currencyRates: globalThis.Array.isArray(object?.currencyRates)
        ? object.currencyRates.map((e: any) => CurrencyRate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BrokerageCurrencyRates): unknown {
    const obj: any = {};
    if (message.brokerageId !== 0) {
      obj.brokerageId = Math.round(message.brokerageId);
    }
    if (message.masterCurrency !== "") {
      obj.masterCurrency = message.masterCurrency;
    }
    if (message.statementDate !== 0) {
      obj.statementDate = Math.round(message.statementDate);
    }
    if (message.currencyRates?.length) {
      obj.currencyRates = message.currencyRates.map((e) => CurrencyRate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerageCurrencyRates>, I>>(base?: I): BrokerageCurrencyRates {
    return BrokerageCurrencyRates.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BrokerageCurrencyRates>, I>>(object: I): BrokerageCurrencyRates {
    const message = createBaseBrokerageCurrencyRates();
    message.brokerageId = object.brokerageId ?? 0;
    message.masterCurrency = object.masterCurrency ?? "";
    message.statementDate = object.statementDate ?? 0;
    message.currencyRates = object.currencyRates?.map((e) => CurrencyRate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCurrencyRate(): CurrencyRate {
  return { currency: "", rate: 0, decimalPlaces: 0 };
}

export const CurrencyRate = {
  encode(message: CurrencyRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== "") {
      writer.uint32(18).string(message.currency);
    }
    if (message.rate !== 0) {
      writer.uint32(25).double(message.rate);
    }
    if (message.decimalPlaces !== undefined && message.decimalPlaces !== 0) {
      writer.uint32(32).uint32(message.decimalPlaces);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.rate = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.decimalPlaces = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CurrencyRate {
    return {
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      rate: isSet(object.rate) ? globalThis.Number(object.rate) : 0,
      decimalPlaces: isSet(object.decimalPlaces) ? globalThis.Number(object.decimalPlaces) : 0,
    };
  },

  toJSON(message: CurrencyRate): unknown {
    const obj: any = {};
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.rate !== 0) {
      obj.rate = message.rate;
    }
    if (message.decimalPlaces !== undefined && message.decimalPlaces !== 0) {
      obj.decimalPlaces = Math.round(message.decimalPlaces);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRate>, I>>(base?: I): CurrencyRate {
    return CurrencyRate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CurrencyRate>, I>>(object: I): CurrencyRate {
    const message = createBaseCurrencyRate();
    message.currency = object.currency ?? "";
    message.rate = object.rate ?? 0;
    message.decimalPlaces = object.decimalPlaces ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

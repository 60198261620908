import { CQGService } from "./CQGService";
import { Deferred } from "../utils/Deferred";
import { Instrument } from "../models/Instrument";
import { stringNullable } from "../types/Types";
import { SymbolResolutionSubscription } from "./SymbolResolutionSubscriptions";

export class InstrumentLoadService {
  static loadMonthsFromCqgRoot = (cqgRoot: string, numberOfMonthsToFetch: number) => {
    return this.fetchMonths(cqgRoot, numberOfMonthsToFetch);
  };

  static loadMonths = (instrument: Instrument) => {
    var defer = Promise;

    let finalizedInstruments = (instruments: Instrument[]) => {
      if (
        instrument.isFuture() &&
        instruments.find((ins) => {
          return ins.contractId === instrument.contractId;
        })
      ) {
        var copy = instruments.slice();
        copy.unshift(instrument);

        return copy;
      }

      return instruments;
    };

    if (!instrument.isCmeResolved()) {
      instrument.resolveFromCmeSymbol().then(
        () => {
          this.fetchMonths(instrument.cqgSymbol, instrument.quoteboardMonthsToFetch as number).then(
            (instruments: Instrument[]) => {
              defer.resolve(finalizedInstruments(instruments));
            },
            (error: Error) => {
              defer.reject(error);
            },
          );
        },
        (error: Error) => {
          defer.reject(error);
        },
      );
    } else {
      this.fetchMonths(instrument.cqgSymbol, instrument.quoteboardMonthsToFetch as number).then(
        (instruments: Instrument[]) => {
          defer.resolve(finalizedInstruments(instruments));
        },
        (error: Error) => {
          defer.reject(error);
        },
      );
    }

    return defer;
  };

  static fetchMonths = (cqgRootSymbol: stringNullable, numberOfMonthsToFetch: number) => {
    let defer = new Deferred<Instrument[]>();
    let prefix = "F.US.";
    let symbols = [prefix + cqgRootSymbol];
    let count = numberOfMonthsToFetch;
    let instruments: Instrument[] = [];
    let fetchCount = 0;
    let timeoutSecs = 5;

    let timedOut = () => {
      console.warn("Instrument resolution taking more time than expected for: " + cqgRootSymbol);
      recordAndNotifyIfComplete(true);
    };

    let timer = setTimeout(timedOut, timeoutSecs * 1000);

    const recordAndNotifyIfComplete = (force: boolean = false) => {
      if (++fetchCount === count || force === true) {
        clearTimeout(timer);
        defer.resolve(instruments);
      }
    };

    for (let i = 1; i <= count; i++) {
      symbols.push(prefix + cqgRootSymbol + "?" + i);
    }
    // console.log("Symbol resolution request: ", symbols);

    let syms: SymbolResolutionSubscription[] = CQGService.resolveSymbols(symbols);

    syms.forEach((sym: SymbolResolutionSubscription) => {
      sym.resolvePromise.then(
        (ins: Instrument) => {
          if (
            !instruments.find((inst) => {
              return inst.contractSymbol === ins.contractSymbol;
            })
          ) {
            instruments.push(ins);

            if (!ins.isCmeResolved()) {
              ins.resolveFromCmeSymbol().then(
                () => {
                  recordAndNotifyIfComplete();
                },
                (error: any) => {
                  console.warn(error);
                  recordAndNotifyIfComplete();
                },
              );
            } else {
              recordAndNotifyIfComplete();
            }
          } else {
            // It has been seen that CQG resolves to same symbol for different months. So just see if we are done.
            recordAndNotifyIfComplete();
          }
        },
        (error: any) => {
          console.debug("Error resolving cqg symbol '" + error.symbol + "'. Reason[ " + error.reason + " ]");
          recordAndNotifyIfComplete();
        },
      );
    });

    return defer.promise;
  };
}

import { numberNullable, stringNullable } from "../types/Types";

export class CurrencyRatesSubscription {
  id: number;
  consumerCount: number = 0;
  statusCode: numberNullable;
  textMessage: stringNullable;

  constructor(requestId: number) {
    this.id = requestId;
  }
}

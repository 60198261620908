import React, { useCallback, useState } from "react";
import ECharts from "echarts-for-react";

const options = {
  xAxis: [
    {
      type: "category",
      data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      axisPointer: {
        type: "shadow",
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "",
      min: 0,
      max: 250,
      interval: 50,
      axisLabel: {
        formatter: "{value} ml",
      },
    },
    {
      type: "value",
      name: "",
      min: 0,
      max: 25,
      interval: 5,
      axisLabel: {
        formatter: "{value} °C",
      },
    },
  ],
  series: [
    {
      name: "",
      type: "line",
      yAxisIndex: 1,
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
    },
  ],
};

export default function LineChartDemo({
  workspaceClassName,
  selectedWorkspace,
  isPopupWindow,
}) {
  const [width, setWidth] = useState();
  const div = useCallback(
    (node) => {
      if (node !== null) {
        setWidth(node.getBoundingClientRect().width);
      }
    },
    // eslint-disable-next-line
    [selectedWorkspace]
  );
  return (
    <div
      ref={div}
      style={{ width: workspaceClassName ? "" : "450px" }}
      className={workspaceClassName}
    >
      <ECharts
        option={options}
        className={workspaceClassName}
        style={{ width: isPopupWindow ? "" : `${width}px` }}
      />
    </div>
  );
}

// TYPE CASTING
export function isArrayWithValues(arr: any[]) {
  return Array.isArray(arr) && arr.length > 0;
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export function toNumber(value: any) {
  if (isNullOrUndefined(value)) return 0;

  // value may be '2' or 2 or '2.0' or 2.0 or `100,000.00` or `100000.00`
  let val = value.toString().replace(/,/g, "");
  let parsedVal = parseFloat(val);
  return parsedVal;
}

export function isNumber(value: string | number) {
  if (isNullOrUndefined(value)) return false;
  let parsedVal = toNumber(value);
  return !isNaN(parsedVal);
}

// FORMAT NUMBERS
export function formatCurremcy(value: any, decimals: number = 2, currency: string = "USD") {
  let parsedVal = toNumber(value);
  return parsedVal.toLocaleString("en-US", {
    maximumFractionDigits: decimals,
    style: "currency",
    currency: currency,
  });
}


// FORMAT DATES

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

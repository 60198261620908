import { createSelector } from "reselect";

const selectRaw = (state: any) => state.auth;

const currentUser = createSelector(
  [selectRaw],
  (auth) => auth.currentUser
);

const authSelectors = {
  selectRaw,
  currentUser,
};

export default authSelectors;

import { Widget, WidgetType } from "../../types";

export const widgets: Widget[] = [
  {
    key: 1,
    title: WidgetType.AccountInfo,
    WidgetType: WidgetType.AccountInfo,
    icon: "/images/icons/tradePlan.svg",

  },
  {
    key: 2,
    title: WidgetType.Watchlist,
    WidgetType: WidgetType.Watchlist,
    icon: "/images/icons/tradeLog.svg",
  },
  {
    key: 3,
    title: WidgetType.AvailableProducts,
    WidgetType: WidgetType.AvailableProducts,
    icon: "/images/icons/leaderboard.svg",
  },
  {
    key: 4,
    title: WidgetType.Orders,
    WidgetType: WidgetType.Orders,
    icon: "/images/icons/order.svg",
  },
  {
    key: 5,
    title: WidgetType.Positions,
    WidgetType: WidgetType.Positions,
    icon: "/images/icons/position.svg",
  },
  {
    key: 6,
    title: WidgetType.TradeLogs,
    WidgetType: WidgetType.TradeLogs,
    icon: "/images/icons/tradePlan.svg",
  },
];

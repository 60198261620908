import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "@mui/styles";
import { Checkbox, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Product } from "../../../types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: '10px',
    padding: '10px 0px 10px 0px',
    fontFamily: 'Averta-regular',
    fontWeight: '700',
  },
  [`&.${tableCellClasses.body}`]: {
    height: '5px',
    fontSize: 12,
    padding: '1px',
    fontFamily: 'Averta-regular',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.selected-product-row": {
    backgroundColor: "#555",
  },
}));

const useStyles = makeStyles({
  root: {
    border: "1px solid #000 !important",
    borderRadius: "8px !important",
    width: "150px",
    height: "40px",
    position: "relative",
    zIndex: "1",
    fontFamily: 'Averta-regular',
    padding: "10px 20px !important",
    opacity: "0.8",
    color: "black!important",
    "&:before": {
      content: '""',
      display: "block",
      height: "calc(100% - 2px)",
      width: "calc(100% - 2px)",
      position: "absolute",
      top: "1px",
      left: "1px",
      borderRadius: "9px",
      zIndex: "-1",
    },
    "&:hover": {
      opacity: "1",
      backgroundColor: "#0000001a!important",
      color: "#333",
    },
    "&:disabled": {
      opacity: "0.5",
    },
  },
});
const textFieldStyle = {
  "& .MuiFormLabel-root": {
    color: '#000',
    "&.Mui-focused": {
      color: '#000',
    },
  },
  "& .MuiInputBase-root": {
    color: '#000',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: '#000',
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#000',
    },
  },
  "& .MuiInputBase-input": {
    color: '#000',
  },
  "& .MuiFormHelperText-root": {
    color: '#000',
  },
};

const AddProduct = ({
  open,
  setOpen,
  onAddProduct,
  products,
  loaded,
  updateWatchlistLoaded,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  products?: Product[];
  onAddProduct?: (watchlistItemIds: string) => void;
  loaded?: boolean;
  updateWatchlistLoaded?: boolean;
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);

  const handleClose = () => {
    setOpen(false);
    setSelectedRowIds([]);
    setSearch("");
  };
  const handleOnAddProduct = async () => {
    if (selectedRowIds.length) {
      await onAddProduct?.(selectedRowIds.join(","));
      setSelectedRowIds([]);
      setSearch("");
    }
  };

  const onSelectProductRow = (row: any) => {
    if (selectedRowIds.includes(row?.id)) {
      setSelectedRowIds(selectedRowIds.filter((key: any) => key !== row?.id));
    } else {
      setSelectedRowIds([row?.id, ...selectedRowIds]);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          padding: "12px",
          borderRadius: "8px",
          minWidth: "850px",
          height: "550px",
        },
      }}
      className="draggableCancelSelector"
    >
      <DialogTitle id="customized-dialog-title" className="draggableCancelSelector">
        Add Products
        <div style={{ marginTop: "20px" }}>
          <TextField
            id="title"
            label="Search"
            size="small"
            className="basic-info-title-field"
            sx={textFieldStyle}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </DialogTitle>
      <DialogContent dividers className="add-product-dialog draggableCancelSelector">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Symbol</StyledTableCell>
                <StyledTableCell align="left">Short Name</StyledTableCell>
                <StyledTableCell align="left">Tick Size</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products
                ?.filter((item) => {
                  if (!item) return false;
                  if (!search) return true;

                  const text = search.toLowerCase();
                  return text === ""
                    ? item
                    : item.name?.toLowerCase().includes(text) ||
                        item.shortName?.toLowerCase().includes(text) ||
                        item.assetClass?.name?.toLowerCase().includes(text) ||
                        item.cmeSymbol?.toLowerCase().includes(text);
                })
                .map((row) => (
                  <StyledTableRow
                    key={row.id}
                    onClick={() => {
                      onSelectProductRow(row);
                    }}
                    className={
                      // selectedRowIds.includes(row.id)
                      //   ? "selected-product-row"
                      //   :
                      "product-row"
                    }
                  >
                    <StyledTableCell align="left">
                    <Checkbox checked={selectedRowIds.includes(row.id)}
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'rgba(0, 0, 0, 0.80)',
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 18,
                          },
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.cmeSymbol}</StyledTableCell>
                    <StyledTableCell align="left">{row.shortName}</StyledTableCell>
                    <StyledTableCell align="left">{row.tickSize}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions style={{ gap: "12px" }}>
        <Button className={classes.root} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={classes.root}
          onClick={handleOnAddProduct}
          autoFocus
          disabled={!Boolean(selectedRowIds.length) || !updateWatchlistLoaded || !loaded}
        >
          {!updateWatchlistLoaded || !loaded ? "Loading..." : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProduct;

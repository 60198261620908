import { DisplayUtil } from "../utils/DisplayUtil";
import { TimeUtil } from "../utils/TimeUtil";
import * as TradeRouting from "../proto/trade_routing_2";
import { numberNullable, stringNullable } from "../types/Types";
import { Instrument } from "./Instrument";

export class MatchedTrade {
  private instrument: Instrument;
  isShort: boolean | undefined;
  size: number = 0;
  price: number;
  tradeDate: Date;
  tradeUtcTime: Date | null;
  statementDate: Date;
  displaySymbol: stringNullable;
  displayCurrency: stringNullable;
  displayTradeDate: stringNullable;
  displayLong: numberNullable = null;
  displayShort: numberNullable = null;
  displayPrice: numberNullable = null;

  constructor(matchedTrade: TradeRouting.MatchedTrade, instrument: Instrument) {
    this.instrument = instrument;
    this.isShort = matchedTrade.isShort;
    this.size = matchedTrade.uint32Qty!;
    this.price = matchedTrade.price;
    this.tradeDate = TimeUtil.toOverchukUtcBasedDate(matchedTrade.tradeDate);
    this.tradeUtcTime = matchedTrade.tradeUtcTime ? TimeUtil.toOverchukUtcBasedDate(matchedTrade.tradeUtcTime) : null;
    this.statementDate = TimeUtil.toOverchukUtcBasedDate(matchedTrade.statementDate);

    this.updateDisplayValues();
  }

  getInstrument(): Instrument {
    return this.instrument;
  }

  updateDisplayValues(): void {
    this.displaySymbol = this.instrument.title;
    this.displayCurrency = this.instrument.currency;
    this.displayTradeDate = DisplayUtil.toDisplayDate(this.tradeDate);
    this.displayLong = this.isShort ? null : this.size;
    this.displayShort = this.isShort ? this.size : null;
    this.displayPrice = DisplayUtil.toDisplayPrice(this.price, this.instrument);
  }
}

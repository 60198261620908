import { DockviewGroupPanelFloatingChangeEvent, IDockviewHeaderActionsProps } from "dockview";
import { useEffect, useState } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MinimizeIcon from "@mui/icons-material/Minimize";

const iconStyle = { cursor: "pointer", height: "15px", width: "15px" };
const spacingStyle = { ...iconStyle, paddingLeft: "10px" };

const RightHeaderControls = (props: IDockviewHeaderActionsProps) => {
  const [isMaximized, setIsMaximized] = useState(props.containerApi.hasMaximizedGroup());
  const [isFloating, setIsFloating] = useState(props.api.location.type === "floating");

  useEffect(() => {
    const handleMaximizedChange = () => setIsMaximized(props.containerApi.hasMaximizedGroup());
    const handleLocationChange = (event: DockviewGroupPanelFloatingChangeEvent) =>
      setIsFloating(event.location.type === "floating");

    const disposable = props.containerApi.onDidMaximizedGroupChange(handleMaximizedChange);
    const disposableLocation = props.api.onDidLocationChange(handleLocationChange);

    return () => {
      disposable.dispose();
      disposableLocation.dispose();
    };
  }, [props.containerApi, props.api]);

  const toggleMaximize = () => {
    if (isMaximized) {
      props.containerApi.exitMaximizedGroup();
    } else {
      props.activePanel?.api.maximize();
    }
  };

  const toggleFloating = () => {
    if (isFloating) {
      const group = props.containerApi.addGroup();
      props.group.api.moveTo({ group });
    } else {
      props.containerApi.addFloatingGroup(props.group, {
        position: { bottom: 300, right: 600 },
      });
    }
  };

  return (
    <div
      className="group-control"
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0px 8px",
        height: "100%",
        color: "var(--dv-activegroup-visiblepanel-tab-color)",
      }}
    >
      {!isFloating &&
        (isMaximized ? (
          <CloseFullscreenIcon style={iconStyle} onClick={toggleMaximize} />
        ) : (
          <OpenInFullIcon style={iconStyle} onClick={toggleMaximize} />
        ))}
      {isFloating ? (
        <MinimizeIcon style={spacingStyle} onClick={toggleFloating} />
      ) : (
        <OpenInNewIcon style={spacingStyle} onClick={toggleFloating} />
      )}
    </div>
  );
};

export default RightHeaderControls;

export function arrFindLast(array: any, predicate: any) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return undefined;
}

export function sortBy<T>(array: T[], iteratee: (item: T) => any): T[] {
  return array.slice().sort((a, b) => {
    const aValue = iteratee(a);
    const bValue = iteratee(b);

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  });
}

//   Usage example:
//   const sortedTransactionStatuses = sortBy(
//     transactionStatuses,
//     (status: Order2.TransactionStatus) => status.transUtcTime,
//   );

export function arrUniq<T>(array: T[], iteratee: (item: T) => any): T[] {
  const seen = new Set();
  const result: T[] = [];

  for (const item of array) {
    const value = iteratee(item);
    if (!seen.has(value)) {
      seen.add(value);
      result.push(item);
    }
  }

  return result;
}

// Usage example:
// const uniqueOrderStatuses = uniq(updates, (orderStatus) => orderStatus.order.account_id);

export function arrOmit<T>(array: T[], predicate: (item: T) => boolean): T[] {
  return array.filter((item) => !predicate(item));
}

// Usage example:
// orders = omit(orders, (order) => {
//   return updateAccounts.indexOf(order.getAccount()) >= 0;
// });

interface Alert {
    title: string;
    body: string;
    dialogType: number;
    extraData: any;
    callback: (() => void) | null;
  }
  
  export class UserAlerts {
    static userAlerts: Alert[] = [];
  
    static addAlert(
      title: string,
      body: string,
      dialogType: number,
      extraData: any,
      cb: (() => void  ) | null = null
    ): void {
      this.userAlerts.push({ title, body, dialogType, extraData, callback: cb });
    }
  
    static removeAlert(): void {
      this.userAlerts = [];
    }
  
    static getAlerts(): Alert[] {
      return this.userAlerts;
    }
  }
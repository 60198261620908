import { GridRow, GridRowProps } from "@mui/x-data-grid";
import { useDrag, useDrop } from "react-dnd";

const ItemType = "ROW";

const DraggableGridRow = ({
  props,
  onRowDrop,
}: {
  props: GridRowProps;
  onRowDrop: (fromIndex: number, toIndex: number) => void;
}) => {
  const { row, index, ...rest } = props;
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [{ isOver }, drop] = useDrop({
    accept: ItemType,
    drop: (item: any) => {
      if (item.index !== index) {
        onRowDrop(item.index, index);
        item.index = index;
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div ref={(node) => ref(drop(node))} style={{ cursor: "move", backgroundColor: isOver ? "#d3d3d3" : "white" }}>
      <GridRow index={index} row={row} {...rest}></GridRow>
    </div>
  );
};

export default DraggableGridRow;

export class TradeSubscriptionParams {
    id: number;
    subscriptionScope: number[] = [];
    publicationType?: number | undefined;
    accountId: number ;
    skipOrdersSnapshot: boolean | undefined;
  
    constructor(id: number, accountId: number) {
      this.id = id;
      this.accountId = accountId;
    }
  }
import axios, { AxiosError } from "axios";
import { useCallback, useState } from "react";
import Notification from "../../components/shared/notification/Notification";
import { TradeLog } from "./types";
import { useGetCurrentUser } from "./authentication";

export const useTradeLog = () => {
  const [tradelog, setTradeLog] = useState<TradeLog[]>([]);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const currentUser = useGetCurrentUser();

  const loadTradeLogData = useCallback(async () => {
    try {
      const response = await axios.get(`tradelog/simulator/${currentUser.id}`);
      setTradeLog(response.data);
    } catch (error: any) {
      setError(error);
      Notification.error(
        `Could not load trade log data, ${error?.message}`
      );
    } finally {
      setLoaded(true);
    }
  }, [currentUser.id]);

  return { tradelog, error, loaded, loadTradeLogData, setTradeLog };
};
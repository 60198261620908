import React from "react";
import "./App.css";
import { configureStore, getHistory } from "./redux/store";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import RoutesComponent from "./routes/RoutesComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const store = configureStore();

function App(props: any) {
  return (
    <div className="App">
      <Provider store={store}>
        <AppWithRoute {...props} />
      </Provider>
    </div>
  );
}

function AppWithRoute(props: any) {
  return (
    <>
      <ToastContainer
        theme="colored"
        autoClose={5000}
        style={{ fontSize: "15px" }}
      />
      <ConnectedRouter history={getHistory()}>
        <RoutesComponent />
      </ConnectedRouter>
    </>
  );
}

export default App;

import { Deferred } from "../utils/Deferred";
import * as WebAPI from "../proto/webapi_2";
import EventEmitter from "events";
import { InstrumentsManager } from "./InstrumentsManager";
import { Instrument } from "../models/Instrument";
import { numberNullable, stringNullable } from "../types/Types";

export class SymbolResolutionSubscriptions {
  static subscriptions: { [key: string]: SymbolResolutionSubscription } = {}; //symbolresolution subscriptions.       > dictionary<proposedSymbol, SymbolResolutionSubscription>
  static onResolved = new EventEmitter();

  static add = (symbol: string, id: number) => {
    return (this.subscriptions[symbol] = new SymbolResolutionSubscription(symbol, id));
  };

  static processInformationReport = (informationReport: WebAPI.InformationReport[]) => {
    var resolved: string[] = [],
      notFound: string[] = [];
    informationReport.forEach((item: WebAPI.InformationReport) => {
      if (item.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_DISCONNECTED) {
        console.warn("Disconnected status code was received for symbol resolution subscription.");
        return true;
      }
      var subscription = this.processInformationReportItem(item);
      if (subscription) {
        let statusCode = subscription.statusCode;
        if (statusCode && statusCode >= WebAPI.InformationReport_StatusCode.STATUS_CODE_FAILURE) {
          notFound.push(subscription.symbol);
        } else if (
          statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS ||
          statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED
        ) {
          resolved.push(subscription.symbol);
        }
      }
    });

    if (resolved.length > 0 || notFound.length > 0) {
      this.onResolved.emit("SymbolResolutionSubscriptions", { resolved, notFound });
    }
  };

  static processInformationReportItem = (informationReport: WebAPI.InformationReport) => {
    var subscription = this.findById(informationReport.id);
    if (subscription) {
      subscription.update(informationReport);
    }
    return subscription;
  };

  static getBySymbol = (symbol: string) => {
    return this.subscriptions[symbol];
  };

  static findById = (id: number) => {
    var foundSubscription = null;

    for (var key in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(key)) {
        let subscription = this.subscriptions[key];
        if (subscription.id === id) {
          foundSubscription = subscription;
          break;
        }
      }
    }

    return foundSubscription;
  };

  static clear = () => {
    this.subscriptions = {};
  };
}

export class SymbolResolutionSubscription {
  symbol: string;
  id: number;
  statusCode: numberNullable;
  textMessage: stringNullable;
  instrument = InstrumentsManager.createEmptyInstrumentState();
  resolveDeferred = new Deferred<Instrument>();
  resolvePromise = this.resolveDeferred.promise;

  constructor(symbol: string, id: number) {
    this.symbol = symbol;
    this.id = id;
  }

  update = (informationReport: WebAPI.InformationReport) => {
    var statusCode = informationReport.statusCode;
    this.statusCode = statusCode;
    this.textMessage = informationReport.textMessage;
    var contractMetadata =
      informationReport.symbolResolutionReport && informationReport.symbolResolutionReport.contractMetadata;

    if (contractMetadata) {
      this.instrument = InstrumentsManager.addOrUpdate(contractMetadata);
    }

    if (statusCode >= WebAPI.InformationReport_StatusCode.STATUS_CODE_FAILURE) {
      console.warn("Symbol resolution failed for symbol: ", { symbol: this.symbol, reason: this.textMessage });
      // this.resolveDeferred.reject({ symbol: this.symbol, reason: this.textMessage });
    } else if (
      statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS ||
      statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED
    ) {
      this.resolveDeferred.resolve(this.instrument);
    }
  };
}

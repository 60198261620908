import React, { useCallback, useState } from "react";
import ECharts from "echarts-for-react";
// import { options } from "./option";

const options = {
  xAxis: [
    {
      type: "category",
      data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      axisPointer: {
        type: "shadow",
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "",
      min: 0,
      max: 250,
      interval: 50,
      axisLabel: {
        formatter: "{value} ml",
      },
    },
    {
      type: "value",
      name: "",
      min: 0,
      max: 25,
      interval: 5,
      axisLabel: {
        formatter: "{value} °C",
      },
    },
  ],
  series: [
    {
      name: "",
      type: "bar",
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
      ],
    },
    {
      name: "",
      type: "bar",
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
      ],
    },
  ],
};

export default function BarChartDemo({
  workspaceClassName,
  selectedWorkspace,
  isPopupWindow,
}) {
  const [width, setWidth] = useState();
  const div = useCallback(
    (node) => {
      if (node !== null) {
        setWidth(node.getBoundingClientRect().width);
      }
    },
    // eslint-disable-next-line
    [selectedWorkspace]
  );
  return (
    <div
      ref={div}
      style={{ width: workspaceClassName ? "" : "450px" }}
      className={workspaceClassName}
    >
      <ECharts
        option={options}
        className={workspaceClassName}
        style={{ width: isPopupWindow ? "" : `${width}px` }}
      />
    </div>
  );
}

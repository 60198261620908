import * as UserSession from "../proto/user_session_2";
import * as TradeRouting from "../proto/trade_routing_2";
import * as MarketData from "../proto/market_data_2";

import { CQGEnvironment } from "../services/CQGEnvironment";
import { SimulatorController } from "../controllers/SimulatorController";
import { InstrumentSubscriptions } from "../services/InstrumentSubscriptions";
import { fetchCMEInstruments } from "../usage-examples/SymbolResolution";
import { makeMarketDataSubscription } from "../usage-examples/MarketDataSubscription";

export class ServiceReadyWatcher {
  cqgEnvironment: CQGEnvironment;

  constructor(cqgEnvironment: CQGEnvironment) {
    this.cqgEnvironment = cqgEnvironment;
    this.cqgEnvironment.serviceMessageEventManager.onServiceReady((logonResult: UserSession.LogonResult) => {
      if (logonResult.resultCode === UserSession.LogonResult_ResultCode.RESULT_CODE_SUCCESS) {
        // TODO: Ignore this code for now
        this.sendTradeSubscriptions();
        // this.cqgEnvironment.serviceMessageEventManager.onMarketDataSubscriptionStatuses(
        //   (marketDataSubscriptionStatuses: MarketData.MarketDataSubscriptionStatus[]) => {
        //     InstrumentSubscriptions.Instance.processMarketDataSubscriptionStatus(marketDataSubscriptionStatuses);
        //   },
        // );

        // SimulatorController.lodInstruments();

        // fetchCMEInstruments();
        // makeMarketDataSubscription();
      }
    });
  }

  sendTradeSubscriptions = () => {
    let accountId = CQGEnvironment.cqgAccountAuthInfo?.accountId as number;

    this.cqgEnvironment.tradeSubscriptionsManager.subscribe({
      id: this.cqgEnvironment.serviceMessageManager.nextRequestId(),
      accountId: accountId,
      publicationType: TradeRouting.TradeSubscription_PublicationType.PUBLICATION_TYPE_ACCOUNTS,
      skipOrdersSnapshot: false,
      subscriptionScope: [TradeRouting.TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_COLLATERAL],
    });

    this.cqgEnvironment.tradeSubscriptionsManager.subscribe({
      id: this.cqgEnvironment.serviceMessageManager.nextRequestId(),
      accountId: accountId,
      publicationType: TradeRouting.TradeSubscription_PublicationType.PUBLICATION_TYPE_ACCOUNTS,
      skipOrdersSnapshot: false,
      subscriptionScope: [TradeRouting.TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ACCOUNT_SUMMARY],
    });
  };
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: grid;
  height: 100vh;
  width: 100vw;
  background: #f2f2f2;
  grid-template-rows: 56px 1fr;
}
main {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/home/components/Layout/layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,4BAA4B;AAC9B;AACA;EACE,aAAa;AACf","sourcesContent":[".layout {\n  display: grid;\n  height: 100vh;\n  width: 100vw;\n  background: #f2f2f2;\n  grid-template-rows: 56px 1fr;\n}\nmain {\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

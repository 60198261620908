import actions from './watchlistActions';
import {produce} from "immer";
const initialData = {
  loadingInit: true,
  loading: false,
  watchlist: [],
  removeProduct: null,
  addProduct: null,
};

export const watchlist = (state = initialData, { type, payload }) => {
  if (type === actions.WATCHLIST_GET_SUCCESS) {
    return state.watchlist;
  }
  if (type === actions.WATCHLIST_SET_SUCCESS) {
    const watchlistLength = state.watchlist.length;
    if (watchlistLength === 0) {
      return produce(state, draft => {
        draft.watchlist = [payload];
        draft.loading = false;
      });
    }
    const watchlistIndex = state.watchlist.findIndex((watchlist) => watchlist.id === payload.id);
    if (watchlistIndex === -1) {
      return produce(state, draft => {
          draft.watchlist.push(payload);
          draft.loading = false;
        });
      }
      if (watchlistIndex > -1) {
        return produce(state, draft => {
          draft.watchlist[watchlistIndex] = payload;
          draft.loading = false;
        });
    }
  }
  if (type === actions.WATCHLIST_REMOVE) {
    return produce(state, draft => {
      draft.removeProduct = payload;
      draft.addProduct = null;
      draft.loading = true;
    })
  }
  if (type === actions.WATCHLIST_ADD) {
    return produce(state, draft => {
      draft.addProduct = payload;
      draft.removeProduct = null;
      draft.loading = true;
    })
  }
  if (type === actions.WATCHLIST_CLEAR_SUCCESS) {
    return produce(state, draft => {
      draft.watchlist = [];
      draft.removeProduct = null;
      draft.addProduct = null;
      draft.loading = false;
    })
  }

  return state;
};

import { CMESymbolService } from "./CMESymbolService";
import { InstrumentLoadService } from "./InstrumentLoadService";
import { TimeUtil } from "../utils/TimeUtil";
import { CMEInstrument } from "../models/CMEInstrument";
import { Instrument } from "../models/Instrument";

// TODO: This is borrowwed class from a controller.
// It needs to be moved to the correct location.

export class SymbolResolver {
  private static instruments: Instrument[] = [];
  private static currentAssetClass = null;
  private static currentInstrument: Instrument | null = null;
  private static contractDescription = null;
  private static showOptions = null;
  private static symbolsResolved = false;

  static resolveSymbols = (cmeSymbol: string) => {
    return new Promise<Instrument[]>((resolve, reject) => {
      CMESymbolService.getInstrumentFromCmeSymbol(cmeSymbol).then((instrument: CMEInstrument) => {
        InstrumentLoadService.loadMonthsFromCqgRoot(instrument.cqg_symbol, instrument.quoteboard_months_to_fetch)
          .then((instruments) => {
            resolve(instruments);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  };

  static resolveCQGSymbols = (cqgSymbol: string) => {
    return new Promise<Instrument[]>((resolve, reject) => {
      InstrumentLoadService.loadMonthsFromCqgRoot(cqgSymbol, 1)
        .then((instruments: Instrument[]) => {
          resolve(instruments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static isInstrumentActive = (instrument: Instrument) => {
    return TimeUtil.toUtcDate(instrument.last_trading_date as number) >= new Date();
  };
}

import React from 'react';
import LoadingComponent from './LoadingComponent';

export default function CustomLoadable(opts: any) {
  const LazyComponent = React.lazy(opts.loader);

  return (props: any) => (
    <React.Suspense fallback={<LoadingComponent />}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
}

import { CQGEnvironment } from "../services/CQGEnvironment";
import { OrdersManager } from "../services/OrdersManager";

export class AccountsLoadedWatcher {
  cqgEnvironment: CQGEnvironment;

  constructor(cqgEnvironment: CQGEnvironment) {
    this.cqgEnvironment = cqgEnvironment;
    cqgEnvironment.accountsManager?.getAccountsLoadedPromise().then((accounts) => {
      let nextReqId = this.cqgEnvironment.serviceMessageManager.nextRequestId();
      OrdersManager.subscribeToAccountOrders(CQGEnvironment.cqgAccountAuthInfo?.accountId!, nextReqId);

      nextReqId = this.cqgEnvironment.serviceMessageManager.nextRequestId();
      cqgEnvironment.positionsManager.subscribe(nextReqId, CQGEnvironment.cqgAccountAuthInfo?.accountId!);
    });
  }
}

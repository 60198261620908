import { Account, AccountType, User } from "../types";

export const getStreamingAccount = (currentUser: User) => {
  // Ensure `currentUser.accounts` is always an array
  const accounts = Array.isArray(currentUser?.accounts) ? currentUser.accounts : [];

  if (accounts.length === 0) return null;

  // Return the single account if there's only one
  if (accounts.length === 1) return accounts[0];

  // Find the simulator account
  const simulatorAccount = accounts.find((account: Account) => account?.accountType === AccountType.Simulator);
  if (!simulatorAccount) console.error("No simulator account found, streaming may not work");
  return simulatorAccount;
};

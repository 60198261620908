const prefix = 'LAYOUT';

const layoutActions = {
  UPDATE_LAYOUT: `${prefix}_UPDATE_LAYOUT`,

  doUpdateLayout: (payload: any) => {
    return {
      type: layoutActions.UPDATE_LAYOUT,
      payload: payload
    };
  },
};

export default layoutActions;

import CloseIcon from "@mui/icons-material/Close";
import PlusIcon from "../../../../common/icons/PlusIcon";
import WidgetSelectionDialog from "../../../../widget-selection/WidgetSelectionDialog";
import { Workspace, Widget, WidgetType, Watchlist } from "../../../../../types";
import "../Dashboard.css";
import { useCreateWorkspace } from "../../../../../hooks/api/workspace";
import { useCreateWatchlist } from "../../../../../hooks/api/watchlist";

type AddNewWorkspaceProps = {
  onNewWorkspaceCreated: (workspace: Workspace, workspaceWidgets: Widget[]) => void;
  newWorkspace: boolean;
  setNewWorkspace: React.Dispatch<React.SetStateAction<boolean>>;
  showWidgetSelectionDialog: boolean;
  setShowWidgetSelectionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onNewWorkspaceRequested: () => void;
};

const AddNewWorkspace = ({
  onNewWorkspaceCreated,
  newWorkspace,
  setNewWorkspace,
  showWidgetSelectionDialog,
  setShowWidgetSelectionDialog,
  onNewWorkspaceRequested
}: AddNewWorkspaceProps) => {
  const { createWorkspaceAsync } = useCreateWorkspace();
  const { createWatchlistAsync } = useCreateWatchlist();

  const toggleNewWorkspace = () => {
    onNewWorkspaceRequested();
    setNewWorkspace(!newWorkspace);
  };

  const onCreateWorkspace = (workspaceWidgets: Widget[], title: any) => {
    workspaceWidgets = JSON.parse(JSON.stringify(workspaceWidgets));
    const workspaceDTO: Workspace = {
      title,
    };
    try {
      // create workspace on server and reterive the id;

      createWorkspaceAsync(workspaceDTO, async (workspace: Workspace) => {
        workspaceWidgets.forEach((widget: Widget) => {
          widget.workspaceId = workspace.id;
        });

        // check if item chosen is watchlist also, create a watchlist on the server in that case and assign id to widget.
        const watchlist = workspaceWidgets.find((widget) => widget.WidgetType === WidgetType.Watchlist);
        if (watchlist && workspace.id) {
          createWatchlistAsync(workspace.id, (response: Watchlist) => {
            watchlist.widgetId = response.id;
            setShowWidgetSelectionDialog(false);
            setNewWorkspace(false);
            onNewWorkspaceCreated(workspace, workspaceWidgets);
          });
        } else {
          setShowWidgetSelectionDialog(false);
          setNewWorkspace(false);
          onNewWorkspaceCreated(workspace, workspaceWidgets);
        }
      });
    } catch (error) {
      console.log("error while creating workspace", error);
    }
  };

  return (
    <>
      <div className={`Tab newWorkspace ${newWorkspace ? "active" : ""}`}>
        <button onClick={toggleNewWorkspace}>New Workspace</button>
        <button onClick={toggleNewWorkspace}>
          {newWorkspace ? <CloseIcon style={{ height: "16px", width: "16px" }} /> : <PlusIcon />}
        </button>
      </div>

      {showWidgetSelectionDialog && (
        <WidgetSelectionDialog
          title="Select Widgets"
          show={true}
          onOkay={onCreateWorkspace}
          onCancel={() => setShowWidgetSelectionDialog(false)}
          isShowTitle={true}
        />
      )}
    </>
  );
};

export default AddNewWorkspace;

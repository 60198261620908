// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: common/shared_1.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "shared_1";

/** Entities shared between different protocols. */

/** Text with a unique key. Possible text messages are listed in a separate document. */
export interface Text {
  /** Message unique key. */
  key?:
    | string
    | undefined;
  /** Message text. */
  text?:
    | string
    | undefined;
  /**
   * Optional message format string which is sent only if a message has parameters.
   * Format is defined as a sample for localized clients to replace it with a translated version
   * and compose a message using variable parameters.
   * Format string sample: "The request is rejected because %1% limit of %2% per %3% seconds is exceeded"
   */
  format?:
    | string
    | undefined;
  /**
   * Optional list of format string parameters ordered according to the format string placeholders.
   * E.g. parameters for the sample format string above could be: ["request rate", "1000", "10"].
   * Note: float numbers are always defined in decimal format with a dot separator like "23434.34"
   * and date and time in extended ISO 8601 format as "2016-11-09 15:11:20.323" (time part is optional for
   * dates, date part is optional for time only, seconds in time part also may not have fractional part).
   * Client can convert these values to different formats if they need to.
   */
  param: string[];
}

/** Message for holding OrderStatus enum values as a name space. */
export interface OrderStatus {
}

/** Status of the order. */
export enum OrderStatus_Status {
  /** IN_TRANSIT - Original order is sent to execution system. */
  IN_TRANSIT = 1,
  /** REJECTED - Order is rejected. */
  REJECTED = 2,
  /** WORKING - Order is acknowledged by execution system and perhaps partially filled. */
  WORKING = 3,
  /** EXPIRED - Order is expired. */
  EXPIRED = 4,
  /** IN_CANCEL - Cancel request is sent to execution system. */
  IN_CANCEL = 5,
  /** IN_MODIFY - Modify request is sent to execution system. */
  IN_MODIFY = 6,
  /** CANCELLED - Order is canceled. */
  CANCELLED = 7,
  /** FILLED - Order is completely filled by execution system. */
  FILLED = 8,
  /** SUSPENDED - Order is waiting submission to execution system. */
  SUSPENDED = 9,
  /** DISCONNECTED - Order may be canceled because a disconnect occurred. */
  DISCONNECTED = 10,
  /** ACTIVEAT - Order will be placed at a specified time (waiting execution system to start accepting orders). */
  ACTIVEAT = 11,
  /** APPROVE_REQUIRED - Cross order is sent to exchange and waiting for approval from exchange and/or counter-parties. */
  APPROVE_REQUIRED = 12,
  /**
   * APPROVED_BY_EXCHANGE - The exchange approved the cross order.
   * Note that this approve relates only to the sides/participants specified in the order.
   * Thus, it is not implied the exchange approved the other sides of the order (counter-parties).
   */
  APPROVED_BY_EXCHANGE = 13,
  /**
   * APPROVE_REJECTED - Cross order is rejected by one of its participants.
   * Note that if a participant rejects its own side of the cross order, the counter-parties may not receive
   * any reject messages if exchange does not support this notification.
   */
  APPROVE_REJECTED = 14,
  /**
   * MATCHED - Cross order is completely executed on exchange, this is the final state of the cross order unless any of its
   * trades/legs is subsequently broken by the exchange.
   */
  MATCHED = 15,
  /**
   * PARTIALLY_MATCHED - Cross order/leg is executed on exchange, this is transit state until all transactions for trades/breaks are
   * received.
   */
  PARTIALLY_MATCHED = 16,
  /** TRADE_BROKEN - Cross order was executed on exchange, but now all its trades/legs have been broken by the exchange. */
  TRADE_BROKEN = 17,
  UNRECOGNIZED = -1,
}

export function orderStatus_StatusFromJSON(object: any): OrderStatus_Status {
  switch (object) {
    case 1:
    case "IN_TRANSIT":
      return OrderStatus_Status.IN_TRANSIT;
    case 2:
    case "REJECTED":
      return OrderStatus_Status.REJECTED;
    case 3:
    case "WORKING":
      return OrderStatus_Status.WORKING;
    case 4:
    case "EXPIRED":
      return OrderStatus_Status.EXPIRED;
    case 5:
    case "IN_CANCEL":
      return OrderStatus_Status.IN_CANCEL;
    case 6:
    case "IN_MODIFY":
      return OrderStatus_Status.IN_MODIFY;
    case 7:
    case "CANCELLED":
      return OrderStatus_Status.CANCELLED;
    case 8:
    case "FILLED":
      return OrderStatus_Status.FILLED;
    case 9:
    case "SUSPENDED":
      return OrderStatus_Status.SUSPENDED;
    case 10:
    case "DISCONNECTED":
      return OrderStatus_Status.DISCONNECTED;
    case 11:
    case "ACTIVEAT":
      return OrderStatus_Status.ACTIVEAT;
    case 12:
    case "APPROVE_REQUIRED":
      return OrderStatus_Status.APPROVE_REQUIRED;
    case 13:
    case "APPROVED_BY_EXCHANGE":
      return OrderStatus_Status.APPROVED_BY_EXCHANGE;
    case 14:
    case "APPROVE_REJECTED":
      return OrderStatus_Status.APPROVE_REJECTED;
    case 15:
    case "MATCHED":
      return OrderStatus_Status.MATCHED;
    case 16:
    case "PARTIALLY_MATCHED":
      return OrderStatus_Status.PARTIALLY_MATCHED;
    case 17:
    case "TRADE_BROKEN":
      return OrderStatus_Status.TRADE_BROKEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderStatus_Status.UNRECOGNIZED;
  }
}

export function orderStatus_StatusToJSON(object: OrderStatus_Status): string {
  switch (object) {
    case OrderStatus_Status.IN_TRANSIT:
      return "IN_TRANSIT";
    case OrderStatus_Status.REJECTED:
      return "REJECTED";
    case OrderStatus_Status.WORKING:
      return "WORKING";
    case OrderStatus_Status.EXPIRED:
      return "EXPIRED";
    case OrderStatus_Status.IN_CANCEL:
      return "IN_CANCEL";
    case OrderStatus_Status.IN_MODIFY:
      return "IN_MODIFY";
    case OrderStatus_Status.CANCELLED:
      return "CANCELLED";
    case OrderStatus_Status.FILLED:
      return "FILLED";
    case OrderStatus_Status.SUSPENDED:
      return "SUSPENDED";
    case OrderStatus_Status.DISCONNECTED:
      return "DISCONNECTED";
    case OrderStatus_Status.ACTIVEAT:
      return "ACTIVEAT";
    case OrderStatus_Status.APPROVE_REQUIRED:
      return "APPROVE_REQUIRED";
    case OrderStatus_Status.APPROVED_BY_EXCHANGE:
      return "APPROVED_BY_EXCHANGE";
    case OrderStatus_Status.APPROVE_REJECTED:
      return "APPROVE_REJECTED";
    case OrderStatus_Status.MATCHED:
      return "MATCHED";
    case OrderStatus_Status.PARTIALLY_MATCHED:
      return "PARTIALLY_MATCHED";
    case OrderStatus_Status.TRADE_BROKEN:
      return "TRADE_BROKEN";
    case OrderStatus_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Message for holding TransactionStatus enum values as a name space. */
export interface TransactionStatus {
}

/** Status of the transaction. */
export enum TransactionStatus_Status {
  /** IN_TRANSIT - Original order is sent to execution system. */
  IN_TRANSIT = 1,
  /** REJECTED - Original order is rejected (exception). */
  REJECTED = 2,
  /** ACK_PLACE - Order is acknowledged by execution system. */
  ACK_PLACE = 3,
  /** EXPIRED - Order is expired (exception). */
  EXPIRED = 4,
  /** IN_CANCEL - Cancel request is sent to execution system. */
  IN_CANCEL = 5,
  /** ACK_CANCEL - Cancel is acknowledged by execution system. */
  ACK_CANCEL = 6,
  /** REJECT_CANCEL - Cancel request is rejected (exception). */
  REJECT_CANCEL = 7,
  /** IN_MODIFY - Modify request is sent to execution system. */
  IN_MODIFY = 8,
  /** ACK_MODIFY - Modify is acknowledged by execution system. */
  ACK_MODIFY = 9,
  /** REJECT_MODIFY - Modify request is rejected (exception). */
  REJECT_MODIFY = 10,
  /** FILL - Fill event is received from execution system. */
  FILL = 11,
  /** SUSPEND - Order is suspended (parked). */
  SUSPEND = 12,
  /** FILL_CORRECT - Referenced fill is corrected. */
  FILL_CORRECT = 13,
  /** FILL_CANCEL - Referenced fill is canceled (exception). */
  FILL_CANCEL = 14,
  /** FILL_BUST - Referenced fill is busted (exception). */
  FILL_BUST = 15,
  /** ACTIVEAT - Acknowledgment that order will activate at specified time. */
  ACTIVEAT = 16,
  /** DISCONNECT - Order may be canceled because a disconnect occurred (exception). */
  DISCONNECT = 17,
  /** SYNTHETIC_ACTIVATED - Synthetic order was sent to the actual execution system. */
  SYNTHETIC_ACTIVATED = 18,
  /** UPDATE - Order status and/ or attributes are updated. */
  UPDATE = 19,
  /**
   * SYNTHETIC_FAILED - Synthetic strategy execution failed, expect 'text_message'
   * attribute with further details. The client may identify outright orders used
   * when attempting to execute the synthetic strategy by finding orders in its
   * book where OrderStatus.origin_order_id == OrderStatus.order_id.
   */
  SYNTHETIC_FAILED = 20,
  /** SYNTHETIC_OVERFILL - Synthetic strategy order has at least one overfilled leg, 'synthetic_hang' attribute is populated. */
  SYNTHETIC_OVERFILL = 21,
  /** SYNTHETIC_HANG - Hanging quantity update for the synthetic strategy order. */
  SYNTHETIC_HANG = 22,
  /**
   * APPROVING - Cross order is in OrderStatus.APPROVE_REQUIRED and TransactionStatus.APPROVING state when it is
   * acknowledged by exchange but waiting for approval from counter-parties.
   * Note: if exchange does not support this status, APPROVED_BY_EXCHANGE status is sent instead.
   */
  APPROVING = 23,
  /**
   * APPROVE_REQUESTED - Cross order is in OrderStatus.APPROVE_REQUIRED and TransactionStatus.APPROVE_REQUESTED state when
   * it is waiting for approval from the client (i.e. from the side/participant that received this transaction).
   */
  APPROVE_REQUESTED = 24,
  /**
   * APPROVED_BY_EXCHANGE - The exchange approved the cross order.
   * Note that this approve relates only to the sides/participants specified in the order.
   * Thus, it is not implied the exchange approved the other sides of the order (counter-parties).
   */
  APPROVED_BY_EXCHANGE = 25,
  /**
   * REJECTED_BY_USER - Cross order is rejected by one of its participants.
   * Note that if a participant rejects its own side of the cross order, the counter-parties may not receive
   * any reject messages if exchange does not support this notification.
   */
  REJECTED_BY_USER = 26,
  /** MATCHED - Cross order is executed on exchange. */
  MATCHED = 27,
  /** TRADE_BROKEN - Cross order/leg have been broken by the exchange. */
  TRADE_BROKEN = 28,
  /** TRADE_AMENDED - Previously matched cross order/leg have been amended by the exchange. */
  TRADE_AMENDED = 29,
  UNRECOGNIZED = -1,
}

export function transactionStatus_StatusFromJSON(object: any): TransactionStatus_Status {
  switch (object) {
    case 1:
    case "IN_TRANSIT":
      return TransactionStatus_Status.IN_TRANSIT;
    case 2:
    case "REJECTED":
      return TransactionStatus_Status.REJECTED;
    case 3:
    case "ACK_PLACE":
      return TransactionStatus_Status.ACK_PLACE;
    case 4:
    case "EXPIRED":
      return TransactionStatus_Status.EXPIRED;
    case 5:
    case "IN_CANCEL":
      return TransactionStatus_Status.IN_CANCEL;
    case 6:
    case "ACK_CANCEL":
      return TransactionStatus_Status.ACK_CANCEL;
    case 7:
    case "REJECT_CANCEL":
      return TransactionStatus_Status.REJECT_CANCEL;
    case 8:
    case "IN_MODIFY":
      return TransactionStatus_Status.IN_MODIFY;
    case 9:
    case "ACK_MODIFY":
      return TransactionStatus_Status.ACK_MODIFY;
    case 10:
    case "REJECT_MODIFY":
      return TransactionStatus_Status.REJECT_MODIFY;
    case 11:
    case "FILL":
      return TransactionStatus_Status.FILL;
    case 12:
    case "SUSPEND":
      return TransactionStatus_Status.SUSPEND;
    case 13:
    case "FILL_CORRECT":
      return TransactionStatus_Status.FILL_CORRECT;
    case 14:
    case "FILL_CANCEL":
      return TransactionStatus_Status.FILL_CANCEL;
    case 15:
    case "FILL_BUST":
      return TransactionStatus_Status.FILL_BUST;
    case 16:
    case "ACTIVEAT":
      return TransactionStatus_Status.ACTIVEAT;
    case 17:
    case "DISCONNECT":
      return TransactionStatus_Status.DISCONNECT;
    case 18:
    case "SYNTHETIC_ACTIVATED":
      return TransactionStatus_Status.SYNTHETIC_ACTIVATED;
    case 19:
    case "UPDATE":
      return TransactionStatus_Status.UPDATE;
    case 20:
    case "SYNTHETIC_FAILED":
      return TransactionStatus_Status.SYNTHETIC_FAILED;
    case 21:
    case "SYNTHETIC_OVERFILL":
      return TransactionStatus_Status.SYNTHETIC_OVERFILL;
    case 22:
    case "SYNTHETIC_HANG":
      return TransactionStatus_Status.SYNTHETIC_HANG;
    case 23:
    case "APPROVING":
      return TransactionStatus_Status.APPROVING;
    case 24:
    case "APPROVE_REQUESTED":
      return TransactionStatus_Status.APPROVE_REQUESTED;
    case 25:
    case "APPROVED_BY_EXCHANGE":
      return TransactionStatus_Status.APPROVED_BY_EXCHANGE;
    case 26:
    case "REJECTED_BY_USER":
      return TransactionStatus_Status.REJECTED_BY_USER;
    case 27:
    case "MATCHED":
      return TransactionStatus_Status.MATCHED;
    case 28:
    case "TRADE_BROKEN":
      return TransactionStatus_Status.TRADE_BROKEN;
    case 29:
    case "TRADE_AMENDED":
      return TransactionStatus_Status.TRADE_AMENDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TransactionStatus_Status.UNRECOGNIZED;
  }
}

export function transactionStatus_StatusToJSON(object: TransactionStatus_Status): string {
  switch (object) {
    case TransactionStatus_Status.IN_TRANSIT:
      return "IN_TRANSIT";
    case TransactionStatus_Status.REJECTED:
      return "REJECTED";
    case TransactionStatus_Status.ACK_PLACE:
      return "ACK_PLACE";
    case TransactionStatus_Status.EXPIRED:
      return "EXPIRED";
    case TransactionStatus_Status.IN_CANCEL:
      return "IN_CANCEL";
    case TransactionStatus_Status.ACK_CANCEL:
      return "ACK_CANCEL";
    case TransactionStatus_Status.REJECT_CANCEL:
      return "REJECT_CANCEL";
    case TransactionStatus_Status.IN_MODIFY:
      return "IN_MODIFY";
    case TransactionStatus_Status.ACK_MODIFY:
      return "ACK_MODIFY";
    case TransactionStatus_Status.REJECT_MODIFY:
      return "REJECT_MODIFY";
    case TransactionStatus_Status.FILL:
      return "FILL";
    case TransactionStatus_Status.SUSPEND:
      return "SUSPEND";
    case TransactionStatus_Status.FILL_CORRECT:
      return "FILL_CORRECT";
    case TransactionStatus_Status.FILL_CANCEL:
      return "FILL_CANCEL";
    case TransactionStatus_Status.FILL_BUST:
      return "FILL_BUST";
    case TransactionStatus_Status.ACTIVEAT:
      return "ACTIVEAT";
    case TransactionStatus_Status.DISCONNECT:
      return "DISCONNECT";
    case TransactionStatus_Status.SYNTHETIC_ACTIVATED:
      return "SYNTHETIC_ACTIVATED";
    case TransactionStatus_Status.UPDATE:
      return "UPDATE";
    case TransactionStatus_Status.SYNTHETIC_FAILED:
      return "SYNTHETIC_FAILED";
    case TransactionStatus_Status.SYNTHETIC_OVERFILL:
      return "SYNTHETIC_OVERFILL";
    case TransactionStatus_Status.SYNTHETIC_HANG:
      return "SYNTHETIC_HANG";
    case TransactionStatus_Status.APPROVING:
      return "APPROVING";
    case TransactionStatus_Status.APPROVE_REQUESTED:
      return "APPROVE_REQUESTED";
    case TransactionStatus_Status.APPROVED_BY_EXCHANGE:
      return "APPROVED_BY_EXCHANGE";
    case TransactionStatus_Status.REJECTED_BY_USER:
      return "REJECTED_BY_USER";
    case TransactionStatus_Status.MATCHED:
      return "MATCHED";
    case TransactionStatus_Status.TRADE_BROKEN:
      return "TRADE_BROKEN";
    case TransactionStatus_Status.TRADE_AMENDED:
      return "TRADE_AMENDED";
    case TransactionStatus_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Initial message to obtain access. */
export interface SignOnRequest {
  accessKey: string;
}

/** Sign On result. */
export interface SignOnResult {
  /**
   * Operation status.
   * This field is associated with ResultCode enum.
   */
  resultCode: number;
  /** Operation details. */
  details?: Text | undefined;
}

export enum SignOnResult_ResultCode {
  /** SUCCESS - / success codes (0 - 100) */
  SUCCESS = 0,
  /**
   * FAILURE - // failure codes (100+)
   * General failure.
   */
  FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function signOnResult_ResultCodeFromJSON(object: any): SignOnResult_ResultCode {
  switch (object) {
    case 0:
    case "SUCCESS":
      return SignOnResult_ResultCode.SUCCESS;
    case 101:
    case "FAILURE":
      return SignOnResult_ResultCode.FAILURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SignOnResult_ResultCode.UNRECOGNIZED;
  }
}

export function signOnResult_ResultCodeToJSON(object: SignOnResult_ResultCode): string {
  switch (object) {
    case SignOnResult_ResultCode.SUCCESS:
      return "SUCCESS";
    case SignOnResult_ResultCode.FAILURE:
      return "FAILURE";
    case SignOnResult_ResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request for a list of entitlements of the current user. */
export interface EntitlementRequest {
}

/** Report with a list of entitlements of the current user. */
export interface EntitlementReport {
  /** List of entitlements. */
  entitlement: Entitlement[];
}

export interface Entitlement {
  /**
   * This value is associated with EntitlementType enum.
   * Ignore Entitlement entry if entitlement_type is not one of EntitlementType
   */
  entitlementType: number;
  /** The code that depends on the type. */
  entitlementCode: string;
  /** Deleted flag is used in updates when entity is removed. */
  deleted?: boolean | undefined;
}

export enum Entitlement_EntitlementType {
  /**
   * FEATURE - Feature entitlement.
   * The code points to a feature number.
   */
  FEATURE = 8,
  /**
   * NEWS - News entitlement.
   * The code points to the enabled news feed.
   */
  NEWS = 10,
  /**
   * DJNEWS - Dow Jones news entitlement
   * The code points to DJ news feed.
   */
  DJNEWS = 26,
  /** STUDIES - Studies. */
  STUDIES = 36,
  /**
   * MANAGE_SECURITY_METADATA - Manage metadata on a security (prefix) level.
   * The code points to a contributor id.
   */
  MANAGE_SECURITY_METADATA = 49,
  /**
   * MANAGE_CONTRACT_METADATA - Manage metadata on a contract level.
   * The code points to a contributor id.
   */
  MANAGE_CONTRACT_METADATA = 50,
  /**
   * OTC_MANAGE_TAILS - Manage OTC state.
   * The code points to a group of OTC services.
   */
  OTC_MANAGE_TAILS = 51,
  UNRECOGNIZED = -1,
}

export function entitlement_EntitlementTypeFromJSON(object: any): Entitlement_EntitlementType {
  switch (object) {
    case 8:
    case "FEATURE":
      return Entitlement_EntitlementType.FEATURE;
    case 10:
    case "NEWS":
      return Entitlement_EntitlementType.NEWS;
    case 26:
    case "DJNEWS":
      return Entitlement_EntitlementType.DJNEWS;
    case 36:
    case "STUDIES":
      return Entitlement_EntitlementType.STUDIES;
    case 49:
    case "MANAGE_SECURITY_METADATA":
      return Entitlement_EntitlementType.MANAGE_SECURITY_METADATA;
    case 50:
    case "MANAGE_CONTRACT_METADATA":
      return Entitlement_EntitlementType.MANAGE_CONTRACT_METADATA;
    case 51:
    case "OTC_MANAGE_TAILS":
      return Entitlement_EntitlementType.OTC_MANAGE_TAILS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Entitlement_EntitlementType.UNRECOGNIZED;
  }
}

export function entitlement_EntitlementTypeToJSON(object: Entitlement_EntitlementType): string {
  switch (object) {
    case Entitlement_EntitlementType.FEATURE:
      return "FEATURE";
    case Entitlement_EntitlementType.NEWS:
      return "NEWS";
    case Entitlement_EntitlementType.DJNEWS:
      return "DJNEWS";
    case Entitlement_EntitlementType.STUDIES:
      return "STUDIES";
    case Entitlement_EntitlementType.MANAGE_SECURITY_METADATA:
      return "MANAGE_SECURITY_METADATA";
    case Entitlement_EntitlementType.MANAGE_CONTRACT_METADATA:
      return "MANAGE_CONTRACT_METADATA";
    case Entitlement_EntitlementType.OTC_MANAGE_TAILS:
      return "OTC_MANAGE_TAILS";
    case Entitlement_EntitlementType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Destination specification.
 * Only one type of destination can be specified.
 */
export interface Destination {
  /** Optional destination description. */
  description?:
    | string
    | undefined;
  /** APN destination (for iOS devices). */
  applePushNotif?:
    | ApplePushNotif
    | undefined;
  /** GCM destination (for Android devices). */
  googlePushNotif?:
    | GooglePushNotif
    | undefined;
  /** Email destination. */
  emailNotif?:
    | EmailNotif
    | undefined;
  /** Notification to an email specified in trader's profile. */
  profileEmailNotif?:
    | EmailFromProfileNotif
    | undefined;
  /** SMS. */
  smsNotif?:
    | SmsNotif
    | undefined;
  /** Sms notification to a phone number specified in trader's profile. */
  profileSmsNotif?: SmsToPhoneFromProfileNotif | undefined;
}

/** Push notification for iOS devices. */
export interface ApplePushNotif {
  /** iOS device token */
  deviceToken: string;
  /** APN application ID */
  appId: string;
}

/** Push notification for Android devices. */
export interface GooglePushNotif {
  /** registration token for Android device and app */
  registrationToken: string;
  /** GCN sender id */
  senderId: string;
}

/** Notification property (e.g. order related information for order event rule notifications). */
export interface NotificationProperty {
  /** Property name. */
  propertyName: string;
  /** Property value. */
  propertyValue: string;
}

/** Email notification. */
export interface EmailNotif {
  /** List of recipients to send email to. At least one entry must be present. */
  recipients: string[];
}

/**
 * Notification to an email specified in trader's profile
 * (to the first email in case there are multiple in the profile).
 */
export interface EmailFromProfileNotif {
}

/** Sms notification. */
export interface SmsNotif {
  /** Destination phone number. */
  phoneNumber: string;
}

/**
 * Sms notification to a phone number specified in trader's profile
 * (to the first phone number in case there are multiple in the profile).
 */
export interface SmsToPhoneFromProfileNotif {
}

/** Named value that can be used for custom parameters or properties. */
export interface NamedValue {
  /** Attribute name. */
  name: string;
  /** Attribute value. */
  value?:
    | string
    | undefined;
  /** An optional "deleted" flag for update operations. */
  deleted?: boolean | undefined;
}

function createBaseText(): Text {
  return { key: "", text: "", format: "", param: [] };
}

export const Text = {
  encode(message: Text, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined && message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.text !== undefined && message.text !== "") {
      writer.uint32(18).string(message.text);
    }
    if (message.format !== undefined && message.format !== "") {
      writer.uint32(26).string(message.format);
    }
    for (const v of message.param) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Text {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseText();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.format = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.param.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Text {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      format: isSet(object.format) ? globalThis.String(object.format) : "",
      param: globalThis.Array.isArray(object?.param) ? object.param.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Text): unknown {
    const obj: any = {};
    if (message.key !== undefined && message.key !== "") {
      obj.key = message.key;
    }
    if (message.text !== undefined && message.text !== "") {
      obj.text = message.text;
    }
    if (message.format !== undefined && message.format !== "") {
      obj.format = message.format;
    }
    if (message.param?.length) {
      obj.param = message.param;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Text>, I>>(base?: I): Text {
    return Text.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Text>, I>>(object: I): Text {
    const message = createBaseText();
    message.key = object.key ?? "";
    message.text = object.text ?? "";
    message.format = object.format ?? "";
    message.param = object.param?.map((e) => e) || [];
    return message;
  },
};

function createBaseOrderStatus(): OrderStatus {
  return {};
}

export const OrderStatus = {
  encode(_: OrderStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): OrderStatus {
    return {};
  },

  toJSON(_: OrderStatus): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderStatus>, I>>(base?: I): OrderStatus {
    return OrderStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderStatus>, I>>(_: I): OrderStatus {
    const message = createBaseOrderStatus();
    return message;
  },
};

function createBaseTransactionStatus(): TransactionStatus {
  return {};
}

export const TransactionStatus = {
  encode(_: TransactionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TransactionStatus {
    return {};
  },

  toJSON(_: TransactionStatus): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TransactionStatus>, I>>(base?: I): TransactionStatus {
    return TransactionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionStatus>, I>>(_: I): TransactionStatus {
    const message = createBaseTransactionStatus();
    return message;
  },
};

function createBaseSignOnRequest(): SignOnRequest {
  return { accessKey: "" };
}

export const SignOnRequest = {
  encode(message: SignOnRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessKey !== "") {
      writer.uint32(10).string(message.accessKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignOnRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignOnRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accessKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignOnRequest {
    return { accessKey: isSet(object.accessKey) ? globalThis.String(object.accessKey) : "" };
  },

  toJSON(message: SignOnRequest): unknown {
    const obj: any = {};
    if (message.accessKey !== "") {
      obj.accessKey = message.accessKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignOnRequest>, I>>(base?: I): SignOnRequest {
    return SignOnRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignOnRequest>, I>>(object: I): SignOnRequest {
    const message = createBaseSignOnRequest();
    message.accessKey = object.accessKey ?? "";
    return message;
  },
};

function createBaseSignOnResult(): SignOnResult {
  return { resultCode: 0, details: undefined };
}

export const SignOnResult = {
  encode(message: SignOnResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCode !== 0) {
      writer.uint32(8).uint32(message.resultCode);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignOnResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignOnResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.details = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignOnResult {
    return {
      resultCode: isSet(object.resultCode) ? globalThis.Number(object.resultCode) : 0,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: SignOnResult): unknown {
    const obj: any = {};
    if (message.resultCode !== 0) {
      obj.resultCode = Math.round(message.resultCode);
    }
    if (message.details !== undefined) {
      obj.details = Text.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignOnResult>, I>>(base?: I): SignOnResult {
    return SignOnResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignOnResult>, I>>(object: I): SignOnResult {
    const message = createBaseSignOnResult();
    message.resultCode = object.resultCode ?? 0;
    message.details = (object.details !== undefined && object.details !== null)
      ? Text.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseEntitlementRequest(): EntitlementRequest {
  return {};
}

export const EntitlementRequest = {
  encode(_: EntitlementRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EntitlementRequest {
    return {};
  },

  toJSON(_: EntitlementRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementRequest>, I>>(base?: I): EntitlementRequest {
    return EntitlementRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementRequest>, I>>(_: I): EntitlementRequest {
    const message = createBaseEntitlementRequest();
    return message;
  },
};

function createBaseEntitlementReport(): EntitlementReport {
  return { entitlement: [] };
}

export const EntitlementReport = {
  encode(message: EntitlementReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entitlement) {
      Entitlement.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entitlement.push(Entitlement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementReport {
    return {
      entitlement: globalThis.Array.isArray(object?.entitlement)
        ? object.entitlement.map((e: any) => Entitlement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EntitlementReport): unknown {
    const obj: any = {};
    if (message.entitlement?.length) {
      obj.entitlement = message.entitlement.map((e) => Entitlement.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementReport>, I>>(base?: I): EntitlementReport {
    return EntitlementReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementReport>, I>>(object: I): EntitlementReport {
    const message = createBaseEntitlementReport();
    message.entitlement = object.entitlement?.map((e) => Entitlement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEntitlement(): Entitlement {
  return { entitlementType: 0, entitlementCode: "", deleted: false };
}

export const Entitlement = {
  encode(message: Entitlement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entitlementType !== 0) {
      writer.uint32(8).uint32(message.entitlementType);
    }
    if (message.entitlementCode !== "") {
      writer.uint32(18).string(message.entitlementCode);
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Entitlement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.entitlementType = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entitlementCode = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Entitlement {
    return {
      entitlementType: isSet(object.entitlementType) ? globalThis.Number(object.entitlementType) : 0,
      entitlementCode: isSet(object.entitlementCode) ? globalThis.String(object.entitlementCode) : "",
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
    };
  },

  toJSON(message: Entitlement): unknown {
    const obj: any = {};
    if (message.entitlementType !== 0) {
      obj.entitlementType = Math.round(message.entitlementType);
    }
    if (message.entitlementCode !== "") {
      obj.entitlementCode = message.entitlementCode;
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Entitlement>, I>>(base?: I): Entitlement {
    return Entitlement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Entitlement>, I>>(object: I): Entitlement {
    const message = createBaseEntitlement();
    message.entitlementType = object.entitlementType ?? 0;
    message.entitlementCode = object.entitlementCode ?? "";
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseDestination(): Destination {
  return {
    description: "",
    applePushNotif: undefined,
    googlePushNotif: undefined,
    emailNotif: undefined,
    profileEmailNotif: undefined,
    smsNotif: undefined,
    profileSmsNotif: undefined,
  };
}

export const Destination = {
  encode(message: Destination, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.applePushNotif !== undefined) {
      ApplePushNotif.encode(message.applePushNotif, writer.uint32(18).fork()).ldelim();
    }
    if (message.googlePushNotif !== undefined) {
      GooglePushNotif.encode(message.googlePushNotif, writer.uint32(26).fork()).ldelim();
    }
    if (message.emailNotif !== undefined) {
      EmailNotif.encode(message.emailNotif, writer.uint32(34).fork()).ldelim();
    }
    if (message.profileEmailNotif !== undefined) {
      EmailFromProfileNotif.encode(message.profileEmailNotif, writer.uint32(50).fork()).ldelim();
    }
    if (message.smsNotif !== undefined) {
      SmsNotif.encode(message.smsNotif, writer.uint32(42).fork()).ldelim();
    }
    if (message.profileSmsNotif !== undefined) {
      SmsToPhoneFromProfileNotif.encode(message.profileSmsNotif, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Destination {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestination();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applePushNotif = ApplePushNotif.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.googlePushNotif = GooglePushNotif.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.emailNotif = EmailNotif.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.profileEmailNotif = EmailFromProfileNotif.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.smsNotif = SmsNotif.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.profileSmsNotif = SmsToPhoneFromProfileNotif.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Destination {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      applePushNotif: isSet(object.applePushNotif) ? ApplePushNotif.fromJSON(object.applePushNotif) : undefined,
      googlePushNotif: isSet(object.googlePushNotif) ? GooglePushNotif.fromJSON(object.googlePushNotif) : undefined,
      emailNotif: isSet(object.emailNotif) ? EmailNotif.fromJSON(object.emailNotif) : undefined,
      profileEmailNotif: isSet(object.profileEmailNotif)
        ? EmailFromProfileNotif.fromJSON(object.profileEmailNotif)
        : undefined,
      smsNotif: isSet(object.smsNotif) ? SmsNotif.fromJSON(object.smsNotif) : undefined,
      profileSmsNotif: isSet(object.profileSmsNotif)
        ? SmsToPhoneFromProfileNotif.fromJSON(object.profileSmsNotif)
        : undefined,
    };
  },

  toJSON(message: Destination): unknown {
    const obj: any = {};
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.applePushNotif !== undefined) {
      obj.applePushNotif = ApplePushNotif.toJSON(message.applePushNotif);
    }
    if (message.googlePushNotif !== undefined) {
      obj.googlePushNotif = GooglePushNotif.toJSON(message.googlePushNotif);
    }
    if (message.emailNotif !== undefined) {
      obj.emailNotif = EmailNotif.toJSON(message.emailNotif);
    }
    if (message.profileEmailNotif !== undefined) {
      obj.profileEmailNotif = EmailFromProfileNotif.toJSON(message.profileEmailNotif);
    }
    if (message.smsNotif !== undefined) {
      obj.smsNotif = SmsNotif.toJSON(message.smsNotif);
    }
    if (message.profileSmsNotif !== undefined) {
      obj.profileSmsNotif = SmsToPhoneFromProfileNotif.toJSON(message.profileSmsNotif);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Destination>, I>>(base?: I): Destination {
    return Destination.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Destination>, I>>(object: I): Destination {
    const message = createBaseDestination();
    message.description = object.description ?? "";
    message.applePushNotif = (object.applePushNotif !== undefined && object.applePushNotif !== null)
      ? ApplePushNotif.fromPartial(object.applePushNotif)
      : undefined;
    message.googlePushNotif = (object.googlePushNotif !== undefined && object.googlePushNotif !== null)
      ? GooglePushNotif.fromPartial(object.googlePushNotif)
      : undefined;
    message.emailNotif = (object.emailNotif !== undefined && object.emailNotif !== null)
      ? EmailNotif.fromPartial(object.emailNotif)
      : undefined;
    message.profileEmailNotif = (object.profileEmailNotif !== undefined && object.profileEmailNotif !== null)
      ? EmailFromProfileNotif.fromPartial(object.profileEmailNotif)
      : undefined;
    message.smsNotif = (object.smsNotif !== undefined && object.smsNotif !== null)
      ? SmsNotif.fromPartial(object.smsNotif)
      : undefined;
    message.profileSmsNotif = (object.profileSmsNotif !== undefined && object.profileSmsNotif !== null)
      ? SmsToPhoneFromProfileNotif.fromPartial(object.profileSmsNotif)
      : undefined;
    return message;
  },
};

function createBaseApplePushNotif(): ApplePushNotif {
  return { deviceToken: "", appId: "" };
}

export const ApplePushNotif = {
  encode(message: ApplePushNotif, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceToken !== "") {
      writer.uint32(10).string(message.deviceToken);
    }
    if (message.appId !== "") {
      writer.uint32(18).string(message.appId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplePushNotif {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplePushNotif();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.appId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplePushNotif {
    return {
      deviceToken: isSet(object.deviceToken) ? globalThis.String(object.deviceToken) : "",
      appId: isSet(object.appId) ? globalThis.String(object.appId) : "",
    };
  },

  toJSON(message: ApplePushNotif): unknown {
    const obj: any = {};
    if (message.deviceToken !== "") {
      obj.deviceToken = message.deviceToken;
    }
    if (message.appId !== "") {
      obj.appId = message.appId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplePushNotif>, I>>(base?: I): ApplePushNotif {
    return ApplePushNotif.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplePushNotif>, I>>(object: I): ApplePushNotif {
    const message = createBaseApplePushNotif();
    message.deviceToken = object.deviceToken ?? "";
    message.appId = object.appId ?? "";
    return message;
  },
};

function createBaseGooglePushNotif(): GooglePushNotif {
  return { registrationToken: "", senderId: "" };
}

export const GooglePushNotif = {
  encode(message: GooglePushNotif, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.registrationToken !== "") {
      writer.uint32(10).string(message.registrationToken);
    }
    if (message.senderId !== "") {
      writer.uint32(18).string(message.senderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GooglePushNotif {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGooglePushNotif();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.registrationToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.senderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GooglePushNotif {
    return {
      registrationToken: isSet(object.registrationToken) ? globalThis.String(object.registrationToken) : "",
      senderId: isSet(object.senderId) ? globalThis.String(object.senderId) : "",
    };
  },

  toJSON(message: GooglePushNotif): unknown {
    const obj: any = {};
    if (message.registrationToken !== "") {
      obj.registrationToken = message.registrationToken;
    }
    if (message.senderId !== "") {
      obj.senderId = message.senderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GooglePushNotif>, I>>(base?: I): GooglePushNotif {
    return GooglePushNotif.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GooglePushNotif>, I>>(object: I): GooglePushNotif {
    const message = createBaseGooglePushNotif();
    message.registrationToken = object.registrationToken ?? "";
    message.senderId = object.senderId ?? "";
    return message;
  },
};

function createBaseNotificationProperty(): NotificationProperty {
  return { propertyName: "", propertyValue: "" };
}

export const NotificationProperty = {
  encode(message: NotificationProperty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.propertyName !== "") {
      writer.uint32(10).string(message.propertyName);
    }
    if (message.propertyValue !== "") {
      writer.uint32(18).string(message.propertyValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationProperty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationProperty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.propertyName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.propertyValue = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationProperty {
    return {
      propertyName: isSet(object.propertyName) ? globalThis.String(object.propertyName) : "",
      propertyValue: isSet(object.propertyValue) ? globalThis.String(object.propertyValue) : "",
    };
  },

  toJSON(message: NotificationProperty): unknown {
    const obj: any = {};
    if (message.propertyName !== "") {
      obj.propertyName = message.propertyName;
    }
    if (message.propertyValue !== "") {
      obj.propertyValue = message.propertyValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationProperty>, I>>(base?: I): NotificationProperty {
    return NotificationProperty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotificationProperty>, I>>(object: I): NotificationProperty {
    const message = createBaseNotificationProperty();
    message.propertyName = object.propertyName ?? "";
    message.propertyValue = object.propertyValue ?? "";
    return message;
  },
};

function createBaseEmailNotif(): EmailNotif {
  return { recipients: [] };
}

export const EmailNotif = {
  encode(message: EmailNotif, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.recipients) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailNotif {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailNotif();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipients.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmailNotif {
    return {
      recipients: globalThis.Array.isArray(object?.recipients)
        ? object.recipients.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: EmailNotif): unknown {
    const obj: any = {};
    if (message.recipients?.length) {
      obj.recipients = message.recipients;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmailNotif>, I>>(base?: I): EmailNotif {
    return EmailNotif.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmailNotif>, I>>(object: I): EmailNotif {
    const message = createBaseEmailNotif();
    message.recipients = object.recipients?.map((e) => e) || [];
    return message;
  },
};

function createBaseEmailFromProfileNotif(): EmailFromProfileNotif {
  return {};
}

export const EmailFromProfileNotif = {
  encode(_: EmailFromProfileNotif, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailFromProfileNotif {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailFromProfileNotif();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EmailFromProfileNotif {
    return {};
  },

  toJSON(_: EmailFromProfileNotif): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EmailFromProfileNotif>, I>>(base?: I): EmailFromProfileNotif {
    return EmailFromProfileNotif.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmailFromProfileNotif>, I>>(_: I): EmailFromProfileNotif {
    const message = createBaseEmailFromProfileNotif();
    return message;
  },
};

function createBaseSmsNotif(): SmsNotif {
  return { phoneNumber: "" };
}

export const SmsNotif = {
  encode(message: SmsNotif, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phoneNumber !== "") {
      writer.uint32(10).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmsNotif {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmsNotif();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmsNotif {
    return { phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : "" };
  },

  toJSON(message: SmsNotif): unknown {
    const obj: any = {};
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmsNotif>, I>>(base?: I): SmsNotif {
    return SmsNotif.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmsNotif>, I>>(object: I): SmsNotif {
    const message = createBaseSmsNotif();
    message.phoneNumber = object.phoneNumber ?? "";
    return message;
  },
};

function createBaseSmsToPhoneFromProfileNotif(): SmsToPhoneFromProfileNotif {
  return {};
}

export const SmsToPhoneFromProfileNotif = {
  encode(_: SmsToPhoneFromProfileNotif, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmsToPhoneFromProfileNotif {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmsToPhoneFromProfileNotif();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SmsToPhoneFromProfileNotif {
    return {};
  },

  toJSON(_: SmsToPhoneFromProfileNotif): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SmsToPhoneFromProfileNotif>, I>>(base?: I): SmsToPhoneFromProfileNotif {
    return SmsToPhoneFromProfileNotif.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmsToPhoneFromProfileNotif>, I>>(_: I): SmsToPhoneFromProfileNotif {
    const message = createBaseSmsToPhoneFromProfileNotif();
    return message;
  },
};

function createBaseNamedValue(): NamedValue {
  return { name: "", value: "", deleted: false };
}

export const NamedValue = {
  encode(message: NamedValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NamedValue {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
    };
  },

  toJSON(message: NamedValue): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NamedValue>, I>>(base?: I): NamedValue {
    return NamedValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamedValue>, I>>(object: I): NamedValue {
    const message = createBaseNamedValue();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    message.deleted = object.deleted ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

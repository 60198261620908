import { CQGApi } from "../CGQApi";
import { Position } from "../models/Position";
import { MarketDataSubscription_Level } from "../proto/market_data_2";
import { CQGEnvironment } from "../services/CQGEnvironment";
import { InstrumentSubscriptions } from "../services/InstrumentSubscriptions";
import { isArrayWithValues } from "../utils/lib";

/**
 * Watches the application internal messages and acts accordingly.
 */
export class AppMessageWatcher {
  cqgEnv: CQGEnvironment;
  constructor(cqgEnv: CQGEnvironment) {
    this.cqgEnv = cqgEnv;
    this.cqgEnv.appMessageManager.onAccountPositionsChange(this.onAccountPositions);
  }

  onAccountPositions = (positions: Position[]) => {
    if (isArrayWithValues(positions)) {
      positions.forEach((position) => {
        const inst = position.getInstrument();

        if (inst) {
          inst.resolveFromCmeSymbol().then(() => {
            const instSub = InstrumentSubscriptions.Instance.getByContractId(inst.contractId!);

            if (!instSub) {
              let level = MarketDataSubscription_Level.LEVEL_TRADES_BBA_VOLUMES;
              CQGApi.Instance.subscribeToMarketData([inst.contractId!], level);
            }
          });
        }
      });
    }
  };
}

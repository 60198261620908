import "./productsNav.css";
import MenuItem from '../Menutem';
import { Watch, BarChartSharp, ConfirmationNumber, ViewListRounded, ViewColumn, Tune, SwapHoriz, BatteryFull, Newspaper } from '@mui/icons-material';

const items = [
  { text: 'Market', icon: ViewListRounded },
  { text: 'Watchlists', icon: Watch },
  { text: 'Chart', icon: BarChartSharp },
  { text: 'Order Ticket', icon: ConfirmationNumber },
  { text: 'Price Ladder', icon: ViewColumn },
  { text: 'Positions', icon: Tune },
  { text: 'Orders', icon: SwapHoriz },
  { text: 'Performance', icon: BatteryFull  },
  { text: 'News', icon: Newspaper },
];

const ProductList = () => (
  <ul>
    {items.map((item, index) => (
      <li key={index}>
        <MenuItem text={item.text} icon={item.icon} />
      </li>
    ))}
  </ul>
);

const ProductsNav = () => (
  <div className="productsNav">
    <h3 className="heading">ADD WIDGETS</h3>
    <ProductList />
  </div>
);

export default ProductsNav;

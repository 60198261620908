import React from 'react';
import { Box, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

interface MenuItemProps {
  text: string;
  icon: SvgIconComponent;
}

const MenuItem = ({ text, icon: Icon }: MenuItemProps) => (
  <Box
    display="flex"
    alignItems="center"
    sx={{
      color: '#25323C',
    }}
  >
    <Icon
      sx={{
        fontSize: '15px',
        color: '#5A6874',
      }}
    />
    <Typography
      variant="body1"
      sx={{
        marginLeft: 1,
        color: '#081D37',
        fontSize: '13px'
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default MenuItem;

import { Position } from "../models/Position";
import { onAppMessageEvent } from "../types/Types";

export class AppMessageManager {
  private onAccountPositionsChangeEvent: onAppMessageEvent = new onAppMessageEvent();

  public onAccountPositionsChange(listnerFn: (positions: Position[]) => void) {
    this.onAccountPositionsChangeEvent.on("accountPositionsChange", listnerFn);
    this.listListmers(this.onAccountPositionsChangeEvent, "accountPositionsChange", "on");
    this.onAccountPositionsChangeregister()
  }

  public onAccountPositionsChangeregister() {
    this.accountPositionsChangeEmit([]);
  }

  public offAccountPositionsChange(listnerFn: (positions: Position[]) => void) {
    this.onAccountPositionsChangeEvent.off("accountPositionsChange", listnerFn);
    this.listListmers(this.onAccountPositionsChangeEvent, "accountPositionsChange", "off");
  }

  public accountPositionsChangeEmit(positions: Position[]) {
    this.onAccountPositionsChangeEvent.emit("accountPositionsChange", positions);
  }

  private listListmers(evt: onAppMessageEvent, evtName: string, mode: string) {
    // console.log((mode === "on" ? "Subscribed" : "UnSubscribed") + ` From '${evtName}'. Listners count: ` + evt.listenerCount(evtName));
  }
}

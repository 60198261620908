import axios, { AxiosResponse } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

interface Store {
  getState: () => {
    auth: {
      currentUser?: {
        accessToken?: string;
      };
    };
  };
}

export default function axiosSetUp(store: Store): void {
  // Set API endpoint
  const apiUrl = process.env.REACT_APP_API_URL || window.REACT_APP_API_URL;
  axios.defaults.baseURL = apiUrl;
  axios.defaults.withCredentials = true;

  // Request interceptor
  axios.interceptors.request.use(
    (config) => {
      const { headers, params, data } = config;
      const state = store.getState();
      const token = state?.auth?.currentUser?.accessToken;

      if (headers && token) {
        headers.Authorization = `Bearer ${token}`;
      }

      if (params) {
        config.params = decamelizeKeys(params);
      }

      if (data) {
        config.data = decamelizeKeys(data);
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  // Response interceptor
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      const { data, headers } = response;

      if (data && headers["content-type"]?.includes("application/json")) {
        response.data = camelizeKeys(data);
      }

      return response;
    },
    (error) => {
      console.error("API error:", error);
      return Promise.reject(error);
    },
  );
}

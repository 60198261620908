// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: symbol_browsing_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ContractMetadata, OptionMaturityMetadata, SecurityMetadata } from "./metadata_2";
import Long from "long";

export const protobufPackage = "symbol_browsing_2";

/**
 * Instrument client type.
 * Values of this type are known to be stable in contrast to symbol category ids
 * and so they can be hard-coded in client code.
 * Only spread-related types are supported so far.
 */
export enum InstrumentClientType {
  INSTRUMENT_CLIENT_TYPE_UNKNOWN = 0,
  INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE = 1,
  INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR = 2,
  INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY = 3,
  INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY = 4,
  INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY = 5,
  INSTRUMENT_CLIENT_TYPE_SPREAD_PACK = 6,
  INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY = 7,
  INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR = 8,
  INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR = 9,
  INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP = 11,
  INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR = 12,
  INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK = 14,
  UNRECOGNIZED = -1,
}

export function instrumentClientTypeFromJSON(object: any): InstrumentClientType {
  switch (object) {
    case 0:
    case "INSTRUMENT_CLIENT_TYPE_UNKNOWN":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_UNKNOWN;
    case 1:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE;
    case 2:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR;
    case 3:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY;
    case 4:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY;
    case 5:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY;
    case 6:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_PACK":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK;
    case 7:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY;
    case 8:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR;
    case 9:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR;
    case 11:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP;
    case 12:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR;
    case 14:
    case "INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK":
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InstrumentClientType.UNRECOGNIZED;
  }
}

export function instrumentClientTypeToJSON(object: InstrumentClientType): string {
  switch (object) {
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_UNKNOWN:
      return "INSTRUMENT_CLIENT_TYPE_UNKNOWN";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_PACK";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR";
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK:
      return "INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK";
    case InstrumentClientType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Symbol structure.
 * It can represent either a product (root symbol), a security,
 * an option maturity or a contract (leaf symbol).
 * Only one of corresponding *_metadata fields can be specified.
 * Each symbol except product ones has a link to its parent
 * (see Symbol.parent_symbol_id field):
 * parent symbol for a security symbol is a product symbol,
 * parent symbol for a contract symbol (non-option) is a security symbol,
 * parent symbol of an option strike contract symbol is an option maturity symbol,
 * parent symbol for an option maturity symbol is a security symbol.
 */
export interface Symbol {
  /** Symbol Identifier. */
  id: string;
  /** Symbol readable name. */
  name: string;
  /** Symbol description. */
  description: string;
  /**
   * CFI code (Classification of Financial Instruments, ISO 10962) if appropriate.
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  cfiCode?:
    | string
    | undefined;
  /** True if this symbol has child symbols (false for leafs of the symbol tree). */
  hasChildSymbols?:
    | boolean
    | undefined;
  /**
   * Deleted flag is used in updates when the symbol is either removed (e.g. expired) or
   * no longer meets request filtering criterias (e.g. SymbolListRequest.category_id filter).
   * Note: list of categories in category_id field (see below) may be cleared when symbol is removed.
   */
  deleted?:
    | boolean
    | undefined;
  /**
   * Last trading date for derivatives if applicable.
   * (local exchange date in time format, use date part only).
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  lastTradingDate?:
    | number
    | undefined;
  /**
   * Month letter and 2-digit year identifying the maturity month of the symbol.
   * Note: look at comment for maturity_month_year from ContractMetadata for further info.
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  maturityMonthYear?:
    | string
    | undefined;
  /**
   * Name of a group of symbols that share the same properties (e.g. commodity name for futures and options).
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  instrumentGroupName?:
    | string
    | undefined;
  /**
   * ID (Symbol.id) of the parent symbol (if this is not the root symbol - product).
   * If this field is empty, product_metadata field is set.
   */
  parentSymbolId?:
    | string
    | undefined;
  /**
   * List of categories (SymbolCategory.id) of this symbol, if any.
   * See SymbolCategory and SymbolCategoryRequest messages.
   */
  categoryIds: string[];
  /**
   * Deprecated and should not be used. Use metadata_2.SecurityMetadata.source_instrument_group_name or
   * ContractMetadata.source_contract_id instead.
   *
   * @deprecated
   */
  sourceSymbolId?:
    | string
    | undefined;
  /** Rank value of the symbol for sorting peer symbols in user interfaces. Higher value means greater priority. */
  rank?:
    | number
    | undefined;
  /**
   * Meta-data of a product if this symbol describes one (aka Symbol Root Key).
   * If this field is set, this symbol doesn't have a parent symbol.
   */
  productMetadata?:
    | ProductMetadata
    | undefined;
  /** Meta-data of a security if this symbol describes one (aka Symbol Prefix Key). */
  securityMetadata?:
    | SecurityMetadata
    | undefined;
  /** Meta-data of options maturity group if this symbol describes one (aka Option Lead Key). */
  optionMaturityMetadata?:
    | OptionMaturityMetadata
    | undefined;
  /** Contract meta-data if a symbol is a specific contract (leaf of the symbol tree). */
  contractMetadata?: ContractMetadata | undefined;
}

/** Symbol category. */
export interface SymbolCategory {
  /**
   * Category identifier.
   * Note: this identifier is not guaranteed to be stable, so categories should be
   * obtained e.g. via SymbolCategoryListRequest or SymbolCategoryListByInstrumentTypeRequest
   * instead of being saved using this id between sessions.
   */
  id: string;
  /** Category name. */
  name: string;
  /** Category description. */
  description?:
    | string
    | undefined;
  /** Category parent identifier (SymbolCategory.id). Omitted for root categories. */
  parentId?:
    | string
    | undefined;
  /** Indicates whether this category can be used as a filter for getting a list of symbols. */
  canFilter: boolean;
  /**
   * If the category is an exchange then this field defines exchange id.
   * See ExchangeMetadata.exchange_id.
   */
  exchangeId?:
    | number
    | undefined;
  /** If the category is a OTC contributor then this field defines contributor ID. */
  contributorId?:
    | string
    | undefined;
  /** Deleted flag is used in updates when the category is removed. */
  deleted?: boolean | undefined;
}

/**
 * Request for a symbol category [sub-]tree for a particular root. each category can have a list of sub-categories.
 * A list of roots can be requested using an empty list of category IDs.
 */
export interface SymbolCategoryListRequest {
  /**
   * Category Identifier (SymbolCategory.id) to request corresponding sub-tree.
   * Do not specify category ID to get categories from roots.
   */
  categoryId?:
    | string
    | undefined;
  /** Optional depth. One level is returned if not specified. */
  depth?: number | undefined;
}

/** Report with a symbol category tree for a particular root. */
export interface SymbolCategoryListReport {
  /** List of categories linked to their parents. */
  symbolCategories: SymbolCategory[];
}

/** Request for a specific category by ID. */
export interface SymbolCategoryRequest {
  /** Category Identifier (SymbolCategory.id) to request corresponding category. */
  categoryId?: string | undefined;
}

/** Report with a category. */
export interface SymbolCategoryReport {
  symbolCategory?: SymbolCategory | undefined;
}

/** Request for a list of category symbols matching input instrument client type. */
export interface SymbolCategoryListByInstrumentTypeRequest {
  /**
   * Instrument client type.
   * This field is associated with InstrumentClientType.Type enum.
   * INSTRUMENT_CLIENT_TYPE_UNKNOWN is used if this field is empty.
   */
  instrumentClientType?: number | undefined;
}

/** Report with a list of category symbols matching input instrument client type. */
export interface SymbolCategoryListByInstrumentTypeReport {
  symbolCategories: SymbolCategory[];
}

/**
 * Request for a list of symbols according to a filter.
 * At least one filter field has to be specified.
 * Number of symbols in response is limited (default is 10000).
 * Note: Symbols related to option strikes are not returned unless symbol id of
 * corresponding option maturity symbol is specified as a parent_symbol_id in the request.
 */
export interface SymbolListRequest {
  /**
   * Optional category filter (SymbolCategory.id) controlling how multiple
   * symbol categories are applied in the filter.
   * Categories within the same tree (having the same root) are applied by "OR" in the category filter
   * (e.g. two exchanges, either matches). Otherwise categories are applied by "AND" (e.g. exchange and asset).
   * See SymbolCategory and SymbolCategoryListRequest messages.
   */
  categoryIds: string[];
  /**
   * Number of levels in the symbol tree to return from the top of the symbol tree
   * (i.e. from product level) or from the parent symbol (if specified).
   * Symbols from deeper levels are excluded from the results.
   * One level is returned if the field is omitted.
   */
  depth?:
    | number
    | undefined;
  /**
   * Parent symbol id (Symbol.id) filter to return only child symbols of this parent.
   * If this field is specified, depth must be one or omitted.
   */
  parentSymbolId?: string | undefined;
}

/** Report with a list of found symbols. */
export interface SymbolListReport {
  /** List of symbols. */
  symbols: Symbol[];
}

/** Request for a specific symbol by ID. */
export interface SymbolRequest {
  /** ID (Symbol.id) of a symbol to request. */
  symbolId: string;
  /**
   * True if deleted symbols should also be reported.
   * Note that symbol marked as deleted will be available for 30 days (by default) from its last trading date.
   */
  includeDeleted?: boolean | undefined;
}

/** Report with a symbol. */
export interface SymbolReport {
  symbol?: Symbol | undefined;
}

export interface ProductMetadata {
  productId?: string | undefined;
}

/** Request for a list of product symbols matching a search criteria (see Symbol.product_metadata). */
export interface ProductSearchRequest {
  /**
   * Term to search matched product symbols on.
   * Search term needs to be filled and its length must be greater than 3 (by default) if category_id filter is empty.
   * Searching is done on the text associated to the being searched product symbols.
   * Matching is supported only by "starts with" pattern.
   * Multi-word input (whitespace delimited) is applied by "or".
   * I.e. it matches a symbol if any word from the search term matches.
   */
  searchTerm?:
    | string
    | undefined;
  /**
   * Optional category (SymbolCategory.id) filter controlling how multiple
   * symbol categories are applied in the filter.
   * Categories within the same tree (having the same root) are applied by "OR" in the category filter
   * (e.g. two exchanges, either matches). Otherwise categories are applied by "AND" (e.g. exchange and asset).
   * See SymbolCategory and SymbolCategoryListRequest messages.
   */
  categoryIds: string[];
}

/** Report with a list of found product symbols. */
export interface ProductSearchReport {
  /** List of product symbols. */
  symbols: Symbol[];
}

function createBaseSymbol(): Symbol {
  return {
    id: "",
    name: "",
    description: "",
    cfiCode: "",
    hasChildSymbols: false,
    deleted: false,
    lastTradingDate: 0,
    maturityMonthYear: "",
    instrumentGroupName: "",
    parentSymbolId: "",
    categoryIds: [],
    sourceSymbolId: "",
    rank: 0,
    productMetadata: undefined,
    securityMetadata: undefined,
    optionMaturityMetadata: undefined,
    contractMetadata: undefined,
  };
}

export const Symbol = {
  encode(message: Symbol, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.cfiCode !== undefined && message.cfiCode !== "") {
      writer.uint32(34).string(message.cfiCode);
    }
    if (message.hasChildSymbols !== undefined && message.hasChildSymbols !== false) {
      writer.uint32(40).bool(message.hasChildSymbols);
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      writer.uint32(56).bool(message.deleted);
    }
    if (message.lastTradingDate !== undefined && message.lastTradingDate !== 0) {
      writer.uint32(64).sint64(message.lastTradingDate);
    }
    if (message.maturityMonthYear !== undefined && message.maturityMonthYear !== "") {
      writer.uint32(74).string(message.maturityMonthYear);
    }
    if (message.instrumentGroupName !== undefined && message.instrumentGroupName !== "") {
      writer.uint32(82).string(message.instrumentGroupName);
    }
    if (message.parentSymbolId !== undefined && message.parentSymbolId !== "") {
      writer.uint32(90).string(message.parentSymbolId);
    }
    for (const v of message.categoryIds) {
      writer.uint32(98).string(v!);
    }
    if (message.sourceSymbolId !== undefined && message.sourceSymbolId !== "") {
      writer.uint32(106).string(message.sourceSymbolId);
    }
    if (message.rank !== undefined && message.rank !== 0) {
      writer.uint32(136).uint32(message.rank);
    }
    if (message.productMetadata !== undefined) {
      ProductMetadata.encode(message.productMetadata, writer.uint32(114).fork()).ldelim();
    }
    if (message.securityMetadata !== undefined) {
      SecurityMetadata.encode(message.securityMetadata, writer.uint32(122).fork()).ldelim();
    }
    if (message.optionMaturityMetadata !== undefined) {
      OptionMaturityMetadata.encode(message.optionMaturityMetadata, writer.uint32(130).fork()).ldelim();
    }
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Symbol {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbol();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cfiCode = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.hasChildSymbols = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.deleted = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.lastTradingDate = longToNumber(reader.sint64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.maturityMonthYear = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.instrumentGroupName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.parentSymbolId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.categoryIds.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.sourceSymbolId = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.rank = reader.uint32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.productMetadata = ProductMetadata.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.securityMetadata = SecurityMetadata.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.optionMaturityMetadata = OptionMaturityMetadata.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Symbol {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      cfiCode: isSet(object.cfiCode) ? globalThis.String(object.cfiCode) : "",
      hasChildSymbols: isSet(object.hasChildSymbols) ? globalThis.Boolean(object.hasChildSymbols) : false,
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
      lastTradingDate: isSet(object.lastTradingDate) ? globalThis.Number(object.lastTradingDate) : 0,
      maturityMonthYear: isSet(object.maturityMonthYear) ? globalThis.String(object.maturityMonthYear) : "",
      instrumentGroupName: isSet(object.instrumentGroupName) ? globalThis.String(object.instrumentGroupName) : "",
      parentSymbolId: isSet(object.parentSymbolId) ? globalThis.String(object.parentSymbolId) : "",
      categoryIds: globalThis.Array.isArray(object?.categoryIds)
        ? object.categoryIds.map((e: any) => globalThis.String(e))
        : [],
      sourceSymbolId: isSet(object.sourceSymbolId) ? globalThis.String(object.sourceSymbolId) : "",
      rank: isSet(object.rank) ? globalThis.Number(object.rank) : 0,
      productMetadata: isSet(object.productMetadata) ? ProductMetadata.fromJSON(object.productMetadata) : undefined,
      securityMetadata: isSet(object.securityMetadata) ? SecurityMetadata.fromJSON(object.securityMetadata) : undefined,
      optionMaturityMetadata: isSet(object.optionMaturityMetadata)
        ? OptionMaturityMetadata.fromJSON(object.optionMaturityMetadata)
        : undefined,
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
    };
  },

  toJSON(message: Symbol): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.cfiCode !== undefined && message.cfiCode !== "") {
      obj.cfiCode = message.cfiCode;
    }
    if (message.hasChildSymbols !== undefined && message.hasChildSymbols !== false) {
      obj.hasChildSymbols = message.hasChildSymbols;
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    if (message.lastTradingDate !== undefined && message.lastTradingDate !== 0) {
      obj.lastTradingDate = Math.round(message.lastTradingDate);
    }
    if (message.maturityMonthYear !== undefined && message.maturityMonthYear !== "") {
      obj.maturityMonthYear = message.maturityMonthYear;
    }
    if (message.instrumentGroupName !== undefined && message.instrumentGroupName !== "") {
      obj.instrumentGroupName = message.instrumentGroupName;
    }
    if (message.parentSymbolId !== undefined && message.parentSymbolId !== "") {
      obj.parentSymbolId = message.parentSymbolId;
    }
    if (message.categoryIds?.length) {
      obj.categoryIds = message.categoryIds;
    }
    if (message.sourceSymbolId !== undefined && message.sourceSymbolId !== "") {
      obj.sourceSymbolId = message.sourceSymbolId;
    }
    if (message.rank !== undefined && message.rank !== 0) {
      obj.rank = Math.round(message.rank);
    }
    if (message.productMetadata !== undefined) {
      obj.productMetadata = ProductMetadata.toJSON(message.productMetadata);
    }
    if (message.securityMetadata !== undefined) {
      obj.securityMetadata = SecurityMetadata.toJSON(message.securityMetadata);
    }
    if (message.optionMaturityMetadata !== undefined) {
      obj.optionMaturityMetadata = OptionMaturityMetadata.toJSON(message.optionMaturityMetadata);
    }
    if (message.contractMetadata !== undefined) {
      obj.contractMetadata = ContractMetadata.toJSON(message.contractMetadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Symbol>, I>>(base?: I): Symbol {
    return Symbol.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Symbol>, I>>(object: I): Symbol {
    const message = createBaseSymbol();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.cfiCode = object.cfiCode ?? "";
    message.hasChildSymbols = object.hasChildSymbols ?? false;
    message.deleted = object.deleted ?? false;
    message.lastTradingDate = object.lastTradingDate ?? 0;
    message.maturityMonthYear = object.maturityMonthYear ?? "";
    message.instrumentGroupName = object.instrumentGroupName ?? "";
    message.parentSymbolId = object.parentSymbolId ?? "";
    message.categoryIds = object.categoryIds?.map((e) => e) || [];
    message.sourceSymbolId = object.sourceSymbolId ?? "";
    message.rank = object.rank ?? 0;
    message.productMetadata = (object.productMetadata !== undefined && object.productMetadata !== null)
      ? ProductMetadata.fromPartial(object.productMetadata)
      : undefined;
    message.securityMetadata = (object.securityMetadata !== undefined && object.securityMetadata !== null)
      ? SecurityMetadata.fromPartial(object.securityMetadata)
      : undefined;
    message.optionMaturityMetadata =
      (object.optionMaturityMetadata !== undefined && object.optionMaturityMetadata !== null)
        ? OptionMaturityMetadata.fromPartial(object.optionMaturityMetadata)
        : undefined;
    message.contractMetadata = (object.contractMetadata !== undefined && object.contractMetadata !== null)
      ? ContractMetadata.fromPartial(object.contractMetadata)
      : undefined;
    return message;
  },
};

function createBaseSymbolCategory(): SymbolCategory {
  return {
    id: "",
    name: "",
    description: "",
    parentId: "",
    canFilter: false,
    exchangeId: 0,
    contributorId: "",
    deleted: false,
  };
}

export const SymbolCategory = {
  encode(message: SymbolCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.parentId !== undefined && message.parentId !== "") {
      writer.uint32(34).string(message.parentId);
    }
    if (message.canFilter !== false) {
      writer.uint32(40).bool(message.canFilter);
    }
    if (message.exchangeId !== undefined && message.exchangeId !== 0) {
      writer.uint32(48).sint32(message.exchangeId);
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      writer.uint32(58).string(message.contributorId);
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      writer.uint32(64).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.canFilter = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.exchangeId = reader.sint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.contributorId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.deleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolCategory {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : "",
      canFilter: isSet(object.canFilter) ? globalThis.Boolean(object.canFilter) : false,
      exchangeId: isSet(object.exchangeId) ? globalThis.Number(object.exchangeId) : 0,
      contributorId: isSet(object.contributorId) ? globalThis.String(object.contributorId) : "",
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
    };
  },

  toJSON(message: SymbolCategory): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.parentId !== undefined && message.parentId !== "") {
      obj.parentId = message.parentId;
    }
    if (message.canFilter !== false) {
      obj.canFilter = message.canFilter;
    }
    if (message.exchangeId !== undefined && message.exchangeId !== 0) {
      obj.exchangeId = Math.round(message.exchangeId);
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      obj.contributorId = message.contributorId;
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategory>, I>>(base?: I): SymbolCategory {
    return SymbolCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolCategory>, I>>(object: I): SymbolCategory {
    const message = createBaseSymbolCategory();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.parentId = object.parentId ?? "";
    message.canFilter = object.canFilter ?? false;
    message.exchangeId = object.exchangeId ?? 0;
    message.contributorId = object.contributorId ?? "";
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseSymbolCategoryListRequest(): SymbolCategoryListRequest {
  return { categoryId: "", depth: 0 };
}

export const SymbolCategoryListRequest = {
  encode(message: SymbolCategoryListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryId !== undefined && message.categoryId !== "") {
      writer.uint32(10).string(message.categoryId);
    }
    if (message.depth !== undefined && message.depth !== 0) {
      writer.uint32(16).uint32(message.depth);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.depth = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListRequest {
    return {
      categoryId: isSet(object.categoryId) ? globalThis.String(object.categoryId) : "",
      depth: isSet(object.depth) ? globalThis.Number(object.depth) : 0,
    };
  },

  toJSON(message: SymbolCategoryListRequest): unknown {
    const obj: any = {};
    if (message.categoryId !== undefined && message.categoryId !== "") {
      obj.categoryId = message.categoryId;
    }
    if (message.depth !== undefined && message.depth !== 0) {
      obj.depth = Math.round(message.depth);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListRequest>, I>>(base?: I): SymbolCategoryListRequest {
    return SymbolCategoryListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListRequest>, I>>(object: I): SymbolCategoryListRequest {
    const message = createBaseSymbolCategoryListRequest();
    message.categoryId = object.categoryId ?? "";
    message.depth = object.depth ?? 0;
    return message;
  },
};

function createBaseSymbolCategoryListReport(): SymbolCategoryListReport {
  return { symbolCategories: [] };
}

export const SymbolCategoryListReport = {
  encode(message: SymbolCategoryListReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbolCategories) {
      SymbolCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbolCategories.push(SymbolCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListReport {
    return {
      symbolCategories: globalThis.Array.isArray(object?.symbolCategories)
        ? object.symbolCategories.map((e: any) => SymbolCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SymbolCategoryListReport): unknown {
    const obj: any = {};
    if (message.symbolCategories?.length) {
      obj.symbolCategories = message.symbolCategories.map((e) => SymbolCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListReport>, I>>(base?: I): SymbolCategoryListReport {
    return SymbolCategoryListReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListReport>, I>>(object: I): SymbolCategoryListReport {
    const message = createBaseSymbolCategoryListReport();
    message.symbolCategories = object.symbolCategories?.map((e) => SymbolCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSymbolCategoryRequest(): SymbolCategoryRequest {
  return { categoryId: "" };
}

export const SymbolCategoryRequest = {
  encode(message: SymbolCategoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryId !== undefined && message.categoryId !== "") {
      writer.uint32(10).string(message.categoryId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryRequest {
    return { categoryId: isSet(object.categoryId) ? globalThis.String(object.categoryId) : "" };
  },

  toJSON(message: SymbolCategoryRequest): unknown {
    const obj: any = {};
    if (message.categoryId !== undefined && message.categoryId !== "") {
      obj.categoryId = message.categoryId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryRequest>, I>>(base?: I): SymbolCategoryRequest {
    return SymbolCategoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolCategoryRequest>, I>>(object: I): SymbolCategoryRequest {
    const message = createBaseSymbolCategoryRequest();
    message.categoryId = object.categoryId ?? "";
    return message;
  },
};

function createBaseSymbolCategoryReport(): SymbolCategoryReport {
  return { symbolCategory: undefined };
}

export const SymbolCategoryReport = {
  encode(message: SymbolCategoryReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbolCategory !== undefined) {
      SymbolCategory.encode(message.symbolCategory, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbolCategory = SymbolCategory.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryReport {
    return {
      symbolCategory: isSet(object.symbolCategory) ? SymbolCategory.fromJSON(object.symbolCategory) : undefined,
    };
  },

  toJSON(message: SymbolCategoryReport): unknown {
    const obj: any = {};
    if (message.symbolCategory !== undefined) {
      obj.symbolCategory = SymbolCategory.toJSON(message.symbolCategory);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryReport>, I>>(base?: I): SymbolCategoryReport {
    return SymbolCategoryReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolCategoryReport>, I>>(object: I): SymbolCategoryReport {
    const message = createBaseSymbolCategoryReport();
    message.symbolCategory = (object.symbolCategory !== undefined && object.symbolCategory !== null)
      ? SymbolCategory.fromPartial(object.symbolCategory)
      : undefined;
    return message;
  },
};

function createBaseSymbolCategoryListByInstrumentTypeRequest(): SymbolCategoryListByInstrumentTypeRequest {
  return { instrumentClientType: 0 };
}

export const SymbolCategoryListByInstrumentTypeRequest = {
  encode(message: SymbolCategoryListByInstrumentTypeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instrumentClientType !== undefined && message.instrumentClientType !== 0) {
      writer.uint32(8).uint32(message.instrumentClientType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListByInstrumentTypeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListByInstrumentTypeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.instrumentClientType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListByInstrumentTypeRequest {
    return {
      instrumentClientType: isSet(object.instrumentClientType) ? globalThis.Number(object.instrumentClientType) : 0,
    };
  },

  toJSON(message: SymbolCategoryListByInstrumentTypeRequest): unknown {
    const obj: any = {};
    if (message.instrumentClientType !== undefined && message.instrumentClientType !== 0) {
      obj.instrumentClientType = Math.round(message.instrumentClientType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeRequest>, I>>(
    base?: I,
  ): SymbolCategoryListByInstrumentTypeRequest {
    return SymbolCategoryListByInstrumentTypeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeRequest>, I>>(
    object: I,
  ): SymbolCategoryListByInstrumentTypeRequest {
    const message = createBaseSymbolCategoryListByInstrumentTypeRequest();
    message.instrumentClientType = object.instrumentClientType ?? 0;
    return message;
  },
};

function createBaseSymbolCategoryListByInstrumentTypeReport(): SymbolCategoryListByInstrumentTypeReport {
  return { symbolCategories: [] };
}

export const SymbolCategoryListByInstrumentTypeReport = {
  encode(message: SymbolCategoryListByInstrumentTypeReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbolCategories) {
      SymbolCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListByInstrumentTypeReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListByInstrumentTypeReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbolCategories.push(SymbolCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListByInstrumentTypeReport {
    return {
      symbolCategories: globalThis.Array.isArray(object?.symbolCategories)
        ? object.symbolCategories.map((e: any) => SymbolCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SymbolCategoryListByInstrumentTypeReport): unknown {
    const obj: any = {};
    if (message.symbolCategories?.length) {
      obj.symbolCategories = message.symbolCategories.map((e) => SymbolCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeReport>, I>>(
    base?: I,
  ): SymbolCategoryListByInstrumentTypeReport {
    return SymbolCategoryListByInstrumentTypeReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeReport>, I>>(
    object: I,
  ): SymbolCategoryListByInstrumentTypeReport {
    const message = createBaseSymbolCategoryListByInstrumentTypeReport();
    message.symbolCategories = object.symbolCategories?.map((e) => SymbolCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSymbolListRequest(): SymbolListRequest {
  return { categoryIds: [], depth: 0, parentSymbolId: "" };
}

export const SymbolListRequest = {
  encode(message: SymbolListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.categoryIds) {
      writer.uint32(10).string(v!);
    }
    if (message.depth !== undefined && message.depth !== 0) {
      writer.uint32(16).uint32(message.depth);
    }
    if (message.parentSymbolId !== undefined && message.parentSymbolId !== "") {
      writer.uint32(26).string(message.parentSymbolId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.depth = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parentSymbolId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolListRequest {
    return {
      categoryIds: globalThis.Array.isArray(object?.categoryIds)
        ? object.categoryIds.map((e: any) => globalThis.String(e))
        : [],
      depth: isSet(object.depth) ? globalThis.Number(object.depth) : 0,
      parentSymbolId: isSet(object.parentSymbolId) ? globalThis.String(object.parentSymbolId) : "",
    };
  },

  toJSON(message: SymbolListRequest): unknown {
    const obj: any = {};
    if (message.categoryIds?.length) {
      obj.categoryIds = message.categoryIds;
    }
    if (message.depth !== undefined && message.depth !== 0) {
      obj.depth = Math.round(message.depth);
    }
    if (message.parentSymbolId !== undefined && message.parentSymbolId !== "") {
      obj.parentSymbolId = message.parentSymbolId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolListRequest>, I>>(base?: I): SymbolListRequest {
    return SymbolListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolListRequest>, I>>(object: I): SymbolListRequest {
    const message = createBaseSymbolListRequest();
    message.categoryIds = object.categoryIds?.map((e) => e) || [];
    message.depth = object.depth ?? 0;
    message.parentSymbolId = object.parentSymbolId ?? "";
    return message;
  },
};

function createBaseSymbolListReport(): SymbolListReport {
  return { symbols: [] };
}

export const SymbolListReport = {
  encode(message: SymbolListReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbols) {
      Symbol.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolListReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolListReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbols.push(Symbol.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolListReport {
    return {
      symbols: globalThis.Array.isArray(object?.symbols) ? object.symbols.map((e: any) => Symbol.fromJSON(e)) : [],
    };
  },

  toJSON(message: SymbolListReport): unknown {
    const obj: any = {};
    if (message.symbols?.length) {
      obj.symbols = message.symbols.map((e) => Symbol.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolListReport>, I>>(base?: I): SymbolListReport {
    return SymbolListReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolListReport>, I>>(object: I): SymbolListReport {
    const message = createBaseSymbolListReport();
    message.symbols = object.symbols?.map((e) => Symbol.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSymbolRequest(): SymbolRequest {
  return { symbolId: "", includeDeleted: false };
}

export const SymbolRequest = {
  encode(message: SymbolRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbolId !== "") {
      writer.uint32(10).string(message.symbolId);
    }
    if (message.includeDeleted !== undefined && message.includeDeleted !== false) {
      writer.uint32(16).bool(message.includeDeleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbolId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeDeleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolRequest {
    return {
      symbolId: isSet(object.symbolId) ? globalThis.String(object.symbolId) : "",
      includeDeleted: isSet(object.includeDeleted) ? globalThis.Boolean(object.includeDeleted) : false,
    };
  },

  toJSON(message: SymbolRequest): unknown {
    const obj: any = {};
    if (message.symbolId !== "") {
      obj.symbolId = message.symbolId;
    }
    if (message.includeDeleted !== undefined && message.includeDeleted !== false) {
      obj.includeDeleted = message.includeDeleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolRequest>, I>>(base?: I): SymbolRequest {
    return SymbolRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolRequest>, I>>(object: I): SymbolRequest {
    const message = createBaseSymbolRequest();
    message.symbolId = object.symbolId ?? "";
    message.includeDeleted = object.includeDeleted ?? false;
    return message;
  },
};

function createBaseSymbolReport(): SymbolReport {
  return { symbol: undefined };
}

export const SymbolReport = {
  encode(message: SymbolReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbol !== undefined) {
      Symbol.encode(message.symbol, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbol = Symbol.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SymbolReport {
    return { symbol: isSet(object.symbol) ? Symbol.fromJSON(object.symbol) : undefined };
  },

  toJSON(message: SymbolReport): unknown {
    const obj: any = {};
    if (message.symbol !== undefined) {
      obj.symbol = Symbol.toJSON(message.symbol);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolReport>, I>>(base?: I): SymbolReport {
    return SymbolReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SymbolReport>, I>>(object: I): SymbolReport {
    const message = createBaseSymbolReport();
    message.symbol = (object.symbol !== undefined && object.symbol !== null)
      ? Symbol.fromPartial(object.symbol)
      : undefined;
    return message;
  },
};

function createBaseProductMetadata(): ProductMetadata {
  return { productId: "" };
}

export const ProductMetadata = {
  encode(message: ProductMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productId !== undefined && message.productId !== "") {
      writer.uint32(10).string(message.productId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.productId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProductMetadata {
    return { productId: isSet(object.productId) ? globalThis.String(object.productId) : "" };
  },

  toJSON(message: ProductMetadata): unknown {
    const obj: any = {};
    if (message.productId !== undefined && message.productId !== "") {
      obj.productId = message.productId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductMetadata>, I>>(base?: I): ProductMetadata {
    return ProductMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProductMetadata>, I>>(object: I): ProductMetadata {
    const message = createBaseProductMetadata();
    message.productId = object.productId ?? "";
    return message;
  },
};

function createBaseProductSearchRequest(): ProductSearchRequest {
  return { searchTerm: "", categoryIds: [] };
}

export const ProductSearchRequest = {
  encode(message: ProductSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchTerm !== undefined && message.searchTerm !== "") {
      writer.uint32(10).string(message.searchTerm);
    }
    for (const v of message.categoryIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchTerm = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.categoryIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProductSearchRequest {
    return {
      searchTerm: isSet(object.searchTerm) ? globalThis.String(object.searchTerm) : "",
      categoryIds: globalThis.Array.isArray(object?.categoryIds)
        ? object.categoryIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProductSearchRequest): unknown {
    const obj: any = {};
    if (message.searchTerm !== undefined && message.searchTerm !== "") {
      obj.searchTerm = message.searchTerm;
    }
    if (message.categoryIds?.length) {
      obj.categoryIds = message.categoryIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductSearchRequest>, I>>(base?: I): ProductSearchRequest {
    return ProductSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProductSearchRequest>, I>>(object: I): ProductSearchRequest {
    const message = createBaseProductSearchRequest();
    message.searchTerm = object.searchTerm ?? "";
    message.categoryIds = object.categoryIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseProductSearchReport(): ProductSearchReport {
  return { symbols: [] };
}

export const ProductSearchReport = {
  encode(message: ProductSearchReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbols) {
      Symbol.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductSearchReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductSearchReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbols.push(Symbol.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProductSearchReport {
    return {
      symbols: globalThis.Array.isArray(object?.symbols) ? object.symbols.map((e: any) => Symbol.fromJSON(e)) : [],
    };
  },

  toJSON(message: ProductSearchReport): unknown {
    const obj: any = {};
    if (message.symbols?.length) {
      obj.symbols = message.symbols.map((e) => Symbol.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductSearchReport>, I>>(base?: I): ProductSearchReport {
    return ProductSearchReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProductSearchReport>, I>>(object: I): ProductSearchReport {
    const message = createBaseProductSearchReport();
    message.symbols = object.symbols?.map((e) => Symbol.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

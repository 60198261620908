// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-info-image-container > img {
  filter: brightness(0) saturate(100%) invert(74%) sepia(1%) saturate(845%)
    hue-rotate(355deg) brightness(82%) contrast(82%);
}
  
.account-balance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #000;
  font-size: 12px;
  margin-top: 0;
  padding-top: 0;
}

.account-value-table {
  width: 100%;
  border-collapse: collapse;
}

.account-value-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.account-infos {
  text-align: end;
  color: #5A6874;
}

.account-value-table td:nth-child(1),
.account-value-table td:nth-child(2) {
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/widgets/accountValue/AccountValue.css"],"names":[],"mappings":"AAAA;EACE;oDACkD;AACpD;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,eAAe;EACf,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;;EAEE,UAAU;AACZ","sourcesContent":[".account-info-image-container > img {\n  filter: brightness(0) saturate(100%) invert(74%) sepia(1%) saturate(845%)\n    hue-rotate(355deg) brightness(82%) contrast(82%);\n}\n  \n.account-balance-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  color: #000;\n  font-size: 12px;\n  margin-top: 0;\n  padding-top: 0;\n}\n\n.account-value-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.account-value-table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n.account-infos {\n  text-align: end;\n  color: #5A6874;\n}\n\n.account-value-table td:nth-child(1),\n.account-value-table td:nth-child(2) {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

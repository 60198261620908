import { Menu, MenuItem } from "@mui/material";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import SwapCallsOutlinedIcon from "@mui/icons-material/SwapCallsOutlined";
import { EditNoteRounded } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const WorkspaceMenu: React.FC<{
    anchorEl: null | HTMLElement;
    open: boolean;
    onClose: () => void;
    onRenameClick: () => void;
    onDeleteClick: () => void;
    onChangeDefaultClick: () => void;
    onDuplicateClick: () => void;
  }> = ({ anchorEl, open, onClose, onRenameClick, onDeleteClick, onChangeDefaultClick, onDuplicateClick }) => (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ marginTop: "10px", padding: "5px" }}
    >
      <MenuItem onClick={onDuplicateClick}>
        <CopyAllOutlinedIcon className="menuIcon" sx={{ color: "#7968ff" }} />
        <span className="menuText">Duplicate</span>
      </MenuItem>
      <MenuItem onClick={onChangeDefaultClick}>
        <SwapCallsOutlinedIcon className="menuIcon" />
        <span className="menuText">Set as Default</span>
      </MenuItem>
      <MenuItem onClick={onRenameClick}>
        <EditNoteRounded className="menuIcon" />
        <span className="menuText">Rename Workspace</span>
      </MenuItem>
      <hr className="divider-tab" />
      <MenuItem onClick={onDeleteClick}>
        <DeleteOutlineOutlinedIcon className="menuIcon" />
        <span className="menuText">Delete</span>
      </MenuItem>
    </Menu>
  );

  export default WorkspaceMenu
const Logo = () => (
  <div className="logo">
    <img
      src="/images/icons/logo.svg"
      alt="logo"
    />
    <div className="logo-content">CME Group</div>
  </div>
);

export default Logo;

import React from "react";
import { Workspace } from "../../../types";
import { formatCurremcy, isArrayWithValues } from "../../../cqg-api/utils/lib";
import { useCollateralStatus, useOrdersList, usePositionsList } from "../../../cqg-api/hooks/ServiceHooks";
import "./AccountValue.css";

interface AccountValueProps {
  selectedWorkspace?: Workspace | null;
  workspaceClassName?: string;
}

const AccountValue: React.FC<AccountValueProps> = ({ selectedWorkspace, workspaceClassName }) => {
  const { collateralStatus } = useCollateralStatus();
  const { ordersList } = useOrdersList();
  const { positionsList } = usePositionsList();

  const renderCollateralStatus = () =>
    !isArrayWithValues(collateralStatus) ? (
      <tr>
        <td colSpan={2} className="account-infos">
          Loading ...
        </td>
      </tr>
    ) : (
      collateralStatus.map((status, idx) => (
        <React.Fragment key={`collateral-status-${idx}`}>
          <tr>
            <td>Total Margin</td>
            <td className="account-infos">{formatCurremcy(status.totalMargin)}</td>
          </tr>
          <tr>
            <td>Position Margin</td>
            <td className="account-infos">{formatCurremcy(status.positionMargin)}</td>
          </tr>
          <tr>
            <td>Purchasing Power</td>
            <td className="account-infos">{formatCurremcy(status.purchasingPower)}</td>
          </tr>
        </React.Fragment>
      ))
    );

  return (
    <div className={workspaceClassName} style={{ width: workspaceClassName ? "auto" : "450px", padding: "10px 0" }}>
      {selectedWorkspace?.id ? (
        <div className="account-balance-container">
          <table className="account-value-table">
            <tbody>
              {renderCollateralStatus()}
              {isArrayWithValues(positionsList) && (
                <tr>
                  <td>Total Position</td>
                  <td className="account-infos">{positionsList.length}</td>
                </tr>
              )}
              {isArrayWithValues(ordersList) && (
                <tr>
                  <td>Total Orders</td>
                  <td className="account-infos">{ordersList.length}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="account-info-image-container">
          <img src="/images/icons/tradePlan.svg" alt="Account info" height="100" width="150" />
        </div>
      )}
    </div>
  );
};

export default AccountValue;

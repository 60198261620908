import React, { useCallback, useState } from "react";
import ECharts from "echarts-for-react";
import { options } from "./mockData/option";

export default function EnergyChart({
  workspaceClassName,
  selectedWorkspace,
  isPopupWindow,
}) {
  const [width, setWidth] = useState();
  const div = useCallback(
    (node) => {
      if (node !== null) {
        setWidth(node.getBoundingClientRect().width);
      }
    },
    // eslint-disable-next-line
    [selectedWorkspace]
  );
  return (
    <div
      ref={div}
      style={{ width: workspaceClassName ? "" : "450px" }}
      className={workspaceClassName}
    >
      <ECharts
        option={options}
        className={workspaceClassName}
        style={{ width: isPopupWindow ? "" : `${width}px` }}
      />
    </div>
  );
}

import * as MarketData from "../proto/market_data_2";
import { InstrumentSubscriptions } from "./InstrumentSubscriptions";

export class InstrumentSubscription {
  subscriptions: InstrumentSubscriptions;
  contractId: number;
  level: number;
  statusCode: number | null;
  textMessage: string | null;
  isPending: boolean;
  private consumersByLevel: number[];

  constructor(subscriptions: InstrumentSubscriptions, contractId: number, level: number) {
    this.subscriptions = subscriptions;
    this.contractId = contractId;
    this.level = level;
    this.statusCode = null;
    this.textMessage = null;
    this.isPending = true;

    this.consumersByLevel = [];
    this.consumersByLevel[level] = 1;
  }

  modifyConsumerCount = (level: number, getNewConsumerCount: (oldConsumerCount: number | undefined) => number) => {
    const oldLevel = this.getLevel();
    const oldConsumerCount = this.consumersByLevel[level] || 0;
    const newConsumerCount = getNewConsumerCount(oldConsumerCount);
    this.consumersByLevel[level] = newConsumerCount;
    const newLevel = this.getLevel();
    if (newLevel !== oldLevel) {
      this.subscriptions.changeLevel(this, oldLevel, newLevel);
    }
    return { oldLevel, newLevel };
  };

  getLevel = (): number => {
    for (let level = this.consumersByLevel.length - 1; level >= 0; --level) {
      if (this.consumersByLevel[level] > 0) {
        return level;
      }
    }
    return MarketData.MarketDataSubscription_Level.LEVEL_NONE;
  };

  addConsumer = (level: number) => {
    return this.modifyConsumerCount(level, (oldConsumerCount) => (oldConsumerCount ? oldConsumerCount + 1 : 1));
  };

  removeConsumer = (level: number) => {
    return this.modifyConsumerCount(level, (oldConsumerCount) => (oldConsumerCount ? oldConsumerCount - 1 : 0));
  };

  processMarketDataSubscriptionStatus = (marketDataSubscriptionStatus: MarketData.MarketDataSubscriptionStatus) => {
    this.statusCode = marketDataSubscriptionStatus.statusCode;
    this.textMessage = marketDataSubscriptionStatus.textMessage as string;
    this.isPending = false;
  };
}

import { DisplayUtil } from "../utils/DisplayUtil";
import { InstrumentsManager } from "../services/InstrumentsManager";
import { TimeUtil } from "../utils/TimeUtil";
import { PositionStatus } from "../proto/trade_routing_2";
import { dateNullable, numberNullable, stringNullable } from "../types/Types";
import { Account } from "./Account";
import { Instrument } from "./Instrument";
import * as TradeRouting from "../proto/trade_routing_2";

export class Position {
  id: number;
  private account: Account;
  private instrument: Instrument | undefined;

  isShort: boolean = false;
  size: number = 0;
  lastTradeDate: Date | null = null;
  price: number = 0;

  oteMvo: number = 0;
  upl: number = 0;
  mvo: number = 0;

  displaySymbol: stringNullable = null;
  displayCurrency: stringNullable = null;
  displayDescription: stringNullable = null;
  displayLastTradeDate: stringNullable = null;
  displayLong: numberNullable = null;
  displayShort: numberNullable = null;
  displayPrice: numberNullable = null;
  displayOteMvo: numberNullable = null;
  displayUpl: numberNullable = null;
  displayMvo: numberNullable = null;
  displayStrikePrice: numberNullable = null;
  displayPutCall: stringNullable = null;
  tradeUtcTime: dateNullable = null;

  constructor(account: Account, positionStatus: PositionStatus, position: TradeRouting.OpenPosition) {
    this.account = account;
    this.instrument = InstrumentsManager.getInstrument(positionStatus.contractId);
    this.id = position.id;

    this.updateFromPositionStatus(positionStatus, position);
  }

  getAccount = () => {
    return this.account;
  };

  getInstrument = () => {
    return this.instrument;
  };

  updateFromPositionStatus = (positionStatus: PositionStatus, position: TradeRouting.OpenPosition) => {
    this.isShort = positionStatus.isShortOpenPosition;
    this.size = position.uint32Qty as number;
    this.lastTradeDate = TimeUtil.toOverchukUtcBasedDate(position.tradeDate);
    this.tradeUtcTime = position.tradeUtcTime ? TimeUtil.toOverchukUtcBasedDate(position.tradeUtcTime) : null;
    this.price = position.priceCorrect;

    this.updateDisplayValues();
  };

  updateFromAccountSummary = (oteMvo: number, upl: number, mvo: number) => {
    this.oteMvo = oteMvo;
    this.upl = upl;
    this.mvo = mvo;

    this.updateDisplayValues();
  };

  updateDisplayValues = () => {
    if (!this.instrument?.displayName) {
      this.instrument?.resolveFromCmeSymbol().then((value) => {
        this.displaySymbol = value ? value : this.instrument?.title;
      });
    } else {
      this.displaySymbol = this.instrument.displayName;
    }

    this.displayCurrency = this.instrument?.currency;
    this.displayDescription = this.instrument?.description;
    this.displayLastTradeDate = this.lastTradeDate ? DisplayUtil.toDisplayDate(this.lastTradeDate) : null;
    this.displayLong = this.isShort ? null : this.size;
    this.displayShort = this.isShort ? this.size : null;
    this.displayPrice = this.price && this.instrument ? DisplayUtil.toDisplayPrice(this.price, this.instrument) : null;
    this.displayOteMvo = this.oteMvo ? this.oteMvo : null;
    this.displayUpl = this.upl ? this.upl : null;
    this.displayMvo = this.mvo ? this.mvo : null;
    this.displayStrikePrice = this.instrument?.strikePrice;
    this.displayPutCall = this.instrument?.putCallAbbrev();
  };
}

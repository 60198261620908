import { IconButton } from "@mui/material";
import { Workspace } from "../../../../types";
import DotsIcon from "../../../common/icons/dots-vertical";

const WorkspaceTab: React.FC<{
  workspace: Workspace;
  active: boolean;
  onClick: () => void;
  onMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ workspace, active, onClick, onMenuOpen }) => (
  <div className={`Tab ${active ? "active" : ""}`}>
    <button onClick={onClick}>{workspace.title || workspace.id}</button>
    <IconButton onClick={onMenuOpen} style={{ marginLeft: "8px" }} sx={{"&:focus-visible": {color: "rgb(0 0 0 / 0%)"}}}>
      <DotsIcon />
    </IconButton>
  </div>
);

export default WorkspaceTab;

import { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTradeLog } from "../../../hooks/api/tradeLog";
import { TradeLog } from "../../../hooks/api/types";
import { StyledTableCell, StyledTableRow, StyledTableRowTitle } from "./styles";
import { Paper } from "@mui/material";
import { getOrderDuration, getOrderType } from "../../../utils/utils";
import './index.css';

function Row(props: { row: TradeLog }) {
  const { row } = props;
  const [openBuyOrder, setOpenBuyOrder] = useState(false);
  const [openSellOrder, setOpenSellOrder] = useState(false);
  const buyOrderDate = new Date(row.buyOrder.statusUtcTime);
  const sellOrderDate = new Date(row.sellOrder.statusUtcTime);
  return (
    <Fragment>
      <StyledTableRowTitle sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>1 {row?.cmeSymbol?.cmeSymbol} Future</StyledTableCell>
        <StyledTableCell>P&L: ${row.realizedProfitLoss}</StyledTableCell>
        <StyledTableCell colSpan={11} />
      </StyledTableRowTitle>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenBuyOrder(!openBuyOrder);
            }}
          >
            {openBuyOrder ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {`${buyOrderDate.toDateString()} ${buyOrderDate.getHours()}:${buyOrderDate.getMinutes()}:${buyOrderDate.getSeconds()}`}
        </StyledTableCell>
        <StyledTableCell align="right">Open</StyledTableCell>
        <StyledTableCell align="right">{row.buyOrder.side == 1 ? "Buy" : "Sell"}</StyledTableCell>
        <StyledTableCell align="right">{row.instrument.putCall}</StyledTableCell>
        <StyledTableCell align="right">{row.instrument.strikePrice}</StyledTableCell>
        <StyledTableCell align="right">{row.qty}</StyledTableCell>
        <StyledTableCell align="right">{getOrderType(row.buyOrder.orderType)}</StyledTableCell>
        <StyledTableCell align="right">{row.buyOrder.limitPrice}</StyledTableCell>
        <StyledTableCell align="right">{getOrderDuration(row.buyOrder.duration)}</StyledTableCell>
        <StyledTableCell align="right">{row.buyOrder.avgFillPrice}</StyledTableCell>
        <StyledTableCell align="right">{row.buyOrder.tradeLogStopLoss}</StyledTableCell>
        <StyledTableCell align="right">{row.buyOrder.stopPrice && `$${row.buyOrder.stopPrice}`}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={openBuyOrder} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div>Approach: {row.buyOrder.tradeLogApproach}</div>
              <div>Mkt Trend:</div>
              <div>R to R:</div>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenSellOrder(!openSellOrder);
            }}
          >
            {openSellOrder ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {`${sellOrderDate.toDateString()} ${sellOrderDate.getHours()}:${sellOrderDate.getMinutes()}:${sellOrderDate.getSeconds()}`}
        </StyledTableCell>
        <StyledTableCell align="right">Close</StyledTableCell>
        <StyledTableCell align="right">{row.sellOrder.side == 1 ? "Buy" : "Sell"}</StyledTableCell>
        <StyledTableCell align="right">{row.instrument.putCall}</StyledTableCell>
        <StyledTableCell align="right">{row.instrument.strikePrice}</StyledTableCell>
        <StyledTableCell align="right">{row.qty}</StyledTableCell>
        <StyledTableCell align="right">{getOrderType(row.sellOrder.orderType)}</StyledTableCell>
        <StyledTableCell align="right">{row.sellOrder.limitPrice}</StyledTableCell>
        <StyledTableCell align="right">{getOrderDuration(row.sellOrder.duration)}</StyledTableCell>
        <StyledTableCell align="right">{row.sellOrder.avgFillPrice}</StyledTableCell>
        <StyledTableCell align="right">{row.sellOrder.tradeLogStopLoss}</StyledTableCell>
        <StyledTableCell align="right">{row.sellOrder.stopPrice && `$${row.sellOrder.stopPrice}`}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={openSellOrder} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div>Approach: {row.sellOrder.tradeLogApproach}</div>
              <div>Mkt Trend:</div>
              <div>R to R:</div>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </Fragment>
  );
}

export default function CollapsibleTable() {
  const { tradelog, loadTradeLogData } = useTradeLog();

  useEffect(() => {
    loadTradeLogData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="widget-parent-container">
        <div className="draggableCancelSelector widget-grid-container">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ position:'absolute', top: 0, bottom: 0 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell>Transaction Date and Time</StyledTableCell>
                    <StyledTableCell align="right">Open/Close</StyledTableCell>
                    <StyledTableCell align="right">Buy/Sell</StyledTableCell>
                    <StyledTableCell align="right">C/P</StyledTableCell>
                    <StyledTableCell align="right">Strike Px</StyledTableCell>
                    <StyledTableCell align="right">Qty</StyledTableCell>
                    <StyledTableCell align="right">Type</StyledTableCell>
                    <StyledTableCell align="right">Px</StyledTableCell>
                    <StyledTableCell align="right">TIF</StyledTableCell>
                    <StyledTableCell align="right">Fill Px</StyledTableCell>
                    <StyledTableCell align="right">Stop</StyledTableCell>
                    <StyledTableCell align="right">Stop Px</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {tradelog.map((row) => (
                    <Row key={row.id} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </Fragment>
  );
}

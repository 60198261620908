// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: order_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Decimal } from "./common/decimal";
import { NamedValue, Text } from "./common/shared_1";
import { Timestamp } from "./google/protobuf/timestamp";
import { ContractMetadata } from "./metadata_2";
import {
  GoMarket,
  StrategyLegFill,
  StrategyTradingParameters,
  SyntheticHang,
  SyntheticLiquidate,
  SyntheticScratch,
  SyntheticStrategyProperties,
} from "./strategy_2";
import { ModifyUserAttributes, UserAttribute } from "./user_attribute_2";
import Long from "long";

export const protobufPackage = "order_2";

/** Specifies what kind of strategy an order, a trade or a position belongs to. */
export enum SpeculationType {
  /** SPECULATION_TYPE_SPECULATION - Speculation strategy. */
  SPECULATION_TYPE_SPECULATION = 1,
  /** SPECULATION_TYPE_ARBITRAGE - Arbitrage strategy. */
  SPECULATION_TYPE_ARBITRAGE = 2,
  /** SPECULATION_TYPE_HEDGE - Hedging strategy. */
  SPECULATION_TYPE_HEDGE = 3,
  UNRECOGNIZED = -1,
}

export function speculationTypeFromJSON(object: any): SpeculationType {
  switch (object) {
    case 1:
    case "SPECULATION_TYPE_SPECULATION":
      return SpeculationType.SPECULATION_TYPE_SPECULATION;
    case 2:
    case "SPECULATION_TYPE_ARBITRAGE":
      return SpeculationType.SPECULATION_TYPE_ARBITRAGE;
    case 3:
    case "SPECULATION_TYPE_HEDGE":
      return SpeculationType.SPECULATION_TYPE_HEDGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SpeculationType.UNRECOGNIZED;
  }
}

export function speculationTypeToJSON(object: SpeculationType): string {
  switch (object) {
    case SpeculationType.SPECULATION_TYPE_SPECULATION:
      return "SPECULATION_TYPE_SPECULATION";
    case SpeculationType.SPECULATION_TYPE_ARBITRAGE:
      return "SPECULATION_TYPE_ARBITRAGE";
    case SpeculationType.SPECULATION_TYPE_HEDGE:
      return "SPECULATION_TYPE_HEDGE";
    case SpeculationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** MiFID algorithm ID type. */
export enum MiFIDAlgorithmIdType {
  /**
   * MIFID_ALGORITHM_ID_TYPE_EXTERNAL - External MiFID Algo ID, an explicit value to be sent as-is to the execution system.
   * Non-CQG WebAPI clients should always use this type.
   */
  MIFID_ALGORITHM_ID_TYPE_EXTERNAL = 1,
  /** MIFID_ALGORITHM_ID_TYPE_CQG - CQG internal MiFID Algo ID. */
  MIFID_ALGORITHM_ID_TYPE_CQG = 2,
  UNRECOGNIZED = -1,
}

export function miFIDAlgorithmIdTypeFromJSON(object: any): MiFIDAlgorithmIdType {
  switch (object) {
    case 1:
    case "MIFID_ALGORITHM_ID_TYPE_EXTERNAL":
      return MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_EXTERNAL;
    case 2:
    case "MIFID_ALGORITHM_ID_TYPE_CQG":
      return MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_CQG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MiFIDAlgorithmIdType.UNRECOGNIZED;
  }
}

export function miFIDAlgorithmIdTypeToJSON(object: MiFIDAlgorithmIdType): string {
  switch (object) {
    case MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_EXTERNAL:
      return "MIFID_ALGORITHM_ID_TYPE_EXTERNAL";
    case MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_CQG:
      return "MIFID_ALGORITHM_ID_TYPE_CQG";
    case MiFIDAlgorithmIdType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** MiFID investment decision ID type. */
export enum MiFIDInvestmentDecisionIdType {
  /** MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID - Trader MiFID short code. */
  MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID = 1,
  /** MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID - External MiFID Algo ID, an explicit value to be sent as-is to the execution system. */
  MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID = 2,
  /** MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID - CQG internal MiFID Algo ID. */
  MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID = 3,
  UNRECOGNIZED = -1,
}

export function miFIDInvestmentDecisionIdTypeFromJSON(object: any): MiFIDInvestmentDecisionIdType {
  switch (object) {
    case 1:
    case "MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID":
      return MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID;
    case 2:
    case "MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID":
      return MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID;
    case 3:
    case "MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID":
      return MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MiFIDInvestmentDecisionIdType.UNRECOGNIZED;
  }
}

export function miFIDInvestmentDecisionIdTypeToJSON(object: MiFIDInvestmentDecisionIdType): string {
  switch (object) {
    case MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID:
      return "MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID";
    case MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID:
      return "MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID";
    case MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID:
      return "MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID";
    case MiFIDInvestmentDecisionIdType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** MiFID execution decision ID type. */
export enum MiFIDExecutionDecisionIdType {
  /** MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID - Trader MiFID short code. */
  MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID = 1,
  /** MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID - External MiFID Algo ID, an explicit value to be sent as-is to the execution system. */
  MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID = 2,
  UNRECOGNIZED = -1,
}

export function miFIDExecutionDecisionIdTypeFromJSON(object: any): MiFIDExecutionDecisionIdType {
  switch (object) {
    case 1:
    case "MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID":
      return MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID;
    case 2:
    case "MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID":
      return MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MiFIDExecutionDecisionIdType.UNRECOGNIZED;
  }
}

export function miFIDExecutionDecisionIdTypeToJSON(object: MiFIDExecutionDecisionIdType): string {
  switch (object) {
    case MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID:
      return "MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID";
    case MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID:
      return "MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID";
    case MiFIDExecutionDecisionIdType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Low-level order request reject. It is sent only if direct order status updates are impossible. */
export interface OrderRequestReject {
  /** ID of the order request this reject corresponds to. */
  requestId: number;
  /**
   * If order request is rejected, this is rejection code.
   * See https://help.cqg.com/apihelp/#!Documents/rejectcodesfixconnectorderrouting.htm for the list of values.
   * Client implementation must be ready to get new values in this field.
   */
  rejectCode: number;
  /**
   * Note: use 'details' instead.
   *
   * @deprecated
   */
  textMessage?:
    | string
    | undefined;
  /** Optional reject details message. */
  details?: Text | undefined;
}

/** It is sent only for OrderRequest actions: LiquidateAll, CancelAll, GoFlat. */
export interface OrderRequestAck {
  /** ID of the order request this acknowledgement corresponds to. */
  requestId?:
    | number
    | undefined;
  /** Time when OrderRequest action has been processed. */
  when?: Date | undefined;
}

/** Status of order. */
export interface OrderStatus {
  /** List of trade subscription IDs this status is related to. */
  subscriptionIds: number[];
  /**
   * True if this is a snapshot related message.
   * Since snapshot might be sent in several messages (including none),
   * client should use TradeSnapshotCompletion message as
   * an indicator of complete snapshot delivery for a particular subscription.
   */
  isSnapshot?:
    | boolean
    | undefined;
  /** This field is associated with shared_1.OrderStatus.Status enum type. */
  status: number;
  /**
   * Order ID assigned by server.
   * It is changed by server after each modify request acknowledgment.
   * Some fields and nested messages of OrderStatus message may be different
   * for different accounts for the same order (i.e. same order_id).
   */
  orderId: string;
  /** Order ID assigned by server to originally placed order. It stays the same regardless of modification requests. */
  chainOrderId: string;
  /** Order ID assigned by execution system (e.g. exchange). */
  execOrderId?:
    | string
    | undefined;
  /**
   * Id used to determine a user's order in Detailed DOM.
   * Is unique for an exchange. contract_id + detailed_dom_order_id pair is unique among all orders of a user.
   */
  detailedDomOrderId?:
    | string
    | undefined;
  /**
   * Note: use status_utc_timestamp field instead.
   *
   * @deprecated
   */
  statusUtcTime?:
    | number
    | undefined;
  /**
   * Last order change time from server perspective (UTC).
   * It is not guaranteed that order status (e.g. from different order chain) which comes later has bigger timestamp.
   */
  statusUtcTimestamp:
    | Date
    | undefined;
  /**
   * Note: use submission_utc_timestamp field instead.
   *
   * @deprecated
   */
  submissionUtcTime?:
    | number
    | undefined;
  /** Time when original order was submitted to the execution system by server (UTC). */
  submissionUtcTimestamp:
    | Date
    | undefined;
  /**
   * Note: use fill_qty field instead.
   *
   * @deprecated
   */
  uint32FillQty?:
    | number
    | undefined;
  /** Filled quantity. */
  fillQty?:
    | Decimal
    | undefined;
  /** Number of fill events. */
  fillCnt: number;
  /**
   * Average fill price.
   * NOTE: this price is aligned by ContractMetadata.correct_price_scale so don't use it for OTE/UPL calculation but
   * use prices from individual TRADE elements. See also avg_fill_price_correct field.
   */
  scaledAvgFillPrice: number;
  /**
   * Average fill price in correct price format.
   * Could have higher precision than avg_fill_price multiplied by correct_price_scale.
   */
  avgFillPriceCorrect: number;
  /**
   * Note: use active_at_utc_timestamp field instead.
   *
   * @deprecated
   */
  activeAtUtcTime?:
    | number
    | undefined;
  /** Time when order should be submitted to execution system as assigned by server (UTC). */
  activeAtUtcTimestamp?:
    | Date
    | undefined;
  /**
   * Note: use remaining_qty field instead.
   *
   * @deprecated
   */
  uint32RemainingQty?:
    | number
    | undefined;
  /**
   * Remaining (unfilled) order quantity. It is not always equal
   * to order size minus filled size (for example, busted orders violate that).
   */
  remainingQty?:
    | Decimal
    | undefined;
  /** Order with updated attributes. Server may not send it if order attributes remained the same since last update. */
  order?:
    | Order
    | undefined;
  /**
   * List of [last] transactions.
   * In case of initial snapshot (if last_orders_update_utc_time was not set in TradeSubscription)
   * all available transactions are sent. This list can be empty if this status is sent to update order attributes only
   * (e.g. to update prices of trailing orders).
   * It is guaranteed that this list is sorted by transaction identifier (TransactionStatus.trans_id).
   */
  transactionStatuses: TransactionStatus[];
  /**
   * Time of the latest REJECTED, REJECT_CANCEL or REJECT_MODIFY transaction, if any (UTC).
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   */
  rejectUtcTimestamp?:
    | Date
    | undefined;
  /**
   * Message of the latest REJECTED, REJECT_CANCEL or REJECT_MODIFY transaction, if any.
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   */
  rejectMessage?:
    | string
    | undefined;
  /** Time of the latest ACK_CANCEL transaction, if any (UTC). */
  cancelUtcTimestamp?:
    | Date
    | undefined;
  /** Time of the latest FILL transaction, if any (UTC). */
  fillUtcTimestamp?:
    | Date
    | undefined;
  /**
   * Whether there is FILL_CORRECT with updated quantity. Canceled and busted fills are ignored.
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   *
   * @deprecated
   */
  hasFillWithModifiedQty?:
    | boolean
    | undefined;
  /**
   * Whether there is FILL_CORRECT with updated price. Canceled and busted fills are ignored.
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   *
   * @deprecated
   */
  hasFillWithModifiedPrice?:
    | boolean
    | undefined;
  /** User who placed the order, or user on whose behalf the order was placed. */
  enteredByUser: string;
  /** Statement date when this order was submitted. */
  firstStatementDate: number;
  /**
   * Last statement date this order belongs to. It is set when order is cleaned by a statement report or other event
   * so that order is not 'current' any longer. For example, canceled synthetic strategy leg orders without fills
   * may be cleared before the end of 'current' day.
   */
  lastStatementDate?:
    | number
    | undefined;
  /**
   * Multiple OrderStatus and PositionStatus messages in a single ServerMsg can reference the same contract.
   * If the contract is unknown to the client before this ServerMsg,
   * contract meta-data will be added in at least one of them.
   * If in your code you process orders first and positions second then during processing orders you may
   * encounter order with yet unknown contract_id.
   * In this case you should look for matching contract metadata in positions.
   * The opposite is also true: contract metadata for positions can be in order status messages.
   */
  contractMetadata: ContractMetadata[];
  /**
   * Id of an account for this order status.
   * Some fields and nested messages of OrderStatus message may be different
   * for different accounts for the same order (i.e. same order_id).
   */
  accountId: number;
  /** If the order is part of a compound order then this field describe the structure of the compound. */
  compoundOrderStructure?:
    | CompoundOrderStructure
    | undefined;
  /** The number of active (not resolved) hangs on this synthetic strategy order. */
  hangCount?:
    | number
    | undefined;
  /** Synthetic order's outright properties */
  strategyProperties?:
    | SyntheticStrategyProperties
    | undefined;
  /** Cross transaction ID assigned by exchange. */
  crossTransactionId?:
    | string
    | undefined;
  /** Exchange specific extra attributes. */
  exchangeExtraAttributes: NamedValue[];
  /**
   * Cumulative commission charged for the order fills.
   * CQG trade routing system might not provide this information for some contracts.
   */
  fillCommission?:
    | Commission
    | undefined;
  /** Order identifier assigned by server when sending the order to execution system. */
  routeClOrderId?:
    | string
    | undefined;
  /**
   * Actual value of MiFID Execution Within Firm associated with
   * initial or acknowledged modify order request of this order chain for execution in this account
   */
  mifidExecutionDecision?:
    | string
    | undefined;
  /** Indicates whether MiFID Execution decision identifies a person (false) or algorithm (true) */
  mifidExecutionDecisionIsAlgo?:
    | boolean
    | undefined;
  /**
   * Actual value of MiFID Investment Decision Within Firm associated with
   * initial or acknowledged modify order request of this order chain.
   */
  mifidInvestmentDecision?:
    | string
    | undefined;
  /** Indicates whether MiFID investment decision identifies a person (false) or algorithm (true) */
  mifidInvestmentDecisionIsAlgo?:
    | boolean
    | undefined;
  /** Message originator location (i.e. geographic location) */
  senderLocationId?:
    | string
    | undefined;
  /** Message originator identifier (desk, trader etc) */
  senderOperatorId?:
    | string
    | undefined;
  /** Applied effective value of message originator identifier */
  operatorId?:
    | string
    | undefined;
  /** Identifier that is used to match two filled care orders. */
  fillCareOrderRequestId?: string | undefined;
}

/**
 * Status of a specific transaction.
 * All attributes are at the transaction moment.
 */
export interface TransactionStatus {
  /** This field is associated with shared_1.TransactionStatus.Status enum type. */
  status: number;
  /**
   * Order transaction identifier.
   * For all transactions within order chain it is guaranteed that:
   * 1) Transaction identifier is unique.
   * 2) The later transaction has greater transaction identifier than the earlier one.
   */
  transId: number;
  /**
   * Transaction time assigned by server (UTC).
   * Note: use trans_utc_timestamp field instead.
   *
   * @deprecated
   */
  transUtcTime?:
    | number
    | undefined;
  /** Transaction time assigned by server (UTC). */
  transUtcTimestamp:
    | Date
    | undefined;
  /** Reference transaction ID to the previous fill transaction for a bust, cancel or correction. */
  refTransId?:
    | number
    | undefined;
  /** If order is modified, this client order ID of the order before modification. */
  origClOrderId?:
    | string
    | undefined;
  /** Client order ID of the order at the transaction moment. */
  clOrderId: string;
  /**
   * Note: use fill_qty field instead.
   *
   * @deprecated
   */
  uint32FillQty?:
    | number
    | undefined;
  /** Filled quantity for fill transactions or updated quantity for fill correction. */
  fillQty?:
    | Decimal
    | undefined;
  /** Fill price for fill transactions or updated price for fill correction. */
  scaledFillPrice?:
    | number
    | undefined;
  /** List of specific per leg trades (more than one for strategies). */
  trades: Trade[];
  /**
   * List of leg order fills that contribute to a synthetic strategy order fill.
   * Present if the transaction is of type FILL or FILL_CORRECT and this order is a synthetic strategy order.
   * Mutually exclusive with 'trade' attribute.
   */
  strategyLegFills: StrategyLegFill[];
  /** Details of the hang */
  syntheticHang?:
    | SyntheticHang
    | undefined;
  /**
   * Note: use prev_order_qty field instead.
   *
   * @deprecated
   */
  uint32PrevOrderQty?:
    | number
    | undefined;
  /** If order is modified, this is the previous order size. */
  prevOrderQty?:
    | Decimal
    | undefined;
  /**
   * Note: use order_qty field instead.
   *
   * @deprecated
   */
  uint32OrderQty?:
    | number
    | undefined;
  /** If order is modified, this is the updated order size. */
  orderQty?:
    | Decimal
    | undefined;
  /** If order is modified, this is the previous order limit price. */
  scaledPrevLimitPrice?:
    | number
    | undefined;
  /** If order is modified, this is the updated order limit price. */
  scaledLimitPrice?:
    | number
    | undefined;
  /** If order is modified, this is the previous order stop price. */
  scaledPrevStopPrice?:
    | number
    | undefined;
  /** If order is modified, this is the updated order stop price. */
  scaledStopPrice?:
    | number
    | undefined;
  /**
   * Note: use prev_visible_qty field instead.
   *
   * @deprecated
   */
  uint32PrevVisibleQty?:
    | number
    | undefined;
  /** If order is modified, this is the previous visible order size. */
  prevVisibleQty?:
    | Decimal
    | undefined;
  /**
   * Note: use visible_qty field instead.
   *
   * @deprecated
   */
  uint32VisibleQty?:
    | number
    | undefined;
  /** If order is modified, this is the updated visible order size. */
  visibleQty?:
    | Decimal
    | undefined;
  /**
   * Note: use prev_min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32PrevMinVisibleQty?:
    | number
    | undefined;
  /** If order is modified, this is the previous min visible order size. */
  prevMinVisibleQty?:
    | Decimal
    | undefined;
  /**
   * Note: use min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty?:
    | number
    | undefined;
  /** If order is modified, this is the updated min visible order size. */
  minVisibleQty?:
    | Decimal
    | undefined;
  /**
   * Note: use prev_fill_qty field instead.
   *
   * @deprecated
   */
  uint32PrevFillQty?:
    | number
    | undefined;
  /** If fill is modified, the previous size is reported here. */
  prevFillQty?:
    | Decimal
    | undefined;
  /** If fill is modified, the previous price is reported here. */
  scaledPrevFillPrice?:
    | number
    | undefined;
  /**
   * If order is modified, this is the previous order type.
   * This field is associated with Order.OrderType enum type.
   */
  prevOrderType?:
    | number
    | undefined;
  /**
   * If order is modified, this is the updated order type.
   * This field is associated with Order.OrderType enum type.
   */
  orderType?:
    | number
    | undefined;
  /**
   * If order is modified, these are the previous exec instructions.
   * This field is associated with Order.ExecInstruction enum type.
   */
  prevExecInstructions: number[];
  /**
   * If order is modified, these are the updated exec instructions.
   * This field is associated with Order.ExecInstruction enum type.
   */
  execInstructions: number[];
  /**
   * If order is modified, this is the previous duration.
   * This field is associated with Order.Duration enum type.
   */
  prevDuration?:
    | number
    | undefined;
  /**
   * If order is modified, this is the new duration.
   * This field is associated with Order.Duration enum type.
   */
  duration?:
    | number
    | undefined;
  /** If order is modified, this is the previous date then the order should expire  (see Order.good_thru_date). */
  prevGoodThruDate?:
    | number
    | undefined;
  /**
   * If order is modified, this is the updated date then the order should expire  (see Order.good_thru_date).
   * The value of duration field must be set to GTD when this date value is specified.
   */
  goodThruDate?:
    | number
    | undefined;
  /**
   * If order request is rejected, this is rejection code.
   * See https://help.cqg.com/apihelp/#!Documents/rejectcodesfixconnectorderrouting.htm for the list of values.
   */
  rejectCode?:
    | number
    | undefined;
  /** If present, identifies the counterpart of the order fill, as reported by the execution system. */
  fillCounterparty?:
    | string
    | undefined;
  /** Order identifier assigned by server when sending the order to execution system. */
  routeClOrderId?:
    | string
    | undefined;
  /** Additional transaction details. */
  textMessage?:
    | string
    | undefined;
  /**
   * If order is modified, this is the previous time then the order should expire  (see Order.good_thru_utc_time).
   * Note: use prev_good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  prevGoodThruUtcTime?:
    | number
    | undefined;
  /** If order is modified, this is the previous time then the order should expire  (see Order.good_thru_utc_timestamp). */
  prevGoodThruUtcTimestamp?:
    | Date
    | undefined;
  /**
   * If order is modified, this is the updated time then the order should expire  (see Order.good_thru_utc).
   * The value of duration field must be set to GTT when this time value is specified.
   * Note: use good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  goodThruUtcTime?:
    | number
    | undefined;
  /**
   * If order is modified, this is the updated time then the order should expire  (see Order.good_thru_utc).
   * The value of duration field must be set to GTT when this time value is specified.
   */
  goodThruUtcTimestamp?:
    | Date
    | undefined;
  /** True if fill was made as aggressive. False if fill was made as passive. Abscence of value means undefined. */
  isAggressive?:
    | boolean
    | undefined;
  /** OrderRequest.client_regulatory_algorithm_id value echoed back from the latest order request. */
  clientRegulatoryAlgorithmId?:
    | number
    | undefined;
  /**
   * Regulatory Algorithm ID actually sent to the exchange for this order, if any.
   * Will differ from client_regulatory_algorithm_id if any CQG algorithm was applied to the order.
   */
  effectiveRegulatoryAlgorithmId?:
    | number
    | undefined;
  /** MiFID algorithm ID provided with this order request, contents depend on mifid_algorithm_id_type. */
  mifidAlgorithmId?:
    | string
    | undefined;
  /** Defines the type of mifid_algorithm_id reported. One of MiFIDAlgorithmIdType enum. */
  mifidAlgorithmIdType?:
    | number
    | undefined;
  /**
   * The MiFID algorithm ID associated with the order request for execution in this account.
   * It may have no relation to mifid_algorithm_id, e.g. in case client supplied a mifid_algorithm_id
   * and then a server-side algorithm was used, its identifier would be applied to the order instead.
   */
  mifidAppliedAlgorithmId?:
    | string
    | undefined;
  /** The type of mifid_applied_algorithm_id reported. One of MiFIDAlgorithmIdType enum. */
  mifidAppliedAlgorithmIdType?:
    | number
    | undefined;
  /**
   * Actual value of MiFID Execution Within Firm associated with this order for execution in this account,
   * reflects FCM + route short code mapping if configured.
   */
  mifidExecutionDecision?:
    | string
    | undefined;
  /** Indicates whether MiFID Execution decision identifies a person (false) or algorithm (true) */
  mifidExecutionDecisionIsAlgo?:
    | boolean
    | undefined;
  /**
   * Open/close effect of the order fill transaction as a result of the corresponding open_close_instruction.
   * One of OpenCloseInstruction enum.
   */
  openCloseEffect?:
    | number
    | undefined;
  /** Speculation type of the transaction. One of SpeculationType enum. */
  speculationType?:
    | number
    | undefined;
  /**
   * Commission charged for the fill.
   * CQG trade routing system might not provide this information for some contracts.
   */
  fillCommission?:
    | Commission
    | undefined;
  /** Decision Within Firm associated with this order. */
  mifidInvestmentDecision?:
    | string
    | undefined;
  /** Indicates whether MiFID investment decision identifies a person (false) or algorithm (true) */
  mifidInvestmentDecisionIsAlgo?:
    | boolean
    | undefined;
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code
   */
  executionSourceCode?:
    | string
    | undefined;
  /**
   * True if the order with cl_order_id the same as cl_order_id from the
   * current transaction is automatic, manual (the order was sent after the
   * user direct action) otherwise.
   * Different transaction statuses of the same order (or orders in the same
   * chain, see OrderStatus.chain_order_id) may have different values
   * of the flag, depending on the nature of the corresponding order action.
   */
  isAutomated?:
    | boolean
    | undefined;
  /** Identifier assigned to a trade by a matching system. */
  tradeMatchId?:
    | string
    | undefined;
  /** Identifier that is used to match two filled care orders. */
  fillCareOrderRequestId?:
    | string
    | undefined;
  /**
   * User who modified or canceled the order, or user on whose behalf the order was modified or canceled.
   * It is present in the following cases:
   * 1. For real time events:
   *    - If transaction status is IN_TRANSIT, SUSPEND or ACTIVEAT;
   *    - If a trader who initiated this transaction is different from the trader who initiated the previous
   *      transaction, and if the current transaction has one of the following statuses:
   *      IN_MODIFY, IN_CANCEL, REJECT_MODIFY, REJECT_CANCEL, or UPDATE (when available).
   * 2. For past events:
   *    - If transaction has one of these statuses: IN_TRANSIT, SUSPEND, ACTIVEAT, IN_MODIFY, IN_CANCEL, REJECT_MODIFY,
   *      REJECT_CANCEL or UPDATE (when available).
   */
  username?: string | undefined;
}

/** Trade per specific outright contract. */
export interface Trade {
  /** Trade ID assigned by server, unique within account. */
  tradeId: string;
  /** Server contract identifier. */
  contractId: number;
  /** Brokerage statement date this trade corresponds to (in time format, convert to date only). */
  statementDate: number;
  /**
   * Time of the trade (UTC).
   * Note: use trade_utc_timestamp field instead.
   *
   * @deprecated
   */
  tradeUtcTime?:
    | number
    | undefined;
  /** Time of the trade (UTC). */
  tradeUtcTimestamp:
    | Date
    | undefined;
  /** Exchange trade day this trade corresponds to (in time format, date only value). */
  tradeDate: number;
  /**
   * Trade price in native integer format.
   * price = round(price_correct / correct_price_scale).
   */
  scaledPrice: number;
  /** Trade price in correct price format. Could have higher precision than price multiplied by correct_price_scale. */
  priceCorrect: number;
  /**
   * Trade side.
   * This field is associated with Order.Side enum type.
   */
  side: number;
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty?:
    | number
    | undefined;
  /** Trade size. */
  qty?:
    | Decimal
    | undefined;
  /**
   * If present, identifies the counter-party of the trade, as reported by the execution system
   * (may or may not match counter-party reported for the order fill).
   */
  tradeCounterparty?:
    | string
    | undefined;
  /** True if trade was made as aggressive. False if trade was made as passive. Abscence of value means undefined. */
  isAggressive?:
    | boolean
    | undefined;
  /** Execution id of the leg fill */
  legExecutionId?:
    | string
    | undefined;
  /**
   * Open/close effect of the trade as a result of the corresponding open_close_instruction.
   * One of OpenCloseInstruction enum.
   */
  openCloseEffect?:
    | number
    | undefined;
  /** Speculation type of the trade. One of SpeculationType enum. */
  speculationType?:
    | number
    | undefined;
  /** Identifier assigned to a trade by a matching system. */
  tradeMatchId?:
    | string
    | undefined;
  /**
   * When this field is present, the contract_id field contains the server contract identifier of a relative
   * symbol (e.g., in case of relative daily futures), and the specific_contract_id contains the server
   * contract identifier of the specific symbol (e.g. the specific instrument mapped to the relative daily
   * future at the moment of this trade).
   */
  specificContractId?:
    | number
    | undefined;
  /** List of fill extra attributes. */
  fillExtraAttributes: NamedValue[];
}

export interface Commission {
  /** Commission currency code (ISO 4217 based). */
  commissionCurrency: string;
  /** Commission. */
  commission: number;
}

/** Compound order structure. */
export interface CompoundOrderStructure {
  /**
   * This field is associated with CompoundOrder.Type enum type.
   * Type of the compound order.
   */
  type: number;
  /** ID of the compound order assigned by client. */
  clCompoundId: string;
  /** List of entires. */
  compoundOrderEntries: CompoundOrderStructureEntry[];
  /**
   * Offset in ticks from fill price.
   * New order price will be calculated as
   * (fill price + offset) for buy master orders and (fill price - offset) for sell master orders.
   */
  profitOffset?:
    | number
    | undefined;
  /**
   * Offset in ticks from fill price.
   * New order price will be calculated as
   * (fill price - offset) for buy master orders and (fill price + offset) for sell master orders.
   */
  lossOffset?:
    | number
    | undefined;
  /**
   * Offset in ticks from loss_offset for stop limit orders.
   * The offset determines the limit price for the order the stop limit becomes when the stop price is hit.
   */
  stopLimitOffset?: number | undefined;
}

/** Entry of a compound order structure. One of optional fields is specified. */
export interface CompoundOrderStructureEntry {
  /** Regular order id as assigned by server. */
  chainOrderId?:
    | string
    | undefined;
  /** Sub-compound order structure entry. */
  compoundOrderStructure?: CompoundOrderStructure | undefined;
}

/** Trade routing order. It is used as new order request and as a part of order status updates. */
export interface Order {
  /** Id of an account that is/ was used to place an order. */
  accountId: number;
  /**
   * Client side time when an order was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime?:
    | number
    | undefined;
  /**
   * Client side time when an order was submitted (UTC).
   * If specified by the user it's used instead of when_utc_time field
   * (at least one of these fields has to be specified).
   */
  whenUtcTimestamp?:
    | Date
    | undefined;
  /** Server side contract Id of the order (see ContractMetadata message). */
  contractId: number;
  /**
   * Client order identifier.
   * It must be unique within a single trading day for day orders and across days for multi-day orders.
   * Maximum allowed length is 64 bytes.
   * In OrderStatus messages it contains cl_order_id of the last accepted order request in the order chain,
   * which may be a cancel request.
   */
  clOrderId: string;
  /**
   * Order type.
   * This field is associated with OrderType enum type.
   */
  orderType: number;
  /**
   * List of applicable execution instructions.
   * This field is associated with ExecInstruction enum type.
   */
  execInstructions: number[];
  /**
   * Order duration.
   * This field is associated with Duration enum type.
   */
  duration: number;
  /** Date for GTD (local to exchange, date only value in time format) when the order should expire. */
  goodThruDate?:
    | number
    | undefined;
  /**
   * Order side.
   * This field is associated with Side enum type.
   * This field is required for all order types except for ORDER_TYPE_CROSS.
   * For orders with ORDER_TYPE_CROSS order type this field is not relevant and ignored.
   * See CrossOrderParameters.side_allocation field instead.
   */
  side?:
    | number
    | undefined;
  /** Limit price of the order (required for Limit and Stop-limit orders). */
  scaledLimitPrice?:
    | number
    | undefined;
  /** Stop price of the order (required for Stop and Stop-limit orders). */
  scaledStopPrice?:
    | number
    | undefined;
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty?:
    | number
    | undefined;
  /**
   * Note: use visible_qty field instead.
   *
   * @deprecated
   */
  uint32VisibleQty?:
    | number
    | undefined;
  /**
   * Note: use min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty?:
    | number
    | undefined;
  /** Total positive size of the order. */
  qty?:
    | Decimal
    | undefined;
  /** Visible size of the order for Icebergs. */
  visibleQty?:
    | Decimal
    | undefined;
  /**
   * Minimum visible positive size of the order
   * used for iceberg orders with 'random visible size',
   * must be < visible_qty.
   */
  minVisibleQty?:
    | Decimal
    | undefined;
  /**
   * True if this is a manual order (order placed by the user direct action), automated otherwise.
   * Deprecated, use OrderRequest.is_automated to set a request source,
   * or TransactionStatus.is_automated to read the value for an order.
   *
   * @deprecated
   */
  isManual?:
    | boolean
    | undefined;
  /**
   * True if order is used for closing/ reducing a position, opening/ increasing otherwise
   * (omit this field if there is no need to be explicit). Deprecated, refer to open_close_instruction.
   *
   * @deprecated
   */
  isClose?:
    | boolean
    | undefined;
  /** True if the order is aggressive (Fixed Income specific order modifier). */
  isAggressive?:
    | boolean
    | undefined;
  /** Maximum offset between market and limit prices or market and stop prices for trail orders. */
  scaledTrailOffset?:
    | number
    | undefined;
  /**
   * Trailing peg to follow specific side of the market (for limit orders only).
   * This field is associated with TrailingPeg enum type.
   * Note: order request is rejected if this field is set for stop or stop-limit order.
   */
  trailingPeg?:
    | number
    | undefined;
  /**
   * Note: use trigger_qty instead.
   *
   * @deprecated
   */
  uint32TriggerQty?:
    | number
    | undefined;
  /** Trigger quantity (threshold) as additional stop order activation condition. */
  triggerQty?:
    | Decimal
    | undefined;
  /**
   * Time when order should be submitted to execution system as assigned by client (UTC).
   * Note: use activation_utc_timestamp field instead.
   *
   * @deprecated
   */
  activationUtcTime?:
    | number
    | undefined;
  /**
   * Time when order should be submitted to execution system as assigned by client (UTC).
   * If specified by the user it's used instead of activation_utc_time field.
   */
  activationUtcTimestamp?:
    | Date
    | undefined;
  /**
   * Time when order should be suspended (parked) (UTC).
   * Note: use suspension_utc_timestamp field instead.
   *
   * @deprecated
   */
  suspensionUtcTime?:
    | number
    | undefined;
  /**
   * Time when order should be suspended (parked) (UTC).
   * If specified by the user it's used instead of suspension_utc_time field.
   */
  suspensionUtcTimestamp?:
    | Date
    | undefined;
  /**
   * User specific attributes.
   * Only printable ASCII characters are allowed in "name" and "value" fields including 0-9A-Za-z, punctuation.
   * (ASCII character set used by the "C" locale with codes greater than 0x1f (US), except 0x7f (DEL).)
   * Violation of the fields formatting leads to rejection of requests that contain UserAttribute.
   * Note that despite punctuation is allowed, it can cause issues with 3rd party tools and formats like CSV.
   * Order comment is provided for an order via UserAttribute with "name" set to "comment" and "value" set to
   * the order comment. Depending on order route, a limit on order comment length can be imposed.
   * If that limit is exceeded, order comment is skipped, but order is not rejected.
   */
  userAttributes: UserAttribute[];
  /**
   * List of order extra attributes.
   * Such attributes are used for Pre-Trade Mid-Market Mark, Algo Strategies and other purposes.
   */
  extraAttributes: NamedValue[];
  /** Case-sensitive identifier (abbreviation) of algorithm used to execute the order (e.g. VWAP, TWAP, etc.). */
  algoStrategy?:
    | string
    | undefined;
  /**
   * Date and time for GTT order (UTC) when the order should expire.
   * Note: use good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  goodThruUtcTime?:
    | number
    | undefined;
  /**
   * Date and time for GTT order (UTC) when the order should expire.
   * If specified by the user it's used instead of good_thru_utc_time field.
   */
  goodThruUtcTimestamp?:
    | Date
    | undefined;
  /** Flag used by the client for marking the user attributes checked/unchecked. */
  isUserAttributeChecked?:
    | boolean
    | undefined;
  /**
   * Synthetic strategy trading parameters, applicable only if contract_id designates a synthetic strategy.
   * If the parameter is not applicable for the order the default value is used.
   */
  strategyTradingParameters?:
    | StrategyTradingParameters
    | undefined;
  /**
   * Specifies what the order is intended to do: open or close (reduce) a position. One of OpenCloseInstruction enum.
   * Applicable only if ContractMetadata.position_tracking is either
   * LONG_SHORT_WITH_IMPLIED_CLOSE or LONG_SHORT_WITH_EXPLICIT_CLOSE.
   */
  openCloseInstruction?:
    | number
    | undefined;
  /** Speculation type of the order. One of SpeculationType enum. */
  speculationType?:
    | number
    | undefined;
  /** Optional OTC contributor involved into the order processing. */
  contributorId?:
    | string
    | undefined;
  /**
   * FIA Execution Source Code value for the placement.
   * Overrides the value CQG would otherwise automatically assign
   * to the operation based on trader-account configuration.
   * Refer to https://www.fia.org/articles/fia-and-fia-tech-release-guidelines-simplified-execution-source-code
   * Possible values:
   * W - Desk
   * Y - Electronic (Default)
   * C - Vendor-provided Platform billed by Executing Broker
   * G - Sponsored Access via Exchange API or FIX provided by Executing Broker
   * H - Premium Algorithmic Trading Provider billed by Executing Broker
   * D - Other, including Other-provided Screen
   */
  executionSourceCode?:
    | string
    | undefined;
  /**
   * Cross order parameters.
   * It must be specified if order_type is ORDER_TYPE_CROSS.
   */
  crossOrderParameters?:
    | CrossOrderParameters
    | undefined;
  /**
   * Unique identifier of account within the exchange's Member to place order on it.
   * This account can be external for CQG. Placing order on such account may require
   * providing additional exchange-specific information via Order.extra_attributes field.
   * See order_2.ExternalAccount.external_account_number and trading_account_2.Account.allow_external_accounts.
   */
  externalAccountNumber?:
    | string
    | undefined;
  /** Specifies that the order is Care order. */
  isCareOrder?: boolean | undefined;
}

export enum Order_Side {
  /** SIDE_BUY - Describes purchasing side of a trade. */
  SIDE_BUY = 1,
  /** SIDE_SELL - Describes selling side of a trade. */
  SIDE_SELL = 2,
  UNRECOGNIZED = -1,
}

export function order_SideFromJSON(object: any): Order_Side {
  switch (object) {
    case 1:
    case "SIDE_BUY":
      return Order_Side.SIDE_BUY;
    case 2:
    case "SIDE_SELL":
      return Order_Side.SIDE_SELL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Order_Side.UNRECOGNIZED;
  }
}

export function order_SideToJSON(object: Order_Side): string {
  switch (object) {
    case Order_Side.SIDE_BUY:
      return "SIDE_BUY";
    case Order_Side.SIDE_SELL:
      return "SIDE_SELL";
    case Order_Side.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Order_OrderType {
  /** ORDER_TYPE_MKT - Market order, buy or sell by the best available opposite price. */
  ORDER_TYPE_MKT = 1,
  /** ORDER_TYPE_LMT - Limit order, buy or sell by price that is the same or better then specified limit price. */
  ORDER_TYPE_LMT = 2,
  /**
   * ORDER_TYPE_STP - Stop order, Order becomes a Market when market reaches order's stop price
   * (which is on opposite side of market).
   */
  ORDER_TYPE_STP = 3,
  /** ORDER_TYPE_STL - Stop-limit order, Order becomes a Limit when market reaches order's stop price. */
  ORDER_TYPE_STL = 4,
  /** ORDER_TYPE_CROSS - Cross order type. See also CrossOrderParameters message. */
  ORDER_TYPE_CROSS = 5,
  UNRECOGNIZED = -1,
}

export function order_OrderTypeFromJSON(object: any): Order_OrderType {
  switch (object) {
    case 1:
    case "ORDER_TYPE_MKT":
      return Order_OrderType.ORDER_TYPE_MKT;
    case 2:
    case "ORDER_TYPE_LMT":
      return Order_OrderType.ORDER_TYPE_LMT;
    case 3:
    case "ORDER_TYPE_STP":
      return Order_OrderType.ORDER_TYPE_STP;
    case 4:
    case "ORDER_TYPE_STL":
      return Order_OrderType.ORDER_TYPE_STL;
    case 5:
    case "ORDER_TYPE_CROSS":
      return Order_OrderType.ORDER_TYPE_CROSS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Order_OrderType.UNRECOGNIZED;
  }
}

export function order_OrderTypeToJSON(object: Order_OrderType): string {
  switch (object) {
    case Order_OrderType.ORDER_TYPE_MKT:
      return "ORDER_TYPE_MKT";
    case Order_OrderType.ORDER_TYPE_LMT:
      return "ORDER_TYPE_LMT";
    case Order_OrderType.ORDER_TYPE_STP:
      return "ORDER_TYPE_STP";
    case Order_OrderType.ORDER_TYPE_STL:
      return "ORDER_TYPE_STL";
    case Order_OrderType.ORDER_TYPE_CROSS:
      return "ORDER_TYPE_CROSS";
    case Order_OrderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Order_ExecInstruction {
  /**
   * EXEC_INSTRUCTION_NONE - None (this means "plain order without any exec instructions").
   * This value shall not be explicitly provided in OrderRequest.
   */
  EXEC_INSTRUCTION_NONE = 12,
  /** EXEC_INSTRUCTION_AON - All or None (fill order only completely). */
  EXEC_INSTRUCTION_AON = 1,
  /** EXEC_INSTRUCTION_ICEBERG - Iceberg (show only part of order size). */
  EXEC_INSTRUCTION_ICEBERG = 2,
  /** EXEC_INSTRUCTION_QT - Quantity triggered (aka DOM Triggered, honor additional quantity threshold for triggering). */
  EXEC_INSTRUCTION_QT = 3,
  /** EXEC_INSTRUCTION_TRAIL - Trailing order (price of the order is following market one direction by specific offset). */
  EXEC_INSTRUCTION_TRAIL = 4,
  /** EXEC_INSTRUCTION_FUNARI - Funari (Limit order becomes a Market on Close). */
  EXEC_INSTRUCTION_FUNARI = 5,
  /** EXEC_INSTRUCTION_MIT - Market if Touched (Limit order becomes a Market when market reaches order's limit price). */
  EXEC_INSTRUCTION_MIT = 6,
  /**
   * EXEC_INSTRUCTION_MLM - Market Limit Market is a limit order that is used to place a buy order above the best offer
   * to fill by the best offer or a sell order below the best bid to fill by the best bid.
   */
  EXEC_INSTRUCTION_MLM = 7,
  /**
   * EXEC_INSTRUCTION_POSTONLY - Post-only order. Ensures the limit order will be added to the order book and not match with
   * a pre-existing order.
   */
  EXEC_INSTRUCTION_POSTONLY = 8,
  /** EXEC_INSTRUCTION_MTL - Market with leftover as Limit (market order then unexecuted quantity becomes limit order at last price). */
  EXEC_INSTRUCTION_MTL = 10,
  /**
   * EXEC_INSTRUCTION_AUCTION - An auction order is an order to buy or sell in the market at the Calculated Opening Price (COP).
   * Unmatched auction orders are converted to limit orders on the market open.
   */
  EXEC_INSTRUCTION_AUCTION = 11,
  /** EXEC_INSTRUCTION_ATANYPRICE - At Any Price Orders are US-Style Market Orders. */
  EXEC_INSTRUCTION_ATANYPRICE = 13,
  /** EXEC_INSTRUCTION_LMT_PRARGD - Limit order with prearranged transaction flag (IntentToCross) set. */
  EXEC_INSTRUCTION_LMT_PRARGD = 14,
  /**
   * EXEC_INSTRUCTION_STM - Stop the Market.
   * Used when a firm has an order that they want to fill internally, but only for prices that are in the market.
   */
  EXEC_INSTRUCTION_STM = 15,
  UNRECOGNIZED = -1,
}

export function order_ExecInstructionFromJSON(object: any): Order_ExecInstruction {
  switch (object) {
    case 12:
    case "EXEC_INSTRUCTION_NONE":
      return Order_ExecInstruction.EXEC_INSTRUCTION_NONE;
    case 1:
    case "EXEC_INSTRUCTION_AON":
      return Order_ExecInstruction.EXEC_INSTRUCTION_AON;
    case 2:
    case "EXEC_INSTRUCTION_ICEBERG":
      return Order_ExecInstruction.EXEC_INSTRUCTION_ICEBERG;
    case 3:
    case "EXEC_INSTRUCTION_QT":
      return Order_ExecInstruction.EXEC_INSTRUCTION_QT;
    case 4:
    case "EXEC_INSTRUCTION_TRAIL":
      return Order_ExecInstruction.EXEC_INSTRUCTION_TRAIL;
    case 5:
    case "EXEC_INSTRUCTION_FUNARI":
      return Order_ExecInstruction.EXEC_INSTRUCTION_FUNARI;
    case 6:
    case "EXEC_INSTRUCTION_MIT":
      return Order_ExecInstruction.EXEC_INSTRUCTION_MIT;
    case 7:
    case "EXEC_INSTRUCTION_MLM":
      return Order_ExecInstruction.EXEC_INSTRUCTION_MLM;
    case 8:
    case "EXEC_INSTRUCTION_POSTONLY":
      return Order_ExecInstruction.EXEC_INSTRUCTION_POSTONLY;
    case 10:
    case "EXEC_INSTRUCTION_MTL":
      return Order_ExecInstruction.EXEC_INSTRUCTION_MTL;
    case 11:
    case "EXEC_INSTRUCTION_AUCTION":
      return Order_ExecInstruction.EXEC_INSTRUCTION_AUCTION;
    case 13:
    case "EXEC_INSTRUCTION_ATANYPRICE":
      return Order_ExecInstruction.EXEC_INSTRUCTION_ATANYPRICE;
    case 14:
    case "EXEC_INSTRUCTION_LMT_PRARGD":
      return Order_ExecInstruction.EXEC_INSTRUCTION_LMT_PRARGD;
    case 15:
    case "EXEC_INSTRUCTION_STM":
      return Order_ExecInstruction.EXEC_INSTRUCTION_STM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Order_ExecInstruction.UNRECOGNIZED;
  }
}

export function order_ExecInstructionToJSON(object: Order_ExecInstruction): string {
  switch (object) {
    case Order_ExecInstruction.EXEC_INSTRUCTION_NONE:
      return "EXEC_INSTRUCTION_NONE";
    case Order_ExecInstruction.EXEC_INSTRUCTION_AON:
      return "EXEC_INSTRUCTION_AON";
    case Order_ExecInstruction.EXEC_INSTRUCTION_ICEBERG:
      return "EXEC_INSTRUCTION_ICEBERG";
    case Order_ExecInstruction.EXEC_INSTRUCTION_QT:
      return "EXEC_INSTRUCTION_QT";
    case Order_ExecInstruction.EXEC_INSTRUCTION_TRAIL:
      return "EXEC_INSTRUCTION_TRAIL";
    case Order_ExecInstruction.EXEC_INSTRUCTION_FUNARI:
      return "EXEC_INSTRUCTION_FUNARI";
    case Order_ExecInstruction.EXEC_INSTRUCTION_MIT:
      return "EXEC_INSTRUCTION_MIT";
    case Order_ExecInstruction.EXEC_INSTRUCTION_MLM:
      return "EXEC_INSTRUCTION_MLM";
    case Order_ExecInstruction.EXEC_INSTRUCTION_POSTONLY:
      return "EXEC_INSTRUCTION_POSTONLY";
    case Order_ExecInstruction.EXEC_INSTRUCTION_MTL:
      return "EXEC_INSTRUCTION_MTL";
    case Order_ExecInstruction.EXEC_INSTRUCTION_AUCTION:
      return "EXEC_INSTRUCTION_AUCTION";
    case Order_ExecInstruction.EXEC_INSTRUCTION_ATANYPRICE:
      return "EXEC_INSTRUCTION_ATANYPRICE";
    case Order_ExecInstruction.EXEC_INSTRUCTION_LMT_PRARGD:
      return "EXEC_INSTRUCTION_LMT_PRARGD";
    case Order_ExecInstruction.EXEC_INSTRUCTION_STM:
      return "EXEC_INSTRUCTION_STM";
    case Order_ExecInstruction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Order_Duration {
  /** DURATION_DAY - Day order. Order is working through the current trading day only. */
  DURATION_DAY = 1,
  /** DURATION_GTC - Good Til Canceled. Order is working until canceled or until the contract is no longer available for trading. */
  DURATION_GTC = 2,
  /**
   * DURATION_GTD - Good Til Date. Order is working through the specified trade date (good_thru_date) for the contract.
   * Note: Exchange must have a trading session for the contract for the specified trade date.
   */
  DURATION_GTD = 3,
  /** DURATION_GTT - Good Til Time. Order is working until the specified time. */
  DURATION_GTT = 4,
  /** DURATION_FAK - Fill and Kill. Immediately fill as many as possible and cancel the rest. */
  DURATION_FAK = 5,
  /** DURATION_FOK - Fill Or Kill. Immediately fill this order completely or cancel. */
  DURATION_FOK = 6,
  /** DURATION_ATO - At The Open. Buy or sell at the very beginning of the trading day. */
  DURATION_ATO = 7,
  /** DURATION_ATC - At The Close. Buy or sell at the close of the market, or as near to the closing price as possible. */
  DURATION_ATC = 8,
  /** DURATION_GFA - Good for auction. Order only valid for the next auction. */
  DURATION_GFA = 9,
  UNRECOGNIZED = -1,
}

export function order_DurationFromJSON(object: any): Order_Duration {
  switch (object) {
    case 1:
    case "DURATION_DAY":
      return Order_Duration.DURATION_DAY;
    case 2:
    case "DURATION_GTC":
      return Order_Duration.DURATION_GTC;
    case 3:
    case "DURATION_GTD":
      return Order_Duration.DURATION_GTD;
    case 4:
    case "DURATION_GTT":
      return Order_Duration.DURATION_GTT;
    case 5:
    case "DURATION_FAK":
      return Order_Duration.DURATION_FAK;
    case 6:
    case "DURATION_FOK":
      return Order_Duration.DURATION_FOK;
    case 7:
    case "DURATION_ATO":
      return Order_Duration.DURATION_ATO;
    case 8:
    case "DURATION_ATC":
      return Order_Duration.DURATION_ATC;
    case 9:
    case "DURATION_GFA":
      return Order_Duration.DURATION_GFA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Order_Duration.UNRECOGNIZED;
  }
}

export function order_DurationToJSON(object: Order_Duration): string {
  switch (object) {
    case Order_Duration.DURATION_DAY:
      return "DURATION_DAY";
    case Order_Duration.DURATION_GTC:
      return "DURATION_GTC";
    case Order_Duration.DURATION_GTD:
      return "DURATION_GTD";
    case Order_Duration.DURATION_GTT:
      return "DURATION_GTT";
    case Order_Duration.DURATION_FAK:
      return "DURATION_FAK";
    case Order_Duration.DURATION_FOK:
      return "DURATION_FOK";
    case Order_Duration.DURATION_ATO:
      return "DURATION_ATO";
    case Order_Duration.DURATION_ATC:
      return "DURATION_ATC";
    case Order_Duration.DURATION_GFA:
      return "DURATION_GFA";
    case Order_Duration.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Order_TrailingPeg {
  /** TRAILING_PEG_BESTBID - Trail the best bid. */
  TRAILING_PEG_BESTBID = 1,
  /** TRAILING_PEG_BESTASK - Trail the best ask. */
  TRAILING_PEG_BESTASK = 2,
  /** TRAILING_PEG_LASTTRADE - Trail the last trade. */
  TRAILING_PEG_LASTTRADE = 3,
  UNRECOGNIZED = -1,
}

export function order_TrailingPegFromJSON(object: any): Order_TrailingPeg {
  switch (object) {
    case 1:
    case "TRAILING_PEG_BESTBID":
      return Order_TrailingPeg.TRAILING_PEG_BESTBID;
    case 2:
    case "TRAILING_PEG_BESTASK":
      return Order_TrailingPeg.TRAILING_PEG_BESTASK;
    case 3:
    case "TRAILING_PEG_LASTTRADE":
      return Order_TrailingPeg.TRAILING_PEG_LASTTRADE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Order_TrailingPeg.UNRECOGNIZED;
  }
}

export function order_TrailingPegToJSON(object: Order_TrailingPeg): string {
  switch (object) {
    case Order_TrailingPeg.TRAILING_PEG_BESTBID:
      return "TRAILING_PEG_BESTBID";
    case Order_TrailingPeg.TRAILING_PEG_BESTASK:
      return "TRAILING_PEG_BESTASK";
    case Order_TrailingPeg.TRAILING_PEG_LASTTRADE:
      return "TRAILING_PEG_LASTTRADE";
    case Order_TrailingPeg.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Specifies what the order is intended to do:
 * open (or extend) a position on the same side as the order or close (or reduce) an opposite position.
 * Applicable only for contracts with position_tracking
 * either LONG_SHORT_WITH_IMPLIED_CLOSE or LONG_SHORT_WITH_EXPLICIT_CLOSE.
 */
export enum Order_OpenCloseInstruction {
  /** OPEN_CLOSE_INSTRUCTION_OPEN - Opening a new today position. */
  OPEN_CLOSE_INSTRUCTION_OPEN = 1,
  /**
   * OPEN_CLOSE_INSTRUCTION_CLOSE - Closing or reducing (today only if ContractMetadata.position_tracking is LONG_SHORT_WITH_EXPLICIT_CLOSE,
   * today or yesterday if LONG_SHORT_WITH_IMPLIED_CLOSE).
   */
  OPEN_CLOSE_INSTRUCTION_CLOSE = 2,
  /**
   * OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY - Closing or reducing a yesterday position
   * (if ContractMetadata.position_tracking is LONG_SHORT_WITH_EXPLICIT_CLOSE).
   */
  OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY = 3,
  UNRECOGNIZED = -1,
}

export function order_OpenCloseInstructionFromJSON(object: any): Order_OpenCloseInstruction {
  switch (object) {
    case 1:
    case "OPEN_CLOSE_INSTRUCTION_OPEN":
      return Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_OPEN;
    case 2:
    case "OPEN_CLOSE_INSTRUCTION_CLOSE":
      return Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE;
    case 3:
    case "OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY":
      return Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Order_OpenCloseInstruction.UNRECOGNIZED;
  }
}

export function order_OpenCloseInstructionToJSON(object: Order_OpenCloseInstruction): string {
  switch (object) {
    case Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_OPEN:
      return "OPEN_CLOSE_INSTRUCTION_OPEN";
    case Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE:
      return "OPEN_CLOSE_INSTRUCTION_CLOSE";
    case Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY:
      return "OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY";
    case Order_OpenCloseInstruction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * One of the order related requests. There must be only one optional order submessage specified per request.
 * E.g NewOrder, but not NewOrder and CancelOrder.
 * It is required to perform a TradeSubscription before any order requests, the subscription is required
 * in order to receive order events.
 */
export interface OrderRequest {
  /** ID of a request that should be unique enough to match with possible OrderRequestReject. */
  requestId: number;
  /** New order request (place order). */
  newOrder?:
    | NewOrder
    | undefined;
  /** Modify order request. */
  modifyOrder?:
    | ModifyOrder
    | undefined;
  /** Cancel order request. */
  cancelOrder?:
    | CancelOrder
    | undefined;
  /** Cancel all orders request. */
  cancelAllOrders?:
    | CancelAllOrders
    | undefined;
  /** Liquidate all open positions. */
  liquidateAll?:
    | LiquidateAll
    | undefined;
  /** Cancel all orders and liquidate all open positions. */
  goFlat?:
    | GoFlat
    | undefined;
  /** Suspend (park) order. */
  suspendOrder?:
    | SuspendOrder
    | undefined;
  /** Activate suspended (parked) order. */
  activateOrder?:
    | ActivateOrder
    | undefined;
  /** Modify order's user attributes. */
  modifyUserAttributes?:
    | ModifyUserAttributes
    | undefined;
  /** New compound order request (place compound order). */
  newCompoundOrder?:
    | NewCompoundOrder
    | undefined;
  /** Synthetic hangup liquidation with a MKT order. */
  syntheticLiquidate?:
    | SyntheticLiquidate
    | undefined;
  /** Synthetic hangup liquidation with a LMT order. */
  syntheticScratch?:
    | SyntheticScratch
    | undefined;
  /** Modify a synthetic order to get an immediate fill. */
  goMarket?:
    | GoMarket
    | undefined;
  /** Approve order with ORDER_TYPE_CROSS type. */
  approveOrder?:
    | ApproveOrder
    | undefined;
  /** Fill specific care order. */
  fillCareOrder?:
    | FillCareOrder
    | undefined;
  /**
   * Username of the user on whose behalf the order request is being sent.
   * Must be a known username in CQG trade routing system,
   * otherwise the order will be rejected.
   */
  onBehalfOfUser?:
    | string
    | undefined;
  /**
   * Regulatory Algorithm ID for client algorithm associated with this order, if any.
   * This field is applicable only for exchanges that use it, e.g. Eurex
   * (not currently used, remains in protocol for backward compatibility only).
   * TransactionStatus message echoes back the value from the latest order request
   */
  clientRegulatoryAlgorithmId?:
    | number
    | undefined;
  /**
   * MiFID algorithm ID for this order, contents depend on mifid_algorithm_id_type.
   * Provide only when the order must have an algorithm as Execution Within Firm under MiFID II definitions.
   */
  mifidAlgorithmId?:
    | string
    | undefined;
  /** The type of mifid_algorithm_id supplied. One of MiFIDAlgorithmIdType enum. */
  mifidAlgorithmIdType?:
    | number
    | undefined;
  /**
   * True instructs server to send this order as Execution Within Firm=CLIENT/NORE
   * rather than the user's server-side configured trader short code.
   */
  overrideExecutionWithinFirmWithNore?:
    | boolean
    | undefined;
  /** MiFID investment decision ID for this order, contents depend on mifid_investment_decision_id_type. */
  mifidInvestmentDecisionId?:
    | string
    | undefined;
  /** The type of mifid_investment_decision_id supplied. One of MiFIDInvestmentDecisionIdType enum. */
  mifidInvestmentDecisionIdType?:
    | number
    | undefined;
  /**
   * MiFID execution decision ID for this order, contents depend on mifid_execution_decision_id_type.
   * Note: this field is used only by approved clients for absolute pass-through; orders from any other
   * clients that use the field will be rejected. Use general-purpose field mifid_algorithm_id with
   * mifid_algorithm_id_type=1 (MIFID_ALGORITHM_ID_TYPE_EXTERNAL) when execution decision is made by
   * an algorithm with registered short code.
   */
  mifidExecutionDecisionId?:
    | string
    | undefined;
  /** The type of mifid_execution_decision_id supplied. One of MiFIDExecutionDecisionIdType enum. */
  mifidExecutionDecisionIdType?:
    | number
    | undefined;
  /** True if this is an automated order request, manual (request placed by the user direct action) otherwise. */
  isAutomated?:
    | boolean
    | undefined;
  /**
   * Message originator location (i.e. geographic location).
   * Format: "CC[,SS]", CountryCode (ISO 3166-1 alpha 2) and StateCode
   * (postal abbreviation) when sending country code is "US" or "CA".
   * Examples: "US,CA", "GB".
   */
  senderLocationId?:
    | string
    | undefined;
  /**
   * Message originator identifier (desk, trader etc).
   * It is passed directly to the exchange in the appropriate exchange API field/tag,
   * often 'Operator ID', 'tag 50', or similar.
   */
  senderOperatorId?: string | undefined;
}

/** New order request. */
export interface NewOrder {
  /** Order to place. */
  order:
    | Order
    | undefined;
  /** True if order should be suspended (parked) and wait a separate activate request or activation time. */
  suspend?: boolean | undefined;
}

/** Modify order request, include only fields that are supposed to be modified. */
export interface ModifyOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to modify. */
  origClOrderId: string;
  /**
   * Client order ID of this modify request (will become new client order Id if modify is accepted).
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime?:
    | number
    | undefined;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp?:
    | Date
    | undefined;
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty?:
    | number
    | undefined;
  /**
   * Note: use visible_qty field instead.
   *
   * @deprecated
   */
  uint32VisibleQty?:
    | number
    | undefined;
  /**
   * Note: use min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty?:
    | number
    | undefined;
  /** Order size what the client wants the total size to become. */
  qty?:
    | Decimal
    | undefined;
  /** What the client wants the visible size to become. */
  visibleQty?:
    | Decimal
    | undefined;
  /** What the client wants the minimum visible size to become. */
  minVisibleQty?:
    | Decimal
    | undefined;
  /** What the client wants the limit price to become. */
  scaledLimitPrice?:
    | number
    | undefined;
  /** What the client wants the stop price to become. */
  scaledStopPrice?:
    | number
    | undefined;
  /**
   * What the client wants the activation time to become (UTC).
   * Note: use activation_utc_timestamp field instead.
   *
   * @deprecated
   */
  activationUtcTime?:
    | number
    | undefined;
  /**
   * What the client wants the activation time to become (UTC).
   * If specified it's used instead of activation_utc_time field.
   */
  activationUtcTimestamp?:
    | Date
    | undefined;
  /** True if activation time should be removed. */
  removeActivationTime?:
    | boolean
    | undefined;
  /**
   * What the client wants the suspension time to become (UTC).
   * Note: use suspension_utc_timestamp field instead.
   *
   * @deprecated
   */
  suspensionUtcTime?:
    | number
    | undefined;
  /**
   * What the client wants the suspension time to become (UTC).
   * If specified it's used instead of suspension_utc_time field.
   */
  suspensionUtcTimestamp?:
    | Date
    | undefined;
  /** True if suspension time should be removed. */
  removeSuspensionUtcTime?:
    | boolean
    | undefined;
  /**
   * What the client wants the order duration to become.
   * This field is associated with Order.Duration enum type.
   */
  duration?:
    | number
    | undefined;
  /** What the client wants the order expiration date to become (see Order.good_thru_date). */
  goodThruDate?:
    | number
    | undefined;
  /**
   * What the client wants the order expiration time to become (see Order.good_thru_utc_time).
   * Note: use good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  goodThruUtcTime?:
    | number
    | undefined;
  /**
   * What the client wants the order expiration time to become (see Order.good_thru_utc_timestamp).
   * If specified by the user it's used instead of good_thru_utc_time field.
   */
  goodThruUtcTimestamp?:
    | Date
    | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code
   */
  executionSourceCode?: string | undefined;
}

/** Cancel order request. */
export interface CancelOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to cancel. */
  origClOrderId: string;
  /**
   * Client order ID of this cancel request.
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime?:
    | number
    | undefined;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp?:
    | Date
    | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
}

/** Specifies an account and what orders to use in the account. */
export interface AccountOrderFilter {
  /** ID of the order's account. */
  accountId: number;
  /**
   * Specifies either all orders in the account or just orders in the
   * account associated with the trader sending the request.
   */
  mine?:
    | boolean
    | undefined;
  /**
   * Specifies whether only suspended orders or all not suspended orders in the
   * account associated with the trader sending the request.
   */
  suspended?:
    | boolean
    | undefined;
  /**
   * Specifies whether only BUY orders or only SELL orders.
   * This field is associated with Order.Side enum type.
   */
  side?:
    | number
    | undefined;
  /** Use only orders of specified contract. */
  contractId?:
    | number
    | undefined;
  /**
   * Specifies whether only order(s) expiring during the
   * current/nearest session should be considered.
   */
  currentDayOnly?: boolean | undefined;
}

/** Cancel all orders request. */
export interface CancelAllOrders {
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime?:
    | number
    | undefined;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp?:
    | Date
    | undefined;
  /** Client order ID of this cancel request. */
  clOrderId?:
    | string
    | undefined;
  /**
   * Specifies an account and what orders to cancel in the account.
   * All orders that meet the criteria (account, mine, suspended etc.) will be canceled.
   * At least one AccountOrderFilter should be present.
   */
  accountOrderFilters: AccountOrderFilter[];
}

/** Activate suspended (parked) order request. */
export interface ActivateOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to activate. */
  origClOrderId: string;
  /**
   * Client order ID of this activation request (will become new client order Id if activation is accepted).
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime?:
    | number
    | undefined;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp?:
    | Date
    | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code.
   */
  executionSourceCode?:
    | string
    | undefined;
  /** Set to true when the action is initiated by care order auto activation algorithm. */
  isCareAutoActivation?: boolean | undefined;
}

/** Placement of a compound order. */
export interface NewCompoundOrder {
  /** Compound order to place. */
  compoundOrder:
    | CompoundOrder
    | undefined;
  /**
   * Indicates whether compound order execution shall happen on a partial fill (true or omitted)
   * or complete fill (false).
   */
  partialFillsHandling?: boolean | undefined;
}

/** Compound order. */
export interface CompoundOrder {
  /** This field is associated with Type enum type. */
  type: number;
  /**
   * ID of the compound order assigned by client.
   * Maximum allowed length is 64 bytes.
   */
  clCompoundId: string;
  /** List of entires. */
  compoundOrderEntries: CompoundOrderEntry[];
  /**
   * Offset in ticks from fill price (variable ticks size is supported). New order price will be
   * calculated as (fill price + offset) for buy master orders and (price - offset) for sell master orders.
   */
  profitTickOffset?:
    | number
    | undefined;
  /**
   * Offset in ticks from fill price (variable ticks size is supported). New order price will be
   * calculated as (fill price - offset) for buy master orders and (price + offset) for sell master orders.
   */
  lossTickOffset?:
    | number
    | undefined;
  /**
   * Offset in ticks from loss_tick_offset for stop limit orders (variable ticks size is supported).
   * The offset determines the limit price for stop limit order after the stop price is hit.
   */
  stopLimitTickOffset?:
    | number
    | undefined;
  /** Treat compound order as server-side bracket. */
  isBracket?: boolean | undefined;
}

/** Type of the compound order. */
export enum CompoundOrder_Type {
  /**
   * TYPE_OPO - Order Places Order.
   * [Partial] fill of the first entry triggers [partial] placement of all other orders.
   */
  TYPE_OPO = 1,
  /**
   * TYPE_OCO - Order Cancels Order. This type allows several compound order entires.
   * [Partial] fill of any order triggers [partial] canceling of all other orders.
   */
  TYPE_OCO = 2,
  /** TYPE_INDEPENDENT - A set of otherwise unrelated orders being placed in one action. */
  TYPE_INDEPENDENT = 3,
  UNRECOGNIZED = -1,
}

export function compoundOrder_TypeFromJSON(object: any): CompoundOrder_Type {
  switch (object) {
    case 1:
    case "TYPE_OPO":
      return CompoundOrder_Type.TYPE_OPO;
    case 2:
    case "TYPE_OCO":
      return CompoundOrder_Type.TYPE_OCO;
    case 3:
    case "TYPE_INDEPENDENT":
      return CompoundOrder_Type.TYPE_INDEPENDENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CompoundOrder_Type.UNRECOGNIZED;
  }
}

export function compoundOrder_TypeToJSON(object: CompoundOrder_Type): string {
  switch (object) {
    case CompoundOrder_Type.TYPE_OPO:
      return "TYPE_OPO";
    case CompoundOrder_Type.TYPE_OCO:
      return "TYPE_OCO";
    case CompoundOrder_Type.TYPE_INDEPENDENT:
      return "TYPE_INDEPENDENT";
    case CompoundOrder_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Use existing order. */
export interface UseOrder {
  /** Order id of existing order assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
}

/** Entry of a compound order. Only one of optional fields has to be specified. */
export interface CompoundOrderEntry {
  /** Regular order entry. */
  order?:
    | Order
    | undefined;
  /** Sub-compound order entry. */
  compoundOrder?:
    | CompoundOrder
    | undefined;
  /** Existing order entry. */
  useOrder?: UseOrder | undefined;
}

/** Suspend (park) order request. */
export interface SuspendOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to suspend (park). */
  origClOrderId: string;
  /**
   * Client order ID of this suspension request (will become new client order Id if suspension is accepted).
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime?:
    | number
    | undefined;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp?:
    | Date
    | undefined;
  /**
   * Time when the suspended (parked) order should automatically activate (UTC).
   * Note: use activation_utc_timestamp field instead.
   *
   * @deprecated
   */
  activationUtcTime?:
    | number
    | undefined;
  /**
   * Time when the suspended (parked) order should automatically activate (UTC).
   * If specified it's used instead of activation_utc_time field.
   * If neither this nor activation_utc_time field is specified, order must be activated by ActivateOrder request
   */
  activationUtcTimestamp?:
    | Date
    | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
}

/** Specify account and its positions. */
export interface AccountPositionFilter {
  /** Id of an account. */
  accountId: number;
  /** Server side contract id. */
  contractId?:
    | number
    | undefined;
  /**
   * Specifies whether only short (true) or only long (false) positions should be selected.
   * If not specified, both short and long positions will be selected.
   */
  isShort?:
    | boolean
    | undefined;
  /**
   * Specifies whether only positions of current day (true)
   * or only positions of previous days (false) should be selected.
   * If not specified, positions of current and previous day(s) will be selected.
   * Should be used only if instrument supports explicit close for positions.
   * If specified, and any instrument not supporting explicit close matches this filter,
   * ServerMsg.OrderRequestReject is sent.
   */
  currentDayOnly?: boolean | undefined;
}

/** Liquidate all open positions. */
export interface LiquidateAll {
  /**
   * Accounts and positions to liquidate.
   * At least one AccountPositionFilter should be specified, otherwise ServerMsg.OrderRequestReject is sent.
   * Position matching any filter is to be liquidated.
   */
  accountPositionFilters: AccountPositionFilter[];
  /** Client side time when this request was submitted (UTC). */
  whenUtcTimestamp:
    | Date
    | undefined;
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code.
   */
  executionSourceCode?:
    | string
    | undefined;
  /** Specifies a speculation type. One of SpeculationType enum. */
  speculationType?: number | undefined;
}

/** Cancel all orders (including parked) and liquidate all open positions. */
export interface GoFlat {
  /** Id of an account. At least one should be specified, otherwise ServerMsg.OrderRequestReject is sent. */
  accountIds: number[];
  /** Client side time when this request was submitted (UTC). */
  whenUtcTimestamp:
    | Date
    | undefined;
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code.
   */
  executionSourceCode?:
    | string
    | undefined;
  /** Specifies a speculation type. One of SpeculationType enum. */
  speculationType?: number | undefined;
}

/** Request of order entitlements. */
export interface OrderEntitlementRequest {
  /** Server side contract id. */
  contractId: number;
  /** Account ID in CQG trade routing system. */
  accountId: number;
}

/** Report with order entitlements. */
export interface OrderEntitlementReport {
  /** Set of order entitlements. */
  orderEntitlements: OrderEntitlement[];
}

/**
 * Entitlements for order.
 * For future compatibility unknown order types/durations/execution instructions should be ignored.
 */
export interface OrderEntitlement {
  /**
   * Order type.
   * This field is associated with Order.OrderType enum type.
   */
  orderType: number;
  /**
   * Value indicating whether order_type may be executed synthetically by CQG.
   * E.g. if is_synthetic is true and order_type is MKT, it means that there will be synthetic processing
   * of Market order by CQG before the order is sent to the exchange, where Market order is not natively supported.
   * This is an informational field. The Client cannot specify this field in an order.
   */
  isSynthetic: boolean;
  /**
   * Order duration.
   * This field is associated with Order.Duration enum type.
   */
  duration: number;
  /**
   * Order execution instruction.
   * This field is associated with Order.ExecInstruction enum type.
   */
  execInstruction: number;
  /** Whether the algo strategy is required to be set for the order. */
  algoStrategyRequired?:
    | boolean
    | undefined;
  /** List of algo strategies enabled for the order (abbreviations). */
  algoStrategies: string[];
}

/**
 * Cross order parameters.
 * This message allows to report or register privately negotiated pre-agreed
 * transactions with one or multiple counter parties. Matching at exchange can
 * either immediately occur when the request is submitted as a dual-sided
 * or will occur when the different counterparts have all replied
 * to the cross order initiator (see ApproveOrder message).
 * Examples of these transactions: cross orders, Approved Publication
 * Arrangement (APA) and wholesale orders.
 * Transaction type is chosen by specifying corresponding execution instruction
 * (see Order.ExecInstruction). Note that some transaction types have various
 * exchange specific custom parameters (e.g. see Order.extra_attribute,
 * SideAllocation.extra_attributes and ApproveOrder.extra_attributes).
 * All these transaction types are referred to as "cross orders" within the
 * protocol for simplicity.
 *
 * Note that exchanges may support LegAllocation as independent children of a
 * cross order. This is used when each leg is an independent trade, so submitting the
 * cross order on a recognized strategy symbol is not possible. In this case, the
 * cross order symbol is a exchange-specific placeholder agreed between client and server
 * rather than a tradable symbol. Note: there are several required fields related to
 * placeholder contract like "qty" and "price" in Order, CrossOrderParameters and
 * SideAllocation messages. Client should fill these fields by some acceptable stub data,
 * like qty = "1".
 *
 * Possible scenarios:
 * 1) Dual-sided submission (no match process)
 *    Initiator submits a cross order and specifies all participants involved
 *    on buy and sell sides of the order; approve from the participants may be
 *    required for some exchanges.
 *    Two or more side allocations are provided for this type of cross order.
 * 2) Single-sided submission
 *    Each participant submits its own side (buy or sell) of a cross order.
 *    Matching could be done with an associated identifier (see transaction_id
 *    field) or with an approve request.
 *    One side allocation is provided for this type of cross order.
 * 3) Approved Publication Arrangement (APA)
 *    Allows users to publish trade reports in all instruments subject to the
 *    OTC publication requirement.
 *    No side allocations are provided for this type of cross order.
 */
export interface CrossOrderParameters {
  /** Side allocations of the cross order. */
  sideAllocations: SideAllocation[];
  /**
   * Price of the cross order (in correct format).
   * It is a required field.
   */
  price?:
    | number
    | undefined;
  /**
   * Optional ID that can be used to associate the single-sided
   * cross orders with the same transaction (used by Euronext exchange).
   */
  transactionId?:
    | string
    | undefined;
  /** Leg allocations of the cross order. */
  legAllocations: LegAllocation[];
}

/** Side allocation of the cross order. */
export interface SideAllocation {
  /**
   * Identifier of the side allocation record, this field is generated by
   * server.
   * Client should set this field to modify/approve existing side allocation.
   */
  sideId?:
    | number
    | undefined;
  /** Either this or external_account field must be specified. */
  accountId?:
    | number
    | undefined;
  /** Either this or account_id field must be specified. */
  externalAccount?:
    | ExternalAccount
    | undefined;
  /**
   * Allocation size, is only used for some particular scenarios
   * (like strategies on options for Euronext), in regular scenarios
   * the attribute should be filled by stub value "1".
   * It is a required field.
   */
  qty?:
    | Decimal
    | undefined;
  /**
   * Allocation side.
   * This field is associated with Order.Side enum type.
   * It is a required field.
   */
  side?:
    | number
    | undefined;
  /** List of side allocation extra attributes. */
  extraAttributes: NamedValue[];
}

/** Leg allocation of the cross order */
export interface LegAllocation {
  /**
   * Server side contract Id of the leg (see ContractMetadata message).
   * It is a required field.
   */
  contractId?:
    | number
    | undefined;
  /** Size of the leg. */
  qty?:
    | Decimal
    | undefined;
  /**
   * Price of the leg (in correct format).
   * It is a required field.
   */
  price?:
    | number
    | undefined;
  /**
   * Side of the leg, for exchanges that support LegAllocation as independent
   * child of CrossOrderParameters rather than child of SideAllocation.
   * This field is associated with Order.Side enum type.
   */
  side?:
    | number
    | undefined;
  /**
   * Identifier of the leg allocation record, this field is generated by server.
   * Client may require to set this field to modify/approve existing leg allocation.
   */
  legAllocId?:
    | number
    | undefined;
  /**
   * Identifier assigned to a trade by a matching system.
   * This field is only sent from server to client.
   * Check if the field is supported for specific execution system.
   */
  tradeMatchId?: string | undefined;
}

/**
 * Account related information that is specific for the exchange and/or the
 * brokerage.
 */
export interface ExternalAccount {
  /**
   * Unique identifier of account within the exchange's Member to place order
   * on it.
   * This account can be external for CQG.
   */
  externalAccountNumber?:
    | string
    | undefined;
  /**
   * Account type (aka Customer Type Indicator), values are specific per
   * execution systems.
   */
  accountType?:
    | string
    | undefined;
  /** Account origin, values are specific per execution systems. */
  accountOrigin?:
    | string
    | undefined;
  /**
   * Member ID that 'takes up' this account on the target exchange.
   * Should be considered only if account is 'give up'. Can be empty for
   * 'give up' accounts too, means that Member is not specified for current
   * account.
   */
  giveUpMemberId?: string | undefined;
}

/**
 * This message is sent by contra to approve the alleged order of CROSS type.
 * See CrossOrderParameters message for details.
 */
export interface ApproveOrder {
  /**
   * Order id assigned by server after last modification.
   * It is a required field.
   */
  orderId?:
    | string
    | undefined;
  /**
   * ID of the order's account.
   * It is a required field.
   */
  accountId?:
    | number
    | undefined;
  /**
   * This field is associated with Action enum type.
   * APPROVE is used if this field is empty.
   */
  action?:
    | number
    | undefined;
  /**
   * Client side time when an approve order request was submitted (UTC).
   * It is a required field.
   */
  whenUtcTimestamp?:
    | Date
    | undefined;
  /**
   * The contra is expected to provide its own side of the cross order in this
   * field.
   * It is a required field.
   */
  sideAllocation?:
    | SideAllocation
    | undefined;
  /** List of extra attributes. */
  extraAttributes: NamedValue[];
}

export enum ApproveOrder_Action {
  /** APPROVE_ORDER_ACTION_APPROVE - Action to approve the cross order. */
  APPROVE_ORDER_ACTION_APPROVE = 0,
  /** APPROVE_ORDER_ACTION_REJECT - Action to reject the cross order. */
  APPROVE_ORDER_ACTION_REJECT = 1,
  UNRECOGNIZED = -1,
}

export function approveOrder_ActionFromJSON(object: any): ApproveOrder_Action {
  switch (object) {
    case 0:
    case "APPROVE_ORDER_ACTION_APPROVE":
      return ApproveOrder_Action.APPROVE_ORDER_ACTION_APPROVE;
    case 1:
    case "APPROVE_ORDER_ACTION_REJECT":
      return ApproveOrder_Action.APPROVE_ORDER_ACTION_REJECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ApproveOrder_Action.UNRECOGNIZED;
  }
}

export function approveOrder_ActionToJSON(object: ApproveOrder_Action): string {
  switch (object) {
    case ApproveOrder_Action.APPROVE_ORDER_ACTION_APPROVE:
      return "APPROVE_ORDER_ACTION_APPROVE";
    case ApproveOrder_Action.APPROVE_ORDER_ACTION_REJECT:
      return "APPROVE_ORDER_ACTION_REJECT";
    case ApproveOrder_Action.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Fill specific care order.
 * This message is sent in order to fill care order without sending it to
 * exchange.
 */
export interface FillCareOrder {
  /**
   * Execution ID of the fill.
   * It is a required field.
   */
  execId?:
    | string
    | undefined;
  /** Unique id used to identify filled order. */
  fillCareOrderRequestId?:
    | string
    | undefined;
  /**
   * Price for the fill in correct price format.
   * It is a required field.
   */
  fillPrice?:
    | number
    | undefined;
  /**
   * Quantity to fill for the order.
   * It is a required field.
   */
  fillQty?:
    | Decimal
    | undefined;
  /**
   * Order id being filled. See OrderStatus.order_id.
   * It is a required field.
   */
  orderId?:
    | string
    | undefined;
  /**
   * ID of the order's account.
   * It is a required field.
   */
  accountId?:
    | number
    | undefined;
  /** Holds information about fill parameters of spread legs. */
  orderLegs: FillCareOrderLeg[];
  /** Holds information about parameters of opposite-side fill. */
  oppositeSide?: FillCareOppositeSide | undefined;
}

/** Provides fill parameters of native strategy order's leg during FillCareOrder. */
export interface FillCareOrderLeg {
  /**
   * Execution ID of the leg fill.
   * It is a required field.
   */
  legExecId?:
    | string
    | undefined;
  /**
   * Node index of corresponding strategy leg (see LegDefinition.node_index parameter).
   * It is a required field.
   */
  nodeIndex?:
    | number
    | undefined;
  /**
   * Price for the leg fill in correct price format.
   * It is a required field.
   */
  fillPrice?:
    | number
    | undefined;
  /**
   * Quantity for the leg fill.
   * It is a required field.
   */
  fillQty?: Decimal | undefined;
}

/** Provides parameters of opposite-side fill during FillCareOrder. */
export interface FillCareOppositeSide {
  /**
   * Execution ID of opposite-side fill.
   * It is a required field.
   */
  execId?:
    | string
    | undefined;
  /**
   * ID of opposite-side care order to fill,
   * Can be ommitted if create_new_order=true.
   */
  orderId?:
    | string
    | undefined;
  /** Instruction to create a new order for opposite-side fill. */
  createNewOrder?:
    | boolean
    | undefined;
  /**
   * ID of the opposite-side fill's account.
   * It is a required field.
   */
  accountId?:
    | number
    | undefined;
  /** Holds information about fill parameters of spread legs. */
  orderLegs: FillCareOrderLeg[];
}

/**
 * Allows to get an order status with all transactions.
 * Works for current day and historical orders, with
 * the exception of unfilled leg orders of
 * non-failed synthetic strategy orders.
 */
export interface OrderStatusRequest {
  /**
   * Chain order id from an order status.
   * See OrderStatus.chain_order_id.
   * It is a required field.
   */
  chainOrderId?:
    | string
    | undefined;
  /**
   * ID of the order's account.
   * It is a required field.
   */
  accountId?: number | undefined;
}

/** Report with requested information in OrderStatusRequest */
export interface OrderStatusReport {
  /** Order status with all transactions, if found. */
  orderStatus?: OrderStatus | undefined;
}

function createBaseOrderRequestReject(): OrderRequestReject {
  return { requestId: 0, rejectCode: 0, textMessage: "", details: undefined };
}

export const OrderRequestReject = {
  encode(message: OrderRequestReject, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.rejectCode !== 0) {
      writer.uint32(16).uint32(message.rejectCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(26).string(message.textMessage);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRequestReject {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRequestReject();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rejectCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.details = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderRequestReject {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      rejectCode: isSet(object.rejectCode) ? globalThis.Number(object.rejectCode) : 0,
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: OrderRequestReject): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.rejectCode !== 0) {
      obj.rejectCode = Math.round(message.rejectCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.details !== undefined) {
      obj.details = Text.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRequestReject>, I>>(base?: I): OrderRequestReject {
    return OrderRequestReject.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderRequestReject>, I>>(object: I): OrderRequestReject {
    const message = createBaseOrderRequestReject();
    message.requestId = object.requestId ?? 0;
    message.rejectCode = object.rejectCode ?? 0;
    message.textMessage = object.textMessage ?? "";
    message.details = (object.details !== undefined && object.details !== null)
      ? Text.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseOrderRequestAck(): OrderRequestAck {
  return { requestId: 0, when: undefined };
}

export const OrderRequestAck = {
  encode(message: OrderRequestAck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== undefined && message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.when !== undefined) {
      Timestamp.encode(toTimestamp(message.when), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRequestAck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRequestAck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.when = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderRequestAck {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      when: isSet(object.when) ? fromJsonTimestamp(object.when) : undefined,
    };
  },

  toJSON(message: OrderRequestAck): unknown {
    const obj: any = {};
    if (message.requestId !== undefined && message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.when !== undefined) {
      obj.when = message.when.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRequestAck>, I>>(base?: I): OrderRequestAck {
    return OrderRequestAck.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderRequestAck>, I>>(object: I): OrderRequestAck {
    const message = createBaseOrderRequestAck();
    message.requestId = object.requestId ?? 0;
    message.when = object.when ?? undefined;
    return message;
  },
};

function createBaseOrderStatus(): OrderStatus {
  return {
    subscriptionIds: [],
    isSnapshot: false,
    status: 0,
    orderId: "",
    chainOrderId: "",
    execOrderId: "",
    detailedDomOrderId: "",
    statusUtcTime: 0,
    statusUtcTimestamp: undefined,
    submissionUtcTime: 0,
    submissionUtcTimestamp: undefined,
    uint32FillQty: 0,
    fillQty: undefined,
    fillCnt: 0,
    scaledAvgFillPrice: 0,
    avgFillPriceCorrect: 0,
    activeAtUtcTime: 0,
    activeAtUtcTimestamp: undefined,
    uint32RemainingQty: 0,
    remainingQty: undefined,
    order: undefined,
    transactionStatuses: [],
    rejectUtcTimestamp: undefined,
    rejectMessage: "",
    cancelUtcTimestamp: undefined,
    fillUtcTimestamp: undefined,
    hasFillWithModifiedQty: false,
    hasFillWithModifiedPrice: false,
    enteredByUser: "",
    firstStatementDate: 0,
    lastStatementDate: 0,
    contractMetadata: [],
    accountId: 0,
    compoundOrderStructure: undefined,
    hangCount: 0,
    strategyProperties: undefined,
    crossTransactionId: "",
    exchangeExtraAttributes: [],
    fillCommission: undefined,
    routeClOrderId: "",
    mifidExecutionDecision: "",
    mifidExecutionDecisionIsAlgo: false,
    mifidInvestmentDecision: "",
    mifidInvestmentDecisionIsAlgo: false,
    senderLocationId: "",
    senderOperatorId: "",
    operatorId: "",
    fillCareOrderRequestId: "",
  };
}

export const OrderStatus = {
  encode(message: OrderStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.subscriptionIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      writer.uint32(16).bool(message.isSnapshot);
    }
    if (message.status !== 0) {
      writer.uint32(24).uint32(message.status);
    }
    if (message.orderId !== "") {
      writer.uint32(34).string(message.orderId);
    }
    if (message.chainOrderId !== "") {
      writer.uint32(42).string(message.chainOrderId);
    }
    if (message.execOrderId !== undefined && message.execOrderId !== "") {
      writer.uint32(50).string(message.execOrderId);
    }
    if (message.detailedDomOrderId !== undefined && message.detailedDomOrderId !== "") {
      writer.uint32(290).string(message.detailedDomOrderId);
    }
    if (message.statusUtcTime !== undefined && message.statusUtcTime !== 0) {
      writer.uint32(56).sint64(message.statusUtcTime);
    }
    if (message.statusUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.statusUtcTimestamp), writer.uint32(194).fork()).ldelim();
    }
    if (message.submissionUtcTime !== undefined && message.submissionUtcTime !== 0) {
      writer.uint32(64).sint64(message.submissionUtcTime);
    }
    if (message.submissionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.submissionUtcTimestamp), writer.uint32(202).fork()).ldelim();
    }
    if (message.uint32FillQty !== undefined && message.uint32FillQty !== 0) {
      writer.uint32(72).uint32(message.uint32FillQty);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(226).fork()).ldelim();
    }
    if (message.fillCnt !== 0) {
      writer.uint32(80).uint32(message.fillCnt);
    }
    if (message.scaledAvgFillPrice !== 0) {
      writer.uint32(88).sint64(message.scaledAvgFillPrice);
    }
    if (message.avgFillPriceCorrect !== 0) {
      writer.uint32(217).double(message.avgFillPriceCorrect);
    }
    if (message.activeAtUtcTime !== undefined && message.activeAtUtcTime !== 0) {
      writer.uint32(96).sint64(message.activeAtUtcTime);
    }
    if (message.activeAtUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activeAtUtcTimestamp), writer.uint32(210).fork()).ldelim();
    }
    if (message.uint32RemainingQty !== undefined && message.uint32RemainingQty !== 0) {
      writer.uint32(104).uint32(message.uint32RemainingQty);
    }
    if (message.remainingQty !== undefined) {
      Decimal.encode(message.remainingQty, writer.uint32(234).fork()).ldelim();
    }
    if (message.order !== undefined) {
      Order.encode(message.order, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.transactionStatuses) {
      TransactionStatus.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.rejectUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.rejectUtcTimestamp), writer.uint32(242).fork()).ldelim();
    }
    if (message.rejectMessage !== undefined && message.rejectMessage !== "") {
      writer.uint32(250).string(message.rejectMessage);
    }
    if (message.cancelUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.cancelUtcTimestamp), writer.uint32(258).fork()).ldelim();
    }
    if (message.fillUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.fillUtcTimestamp), writer.uint32(266).fork()).ldelim();
    }
    if (message.hasFillWithModifiedQty !== undefined && message.hasFillWithModifiedQty !== false) {
      writer.uint32(272).bool(message.hasFillWithModifiedQty);
    }
    if (message.hasFillWithModifiedPrice !== undefined && message.hasFillWithModifiedPrice !== false) {
      writer.uint32(280).bool(message.hasFillWithModifiedPrice);
    }
    if (message.enteredByUser !== "") {
      writer.uint32(130).string(message.enteredByUser);
    }
    if (message.firstStatementDate !== 0) {
      writer.uint32(136).sint64(message.firstStatementDate);
    }
    if (message.lastStatementDate !== undefined && message.lastStatementDate !== 0) {
      writer.uint32(144).sint64(message.lastStatementDate);
    }
    for (const v of message.contractMetadata) {
      ContractMetadata.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    if (message.accountId !== 0) {
      writer.uint32(160).sint32(message.accountId);
    }
    if (message.compoundOrderStructure !== undefined) {
      CompoundOrderStructure.encode(message.compoundOrderStructure, writer.uint32(170).fork()).ldelim();
    }
    if (message.hangCount !== undefined && message.hangCount !== 0) {
      writer.uint32(176).uint32(message.hangCount);
    }
    if (message.strategyProperties !== undefined) {
      SyntheticStrategyProperties.encode(message.strategyProperties, writer.uint32(186).fork()).ldelim();
    }
    if (message.crossTransactionId !== undefined && message.crossTransactionId !== "") {
      writer.uint32(298).string(message.crossTransactionId);
    }
    for (const v of message.exchangeExtraAttributes) {
      NamedValue.encode(v!, writer.uint32(306).fork()).ldelim();
    }
    if (message.fillCommission !== undefined) {
      Commission.encode(message.fillCommission, writer.uint32(314).fork()).ldelim();
    }
    if (message.routeClOrderId !== undefined && message.routeClOrderId !== "") {
      writer.uint32(322).string(message.routeClOrderId);
    }
    if (message.mifidExecutionDecision !== undefined && message.mifidExecutionDecision !== "") {
      writer.uint32(330).string(message.mifidExecutionDecision);
    }
    if (message.mifidExecutionDecisionIsAlgo !== undefined && message.mifidExecutionDecisionIsAlgo !== false) {
      writer.uint32(336).bool(message.mifidExecutionDecisionIsAlgo);
    }
    if (message.mifidInvestmentDecision !== undefined && message.mifidInvestmentDecision !== "") {
      writer.uint32(346).string(message.mifidInvestmentDecision);
    }
    if (message.mifidInvestmentDecisionIsAlgo !== undefined && message.mifidInvestmentDecisionIsAlgo !== false) {
      writer.uint32(352).bool(message.mifidInvestmentDecisionIsAlgo);
    }
    if (message.senderLocationId !== undefined && message.senderLocationId !== "") {
      writer.uint32(362).string(message.senderLocationId);
    }
    if (message.senderOperatorId !== undefined && message.senderOperatorId !== "") {
      writer.uint32(370).string(message.senderOperatorId);
    }
    if (message.operatorId !== undefined && message.operatorId !== "") {
      writer.uint32(378).string(message.operatorId);
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      writer.uint32(386).string(message.fillCareOrderRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.subscriptionIds.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.subscriptionIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSnapshot = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.execOrderId = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.detailedDomOrderId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.statusUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.statusUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.submissionUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.submissionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.uint32FillQty = reader.uint32();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.fillQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.fillCnt = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.scaledAvgFillPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 27:
          if (tag !== 217) {
            break;
          }

          message.avgFillPriceCorrect = reader.double();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.activeAtUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.activeAtUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.uint32RemainingQty = reader.uint32();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.remainingQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.order = Order.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.transactionStatuses.push(TransactionStatus.decode(reader, reader.uint32()));
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.rejectUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.rejectMessage = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.cancelUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.fillUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.hasFillWithModifiedQty = reader.bool();
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.hasFillWithModifiedPrice = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.enteredByUser = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.firstStatementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.lastStatementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.contractMetadata.push(ContractMetadata.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.compoundOrderStructure = CompoundOrderStructure.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.hangCount = reader.uint32();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.strategyProperties = SyntheticStrategyProperties.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.crossTransactionId = reader.string();
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.exchangeExtraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.fillCommission = Commission.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.routeClOrderId = reader.string();
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.mifidExecutionDecision = reader.string();
          continue;
        case 42:
          if (tag !== 336) {
            break;
          }

          message.mifidExecutionDecisionIsAlgo = reader.bool();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.mifidInvestmentDecision = reader.string();
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.mifidInvestmentDecisionIsAlgo = reader.bool();
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.senderLocationId = reader.string();
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.senderOperatorId = reader.string();
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.operatorId = reader.string();
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.fillCareOrderRequestId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderStatus {
    return {
      subscriptionIds: globalThis.Array.isArray(object?.subscriptionIds)
        ? object.subscriptionIds.map((e: any) => globalThis.Number(e))
        : [],
      isSnapshot: isSet(object.isSnapshot) ? globalThis.Boolean(object.isSnapshot) : false,
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      execOrderId: isSet(object.execOrderId) ? globalThis.String(object.execOrderId) : "",
      detailedDomOrderId: isSet(object.detailedDomOrderId) ? globalThis.String(object.detailedDomOrderId) : "",
      statusUtcTime: isSet(object.statusUtcTime) ? globalThis.Number(object.statusUtcTime) : 0,
      statusUtcTimestamp: isSet(object.statusUtcTimestamp) ? fromJsonTimestamp(object.statusUtcTimestamp) : undefined,
      submissionUtcTime: isSet(object.submissionUtcTime) ? globalThis.Number(object.submissionUtcTime) : 0,
      submissionUtcTimestamp: isSet(object.submissionUtcTimestamp)
        ? fromJsonTimestamp(object.submissionUtcTimestamp)
        : undefined,
      uint32FillQty: isSet(object.uint32FillQty) ? globalThis.Number(object.uint32FillQty) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
      fillCnt: isSet(object.fillCnt) ? globalThis.Number(object.fillCnt) : 0,
      scaledAvgFillPrice: isSet(object.scaledAvgFillPrice) ? globalThis.Number(object.scaledAvgFillPrice) : 0,
      avgFillPriceCorrect: isSet(object.avgFillPriceCorrect) ? globalThis.Number(object.avgFillPriceCorrect) : 0,
      activeAtUtcTime: isSet(object.activeAtUtcTime) ? globalThis.Number(object.activeAtUtcTime) : 0,
      activeAtUtcTimestamp: isSet(object.activeAtUtcTimestamp)
        ? fromJsonTimestamp(object.activeAtUtcTimestamp)
        : undefined,
      uint32RemainingQty: isSet(object.uint32RemainingQty) ? globalThis.Number(object.uint32RemainingQty) : 0,
      remainingQty: isSet(object.remainingQty) ? Decimal.fromJSON(object.remainingQty) : undefined,
      order: isSet(object.order) ? Order.fromJSON(object.order) : undefined,
      transactionStatuses: globalThis.Array.isArray(object?.transactionStatuses)
        ? object.transactionStatuses.map((e: any) => TransactionStatus.fromJSON(e))
        : [],
      rejectUtcTimestamp: isSet(object.rejectUtcTimestamp) ? fromJsonTimestamp(object.rejectUtcTimestamp) : undefined,
      rejectMessage: isSet(object.rejectMessage) ? globalThis.String(object.rejectMessage) : "",
      cancelUtcTimestamp: isSet(object.cancelUtcTimestamp) ? fromJsonTimestamp(object.cancelUtcTimestamp) : undefined,
      fillUtcTimestamp: isSet(object.fillUtcTimestamp) ? fromJsonTimestamp(object.fillUtcTimestamp) : undefined,
      hasFillWithModifiedQty: isSet(object.hasFillWithModifiedQty)
        ? globalThis.Boolean(object.hasFillWithModifiedQty)
        : false,
      hasFillWithModifiedPrice: isSet(object.hasFillWithModifiedPrice)
        ? globalThis.Boolean(object.hasFillWithModifiedPrice)
        : false,
      enteredByUser: isSet(object.enteredByUser) ? globalThis.String(object.enteredByUser) : "",
      firstStatementDate: isSet(object.firstStatementDate) ? globalThis.Number(object.firstStatementDate) : 0,
      lastStatementDate: isSet(object.lastStatementDate) ? globalThis.Number(object.lastStatementDate) : 0,
      contractMetadata: globalThis.Array.isArray(object?.contractMetadata)
        ? object.contractMetadata.map((e: any) => ContractMetadata.fromJSON(e))
        : [],
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      compoundOrderStructure: isSet(object.compoundOrderStructure)
        ? CompoundOrderStructure.fromJSON(object.compoundOrderStructure)
        : undefined,
      hangCount: isSet(object.hangCount) ? globalThis.Number(object.hangCount) : 0,
      strategyProperties: isSet(object.strategyProperties)
        ? SyntheticStrategyProperties.fromJSON(object.strategyProperties)
        : undefined,
      crossTransactionId: isSet(object.crossTransactionId) ? globalThis.String(object.crossTransactionId) : "",
      exchangeExtraAttributes: globalThis.Array.isArray(object?.exchangeExtraAttributes)
        ? object.exchangeExtraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      fillCommission: isSet(object.fillCommission) ? Commission.fromJSON(object.fillCommission) : undefined,
      routeClOrderId: isSet(object.routeClOrderId) ? globalThis.String(object.routeClOrderId) : "",
      mifidExecutionDecision: isSet(object.mifidExecutionDecision)
        ? globalThis.String(object.mifidExecutionDecision)
        : "",
      mifidExecutionDecisionIsAlgo: isSet(object.mifidExecutionDecisionIsAlgo)
        ? globalThis.Boolean(object.mifidExecutionDecisionIsAlgo)
        : false,
      mifidInvestmentDecision: isSet(object.mifidInvestmentDecision)
        ? globalThis.String(object.mifidInvestmentDecision)
        : "",
      mifidInvestmentDecisionIsAlgo: isSet(object.mifidInvestmentDecisionIsAlgo)
        ? globalThis.Boolean(object.mifidInvestmentDecisionIsAlgo)
        : false,
      senderLocationId: isSet(object.senderLocationId) ? globalThis.String(object.senderLocationId) : "",
      senderOperatorId: isSet(object.senderOperatorId) ? globalThis.String(object.senderOperatorId) : "",
      operatorId: isSet(object.operatorId) ? globalThis.String(object.operatorId) : "",
      fillCareOrderRequestId: isSet(object.fillCareOrderRequestId)
        ? globalThis.String(object.fillCareOrderRequestId)
        : "",
    };
  },

  toJSON(message: OrderStatus): unknown {
    const obj: any = {};
    if (message.subscriptionIds?.length) {
      obj.subscriptionIds = message.subscriptionIds.map((e) => Math.round(e));
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      obj.isSnapshot = message.isSnapshot;
    }
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.execOrderId !== undefined && message.execOrderId !== "") {
      obj.execOrderId = message.execOrderId;
    }
    if (message.detailedDomOrderId !== undefined && message.detailedDomOrderId !== "") {
      obj.detailedDomOrderId = message.detailedDomOrderId;
    }
    if (message.statusUtcTime !== undefined && message.statusUtcTime !== 0) {
      obj.statusUtcTime = Math.round(message.statusUtcTime);
    }
    if (message.statusUtcTimestamp !== undefined) {
      obj.statusUtcTimestamp = message.statusUtcTimestamp.toISOString();
    }
    if (message.submissionUtcTime !== undefined && message.submissionUtcTime !== 0) {
      obj.submissionUtcTime = Math.round(message.submissionUtcTime);
    }
    if (message.submissionUtcTimestamp !== undefined) {
      obj.submissionUtcTimestamp = message.submissionUtcTimestamp.toISOString();
    }
    if (message.uint32FillQty !== undefined && message.uint32FillQty !== 0) {
      obj.uint32FillQty = Math.round(message.uint32FillQty);
    }
    if (message.fillQty !== undefined) {
      obj.fillQty = Decimal.toJSON(message.fillQty);
    }
    if (message.fillCnt !== 0) {
      obj.fillCnt = Math.round(message.fillCnt);
    }
    if (message.scaledAvgFillPrice !== 0) {
      obj.scaledAvgFillPrice = Math.round(message.scaledAvgFillPrice);
    }
    if (message.avgFillPriceCorrect !== 0) {
      obj.avgFillPriceCorrect = message.avgFillPriceCorrect;
    }
    if (message.activeAtUtcTime !== undefined && message.activeAtUtcTime !== 0) {
      obj.activeAtUtcTime = Math.round(message.activeAtUtcTime);
    }
    if (message.activeAtUtcTimestamp !== undefined) {
      obj.activeAtUtcTimestamp = message.activeAtUtcTimestamp.toISOString();
    }
    if (message.uint32RemainingQty !== undefined && message.uint32RemainingQty !== 0) {
      obj.uint32RemainingQty = Math.round(message.uint32RemainingQty);
    }
    if (message.remainingQty !== undefined) {
      obj.remainingQty = Decimal.toJSON(message.remainingQty);
    }
    if (message.order !== undefined) {
      obj.order = Order.toJSON(message.order);
    }
    if (message.transactionStatuses?.length) {
      obj.transactionStatuses = message.transactionStatuses.map((e) => TransactionStatus.toJSON(e));
    }
    if (message.rejectUtcTimestamp !== undefined) {
      obj.rejectUtcTimestamp = message.rejectUtcTimestamp.toISOString();
    }
    if (message.rejectMessage !== undefined && message.rejectMessage !== "") {
      obj.rejectMessage = message.rejectMessage;
    }
    if (message.cancelUtcTimestamp !== undefined) {
      obj.cancelUtcTimestamp = message.cancelUtcTimestamp.toISOString();
    }
    if (message.fillUtcTimestamp !== undefined) {
      obj.fillUtcTimestamp = message.fillUtcTimestamp.toISOString();
    }
    if (message.hasFillWithModifiedQty !== undefined && message.hasFillWithModifiedQty !== false) {
      obj.hasFillWithModifiedQty = message.hasFillWithModifiedQty;
    }
    if (message.hasFillWithModifiedPrice !== undefined && message.hasFillWithModifiedPrice !== false) {
      obj.hasFillWithModifiedPrice = message.hasFillWithModifiedPrice;
    }
    if (message.enteredByUser !== "") {
      obj.enteredByUser = message.enteredByUser;
    }
    if (message.firstStatementDate !== 0) {
      obj.firstStatementDate = Math.round(message.firstStatementDate);
    }
    if (message.lastStatementDate !== undefined && message.lastStatementDate !== 0) {
      obj.lastStatementDate = Math.round(message.lastStatementDate);
    }
    if (message.contractMetadata?.length) {
      obj.contractMetadata = message.contractMetadata.map((e) => ContractMetadata.toJSON(e));
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.compoundOrderStructure !== undefined) {
      obj.compoundOrderStructure = CompoundOrderStructure.toJSON(message.compoundOrderStructure);
    }
    if (message.hangCount !== undefined && message.hangCount !== 0) {
      obj.hangCount = Math.round(message.hangCount);
    }
    if (message.strategyProperties !== undefined) {
      obj.strategyProperties = SyntheticStrategyProperties.toJSON(message.strategyProperties);
    }
    if (message.crossTransactionId !== undefined && message.crossTransactionId !== "") {
      obj.crossTransactionId = message.crossTransactionId;
    }
    if (message.exchangeExtraAttributes?.length) {
      obj.exchangeExtraAttributes = message.exchangeExtraAttributes.map((e) => NamedValue.toJSON(e));
    }
    if (message.fillCommission !== undefined) {
      obj.fillCommission = Commission.toJSON(message.fillCommission);
    }
    if (message.routeClOrderId !== undefined && message.routeClOrderId !== "") {
      obj.routeClOrderId = message.routeClOrderId;
    }
    if (message.mifidExecutionDecision !== undefined && message.mifidExecutionDecision !== "") {
      obj.mifidExecutionDecision = message.mifidExecutionDecision;
    }
    if (message.mifidExecutionDecisionIsAlgo !== undefined && message.mifidExecutionDecisionIsAlgo !== false) {
      obj.mifidExecutionDecisionIsAlgo = message.mifidExecutionDecisionIsAlgo;
    }
    if (message.mifidInvestmentDecision !== undefined && message.mifidInvestmentDecision !== "") {
      obj.mifidInvestmentDecision = message.mifidInvestmentDecision;
    }
    if (message.mifidInvestmentDecisionIsAlgo !== undefined && message.mifidInvestmentDecisionIsAlgo !== false) {
      obj.mifidInvestmentDecisionIsAlgo = message.mifidInvestmentDecisionIsAlgo;
    }
    if (message.senderLocationId !== undefined && message.senderLocationId !== "") {
      obj.senderLocationId = message.senderLocationId;
    }
    if (message.senderOperatorId !== undefined && message.senderOperatorId !== "") {
      obj.senderOperatorId = message.senderOperatorId;
    }
    if (message.operatorId !== undefined && message.operatorId !== "") {
      obj.operatorId = message.operatorId;
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      obj.fillCareOrderRequestId = message.fillCareOrderRequestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderStatus>, I>>(base?: I): OrderStatus {
    return OrderStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderStatus>, I>>(object: I): OrderStatus {
    const message = createBaseOrderStatus();
    message.subscriptionIds = object.subscriptionIds?.map((e) => e) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    message.status = object.status ?? 0;
    message.orderId = object.orderId ?? "";
    message.chainOrderId = object.chainOrderId ?? "";
    message.execOrderId = object.execOrderId ?? "";
    message.detailedDomOrderId = object.detailedDomOrderId ?? "";
    message.statusUtcTime = object.statusUtcTime ?? 0;
    message.statusUtcTimestamp = object.statusUtcTimestamp ?? undefined;
    message.submissionUtcTime = object.submissionUtcTime ?? 0;
    message.submissionUtcTimestamp = object.submissionUtcTimestamp ?? undefined;
    message.uint32FillQty = object.uint32FillQty ?? 0;
    message.fillQty = (object.fillQty !== undefined && object.fillQty !== null)
      ? Decimal.fromPartial(object.fillQty)
      : undefined;
    message.fillCnt = object.fillCnt ?? 0;
    message.scaledAvgFillPrice = object.scaledAvgFillPrice ?? 0;
    message.avgFillPriceCorrect = object.avgFillPriceCorrect ?? 0;
    message.activeAtUtcTime = object.activeAtUtcTime ?? 0;
    message.activeAtUtcTimestamp = object.activeAtUtcTimestamp ?? undefined;
    message.uint32RemainingQty = object.uint32RemainingQty ?? 0;
    message.remainingQty = (object.remainingQty !== undefined && object.remainingQty !== null)
      ? Decimal.fromPartial(object.remainingQty)
      : undefined;
    message.order = (object.order !== undefined && object.order !== null) ? Order.fromPartial(object.order) : undefined;
    message.transactionStatuses = object.transactionStatuses?.map((e) => TransactionStatus.fromPartial(e)) || [];
    message.rejectUtcTimestamp = object.rejectUtcTimestamp ?? undefined;
    message.rejectMessage = object.rejectMessage ?? "";
    message.cancelUtcTimestamp = object.cancelUtcTimestamp ?? undefined;
    message.fillUtcTimestamp = object.fillUtcTimestamp ?? undefined;
    message.hasFillWithModifiedQty = object.hasFillWithModifiedQty ?? false;
    message.hasFillWithModifiedPrice = object.hasFillWithModifiedPrice ?? false;
    message.enteredByUser = object.enteredByUser ?? "";
    message.firstStatementDate = object.firstStatementDate ?? 0;
    message.lastStatementDate = object.lastStatementDate ?? 0;
    message.contractMetadata = object.contractMetadata?.map((e) => ContractMetadata.fromPartial(e)) || [];
    message.accountId = object.accountId ?? 0;
    message.compoundOrderStructure =
      (object.compoundOrderStructure !== undefined && object.compoundOrderStructure !== null)
        ? CompoundOrderStructure.fromPartial(object.compoundOrderStructure)
        : undefined;
    message.hangCount = object.hangCount ?? 0;
    message.strategyProperties = (object.strategyProperties !== undefined && object.strategyProperties !== null)
      ? SyntheticStrategyProperties.fromPartial(object.strategyProperties)
      : undefined;
    message.crossTransactionId = object.crossTransactionId ?? "";
    message.exchangeExtraAttributes = object.exchangeExtraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.fillCommission = (object.fillCommission !== undefined && object.fillCommission !== null)
      ? Commission.fromPartial(object.fillCommission)
      : undefined;
    message.routeClOrderId = object.routeClOrderId ?? "";
    message.mifidExecutionDecision = object.mifidExecutionDecision ?? "";
    message.mifidExecutionDecisionIsAlgo = object.mifidExecutionDecisionIsAlgo ?? false;
    message.mifidInvestmentDecision = object.mifidInvestmentDecision ?? "";
    message.mifidInvestmentDecisionIsAlgo = object.mifidInvestmentDecisionIsAlgo ?? false;
    message.senderLocationId = object.senderLocationId ?? "";
    message.senderOperatorId = object.senderOperatorId ?? "";
    message.operatorId = object.operatorId ?? "";
    message.fillCareOrderRequestId = object.fillCareOrderRequestId ?? "";
    return message;
  },
};

function createBaseTransactionStatus(): TransactionStatus {
  return {
    status: 0,
    transId: 0,
    transUtcTime: 0,
    transUtcTimestamp: undefined,
    refTransId: 0,
    origClOrderId: "",
    clOrderId: "",
    uint32FillQty: 0,
    fillQty: undefined,
    scaledFillPrice: 0,
    trades: [],
    strategyLegFills: [],
    syntheticHang: undefined,
    uint32PrevOrderQty: 0,
    prevOrderQty: undefined,
    uint32OrderQty: 0,
    orderQty: undefined,
    scaledPrevLimitPrice: 0,
    scaledLimitPrice: 0,
    scaledPrevStopPrice: 0,
    scaledStopPrice: 0,
    uint32PrevVisibleQty: 0,
    prevVisibleQty: undefined,
    uint32VisibleQty: 0,
    visibleQty: undefined,
    uint32PrevMinVisibleQty: 0,
    prevMinVisibleQty: undefined,
    uint32MinVisibleQty: 0,
    minVisibleQty: undefined,
    uint32PrevFillQty: 0,
    prevFillQty: undefined,
    scaledPrevFillPrice: 0,
    prevOrderType: 0,
    orderType: 0,
    prevExecInstructions: [],
    execInstructions: [],
    prevDuration: 0,
    duration: 0,
    prevGoodThruDate: 0,
    goodThruDate: 0,
    rejectCode: 0,
    fillCounterparty: "",
    routeClOrderId: "",
    textMessage: "",
    prevGoodThruUtcTime: 0,
    prevGoodThruUtcTimestamp: undefined,
    goodThruUtcTime: 0,
    goodThruUtcTimestamp: undefined,
    isAggressive: false,
    clientRegulatoryAlgorithmId: 0,
    effectiveRegulatoryAlgorithmId: 0,
    mifidAlgorithmId: "",
    mifidAlgorithmIdType: 0,
    mifidAppliedAlgorithmId: "",
    mifidAppliedAlgorithmIdType: 0,
    mifidExecutionDecision: "",
    mifidExecutionDecisionIsAlgo: false,
    openCloseEffect: 0,
    speculationType: 0,
    fillCommission: undefined,
    mifidInvestmentDecision: "",
    mifidInvestmentDecisionIsAlgo: false,
    executionSourceCode: "",
    isAutomated: false,
    tradeMatchId: "",
    fillCareOrderRequestId: "",
    username: "",
  };
}

export const TransactionStatus = {
  encode(message: TransactionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).uint32(message.status);
    }
    if (message.transId !== 0) {
      writer.uint32(16).uint64(message.transId);
    }
    if (message.transUtcTime !== undefined && message.transUtcTime !== 0) {
      writer.uint32(24).sint64(message.transUtcTime);
    }
    if (message.transUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.transUtcTimestamp), writer.uint32(322).fork()).ldelim();
    }
    if (message.refTransId !== undefined && message.refTransId !== 0) {
      writer.uint32(32).uint64(message.refTransId);
    }
    if (message.origClOrderId !== undefined && message.origClOrderId !== "") {
      writer.uint32(42).string(message.origClOrderId);
    }
    if (message.clOrderId !== "") {
      writer.uint32(50).string(message.clOrderId);
    }
    if (message.uint32FillQty !== undefined && message.uint32FillQty !== 0) {
      writer.uint32(56).uint32(message.uint32FillQty);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(418).fork()).ldelim();
    }
    if (message.scaledFillPrice !== undefined && message.scaledFillPrice !== 0) {
      writer.uint32(64).sint64(message.scaledFillPrice);
    }
    for (const v of message.trades) {
      Trade.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.strategyLegFills) {
      StrategyLegFill.encode(v!, writer.uint32(306).fork()).ldelim();
    }
    if (message.syntheticHang !== undefined) {
      SyntheticHang.encode(message.syntheticHang, writer.uint32(314).fork()).ldelim();
    }
    if (message.uint32PrevOrderQty !== undefined && message.uint32PrevOrderQty !== 0) {
      writer.uint32(80).uint32(message.uint32PrevOrderQty);
    }
    if (message.prevOrderQty !== undefined) {
      Decimal.encode(message.prevOrderQty, writer.uint32(426).fork()).ldelim();
    }
    if (message.uint32OrderQty !== undefined && message.uint32OrderQty !== 0) {
      writer.uint32(88).uint32(message.uint32OrderQty);
    }
    if (message.orderQty !== undefined) {
      Decimal.encode(message.orderQty, writer.uint32(434).fork()).ldelim();
    }
    if (message.scaledPrevLimitPrice !== undefined && message.scaledPrevLimitPrice !== 0) {
      writer.uint32(96).sint64(message.scaledPrevLimitPrice);
    }
    if (message.scaledLimitPrice !== undefined && message.scaledLimitPrice !== 0) {
      writer.uint32(104).sint64(message.scaledLimitPrice);
    }
    if (message.scaledPrevStopPrice !== undefined && message.scaledPrevStopPrice !== 0) {
      writer.uint32(112).sint64(message.scaledPrevStopPrice);
    }
    if (message.scaledStopPrice !== undefined && message.scaledStopPrice !== 0) {
      writer.uint32(120).sint64(message.scaledStopPrice);
    }
    if (message.uint32PrevVisibleQty !== undefined && message.uint32PrevVisibleQty !== 0) {
      writer.uint32(128).uint32(message.uint32PrevVisibleQty);
    }
    if (message.prevVisibleQty !== undefined) {
      Decimal.encode(message.prevVisibleQty, writer.uint32(442).fork()).ldelim();
    }
    if (message.uint32VisibleQty !== undefined && message.uint32VisibleQty !== 0) {
      writer.uint32(136).uint32(message.uint32VisibleQty);
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(450).fork()).ldelim();
    }
    if (message.uint32PrevMinVisibleQty !== undefined && message.uint32PrevMinVisibleQty !== 0) {
      writer.uint32(144).uint32(message.uint32PrevMinVisibleQty);
    }
    if (message.prevMinVisibleQty !== undefined) {
      Decimal.encode(message.prevMinVisibleQty, writer.uint32(458).fork()).ldelim();
    }
    if (message.uint32MinVisibleQty !== undefined && message.uint32MinVisibleQty !== 0) {
      writer.uint32(152).uint32(message.uint32MinVisibleQty);
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(466).fork()).ldelim();
    }
    if (message.uint32PrevFillQty !== undefined && message.uint32PrevFillQty !== 0) {
      writer.uint32(160).uint32(message.uint32PrevFillQty);
    }
    if (message.prevFillQty !== undefined) {
      Decimal.encode(message.prevFillQty, writer.uint32(474).fork()).ldelim();
    }
    if (message.scaledPrevFillPrice !== undefined && message.scaledPrevFillPrice !== 0) {
      writer.uint32(168).sint64(message.scaledPrevFillPrice);
    }
    if (message.prevOrderType !== undefined && message.prevOrderType !== 0) {
      writer.uint32(176).uint32(message.prevOrderType);
    }
    if (message.orderType !== undefined && message.orderType !== 0) {
      writer.uint32(184).uint32(message.orderType);
    }
    writer.uint32(194).fork();
    for (const v of message.prevExecInstructions) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(202).fork();
    for (const v of message.execInstructions) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.prevDuration !== undefined && message.prevDuration !== 0) {
      writer.uint32(208).uint32(message.prevDuration);
    }
    if (message.duration !== undefined && message.duration !== 0) {
      writer.uint32(216).uint32(message.duration);
    }
    if (message.prevGoodThruDate !== undefined && message.prevGoodThruDate !== 0) {
      writer.uint32(224).sint64(message.prevGoodThruDate);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      writer.uint32(232).sint64(message.goodThruDate);
    }
    if (message.rejectCode !== undefined && message.rejectCode !== 0) {
      writer.uint32(240).uint32(message.rejectCode);
    }
    if (message.fillCounterparty !== undefined && message.fillCounterparty !== "") {
      writer.uint32(250).string(message.fillCounterparty);
    }
    if (message.routeClOrderId !== undefined && message.routeClOrderId !== "") {
      writer.uint32(258).string(message.routeClOrderId);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(266).string(message.textMessage);
    }
    if (message.prevGoodThruUtcTime !== undefined && message.prevGoodThruUtcTime !== 0) {
      writer.uint32(272).sint64(message.prevGoodThruUtcTime);
    }
    if (message.prevGoodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.prevGoodThruUtcTimestamp), writer.uint32(330).fork()).ldelim();
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      writer.uint32(280).sint64(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.goodThruUtcTimestamp), writer.uint32(338).fork()).ldelim();
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      writer.uint32(512).bool(message.isAggressive);
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== 0) {
      writer.uint32(288).uint32(message.clientRegulatoryAlgorithmId);
    }
    if (message.effectiveRegulatoryAlgorithmId !== undefined && message.effectiveRegulatoryAlgorithmId !== 0) {
      writer.uint32(296).uint32(message.effectiveRegulatoryAlgorithmId);
    }
    if (message.mifidAlgorithmId !== undefined && message.mifidAlgorithmId !== "") {
      writer.uint32(346).string(message.mifidAlgorithmId);
    }
    if (message.mifidAlgorithmIdType !== undefined && message.mifidAlgorithmIdType !== 0) {
      writer.uint32(352).uint32(message.mifidAlgorithmIdType);
    }
    if (message.mifidAppliedAlgorithmId !== undefined && message.mifidAppliedAlgorithmId !== "") {
      writer.uint32(362).string(message.mifidAppliedAlgorithmId);
    }
    if (message.mifidAppliedAlgorithmIdType !== undefined && message.mifidAppliedAlgorithmIdType !== 0) {
      writer.uint32(368).uint32(message.mifidAppliedAlgorithmIdType);
    }
    if (message.mifidExecutionDecision !== undefined && message.mifidExecutionDecision !== "") {
      writer.uint32(378).string(message.mifidExecutionDecision);
    }
    if (message.mifidExecutionDecisionIsAlgo !== undefined && message.mifidExecutionDecisionIsAlgo !== false) {
      writer.uint32(536).bool(message.mifidExecutionDecisionIsAlgo);
    }
    if (message.openCloseEffect !== undefined && message.openCloseEffect !== 0) {
      writer.uint32(384).uint32(message.openCloseEffect);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(392).uint32(message.speculationType);
    }
    if (message.fillCommission !== undefined) {
      Commission.encode(message.fillCommission, writer.uint32(482).fork()).ldelim();
    }
    if (message.mifidInvestmentDecision !== undefined && message.mifidInvestmentDecision !== "") {
      writer.uint32(490).string(message.mifidInvestmentDecision);
    }
    if (message.mifidInvestmentDecisionIsAlgo !== undefined && message.mifidInvestmentDecisionIsAlgo !== false) {
      writer.uint32(496).bool(message.mifidInvestmentDecisionIsAlgo);
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      writer.uint32(506).string(message.executionSourceCode);
    }
    if (message.isAutomated !== undefined && message.isAutomated !== false) {
      writer.uint32(520).bool(message.isAutomated);
    }
    if (message.tradeMatchId !== undefined && message.tradeMatchId !== "") {
      writer.uint32(530).string(message.tradeMatchId);
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      writer.uint32(546).string(message.fillCareOrderRequestId);
    }
    if (message.username !== undefined && message.username !== "") {
      writer.uint32(554).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.transId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.transUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.refTransId = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.origClOrderId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.clOrderId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.uint32FillQty = reader.uint32();
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.fillQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.scaledFillPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.trades.push(Trade.decode(reader, reader.uint32()));
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.strategyLegFills.push(StrategyLegFill.decode(reader, reader.uint32()));
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.syntheticHang = SyntheticHang.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.uint32PrevOrderQty = reader.uint32();
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.prevOrderQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.uint32OrderQty = reader.uint32();
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.orderQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.scaledPrevLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.scaledLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.scaledPrevStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.scaledStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.uint32PrevVisibleQty = reader.uint32();
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.prevVisibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.uint32VisibleQty = reader.uint32();
          continue;
        case 56:
          if (tag !== 450) {
            break;
          }

          message.visibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.uint32PrevMinVisibleQty = reader.uint32();
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.prevMinVisibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.uint32MinVisibleQty = reader.uint32();
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.uint32PrevFillQty = reader.uint32();
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.prevFillQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.scaledPrevFillPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.prevOrderType = reader.uint32();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.orderType = reader.uint32();
          continue;
        case 24:
          if (tag === 192) {
            message.prevExecInstructions.push(reader.uint32());

            continue;
          }

          if (tag === 194) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.prevExecInstructions.push(reader.uint32());
            }

            continue;
          }

          break;
        case 25:
          if (tag === 200) {
            message.execInstructions.push(reader.uint32());

            continue;
          }

          if (tag === 202) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.execInstructions.push(reader.uint32());
            }

            continue;
          }

          break;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.prevDuration = reader.uint32();
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.duration = reader.uint32();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.prevGoodThruDate = longToNumber(reader.sint64() as Long);
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.goodThruDate = longToNumber(reader.sint64() as Long);
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.rejectCode = reader.uint32();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.fillCounterparty = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.routeClOrderId = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.prevGoodThruUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.prevGoodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.goodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 64:
          if (tag !== 512) {
            break;
          }

          message.isAggressive = reader.bool();
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.clientRegulatoryAlgorithmId = reader.uint32();
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.effectiveRegulatoryAlgorithmId = reader.uint32();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.mifidAlgorithmId = reader.string();
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.mifidAlgorithmIdType = reader.uint32();
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.mifidAppliedAlgorithmId = reader.string();
          continue;
        case 46:
          if (tag !== 368) {
            break;
          }

          message.mifidAppliedAlgorithmIdType = reader.uint32();
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.mifidExecutionDecision = reader.string();
          continue;
        case 67:
          if (tag !== 536) {
            break;
          }

          message.mifidExecutionDecisionIsAlgo = reader.bool();
          continue;
        case 48:
          if (tag !== 384) {
            break;
          }

          message.openCloseEffect = reader.uint32();
          continue;
        case 49:
          if (tag !== 392) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 60:
          if (tag !== 482) {
            break;
          }

          message.fillCommission = Commission.decode(reader, reader.uint32());
          continue;
        case 61:
          if (tag !== 490) {
            break;
          }

          message.mifidInvestmentDecision = reader.string();
          continue;
        case 62:
          if (tag !== 496) {
            break;
          }

          message.mifidInvestmentDecisionIsAlgo = reader.bool();
          continue;
        case 63:
          if (tag !== 506) {
            break;
          }

          message.executionSourceCode = reader.string();
          continue;
        case 65:
          if (tag !== 520) {
            break;
          }

          message.isAutomated = reader.bool();
          continue;
        case 66:
          if (tag !== 530) {
            break;
          }

          message.tradeMatchId = reader.string();
          continue;
        case 68:
          if (tag !== 546) {
            break;
          }

          message.fillCareOrderRequestId = reader.string();
          continue;
        case 69:
          if (tag !== 554) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionStatus {
    return {
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      transId: isSet(object.transId) ? globalThis.Number(object.transId) : 0,
      transUtcTime: isSet(object.transUtcTime) ? globalThis.Number(object.transUtcTime) : 0,
      transUtcTimestamp: isSet(object.transUtcTimestamp) ? fromJsonTimestamp(object.transUtcTimestamp) : undefined,
      refTransId: isSet(object.refTransId) ? globalThis.Number(object.refTransId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? globalThis.String(object.origClOrderId) : "",
      clOrderId: isSet(object.clOrderId) ? globalThis.String(object.clOrderId) : "",
      uint32FillQty: isSet(object.uint32FillQty) ? globalThis.Number(object.uint32FillQty) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
      scaledFillPrice: isSet(object.scaledFillPrice) ? globalThis.Number(object.scaledFillPrice) : 0,
      trades: globalThis.Array.isArray(object?.trades) ? object.trades.map((e: any) => Trade.fromJSON(e)) : [],
      strategyLegFills: globalThis.Array.isArray(object?.strategyLegFills)
        ? object.strategyLegFills.map((e: any) => StrategyLegFill.fromJSON(e))
        : [],
      syntheticHang: isSet(object.syntheticHang) ? SyntheticHang.fromJSON(object.syntheticHang) : undefined,
      uint32PrevOrderQty: isSet(object.uint32PrevOrderQty) ? globalThis.Number(object.uint32PrevOrderQty) : 0,
      prevOrderQty: isSet(object.prevOrderQty) ? Decimal.fromJSON(object.prevOrderQty) : undefined,
      uint32OrderQty: isSet(object.uint32OrderQty) ? globalThis.Number(object.uint32OrderQty) : 0,
      orderQty: isSet(object.orderQty) ? Decimal.fromJSON(object.orderQty) : undefined,
      scaledPrevLimitPrice: isSet(object.scaledPrevLimitPrice) ? globalThis.Number(object.scaledPrevLimitPrice) : 0,
      scaledLimitPrice: isSet(object.scaledLimitPrice) ? globalThis.Number(object.scaledLimitPrice) : 0,
      scaledPrevStopPrice: isSet(object.scaledPrevStopPrice) ? globalThis.Number(object.scaledPrevStopPrice) : 0,
      scaledStopPrice: isSet(object.scaledStopPrice) ? globalThis.Number(object.scaledStopPrice) : 0,
      uint32PrevVisibleQty: isSet(object.uint32PrevVisibleQty) ? globalThis.Number(object.uint32PrevVisibleQty) : 0,
      prevVisibleQty: isSet(object.prevVisibleQty) ? Decimal.fromJSON(object.prevVisibleQty) : undefined,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? globalThis.Number(object.uint32VisibleQty) : 0,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      uint32PrevMinVisibleQty: isSet(object.uint32PrevMinVisibleQty)
        ? globalThis.Number(object.uint32PrevMinVisibleQty)
        : 0,
      prevMinVisibleQty: isSet(object.prevMinVisibleQty) ? Decimal.fromJSON(object.prevMinVisibleQty) : undefined,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? globalThis.Number(object.uint32MinVisibleQty) : 0,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      uint32PrevFillQty: isSet(object.uint32PrevFillQty) ? globalThis.Number(object.uint32PrevFillQty) : 0,
      prevFillQty: isSet(object.prevFillQty) ? Decimal.fromJSON(object.prevFillQty) : undefined,
      scaledPrevFillPrice: isSet(object.scaledPrevFillPrice) ? globalThis.Number(object.scaledPrevFillPrice) : 0,
      prevOrderType: isSet(object.prevOrderType) ? globalThis.Number(object.prevOrderType) : 0,
      orderType: isSet(object.orderType) ? globalThis.Number(object.orderType) : 0,
      prevExecInstructions: globalThis.Array.isArray(object?.prevExecInstructions)
        ? object.prevExecInstructions.map((e: any) => globalThis.Number(e))
        : [],
      execInstructions: globalThis.Array.isArray(object?.execInstructions)
        ? object.execInstructions.map((e: any) => globalThis.Number(e))
        : [],
      prevDuration: isSet(object.prevDuration) ? globalThis.Number(object.prevDuration) : 0,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      prevGoodThruDate: isSet(object.prevGoodThruDate) ? globalThis.Number(object.prevGoodThruDate) : 0,
      goodThruDate: isSet(object.goodThruDate) ? globalThis.Number(object.goodThruDate) : 0,
      rejectCode: isSet(object.rejectCode) ? globalThis.Number(object.rejectCode) : 0,
      fillCounterparty: isSet(object.fillCounterparty) ? globalThis.String(object.fillCounterparty) : "",
      routeClOrderId: isSet(object.routeClOrderId) ? globalThis.String(object.routeClOrderId) : "",
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      prevGoodThruUtcTime: isSet(object.prevGoodThruUtcTime) ? globalThis.Number(object.prevGoodThruUtcTime) : 0,
      prevGoodThruUtcTimestamp: isSet(object.prevGoodThruUtcTimestamp)
        ? fromJsonTimestamp(object.prevGoodThruUtcTimestamp)
        : undefined,
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? globalThis.Number(object.goodThruUtcTime) : 0,
      goodThruUtcTimestamp: isSet(object.goodThruUtcTimestamp)
        ? fromJsonTimestamp(object.goodThruUtcTimestamp)
        : undefined,
      isAggressive: isSet(object.isAggressive) ? globalThis.Boolean(object.isAggressive) : false,
      clientRegulatoryAlgorithmId: isSet(object.clientRegulatoryAlgorithmId)
        ? globalThis.Number(object.clientRegulatoryAlgorithmId)
        : 0,
      effectiveRegulatoryAlgorithmId: isSet(object.effectiveRegulatoryAlgorithmId)
        ? globalThis.Number(object.effectiveRegulatoryAlgorithmId)
        : 0,
      mifidAlgorithmId: isSet(object.mifidAlgorithmId) ? globalThis.String(object.mifidAlgorithmId) : "",
      mifidAlgorithmIdType: isSet(object.mifidAlgorithmIdType) ? globalThis.Number(object.mifidAlgorithmIdType) : 0,
      mifidAppliedAlgorithmId: isSet(object.mifidAppliedAlgorithmId)
        ? globalThis.String(object.mifidAppliedAlgorithmId)
        : "",
      mifidAppliedAlgorithmIdType: isSet(object.mifidAppliedAlgorithmIdType)
        ? globalThis.Number(object.mifidAppliedAlgorithmIdType)
        : 0,
      mifidExecutionDecision: isSet(object.mifidExecutionDecision)
        ? globalThis.String(object.mifidExecutionDecision)
        : "",
      mifidExecutionDecisionIsAlgo: isSet(object.mifidExecutionDecisionIsAlgo)
        ? globalThis.Boolean(object.mifidExecutionDecisionIsAlgo)
        : false,
      openCloseEffect: isSet(object.openCloseEffect) ? globalThis.Number(object.openCloseEffect) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      fillCommission: isSet(object.fillCommission) ? Commission.fromJSON(object.fillCommission) : undefined,
      mifidInvestmentDecision: isSet(object.mifidInvestmentDecision)
        ? globalThis.String(object.mifidInvestmentDecision)
        : "",
      mifidInvestmentDecisionIsAlgo: isSet(object.mifidInvestmentDecisionIsAlgo)
        ? globalThis.Boolean(object.mifidInvestmentDecisionIsAlgo)
        : false,
      executionSourceCode: isSet(object.executionSourceCode) ? globalThis.String(object.executionSourceCode) : "",
      isAutomated: isSet(object.isAutomated) ? globalThis.Boolean(object.isAutomated) : false,
      tradeMatchId: isSet(object.tradeMatchId) ? globalThis.String(object.tradeMatchId) : "",
      fillCareOrderRequestId: isSet(object.fillCareOrderRequestId)
        ? globalThis.String(object.fillCareOrderRequestId)
        : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
    };
  },

  toJSON(message: TransactionStatus): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.transId !== 0) {
      obj.transId = Math.round(message.transId);
    }
    if (message.transUtcTime !== undefined && message.transUtcTime !== 0) {
      obj.transUtcTime = Math.round(message.transUtcTime);
    }
    if (message.transUtcTimestamp !== undefined) {
      obj.transUtcTimestamp = message.transUtcTimestamp.toISOString();
    }
    if (message.refTransId !== undefined && message.refTransId !== 0) {
      obj.refTransId = Math.round(message.refTransId);
    }
    if (message.origClOrderId !== undefined && message.origClOrderId !== "") {
      obj.origClOrderId = message.origClOrderId;
    }
    if (message.clOrderId !== "") {
      obj.clOrderId = message.clOrderId;
    }
    if (message.uint32FillQty !== undefined && message.uint32FillQty !== 0) {
      obj.uint32FillQty = Math.round(message.uint32FillQty);
    }
    if (message.fillQty !== undefined) {
      obj.fillQty = Decimal.toJSON(message.fillQty);
    }
    if (message.scaledFillPrice !== undefined && message.scaledFillPrice !== 0) {
      obj.scaledFillPrice = Math.round(message.scaledFillPrice);
    }
    if (message.trades?.length) {
      obj.trades = message.trades.map((e) => Trade.toJSON(e));
    }
    if (message.strategyLegFills?.length) {
      obj.strategyLegFills = message.strategyLegFills.map((e) => StrategyLegFill.toJSON(e));
    }
    if (message.syntheticHang !== undefined) {
      obj.syntheticHang = SyntheticHang.toJSON(message.syntheticHang);
    }
    if (message.uint32PrevOrderQty !== undefined && message.uint32PrevOrderQty !== 0) {
      obj.uint32PrevOrderQty = Math.round(message.uint32PrevOrderQty);
    }
    if (message.prevOrderQty !== undefined) {
      obj.prevOrderQty = Decimal.toJSON(message.prevOrderQty);
    }
    if (message.uint32OrderQty !== undefined && message.uint32OrderQty !== 0) {
      obj.uint32OrderQty = Math.round(message.uint32OrderQty);
    }
    if (message.orderQty !== undefined) {
      obj.orderQty = Decimal.toJSON(message.orderQty);
    }
    if (message.scaledPrevLimitPrice !== undefined && message.scaledPrevLimitPrice !== 0) {
      obj.scaledPrevLimitPrice = Math.round(message.scaledPrevLimitPrice);
    }
    if (message.scaledLimitPrice !== undefined && message.scaledLimitPrice !== 0) {
      obj.scaledLimitPrice = Math.round(message.scaledLimitPrice);
    }
    if (message.scaledPrevStopPrice !== undefined && message.scaledPrevStopPrice !== 0) {
      obj.scaledPrevStopPrice = Math.round(message.scaledPrevStopPrice);
    }
    if (message.scaledStopPrice !== undefined && message.scaledStopPrice !== 0) {
      obj.scaledStopPrice = Math.round(message.scaledStopPrice);
    }
    if (message.uint32PrevVisibleQty !== undefined && message.uint32PrevVisibleQty !== 0) {
      obj.uint32PrevVisibleQty = Math.round(message.uint32PrevVisibleQty);
    }
    if (message.prevVisibleQty !== undefined) {
      obj.prevVisibleQty = Decimal.toJSON(message.prevVisibleQty);
    }
    if (message.uint32VisibleQty !== undefined && message.uint32VisibleQty !== 0) {
      obj.uint32VisibleQty = Math.round(message.uint32VisibleQty);
    }
    if (message.visibleQty !== undefined) {
      obj.visibleQty = Decimal.toJSON(message.visibleQty);
    }
    if (message.uint32PrevMinVisibleQty !== undefined && message.uint32PrevMinVisibleQty !== 0) {
      obj.uint32PrevMinVisibleQty = Math.round(message.uint32PrevMinVisibleQty);
    }
    if (message.prevMinVisibleQty !== undefined) {
      obj.prevMinVisibleQty = Decimal.toJSON(message.prevMinVisibleQty);
    }
    if (message.uint32MinVisibleQty !== undefined && message.uint32MinVisibleQty !== 0) {
      obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty);
    }
    if (message.minVisibleQty !== undefined) {
      obj.minVisibleQty = Decimal.toJSON(message.minVisibleQty);
    }
    if (message.uint32PrevFillQty !== undefined && message.uint32PrevFillQty !== 0) {
      obj.uint32PrevFillQty = Math.round(message.uint32PrevFillQty);
    }
    if (message.prevFillQty !== undefined) {
      obj.prevFillQty = Decimal.toJSON(message.prevFillQty);
    }
    if (message.scaledPrevFillPrice !== undefined && message.scaledPrevFillPrice !== 0) {
      obj.scaledPrevFillPrice = Math.round(message.scaledPrevFillPrice);
    }
    if (message.prevOrderType !== undefined && message.prevOrderType !== 0) {
      obj.prevOrderType = Math.round(message.prevOrderType);
    }
    if (message.orderType !== undefined && message.orderType !== 0) {
      obj.orderType = Math.round(message.orderType);
    }
    if (message.prevExecInstructions?.length) {
      obj.prevExecInstructions = message.prevExecInstructions.map((e) => Math.round(e));
    }
    if (message.execInstructions?.length) {
      obj.execInstructions = message.execInstructions.map((e) => Math.round(e));
    }
    if (message.prevDuration !== undefined && message.prevDuration !== 0) {
      obj.prevDuration = Math.round(message.prevDuration);
    }
    if (message.duration !== undefined && message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.prevGoodThruDate !== undefined && message.prevGoodThruDate !== 0) {
      obj.prevGoodThruDate = Math.round(message.prevGoodThruDate);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      obj.goodThruDate = Math.round(message.goodThruDate);
    }
    if (message.rejectCode !== undefined && message.rejectCode !== 0) {
      obj.rejectCode = Math.round(message.rejectCode);
    }
    if (message.fillCounterparty !== undefined && message.fillCounterparty !== "") {
      obj.fillCounterparty = message.fillCounterparty;
    }
    if (message.routeClOrderId !== undefined && message.routeClOrderId !== "") {
      obj.routeClOrderId = message.routeClOrderId;
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.prevGoodThruUtcTime !== undefined && message.prevGoodThruUtcTime !== 0) {
      obj.prevGoodThruUtcTime = Math.round(message.prevGoodThruUtcTime);
    }
    if (message.prevGoodThruUtcTimestamp !== undefined) {
      obj.prevGoodThruUtcTimestamp = message.prevGoodThruUtcTimestamp.toISOString();
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      obj.goodThruUtcTime = Math.round(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      obj.goodThruUtcTimestamp = message.goodThruUtcTimestamp.toISOString();
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      obj.isAggressive = message.isAggressive;
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== 0) {
      obj.clientRegulatoryAlgorithmId = Math.round(message.clientRegulatoryAlgorithmId);
    }
    if (message.effectiveRegulatoryAlgorithmId !== undefined && message.effectiveRegulatoryAlgorithmId !== 0) {
      obj.effectiveRegulatoryAlgorithmId = Math.round(message.effectiveRegulatoryAlgorithmId);
    }
    if (message.mifidAlgorithmId !== undefined && message.mifidAlgorithmId !== "") {
      obj.mifidAlgorithmId = message.mifidAlgorithmId;
    }
    if (message.mifidAlgorithmIdType !== undefined && message.mifidAlgorithmIdType !== 0) {
      obj.mifidAlgorithmIdType = Math.round(message.mifidAlgorithmIdType);
    }
    if (message.mifidAppliedAlgorithmId !== undefined && message.mifidAppliedAlgorithmId !== "") {
      obj.mifidAppliedAlgorithmId = message.mifidAppliedAlgorithmId;
    }
    if (message.mifidAppliedAlgorithmIdType !== undefined && message.mifidAppliedAlgorithmIdType !== 0) {
      obj.mifidAppliedAlgorithmIdType = Math.round(message.mifidAppliedAlgorithmIdType);
    }
    if (message.mifidExecutionDecision !== undefined && message.mifidExecutionDecision !== "") {
      obj.mifidExecutionDecision = message.mifidExecutionDecision;
    }
    if (message.mifidExecutionDecisionIsAlgo !== undefined && message.mifidExecutionDecisionIsAlgo !== false) {
      obj.mifidExecutionDecisionIsAlgo = message.mifidExecutionDecisionIsAlgo;
    }
    if (message.openCloseEffect !== undefined && message.openCloseEffect !== 0) {
      obj.openCloseEffect = Math.round(message.openCloseEffect);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.fillCommission !== undefined) {
      obj.fillCommission = Commission.toJSON(message.fillCommission);
    }
    if (message.mifidInvestmentDecision !== undefined && message.mifidInvestmentDecision !== "") {
      obj.mifidInvestmentDecision = message.mifidInvestmentDecision;
    }
    if (message.mifidInvestmentDecisionIsAlgo !== undefined && message.mifidInvestmentDecisionIsAlgo !== false) {
      obj.mifidInvestmentDecisionIsAlgo = message.mifidInvestmentDecisionIsAlgo;
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      obj.executionSourceCode = message.executionSourceCode;
    }
    if (message.isAutomated !== undefined && message.isAutomated !== false) {
      obj.isAutomated = message.isAutomated;
    }
    if (message.tradeMatchId !== undefined && message.tradeMatchId !== "") {
      obj.tradeMatchId = message.tradeMatchId;
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      obj.fillCareOrderRequestId = message.fillCareOrderRequestId;
    }
    if (message.username !== undefined && message.username !== "") {
      obj.username = message.username;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TransactionStatus>, I>>(base?: I): TransactionStatus {
    return TransactionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionStatus>, I>>(object: I): TransactionStatus {
    const message = createBaseTransactionStatus();
    message.status = object.status ?? 0;
    message.transId = object.transId ?? 0;
    message.transUtcTime = object.transUtcTime ?? 0;
    message.transUtcTimestamp = object.transUtcTimestamp ?? undefined;
    message.refTransId = object.refTransId ?? 0;
    message.origClOrderId = object.origClOrderId ?? "";
    message.clOrderId = object.clOrderId ?? "";
    message.uint32FillQty = object.uint32FillQty ?? 0;
    message.fillQty = (object.fillQty !== undefined && object.fillQty !== null)
      ? Decimal.fromPartial(object.fillQty)
      : undefined;
    message.scaledFillPrice = object.scaledFillPrice ?? 0;
    message.trades = object.trades?.map((e) => Trade.fromPartial(e)) || [];
    message.strategyLegFills = object.strategyLegFills?.map((e) => StrategyLegFill.fromPartial(e)) || [];
    message.syntheticHang = (object.syntheticHang !== undefined && object.syntheticHang !== null)
      ? SyntheticHang.fromPartial(object.syntheticHang)
      : undefined;
    message.uint32PrevOrderQty = object.uint32PrevOrderQty ?? 0;
    message.prevOrderQty = (object.prevOrderQty !== undefined && object.prevOrderQty !== null)
      ? Decimal.fromPartial(object.prevOrderQty)
      : undefined;
    message.uint32OrderQty = object.uint32OrderQty ?? 0;
    message.orderQty = (object.orderQty !== undefined && object.orderQty !== null)
      ? Decimal.fromPartial(object.orderQty)
      : undefined;
    message.scaledPrevLimitPrice = object.scaledPrevLimitPrice ?? 0;
    message.scaledLimitPrice = object.scaledLimitPrice ?? 0;
    message.scaledPrevStopPrice = object.scaledPrevStopPrice ?? 0;
    message.scaledStopPrice = object.scaledStopPrice ?? 0;
    message.uint32PrevVisibleQty = object.uint32PrevVisibleQty ?? 0;
    message.prevVisibleQty = (object.prevVisibleQty !== undefined && object.prevVisibleQty !== null)
      ? Decimal.fromPartial(object.prevVisibleQty)
      : undefined;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.visibleQty = (object.visibleQty !== undefined && object.visibleQty !== null)
      ? Decimal.fromPartial(object.visibleQty)
      : undefined;
    message.uint32PrevMinVisibleQty = object.uint32PrevMinVisibleQty ?? 0;
    message.prevMinVisibleQty = (object.prevMinVisibleQty !== undefined && object.prevMinVisibleQty !== null)
      ? Decimal.fromPartial(object.prevMinVisibleQty)
      : undefined;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.minVisibleQty = (object.minVisibleQty !== undefined && object.minVisibleQty !== null)
      ? Decimal.fromPartial(object.minVisibleQty)
      : undefined;
    message.uint32PrevFillQty = object.uint32PrevFillQty ?? 0;
    message.prevFillQty = (object.prevFillQty !== undefined && object.prevFillQty !== null)
      ? Decimal.fromPartial(object.prevFillQty)
      : undefined;
    message.scaledPrevFillPrice = object.scaledPrevFillPrice ?? 0;
    message.prevOrderType = object.prevOrderType ?? 0;
    message.orderType = object.orderType ?? 0;
    message.prevExecInstructions = object.prevExecInstructions?.map((e) => e) || [];
    message.execInstructions = object.execInstructions?.map((e) => e) || [];
    message.prevDuration = object.prevDuration ?? 0;
    message.duration = object.duration ?? 0;
    message.prevGoodThruDate = object.prevGoodThruDate ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.rejectCode = object.rejectCode ?? 0;
    message.fillCounterparty = object.fillCounterparty ?? "";
    message.routeClOrderId = object.routeClOrderId ?? "";
    message.textMessage = object.textMessage ?? "";
    message.prevGoodThruUtcTime = object.prevGoodThruUtcTime ?? 0;
    message.prevGoodThruUtcTimestamp = object.prevGoodThruUtcTimestamp ?? undefined;
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.goodThruUtcTimestamp = object.goodThruUtcTimestamp ?? undefined;
    message.isAggressive = object.isAggressive ?? false;
    message.clientRegulatoryAlgorithmId = object.clientRegulatoryAlgorithmId ?? 0;
    message.effectiveRegulatoryAlgorithmId = object.effectiveRegulatoryAlgorithmId ?? 0;
    message.mifidAlgorithmId = object.mifidAlgorithmId ?? "";
    message.mifidAlgorithmIdType = object.mifidAlgorithmIdType ?? 0;
    message.mifidAppliedAlgorithmId = object.mifidAppliedAlgorithmId ?? "";
    message.mifidAppliedAlgorithmIdType = object.mifidAppliedAlgorithmIdType ?? 0;
    message.mifidExecutionDecision = object.mifidExecutionDecision ?? "";
    message.mifidExecutionDecisionIsAlgo = object.mifidExecutionDecisionIsAlgo ?? false;
    message.openCloseEffect = object.openCloseEffect ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.fillCommission = (object.fillCommission !== undefined && object.fillCommission !== null)
      ? Commission.fromPartial(object.fillCommission)
      : undefined;
    message.mifidInvestmentDecision = object.mifidInvestmentDecision ?? "";
    message.mifidInvestmentDecisionIsAlgo = object.mifidInvestmentDecisionIsAlgo ?? false;
    message.executionSourceCode = object.executionSourceCode ?? "";
    message.isAutomated = object.isAutomated ?? false;
    message.tradeMatchId = object.tradeMatchId ?? "";
    message.fillCareOrderRequestId = object.fillCareOrderRequestId ?? "";
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseTrade(): Trade {
  return {
    tradeId: "",
    contractId: 0,
    statementDate: 0,
    tradeUtcTime: 0,
    tradeUtcTimestamp: undefined,
    tradeDate: 0,
    scaledPrice: 0,
    priceCorrect: 0,
    side: 0,
    uint32Qty: 0,
    qty: undefined,
    tradeCounterparty: "",
    isAggressive: false,
    legExecutionId: "",
    openCloseEffect: 0,
    speculationType: 0,
    tradeMatchId: "",
    specificContractId: 0,
    fillExtraAttributes: [],
  };
}

export const Trade = {
  encode(message: Trade, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tradeId !== "") {
      writer.uint32(10).string(message.tradeId);
    }
    if (message.contractId !== 0) {
      writer.uint32(16).uint32(message.contractId);
    }
    if (message.statementDate !== 0) {
      writer.uint32(24).sint64(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      writer.uint32(32).sint64(message.tradeUtcTime);
    }
    if (message.tradeUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.tradeUtcTimestamp), writer.uint32(98).fork()).ldelim();
    }
    if (message.tradeDate !== 0) {
      writer.uint32(40).sint64(message.tradeDate);
    }
    if (message.scaledPrice !== 0) {
      writer.uint32(48).sint64(message.scaledPrice);
    }
    if (message.priceCorrect !== 0) {
      writer.uint32(105).double(message.priceCorrect);
    }
    if (message.side !== 0) {
      writer.uint32(56).uint32(message.side);
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      writer.uint32(64).uint32(message.uint32Qty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(130).fork()).ldelim();
    }
    if (message.tradeCounterparty !== undefined && message.tradeCounterparty !== "") {
      writer.uint32(74).string(message.tradeCounterparty);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      writer.uint32(80).bool(message.isAggressive);
    }
    if (message.legExecutionId !== undefined && message.legExecutionId !== "") {
      writer.uint32(90).string(message.legExecutionId);
    }
    if (message.openCloseEffect !== undefined && message.openCloseEffect !== 0) {
      writer.uint32(112).uint32(message.openCloseEffect);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(120).uint32(message.speculationType);
    }
    if (message.tradeMatchId !== undefined && message.tradeMatchId !== "") {
      writer.uint32(138).string(message.tradeMatchId);
    }
    if (message.specificContractId !== undefined && message.specificContractId !== 0) {
      writer.uint32(144).uint32(message.specificContractId);
    }
    for (const v of message.fillExtraAttributes) {
      NamedValue.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Trade {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrade();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tradeId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.statementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tradeUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.tradeUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.tradeDate = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.scaledPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.priceCorrect = reader.double();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.uint32Qty = reader.uint32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tradeCounterparty = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isAggressive = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.legExecutionId = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.openCloseEffect = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.tradeMatchId = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.specificContractId = reader.uint32();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.fillExtraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Trade {
    return {
      tradeId: isSet(object.tradeId) ? globalThis.String(object.tradeId) : "",
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      statementDate: isSet(object.statementDate) ? globalThis.Number(object.statementDate) : 0,
      tradeUtcTime: isSet(object.tradeUtcTime) ? globalThis.Number(object.tradeUtcTime) : 0,
      tradeUtcTimestamp: isSet(object.tradeUtcTimestamp) ? fromJsonTimestamp(object.tradeUtcTimestamp) : undefined,
      tradeDate: isSet(object.tradeDate) ? globalThis.Number(object.tradeDate) : 0,
      scaledPrice: isSet(object.scaledPrice) ? globalThis.Number(object.scaledPrice) : 0,
      priceCorrect: isSet(object.priceCorrect) ? globalThis.Number(object.priceCorrect) : 0,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      uint32Qty: isSet(object.uint32Qty) ? globalThis.Number(object.uint32Qty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      tradeCounterparty: isSet(object.tradeCounterparty) ? globalThis.String(object.tradeCounterparty) : "",
      isAggressive: isSet(object.isAggressive) ? globalThis.Boolean(object.isAggressive) : false,
      legExecutionId: isSet(object.legExecutionId) ? globalThis.String(object.legExecutionId) : "",
      openCloseEffect: isSet(object.openCloseEffect) ? globalThis.Number(object.openCloseEffect) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      tradeMatchId: isSet(object.tradeMatchId) ? globalThis.String(object.tradeMatchId) : "",
      specificContractId: isSet(object.specificContractId) ? globalThis.Number(object.specificContractId) : 0,
      fillExtraAttributes: globalThis.Array.isArray(object?.fillExtraAttributes)
        ? object.fillExtraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Trade): unknown {
    const obj: any = {};
    if (message.tradeId !== "") {
      obj.tradeId = message.tradeId;
    }
    if (message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.statementDate !== 0) {
      obj.statementDate = Math.round(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      obj.tradeUtcTime = Math.round(message.tradeUtcTime);
    }
    if (message.tradeUtcTimestamp !== undefined) {
      obj.tradeUtcTimestamp = message.tradeUtcTimestamp.toISOString();
    }
    if (message.tradeDate !== 0) {
      obj.tradeDate = Math.round(message.tradeDate);
    }
    if (message.scaledPrice !== 0) {
      obj.scaledPrice = Math.round(message.scaledPrice);
    }
    if (message.priceCorrect !== 0) {
      obj.priceCorrect = message.priceCorrect;
    }
    if (message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      obj.uint32Qty = Math.round(message.uint32Qty);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.tradeCounterparty !== undefined && message.tradeCounterparty !== "") {
      obj.tradeCounterparty = message.tradeCounterparty;
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      obj.isAggressive = message.isAggressive;
    }
    if (message.legExecutionId !== undefined && message.legExecutionId !== "") {
      obj.legExecutionId = message.legExecutionId;
    }
    if (message.openCloseEffect !== undefined && message.openCloseEffect !== 0) {
      obj.openCloseEffect = Math.round(message.openCloseEffect);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.tradeMatchId !== undefined && message.tradeMatchId !== "") {
      obj.tradeMatchId = message.tradeMatchId;
    }
    if (message.specificContractId !== undefined && message.specificContractId !== 0) {
      obj.specificContractId = Math.round(message.specificContractId);
    }
    if (message.fillExtraAttributes?.length) {
      obj.fillExtraAttributes = message.fillExtraAttributes.map((e) => NamedValue.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Trade>, I>>(base?: I): Trade {
    return Trade.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Trade>, I>>(object: I): Trade {
    const message = createBaseTrade();
    message.tradeId = object.tradeId ?? "";
    message.contractId = object.contractId ?? 0;
    message.statementDate = object.statementDate ?? 0;
    message.tradeUtcTime = object.tradeUtcTime ?? 0;
    message.tradeUtcTimestamp = object.tradeUtcTimestamp ?? undefined;
    message.tradeDate = object.tradeDate ?? 0;
    message.scaledPrice = object.scaledPrice ?? 0;
    message.priceCorrect = object.priceCorrect ?? 0;
    message.side = object.side ?? 0;
    message.uint32Qty = object.uint32Qty ?? 0;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.tradeCounterparty = object.tradeCounterparty ?? "";
    message.isAggressive = object.isAggressive ?? false;
    message.legExecutionId = object.legExecutionId ?? "";
    message.openCloseEffect = object.openCloseEffect ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.tradeMatchId = object.tradeMatchId ?? "";
    message.specificContractId = object.specificContractId ?? 0;
    message.fillExtraAttributes = object.fillExtraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCommission(): Commission {
  return { commissionCurrency: "", commission: 0 };
}

export const Commission = {
  encode(message: Commission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.commissionCurrency !== "") {
      writer.uint32(10).string(message.commissionCurrency);
    }
    if (message.commission !== 0) {
      writer.uint32(17).double(message.commission);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.commissionCurrency = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.commission = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission {
    return {
      commissionCurrency: isSet(object.commissionCurrency) ? globalThis.String(object.commissionCurrency) : "",
      commission: isSet(object.commission) ? globalThis.Number(object.commission) : 0,
    };
  },

  toJSON(message: Commission): unknown {
    const obj: any = {};
    if (message.commissionCurrency !== "") {
      obj.commissionCurrency = message.commissionCurrency;
    }
    if (message.commission !== 0) {
      obj.commission = message.commission;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission>, I>>(base?: I): Commission {
    return Commission.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Commission>, I>>(object: I): Commission {
    const message = createBaseCommission();
    message.commissionCurrency = object.commissionCurrency ?? "";
    message.commission = object.commission ?? 0;
    return message;
  },
};

function createBaseCompoundOrderStructure(): CompoundOrderStructure {
  return { type: 0, clCompoundId: "", compoundOrderEntries: [], profitOffset: 0, lossOffset: 0, stopLimitOffset: 0 };
}

export const CompoundOrderStructure = {
  encode(message: CompoundOrderStructure, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.clCompoundId !== "") {
      writer.uint32(18).string(message.clCompoundId);
    }
    for (const v of message.compoundOrderEntries) {
      CompoundOrderStructureEntry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.profitOffset !== undefined && message.profitOffset !== 0) {
      writer.uint32(32).sint32(message.profitOffset);
    }
    if (message.lossOffset !== undefined && message.lossOffset !== 0) {
      writer.uint32(40).sint32(message.lossOffset);
    }
    if (message.stopLimitOffset !== undefined && message.stopLimitOffset !== 0) {
      writer.uint32(48).sint32(message.stopLimitOffset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderStructure {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderStructure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clCompoundId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.compoundOrderEntries.push(CompoundOrderStructureEntry.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.profitOffset = reader.sint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lossOffset = reader.sint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.stopLimitOffset = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderStructure {
    return {
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      clCompoundId: isSet(object.clCompoundId) ? globalThis.String(object.clCompoundId) : "",
      compoundOrderEntries: globalThis.Array.isArray(object?.compoundOrderEntries)
        ? object.compoundOrderEntries.map((e: any) => CompoundOrderStructureEntry.fromJSON(e))
        : [],
      profitOffset: isSet(object.profitOffset) ? globalThis.Number(object.profitOffset) : 0,
      lossOffset: isSet(object.lossOffset) ? globalThis.Number(object.lossOffset) : 0,
      stopLimitOffset: isSet(object.stopLimitOffset) ? globalThis.Number(object.stopLimitOffset) : 0,
    };
  },

  toJSON(message: CompoundOrderStructure): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.clCompoundId !== "") {
      obj.clCompoundId = message.clCompoundId;
    }
    if (message.compoundOrderEntries?.length) {
      obj.compoundOrderEntries = message.compoundOrderEntries.map((e) => CompoundOrderStructureEntry.toJSON(e));
    }
    if (message.profitOffset !== undefined && message.profitOffset !== 0) {
      obj.profitOffset = Math.round(message.profitOffset);
    }
    if (message.lossOffset !== undefined && message.lossOffset !== 0) {
      obj.lossOffset = Math.round(message.lossOffset);
    }
    if (message.stopLimitOffset !== undefined && message.stopLimitOffset !== 0) {
      obj.stopLimitOffset = Math.round(message.stopLimitOffset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderStructure>, I>>(base?: I): CompoundOrderStructure {
    return CompoundOrderStructure.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompoundOrderStructure>, I>>(object: I): CompoundOrderStructure {
    const message = createBaseCompoundOrderStructure();
    message.type = object.type ?? 0;
    message.clCompoundId = object.clCompoundId ?? "";
    message.compoundOrderEntries =
      object.compoundOrderEntries?.map((e) => CompoundOrderStructureEntry.fromPartial(e)) || [];
    message.profitOffset = object.profitOffset ?? 0;
    message.lossOffset = object.lossOffset ?? 0;
    message.stopLimitOffset = object.stopLimitOffset ?? 0;
    return message;
  },
};

function createBaseCompoundOrderStructureEntry(): CompoundOrderStructureEntry {
  return { chainOrderId: "", compoundOrderStructure: undefined };
}

export const CompoundOrderStructureEntry = {
  encode(message: CompoundOrderStructureEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.compoundOrderStructure !== undefined) {
      CompoundOrderStructure.encode(message.compoundOrderStructure, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderStructureEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderStructureEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.compoundOrderStructure = CompoundOrderStructure.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderStructureEntry {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      compoundOrderStructure: isSet(object.compoundOrderStructure)
        ? CompoundOrderStructure.fromJSON(object.compoundOrderStructure)
        : undefined,
    };
  },

  toJSON(message: CompoundOrderStructureEntry): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.compoundOrderStructure !== undefined) {
      obj.compoundOrderStructure = CompoundOrderStructure.toJSON(message.compoundOrderStructure);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderStructureEntry>, I>>(base?: I): CompoundOrderStructureEntry {
    return CompoundOrderStructureEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompoundOrderStructureEntry>, I>>(object: I): CompoundOrderStructureEntry {
    const message = createBaseCompoundOrderStructureEntry();
    message.chainOrderId = object.chainOrderId ?? "";
    message.compoundOrderStructure =
      (object.compoundOrderStructure !== undefined && object.compoundOrderStructure !== null)
        ? CompoundOrderStructure.fromPartial(object.compoundOrderStructure)
        : undefined;
    return message;
  },
};

function createBaseOrder(): Order {
  return {
    accountId: 0,
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    contractId: 0,
    clOrderId: "",
    orderType: 0,
    execInstructions: [],
    duration: 0,
    goodThruDate: 0,
    side: 0,
    scaledLimitPrice: 0,
    scaledStopPrice: 0,
    uint32Qty: 0,
    uint32VisibleQty: 0,
    uint32MinVisibleQty: 0,
    qty: undefined,
    visibleQty: undefined,
    minVisibleQty: undefined,
    isManual: false,
    isClose: false,
    isAggressive: false,
    scaledTrailOffset: 0,
    trailingPeg: 0,
    uint32TriggerQty: 0,
    triggerQty: undefined,
    activationUtcTime: 0,
    activationUtcTimestamp: undefined,
    suspensionUtcTime: 0,
    suspensionUtcTimestamp: undefined,
    userAttributes: [],
    extraAttributes: [],
    algoStrategy: "",
    goodThruUtcTime: 0,
    goodThruUtcTimestamp: undefined,
    isUserAttributeChecked: true,
    strategyTradingParameters: undefined,
    openCloseInstruction: 0,
    speculationType: 0,
    contributorId: "",
    executionSourceCode: "",
    crossOrderParameters: undefined,
    externalAccountNumber: "",
    isCareOrder: false,
  };
}

export const Order = {
  encode(message: Order, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      writer.uint32(16).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(218).fork()).ldelim();
    }
    if (message.contractId !== 0) {
      writer.uint32(24).uint32(message.contractId);
    }
    if (message.clOrderId !== "") {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.orderType !== 0) {
      writer.uint32(40).uint32(message.orderType);
    }
    writer.uint32(50).fork();
    for (const v of message.execInstructions) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.duration !== 0) {
      writer.uint32(56).uint32(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      writer.uint32(64).sint64(message.goodThruDate);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(72).uint32(message.side);
    }
    if (message.scaledLimitPrice !== undefined && message.scaledLimitPrice !== 0) {
      writer.uint32(80).sint64(message.scaledLimitPrice);
    }
    if (message.scaledStopPrice !== undefined && message.scaledStopPrice !== 0) {
      writer.uint32(88).sint64(message.scaledStopPrice);
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      writer.uint32(96).uint32(message.uint32Qty);
    }
    if (message.uint32VisibleQty !== undefined && message.uint32VisibleQty !== 0) {
      writer.uint32(104).uint32(message.uint32VisibleQty);
    }
    if (message.uint32MinVisibleQty !== undefined && message.uint32MinVisibleQty !== 0) {
      writer.uint32(112).uint32(message.uint32MinVisibleQty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(274).fork()).ldelim();
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(282).fork()).ldelim();
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(290).fork()).ldelim();
    }
    if (message.isManual !== undefined && message.isManual !== false) {
      writer.uint32(120).bool(message.isManual);
    }
    if (message.isClose !== undefined && message.isClose !== false) {
      writer.uint32(128).bool(message.isClose);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      writer.uint32(136).bool(message.isAggressive);
    }
    if (message.scaledTrailOffset !== undefined && message.scaledTrailOffset !== 0) {
      writer.uint32(144).sint32(message.scaledTrailOffset);
    }
    if (message.trailingPeg !== undefined && message.trailingPeg !== 0) {
      writer.uint32(152).uint32(message.trailingPeg);
    }
    if (message.uint32TriggerQty !== undefined && message.uint32TriggerQty !== 0) {
      writer.uint32(160).uint32(message.uint32TriggerQty);
    }
    if (message.triggerQty !== undefined) {
      Decimal.encode(message.triggerQty, writer.uint32(298).fork()).ldelim();
    }
    if (message.activationUtcTime !== undefined && message.activationUtcTime !== 0) {
      writer.uint32(168).sint64(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activationUtcTimestamp), writer.uint32(226).fork()).ldelim();
    }
    if (message.suspensionUtcTime !== undefined && message.suspensionUtcTime !== 0) {
      writer.uint32(176).sint64(message.suspensionUtcTime);
    }
    if (message.suspensionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.suspensionUtcTimestamp), writer.uint32(234).fork()).ldelim();
    }
    for (const v of message.userAttributes) {
      UserAttribute.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(306).fork()).ldelim();
    }
    if (message.algoStrategy !== undefined && message.algoStrategy !== "") {
      writer.uint32(314).string(message.algoStrategy);
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      writer.uint32(192).sint64(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.goodThruUtcTimestamp), writer.uint32(242).fork()).ldelim();
    }
    if (message.isUserAttributeChecked !== undefined && message.isUserAttributeChecked !== true) {
      writer.uint32(200).bool(message.isUserAttributeChecked);
    }
    if (message.strategyTradingParameters !== undefined) {
      StrategyTradingParameters.encode(message.strategyTradingParameters, writer.uint32(210).fork()).ldelim();
    }
    if (message.openCloseInstruction !== undefined && message.openCloseInstruction !== 0) {
      writer.uint32(248).uint32(message.openCloseInstruction);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(256).uint32(message.speculationType);
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      writer.uint32(322).string(message.contributorId);
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      writer.uint32(330).string(message.executionSourceCode);
    }
    if (message.crossOrderParameters !== undefined) {
      CrossOrderParameters.encode(message.crossOrderParameters, writer.uint32(338).fork()).ldelim();
    }
    if (message.externalAccountNumber !== undefined && message.externalAccountNumber !== "") {
      writer.uint32(346).string(message.externalAccountNumber);
    }
    if (message.isCareOrder !== undefined && message.isCareOrder !== false) {
      writer.uint32(352).bool(message.isCareOrder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Order {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clOrderId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.orderType = reader.uint32();
          continue;
        case 6:
          if (tag === 48) {
            message.execInstructions.push(reader.uint32());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.execInstructions.push(reader.uint32());
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.duration = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.goodThruDate = longToNumber(reader.sint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.scaledLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.scaledStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.uint32Qty = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.uint32VisibleQty = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.uint32MinVisibleQty = reader.uint32();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.visibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.isManual = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.isClose = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.isAggressive = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.scaledTrailOffset = reader.sint32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.trailingPeg = reader.uint32();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.uint32TriggerQty = reader.uint32();
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.triggerQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.activationUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.activationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.suspensionUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.suspensionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.userAttributes.push(UserAttribute.decode(reader, reader.uint32()));
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.algoStrategy = reader.string();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.goodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.isUserAttributeChecked = reader.bool();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.strategyTradingParameters = StrategyTradingParameters.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.openCloseInstruction = reader.uint32();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.contributorId = reader.string();
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.executionSourceCode = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.crossOrderParameters = CrossOrderParameters.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.externalAccountNumber = reader.string();
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.isCareOrder = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Order {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      whenUtcTime: isSet(object.whenUtcTime) ? globalThis.Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      clOrderId: isSet(object.clOrderId) ? globalThis.String(object.clOrderId) : "",
      orderType: isSet(object.orderType) ? globalThis.Number(object.orderType) : 0,
      execInstructions: globalThis.Array.isArray(object?.execInstructions)
        ? object.execInstructions.map((e: any) => globalThis.Number(e))
        : [],
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      goodThruDate: isSet(object.goodThruDate) ? globalThis.Number(object.goodThruDate) : 0,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      scaledLimitPrice: isSet(object.scaledLimitPrice) ? globalThis.Number(object.scaledLimitPrice) : 0,
      scaledStopPrice: isSet(object.scaledStopPrice) ? globalThis.Number(object.scaledStopPrice) : 0,
      uint32Qty: isSet(object.uint32Qty) ? globalThis.Number(object.uint32Qty) : 0,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? globalThis.Number(object.uint32VisibleQty) : 0,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? globalThis.Number(object.uint32MinVisibleQty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      isManual: isSet(object.isManual) ? globalThis.Boolean(object.isManual) : false,
      isClose: isSet(object.isClose) ? globalThis.Boolean(object.isClose) : false,
      isAggressive: isSet(object.isAggressive) ? globalThis.Boolean(object.isAggressive) : false,
      scaledTrailOffset: isSet(object.scaledTrailOffset) ? globalThis.Number(object.scaledTrailOffset) : 0,
      trailingPeg: isSet(object.trailingPeg) ? globalThis.Number(object.trailingPeg) : 0,
      uint32TriggerQty: isSet(object.uint32TriggerQty) ? globalThis.Number(object.uint32TriggerQty) : 0,
      triggerQty: isSet(object.triggerQty) ? Decimal.fromJSON(object.triggerQty) : undefined,
      activationUtcTime: isSet(object.activationUtcTime) ? globalThis.Number(object.activationUtcTime) : 0,
      activationUtcTimestamp: isSet(object.activationUtcTimestamp)
        ? fromJsonTimestamp(object.activationUtcTimestamp)
        : undefined,
      suspensionUtcTime: isSet(object.suspensionUtcTime) ? globalThis.Number(object.suspensionUtcTime) : 0,
      suspensionUtcTimestamp: isSet(object.suspensionUtcTimestamp)
        ? fromJsonTimestamp(object.suspensionUtcTimestamp)
        : undefined,
      userAttributes: globalThis.Array.isArray(object?.userAttributes)
        ? object.userAttributes.map((e: any) => UserAttribute.fromJSON(e))
        : [],
      extraAttributes: globalThis.Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      algoStrategy: isSet(object.algoStrategy) ? globalThis.String(object.algoStrategy) : "",
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? globalThis.Number(object.goodThruUtcTime) : 0,
      goodThruUtcTimestamp: isSet(object.goodThruUtcTimestamp)
        ? fromJsonTimestamp(object.goodThruUtcTimestamp)
        : undefined,
      isUserAttributeChecked: isSet(object.isUserAttributeChecked)
        ? globalThis.Boolean(object.isUserAttributeChecked)
        : true,
      strategyTradingParameters: isSet(object.strategyTradingParameters)
        ? StrategyTradingParameters.fromJSON(object.strategyTradingParameters)
        : undefined,
      openCloseInstruction: isSet(object.openCloseInstruction) ? globalThis.Number(object.openCloseInstruction) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      contributorId: isSet(object.contributorId) ? globalThis.String(object.contributorId) : "",
      executionSourceCode: isSet(object.executionSourceCode) ? globalThis.String(object.executionSourceCode) : "",
      crossOrderParameters: isSet(object.crossOrderParameters)
        ? CrossOrderParameters.fromJSON(object.crossOrderParameters)
        : undefined,
      externalAccountNumber: isSet(object.externalAccountNumber) ? globalThis.String(object.externalAccountNumber) : "",
      isCareOrder: isSet(object.isCareOrder) ? globalThis.Boolean(object.isCareOrder) : false,
    };
  },

  toJSON(message: Order): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      obj.whenUtcTime = Math.round(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.clOrderId !== "") {
      obj.clOrderId = message.clOrderId;
    }
    if (message.orderType !== 0) {
      obj.orderType = Math.round(message.orderType);
    }
    if (message.execInstructions?.length) {
      obj.execInstructions = message.execInstructions.map((e) => Math.round(e));
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      obj.goodThruDate = Math.round(message.goodThruDate);
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.scaledLimitPrice !== undefined && message.scaledLimitPrice !== 0) {
      obj.scaledLimitPrice = Math.round(message.scaledLimitPrice);
    }
    if (message.scaledStopPrice !== undefined && message.scaledStopPrice !== 0) {
      obj.scaledStopPrice = Math.round(message.scaledStopPrice);
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      obj.uint32Qty = Math.round(message.uint32Qty);
    }
    if (message.uint32VisibleQty !== undefined && message.uint32VisibleQty !== 0) {
      obj.uint32VisibleQty = Math.round(message.uint32VisibleQty);
    }
    if (message.uint32MinVisibleQty !== undefined && message.uint32MinVisibleQty !== 0) {
      obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.visibleQty !== undefined) {
      obj.visibleQty = Decimal.toJSON(message.visibleQty);
    }
    if (message.minVisibleQty !== undefined) {
      obj.minVisibleQty = Decimal.toJSON(message.minVisibleQty);
    }
    if (message.isManual !== undefined && message.isManual !== false) {
      obj.isManual = message.isManual;
    }
    if (message.isClose !== undefined && message.isClose !== false) {
      obj.isClose = message.isClose;
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      obj.isAggressive = message.isAggressive;
    }
    if (message.scaledTrailOffset !== undefined && message.scaledTrailOffset !== 0) {
      obj.scaledTrailOffset = Math.round(message.scaledTrailOffset);
    }
    if (message.trailingPeg !== undefined && message.trailingPeg !== 0) {
      obj.trailingPeg = Math.round(message.trailingPeg);
    }
    if (message.uint32TriggerQty !== undefined && message.uint32TriggerQty !== 0) {
      obj.uint32TriggerQty = Math.round(message.uint32TriggerQty);
    }
    if (message.triggerQty !== undefined) {
      obj.triggerQty = Decimal.toJSON(message.triggerQty);
    }
    if (message.activationUtcTime !== undefined && message.activationUtcTime !== 0) {
      obj.activationUtcTime = Math.round(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      obj.activationUtcTimestamp = message.activationUtcTimestamp.toISOString();
    }
    if (message.suspensionUtcTime !== undefined && message.suspensionUtcTime !== 0) {
      obj.suspensionUtcTime = Math.round(message.suspensionUtcTime);
    }
    if (message.suspensionUtcTimestamp !== undefined) {
      obj.suspensionUtcTimestamp = message.suspensionUtcTimestamp.toISOString();
    }
    if (message.userAttributes?.length) {
      obj.userAttributes = message.userAttributes.map((e) => UserAttribute.toJSON(e));
    }
    if (message.extraAttributes?.length) {
      obj.extraAttributes = message.extraAttributes.map((e) => NamedValue.toJSON(e));
    }
    if (message.algoStrategy !== undefined && message.algoStrategy !== "") {
      obj.algoStrategy = message.algoStrategy;
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      obj.goodThruUtcTime = Math.round(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      obj.goodThruUtcTimestamp = message.goodThruUtcTimestamp.toISOString();
    }
    if (message.isUserAttributeChecked !== undefined && message.isUserAttributeChecked !== true) {
      obj.isUserAttributeChecked = message.isUserAttributeChecked;
    }
    if (message.strategyTradingParameters !== undefined) {
      obj.strategyTradingParameters = StrategyTradingParameters.toJSON(message.strategyTradingParameters);
    }
    if (message.openCloseInstruction !== undefined && message.openCloseInstruction !== 0) {
      obj.openCloseInstruction = Math.round(message.openCloseInstruction);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      obj.contributorId = message.contributorId;
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      obj.executionSourceCode = message.executionSourceCode;
    }
    if (message.crossOrderParameters !== undefined) {
      obj.crossOrderParameters = CrossOrderParameters.toJSON(message.crossOrderParameters);
    }
    if (message.externalAccountNumber !== undefined && message.externalAccountNumber !== "") {
      obj.externalAccountNumber = message.externalAccountNumber;
    }
    if (message.isCareOrder !== undefined && message.isCareOrder !== false) {
      obj.isCareOrder = message.isCareOrder;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Order>, I>>(base?: I): Order {
    return Order.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Order>, I>>(object: I): Order {
    const message = createBaseOrder();
    message.accountId = object.accountId ?? 0;
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.contractId = object.contractId ?? 0;
    message.clOrderId = object.clOrderId ?? "";
    message.orderType = object.orderType ?? 0;
    message.execInstructions = object.execInstructions?.map((e) => e) || [];
    message.duration = object.duration ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.side = object.side ?? 0;
    message.scaledLimitPrice = object.scaledLimitPrice ?? 0;
    message.scaledStopPrice = object.scaledStopPrice ?? 0;
    message.uint32Qty = object.uint32Qty ?? 0;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.visibleQty = (object.visibleQty !== undefined && object.visibleQty !== null)
      ? Decimal.fromPartial(object.visibleQty)
      : undefined;
    message.minVisibleQty = (object.minVisibleQty !== undefined && object.minVisibleQty !== null)
      ? Decimal.fromPartial(object.minVisibleQty)
      : undefined;
    message.isManual = object.isManual ?? false;
    message.isClose = object.isClose ?? false;
    message.isAggressive = object.isAggressive ?? false;
    message.scaledTrailOffset = object.scaledTrailOffset ?? 0;
    message.trailingPeg = object.trailingPeg ?? 0;
    message.uint32TriggerQty = object.uint32TriggerQty ?? 0;
    message.triggerQty = (object.triggerQty !== undefined && object.triggerQty !== null)
      ? Decimal.fromPartial(object.triggerQty)
      : undefined;
    message.activationUtcTime = object.activationUtcTime ?? 0;
    message.activationUtcTimestamp = object.activationUtcTimestamp ?? undefined;
    message.suspensionUtcTime = object.suspensionUtcTime ?? 0;
    message.suspensionUtcTimestamp = object.suspensionUtcTimestamp ?? undefined;
    message.userAttributes = object.userAttributes?.map((e) => UserAttribute.fromPartial(e)) || [];
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.algoStrategy = object.algoStrategy ?? "";
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.goodThruUtcTimestamp = object.goodThruUtcTimestamp ?? undefined;
    message.isUserAttributeChecked = object.isUserAttributeChecked ?? true;
    message.strategyTradingParameters =
      (object.strategyTradingParameters !== undefined && object.strategyTradingParameters !== null)
        ? StrategyTradingParameters.fromPartial(object.strategyTradingParameters)
        : undefined;
    message.openCloseInstruction = object.openCloseInstruction ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.contributorId = object.contributorId ?? "";
    message.executionSourceCode = object.executionSourceCode ?? "";
    message.crossOrderParameters = (object.crossOrderParameters !== undefined && object.crossOrderParameters !== null)
      ? CrossOrderParameters.fromPartial(object.crossOrderParameters)
      : undefined;
    message.externalAccountNumber = object.externalAccountNumber ?? "";
    message.isCareOrder = object.isCareOrder ?? false;
    return message;
  },
};

function createBaseOrderRequest(): OrderRequest {
  return {
    requestId: 0,
    newOrder: undefined,
    modifyOrder: undefined,
    cancelOrder: undefined,
    cancelAllOrders: undefined,
    liquidateAll: undefined,
    goFlat: undefined,
    suspendOrder: undefined,
    activateOrder: undefined,
    modifyUserAttributes: undefined,
    newCompoundOrder: undefined,
    syntheticLiquidate: undefined,
    syntheticScratch: undefined,
    goMarket: undefined,
    approveOrder: undefined,
    fillCareOrder: undefined,
    onBehalfOfUser: "",
    clientRegulatoryAlgorithmId: 0,
    mifidAlgorithmId: "",
    mifidAlgorithmIdType: 0,
    overrideExecutionWithinFirmWithNore: false,
    mifidInvestmentDecisionId: "",
    mifidInvestmentDecisionIdType: 0,
    mifidExecutionDecisionId: "",
    mifidExecutionDecisionIdType: 0,
    isAutomated: false,
    senderLocationId: "",
    senderOperatorId: "",
  };
}

export const OrderRequest = {
  encode(message: OrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.newOrder !== undefined) {
      NewOrder.encode(message.newOrder, writer.uint32(18).fork()).ldelim();
    }
    if (message.modifyOrder !== undefined) {
      ModifyOrder.encode(message.modifyOrder, writer.uint32(26).fork()).ldelim();
    }
    if (message.cancelOrder !== undefined) {
      CancelOrder.encode(message.cancelOrder, writer.uint32(34).fork()).ldelim();
    }
    if (message.cancelAllOrders !== undefined) {
      CancelAllOrders.encode(message.cancelAllOrders, writer.uint32(114).fork()).ldelim();
    }
    if (message.liquidateAll !== undefined) {
      LiquidateAll.encode(message.liquidateAll, writer.uint32(162).fork()).ldelim();
    }
    if (message.goFlat !== undefined) {
      GoFlat.encode(message.goFlat, writer.uint32(170).fork()).ldelim();
    }
    if (message.suspendOrder !== undefined) {
      SuspendOrder.encode(message.suspendOrder, writer.uint32(82).fork()).ldelim();
    }
    if (message.activateOrder !== undefined) {
      ActivateOrder.encode(message.activateOrder, writer.uint32(42).fork()).ldelim();
    }
    if (message.modifyUserAttributes !== undefined) {
      ModifyUserAttributes.encode(message.modifyUserAttributes, writer.uint32(50).fork()).ldelim();
    }
    if (message.newCompoundOrder !== undefined) {
      NewCompoundOrder.encode(message.newCompoundOrder, writer.uint32(66).fork()).ldelim();
    }
    if (message.syntheticLiquidate !== undefined) {
      SyntheticLiquidate.encode(message.syntheticLiquidate, writer.uint32(90).fork()).ldelim();
    }
    if (message.syntheticScratch !== undefined) {
      SyntheticScratch.encode(message.syntheticScratch, writer.uint32(98).fork()).ldelim();
    }
    if (message.goMarket !== undefined) {
      GoMarket.encode(message.goMarket, writer.uint32(106).fork()).ldelim();
    }
    if (message.approveOrder !== undefined) {
      ApproveOrder.encode(message.approveOrder, writer.uint32(178).fork()).ldelim();
    }
    if (message.fillCareOrder !== undefined) {
      FillCareOrder.encode(message.fillCareOrder, writer.uint32(226).fork()).ldelim();
    }
    if (message.onBehalfOfUser !== undefined && message.onBehalfOfUser !== "") {
      writer.uint32(58).string(message.onBehalfOfUser);
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== 0) {
      writer.uint32(72).uint32(message.clientRegulatoryAlgorithmId);
    }
    if (message.mifidAlgorithmId !== undefined && message.mifidAlgorithmId !== "") {
      writer.uint32(122).string(message.mifidAlgorithmId);
    }
    if (message.mifidAlgorithmIdType !== undefined && message.mifidAlgorithmIdType !== 0) {
      writer.uint32(128).uint32(message.mifidAlgorithmIdType);
    }
    if (
      message.overrideExecutionWithinFirmWithNore !== undefined && message.overrideExecutionWithinFirmWithNore !== false
    ) {
      writer.uint32(136).bool(message.overrideExecutionWithinFirmWithNore);
    }
    if (message.mifidInvestmentDecisionId !== undefined && message.mifidInvestmentDecisionId !== "") {
      writer.uint32(146).string(message.mifidInvestmentDecisionId);
    }
    if (message.mifidInvestmentDecisionIdType !== undefined && message.mifidInvestmentDecisionIdType !== 0) {
      writer.uint32(152).uint32(message.mifidInvestmentDecisionIdType);
    }
    if (message.mifidExecutionDecisionId !== undefined && message.mifidExecutionDecisionId !== "") {
      writer.uint32(194).string(message.mifidExecutionDecisionId);
    }
    if (message.mifidExecutionDecisionIdType !== undefined && message.mifidExecutionDecisionIdType !== 0) {
      writer.uint32(200).uint32(message.mifidExecutionDecisionIdType);
    }
    if (message.isAutomated !== undefined && message.isAutomated !== false) {
      writer.uint32(184).bool(message.isAutomated);
    }
    if (message.senderLocationId !== undefined && message.senderLocationId !== "") {
      writer.uint32(210).string(message.senderLocationId);
    }
    if (message.senderOperatorId !== undefined && message.senderOperatorId !== "") {
      writer.uint32(218).string(message.senderOperatorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newOrder = NewOrder.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modifyOrder = ModifyOrder.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cancelOrder = CancelOrder.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.cancelAllOrders = CancelAllOrders.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.liquidateAll = LiquidateAll.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.goFlat = GoFlat.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.suspendOrder = SuspendOrder.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.activateOrder = ActivateOrder.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.modifyUserAttributes = ModifyUserAttributes.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.newCompoundOrder = NewCompoundOrder.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.syntheticLiquidate = SyntheticLiquidate.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.syntheticScratch = SyntheticScratch.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.goMarket = GoMarket.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.approveOrder = ApproveOrder.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.fillCareOrder = FillCareOrder.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.onBehalfOfUser = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.clientRegulatoryAlgorithmId = reader.uint32();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.mifidAlgorithmId = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.mifidAlgorithmIdType = reader.uint32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.overrideExecutionWithinFirmWithNore = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.mifidInvestmentDecisionId = reader.string();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.mifidInvestmentDecisionIdType = reader.uint32();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.mifidExecutionDecisionId = reader.string();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.mifidExecutionDecisionIdType = reader.uint32();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.isAutomated = reader.bool();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.senderLocationId = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.senderOperatorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderRequest {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      newOrder: isSet(object.newOrder) ? NewOrder.fromJSON(object.newOrder) : undefined,
      modifyOrder: isSet(object.modifyOrder) ? ModifyOrder.fromJSON(object.modifyOrder) : undefined,
      cancelOrder: isSet(object.cancelOrder) ? CancelOrder.fromJSON(object.cancelOrder) : undefined,
      cancelAllOrders: isSet(object.cancelAllOrders) ? CancelAllOrders.fromJSON(object.cancelAllOrders) : undefined,
      liquidateAll: isSet(object.liquidateAll) ? LiquidateAll.fromJSON(object.liquidateAll) : undefined,
      goFlat: isSet(object.goFlat) ? GoFlat.fromJSON(object.goFlat) : undefined,
      suspendOrder: isSet(object.suspendOrder) ? SuspendOrder.fromJSON(object.suspendOrder) : undefined,
      activateOrder: isSet(object.activateOrder) ? ActivateOrder.fromJSON(object.activateOrder) : undefined,
      modifyUserAttributes: isSet(object.modifyUserAttributes)
        ? ModifyUserAttributes.fromJSON(object.modifyUserAttributes)
        : undefined,
      newCompoundOrder: isSet(object.newCompoundOrder) ? NewCompoundOrder.fromJSON(object.newCompoundOrder) : undefined,
      syntheticLiquidate: isSet(object.syntheticLiquidate)
        ? SyntheticLiquidate.fromJSON(object.syntheticLiquidate)
        : undefined,
      syntheticScratch: isSet(object.syntheticScratch) ? SyntheticScratch.fromJSON(object.syntheticScratch) : undefined,
      goMarket: isSet(object.goMarket) ? GoMarket.fromJSON(object.goMarket) : undefined,
      approveOrder: isSet(object.approveOrder) ? ApproveOrder.fromJSON(object.approveOrder) : undefined,
      fillCareOrder: isSet(object.fillCareOrder) ? FillCareOrder.fromJSON(object.fillCareOrder) : undefined,
      onBehalfOfUser: isSet(object.onBehalfOfUser) ? globalThis.String(object.onBehalfOfUser) : "",
      clientRegulatoryAlgorithmId: isSet(object.clientRegulatoryAlgorithmId)
        ? globalThis.Number(object.clientRegulatoryAlgorithmId)
        : 0,
      mifidAlgorithmId: isSet(object.mifidAlgorithmId) ? globalThis.String(object.mifidAlgorithmId) : "",
      mifidAlgorithmIdType: isSet(object.mifidAlgorithmIdType) ? globalThis.Number(object.mifidAlgorithmIdType) : 0,
      overrideExecutionWithinFirmWithNore: isSet(object.overrideExecutionWithinFirmWithNore)
        ? globalThis.Boolean(object.overrideExecutionWithinFirmWithNore)
        : false,
      mifidInvestmentDecisionId: isSet(object.mifidInvestmentDecisionId)
        ? globalThis.String(object.mifidInvestmentDecisionId)
        : "",
      mifidInvestmentDecisionIdType: isSet(object.mifidInvestmentDecisionIdType)
        ? globalThis.Number(object.mifidInvestmentDecisionIdType)
        : 0,
      mifidExecutionDecisionId: isSet(object.mifidExecutionDecisionId)
        ? globalThis.String(object.mifidExecutionDecisionId)
        : "",
      mifidExecutionDecisionIdType: isSet(object.mifidExecutionDecisionIdType)
        ? globalThis.Number(object.mifidExecutionDecisionIdType)
        : 0,
      isAutomated: isSet(object.isAutomated) ? globalThis.Boolean(object.isAutomated) : false,
      senderLocationId: isSet(object.senderLocationId) ? globalThis.String(object.senderLocationId) : "",
      senderOperatorId: isSet(object.senderOperatorId) ? globalThis.String(object.senderOperatorId) : "",
    };
  },

  toJSON(message: OrderRequest): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.newOrder !== undefined) {
      obj.newOrder = NewOrder.toJSON(message.newOrder);
    }
    if (message.modifyOrder !== undefined) {
      obj.modifyOrder = ModifyOrder.toJSON(message.modifyOrder);
    }
    if (message.cancelOrder !== undefined) {
      obj.cancelOrder = CancelOrder.toJSON(message.cancelOrder);
    }
    if (message.cancelAllOrders !== undefined) {
      obj.cancelAllOrders = CancelAllOrders.toJSON(message.cancelAllOrders);
    }
    if (message.liquidateAll !== undefined) {
      obj.liquidateAll = LiquidateAll.toJSON(message.liquidateAll);
    }
    if (message.goFlat !== undefined) {
      obj.goFlat = GoFlat.toJSON(message.goFlat);
    }
    if (message.suspendOrder !== undefined) {
      obj.suspendOrder = SuspendOrder.toJSON(message.suspendOrder);
    }
    if (message.activateOrder !== undefined) {
      obj.activateOrder = ActivateOrder.toJSON(message.activateOrder);
    }
    if (message.modifyUserAttributes !== undefined) {
      obj.modifyUserAttributes = ModifyUserAttributes.toJSON(message.modifyUserAttributes);
    }
    if (message.newCompoundOrder !== undefined) {
      obj.newCompoundOrder = NewCompoundOrder.toJSON(message.newCompoundOrder);
    }
    if (message.syntheticLiquidate !== undefined) {
      obj.syntheticLiquidate = SyntheticLiquidate.toJSON(message.syntheticLiquidate);
    }
    if (message.syntheticScratch !== undefined) {
      obj.syntheticScratch = SyntheticScratch.toJSON(message.syntheticScratch);
    }
    if (message.goMarket !== undefined) {
      obj.goMarket = GoMarket.toJSON(message.goMarket);
    }
    if (message.approveOrder !== undefined) {
      obj.approveOrder = ApproveOrder.toJSON(message.approveOrder);
    }
    if (message.fillCareOrder !== undefined) {
      obj.fillCareOrder = FillCareOrder.toJSON(message.fillCareOrder);
    }
    if (message.onBehalfOfUser !== undefined && message.onBehalfOfUser !== "") {
      obj.onBehalfOfUser = message.onBehalfOfUser;
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== 0) {
      obj.clientRegulatoryAlgorithmId = Math.round(message.clientRegulatoryAlgorithmId);
    }
    if (message.mifidAlgorithmId !== undefined && message.mifidAlgorithmId !== "") {
      obj.mifidAlgorithmId = message.mifidAlgorithmId;
    }
    if (message.mifidAlgorithmIdType !== undefined && message.mifidAlgorithmIdType !== 0) {
      obj.mifidAlgorithmIdType = Math.round(message.mifidAlgorithmIdType);
    }
    if (
      message.overrideExecutionWithinFirmWithNore !== undefined && message.overrideExecutionWithinFirmWithNore !== false
    ) {
      obj.overrideExecutionWithinFirmWithNore = message.overrideExecutionWithinFirmWithNore;
    }
    if (message.mifidInvestmentDecisionId !== undefined && message.mifidInvestmentDecisionId !== "") {
      obj.mifidInvestmentDecisionId = message.mifidInvestmentDecisionId;
    }
    if (message.mifidInvestmentDecisionIdType !== undefined && message.mifidInvestmentDecisionIdType !== 0) {
      obj.mifidInvestmentDecisionIdType = Math.round(message.mifidInvestmentDecisionIdType);
    }
    if (message.mifidExecutionDecisionId !== undefined && message.mifidExecutionDecisionId !== "") {
      obj.mifidExecutionDecisionId = message.mifidExecutionDecisionId;
    }
    if (message.mifidExecutionDecisionIdType !== undefined && message.mifidExecutionDecisionIdType !== 0) {
      obj.mifidExecutionDecisionIdType = Math.round(message.mifidExecutionDecisionIdType);
    }
    if (message.isAutomated !== undefined && message.isAutomated !== false) {
      obj.isAutomated = message.isAutomated;
    }
    if (message.senderLocationId !== undefined && message.senderLocationId !== "") {
      obj.senderLocationId = message.senderLocationId;
    }
    if (message.senderOperatorId !== undefined && message.senderOperatorId !== "") {
      obj.senderOperatorId = message.senderOperatorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRequest>, I>>(base?: I): OrderRequest {
    return OrderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderRequest>, I>>(object: I): OrderRequest {
    const message = createBaseOrderRequest();
    message.requestId = object.requestId ?? 0;
    message.newOrder = (object.newOrder !== undefined && object.newOrder !== null)
      ? NewOrder.fromPartial(object.newOrder)
      : undefined;
    message.modifyOrder = (object.modifyOrder !== undefined && object.modifyOrder !== null)
      ? ModifyOrder.fromPartial(object.modifyOrder)
      : undefined;
    message.cancelOrder = (object.cancelOrder !== undefined && object.cancelOrder !== null)
      ? CancelOrder.fromPartial(object.cancelOrder)
      : undefined;
    message.cancelAllOrders = (object.cancelAllOrders !== undefined && object.cancelAllOrders !== null)
      ? CancelAllOrders.fromPartial(object.cancelAllOrders)
      : undefined;
    message.liquidateAll = (object.liquidateAll !== undefined && object.liquidateAll !== null)
      ? LiquidateAll.fromPartial(object.liquidateAll)
      : undefined;
    message.goFlat = (object.goFlat !== undefined && object.goFlat !== null)
      ? GoFlat.fromPartial(object.goFlat)
      : undefined;
    message.suspendOrder = (object.suspendOrder !== undefined && object.suspendOrder !== null)
      ? SuspendOrder.fromPartial(object.suspendOrder)
      : undefined;
    message.activateOrder = (object.activateOrder !== undefined && object.activateOrder !== null)
      ? ActivateOrder.fromPartial(object.activateOrder)
      : undefined;
    message.modifyUserAttributes = (object.modifyUserAttributes !== undefined && object.modifyUserAttributes !== null)
      ? ModifyUserAttributes.fromPartial(object.modifyUserAttributes)
      : undefined;
    message.newCompoundOrder = (object.newCompoundOrder !== undefined && object.newCompoundOrder !== null)
      ? NewCompoundOrder.fromPartial(object.newCompoundOrder)
      : undefined;
    message.syntheticLiquidate = (object.syntheticLiquidate !== undefined && object.syntheticLiquidate !== null)
      ? SyntheticLiquidate.fromPartial(object.syntheticLiquidate)
      : undefined;
    message.syntheticScratch = (object.syntheticScratch !== undefined && object.syntheticScratch !== null)
      ? SyntheticScratch.fromPartial(object.syntheticScratch)
      : undefined;
    message.goMarket = (object.goMarket !== undefined && object.goMarket !== null)
      ? GoMarket.fromPartial(object.goMarket)
      : undefined;
    message.approveOrder = (object.approveOrder !== undefined && object.approveOrder !== null)
      ? ApproveOrder.fromPartial(object.approveOrder)
      : undefined;
    message.fillCareOrder = (object.fillCareOrder !== undefined && object.fillCareOrder !== null)
      ? FillCareOrder.fromPartial(object.fillCareOrder)
      : undefined;
    message.onBehalfOfUser = object.onBehalfOfUser ?? "";
    message.clientRegulatoryAlgorithmId = object.clientRegulatoryAlgorithmId ?? 0;
    message.mifidAlgorithmId = object.mifidAlgorithmId ?? "";
    message.mifidAlgorithmIdType = object.mifidAlgorithmIdType ?? 0;
    message.overrideExecutionWithinFirmWithNore = object.overrideExecutionWithinFirmWithNore ?? false;
    message.mifidInvestmentDecisionId = object.mifidInvestmentDecisionId ?? "";
    message.mifidInvestmentDecisionIdType = object.mifidInvestmentDecisionIdType ?? 0;
    message.mifidExecutionDecisionId = object.mifidExecutionDecisionId ?? "";
    message.mifidExecutionDecisionIdType = object.mifidExecutionDecisionIdType ?? 0;
    message.isAutomated = object.isAutomated ?? false;
    message.senderLocationId = object.senderLocationId ?? "";
    message.senderOperatorId = object.senderOperatorId ?? "";
    return message;
  },
};

function createBaseNewOrder(): NewOrder {
  return { order: undefined, suspend: false };
}

export const NewOrder = {
  encode(message: NewOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.order !== undefined) {
      Order.encode(message.order, writer.uint32(10).fork()).ldelim();
    }
    if (message.suspend !== undefined && message.suspend !== false) {
      writer.uint32(16).bool(message.suspend);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.order = Order.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.suspend = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewOrder {
    return {
      order: isSet(object.order) ? Order.fromJSON(object.order) : undefined,
      suspend: isSet(object.suspend) ? globalThis.Boolean(object.suspend) : false,
    };
  },

  toJSON(message: NewOrder): unknown {
    const obj: any = {};
    if (message.order !== undefined) {
      obj.order = Order.toJSON(message.order);
    }
    if (message.suspend !== undefined && message.suspend !== false) {
      obj.suspend = message.suspend;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewOrder>, I>>(base?: I): NewOrder {
    return NewOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewOrder>, I>>(object: I): NewOrder {
    const message = createBaseNewOrder();
    message.order = (object.order !== undefined && object.order !== null) ? Order.fromPartial(object.order) : undefined;
    message.suspend = object.suspend ?? false;
    return message;
  },
};

function createBaseModifyOrder(): ModifyOrder {
  return {
    orderId: "",
    accountId: 0,
    origClOrderId: "",
    clOrderId: "",
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    uint32Qty: 0,
    uint32VisibleQty: 0,
    uint32MinVisibleQty: 0,
    qty: undefined,
    visibleQty: undefined,
    minVisibleQty: undefined,
    scaledLimitPrice: 0,
    scaledStopPrice: 0,
    activationUtcTime: 0,
    activationUtcTimestamp: undefined,
    removeActivationTime: false,
    suspensionUtcTime: 0,
    suspensionUtcTimestamp: undefined,
    removeSuspensionUtcTime: false,
    duration: 0,
    goodThruDate: 0,
    goodThruUtcTime: 0,
    goodThruUtcTimestamp: undefined,
    extraAttributes: [],
    executionSourceCode: "",
  };
}

export const ModifyOrder = {
  encode(message: ModifyOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== "") {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== "") {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(146).fork()).ldelim();
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      writer.uint32(48).uint32(message.uint32Qty);
    }
    if (message.uint32VisibleQty !== undefined && message.uint32VisibleQty !== 0) {
      writer.uint32(56).uint32(message.uint32VisibleQty);
    }
    if (message.uint32MinVisibleQty !== undefined && message.uint32MinVisibleQty !== 0) {
      writer.uint32(64).uint32(message.uint32MinVisibleQty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(186).fork()).ldelim();
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(194).fork()).ldelim();
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(202).fork()).ldelim();
    }
    if (message.scaledLimitPrice !== undefined && message.scaledLimitPrice !== 0) {
      writer.uint32(72).sint64(message.scaledLimitPrice);
    }
    if (message.scaledStopPrice !== undefined && message.scaledStopPrice !== 0) {
      writer.uint32(80).sint64(message.scaledStopPrice);
    }
    if (message.activationUtcTime !== undefined && message.activationUtcTime !== 0) {
      writer.uint32(88).sint64(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activationUtcTimestamp), writer.uint32(154).fork()).ldelim();
    }
    if (message.removeActivationTime !== undefined && message.removeActivationTime !== false) {
      writer.uint32(96).bool(message.removeActivationTime);
    }
    if (message.suspensionUtcTime !== undefined && message.suspensionUtcTime !== 0) {
      writer.uint32(104).sint64(message.suspensionUtcTime);
    }
    if (message.suspensionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.suspensionUtcTimestamp), writer.uint32(162).fork()).ldelim();
    }
    if (message.removeSuspensionUtcTime !== undefined && message.removeSuspensionUtcTime !== false) {
      writer.uint32(112).bool(message.removeSuspensionUtcTime);
    }
    if (message.duration !== undefined && message.duration !== 0) {
      writer.uint32(120).uint32(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      writer.uint32(128).sint64(message.goodThruDate);
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      writer.uint32(136).sint64(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.goodThruUtcTimestamp), writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(210).fork()).ldelim();
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      writer.uint32(218).string(message.executionSourceCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.origClOrderId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clOrderId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.uint32Qty = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.uint32VisibleQty = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.uint32MinVisibleQty = reader.uint32();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.visibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.scaledLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.scaledStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.activationUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.activationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.removeActivationTime = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.suspensionUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.suspensionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.removeSuspensionUtcTime = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.duration = reader.uint32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.goodThruDate = longToNumber(reader.sint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.goodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.executionSourceCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModifyOrder {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? globalThis.String(object.origClOrderId) : "",
      clOrderId: isSet(object.clOrderId) ? globalThis.String(object.clOrderId) : "",
      whenUtcTime: isSet(object.whenUtcTime) ? globalThis.Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      uint32Qty: isSet(object.uint32Qty) ? globalThis.Number(object.uint32Qty) : 0,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? globalThis.Number(object.uint32VisibleQty) : 0,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? globalThis.Number(object.uint32MinVisibleQty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      scaledLimitPrice: isSet(object.scaledLimitPrice) ? globalThis.Number(object.scaledLimitPrice) : 0,
      scaledStopPrice: isSet(object.scaledStopPrice) ? globalThis.Number(object.scaledStopPrice) : 0,
      activationUtcTime: isSet(object.activationUtcTime) ? globalThis.Number(object.activationUtcTime) : 0,
      activationUtcTimestamp: isSet(object.activationUtcTimestamp)
        ? fromJsonTimestamp(object.activationUtcTimestamp)
        : undefined,
      removeActivationTime: isSet(object.removeActivationTime)
        ? globalThis.Boolean(object.removeActivationTime)
        : false,
      suspensionUtcTime: isSet(object.suspensionUtcTime) ? globalThis.Number(object.suspensionUtcTime) : 0,
      suspensionUtcTimestamp: isSet(object.suspensionUtcTimestamp)
        ? fromJsonTimestamp(object.suspensionUtcTimestamp)
        : undefined,
      removeSuspensionUtcTime: isSet(object.removeSuspensionUtcTime)
        ? globalThis.Boolean(object.removeSuspensionUtcTime)
        : false,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      goodThruDate: isSet(object.goodThruDate) ? globalThis.Number(object.goodThruDate) : 0,
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? globalThis.Number(object.goodThruUtcTime) : 0,
      goodThruUtcTimestamp: isSet(object.goodThruUtcTimestamp)
        ? fromJsonTimestamp(object.goodThruUtcTimestamp)
        : undefined,
      extraAttributes: globalThis.Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      executionSourceCode: isSet(object.executionSourceCode) ? globalThis.String(object.executionSourceCode) : "",
    };
  },

  toJSON(message: ModifyOrder): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.origClOrderId !== "") {
      obj.origClOrderId = message.origClOrderId;
    }
    if (message.clOrderId !== "") {
      obj.clOrderId = message.clOrderId;
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      obj.whenUtcTime = Math.round(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.uint32Qty !== undefined && message.uint32Qty !== 0) {
      obj.uint32Qty = Math.round(message.uint32Qty);
    }
    if (message.uint32VisibleQty !== undefined && message.uint32VisibleQty !== 0) {
      obj.uint32VisibleQty = Math.round(message.uint32VisibleQty);
    }
    if (message.uint32MinVisibleQty !== undefined && message.uint32MinVisibleQty !== 0) {
      obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.visibleQty !== undefined) {
      obj.visibleQty = Decimal.toJSON(message.visibleQty);
    }
    if (message.minVisibleQty !== undefined) {
      obj.minVisibleQty = Decimal.toJSON(message.minVisibleQty);
    }
    if (message.scaledLimitPrice !== undefined && message.scaledLimitPrice !== 0) {
      obj.scaledLimitPrice = Math.round(message.scaledLimitPrice);
    }
    if (message.scaledStopPrice !== undefined && message.scaledStopPrice !== 0) {
      obj.scaledStopPrice = Math.round(message.scaledStopPrice);
    }
    if (message.activationUtcTime !== undefined && message.activationUtcTime !== 0) {
      obj.activationUtcTime = Math.round(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      obj.activationUtcTimestamp = message.activationUtcTimestamp.toISOString();
    }
    if (message.removeActivationTime !== undefined && message.removeActivationTime !== false) {
      obj.removeActivationTime = message.removeActivationTime;
    }
    if (message.suspensionUtcTime !== undefined && message.suspensionUtcTime !== 0) {
      obj.suspensionUtcTime = Math.round(message.suspensionUtcTime);
    }
    if (message.suspensionUtcTimestamp !== undefined) {
      obj.suspensionUtcTimestamp = message.suspensionUtcTimestamp.toISOString();
    }
    if (message.removeSuspensionUtcTime !== undefined && message.removeSuspensionUtcTime !== false) {
      obj.removeSuspensionUtcTime = message.removeSuspensionUtcTime;
    }
    if (message.duration !== undefined && message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      obj.goodThruDate = Math.round(message.goodThruDate);
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      obj.goodThruUtcTime = Math.round(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      obj.goodThruUtcTimestamp = message.goodThruUtcTimestamp.toISOString();
    }
    if (message.extraAttributes?.length) {
      obj.extraAttributes = message.extraAttributes.map((e) => NamedValue.toJSON(e));
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      obj.executionSourceCode = message.executionSourceCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyOrder>, I>>(base?: I): ModifyOrder {
    return ModifyOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModifyOrder>, I>>(object: I): ModifyOrder {
    const message = createBaseModifyOrder();
    message.orderId = object.orderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? "";
    message.clOrderId = object.clOrderId ?? "";
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.uint32Qty = object.uint32Qty ?? 0;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.visibleQty = (object.visibleQty !== undefined && object.visibleQty !== null)
      ? Decimal.fromPartial(object.visibleQty)
      : undefined;
    message.minVisibleQty = (object.minVisibleQty !== undefined && object.minVisibleQty !== null)
      ? Decimal.fromPartial(object.minVisibleQty)
      : undefined;
    message.scaledLimitPrice = object.scaledLimitPrice ?? 0;
    message.scaledStopPrice = object.scaledStopPrice ?? 0;
    message.activationUtcTime = object.activationUtcTime ?? 0;
    message.activationUtcTimestamp = object.activationUtcTimestamp ?? undefined;
    message.removeActivationTime = object.removeActivationTime ?? false;
    message.suspensionUtcTime = object.suspensionUtcTime ?? 0;
    message.suspensionUtcTimestamp = object.suspensionUtcTimestamp ?? undefined;
    message.removeSuspensionUtcTime = object.removeSuspensionUtcTime ?? false;
    message.duration = object.duration ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.goodThruUtcTimestamp = object.goodThruUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.executionSourceCode = object.executionSourceCode ?? "";
    return message;
  },
};

function createBaseCancelOrder(): CancelOrder {
  return {
    orderId: "",
    accountId: 0,
    origClOrderId: "",
    clOrderId: "",
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    extraAttributes: [],
  };
}

export const CancelOrder = {
  encode(message: CancelOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== "") {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== "") {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.origClOrderId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clOrderId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelOrder {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? globalThis.String(object.origClOrderId) : "",
      clOrderId: isSet(object.clOrderId) ? globalThis.String(object.clOrderId) : "",
      whenUtcTime: isSet(object.whenUtcTime) ? globalThis.Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      extraAttributes: globalThis.Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CancelOrder): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.origClOrderId !== "") {
      obj.origClOrderId = message.origClOrderId;
    }
    if (message.clOrderId !== "") {
      obj.clOrderId = message.clOrderId;
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      obj.whenUtcTime = Math.round(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.extraAttributes?.length) {
      obj.extraAttributes = message.extraAttributes.map((e) => NamedValue.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelOrder>, I>>(base?: I): CancelOrder {
    return CancelOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelOrder>, I>>(object: I): CancelOrder {
    const message = createBaseCancelOrder();
    message.orderId = object.orderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? "";
    message.clOrderId = object.clOrderId ?? "";
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountOrderFilter(): AccountOrderFilter {
  return { accountId: 0, mine: true, suspended: false, side: 0, contractId: 0, currentDayOnly: false };
}

export const AccountOrderFilter = {
  encode(message: AccountOrderFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.mine !== undefined && message.mine !== true) {
      writer.uint32(16).bool(message.mine);
    }
    if (message.suspended !== undefined && message.suspended !== false) {
      writer.uint32(24).bool(message.suspended);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(32).uint32(message.side);
    }
    if (message.contractId !== undefined && message.contractId !== 0) {
      writer.uint32(40).uint32(message.contractId);
    }
    if (message.currentDayOnly !== undefined && message.currentDayOnly !== false) {
      writer.uint32(48).bool(message.currentDayOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountOrderFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountOrderFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.mine = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.suspended = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.currentDayOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountOrderFilter {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      mine: isSet(object.mine) ? globalThis.Boolean(object.mine) : true,
      suspended: isSet(object.suspended) ? globalThis.Boolean(object.suspended) : false,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      currentDayOnly: isSet(object.currentDayOnly) ? globalThis.Boolean(object.currentDayOnly) : false,
    };
  },

  toJSON(message: AccountOrderFilter): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.mine !== undefined && message.mine !== true) {
      obj.mine = message.mine;
    }
    if (message.suspended !== undefined && message.suspended !== false) {
      obj.suspended = message.suspended;
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.contractId !== undefined && message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.currentDayOnly !== undefined && message.currentDayOnly !== false) {
      obj.currentDayOnly = message.currentDayOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountOrderFilter>, I>>(base?: I): AccountOrderFilter {
    return AccountOrderFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountOrderFilter>, I>>(object: I): AccountOrderFilter {
    const message = createBaseAccountOrderFilter();
    message.accountId = object.accountId ?? 0;
    message.mine = object.mine ?? true;
    message.suspended = object.suspended ?? false;
    message.side = object.side ?? 0;
    message.contractId = object.contractId ?? 0;
    message.currentDayOnly = object.currentDayOnly ?? false;
    return message;
  },
};

function createBaseCancelAllOrders(): CancelAllOrders {
  return { whenUtcTime: 0, whenUtcTimestamp: undefined, clOrderId: "", accountOrderFilters: [] };
}

export const CancelAllOrders = {
  encode(message: CancelAllOrders, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      writer.uint32(8).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    if (message.clOrderId !== undefined && message.clOrderId !== "") {
      writer.uint32(18).string(message.clOrderId);
    }
    for (const v of message.accountOrderFilters) {
      AccountOrderFilter.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelAllOrders {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelAllOrders();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clOrderId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountOrderFilters.push(AccountOrderFilter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelAllOrders {
    return {
      whenUtcTime: isSet(object.whenUtcTime) ? globalThis.Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      clOrderId: isSet(object.clOrderId) ? globalThis.String(object.clOrderId) : "",
      accountOrderFilters: globalThis.Array.isArray(object?.accountOrderFilters)
        ? object.accountOrderFilters.map((e: any) => AccountOrderFilter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CancelAllOrders): unknown {
    const obj: any = {};
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      obj.whenUtcTime = Math.round(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.clOrderId !== undefined && message.clOrderId !== "") {
      obj.clOrderId = message.clOrderId;
    }
    if (message.accountOrderFilters?.length) {
      obj.accountOrderFilters = message.accountOrderFilters.map((e) => AccountOrderFilter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelAllOrders>, I>>(base?: I): CancelAllOrders {
    return CancelAllOrders.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelAllOrders>, I>>(object: I): CancelAllOrders {
    const message = createBaseCancelAllOrders();
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.clOrderId = object.clOrderId ?? "";
    message.accountOrderFilters = object.accountOrderFilters?.map((e) => AccountOrderFilter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseActivateOrder(): ActivateOrder {
  return {
    orderId: "",
    accountId: 0,
    origClOrderId: "",
    clOrderId: "",
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    extraAttributes: [],
    executionSourceCode: "",
    isCareAutoActivation: false,
  };
}

export const ActivateOrder = {
  encode(message: ActivateOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== "") {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== "") {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      writer.uint32(66).string(message.executionSourceCode);
    }
    if (message.isCareAutoActivation !== undefined && message.isCareAutoActivation !== false) {
      writer.uint32(72).bool(message.isCareAutoActivation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.origClOrderId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clOrderId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.executionSourceCode = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isCareAutoActivation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivateOrder {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? globalThis.String(object.origClOrderId) : "",
      clOrderId: isSet(object.clOrderId) ? globalThis.String(object.clOrderId) : "",
      whenUtcTime: isSet(object.whenUtcTime) ? globalThis.Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      extraAttributes: globalThis.Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      executionSourceCode: isSet(object.executionSourceCode) ? globalThis.String(object.executionSourceCode) : "",
      isCareAutoActivation: isSet(object.isCareAutoActivation)
        ? globalThis.Boolean(object.isCareAutoActivation)
        : false,
    };
  },

  toJSON(message: ActivateOrder): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.origClOrderId !== "") {
      obj.origClOrderId = message.origClOrderId;
    }
    if (message.clOrderId !== "") {
      obj.clOrderId = message.clOrderId;
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      obj.whenUtcTime = Math.round(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.extraAttributes?.length) {
      obj.extraAttributes = message.extraAttributes.map((e) => NamedValue.toJSON(e));
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      obj.executionSourceCode = message.executionSourceCode;
    }
    if (message.isCareAutoActivation !== undefined && message.isCareAutoActivation !== false) {
      obj.isCareAutoActivation = message.isCareAutoActivation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateOrder>, I>>(base?: I): ActivateOrder {
    return ActivateOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateOrder>, I>>(object: I): ActivateOrder {
    const message = createBaseActivateOrder();
    message.orderId = object.orderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? "";
    message.clOrderId = object.clOrderId ?? "";
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.executionSourceCode = object.executionSourceCode ?? "";
    message.isCareAutoActivation = object.isCareAutoActivation ?? false;
    return message;
  },
};

function createBaseNewCompoundOrder(): NewCompoundOrder {
  return { compoundOrder: undefined, partialFillsHandling: true };
}

export const NewCompoundOrder = {
  encode(message: NewCompoundOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.compoundOrder !== undefined) {
      CompoundOrder.encode(message.compoundOrder, writer.uint32(10).fork()).ldelim();
    }
    if (message.partialFillsHandling !== undefined && message.partialFillsHandling !== true) {
      writer.uint32(16).bool(message.partialFillsHandling);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewCompoundOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewCompoundOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.compoundOrder = CompoundOrder.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.partialFillsHandling = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewCompoundOrder {
    return {
      compoundOrder: isSet(object.compoundOrder) ? CompoundOrder.fromJSON(object.compoundOrder) : undefined,
      partialFillsHandling: isSet(object.partialFillsHandling) ? globalThis.Boolean(object.partialFillsHandling) : true,
    };
  },

  toJSON(message: NewCompoundOrder): unknown {
    const obj: any = {};
    if (message.compoundOrder !== undefined) {
      obj.compoundOrder = CompoundOrder.toJSON(message.compoundOrder);
    }
    if (message.partialFillsHandling !== undefined && message.partialFillsHandling !== true) {
      obj.partialFillsHandling = message.partialFillsHandling;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewCompoundOrder>, I>>(base?: I): NewCompoundOrder {
    return NewCompoundOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewCompoundOrder>, I>>(object: I): NewCompoundOrder {
    const message = createBaseNewCompoundOrder();
    message.compoundOrder = (object.compoundOrder !== undefined && object.compoundOrder !== null)
      ? CompoundOrder.fromPartial(object.compoundOrder)
      : undefined;
    message.partialFillsHandling = object.partialFillsHandling ?? true;
    return message;
  },
};

function createBaseCompoundOrder(): CompoundOrder {
  return {
    type: 0,
    clCompoundId: "",
    compoundOrderEntries: [],
    profitTickOffset: 0,
    lossTickOffset: 0,
    stopLimitTickOffset: 0,
    isBracket: false,
  };
}

export const CompoundOrder = {
  encode(message: CompoundOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.clCompoundId !== "") {
      writer.uint32(18).string(message.clCompoundId);
    }
    for (const v of message.compoundOrderEntries) {
      CompoundOrderEntry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.profitTickOffset !== undefined && message.profitTickOffset !== 0) {
      writer.uint32(32).sint32(message.profitTickOffset);
    }
    if (message.lossTickOffset !== undefined && message.lossTickOffset !== 0) {
      writer.uint32(40).sint32(message.lossTickOffset);
    }
    if (message.stopLimitTickOffset !== undefined && message.stopLimitTickOffset !== 0) {
      writer.uint32(48).sint32(message.stopLimitTickOffset);
    }
    if (message.isBracket !== undefined && message.isBracket !== false) {
      writer.uint32(56).bool(message.isBracket);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clCompoundId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.compoundOrderEntries.push(CompoundOrderEntry.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.profitTickOffset = reader.sint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lossTickOffset = reader.sint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.stopLimitTickOffset = reader.sint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isBracket = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompoundOrder {
    return {
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      clCompoundId: isSet(object.clCompoundId) ? globalThis.String(object.clCompoundId) : "",
      compoundOrderEntries: globalThis.Array.isArray(object?.compoundOrderEntries)
        ? object.compoundOrderEntries.map((e: any) => CompoundOrderEntry.fromJSON(e))
        : [],
      profitTickOffset: isSet(object.profitTickOffset) ? globalThis.Number(object.profitTickOffset) : 0,
      lossTickOffset: isSet(object.lossTickOffset) ? globalThis.Number(object.lossTickOffset) : 0,
      stopLimitTickOffset: isSet(object.stopLimitTickOffset) ? globalThis.Number(object.stopLimitTickOffset) : 0,
      isBracket: isSet(object.isBracket) ? globalThis.Boolean(object.isBracket) : false,
    };
  },

  toJSON(message: CompoundOrder): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.clCompoundId !== "") {
      obj.clCompoundId = message.clCompoundId;
    }
    if (message.compoundOrderEntries?.length) {
      obj.compoundOrderEntries = message.compoundOrderEntries.map((e) => CompoundOrderEntry.toJSON(e));
    }
    if (message.profitTickOffset !== undefined && message.profitTickOffset !== 0) {
      obj.profitTickOffset = Math.round(message.profitTickOffset);
    }
    if (message.lossTickOffset !== undefined && message.lossTickOffset !== 0) {
      obj.lossTickOffset = Math.round(message.lossTickOffset);
    }
    if (message.stopLimitTickOffset !== undefined && message.stopLimitTickOffset !== 0) {
      obj.stopLimitTickOffset = Math.round(message.stopLimitTickOffset);
    }
    if (message.isBracket !== undefined && message.isBracket !== false) {
      obj.isBracket = message.isBracket;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrder>, I>>(base?: I): CompoundOrder {
    return CompoundOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompoundOrder>, I>>(object: I): CompoundOrder {
    const message = createBaseCompoundOrder();
    message.type = object.type ?? 0;
    message.clCompoundId = object.clCompoundId ?? "";
    message.compoundOrderEntries = object.compoundOrderEntries?.map((e) => CompoundOrderEntry.fromPartial(e)) || [];
    message.profitTickOffset = object.profitTickOffset ?? 0;
    message.lossTickOffset = object.lossTickOffset ?? 0;
    message.stopLimitTickOffset = object.stopLimitTickOffset ?? 0;
    message.isBracket = object.isBracket ?? false;
    return message;
  },
};

function createBaseUseOrder(): UseOrder {
  return { orderId: "", accountId: 0 };
}

export const UseOrder = {
  encode(message: UseOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UseOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UseOrder {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
    };
  },

  toJSON(message: UseOrder): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UseOrder>, I>>(base?: I): UseOrder {
    return UseOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UseOrder>, I>>(object: I): UseOrder {
    const message = createBaseUseOrder();
    message.orderId = object.orderId ?? "";
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseCompoundOrderEntry(): CompoundOrderEntry {
  return { order: undefined, compoundOrder: undefined, useOrder: undefined };
}

export const CompoundOrderEntry = {
  encode(message: CompoundOrderEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.order !== undefined) {
      Order.encode(message.order, writer.uint32(10).fork()).ldelim();
    }
    if (message.compoundOrder !== undefined) {
      CompoundOrder.encode(message.compoundOrder, writer.uint32(18).fork()).ldelim();
    }
    if (message.useOrder !== undefined) {
      UseOrder.encode(message.useOrder, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.order = Order.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.compoundOrder = CompoundOrder.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.useOrder = UseOrder.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderEntry {
    return {
      order: isSet(object.order) ? Order.fromJSON(object.order) : undefined,
      compoundOrder: isSet(object.compoundOrder) ? CompoundOrder.fromJSON(object.compoundOrder) : undefined,
      useOrder: isSet(object.useOrder) ? UseOrder.fromJSON(object.useOrder) : undefined,
    };
  },

  toJSON(message: CompoundOrderEntry): unknown {
    const obj: any = {};
    if (message.order !== undefined) {
      obj.order = Order.toJSON(message.order);
    }
    if (message.compoundOrder !== undefined) {
      obj.compoundOrder = CompoundOrder.toJSON(message.compoundOrder);
    }
    if (message.useOrder !== undefined) {
      obj.useOrder = UseOrder.toJSON(message.useOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderEntry>, I>>(base?: I): CompoundOrderEntry {
    return CompoundOrderEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompoundOrderEntry>, I>>(object: I): CompoundOrderEntry {
    const message = createBaseCompoundOrderEntry();
    message.order = (object.order !== undefined && object.order !== null) ? Order.fromPartial(object.order) : undefined;
    message.compoundOrder = (object.compoundOrder !== undefined && object.compoundOrder !== null)
      ? CompoundOrder.fromPartial(object.compoundOrder)
      : undefined;
    message.useOrder = (object.useOrder !== undefined && object.useOrder !== null)
      ? UseOrder.fromPartial(object.useOrder)
      : undefined;
    return message;
  },
};

function createBaseSuspendOrder(): SuspendOrder {
  return {
    orderId: "",
    accountId: 0,
    origClOrderId: "",
    clOrderId: "",
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    activationUtcTime: 0,
    activationUtcTimestamp: undefined,
    extraAttributes: [],
  };
}

export const SuspendOrder = {
  encode(message: SuspendOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== "") {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== "") {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(58).fork()).ldelim();
    }
    if (message.activationUtcTime !== undefined && message.activationUtcTime !== 0) {
      writer.uint32(48).sint64(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activationUtcTimestamp), writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SuspendOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSuspendOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.origClOrderId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clOrderId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.activationUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.activationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SuspendOrder {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? globalThis.String(object.origClOrderId) : "",
      clOrderId: isSet(object.clOrderId) ? globalThis.String(object.clOrderId) : "",
      whenUtcTime: isSet(object.whenUtcTime) ? globalThis.Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      activationUtcTime: isSet(object.activationUtcTime) ? globalThis.Number(object.activationUtcTime) : 0,
      activationUtcTimestamp: isSet(object.activationUtcTimestamp)
        ? fromJsonTimestamp(object.activationUtcTimestamp)
        : undefined,
      extraAttributes: globalThis.Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SuspendOrder): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.origClOrderId !== "") {
      obj.origClOrderId = message.origClOrderId;
    }
    if (message.clOrderId !== "") {
      obj.clOrderId = message.clOrderId;
    }
    if (message.whenUtcTime !== undefined && message.whenUtcTime !== 0) {
      obj.whenUtcTime = Math.round(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.activationUtcTime !== undefined && message.activationUtcTime !== 0) {
      obj.activationUtcTime = Math.round(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      obj.activationUtcTimestamp = message.activationUtcTimestamp.toISOString();
    }
    if (message.extraAttributes?.length) {
      obj.extraAttributes = message.extraAttributes.map((e) => NamedValue.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SuspendOrder>, I>>(base?: I): SuspendOrder {
    return SuspendOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SuspendOrder>, I>>(object: I): SuspendOrder {
    const message = createBaseSuspendOrder();
    message.orderId = object.orderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? "";
    message.clOrderId = object.clOrderId ?? "";
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.activationUtcTime = object.activationUtcTime ?? 0;
    message.activationUtcTimestamp = object.activationUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountPositionFilter(): AccountPositionFilter {
  return { accountId: 0, contractId: 0, isShort: false, currentDayOnly: false };
}

export const AccountPositionFilter = {
  encode(message: AccountPositionFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.contractId !== undefined && message.contractId !== 0) {
      writer.uint32(16).uint32(message.contractId);
    }
    if (message.isShort !== undefined && message.isShort !== false) {
      writer.uint32(24).bool(message.isShort);
    }
    if (message.currentDayOnly !== undefined && message.currentDayOnly !== false) {
      writer.uint32(32).bool(message.currentDayOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPositionFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPositionFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isShort = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.currentDayOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountPositionFilter {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      isShort: isSet(object.isShort) ? globalThis.Boolean(object.isShort) : false,
      currentDayOnly: isSet(object.currentDayOnly) ? globalThis.Boolean(object.currentDayOnly) : false,
    };
  },

  toJSON(message: AccountPositionFilter): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.contractId !== undefined && message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.isShort !== undefined && message.isShort !== false) {
      obj.isShort = message.isShort;
    }
    if (message.currentDayOnly !== undefined && message.currentDayOnly !== false) {
      obj.currentDayOnly = message.currentDayOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPositionFilter>, I>>(base?: I): AccountPositionFilter {
    return AccountPositionFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountPositionFilter>, I>>(object: I): AccountPositionFilter {
    const message = createBaseAccountPositionFilter();
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    message.isShort = object.isShort ?? false;
    message.currentDayOnly = object.currentDayOnly ?? false;
    return message;
  },
};

function createBaseLiquidateAll(): LiquidateAll {
  return { accountPositionFilters: [], whenUtcTimestamp: undefined, executionSourceCode: "", speculationType: 0 };
}

export const LiquidateAll = {
  encode(message: LiquidateAll, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accountPositionFilters) {
      AccountPositionFilter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      writer.uint32(26).string(message.executionSourceCode);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(32).uint32(message.speculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LiquidateAll {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLiquidateAll();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountPositionFilters.push(AccountPositionFilter.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.executionSourceCode = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LiquidateAll {
    return {
      accountPositionFilters: globalThis.Array.isArray(object?.accountPositionFilters)
        ? object.accountPositionFilters.map((e: any) => AccountPositionFilter.fromJSON(e))
        : [],
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      executionSourceCode: isSet(object.executionSourceCode) ? globalThis.String(object.executionSourceCode) : "",
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
    };
  },

  toJSON(message: LiquidateAll): unknown {
    const obj: any = {};
    if (message.accountPositionFilters?.length) {
      obj.accountPositionFilters = message.accountPositionFilters.map((e) => AccountPositionFilter.toJSON(e));
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      obj.executionSourceCode = message.executionSourceCode;
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LiquidateAll>, I>>(base?: I): LiquidateAll {
    return LiquidateAll.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LiquidateAll>, I>>(object: I): LiquidateAll {
    const message = createBaseLiquidateAll();
    message.accountPositionFilters = object.accountPositionFilters?.map((e) => AccountPositionFilter.fromPartial(e)) ||
      [];
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.executionSourceCode = object.executionSourceCode ?? "";
    message.speculationType = object.speculationType ?? 0;
    return message;
  },
};

function createBaseGoFlat(): GoFlat {
  return { accountIds: [], whenUtcTimestamp: undefined, executionSourceCode: "", speculationType: 0 };
}

export const GoFlat = {
  encode(message: GoFlat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.accountIds) {
      writer.sint32(v);
    }
    writer.ldelim();
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      writer.uint32(26).string(message.executionSourceCode);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(32).uint32(message.speculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoFlat {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoFlat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.accountIds.push(reader.sint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.accountIds.push(reader.sint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.executionSourceCode = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GoFlat {
    return {
      accountIds: globalThis.Array.isArray(object?.accountIds)
        ? object.accountIds.map((e: any) => globalThis.Number(e))
        : [],
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      executionSourceCode: isSet(object.executionSourceCode) ? globalThis.String(object.executionSourceCode) : "",
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
    };
  },

  toJSON(message: GoFlat): unknown {
    const obj: any = {};
    if (message.accountIds?.length) {
      obj.accountIds = message.accountIds.map((e) => Math.round(e));
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      obj.executionSourceCode = message.executionSourceCode;
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GoFlat>, I>>(base?: I): GoFlat {
    return GoFlat.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GoFlat>, I>>(object: I): GoFlat {
    const message = createBaseGoFlat();
    message.accountIds = object.accountIds?.map((e) => e) || [];
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.executionSourceCode = object.executionSourceCode ?? "";
    message.speculationType = object.speculationType ?? 0;
    return message;
  },
};

function createBaseOrderEntitlementRequest(): OrderEntitlementRequest {
  return { contractId: 0, accountId: 0 };
}

export const OrderEntitlementRequest = {
  encode(message: OrderEntitlementRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEntitlementRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEntitlementRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderEntitlementRequest {
    return {
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
    };
  },

  toJSON(message: OrderEntitlementRequest): unknown {
    const obj: any = {};
    if (message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEntitlementRequest>, I>>(base?: I): OrderEntitlementRequest {
    return OrderEntitlementRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderEntitlementRequest>, I>>(object: I): OrderEntitlementRequest {
    const message = createBaseOrderEntitlementRequest();
    message.contractId = object.contractId ?? 0;
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseOrderEntitlementReport(): OrderEntitlementReport {
  return { orderEntitlements: [] };
}

export const OrderEntitlementReport = {
  encode(message: OrderEntitlementReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.orderEntitlements) {
      OrderEntitlement.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEntitlementReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEntitlementReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderEntitlements.push(OrderEntitlement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderEntitlementReport {
    return {
      orderEntitlements: globalThis.Array.isArray(object?.orderEntitlements)
        ? object.orderEntitlements.map((e: any) => OrderEntitlement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OrderEntitlementReport): unknown {
    const obj: any = {};
    if (message.orderEntitlements?.length) {
      obj.orderEntitlements = message.orderEntitlements.map((e) => OrderEntitlement.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEntitlementReport>, I>>(base?: I): OrderEntitlementReport {
    return OrderEntitlementReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderEntitlementReport>, I>>(object: I): OrderEntitlementReport {
    const message = createBaseOrderEntitlementReport();
    message.orderEntitlements = object.orderEntitlements?.map((e) => OrderEntitlement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOrderEntitlement(): OrderEntitlement {
  return {
    orderType: 0,
    isSynthetic: false,
    duration: 0,
    execInstruction: 0,
    algoStrategyRequired: false,
    algoStrategies: [],
  };
}

export const OrderEntitlement = {
  encode(message: OrderEntitlement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderType !== 0) {
      writer.uint32(8).uint32(message.orderType);
    }
    if (message.isSynthetic !== false) {
      writer.uint32(16).bool(message.isSynthetic);
    }
    if (message.duration !== 0) {
      writer.uint32(24).uint32(message.duration);
    }
    if (message.execInstruction !== 0) {
      writer.uint32(32).uint32(message.execInstruction);
    }
    if (message.algoStrategyRequired !== undefined && message.algoStrategyRequired !== false) {
      writer.uint32(40).bool(message.algoStrategyRequired);
    }
    for (const v of message.algoStrategies) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEntitlement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEntitlement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.orderType = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSynthetic = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.duration = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.execInstruction = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.algoStrategyRequired = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.algoStrategies.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderEntitlement {
    return {
      orderType: isSet(object.orderType) ? globalThis.Number(object.orderType) : 0,
      isSynthetic: isSet(object.isSynthetic) ? globalThis.Boolean(object.isSynthetic) : false,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      execInstruction: isSet(object.execInstruction) ? globalThis.Number(object.execInstruction) : 0,
      algoStrategyRequired: isSet(object.algoStrategyRequired)
        ? globalThis.Boolean(object.algoStrategyRequired)
        : false,
      algoStrategies: globalThis.Array.isArray(object?.algoStrategies)
        ? object.algoStrategies.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: OrderEntitlement): unknown {
    const obj: any = {};
    if (message.orderType !== 0) {
      obj.orderType = Math.round(message.orderType);
    }
    if (message.isSynthetic !== false) {
      obj.isSynthetic = message.isSynthetic;
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.execInstruction !== 0) {
      obj.execInstruction = Math.round(message.execInstruction);
    }
    if (message.algoStrategyRequired !== undefined && message.algoStrategyRequired !== false) {
      obj.algoStrategyRequired = message.algoStrategyRequired;
    }
    if (message.algoStrategies?.length) {
      obj.algoStrategies = message.algoStrategies;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEntitlement>, I>>(base?: I): OrderEntitlement {
    return OrderEntitlement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderEntitlement>, I>>(object: I): OrderEntitlement {
    const message = createBaseOrderEntitlement();
    message.orderType = object.orderType ?? 0;
    message.isSynthetic = object.isSynthetic ?? false;
    message.duration = object.duration ?? 0;
    message.execInstruction = object.execInstruction ?? 0;
    message.algoStrategyRequired = object.algoStrategyRequired ?? false;
    message.algoStrategies = object.algoStrategies?.map((e) => e) || [];
    return message;
  },
};

function createBaseCrossOrderParameters(): CrossOrderParameters {
  return { sideAllocations: [], price: 0, transactionId: "", legAllocations: [] };
}

export const CrossOrderParameters = {
  encode(message: CrossOrderParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sideAllocations) {
      SideAllocation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.price !== undefined && message.price !== 0) {
      writer.uint32(17).double(message.price);
    }
    if (message.transactionId !== undefined && message.transactionId !== "") {
      writer.uint32(26).string(message.transactionId);
    }
    for (const v of message.legAllocations) {
      LegAllocation.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrossOrderParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrossOrderParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sideAllocations.push(SideAllocation.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.price = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.legAllocations.push(LegAllocation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrossOrderParameters {
    return {
      sideAllocations: globalThis.Array.isArray(object?.sideAllocations)
        ? object.sideAllocations.map((e: any) => SideAllocation.fromJSON(e))
        : [],
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      legAllocations: globalThis.Array.isArray(object?.legAllocations)
        ? object.legAllocations.map((e: any) => LegAllocation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CrossOrderParameters): unknown {
    const obj: any = {};
    if (message.sideAllocations?.length) {
      obj.sideAllocations = message.sideAllocations.map((e) => SideAllocation.toJSON(e));
    }
    if (message.price !== undefined && message.price !== 0) {
      obj.price = message.price;
    }
    if (message.transactionId !== undefined && message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.legAllocations?.length) {
      obj.legAllocations = message.legAllocations.map((e) => LegAllocation.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrossOrderParameters>, I>>(base?: I): CrossOrderParameters {
    return CrossOrderParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CrossOrderParameters>, I>>(object: I): CrossOrderParameters {
    const message = createBaseCrossOrderParameters();
    message.sideAllocations = object.sideAllocations?.map((e) => SideAllocation.fromPartial(e)) || [];
    message.price = object.price ?? 0;
    message.transactionId = object.transactionId ?? "";
    message.legAllocations = object.legAllocations?.map((e) => LegAllocation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSideAllocation(): SideAllocation {
  return { sideId: 0, accountId: 0, externalAccount: undefined, qty: undefined, side: 0, extraAttributes: [] };
}

export const SideAllocation = {
  encode(message: SideAllocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sideId !== undefined && message.sideId !== 0) {
      writer.uint32(8).sint64(message.sideId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.externalAccount !== undefined) {
      ExternalAccount.encode(message.externalAccount, writer.uint32(26).fork()).ldelim();
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(34).fork()).ldelim();
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(40).uint32(message.side);
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SideAllocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSideAllocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sideId = longToNumber(reader.sint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.externalAccount = ExternalAccount.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SideAllocation {
    return {
      sideId: isSet(object.sideId) ? globalThis.Number(object.sideId) : 0,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      externalAccount: isSet(object.externalAccount) ? ExternalAccount.fromJSON(object.externalAccount) : undefined,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      extraAttributes: globalThis.Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SideAllocation): unknown {
    const obj: any = {};
    if (message.sideId !== undefined && message.sideId !== 0) {
      obj.sideId = Math.round(message.sideId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.externalAccount !== undefined) {
      obj.externalAccount = ExternalAccount.toJSON(message.externalAccount);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.extraAttributes?.length) {
      obj.extraAttributes = message.extraAttributes.map((e) => NamedValue.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SideAllocation>, I>>(base?: I): SideAllocation {
    return SideAllocation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SideAllocation>, I>>(object: I): SideAllocation {
    const message = createBaseSideAllocation();
    message.sideId = object.sideId ?? 0;
    message.accountId = object.accountId ?? 0;
    message.externalAccount = (object.externalAccount !== undefined && object.externalAccount !== null)
      ? ExternalAccount.fromPartial(object.externalAccount)
      : undefined;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.side = object.side ?? 0;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLegAllocation(): LegAllocation {
  return { contractId: 0, qty: undefined, price: 0, side: 0, legAllocId: 0, tradeMatchId: "" };
}

export const LegAllocation = {
  encode(message: LegAllocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== undefined && message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(18).fork()).ldelim();
    }
    if (message.price !== undefined && message.price !== 0) {
      writer.uint32(25).double(message.price);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(32).uint32(message.side);
    }
    if (message.legAllocId !== undefined && message.legAllocId !== 0) {
      writer.uint32(40).sint64(message.legAllocId);
    }
    if (message.tradeMatchId !== undefined && message.tradeMatchId !== "") {
      writer.uint32(50).string(message.tradeMatchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LegAllocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLegAllocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.price = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.legAllocId = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tradeMatchId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LegAllocation {
    return {
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      legAllocId: isSet(object.legAllocId) ? globalThis.Number(object.legAllocId) : 0,
      tradeMatchId: isSet(object.tradeMatchId) ? globalThis.String(object.tradeMatchId) : "",
    };
  },

  toJSON(message: LegAllocation): unknown {
    const obj: any = {};
    if (message.contractId !== undefined && message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.price !== undefined && message.price !== 0) {
      obj.price = message.price;
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.legAllocId !== undefined && message.legAllocId !== 0) {
      obj.legAllocId = Math.round(message.legAllocId);
    }
    if (message.tradeMatchId !== undefined && message.tradeMatchId !== "") {
      obj.tradeMatchId = message.tradeMatchId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LegAllocation>, I>>(base?: I): LegAllocation {
    return LegAllocation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LegAllocation>, I>>(object: I): LegAllocation {
    const message = createBaseLegAllocation();
    message.contractId = object.contractId ?? 0;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.price = object.price ?? 0;
    message.side = object.side ?? 0;
    message.legAllocId = object.legAllocId ?? 0;
    message.tradeMatchId = object.tradeMatchId ?? "";
    return message;
  },
};

function createBaseExternalAccount(): ExternalAccount {
  return { externalAccountNumber: "", accountType: "", accountOrigin: "", giveUpMemberId: "" };
}

export const ExternalAccount = {
  encode(message: ExternalAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.externalAccountNumber !== undefined && message.externalAccountNumber !== "") {
      writer.uint32(10).string(message.externalAccountNumber);
    }
    if (message.accountType !== undefined && message.accountType !== "") {
      writer.uint32(18).string(message.accountType);
    }
    if (message.accountOrigin !== undefined && message.accountOrigin !== "") {
      writer.uint32(26).string(message.accountOrigin);
    }
    if (message.giveUpMemberId !== undefined && message.giveUpMemberId !== "") {
      writer.uint32(34).string(message.giveUpMemberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.externalAccountNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountOrigin = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.giveUpMemberId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalAccount {
    return {
      externalAccountNumber: isSet(object.externalAccountNumber) ? globalThis.String(object.externalAccountNumber) : "",
      accountType: isSet(object.accountType) ? globalThis.String(object.accountType) : "",
      accountOrigin: isSet(object.accountOrigin) ? globalThis.String(object.accountOrigin) : "",
      giveUpMemberId: isSet(object.giveUpMemberId) ? globalThis.String(object.giveUpMemberId) : "",
    };
  },

  toJSON(message: ExternalAccount): unknown {
    const obj: any = {};
    if (message.externalAccountNumber !== undefined && message.externalAccountNumber !== "") {
      obj.externalAccountNumber = message.externalAccountNumber;
    }
    if (message.accountType !== undefined && message.accountType !== "") {
      obj.accountType = message.accountType;
    }
    if (message.accountOrigin !== undefined && message.accountOrigin !== "") {
      obj.accountOrigin = message.accountOrigin;
    }
    if (message.giveUpMemberId !== undefined && message.giveUpMemberId !== "") {
      obj.giveUpMemberId = message.giveUpMemberId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExternalAccount>, I>>(base?: I): ExternalAccount {
    return ExternalAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExternalAccount>, I>>(object: I): ExternalAccount {
    const message = createBaseExternalAccount();
    message.externalAccountNumber = object.externalAccountNumber ?? "";
    message.accountType = object.accountType ?? "";
    message.accountOrigin = object.accountOrigin ?? "";
    message.giveUpMemberId = object.giveUpMemberId ?? "";
    return message;
  },
};

function createBaseApproveOrder(): ApproveOrder {
  return {
    orderId: "",
    accountId: 0,
    action: 0,
    whenUtcTimestamp: undefined,
    sideAllocation: undefined,
    extraAttributes: [],
  };
}

export const ApproveOrder = {
  encode(message: ApproveOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.action !== undefined && message.action !== 0) {
      writer.uint32(24).uint32(message.action);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    if (message.sideAllocation !== undefined) {
      SideAllocation.encode(message.sideAllocation, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApproveOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.action = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sideAllocation = SideAllocation.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApproveOrder {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      action: isSet(object.action) ? globalThis.Number(object.action) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      sideAllocation: isSet(object.sideAllocation) ? SideAllocation.fromJSON(object.sideAllocation) : undefined,
      extraAttributes: globalThis.Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApproveOrder): unknown {
    const obj: any = {};
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.action !== undefined && message.action !== 0) {
      obj.action = Math.round(message.action);
    }
    if (message.whenUtcTimestamp !== undefined) {
      obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString();
    }
    if (message.sideAllocation !== undefined) {
      obj.sideAllocation = SideAllocation.toJSON(message.sideAllocation);
    }
    if (message.extraAttributes?.length) {
      obj.extraAttributes = message.extraAttributes.map((e) => NamedValue.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApproveOrder>, I>>(base?: I): ApproveOrder {
    return ApproveOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApproveOrder>, I>>(object: I): ApproveOrder {
    const message = createBaseApproveOrder();
    message.orderId = object.orderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.action = object.action ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.sideAllocation = (object.sideAllocation !== undefined && object.sideAllocation !== null)
      ? SideAllocation.fromPartial(object.sideAllocation)
      : undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFillCareOrder(): FillCareOrder {
  return {
    execId: "",
    fillCareOrderRequestId: "",
    fillPrice: 0,
    fillQty: undefined,
    orderId: "",
    accountId: 0,
    orderLegs: [],
    oppositeSide: undefined,
  };
}

export const FillCareOrder = {
  encode(message: FillCareOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.execId !== undefined && message.execId !== "") {
      writer.uint32(10).string(message.execId);
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      writer.uint32(18).string(message.fillCareOrderRequestId);
    }
    if (message.fillPrice !== undefined && message.fillPrice !== 0) {
      writer.uint32(25).double(message.fillPrice);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(34).fork()).ldelim();
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(42).string(message.orderId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(48).sint32(message.accountId);
    }
    for (const v of message.orderLegs) {
      FillCareOrderLeg.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.oppositeSide !== undefined) {
      FillCareOppositeSide.encode(message.oppositeSide, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCareOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCareOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.execId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fillCareOrderRequestId = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.fillPrice = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fillQty = Decimal.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.orderLegs.push(FillCareOrderLeg.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.oppositeSide = FillCareOppositeSide.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FillCareOrder {
    return {
      execId: isSet(object.execId) ? globalThis.String(object.execId) : "",
      fillCareOrderRequestId: isSet(object.fillCareOrderRequestId)
        ? globalThis.String(object.fillCareOrderRequestId)
        : "",
      fillPrice: isSet(object.fillPrice) ? globalThis.Number(object.fillPrice) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      orderLegs: globalThis.Array.isArray(object?.orderLegs)
        ? object.orderLegs.map((e: any) => FillCareOrderLeg.fromJSON(e))
        : [],
      oppositeSide: isSet(object.oppositeSide) ? FillCareOppositeSide.fromJSON(object.oppositeSide) : undefined,
    };
  },

  toJSON(message: FillCareOrder): unknown {
    const obj: any = {};
    if (message.execId !== undefined && message.execId !== "") {
      obj.execId = message.execId;
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      obj.fillCareOrderRequestId = message.fillCareOrderRequestId;
    }
    if (message.fillPrice !== undefined && message.fillPrice !== 0) {
      obj.fillPrice = message.fillPrice;
    }
    if (message.fillQty !== undefined) {
      obj.fillQty = Decimal.toJSON(message.fillQty);
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.orderLegs?.length) {
      obj.orderLegs = message.orderLegs.map((e) => FillCareOrderLeg.toJSON(e));
    }
    if (message.oppositeSide !== undefined) {
      obj.oppositeSide = FillCareOppositeSide.toJSON(message.oppositeSide);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCareOrder>, I>>(base?: I): FillCareOrder {
    return FillCareOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FillCareOrder>, I>>(object: I): FillCareOrder {
    const message = createBaseFillCareOrder();
    message.execId = object.execId ?? "";
    message.fillCareOrderRequestId = object.fillCareOrderRequestId ?? "";
    message.fillPrice = object.fillPrice ?? 0;
    message.fillQty = (object.fillQty !== undefined && object.fillQty !== null)
      ? Decimal.fromPartial(object.fillQty)
      : undefined;
    message.orderId = object.orderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.orderLegs = object.orderLegs?.map((e) => FillCareOrderLeg.fromPartial(e)) || [];
    message.oppositeSide = (object.oppositeSide !== undefined && object.oppositeSide !== null)
      ? FillCareOppositeSide.fromPartial(object.oppositeSide)
      : undefined;
    return message;
  },
};

function createBaseFillCareOrderLeg(): FillCareOrderLeg {
  return { legExecId: "", nodeIndex: 0, fillPrice: 0, fillQty: undefined };
}

export const FillCareOrderLeg = {
  encode(message: FillCareOrderLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.legExecId !== undefined && message.legExecId !== "") {
      writer.uint32(10).string(message.legExecId);
    }
    if (message.nodeIndex !== undefined && message.nodeIndex !== 0) {
      writer.uint32(16).uint32(message.nodeIndex);
    }
    if (message.fillPrice !== undefined && message.fillPrice !== 0) {
      writer.uint32(25).double(message.fillPrice);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCareOrderLeg {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCareOrderLeg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.legExecId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.nodeIndex = reader.uint32();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.fillPrice = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fillQty = Decimal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FillCareOrderLeg {
    return {
      legExecId: isSet(object.legExecId) ? globalThis.String(object.legExecId) : "",
      nodeIndex: isSet(object.nodeIndex) ? globalThis.Number(object.nodeIndex) : 0,
      fillPrice: isSet(object.fillPrice) ? globalThis.Number(object.fillPrice) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
    };
  },

  toJSON(message: FillCareOrderLeg): unknown {
    const obj: any = {};
    if (message.legExecId !== undefined && message.legExecId !== "") {
      obj.legExecId = message.legExecId;
    }
    if (message.nodeIndex !== undefined && message.nodeIndex !== 0) {
      obj.nodeIndex = Math.round(message.nodeIndex);
    }
    if (message.fillPrice !== undefined && message.fillPrice !== 0) {
      obj.fillPrice = message.fillPrice;
    }
    if (message.fillQty !== undefined) {
      obj.fillQty = Decimal.toJSON(message.fillQty);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCareOrderLeg>, I>>(base?: I): FillCareOrderLeg {
    return FillCareOrderLeg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FillCareOrderLeg>, I>>(object: I): FillCareOrderLeg {
    const message = createBaseFillCareOrderLeg();
    message.legExecId = object.legExecId ?? "";
    message.nodeIndex = object.nodeIndex ?? 0;
    message.fillPrice = object.fillPrice ?? 0;
    message.fillQty = (object.fillQty !== undefined && object.fillQty !== null)
      ? Decimal.fromPartial(object.fillQty)
      : undefined;
    return message;
  },
};

function createBaseFillCareOppositeSide(): FillCareOppositeSide {
  return { execId: "", orderId: "", createNewOrder: false, accountId: 0, orderLegs: [] };
}

export const FillCareOppositeSide = {
  encode(message: FillCareOppositeSide, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.execId !== undefined && message.execId !== "") {
      writer.uint32(10).string(message.execId);
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    if (message.createNewOrder !== undefined && message.createNewOrder !== false) {
      writer.uint32(24).bool(message.createNewOrder);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(32).sint32(message.accountId);
    }
    for (const v of message.orderLegs) {
      FillCareOrderLeg.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCareOppositeSide {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCareOppositeSide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.execId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createNewOrder = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orderLegs.push(FillCareOrderLeg.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FillCareOppositeSide {
    return {
      execId: isSet(object.execId) ? globalThis.String(object.execId) : "",
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      createNewOrder: isSet(object.createNewOrder) ? globalThis.Boolean(object.createNewOrder) : false,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      orderLegs: globalThis.Array.isArray(object?.orderLegs)
        ? object.orderLegs.map((e: any) => FillCareOrderLeg.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FillCareOppositeSide): unknown {
    const obj: any = {};
    if (message.execId !== undefined && message.execId !== "") {
      obj.execId = message.execId;
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.createNewOrder !== undefined && message.createNewOrder !== false) {
      obj.createNewOrder = message.createNewOrder;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.orderLegs?.length) {
      obj.orderLegs = message.orderLegs.map((e) => FillCareOrderLeg.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCareOppositeSide>, I>>(base?: I): FillCareOppositeSide {
    return FillCareOppositeSide.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FillCareOppositeSide>, I>>(object: I): FillCareOppositeSide {
    const message = createBaseFillCareOppositeSide();
    message.execId = object.execId ?? "";
    message.orderId = object.orderId ?? "";
    message.createNewOrder = object.createNewOrder ?? false;
    message.accountId = object.accountId ?? 0;
    message.orderLegs = object.orderLegs?.map((e) => FillCareOrderLeg.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOrderStatusRequest(): OrderStatusRequest {
  return { chainOrderId: "", accountId: 0 };
}

export const OrderStatusRequest = {
  encode(message: OrderStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderStatusRequest {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
    };
  },

  toJSON(message: OrderStatusRequest): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderStatusRequest>, I>>(base?: I): OrderStatusRequest {
    return OrderStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderStatusRequest>, I>>(object: I): OrderStatusRequest {
    const message = createBaseOrderStatusRequest();
    message.chainOrderId = object.chainOrderId ?? "";
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseOrderStatusReport(): OrderStatusReport {
  return { orderStatus: undefined };
}

export const OrderStatusReport = {
  encode(message: OrderStatusReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderStatus !== undefined) {
      OrderStatus.encode(message.orderStatus, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatusReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderStatusReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderStatus = OrderStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderStatusReport {
    return { orderStatus: isSet(object.orderStatus) ? OrderStatus.fromJSON(object.orderStatus) : undefined };
  },

  toJSON(message: OrderStatusReport): unknown {
    const obj: any = {};
    if (message.orderStatus !== undefined) {
      obj.orderStatus = OrderStatus.toJSON(message.orderStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderStatusReport>, I>>(base?: I): OrderStatusReport {
    return OrderStatusReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderStatusReport>, I>>(object: I): OrderStatusReport {
    const message = createBaseOrderStatusReport();
    message.orderStatus = (object.orderStatus !== undefined && object.orderStatus !== null)
      ? OrderStatus.fromPartial(object.orderStatus)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { GridSortModel } from "@mui/x-data-grid";

export const sortRows = (rows: any, sortModel: GridSortModel) => {
  if (sortModel.length === 0) return rows;
  const { field, sort } = sortModel[0];
  return [...rows].sort((a, b) => {
    const aValue = a[field];
    const bValue = b[field];
    if (aValue < bValue) return sort === "asc" ? -1 : 1;
    if (aValue > bValue) return sort === "asc" ? 1 : -1;
    return 0;
  });
};

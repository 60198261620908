// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: market_data_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Decimal } from "./common/decimal";
import { Text } from "./common/shared_1";
import { Timestamp } from "./google/protobuf/timestamp";
import Long from "long";

export const protobufPackage = "market_data_2";

/**
 * Subscription to market data.
 * If it is necessary to change subscription level client should send a new subscription request
 * with the same contract ID but a new subscription level.
 */
export interface MarketDataSubscription {
  /**
   * Contract ID to subscribe (see symbol resolution report).
   * This is client's responsibility to re-subscribe in case of symbol resolution update.
   */
  contractId: number;
  /**
   * Subscription request id. Should be unique among currently processed requests.
   * It is a required field.
   */
  requestId?:
    | number
    | undefined;
  /**
   * Subscription level.
   * This field is associated with Level enum type.
   * Result level will be available in MarketDataSubscriptionStatus.
   */
  level: number;
  /**
   * Defines market values array in real-time market data snapshot.
   * False or omitted means that snapshots will contain market values for most recent trading day only (if available).
   * True means that snapshots may contain market values for several (up to 3) past trading days.
   */
  includePastMarketValues?:
    | boolean
    | undefined;
  /**
   * Defines quotes array in real-time market data snapshot.
   * See details in commentary for field RealTimeMarketData.quotes.
   * Note: subscription with include_past_quotes=True may take longer.
   */
  includePastQuotes?:
    | boolean
    | undefined;
  /**
   * Defines session market values array in real-time market data snapshot.
   * False or omitted means that snapshots will not contain session market values.
   * True means that snapshots may contain session market values for several sessions.
   */
  includeSessionMarketValues?:
    | boolean
    | undefined;
  /**
   * Specifies that quotes and market values need to include yields.
   * Result value will be available in MarketDataSubscriptionStatus.
   * Re-subscription with the changed value of this parameter may lead to temporary disconnection
   * of real-time market data (STATUS_CODE_DISCONNECTED).
   */
  includeYields?:
    | boolean
    | undefined;
  /**
   * Defines source contract quote price in real-time market data.
   * scaled_source_price is provided only for best ask and best bid.
   * Other quote types either have the same values as scaled_price for the contract or not supported.
   * Note: Flag is ignored (considered as False) if the contract does not have a source contract.
   * Re-subscription with the changed value of this parameter may lead to temporary disconnection
   * of real-time market data (STATUS_CODE_DISCONNECTED).
   */
  includeSourcePrices?:
    | boolean
    | undefined;
  /**
   * Whether need to include RFQ in real-time market data. By default server don't provide them.
   * Result value will be available in MarketDataSubscriptionStatus.
   */
  includeRequestsForQuotation?:
    | boolean
    | undefined;
  /** Specifies that quotes need to include trade attributes. */
  includeTradeAttributes?:
    | boolean
    | undefined;
  /** Specifies to include market state in real-time market data. */
  includeMarketState?:
    | boolean
    | undefined;
  /** Specifies to include Off Market trade quotes in real-time market data. */
  includeOffMarketTrades?:
    | boolean
    | undefined;
  /** Optional OTC contributor ID for contributor specific data subscriptions. */
  contributorId?:
    | string
    | undefined;
  /**
   * Specifies that quotes and market values need to include currency prices used for price conversion.
   * Note: Flag is ignored (considered as False) if the contract does not have a source contract.
   * Re-subscription with the changed value of this parameter may lead to temporary disconnection
   * of real-time market data (STATUS_CODE_DISCONNECTED).
   */
  includeCurrencyRate?:
    | boolean
    | undefined;
  /**
   * DOM data subscription type for level LEVEL_TRADES_BBA_DOM or LEVEL_TRADES_BBA_DETAILED_DOM.
   * This field is associated with DomType enum type.
   * Default value is DOM_TYPE_COMBINED
   */
  domSubscriptionType?:
    | number
    | undefined;
  /** Specifies whether to include scaled premium price added to quote price. */
  includePremium?: boolean | undefined;
}

/** Level or subscription. */
export enum MarketDataSubscription_Level {
  /** LEVEL_NONE - Unsubscribe. */
  LEVEL_NONE = 0,
  /**
   * LEVEL_SETTLEMENTS - Get only settlement quotes.
   * NOTE: MarketValues will contain only settlements.
   */
  LEVEL_SETTLEMENTS = 5,
  /**
   * LEVEL_END_OF_DAY - Get only market values.
   * Supported only for contracts with filled field ContractMetadata::end_of_day_delay.
   * NOTE: Array of quotes, requests for quotation, corrections, detailed DOM,
   * market state in RealTimeMarketData message will be always empty.
   * NOTE: If current trading day is not yet available (see commentary to field 'end_of_day_delay' in message
   * ContractMetadata), then MarketValues for today are provided the same way as if trading day was just started:
   * only required fields and yesterday prices are filled.
   */
  LEVEL_END_OF_DAY = 6,
  /** LEVEL_TRADES - Get trade and settlement with volumes (if volumes are known). */
  LEVEL_TRADES = 1,
  /** LEVEL_TRADES_BBA - Get trades and settlements with volumes (if volumes are known), best asks and best bids without volumes. */
  LEVEL_TRADES_BBA = 2,
  /** LEVEL_TRADES_BBA_VOLUMES - Get trades, settlements, best asks and best bids with volumes (if volumes are known). */
  LEVEL_TRADES_BBA_VOLUMES = 3,
  /**
   * LEVEL_TRADES_BBA_DOM - All price data including DOM (Implied and/or Combined, depending on dom_subscription_type
   * and MarketDataSubscriptionStatus.actual_dom_subscription_type).
   */
  LEVEL_TRADES_BBA_DOM = 4,
  /**
   * LEVEL_TRADES_BBA_DETAILED_DOM - LEVEL_TRADES_BBA_DOM + Order Details.
   * Note: Includes information for all orders (both implied and outright).
   */
  LEVEL_TRADES_BBA_DETAILED_DOM = 7,
  UNRECOGNIZED = -1,
}

export function marketDataSubscription_LevelFromJSON(object: any): MarketDataSubscription_Level {
  switch (object) {
    case 0:
    case "LEVEL_NONE":
      return MarketDataSubscription_Level.LEVEL_NONE;
    case 5:
    case "LEVEL_SETTLEMENTS":
      return MarketDataSubscription_Level.LEVEL_SETTLEMENTS;
    case 6:
    case "LEVEL_END_OF_DAY":
      return MarketDataSubscription_Level.LEVEL_END_OF_DAY;
    case 1:
    case "LEVEL_TRADES":
      return MarketDataSubscription_Level.LEVEL_TRADES;
    case 2:
    case "LEVEL_TRADES_BBA":
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA;
    case 3:
    case "LEVEL_TRADES_BBA_VOLUMES":
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA_VOLUMES;
    case 4:
    case "LEVEL_TRADES_BBA_DOM":
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA_DOM;
    case 7:
    case "LEVEL_TRADES_BBA_DETAILED_DOM":
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA_DETAILED_DOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketDataSubscription_Level.UNRECOGNIZED;
  }
}

export function marketDataSubscription_LevelToJSON(object: MarketDataSubscription_Level): string {
  switch (object) {
    case MarketDataSubscription_Level.LEVEL_NONE:
      return "LEVEL_NONE";
    case MarketDataSubscription_Level.LEVEL_SETTLEMENTS:
      return "LEVEL_SETTLEMENTS";
    case MarketDataSubscription_Level.LEVEL_END_OF_DAY:
      return "LEVEL_END_OF_DAY";
    case MarketDataSubscription_Level.LEVEL_TRADES:
      return "LEVEL_TRADES";
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA:
      return "LEVEL_TRADES_BBA";
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA_VOLUMES:
      return "LEVEL_TRADES_BBA_VOLUMES";
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA_DOM:
      return "LEVEL_TRADES_BBA_DOM";
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA_DETAILED_DOM:
      return "LEVEL_TRADES_BBA_DETAILED_DOM";
    case MarketDataSubscription_Level.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MarketDataSubscription_DomType {
  /**
   * DOM_TYPE_COMBINED - Real-time data shall contain Combined DOM only: a sum of Outright DOM and Implied DOM.
   * Implied DOM is based on spread orders, Outright DOM is based on outright orders.
   */
  DOM_TYPE_COMBINED = 0,
  /** DOM_TYPE_IMPLIED - Real-time data shall contain only Implied DOM. */
  DOM_TYPE_IMPLIED = 1,
  /** DOM_TYPE_IMPLIED_AND_COMBINED - Real-time data shall contain both Combined and Implied DOM. */
  DOM_TYPE_IMPLIED_AND_COMBINED = 2,
  UNRECOGNIZED = -1,
}

export function marketDataSubscription_DomTypeFromJSON(object: any): MarketDataSubscription_DomType {
  switch (object) {
    case 0:
    case "DOM_TYPE_COMBINED":
      return MarketDataSubscription_DomType.DOM_TYPE_COMBINED;
    case 1:
    case "DOM_TYPE_IMPLIED":
      return MarketDataSubscription_DomType.DOM_TYPE_IMPLIED;
    case 2:
    case "DOM_TYPE_IMPLIED_AND_COMBINED":
      return MarketDataSubscription_DomType.DOM_TYPE_IMPLIED_AND_COMBINED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketDataSubscription_DomType.UNRECOGNIZED;
  }
}

export function marketDataSubscription_DomTypeToJSON(object: MarketDataSubscription_DomType): string {
  switch (object) {
    case MarketDataSubscription_DomType.DOM_TYPE_COMBINED:
      return "DOM_TYPE_COMBINED";
    case MarketDataSubscription_DomType.DOM_TYPE_IMPLIED:
      return "DOM_TYPE_IMPLIED";
    case MarketDataSubscription_DomType.DOM_TYPE_IMPLIED_AND_COMBINED:
      return "DOM_TYPE_IMPLIED_AND_COMBINED";
    case MarketDataSubscription_DomType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Subscription status */
export interface MarketDataSubscriptionStatus {
  /** Subscription contract ID. */
  contractId: number;
  /**
   * Subscription result.
   * This field is associated with StatusCode enum type.
   */
  statusCode: number;
  /**
   * Effective subscription level.
   * This field is associated with MarketDataSubscription.Level enum type.
   * Note: when successfully subscribed to delayed DOM data, the reported level is LEVEL_TRADES_BBA_DOM,
   * but actual DOM data may not yet be available and will be available later.
   * Level can be decreased if:
   *   1. the client is prohibited to get requested data;
   *   2. Implied DOM is unavailable for an instrument and level LEVEL_TRADES_BBA_DOM or LEVEL_TRADES_BBA_DETAILED_DOM
   *      was selected with DOM_TYPE_IMPLIED - it will be lowered to LEVEL_TRADES_BBA_VOLUMES;
   */
  level: number;
  /**
   * Possible details of subscription failure.
   *
   * @deprecated
   */
  textMessage?:
    | string
    | undefined;
  /** Possible details of subscription failure. */
  details?:
    | Text
    | undefined;
  /**
   * Specifies whether past quotes are included into real-time market data snapshot or not.
   * Always False if user didn't request past quotes.
   */
  pastQuotesIncluded?:
    | boolean
    | undefined;
  /** Specifies whether intraday session market values are included into real-time market data or not. */
  sessionMarketValuesIncluded?:
    | boolean
    | undefined;
  /** Specifies whether yields are included into real-time market data or not. */
  yieldsIncluded?:
    | boolean
    | undefined;
  /**
   * Specifies whether source contract prices are include into real-time market data or not.
   * Always False if user didn't request source contract quotes.
   */
  sourcePriceIncluded?:
    | boolean
    | undefined;
  /**
   * Specifies whether RFQs are included into real-time market data or not.
   * Always False if user didn't request RFQs.
   */
  requestsForQuotationIncluded?:
    | boolean
    | undefined;
  /**
   * Specifies whether trade attributes included into real-time market data.
   * Always False if user didn't request trade attributes.
   */
  tradeAttributesIncluded?:
    | boolean
    | undefined;
  /**
   * Specifies whether market state included into real-time market data.
   * Always False if user didn't request market state.
   */
  marketStateIncluded?:
    | boolean
    | undefined;
  /**
   * Specifies whether Off Market trade quotes included into real-time market data.
   * Always False if user didn't request Off Market trades.
   */
  offMarketTradesIncluded?:
    | boolean
    | undefined;
  /**
   * Specifies whether currency prices are included into real-time market data.
   * Always False if user did not request currency prices or contract does not use currency conversion.
   */
  currencyRateIncluded?:
    | boolean
    | undefined;
  /** Request ID of a corresponding request. */
  requestId?:
    | number
    | undefined;
  /** OTC contributor ID for contributor specific data subscriptions. */
  contributorId?:
    | string
    | undefined;
  /**
   * Effective DOM data subscription type.
   * This field is associated with MarketDataSubscription.DomType enum type.
   */
  actualDomSubscriptionType?:
    | number
    | undefined;
  /** Specifies whether the scaled premium price is included in quotes. */
  premiumIncluded?: boolean | undefined;
}

export enum MarketDataSubscriptionStatus_StatusCode {
  /** STATUS_CODE_SUCCESS - / success codes (0 - 99) */
  STATUS_CODE_SUCCESS = 0,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is disconnected.
   * Possible reasons: communication issues, metadata update, subscription parameters change.
   * NOTE: Clients should not resubscribe in this case, the server will restore subscription with
   * sending STATUS_CODE_SUCCESS status. If subscription can't be restored for some reason
   * one of the failure codes will be sent.
   */
  STATUS_CODE_DISCONNECTED = 1,
  /**
   * STATUS_CODE_FAILURE - / failure codes (100+)
   * General failure.
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Subscription parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_ACCESS_DENIED - The user is not allowed to be subscribed to this Instrument. */
  STATUS_CODE_ACCESS_DENIED = 103,
  /** STATUS_CODE_DELETED - Market data source was deleted because of expiration or by another reason. */
  STATUS_CODE_DELETED = 104,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The subscription count limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 105,
  /** STATUS_CODE_CONTRIBUTOR_REQUIRED - OTC Contributor ID is required for this subscription but was not provided or not authorized. */
  STATUS_CODE_CONTRIBUTOR_REQUIRED = 106,
  /** STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION - The subscription rate limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION = 107,
  /** STATUS_CODE_NOT_SUPPORTED - Market data subscription is not supported for specified contract. */
  STATUS_CODE_NOT_SUPPORTED = 110,
  UNRECOGNIZED = -1,
}

export function marketDataSubscriptionStatus_StatusCodeFromJSON(object: any): MarketDataSubscriptionStatus_StatusCode {
  switch (object) {
    case 0:
    case "STATUS_CODE_SUCCESS":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS;
    case 1:
    case "STATUS_CODE_DISCONNECTED":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case "STATUS_CODE_FAILURE":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case "STATUS_CODE_INVALID_PARAMS":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 103:
    case "STATUS_CODE_ACCESS_DENIED":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_ACCESS_DENIED;
    case 104:
    case "STATUS_CODE_DELETED":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DELETED;
    case 105:
    case "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 106:
    case "STATUS_CODE_CONTRIBUTOR_REQUIRED":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED;
    case 107:
    case "STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION;
    case 110:
    case "STATUS_CODE_NOT_SUPPORTED":
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_NOT_SUPPORTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketDataSubscriptionStatus_StatusCode.UNRECOGNIZED;
  }
}

export function marketDataSubscriptionStatus_StatusCodeToJSON(object: MarketDataSubscriptionStatus_StatusCode): string {
  switch (object) {
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS:
      return "STATUS_CODE_SUCCESS";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED:
      return "STATUS_CODE_DISCONNECTED";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE:
      return "STATUS_CODE_FAILURE";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return "STATUS_CODE_INVALID_PARAMS";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_ACCESS_DENIED:
      return "STATUS_CODE_ACCESS_DENIED";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DELETED:
      return "STATUS_CODE_DELETED";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED:
      return "STATUS_CODE_CONTRIBUTOR_REQUIRED";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION:
      return "STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION";
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_NOT_SUPPORTED:
      return "STATUS_CODE_NOT_SUPPORTED";
    case MarketDataSubscriptionStatus_StatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Real time data delivery.
 * Snapshot is sent as the first message after subscription and may appear periodically when subscribed.
 */
export interface RealTimeMarketData {
  /** Contract ID assigned by server. */
  contractId: number;
  /**
   * Contract quotes. DOM is updated by new volumes per price. Zero volume is used to clean this price record from DOM.
   * Snapshot quotes have no indicators and no trade attributes.
   * If is_snapshot=False, contains new quotes for the contract.
   * If is_snapshot=True, contains one last quote of each type (best ask, best bid, trade and settlement).
   * If MarketDataSubscription.include_past_quote=False, includes last quotes for most recent trading day
   * or new day in pre-open.
   * If MarketDataSubscription.include_past_quote=True, includes last quotes for last N trading days
   * or new day in pre-open. N by default equals to 7 and can't be changed by client. Contact customer support
   * if you want to increase this value.
   * If you need to get last settlement, prefer to use message MarketValues because it contains trade_date.
   */
  quotes: Quote[];
  /** Trading day the quotes belong to. */
  quotesTradeDate?:
    | number
    | undefined;
  /**
   * Contract correction quotes.
   * If correction affects fields of MarketValues, and MarketValues for affected trading day was sent in the snapshot
   * (see MarketDataSubscription.include_past_market_values), then a separate update for MarketValues will be sent.
   */
  corrections: Quote[];
  /**
   * True if this is a snapshot (all previously known quotes, market values, detailed DOM, market state for
   * this contract should be cleaned).
   */
  isSnapshot?:
    | boolean
    | undefined;
  /**
   * Collapsing level that was applied by server.
   * NONE if the field is omitted.
   * This field is associated with RealTimeCollapsingLevel enum type.
   */
  collapsingLevel?:
    | number
    | undefined;
  /**
   * Market values of a contract (e.g. Open/High/Low/Last) for several past trading days.
   * Present in snapshots and in updates if some values are changed (only changed values are included in updates).
   */
  marketValues: MarketValues[];
  /** Detailed Combined DOM for LEVEL_TRADES_BBA_DETAILED_DOM subscription level. */
  detailedDom?:
    | DetailedDOM
    | undefined;
  /**
   * List of RFQs.
   * Never presents in snapshots, can be provided in updates only.
   */
  requestsForQuotation: RFQ[];
  /**
   * Specifies the current market state. Not all contracts provide such data.
   * Received on subscription and in updates if the state is changed.
   */
  marketState?:
    | MarketState
    | undefined;
  /**
   * Scale to convert scaled_* prices of RealTimeMarketData and its submessages to correct prices,
   * most of the time same as ContractMetadata.correct_price_scale
   * with possible exception of short periods between metadata change notifications and
   * real-time data snapshots. Set in snapshots only, if missing, use the previous one.
   */
  correctPriceScale?: number | undefined;
}

/** Specific quote information */
export interface Quote {
  /** This field is associated with Type enum type. */
  type: number;
  /**
   * Time of the quote (UTC).
   * If time of first quote is not specified quote_utc_times are unknown.
   * Settlement quotes may not have quote_utc_time(even if past_quotes_included=True).
   * If time of snapshot quotes is required, subscribe with include_past_quotes(subscription may be slower).
   * If MarketDataSubscriptionStatus returned with flag past_quotes_included, quotes shall contain valid quote_utc_time.
   * quote_utc_time is set if the time of this quote is different from the previous one in the list of quotes.
   * Several RealTimeMarketData messages can be sent with the same quote_utc_time.
   */
  quoteUtcTime?:
    | number
    | undefined;
  /** Quote price. */
  scaledPrice: number;
  /**
   * Source contract quote price. See MarketDataSubscription.include_source_prices.
   * correct_price_scale from source contract metadata should be used
   * to convert scaled_source_price to correct source price.
   *
   * For Flat and Basis contracts there is no source contract.
   * In this case, use correct_price_scale from this contract metadata
   * to convert scaled_source_price to correct source price.
   * However, scaled_source_price may be imprecise with error up to half a tick due to rounding.
   * Use Price or NetBasis contributor parameters in contract metadata to get precise correct source price.
   */
  scaledSourcePrice?:
    | number
    | undefined;
  /**
   * Yield
   * Included if subscribed and the contract support yields.
   */
  priceYield?:
    | number
    | undefined;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume?:
    | number
    | undefined;
  /**
   * Quote volume.
   * Not set if volume is unknown or if volumes were not requested (see LEVEL_TRADES_BBA for example).
   * Zero volume indicates that the quote has been cleared. It is included even if volumes were not requested.
   */
  volume?:
    | Decimal
    | undefined;
  /** This field is associated with Indicator enum type. */
  indicators: number[];
  /** This field is associated with SalesCondition enum type. */
  salesCondition?:
    | number
    | undefined;
  /** Trade attributes. */
  tradeAttributes?:
    | TradeAttributes
    | undefined;
  /**
   * Currency price used to calculate scaled prices in this message.
   * correct_price_scale from currency rate contract should be used to get correct price.
   */
  scaledCurrencyRatePrice?:
    | number
    | undefined;
  /**
   * Premium that was added to the quote price. It is rounded to the tick size.
   * correct_price_scale should be used to get premium in correct price format.
   * Premium is calculated as a percent of quote price for bid and ask quotes.
   * Note that scaled_price already includes premium!
   */
  scaledPremium?: number | undefined;
}

/** Type of the quote. */
export enum Quote_Type {
  /** TYPE_TRADE - Trade quote. */
  TYPE_TRADE = 0,
  /**
   * TYPE_BESTBID - Combined DOM Best bid quote.
   * Note that best bid/ask quotes are not guaranteed to be consistent with bid/ask quotes in the short
   * term. Thus, best bid/ask quotes must not be used to update DOM.
   */
  TYPE_BESTBID = 1,
  /**
   * TYPE_BESTASK - Combined DOM Best ask quote.
   * See the note for TYPE_BESTBID.
   */
  TYPE_BESTASK = 2,
  /** TYPE_BID - Combined DOM Bid quote (DOM level). */
  TYPE_BID = 3,
  /** TYPE_ASK - Combined DOM Ask quote (DOM level). */
  TYPE_ASK = 4,
  /** TYPE_SETTLEMENT - Settlement quote. */
  TYPE_SETTLEMENT = 5,
  /** TYPE_IMPLIED_BID - Implied Bid quote (For DOM and DetailedDOM level and DOM subscription type that includes Implied DOM). */
  TYPE_IMPLIED_BID = 10,
  /** TYPE_IMPLIED_ASK - Implied Ask quote (For DOM and DetailedDOM level and DOM subscription type that includes Implied DOM). */
  TYPE_IMPLIED_ASK = 11,
  UNRECOGNIZED = -1,
}

export function quote_TypeFromJSON(object: any): Quote_Type {
  switch (object) {
    case 0:
    case "TYPE_TRADE":
      return Quote_Type.TYPE_TRADE;
    case 1:
    case "TYPE_BESTBID":
      return Quote_Type.TYPE_BESTBID;
    case 2:
    case "TYPE_BESTASK":
      return Quote_Type.TYPE_BESTASK;
    case 3:
    case "TYPE_BID":
      return Quote_Type.TYPE_BID;
    case 4:
    case "TYPE_ASK":
      return Quote_Type.TYPE_ASK;
    case 5:
    case "TYPE_SETTLEMENT":
      return Quote_Type.TYPE_SETTLEMENT;
    case 10:
    case "TYPE_IMPLIED_BID":
      return Quote_Type.TYPE_IMPLIED_BID;
    case 11:
    case "TYPE_IMPLIED_ASK":
      return Quote_Type.TYPE_IMPLIED_ASK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Quote_Type.UNRECOGNIZED;
  }
}

export function quote_TypeToJSON(object: Quote_Type): string {
  switch (object) {
    case Quote_Type.TYPE_TRADE:
      return "TYPE_TRADE";
    case Quote_Type.TYPE_BESTBID:
      return "TYPE_BESTBID";
    case Quote_Type.TYPE_BESTASK:
      return "TYPE_BESTASK";
    case Quote_Type.TYPE_BID:
      return "TYPE_BID";
    case Quote_Type.TYPE_ASK:
      return "TYPE_ASK";
    case Quote_Type.TYPE_SETTLEMENT:
      return "TYPE_SETTLEMENT";
    case Quote_Type.TYPE_IMPLIED_BID:
      return "TYPE_IMPLIED_BID";
    case Quote_Type.TYPE_IMPLIED_ASK:
      return "TYPE_IMPLIED_ASK";
    case Quote_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Optional indicator(s) (e.g. this quote also updates some of Open/High/Low/Last session prices). */
export enum Quote_Indicator {
  /** INDICATOR_OPEN - Price of this quote is a new open price of the contract session. */
  INDICATOR_OPEN = 1,
  /** INDICATOR_HIGH - Price of this quote is a new high price of the contract session. */
  INDICATOR_HIGH = 2,
  /** INDICATOR_LOW - Price of this quote is a new low price of the contract session. */
  INDICATOR_LOW = 3,
  /**
   * INDICATOR_LAST - Price of this quote is a new last price of the contract session
   * (corresponds to MarketValues.scaled_last_price_no_settlement).
   * Contains any last received price, excluding settlement price.
   */
  INDICATOR_LAST = 4,
  /**
   * INDICATOR_CLOSE - Price of this quote is a new close price of the contract session.
   * Contains any last received price, including settlement price.
   */
  INDICATOR_CLOSE = 11,
  /** INDICATOR_PAST - This quote doesn't belong to current trading day. */
  INDICATOR_PAST = 5,
  /**
   * INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT - Quote is generated from another one.
   * E.g. for contracts with PricingFallBack OTC contributor parameter set to 1 if there is no best ask,
   * but there is a trade, trade price is sent as a best ask with this indicator along with the trade.
   */
  INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT = 6,
  /**
   * INDICATOR_INSERTION - Quote is non-settlement insertion correction.
   * Used only when Quote is a part of RealTimeMarketData.correction.
   */
  INDICATOR_INSERTION = 7,
  /**
   * INDICATOR_DELETION - Quote is deletion correction.
   * Used only when Quote is a part of RealTimeMarketData.correction.
   */
  INDICATOR_DELETION = 8,
  /**
   * INDICATOR_OFF_MARKET - This is an Off Market quote, it does not affect Open/High/Low/Last
   * session prices and is not used in bar updating.
   */
  INDICATOR_OFF_MARKET = 9,
  /**
   * INDICATOR_CURRENCY_RATE_CHANGED - Quote is caused by change in currency conversion price.
   * Applicable only for best bid and best ask quotes.
   */
  INDICATOR_CURRENCY_RATE_CHANGED = 10,
  UNRECOGNIZED = -1,
}

export function quote_IndicatorFromJSON(object: any): Quote_Indicator {
  switch (object) {
    case 1:
    case "INDICATOR_OPEN":
      return Quote_Indicator.INDICATOR_OPEN;
    case 2:
    case "INDICATOR_HIGH":
      return Quote_Indicator.INDICATOR_HIGH;
    case 3:
    case "INDICATOR_LOW":
      return Quote_Indicator.INDICATOR_LOW;
    case 4:
    case "INDICATOR_LAST":
      return Quote_Indicator.INDICATOR_LAST;
    case 11:
    case "INDICATOR_CLOSE":
      return Quote_Indicator.INDICATOR_CLOSE;
    case 5:
    case "INDICATOR_PAST":
      return Quote_Indicator.INDICATOR_PAST;
    case 6:
    case "INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT":
      return Quote_Indicator.INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT;
    case 7:
    case "INDICATOR_INSERTION":
      return Quote_Indicator.INDICATOR_INSERTION;
    case 8:
    case "INDICATOR_DELETION":
      return Quote_Indicator.INDICATOR_DELETION;
    case 9:
    case "INDICATOR_OFF_MARKET":
      return Quote_Indicator.INDICATOR_OFF_MARKET;
    case 10:
    case "INDICATOR_CURRENCY_RATE_CHANGED":
      return Quote_Indicator.INDICATOR_CURRENCY_RATE_CHANGED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Quote_Indicator.UNRECOGNIZED;
  }
}

export function quote_IndicatorToJSON(object: Quote_Indicator): string {
  switch (object) {
    case Quote_Indicator.INDICATOR_OPEN:
      return "INDICATOR_OPEN";
    case Quote_Indicator.INDICATOR_HIGH:
      return "INDICATOR_HIGH";
    case Quote_Indicator.INDICATOR_LOW:
      return "INDICATOR_LOW";
    case Quote_Indicator.INDICATOR_LAST:
      return "INDICATOR_LAST";
    case Quote_Indicator.INDICATOR_CLOSE:
      return "INDICATOR_CLOSE";
    case Quote_Indicator.INDICATOR_PAST:
      return "INDICATOR_PAST";
    case Quote_Indicator.INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT:
      return "INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT";
    case Quote_Indicator.INDICATOR_INSERTION:
      return "INDICATOR_INSERTION";
    case Quote_Indicator.INDICATOR_DELETION:
      return "INDICATOR_DELETION";
    case Quote_Indicator.INDICATOR_OFF_MARKET:
      return "INDICATOR_OFF_MARKET";
    case Quote_Indicator.INDICATOR_CURRENCY_RATE_CHANGED:
      return "INDICATOR_CURRENCY_RATE_CHANGED";
    case Quote_Indicator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Optional sales condition of the quote. */
export enum Quote_SalesCondition {
  /** SALES_CONDITION_HIT - Hit trade. */
  SALES_CONDITION_HIT = 1,
  /** SALES_CONDITION_TAKE - Take trade. */
  SALES_CONDITION_TAKE = 2,
  /** SALES_CONDITION_SPREAD_LEG - One leg of a spread. */
  SALES_CONDITION_SPREAD_LEG = 3,
  /**
   * SALES_CONDITION_BUY_SIDE_AGGRESSOR - The market participant was the originator of the transaction.
   * He completes trade on buy with price matched by latest Best Ask price.
   */
  SALES_CONDITION_BUY_SIDE_AGGRESSOR = 4,
  /**
   * SALES_CONDITION_SELL_SIDE_AGGRESSOR - The market participant was the originator of the transaction.
   * He completes trade on sell with price matched by latest Best Bid price.
   */
  SALES_CONDITION_SELL_SIDE_AGGRESSOR = 5,
  UNRECOGNIZED = -1,
}

export function quote_SalesConditionFromJSON(object: any): Quote_SalesCondition {
  switch (object) {
    case 1:
    case "SALES_CONDITION_HIT":
      return Quote_SalesCondition.SALES_CONDITION_HIT;
    case 2:
    case "SALES_CONDITION_TAKE":
      return Quote_SalesCondition.SALES_CONDITION_TAKE;
    case 3:
    case "SALES_CONDITION_SPREAD_LEG":
      return Quote_SalesCondition.SALES_CONDITION_SPREAD_LEG;
    case 4:
    case "SALES_CONDITION_BUY_SIDE_AGGRESSOR":
      return Quote_SalesCondition.SALES_CONDITION_BUY_SIDE_AGGRESSOR;
    case 5:
    case "SALES_CONDITION_SELL_SIDE_AGGRESSOR":
      return Quote_SalesCondition.SALES_CONDITION_SELL_SIDE_AGGRESSOR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Quote_SalesCondition.UNRECOGNIZED;
  }
}

export function quote_SalesConditionToJSON(object: Quote_SalesCondition): string {
  switch (object) {
    case Quote_SalesCondition.SALES_CONDITION_HIT:
      return "SALES_CONDITION_HIT";
    case Quote_SalesCondition.SALES_CONDITION_TAKE:
      return "SALES_CONDITION_TAKE";
    case Quote_SalesCondition.SALES_CONDITION_SPREAD_LEG:
      return "SALES_CONDITION_SPREAD_LEG";
    case Quote_SalesCondition.SALES_CONDITION_BUY_SIDE_AGGRESSOR:
      return "SALES_CONDITION_BUY_SIDE_AGGRESSOR";
    case Quote_SalesCondition.SALES_CONDITION_SELL_SIDE_AGGRESSOR:
      return "SALES_CONDITION_SELL_SIDE_AGGRESSOR";
    case Quote_SalesCondition.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Quote trade attributes. */
export interface TradeAttributes {
  /** Buyer contributor id from ContributorMetadata. */
  buyer?:
    | number
    | undefined;
  /** Seller contributor id from ContributorMetadata. */
  seller?:
    | number
    | undefined;
  /** Quote trade type. */
  tradeType?:
    | string
    | undefined;
  /** Match ID. */
  matchId?:
    | string
    | undefined;
  /** UTC agreement time. */
  agreementTimeUtc?: Date | undefined;
}

/**
 * Market data values of a contract.
 * The market values are sent after subscription and when there are changes not reflected in quotes.
 * Note that market values do not duplicate the updates sent as quotes. E.g. customers are expected
 * to maintain total_volume, tick_volume and OHLC based on quotes and quote indicators.
 * If both quotes and market_values are sent in a single RealTimeMarketData update (is_snapshot=False),
 * then the order of applying quotes and market_values to a user snapshot doesn't matter.
 */
export interface MarketValues {
  /** First price among all tick types from ContractMetadata.bar_building_tick_types. */
  scaledOpenPrice?:
    | number
    | undefined;
  /** Highest price among all tick types from ContractMetadata.bar_building_tick_types. */
  scaledHighPrice?:
    | number
    | undefined;
  /** Lowest price among all tick types from ContractMetadata.bar_building_tick_types. */
  scaledLowPrice?:
    | number
    | undefined;
  /**
   * Left for backward compatibility. Sometimes we provide in this field 'scaled_close_price',
   * sometimes - 'scaled_last_price_no_settlement'.
   * Note: use 'scaled_last_price_no_settlement'/'last_close_price' instead.
   *
   * @deprecated
   */
  scaledLastPrice?:
    | number
    | undefined;
  /**
   * Close price. Details can be found in enum ClosePricePreference of message historical_2.TimeBarParameters,
   * see CLOSE_PRICE_PREFERENCE_DEFAULT.
   * Contains any last received price, including settlement price.
   */
  scaledClosePrice?:
    | number
    | undefined;
  /**
   * Latest price among all tick types from metadata_2.ContractMetadata.bar_building_tick_types,
   * except for BAR_BUILDING_TICK_TYPE_SETTLEMENT. Settlements don't affect last_price.
   * Contains any last received price, excluding settlement price.
   * Can be unavailable for delayed data.
   */
  scaledLastPriceNoSettlement?:
    | number
    | undefined;
  /**
   * Yesterday settlement price.
   * NOTE: Available for current trading day only.
   */
  scaledYesterdaySettlement?:
    | number
    | undefined;
  /**
   * Note: use 'total_volume' instead.
   *
   * @deprecated
   */
  scaledTotalVolume?:
    | number
    | undefined;
  /** Contract total volume. */
  totalVolume?:
    | Decimal
    | undefined;
  /**
   * Yesterday last price.
   * NOTE: Available for current trading day only.
   */
  scaledYesterdayLast?:
    | number
    | undefined;
  /** Indicative open price. */
  scaledIndicativeOpen?:
    | number
    | undefined;
  /**
   * Note: use 'indicative_open_volume' instead.
   *
   * @deprecated
   */
  scaledIndicativeOpenVolume?:
    | number
    | undefined;
  /** Indicative open volume. */
  indicativeOpenVolume?:
    | Decimal
    | undefined;
  /**
   * Day index the market values are related to.
   * 0 - most recent trading day, -1 - trading day before most recent trading day, etc.
   * When new trading day starts, WebAPI sends real-time market data snapshot.
   */
  dayIndex: number;
  /**
   * Note: use 'open_interest' instead.
   *
   * @deprecated
   */
  scaledOpenInterest?:
    | number
    | undefined;
  /** Open interest. */
  openInterest?:
    | Decimal
    | undefined;
  /** Contract tick volume. */
  tickVolume?:
    | number
    | undefined;
  /** Settlement price. */
  scaledSettlement?:
    | number
    | undefined;
  /** Marker price. Also known as PTMMM, Pre-Trade Mid-Market Mark. */
  scaledMarkerPrice?:
    | number
    | undefined;
  /** Last trade price. */
  scaledLastTradePrice?:
    | number
    | undefined;
  /** Last trade volume. */
  lastTradeVolume?:
    | Decimal
    | undefined;
  /**
   * Identifiers of fields being cleared.
   * It also specifies to clear corresponding yield values in market_yields.
   */
  clearedFields: number[];
  /** Trading date the market values belong to. */
  tradeDate: number;
  /**
   * Session index the market values are related to.
   * 0 - first session of the day, 1 - second session of the day, etc.
   */
  sessionIndex?:
    | number
    | undefined;
  /**
   * Market yields.
   * Included if subscribed and the contract support yields.
   */
  marketYields?:
    | MarketYields
    | undefined;
  /**
   * Currency price used to calculate scaled prices in this message.
   * correct_price_scale from currency rate contract should be used to get correct price.
   */
  scaledCurrencyRatePrice?: number | undefined;
}

/** Market yields of a contract. */
export interface MarketYields {
  /** Yield corresponding to open price. */
  yieldOfOpenPrice?:
    | number
    | undefined;
  /** Yield corresponding to high price. */
  yieldOfHighPrice?:
    | number
    | undefined;
  /** Yield corresponding to low price. */
  yieldOfLowPrice?:
    | number
    | undefined;
  /**
   * Yield corresponding to last price.
   *
   * @deprecated
   */
  yieldOfLastPrice?:
    | number
    | undefined;
  /** Yield corresponding to close price. */
  yieldOfClosePrice?:
    | number
    | undefined;
  /** Yield corresponding to last price without settlement. */
  yieldOfLastPriceNoSettlement?:
    | number
    | undefined;
  /**
   * Yesterday settlement yield.
   * NOTE: Available for current trading day only.
   */
  yieldOfYesterdaySettlement?:
    | number
    | undefined;
  /**
   * Yesterday last yield.
   * NOTE: Available for current trading day only.
   */
  yieldOfYesterdayLast?:
    | number
    | undefined;
  /** Indicative open yield. */
  yieldOfIndicativeOpen?:
    | number
    | undefined;
  /** Settlement yield. */
  yieldOfSettlement?: number | undefined;
}

/** Request at-the-money strike for the option maturity. */
export interface AtTheMoneyStrikeRequest {
  /**
   * Identifier of the option maturity representing option strike list of the same underlying and maturity period
   * (see OptionMaturityMetadata.id).
   */
  optionMaturityId: string;
}

/** Report with at-the-money strikes. */
export interface AtTheMoneyStrikeReport {
  /**
   * Option at-the-money strike.
   * It is omitted if at-the-money strike cannot be calculated at the moment (e.g. due to lack of real time data).
   */
  strike?: number | undefined;
}

/**
 * Describes separate orders at price levels.
 * Detailed DOM updates (snapshot or not) can come in several DetailedDOM messages. The first will have
 * RealTimeMarketData.is_snapshot set, the rest will come as updates. The last will have
 * DetailedDOM.is_detailed_dom_complete set.
 * Update for a price level cannot be split into several messages.
 * Updates must be applied in the order they listed in.
 */
export interface DetailedDOM {
  /** True if this message is the last in the series of DetailedDOM updates (snapshot or not) for a contract. */
  isDetailedDomComplete?:
    | boolean
    | undefined;
  /**
   * True if the DOM is stale, i.e. has become obsolete and is not being updated at the moment.
   * A new DetailedDOM message with is_stale = false will be sent when data is fixed.
   */
  isStale?:
    | boolean
    | undefined;
  /** If true, the data for this message should be entirely replaced with 'price_levels' contents. */
  isSnapshot?:
    | boolean
    | undefined;
  /** Price level updates. */
  priceLevels: DetailedDOMAtPrice[];
}

/** Either snapshot of orders at a price level or incremental update. */
export interface DetailedDOMAtPrice {
  /**
   * Price level described by this message.
   * Price is absent for No-price Orders.
   */
  scaledPrice?:
    | number
    | undefined;
  /**
   * Side of this price level. SIDE_BUY or SIDE_SELL.
   * This field is associated with Order.Side enum type.
   */
  side: number;
  /** If true, the data for this price level should be entirely replaced with 'orders' contents. */
  isSnapshot?:
    | boolean
    | undefined;
  /** All orders at the price level, if is_snapshot is true, or changes to the price level, otherwise. */
  orders: DetailedDOMOrder[];
}

/** A single order or change to an existing order (depending on DetailedDOMAtPrice.is_snapshot). */
export interface DetailedDOMOrder {
  /**
   * Same as OrderStatus.detailed_dom_order_id.
   * The field is also non-empty for other users' orders. So, to determine user's place in orders
   * queue user must check if this is his order using the contract_id and detailed_dom_order_id pair.
   */
  detailedDomOrderId: string;
  /** Associated with Operation enum. */
  operation?:
    | number
    | undefined;
  /**
   * Order Index at the price level.
   * It is the index after all updates up to this one have been applied.
   * The index is specified only if it cannot be deduced:
   *  - for INSERT operation it is omitted if equals order_index in previous DetailedDOMOrder plus 1;
   *  - for MODIFY operation it is specified if order place relative to other orders in a price level changed.
   */
  orderIndex?:
    | number
    | undefined;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume?:
    | number
    | undefined;
  /** Order volume, if changed or new order. */
  volume?:
    | Decimal
    | undefined;
  /** If True, then order is Implied. */
  implied?:
    | boolean
    | undefined;
  /** Contributor id from ContributorMetadata. */
  contributorId?: number | undefined;
}

export enum DetailedDOMOrder_Operation {
  /** OPERATION_INSERT - A new order is added. */
  OPERATION_INSERT = 0,
  /** OPERATION_REMOVE - An existing order is removed. */
  OPERATION_REMOVE = 1,
  /** OPERATION_MODIFY - An existing order is modified but remains at the same price level. */
  OPERATION_MODIFY = 2,
  /** OPERATION_MOVE_FROM_PRICE - An existing order is removed from its current price level to another one. */
  OPERATION_MOVE_FROM_PRICE = 3,
  /**
   * OPERATION_MOVE_TO_PRICE - An existing order is inserted into its new price level.
   * Note, some fields can also change.
   * Note, relative order of OPERATION_MOVE_FROM_PRICE and OPERATION_MOVE_TO_PRICE is not defined.
   */
  OPERATION_MOVE_TO_PRICE = 4,
  UNRECOGNIZED = -1,
}

export function detailedDOMOrder_OperationFromJSON(object: any): DetailedDOMOrder_Operation {
  switch (object) {
    case 0:
    case "OPERATION_INSERT":
      return DetailedDOMOrder_Operation.OPERATION_INSERT;
    case 1:
    case "OPERATION_REMOVE":
      return DetailedDOMOrder_Operation.OPERATION_REMOVE;
    case 2:
    case "OPERATION_MODIFY":
      return DetailedDOMOrder_Operation.OPERATION_MODIFY;
    case 3:
    case "OPERATION_MOVE_FROM_PRICE":
      return DetailedDOMOrder_Operation.OPERATION_MOVE_FROM_PRICE;
    case 4:
    case "OPERATION_MOVE_TO_PRICE":
      return DetailedDOMOrder_Operation.OPERATION_MOVE_TO_PRICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DetailedDOMOrder_Operation.UNRECOGNIZED;
  }
}

export function detailedDOMOrder_OperationToJSON(object: DetailedDOMOrder_Operation): string {
  switch (object) {
    case DetailedDOMOrder_Operation.OPERATION_INSERT:
      return "OPERATION_INSERT";
    case DetailedDOMOrder_Operation.OPERATION_REMOVE:
      return "OPERATION_REMOVE";
    case DetailedDOMOrder_Operation.OPERATION_MODIFY:
      return "OPERATION_MODIFY";
    case DetailedDOMOrder_Operation.OPERATION_MOVE_FROM_PRICE:
      return "OPERATION_MOVE_FROM_PRICE";
    case DetailedDOMOrder_Operation.OPERATION_MOVE_TO_PRICE:
      return "OPERATION_MOVE_TO_PRICE";
    case DetailedDOMOrder_Operation.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request for quotation (RFQ). */
export interface RFQ {
  /** Exchange ID of the request. */
  exchangeRequestId?:
    | string
    | undefined;
  /** Timestamp provided by exchange (UTC). */
  utcTimestamp?:
    | Date
    | undefined;
  /**
   * Specifies side of active RFQ.
   * This field is associated with Side enum type.
   */
  side?:
    | number
    | undefined;
  /** RFQ volume. */
  volume?:
    | Decimal
    | undefined;
  /** Expiration time provided by exchange (UTC). */
  expirationUtcTimestamp?: Date | undefined;
}

/** Side of RFQ. */
export enum RFQ_Side {
  /** SIDE_ANY - Any. */
  SIDE_ANY = 0,
  /** SIDE_BID - Bid. */
  SIDE_BID = 1,
  /** SIDE_ASK - Ask. */
  SIDE_ASK = 2,
  /** SIDE_CROSS - Cross. */
  SIDE_CROSS = 3,
  UNRECOGNIZED = -1,
}

export function rFQ_SideFromJSON(object: any): RFQ_Side {
  switch (object) {
    case 0:
    case "SIDE_ANY":
      return RFQ_Side.SIDE_ANY;
    case 1:
    case "SIDE_BID":
      return RFQ_Side.SIDE_BID;
    case 2:
    case "SIDE_ASK":
      return RFQ_Side.SIDE_ASK;
    case 3:
    case "SIDE_CROSS":
      return RFQ_Side.SIDE_CROSS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RFQ_Side.UNRECOGNIZED;
  }
}

export function rFQ_SideToJSON(object: RFQ_Side): string {
  switch (object) {
    case RFQ_Side.SIDE_ANY:
      return "SIDE_ANY";
    case RFQ_Side.SIDE_BID:
      return "SIDE_BID";
    case RFQ_Side.SIDE_ASK:
      return "SIDE_ASK";
    case RFQ_Side.SIDE_CROSS:
      return "SIDE_CROSS";
    case RFQ_Side.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** RFQ request. Used to create RFQ on market. */
export interface RFQRequest {
  /**
   * Client ID of the request.
   * It is a required field.
   */
  clientRequestId?:
    | number
    | undefined;
  /**
   * Id of an account that is used to request quote.
   * It is a required field.
   */
  accountId?:
    | number
    | undefined;
  /**
   * Contract id.
   * It is a required field.
   */
  contractId?:
    | number
    | undefined;
  /**
   * Is the request entered manually.
   * False or omitted means that request is created by automated system.
   */
  isManual?:
    | boolean
    | undefined;
  /**
   * Specifies side of requested quote. Default value is RFQ.SIDE_ANY.
   * This field is associated with RFQ.Side enum type.
   */
  side?:
    | number
    | undefined;
  /** Specifies size of requested quote. Unspecified value means "doesn't matter". */
  size?:
    | Decimal
    | undefined;
  /** Time when RFQ should be created (UTC). */
  executionUtcTimestamp?:
    | Date
    | undefined;
  /**
   * Regulatory Algorithm ID for client algorithm associated with this request, if any.
   * This field is applicable only for exchanges that use it, e.g. Eurex.
   */
  clientRegulatoryAlgorithmId?:
    | number
    | undefined;
  /**
   * Quote request expiration date/time (UTC).
   * Note: there is no way to cancel RFQ. The only way to manage its lifetime is to set expiration_utc_timestamp.
   */
  expirationUtcTimestamp?: Date | undefined;
}

/** Result of RFQ request. */
export interface RFQReport {
  /**
   * Client ID of the request.
   * It is a required field.
   */
  clientRequestId?:
    | number
    | undefined;
  /**
   * Exchange ID of the request.
   * It's set if status_code = STATUS_CODE_SUCCESS.
   */
  exchangeRequestId?:
    | string
    | undefined;
  /**
   * Status code.
   * This field is associated with StatusCode enum type.
   */
  statusCode?:
    | number
    | undefined;
  /** Optional failure details. */
  details?:
    | Text
    | undefined;
  /**
   * Regulatory Algorithm ID actually sent to the exchange for this request, if any.
   * Will differ from client_regulatory_algorithm_id if any CQG algorithm was applied to the order.
   */
  effectiveRegulatoryAlgorithmId?: number | undefined;
}

export enum RFQReport_StatusCode {
  /** STATUS_CODE_SUCCESS - / Success codes (0 - 99) */
  STATUS_CODE_SUCCESS = 0,
  /**
   * STATUS_CODE_FAILURE - / Failure codes (100+)
   * General failure.
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Request parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT - RFQ is not supported for specified contract. */
  STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT = 103,
  UNRECOGNIZED = -1,
}

export function rFQReport_StatusCodeFromJSON(object: any): RFQReport_StatusCode {
  switch (object) {
    case 0:
    case "STATUS_CODE_SUCCESS":
      return RFQReport_StatusCode.STATUS_CODE_SUCCESS;
    case 101:
    case "STATUS_CODE_FAILURE":
      return RFQReport_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case "STATUS_CODE_INVALID_PARAMS":
      return RFQReport_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 103:
    case "STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT":
      return RFQReport_StatusCode.STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RFQReport_StatusCode.UNRECOGNIZED;
  }
}

export function rFQReport_StatusCodeToJSON(object: RFQReport_StatusCode): string {
  switch (object) {
    case RFQReport_StatusCode.STATUS_CODE_SUCCESS:
      return "STATUS_CODE_SUCCESS";
    case RFQReport_StatusCode.STATUS_CODE_FAILURE:
      return "STATUS_CODE_FAILURE";
    case RFQReport_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return "STATUS_CODE_INVALID_PARAMS";
    case RFQReport_StatusCode.STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT:
      return "STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT";
    case RFQReport_StatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Specifies market state for placing orders. */
export interface MarketState {
  /**
   * Current trading session state and allowed order actions.
   * If omitted then there are exchange specific market state changes that do not affect the trading state.
   */
  tradingState?:
    | TradingState
    | undefined;
  /** Array of exchange specific market state elements. */
  exchangeSpecifics: ExchangeSpecificMarketState[];
}

/** Specifies trading session state and allowed order actions. */
export interface TradingState {
  /**
   * Indicates the current exchange state.
   * This field is associated with ExchangeState enum type.
   * If omitted then exchange's provided state information is out of generic ExchangeState enum list.
   * Clients should use ExchangeSpecificMarketState to get detailed exchange state information.
   */
  exchangeState?:
    | number
    | undefined;
  /**
   * Indicates that order placing is allowed.
   * Note that exchanges may have more detailed rules limiting the type of order, duration, etc. that is allowed.
   * Clients should use ExchangeSpecificMarketState if they need to implement/display those detailed rules.
   */
  allowPlaceOrder?:
    | boolean
    | undefined;
  /**
   * Indicates that order canceling is allowed.
   * Note that exchanges may have more detailed rules limiting which orders may be canceled.
   * Clients should use ExchangeSpecificMarketState if they need to implement/display those detailed rules.
   */
  allowCancelOrder?:
    | boolean
    | undefined;
  /**
   * Indicates that order modification is allowed.
   * Note that exchanges may have more detailed rules limiting which modifies are allowed.
   * Clients should use ExchangeSpecificMarketState if they need to implement/display those detailed rules.
   */
  allowModifyOrder?:
    | boolean
    | undefined;
  /** Indicates that continuous trading is occurring at the exchange. */
  matchingEnabled?: boolean | undefined;
}

/**
 * Indicates CQG's normalized exchange trading session state.
 * This is result of a best-effort mapping from each exchange's provided state information to a small set
 * of generic states.
 */
export enum TradingState_ExchangeState {
  /**
   * EXCHANGE_STATE_PRE_OPEN - The state outside of continuous trading, can include uncrossing/auction periods where only certain order
   * actions are allowed. Note that CQG uses this state for pre-open, opening auction, and closing auction.
   */
  EXCHANGE_STATE_PRE_OPEN = 1,
  /** EXCHANGE_STATE_OPEN - The state indicating continuous trading. */
  EXCHANGE_STATE_OPEN = 2,
  /** EXCHANGE_STATE_CLOSED - The state when continuous trading and all closing auctions have ended. */
  EXCHANGE_STATE_CLOSED = 3,
  /** EXCHANGE_STATE_HALTED - The state when continuous trading is halted, usually unscheduled. */
  EXCHANGE_STATE_HALTED = 4,
  /** EXCHANGE_STATE_SUSPENDED - The state when trading is suspended, similar to halt but for an indeterminate period. */
  EXCHANGE_STATE_SUSPENDED = 5,
  UNRECOGNIZED = -1,
}

export function tradingState_ExchangeStateFromJSON(object: any): TradingState_ExchangeState {
  switch (object) {
    case 1:
    case "EXCHANGE_STATE_PRE_OPEN":
      return TradingState_ExchangeState.EXCHANGE_STATE_PRE_OPEN;
    case 2:
    case "EXCHANGE_STATE_OPEN":
      return TradingState_ExchangeState.EXCHANGE_STATE_OPEN;
    case 3:
    case "EXCHANGE_STATE_CLOSED":
      return TradingState_ExchangeState.EXCHANGE_STATE_CLOSED;
    case 4:
    case "EXCHANGE_STATE_HALTED":
      return TradingState_ExchangeState.EXCHANGE_STATE_HALTED;
    case 5:
    case "EXCHANGE_STATE_SUSPENDED":
      return TradingState_ExchangeState.EXCHANGE_STATE_SUSPENDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradingState_ExchangeState.UNRECOGNIZED;
  }
}

export function tradingState_ExchangeStateToJSON(object: TradingState_ExchangeState): string {
  switch (object) {
    case TradingState_ExchangeState.EXCHANGE_STATE_PRE_OPEN:
      return "EXCHANGE_STATE_PRE_OPEN";
    case TradingState_ExchangeState.EXCHANGE_STATE_OPEN:
      return "EXCHANGE_STATE_OPEN";
    case TradingState_ExchangeState.EXCHANGE_STATE_CLOSED:
      return "EXCHANGE_STATE_CLOSED";
    case TradingState_ExchangeState.EXCHANGE_STATE_HALTED:
      return "EXCHANGE_STATE_HALTED";
    case TradingState_ExchangeState.EXCHANGE_STATE_SUSPENDED:
      return "EXCHANGE_STATE_SUSPENDED";
    case TradingState_ExchangeState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Exchange specific market state element represented by tag/value pair.
 * Array of the elements describes entire market state.
 */
export interface ExchangeSpecificMarketState {
  /** The market state element tag. Not all exchanges provide it. */
  tag?:
    | string
    | undefined;
  /** The market state element value. */
  value?: string | undefined;
}

/** Parameters of OptionCalculationRequest */
export interface OptionCalculationParameters {
  /**
   * Identifier of the option maturity representing option strike list of the same underlying and maturity period
   * (see OptionMaturityMetadata.id).
   */
  optionMaturityId?:
    | string
    | undefined;
  /** Server side contract identifiers. Leave it empty to request all strikes for the given maturity. */
  strikeContractIds: number[];
  /** Optional OTC contributor ID for contributor specific data subscriptions. */
  contributorId?: string | undefined;
}

/** Request for Greeks and other option calculations */
export interface OptionCalculationRequest {
  /** ID of the request. Cannot be empty. */
  requestId?:
    | number
    | undefined;
  /** Request parameters. */
  optionCalculationParameters?:
    | OptionCalculationParameters
    | undefined;
  /**
   * This field is associated with RequestType enum type.
   * REQUEST_TYPE_SUBSCRIBE operation is processed by default.
   */
  requestType?: number | undefined;
}

/** Type of request action. */
export enum OptionCalculationRequest_RequestType {
  /** REQUEST_TYPE_SUBSCRIBE - Calculation request with subscription for updates. */
  REQUEST_TYPE_SUBSCRIBE = 2,
  /** REQUEST_TYPE_DROP - Drop subscription request and unsubscribe. */
  REQUEST_TYPE_DROP = 3,
  UNRECOGNIZED = -1,
}

export function optionCalculationRequest_RequestTypeFromJSON(object: any): OptionCalculationRequest_RequestType {
  switch (object) {
    case 2:
    case "REQUEST_TYPE_SUBSCRIBE":
      return OptionCalculationRequest_RequestType.REQUEST_TYPE_SUBSCRIBE;
    case 3:
    case "REQUEST_TYPE_DROP":
      return OptionCalculationRequest_RequestType.REQUEST_TYPE_DROP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OptionCalculationRequest_RequestType.UNRECOGNIZED;
  }
}

export function optionCalculationRequest_RequestTypeToJSON(object: OptionCalculationRequest_RequestType): string {
  switch (object) {
    case OptionCalculationRequest_RequestType.REQUEST_TYPE_SUBSCRIBE:
      return "REQUEST_TYPE_SUBSCRIBE";
    case OptionCalculationRequest_RequestType.REQUEST_TYPE_DROP:
      return "REQUEST_TYPE_DROP";
    case OptionCalculationRequest_RequestType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Calculated values */
export interface OptionCalculationValues {
  /** Server side contract id. */
  strikeContractId?:
    | number
    | undefined;
  /** The most recent price of the underlying instrument at the time of the option contract price used in calculations. */
  scaledCoherentUnderlyingPrice?:
    | number
    | undefined;
  /** Calculated Implied Volatility in percents. */
  impliedVolatility?:
    | number
    | undefined;
  /** Calculated Theoretical Price in correct price format. */
  theov?:
    | number
    | undefined;
  /**
   * Calculated Delta Greek value in percents.
   * Represents the ratio of the change in the correct theoretical price
   * over the change in the correct underlying price.
   */
  delta?:
    | number
    | undefined;
  /**
   * Calculated Gamma Greek value in percents.
   * Represents Delta Greek change which occurs if correct underlying price increases by 1.
   * Correct underlying price can be calculated by multiplying OptionCalculationReport.scaled_underlying_price
   * by correct_price_scale of the underlying contract.
   */
  gamma?:
    | number
    | undefined;
  /**
   * Calculated Vega Greek value.
   * Represents correct theoretical price change which occurs if Implied Volatility increases by 1%.
   */
  vega?:
    | number
    | undefined;
  /**
   * Calculated Theta Greek value.
   * Represents correct theoretical price change which occurs if number of days to expiration decreases by one day.
   */
  theta?:
    | number
    | undefined;
  /**
   * Calculated Rho Greek value.
   * Represents correct theoretical price change which occurs if Interest Rate increases by 1%.
   */
  rho?:
    | number
    | undefined;
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only strike_contract_id will be provided in this case.
   */
  deleted?: boolean | undefined;
}

/** Option calculation response */
export interface OptionCalculationReport {
  /** Request ID. */
  requestId?:
    | number
    | undefined;
  /**
   * Subscription result.
   * This field is associated with StatusCode enum type.
   */
  statusCode?:
    | number
    | undefined;
  /** Timestamp of the latest event that caused calculations included in this report. */
  upToUtcTime?:
    | number
    | undefined;
  /**
   * Interest Rate is the latest price of the most active future contract determined
   * by the currency of the option contract. E.g. interest rate for options traded
   * in USD is the latest price of the most active Eurodollar Futures.
   * Interest Rate within a range (0;1], where 1 means 100%, 0.1 means 10%.
   */
  interestRate?:
    | number
    | undefined;
  /** Latest quote price of the underlying instrument at the moment of calculations. */
  scaledUnderlyingPrice?:
    | number
    | undefined;
  /** Calculated values. */
  values: OptionCalculationValues[];
  /** Optional failure details. */
  details?:
    | Text
    | undefined;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete?: boolean | undefined;
}

export enum OptionCalculationReport_StatusCode {
  /**
   * STATUS_CODE_SUBSCRIBED - / success codes (1 - 99)
   * Request is processed and subscription is established.
   */
  STATUS_CODE_SUBSCRIBED = 1,
  /** STATUS_CODE_DROPPED - The subscription is dropped by the client. */
  STATUS_CODE_DROPPED = 2,
  /** STATUS_CODE_UPDATE - Unsolicited information update because of subscription. */
  STATUS_CODE_UPDATE = 3,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is disconnected. Possible reasons: communication issues, metadata update.
   * NOTE: Clients should not resubscribe in this case, the server will restore subscription with
   * sending STATUS_CODE_SUBSCRIBED status.
   */
  STATUS_CODE_DISCONNECTED = 4,
  /**
   * STATUS_CODE_FAILURE - / failure codes (100+)
   * general failure
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Subscription parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_ACCESS_DENIED - The user is not allowed to be subscribed to this Instrument. */
  STATUS_CODE_ACCESS_DENIED = 103,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The subscription count limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 104,
  /** STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION - The request rate limit has been violated. */
  STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION = 105,
  /** STATUS_CODE_DELETED - Market data source was deleted because of expiration or for another reason. */
  STATUS_CODE_DELETED = 106,
  /** STATUS_CODE_CONTRIBUTOR_REQUIRED - OTC Contributor ID is required for this subscription but was not provided or not authorized. */
  STATUS_CODE_CONTRIBUTOR_REQUIRED = 107,
  UNRECOGNIZED = -1,
}

export function optionCalculationReport_StatusCodeFromJSON(object: any): OptionCalculationReport_StatusCode {
  switch (object) {
    case 1:
    case "STATUS_CODE_SUBSCRIBED":
      return OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIBED;
    case 2:
    case "STATUS_CODE_DROPPED":
      return OptionCalculationReport_StatusCode.STATUS_CODE_DROPPED;
    case 3:
    case "STATUS_CODE_UPDATE":
      return OptionCalculationReport_StatusCode.STATUS_CODE_UPDATE;
    case 4:
    case "STATUS_CODE_DISCONNECTED":
      return OptionCalculationReport_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case "STATUS_CODE_FAILURE":
      return OptionCalculationReport_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case "STATUS_CODE_INVALID_PARAMS":
      return OptionCalculationReport_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 103:
    case "STATUS_CODE_ACCESS_DENIED":
      return OptionCalculationReport_StatusCode.STATUS_CODE_ACCESS_DENIED;
    case 104:
    case "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION":
      return OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 105:
    case "STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION":
      return OptionCalculationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION;
    case 106:
    case "STATUS_CODE_DELETED":
      return OptionCalculationReport_StatusCode.STATUS_CODE_DELETED;
    case 107:
    case "STATUS_CODE_CONTRIBUTOR_REQUIRED":
      return OptionCalculationReport_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OptionCalculationReport_StatusCode.UNRECOGNIZED;
  }
}

export function optionCalculationReport_StatusCodeToJSON(object: OptionCalculationReport_StatusCode): string {
  switch (object) {
    case OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIBED:
      return "STATUS_CODE_SUBSCRIBED";
    case OptionCalculationReport_StatusCode.STATUS_CODE_DROPPED:
      return "STATUS_CODE_DROPPED";
    case OptionCalculationReport_StatusCode.STATUS_CODE_UPDATE:
      return "STATUS_CODE_UPDATE";
    case OptionCalculationReport_StatusCode.STATUS_CODE_DISCONNECTED:
      return "STATUS_CODE_DISCONNECTED";
    case OptionCalculationReport_StatusCode.STATUS_CODE_FAILURE:
      return "STATUS_CODE_FAILURE";
    case OptionCalculationReport_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return "STATUS_CODE_INVALID_PARAMS";
    case OptionCalculationReport_StatusCode.STATUS_CODE_ACCESS_DENIED:
      return "STATUS_CODE_ACCESS_DENIED";
    case OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION";
    case OptionCalculationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION:
      return "STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION";
    case OptionCalculationReport_StatusCode.STATUS_CODE_DELETED:
      return "STATUS_CODE_DELETED";
    case OptionCalculationReport_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED:
      return "STATUS_CODE_CONTRIBUTOR_REQUIRED";
    case OptionCalculationReport_StatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseMarketDataSubscription(): MarketDataSubscription {
  return {
    contractId: 0,
    requestId: 0,
    level: 0,
    includePastMarketValues: false,
    includePastQuotes: false,
    includeSessionMarketValues: false,
    includeYields: false,
    includeSourcePrices: false,
    includeRequestsForQuotation: false,
    includeTradeAttributes: false,
    includeMarketState: false,
    includeOffMarketTrades: false,
    contributorId: "",
    includeCurrencyRate: false,
    domSubscriptionType: 0,
    includePremium: false,
  };
}

export const MarketDataSubscription = {
  encode(message: MarketDataSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.requestId !== undefined && message.requestId !== 0) {
      writer.uint32(112).uint32(message.requestId);
    }
    if (message.level !== 0) {
      writer.uint32(16).uint32(message.level);
    }
    if (message.includePastMarketValues !== undefined && message.includePastMarketValues !== false) {
      writer.uint32(24).bool(message.includePastMarketValues);
    }
    if (message.includePastQuotes !== undefined && message.includePastQuotes !== false) {
      writer.uint32(40).bool(message.includePastQuotes);
    }
    if (message.includeSessionMarketValues !== undefined && message.includeSessionMarketValues !== false) {
      writer.uint32(72).bool(message.includeSessionMarketValues);
    }
    if (message.includeYields !== undefined && message.includeYields !== false) {
      writer.uint32(64).bool(message.includeYields);
    }
    if (message.includeSourcePrices !== undefined && message.includeSourcePrices !== false) {
      writer.uint32(80).bool(message.includeSourcePrices);
    }
    if (message.includeRequestsForQuotation !== undefined && message.includeRequestsForQuotation !== false) {
      writer.uint32(88).bool(message.includeRequestsForQuotation);
    }
    if (message.includeTradeAttributes !== undefined && message.includeTradeAttributes !== false) {
      writer.uint32(96).bool(message.includeTradeAttributes);
    }
    if (message.includeMarketState !== undefined && message.includeMarketState !== false) {
      writer.uint32(104).bool(message.includeMarketState);
    }
    if (message.includeOffMarketTrades !== undefined && message.includeOffMarketTrades !== false) {
      writer.uint32(120).bool(message.includeOffMarketTrades);
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      writer.uint32(58).string(message.contributorId);
    }
    if (message.includeCurrencyRate !== undefined && message.includeCurrencyRate !== false) {
      writer.uint32(128).bool(message.includeCurrencyRate);
    }
    if (message.domSubscriptionType !== undefined && message.domSubscriptionType !== 0) {
      writer.uint32(136).uint32(message.domSubscriptionType);
    }
    if (message.includePremium !== undefined && message.includePremium !== false) {
      writer.uint32(144).bool(message.includePremium);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketDataSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.level = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includePastMarketValues = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includePastQuotes = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.includeSessionMarketValues = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.includeYields = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.includeSourcePrices = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.includeRequestsForQuotation = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.includeTradeAttributes = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.includeMarketState = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.includeOffMarketTrades = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.contributorId = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.includeCurrencyRate = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.domSubscriptionType = reader.uint32();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.includePremium = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketDataSubscription {
    return {
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      level: isSet(object.level) ? globalThis.Number(object.level) : 0,
      includePastMarketValues: isSet(object.includePastMarketValues)
        ? globalThis.Boolean(object.includePastMarketValues)
        : false,
      includePastQuotes: isSet(object.includePastQuotes) ? globalThis.Boolean(object.includePastQuotes) : false,
      includeSessionMarketValues: isSet(object.includeSessionMarketValues)
        ? globalThis.Boolean(object.includeSessionMarketValues)
        : false,
      includeYields: isSet(object.includeYields) ? globalThis.Boolean(object.includeYields) : false,
      includeSourcePrices: isSet(object.includeSourcePrices) ? globalThis.Boolean(object.includeSourcePrices) : false,
      includeRequestsForQuotation: isSet(object.includeRequestsForQuotation)
        ? globalThis.Boolean(object.includeRequestsForQuotation)
        : false,
      includeTradeAttributes: isSet(object.includeTradeAttributes)
        ? globalThis.Boolean(object.includeTradeAttributes)
        : false,
      includeMarketState: isSet(object.includeMarketState) ? globalThis.Boolean(object.includeMarketState) : false,
      includeOffMarketTrades: isSet(object.includeOffMarketTrades)
        ? globalThis.Boolean(object.includeOffMarketTrades)
        : false,
      contributorId: isSet(object.contributorId) ? globalThis.String(object.contributorId) : "",
      includeCurrencyRate: isSet(object.includeCurrencyRate) ? globalThis.Boolean(object.includeCurrencyRate) : false,
      domSubscriptionType: isSet(object.domSubscriptionType) ? globalThis.Number(object.domSubscriptionType) : 0,
      includePremium: isSet(object.includePremium) ? globalThis.Boolean(object.includePremium) : false,
    };
  },

  toJSON(message: MarketDataSubscription): unknown {
    const obj: any = {};
    if (message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.requestId !== undefined && message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.level !== 0) {
      obj.level = Math.round(message.level);
    }
    if (message.includePastMarketValues !== undefined && message.includePastMarketValues !== false) {
      obj.includePastMarketValues = message.includePastMarketValues;
    }
    if (message.includePastQuotes !== undefined && message.includePastQuotes !== false) {
      obj.includePastQuotes = message.includePastQuotes;
    }
    if (message.includeSessionMarketValues !== undefined && message.includeSessionMarketValues !== false) {
      obj.includeSessionMarketValues = message.includeSessionMarketValues;
    }
    if (message.includeYields !== undefined && message.includeYields !== false) {
      obj.includeYields = message.includeYields;
    }
    if (message.includeSourcePrices !== undefined && message.includeSourcePrices !== false) {
      obj.includeSourcePrices = message.includeSourcePrices;
    }
    if (message.includeRequestsForQuotation !== undefined && message.includeRequestsForQuotation !== false) {
      obj.includeRequestsForQuotation = message.includeRequestsForQuotation;
    }
    if (message.includeTradeAttributes !== undefined && message.includeTradeAttributes !== false) {
      obj.includeTradeAttributes = message.includeTradeAttributes;
    }
    if (message.includeMarketState !== undefined && message.includeMarketState !== false) {
      obj.includeMarketState = message.includeMarketState;
    }
    if (message.includeOffMarketTrades !== undefined && message.includeOffMarketTrades !== false) {
      obj.includeOffMarketTrades = message.includeOffMarketTrades;
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      obj.contributorId = message.contributorId;
    }
    if (message.includeCurrencyRate !== undefined && message.includeCurrencyRate !== false) {
      obj.includeCurrencyRate = message.includeCurrencyRate;
    }
    if (message.domSubscriptionType !== undefined && message.domSubscriptionType !== 0) {
      obj.domSubscriptionType = Math.round(message.domSubscriptionType);
    }
    if (message.includePremium !== undefined && message.includePremium !== false) {
      obj.includePremium = message.includePremium;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketDataSubscription>, I>>(base?: I): MarketDataSubscription {
    return MarketDataSubscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketDataSubscription>, I>>(object: I): MarketDataSubscription {
    const message = createBaseMarketDataSubscription();
    message.contractId = object.contractId ?? 0;
    message.requestId = object.requestId ?? 0;
    message.level = object.level ?? 0;
    message.includePastMarketValues = object.includePastMarketValues ?? false;
    message.includePastQuotes = object.includePastQuotes ?? false;
    message.includeSessionMarketValues = object.includeSessionMarketValues ?? false;
    message.includeYields = object.includeYields ?? false;
    message.includeSourcePrices = object.includeSourcePrices ?? false;
    message.includeRequestsForQuotation = object.includeRequestsForQuotation ?? false;
    message.includeTradeAttributes = object.includeTradeAttributes ?? false;
    message.includeMarketState = object.includeMarketState ?? false;
    message.includeOffMarketTrades = object.includeOffMarketTrades ?? false;
    message.contributorId = object.contributorId ?? "";
    message.includeCurrencyRate = object.includeCurrencyRate ?? false;
    message.domSubscriptionType = object.domSubscriptionType ?? 0;
    message.includePremium = object.includePremium ?? false;
    return message;
  },
};

function createBaseMarketDataSubscriptionStatus(): MarketDataSubscriptionStatus {
  return {
    contractId: 0,
    statusCode: 0,
    level: 0,
    textMessage: "",
    details: undefined,
    pastQuotesIncluded: false,
    sessionMarketValuesIncluded: false,
    yieldsIncluded: false,
    sourcePriceIncluded: false,
    requestsForQuotationIncluded: false,
    tradeAttributesIncluded: false,
    marketStateIncluded: false,
    offMarketTradesIncluded: false,
    currencyRateIncluded: false,
    requestId: 0,
    contributorId: "",
    actualDomSubscriptionType: 0,
    premiumIncluded: false,
  };
}

export const MarketDataSubscriptionStatus = {
  encode(message: MarketDataSubscriptionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.level !== 0) {
      writer.uint32(24).uint32(message.level);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(34).string(message.textMessage);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(146).fork()).ldelim();
    }
    if (message.pastQuotesIncluded !== undefined && message.pastQuotesIncluded !== false) {
      writer.uint32(48).bool(message.pastQuotesIncluded);
    }
    if (message.sessionMarketValuesIncluded !== undefined && message.sessionMarketValuesIncluded !== false) {
      writer.uint32(80).bool(message.sessionMarketValuesIncluded);
    }
    if (message.yieldsIncluded !== undefined && message.yieldsIncluded !== false) {
      writer.uint32(72).bool(message.yieldsIncluded);
    }
    if (message.sourcePriceIncluded !== undefined && message.sourcePriceIncluded !== false) {
      writer.uint32(88).bool(message.sourcePriceIncluded);
    }
    if (message.requestsForQuotationIncluded !== undefined && message.requestsForQuotationIncluded !== false) {
      writer.uint32(96).bool(message.requestsForQuotationIncluded);
    }
    if (message.tradeAttributesIncluded !== undefined && message.tradeAttributesIncluded !== false) {
      writer.uint32(104).bool(message.tradeAttributesIncluded);
    }
    if (message.marketStateIncluded !== undefined && message.marketStateIncluded !== false) {
      writer.uint32(112).bool(message.marketStateIncluded);
    }
    if (message.offMarketTradesIncluded !== undefined && message.offMarketTradesIncluded !== false) {
      writer.uint32(128).bool(message.offMarketTradesIncluded);
    }
    if (message.currencyRateIncluded !== undefined && message.currencyRateIncluded !== false) {
      writer.uint32(136).bool(message.currencyRateIncluded);
    }
    if (message.requestId !== undefined && message.requestId !== 0) {
      writer.uint32(120).uint32(message.requestId);
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      writer.uint32(66).string(message.contributorId);
    }
    if (message.actualDomSubscriptionType !== undefined && message.actualDomSubscriptionType !== 0) {
      writer.uint32(152).uint32(message.actualDomSubscriptionType);
    }
    if (message.premiumIncluded !== undefined && message.premiumIncluded !== false) {
      writer.uint32(160).bool(message.premiumIncluded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSubscriptionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketDataSubscriptionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.statusCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.level = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.details = Text.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pastQuotesIncluded = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.sessionMarketValuesIncluded = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.yieldsIncluded = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.sourcePriceIncluded = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.requestsForQuotationIncluded = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.tradeAttributesIncluded = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.marketStateIncluded = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.offMarketTradesIncluded = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.currencyRateIncluded = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contributorId = reader.string();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.actualDomSubscriptionType = reader.uint32();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.premiumIncluded = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketDataSubscriptionStatus {
    return {
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      statusCode: isSet(object.statusCode) ? globalThis.Number(object.statusCode) : 0,
      level: isSet(object.level) ? globalThis.Number(object.level) : 0,
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      pastQuotesIncluded: isSet(object.pastQuotesIncluded) ? globalThis.Boolean(object.pastQuotesIncluded) : false,
      sessionMarketValuesIncluded: isSet(object.sessionMarketValuesIncluded)
        ? globalThis.Boolean(object.sessionMarketValuesIncluded)
        : false,
      yieldsIncluded: isSet(object.yieldsIncluded) ? globalThis.Boolean(object.yieldsIncluded) : false,
      sourcePriceIncluded: isSet(object.sourcePriceIncluded) ? globalThis.Boolean(object.sourcePriceIncluded) : false,
      requestsForQuotationIncluded: isSet(object.requestsForQuotationIncluded)
        ? globalThis.Boolean(object.requestsForQuotationIncluded)
        : false,
      tradeAttributesIncluded: isSet(object.tradeAttributesIncluded)
        ? globalThis.Boolean(object.tradeAttributesIncluded)
        : false,
      marketStateIncluded: isSet(object.marketStateIncluded) ? globalThis.Boolean(object.marketStateIncluded) : false,
      offMarketTradesIncluded: isSet(object.offMarketTradesIncluded)
        ? globalThis.Boolean(object.offMarketTradesIncluded)
        : false,
      currencyRateIncluded: isSet(object.currencyRateIncluded)
        ? globalThis.Boolean(object.currencyRateIncluded)
        : false,
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      contributorId: isSet(object.contributorId) ? globalThis.String(object.contributorId) : "",
      actualDomSubscriptionType: isSet(object.actualDomSubscriptionType)
        ? globalThis.Number(object.actualDomSubscriptionType)
        : 0,
      premiumIncluded: isSet(object.premiumIncluded) ? globalThis.Boolean(object.premiumIncluded) : false,
    };
  },

  toJSON(message: MarketDataSubscriptionStatus): unknown {
    const obj: any = {};
    if (message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.statusCode !== 0) {
      obj.statusCode = Math.round(message.statusCode);
    }
    if (message.level !== 0) {
      obj.level = Math.round(message.level);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.details !== undefined) {
      obj.details = Text.toJSON(message.details);
    }
    if (message.pastQuotesIncluded !== undefined && message.pastQuotesIncluded !== false) {
      obj.pastQuotesIncluded = message.pastQuotesIncluded;
    }
    if (message.sessionMarketValuesIncluded !== undefined && message.sessionMarketValuesIncluded !== false) {
      obj.sessionMarketValuesIncluded = message.sessionMarketValuesIncluded;
    }
    if (message.yieldsIncluded !== undefined && message.yieldsIncluded !== false) {
      obj.yieldsIncluded = message.yieldsIncluded;
    }
    if (message.sourcePriceIncluded !== undefined && message.sourcePriceIncluded !== false) {
      obj.sourcePriceIncluded = message.sourcePriceIncluded;
    }
    if (message.requestsForQuotationIncluded !== undefined && message.requestsForQuotationIncluded !== false) {
      obj.requestsForQuotationIncluded = message.requestsForQuotationIncluded;
    }
    if (message.tradeAttributesIncluded !== undefined && message.tradeAttributesIncluded !== false) {
      obj.tradeAttributesIncluded = message.tradeAttributesIncluded;
    }
    if (message.marketStateIncluded !== undefined && message.marketStateIncluded !== false) {
      obj.marketStateIncluded = message.marketStateIncluded;
    }
    if (message.offMarketTradesIncluded !== undefined && message.offMarketTradesIncluded !== false) {
      obj.offMarketTradesIncluded = message.offMarketTradesIncluded;
    }
    if (message.currencyRateIncluded !== undefined && message.currencyRateIncluded !== false) {
      obj.currencyRateIncluded = message.currencyRateIncluded;
    }
    if (message.requestId !== undefined && message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      obj.contributorId = message.contributorId;
    }
    if (message.actualDomSubscriptionType !== undefined && message.actualDomSubscriptionType !== 0) {
      obj.actualDomSubscriptionType = Math.round(message.actualDomSubscriptionType);
    }
    if (message.premiumIncluded !== undefined && message.premiumIncluded !== false) {
      obj.premiumIncluded = message.premiumIncluded;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketDataSubscriptionStatus>, I>>(base?: I): MarketDataSubscriptionStatus {
    return MarketDataSubscriptionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketDataSubscriptionStatus>, I>>(object: I): MarketDataSubscriptionStatus {
    const message = createBaseMarketDataSubscriptionStatus();
    message.contractId = object.contractId ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.level = object.level ?? 0;
    message.textMessage = object.textMessage ?? "";
    message.details = (object.details !== undefined && object.details !== null)
      ? Text.fromPartial(object.details)
      : undefined;
    message.pastQuotesIncluded = object.pastQuotesIncluded ?? false;
    message.sessionMarketValuesIncluded = object.sessionMarketValuesIncluded ?? false;
    message.yieldsIncluded = object.yieldsIncluded ?? false;
    message.sourcePriceIncluded = object.sourcePriceIncluded ?? false;
    message.requestsForQuotationIncluded = object.requestsForQuotationIncluded ?? false;
    message.tradeAttributesIncluded = object.tradeAttributesIncluded ?? false;
    message.marketStateIncluded = object.marketStateIncluded ?? false;
    message.offMarketTradesIncluded = object.offMarketTradesIncluded ?? false;
    message.currencyRateIncluded = object.currencyRateIncluded ?? false;
    message.requestId = object.requestId ?? 0;
    message.contributorId = object.contributorId ?? "";
    message.actualDomSubscriptionType = object.actualDomSubscriptionType ?? 0;
    message.premiumIncluded = object.premiumIncluded ?? false;
    return message;
  },
};

function createBaseRealTimeMarketData(): RealTimeMarketData {
  return {
    contractId: 0,
    quotes: [],
    quotesTradeDate: 0,
    corrections: [],
    isSnapshot: false,
    collapsingLevel: 0,
    marketValues: [],
    detailedDom: undefined,
    requestsForQuotation: [],
    marketState: undefined,
    correctPriceScale: 0,
  };
}

export const RealTimeMarketData = {
  encode(message: RealTimeMarketData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    for (const v of message.quotes) {
      Quote.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.quotesTradeDate !== undefined && message.quotesTradeDate !== 0) {
      writer.uint32(144).sint64(message.quotesTradeDate);
    }
    for (const v of message.corrections) {
      Quote.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      writer.uint32(24).bool(message.isSnapshot);
    }
    if (message.collapsingLevel !== undefined && message.collapsingLevel !== 0) {
      writer.uint32(32).uint32(message.collapsingLevel);
    }
    for (const v of message.marketValues) {
      MarketValues.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.detailedDom !== undefined) {
      DetailedDOM.encode(message.detailedDom, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.requestsForQuotation) {
      RFQ.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.marketState !== undefined) {
      MarketState.encode(message.marketState, writer.uint32(138).fork()).ldelim();
    }
    if (message.correctPriceScale !== undefined && message.correctPriceScale !== 0) {
      writer.uint32(153).double(message.correctPriceScale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RealTimeMarketData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRealTimeMarketData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quotes.push(Quote.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.quotesTradeDate = longToNumber(reader.sint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.corrections.push(Quote.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isSnapshot = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.collapsingLevel = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.marketValues.push(MarketValues.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.detailedDom = DetailedDOM.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.requestsForQuotation.push(RFQ.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.marketState = MarketState.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 153) {
            break;
          }

          message.correctPriceScale = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RealTimeMarketData {
    return {
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      quotes: globalThis.Array.isArray(object?.quotes) ? object.quotes.map((e: any) => Quote.fromJSON(e)) : [],
      quotesTradeDate: isSet(object.quotesTradeDate) ? globalThis.Number(object.quotesTradeDate) : 0,
      corrections: globalThis.Array.isArray(object?.corrections)
        ? object.corrections.map((e: any) => Quote.fromJSON(e))
        : [],
      isSnapshot: isSet(object.isSnapshot) ? globalThis.Boolean(object.isSnapshot) : false,
      collapsingLevel: isSet(object.collapsingLevel) ? globalThis.Number(object.collapsingLevel) : 0,
      marketValues: globalThis.Array.isArray(object?.marketValues)
        ? object.marketValues.map((e: any) => MarketValues.fromJSON(e))
        : [],
      detailedDom: isSet(object.detailedDom) ? DetailedDOM.fromJSON(object.detailedDom) : undefined,
      requestsForQuotation: globalThis.Array.isArray(object?.requestsForQuotation)
        ? object.requestsForQuotation.map((e: any) => RFQ.fromJSON(e))
        : [],
      marketState: isSet(object.marketState) ? MarketState.fromJSON(object.marketState) : undefined,
      correctPriceScale: isSet(object.correctPriceScale) ? globalThis.Number(object.correctPriceScale) : 0,
    };
  },

  toJSON(message: RealTimeMarketData): unknown {
    const obj: any = {};
    if (message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.quotes?.length) {
      obj.quotes = message.quotes.map((e) => Quote.toJSON(e));
    }
    if (message.quotesTradeDate !== undefined && message.quotesTradeDate !== 0) {
      obj.quotesTradeDate = Math.round(message.quotesTradeDate);
    }
    if (message.corrections?.length) {
      obj.corrections = message.corrections.map((e) => Quote.toJSON(e));
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      obj.isSnapshot = message.isSnapshot;
    }
    if (message.collapsingLevel !== undefined && message.collapsingLevel !== 0) {
      obj.collapsingLevel = Math.round(message.collapsingLevel);
    }
    if (message.marketValues?.length) {
      obj.marketValues = message.marketValues.map((e) => MarketValues.toJSON(e));
    }
    if (message.detailedDom !== undefined) {
      obj.detailedDom = DetailedDOM.toJSON(message.detailedDom);
    }
    if (message.requestsForQuotation?.length) {
      obj.requestsForQuotation = message.requestsForQuotation.map((e) => RFQ.toJSON(e));
    }
    if (message.marketState !== undefined) {
      obj.marketState = MarketState.toJSON(message.marketState);
    }
    if (message.correctPriceScale !== undefined && message.correctPriceScale !== 0) {
      obj.correctPriceScale = message.correctPriceScale;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RealTimeMarketData>, I>>(base?: I): RealTimeMarketData {
    return RealTimeMarketData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RealTimeMarketData>, I>>(object: I): RealTimeMarketData {
    const message = createBaseRealTimeMarketData();
    message.contractId = object.contractId ?? 0;
    message.quotes = object.quotes?.map((e) => Quote.fromPartial(e)) || [];
    message.quotesTradeDate = object.quotesTradeDate ?? 0;
    message.corrections = object.corrections?.map((e) => Quote.fromPartial(e)) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    message.collapsingLevel = object.collapsingLevel ?? 0;
    message.marketValues = object.marketValues?.map((e) => MarketValues.fromPartial(e)) || [];
    message.detailedDom = (object.detailedDom !== undefined && object.detailedDom !== null)
      ? DetailedDOM.fromPartial(object.detailedDom)
      : undefined;
    message.requestsForQuotation = object.requestsForQuotation?.map((e) => RFQ.fromPartial(e)) || [];
    message.marketState = (object.marketState !== undefined && object.marketState !== null)
      ? MarketState.fromPartial(object.marketState)
      : undefined;
    message.correctPriceScale = object.correctPriceScale ?? 0;
    return message;
  },
};

function createBaseQuote(): Quote {
  return {
    type: 0,
    quoteUtcTime: 0,
    scaledPrice: 0,
    scaledSourcePrice: 0,
    priceYield: 0,
    scaledVolume: 0,
    volume: undefined,
    indicators: [],
    salesCondition: 0,
    tradeAttributes: undefined,
    scaledCurrencyRatePrice: 0,
    scaledPremium: 0,
  };
}

export const Quote = {
  encode(message: Quote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.quoteUtcTime !== undefined && message.quoteUtcTime !== 0) {
      writer.uint32(16).sint64(message.quoteUtcTime);
    }
    if (message.scaledPrice !== 0) {
      writer.uint32(24).sint32(message.scaledPrice);
    }
    if (message.scaledSourcePrice !== undefined && message.scaledSourcePrice !== 0) {
      writer.uint32(64).sint32(message.scaledSourcePrice);
    }
    if (message.priceYield !== undefined && message.priceYield !== 0) {
      writer.uint32(49).double(message.priceYield);
    }
    if (message.scaledVolume !== undefined && message.scaledVolume !== 0) {
      writer.uint32(32).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(90).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.indicators) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.salesCondition !== undefined && message.salesCondition !== 0) {
      writer.uint32(56).uint32(message.salesCondition);
    }
    if (message.tradeAttributes !== undefined) {
      TradeAttributes.encode(message.tradeAttributes, writer.uint32(74).fork()).ldelim();
    }
    if (message.scaledCurrencyRatePrice !== undefined && message.scaledCurrencyRatePrice !== 0) {
      writer.uint32(80).sint32(message.scaledCurrencyRatePrice);
    }
    if (message.scaledPremium !== undefined && message.scaledPremium !== 0) {
      writer.uint32(96).sint32(message.scaledPremium);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.quoteUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.scaledPrice = reader.sint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.scaledSourcePrice = reader.sint32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.priceYield = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.scaledVolume = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.volume = Decimal.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag === 40) {
            message.indicators.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.indicators.push(reader.uint32());
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.salesCondition = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tradeAttributes = TradeAttributes.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.scaledCurrencyRatePrice = reader.sint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.scaledPremium = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Quote {
    return {
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      quoteUtcTime: isSet(object.quoteUtcTime) ? globalThis.Number(object.quoteUtcTime) : 0,
      scaledPrice: isSet(object.scaledPrice) ? globalThis.Number(object.scaledPrice) : 0,
      scaledSourcePrice: isSet(object.scaledSourcePrice) ? globalThis.Number(object.scaledSourcePrice) : 0,
      priceYield: isSet(object.priceYield) ? globalThis.Number(object.priceYield) : 0,
      scaledVolume: isSet(object.scaledVolume) ? globalThis.Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      indicators: globalThis.Array.isArray(object?.indicators)
        ? object.indicators.map((e: any) => globalThis.Number(e))
        : [],
      salesCondition: isSet(object.salesCondition) ? globalThis.Number(object.salesCondition) : 0,
      tradeAttributes: isSet(object.tradeAttributes) ? TradeAttributes.fromJSON(object.tradeAttributes) : undefined,
      scaledCurrencyRatePrice: isSet(object.scaledCurrencyRatePrice)
        ? globalThis.Number(object.scaledCurrencyRatePrice)
        : 0,
      scaledPremium: isSet(object.scaledPremium) ? globalThis.Number(object.scaledPremium) : 0,
    };
  },

  toJSON(message: Quote): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.quoteUtcTime !== undefined && message.quoteUtcTime !== 0) {
      obj.quoteUtcTime = Math.round(message.quoteUtcTime);
    }
    if (message.scaledPrice !== 0) {
      obj.scaledPrice = Math.round(message.scaledPrice);
    }
    if (message.scaledSourcePrice !== undefined && message.scaledSourcePrice !== 0) {
      obj.scaledSourcePrice = Math.round(message.scaledSourcePrice);
    }
    if (message.priceYield !== undefined && message.priceYield !== 0) {
      obj.priceYield = message.priceYield;
    }
    if (message.scaledVolume !== undefined && message.scaledVolume !== 0) {
      obj.scaledVolume = Math.round(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      obj.volume = Decimal.toJSON(message.volume);
    }
    if (message.indicators?.length) {
      obj.indicators = message.indicators.map((e) => Math.round(e));
    }
    if (message.salesCondition !== undefined && message.salesCondition !== 0) {
      obj.salesCondition = Math.round(message.salesCondition);
    }
    if (message.tradeAttributes !== undefined) {
      obj.tradeAttributes = TradeAttributes.toJSON(message.tradeAttributes);
    }
    if (message.scaledCurrencyRatePrice !== undefined && message.scaledCurrencyRatePrice !== 0) {
      obj.scaledCurrencyRatePrice = Math.round(message.scaledCurrencyRatePrice);
    }
    if (message.scaledPremium !== undefined && message.scaledPremium !== 0) {
      obj.scaledPremium = Math.round(message.scaledPremium);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Quote>, I>>(base?: I): Quote {
    return Quote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Quote>, I>>(object: I): Quote {
    const message = createBaseQuote();
    message.type = object.type ?? 0;
    message.quoteUtcTime = object.quoteUtcTime ?? 0;
    message.scaledPrice = object.scaledPrice ?? 0;
    message.scaledSourcePrice = object.scaledSourcePrice ?? 0;
    message.priceYield = object.priceYield ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = (object.volume !== undefined && object.volume !== null)
      ? Decimal.fromPartial(object.volume)
      : undefined;
    message.indicators = object.indicators?.map((e) => e) || [];
    message.salesCondition = object.salesCondition ?? 0;
    message.tradeAttributes = (object.tradeAttributes !== undefined && object.tradeAttributes !== null)
      ? TradeAttributes.fromPartial(object.tradeAttributes)
      : undefined;
    message.scaledCurrencyRatePrice = object.scaledCurrencyRatePrice ?? 0;
    message.scaledPremium = object.scaledPremium ?? 0;
    return message;
  },
};

function createBaseTradeAttributes(): TradeAttributes {
  return { buyer: 0, seller: 0, tradeType: "", matchId: "", agreementTimeUtc: undefined };
}

export const TradeAttributes = {
  encode(message: TradeAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.buyer !== undefined && message.buyer !== 0) {
      writer.uint32(8).sint32(message.buyer);
    }
    if (message.seller !== undefined && message.seller !== 0) {
      writer.uint32(16).sint32(message.seller);
    }
    if (message.tradeType !== undefined && message.tradeType !== "") {
      writer.uint32(26).string(message.tradeType);
    }
    if (message.matchId !== undefined && message.matchId !== "") {
      writer.uint32(34).string(message.matchId);
    }
    if (message.agreementTimeUtc !== undefined) {
      Timestamp.encode(toTimestamp(message.agreementTimeUtc), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradeAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradeAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.buyer = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.seller = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.matchId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.agreementTimeUtc = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradeAttributes {
    return {
      buyer: isSet(object.buyer) ? globalThis.Number(object.buyer) : 0,
      seller: isSet(object.seller) ? globalThis.Number(object.seller) : 0,
      tradeType: isSet(object.tradeType) ? globalThis.String(object.tradeType) : "",
      matchId: isSet(object.matchId) ? globalThis.String(object.matchId) : "",
      agreementTimeUtc: isSet(object.agreementTimeUtc) ? fromJsonTimestamp(object.agreementTimeUtc) : undefined,
    };
  },

  toJSON(message: TradeAttributes): unknown {
    const obj: any = {};
    if (message.buyer !== undefined && message.buyer !== 0) {
      obj.buyer = Math.round(message.buyer);
    }
    if (message.seller !== undefined && message.seller !== 0) {
      obj.seller = Math.round(message.seller);
    }
    if (message.tradeType !== undefined && message.tradeType !== "") {
      obj.tradeType = message.tradeType;
    }
    if (message.matchId !== undefined && message.matchId !== "") {
      obj.matchId = message.matchId;
    }
    if (message.agreementTimeUtc !== undefined) {
      obj.agreementTimeUtc = message.agreementTimeUtc.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeAttributes>, I>>(base?: I): TradeAttributes {
    return TradeAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradeAttributes>, I>>(object: I): TradeAttributes {
    const message = createBaseTradeAttributes();
    message.buyer = object.buyer ?? 0;
    message.seller = object.seller ?? 0;
    message.tradeType = object.tradeType ?? "";
    message.matchId = object.matchId ?? "";
    message.agreementTimeUtc = object.agreementTimeUtc ?? undefined;
    return message;
  },
};

function createBaseMarketValues(): MarketValues {
  return {
    scaledOpenPrice: 0,
    scaledHighPrice: 0,
    scaledLowPrice: 0,
    scaledLastPrice: 0,
    scaledClosePrice: 0,
    scaledLastPriceNoSettlement: 0,
    scaledYesterdaySettlement: 0,
    scaledTotalVolume: 0,
    totalVolume: undefined,
    scaledYesterdayLast: 0,
    scaledIndicativeOpen: 0,
    scaledIndicativeOpenVolume: 0,
    indicativeOpenVolume: undefined,
    dayIndex: 0,
    scaledOpenInterest: 0,
    openInterest: undefined,
    tickVolume: 0,
    scaledSettlement: 0,
    scaledMarkerPrice: 0,
    scaledLastTradePrice: 0,
    lastTradeVolume: undefined,
    clearedFields: [],
    tradeDate: 0,
    sessionIndex: 0,
    marketYields: undefined,
    scaledCurrencyRatePrice: 0,
  };
}

export const MarketValues = {
  encode(message: MarketValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scaledOpenPrice !== undefined && message.scaledOpenPrice !== 0) {
      writer.uint32(8).sint32(message.scaledOpenPrice);
    }
    if (message.scaledHighPrice !== undefined && message.scaledHighPrice !== 0) {
      writer.uint32(16).sint32(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== undefined && message.scaledLowPrice !== 0) {
      writer.uint32(24).sint32(message.scaledLowPrice);
    }
    if (message.scaledLastPrice !== undefined && message.scaledLastPrice !== 0) {
      writer.uint32(32).sint32(message.scaledLastPrice);
    }
    if (message.scaledClosePrice !== undefined && message.scaledClosePrice !== 0) {
      writer.uint32(200).sint32(message.scaledClosePrice);
    }
    if (message.scaledLastPriceNoSettlement !== undefined && message.scaledLastPriceNoSettlement !== 0) {
      writer.uint32(208).sint32(message.scaledLastPriceNoSettlement);
    }
    if (message.scaledYesterdaySettlement !== undefined && message.scaledYesterdaySettlement !== 0) {
      writer.uint32(40).sint32(message.scaledYesterdaySettlement);
    }
    if (message.scaledTotalVolume !== undefined && message.scaledTotalVolume !== 0) {
      writer.uint32(48).uint64(message.scaledTotalVolume);
    }
    if (message.totalVolume !== undefined) {
      Decimal.encode(message.totalVolume, writer.uint32(170).fork()).ldelim();
    }
    if (message.scaledYesterdayLast !== undefined && message.scaledYesterdayLast !== 0) {
      writer.uint32(56).sint32(message.scaledYesterdayLast);
    }
    if (message.scaledIndicativeOpen !== undefined && message.scaledIndicativeOpen !== 0) {
      writer.uint32(64).sint32(message.scaledIndicativeOpen);
    }
    if (message.scaledIndicativeOpenVolume !== undefined && message.scaledIndicativeOpenVolume !== 0) {
      writer.uint32(120).uint64(message.scaledIndicativeOpenVolume);
    }
    if (message.indicativeOpenVolume !== undefined) {
      Decimal.encode(message.indicativeOpenVolume, writer.uint32(178).fork()).ldelim();
    }
    if (message.dayIndex !== 0) {
      writer.uint32(72).sint32(message.dayIndex);
    }
    if (message.scaledOpenInterest !== undefined && message.scaledOpenInterest !== 0) {
      writer.uint32(80).sint64(message.scaledOpenInterest);
    }
    if (message.openInterest !== undefined) {
      Decimal.encode(message.openInterest, writer.uint32(186).fork()).ldelim();
    }
    if (message.tickVolume !== undefined && message.tickVolume !== 0) {
      writer.uint32(88).uint32(message.tickVolume);
    }
    if (message.scaledSettlement !== undefined && message.scaledSettlement !== 0) {
      writer.uint32(96).sint32(message.scaledSettlement);
    }
    if (message.scaledMarkerPrice !== undefined && message.scaledMarkerPrice !== 0) {
      writer.uint32(144).sint32(message.scaledMarkerPrice);
    }
    if (message.scaledLastTradePrice !== undefined && message.scaledLastTradePrice !== 0) {
      writer.uint32(152).sint32(message.scaledLastTradePrice);
    }
    if (message.lastTradeVolume !== undefined) {
      Decimal.encode(message.lastTradeVolume, writer.uint32(194).fork()).ldelim();
    }
    writer.uint32(106).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.tradeDate !== 0) {
      writer.uint32(112).sint64(message.tradeDate);
    }
    if (message.sessionIndex !== undefined && message.sessionIndex !== 0) {
      writer.uint32(136).uint32(message.sessionIndex);
    }
    if (message.marketYields !== undefined) {
      MarketYields.encode(message.marketYields, writer.uint32(130).fork()).ldelim();
    }
    if (message.scaledCurrencyRatePrice !== undefined && message.scaledCurrencyRatePrice !== 0) {
      writer.uint32(160).sint32(message.scaledCurrencyRatePrice);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scaledOpenPrice = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.scaledHighPrice = reader.sint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.scaledLowPrice = reader.sint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.scaledLastPrice = reader.sint32();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.scaledClosePrice = reader.sint32();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.scaledLastPriceNoSettlement = reader.sint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.scaledYesterdaySettlement = reader.sint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.scaledTotalVolume = longToNumber(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.totalVolume = Decimal.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.scaledYesterdayLast = reader.sint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.scaledIndicativeOpen = reader.sint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.scaledIndicativeOpenVolume = longToNumber(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.indicativeOpenVolume = Decimal.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.dayIndex = reader.sint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.scaledOpenInterest = longToNumber(reader.sint64() as Long);
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.openInterest = Decimal.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.tickVolume = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.scaledSettlement = reader.sint32();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.scaledMarkerPrice = reader.sint32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.scaledLastTradePrice = reader.sint32();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.lastTradeVolume = Decimal.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag === 104) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 106) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.tradeDate = longToNumber(reader.sint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.sessionIndex = reader.uint32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.marketYields = MarketYields.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.scaledCurrencyRatePrice = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketValues {
    return {
      scaledOpenPrice: isSet(object.scaledOpenPrice) ? globalThis.Number(object.scaledOpenPrice) : 0,
      scaledHighPrice: isSet(object.scaledHighPrice) ? globalThis.Number(object.scaledHighPrice) : 0,
      scaledLowPrice: isSet(object.scaledLowPrice) ? globalThis.Number(object.scaledLowPrice) : 0,
      scaledLastPrice: isSet(object.scaledLastPrice) ? globalThis.Number(object.scaledLastPrice) : 0,
      scaledClosePrice: isSet(object.scaledClosePrice) ? globalThis.Number(object.scaledClosePrice) : 0,
      scaledLastPriceNoSettlement: isSet(object.scaledLastPriceNoSettlement)
        ? globalThis.Number(object.scaledLastPriceNoSettlement)
        : 0,
      scaledYesterdaySettlement: isSet(object.scaledYesterdaySettlement)
        ? globalThis.Number(object.scaledYesterdaySettlement)
        : 0,
      scaledTotalVolume: isSet(object.scaledTotalVolume) ? globalThis.Number(object.scaledTotalVolume) : 0,
      totalVolume: isSet(object.totalVolume) ? Decimal.fromJSON(object.totalVolume) : undefined,
      scaledYesterdayLast: isSet(object.scaledYesterdayLast) ? globalThis.Number(object.scaledYesterdayLast) : 0,
      scaledIndicativeOpen: isSet(object.scaledIndicativeOpen) ? globalThis.Number(object.scaledIndicativeOpen) : 0,
      scaledIndicativeOpenVolume: isSet(object.scaledIndicativeOpenVolume)
        ? globalThis.Number(object.scaledIndicativeOpenVolume)
        : 0,
      indicativeOpenVolume: isSet(object.indicativeOpenVolume)
        ? Decimal.fromJSON(object.indicativeOpenVolume)
        : undefined,
      dayIndex: isSet(object.dayIndex) ? globalThis.Number(object.dayIndex) : 0,
      scaledOpenInterest: isSet(object.scaledOpenInterest) ? globalThis.Number(object.scaledOpenInterest) : 0,
      openInterest: isSet(object.openInterest) ? Decimal.fromJSON(object.openInterest) : undefined,
      tickVolume: isSet(object.tickVolume) ? globalThis.Number(object.tickVolume) : 0,
      scaledSettlement: isSet(object.scaledSettlement) ? globalThis.Number(object.scaledSettlement) : 0,
      scaledMarkerPrice: isSet(object.scaledMarkerPrice) ? globalThis.Number(object.scaledMarkerPrice) : 0,
      scaledLastTradePrice: isSet(object.scaledLastTradePrice) ? globalThis.Number(object.scaledLastTradePrice) : 0,
      lastTradeVolume: isSet(object.lastTradeVolume) ? Decimal.fromJSON(object.lastTradeVolume) : undefined,
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      tradeDate: isSet(object.tradeDate) ? globalThis.Number(object.tradeDate) : 0,
      sessionIndex: isSet(object.sessionIndex) ? globalThis.Number(object.sessionIndex) : 0,
      marketYields: isSet(object.marketYields) ? MarketYields.fromJSON(object.marketYields) : undefined,
      scaledCurrencyRatePrice: isSet(object.scaledCurrencyRatePrice)
        ? globalThis.Number(object.scaledCurrencyRatePrice)
        : 0,
    };
  },

  toJSON(message: MarketValues): unknown {
    const obj: any = {};
    if (message.scaledOpenPrice !== undefined && message.scaledOpenPrice !== 0) {
      obj.scaledOpenPrice = Math.round(message.scaledOpenPrice);
    }
    if (message.scaledHighPrice !== undefined && message.scaledHighPrice !== 0) {
      obj.scaledHighPrice = Math.round(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== undefined && message.scaledLowPrice !== 0) {
      obj.scaledLowPrice = Math.round(message.scaledLowPrice);
    }
    if (message.scaledLastPrice !== undefined && message.scaledLastPrice !== 0) {
      obj.scaledLastPrice = Math.round(message.scaledLastPrice);
    }
    if (message.scaledClosePrice !== undefined && message.scaledClosePrice !== 0) {
      obj.scaledClosePrice = Math.round(message.scaledClosePrice);
    }
    if (message.scaledLastPriceNoSettlement !== undefined && message.scaledLastPriceNoSettlement !== 0) {
      obj.scaledLastPriceNoSettlement = Math.round(message.scaledLastPriceNoSettlement);
    }
    if (message.scaledYesterdaySettlement !== undefined && message.scaledYesterdaySettlement !== 0) {
      obj.scaledYesterdaySettlement = Math.round(message.scaledYesterdaySettlement);
    }
    if (message.scaledTotalVolume !== undefined && message.scaledTotalVolume !== 0) {
      obj.scaledTotalVolume = Math.round(message.scaledTotalVolume);
    }
    if (message.totalVolume !== undefined) {
      obj.totalVolume = Decimal.toJSON(message.totalVolume);
    }
    if (message.scaledYesterdayLast !== undefined && message.scaledYesterdayLast !== 0) {
      obj.scaledYesterdayLast = Math.round(message.scaledYesterdayLast);
    }
    if (message.scaledIndicativeOpen !== undefined && message.scaledIndicativeOpen !== 0) {
      obj.scaledIndicativeOpen = Math.round(message.scaledIndicativeOpen);
    }
    if (message.scaledIndicativeOpenVolume !== undefined && message.scaledIndicativeOpenVolume !== 0) {
      obj.scaledIndicativeOpenVolume = Math.round(message.scaledIndicativeOpenVolume);
    }
    if (message.indicativeOpenVolume !== undefined) {
      obj.indicativeOpenVolume = Decimal.toJSON(message.indicativeOpenVolume);
    }
    if (message.dayIndex !== 0) {
      obj.dayIndex = Math.round(message.dayIndex);
    }
    if (message.scaledOpenInterest !== undefined && message.scaledOpenInterest !== 0) {
      obj.scaledOpenInterest = Math.round(message.scaledOpenInterest);
    }
    if (message.openInterest !== undefined) {
      obj.openInterest = Decimal.toJSON(message.openInterest);
    }
    if (message.tickVolume !== undefined && message.tickVolume !== 0) {
      obj.tickVolume = Math.round(message.tickVolume);
    }
    if (message.scaledSettlement !== undefined && message.scaledSettlement !== 0) {
      obj.scaledSettlement = Math.round(message.scaledSettlement);
    }
    if (message.scaledMarkerPrice !== undefined && message.scaledMarkerPrice !== 0) {
      obj.scaledMarkerPrice = Math.round(message.scaledMarkerPrice);
    }
    if (message.scaledLastTradePrice !== undefined && message.scaledLastTradePrice !== 0) {
      obj.scaledLastTradePrice = Math.round(message.scaledLastTradePrice);
    }
    if (message.lastTradeVolume !== undefined) {
      obj.lastTradeVolume = Decimal.toJSON(message.lastTradeVolume);
    }
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.tradeDate !== 0) {
      obj.tradeDate = Math.round(message.tradeDate);
    }
    if (message.sessionIndex !== undefined && message.sessionIndex !== 0) {
      obj.sessionIndex = Math.round(message.sessionIndex);
    }
    if (message.marketYields !== undefined) {
      obj.marketYields = MarketYields.toJSON(message.marketYields);
    }
    if (message.scaledCurrencyRatePrice !== undefined && message.scaledCurrencyRatePrice !== 0) {
      obj.scaledCurrencyRatePrice = Math.round(message.scaledCurrencyRatePrice);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketValues>, I>>(base?: I): MarketValues {
    return MarketValues.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketValues>, I>>(object: I): MarketValues {
    const message = createBaseMarketValues();
    message.scaledOpenPrice = object.scaledOpenPrice ?? 0;
    message.scaledHighPrice = object.scaledHighPrice ?? 0;
    message.scaledLowPrice = object.scaledLowPrice ?? 0;
    message.scaledLastPrice = object.scaledLastPrice ?? 0;
    message.scaledClosePrice = object.scaledClosePrice ?? 0;
    message.scaledLastPriceNoSettlement = object.scaledLastPriceNoSettlement ?? 0;
    message.scaledYesterdaySettlement = object.scaledYesterdaySettlement ?? 0;
    message.scaledTotalVolume = object.scaledTotalVolume ?? 0;
    message.totalVolume = (object.totalVolume !== undefined && object.totalVolume !== null)
      ? Decimal.fromPartial(object.totalVolume)
      : undefined;
    message.scaledYesterdayLast = object.scaledYesterdayLast ?? 0;
    message.scaledIndicativeOpen = object.scaledIndicativeOpen ?? 0;
    message.scaledIndicativeOpenVolume = object.scaledIndicativeOpenVolume ?? 0;
    message.indicativeOpenVolume = (object.indicativeOpenVolume !== undefined && object.indicativeOpenVolume !== null)
      ? Decimal.fromPartial(object.indicativeOpenVolume)
      : undefined;
    message.dayIndex = object.dayIndex ?? 0;
    message.scaledOpenInterest = object.scaledOpenInterest ?? 0;
    message.openInterest = (object.openInterest !== undefined && object.openInterest !== null)
      ? Decimal.fromPartial(object.openInterest)
      : undefined;
    message.tickVolume = object.tickVolume ?? 0;
    message.scaledSettlement = object.scaledSettlement ?? 0;
    message.scaledMarkerPrice = object.scaledMarkerPrice ?? 0;
    message.scaledLastTradePrice = object.scaledLastTradePrice ?? 0;
    message.lastTradeVolume = (object.lastTradeVolume !== undefined && object.lastTradeVolume !== null)
      ? Decimal.fromPartial(object.lastTradeVolume)
      : undefined;
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.tradeDate = object.tradeDate ?? 0;
    message.sessionIndex = object.sessionIndex ?? 0;
    message.marketYields = (object.marketYields !== undefined && object.marketYields !== null)
      ? MarketYields.fromPartial(object.marketYields)
      : undefined;
    message.scaledCurrencyRatePrice = object.scaledCurrencyRatePrice ?? 0;
    return message;
  },
};

function createBaseMarketYields(): MarketYields {
  return {
    yieldOfOpenPrice: 0,
    yieldOfHighPrice: 0,
    yieldOfLowPrice: 0,
    yieldOfLastPrice: 0,
    yieldOfClosePrice: 0,
    yieldOfLastPriceNoSettlement: 0,
    yieldOfYesterdaySettlement: 0,
    yieldOfYesterdayLast: 0,
    yieldOfIndicativeOpen: 0,
    yieldOfSettlement: 0,
  };
}

export const MarketYields = {
  encode(message: MarketYields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.yieldOfOpenPrice !== undefined && message.yieldOfOpenPrice !== 0) {
      writer.uint32(9).double(message.yieldOfOpenPrice);
    }
    if (message.yieldOfHighPrice !== undefined && message.yieldOfHighPrice !== 0) {
      writer.uint32(17).double(message.yieldOfHighPrice);
    }
    if (message.yieldOfLowPrice !== undefined && message.yieldOfLowPrice !== 0) {
      writer.uint32(25).double(message.yieldOfLowPrice);
    }
    if (message.yieldOfLastPrice !== undefined && message.yieldOfLastPrice !== 0) {
      writer.uint32(33).double(message.yieldOfLastPrice);
    }
    if (message.yieldOfClosePrice !== undefined && message.yieldOfClosePrice !== 0) {
      writer.uint32(73).double(message.yieldOfClosePrice);
    }
    if (message.yieldOfLastPriceNoSettlement !== undefined && message.yieldOfLastPriceNoSettlement !== 0) {
      writer.uint32(81).double(message.yieldOfLastPriceNoSettlement);
    }
    if (message.yieldOfYesterdaySettlement !== undefined && message.yieldOfYesterdaySettlement !== 0) {
      writer.uint32(41).double(message.yieldOfYesterdaySettlement);
    }
    if (message.yieldOfYesterdayLast !== undefined && message.yieldOfYesterdayLast !== 0) {
      writer.uint32(49).double(message.yieldOfYesterdayLast);
    }
    if (message.yieldOfIndicativeOpen !== undefined && message.yieldOfIndicativeOpen !== 0) {
      writer.uint32(57).double(message.yieldOfIndicativeOpen);
    }
    if (message.yieldOfSettlement !== undefined && message.yieldOfSettlement !== 0) {
      writer.uint32(65).double(message.yieldOfSettlement);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketYields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketYields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.yieldOfOpenPrice = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.yieldOfHighPrice = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.yieldOfLowPrice = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.yieldOfLastPrice = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.yieldOfClosePrice = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.yieldOfLastPriceNoSettlement = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.yieldOfYesterdaySettlement = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.yieldOfYesterdayLast = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.yieldOfIndicativeOpen = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.yieldOfSettlement = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketYields {
    return {
      yieldOfOpenPrice: isSet(object.yieldOfOpenPrice) ? globalThis.Number(object.yieldOfOpenPrice) : 0,
      yieldOfHighPrice: isSet(object.yieldOfHighPrice) ? globalThis.Number(object.yieldOfHighPrice) : 0,
      yieldOfLowPrice: isSet(object.yieldOfLowPrice) ? globalThis.Number(object.yieldOfLowPrice) : 0,
      yieldOfLastPrice: isSet(object.yieldOfLastPrice) ? globalThis.Number(object.yieldOfLastPrice) : 0,
      yieldOfClosePrice: isSet(object.yieldOfClosePrice) ? globalThis.Number(object.yieldOfClosePrice) : 0,
      yieldOfLastPriceNoSettlement: isSet(object.yieldOfLastPriceNoSettlement)
        ? globalThis.Number(object.yieldOfLastPriceNoSettlement)
        : 0,
      yieldOfYesterdaySettlement: isSet(object.yieldOfYesterdaySettlement)
        ? globalThis.Number(object.yieldOfYesterdaySettlement)
        : 0,
      yieldOfYesterdayLast: isSet(object.yieldOfYesterdayLast) ? globalThis.Number(object.yieldOfYesterdayLast) : 0,
      yieldOfIndicativeOpen: isSet(object.yieldOfIndicativeOpen) ? globalThis.Number(object.yieldOfIndicativeOpen) : 0,
      yieldOfSettlement: isSet(object.yieldOfSettlement) ? globalThis.Number(object.yieldOfSettlement) : 0,
    };
  },

  toJSON(message: MarketYields): unknown {
    const obj: any = {};
    if (message.yieldOfOpenPrice !== undefined && message.yieldOfOpenPrice !== 0) {
      obj.yieldOfOpenPrice = message.yieldOfOpenPrice;
    }
    if (message.yieldOfHighPrice !== undefined && message.yieldOfHighPrice !== 0) {
      obj.yieldOfHighPrice = message.yieldOfHighPrice;
    }
    if (message.yieldOfLowPrice !== undefined && message.yieldOfLowPrice !== 0) {
      obj.yieldOfLowPrice = message.yieldOfLowPrice;
    }
    if (message.yieldOfLastPrice !== undefined && message.yieldOfLastPrice !== 0) {
      obj.yieldOfLastPrice = message.yieldOfLastPrice;
    }
    if (message.yieldOfClosePrice !== undefined && message.yieldOfClosePrice !== 0) {
      obj.yieldOfClosePrice = message.yieldOfClosePrice;
    }
    if (message.yieldOfLastPriceNoSettlement !== undefined && message.yieldOfLastPriceNoSettlement !== 0) {
      obj.yieldOfLastPriceNoSettlement = message.yieldOfLastPriceNoSettlement;
    }
    if (message.yieldOfYesterdaySettlement !== undefined && message.yieldOfYesterdaySettlement !== 0) {
      obj.yieldOfYesterdaySettlement = message.yieldOfYesterdaySettlement;
    }
    if (message.yieldOfYesterdayLast !== undefined && message.yieldOfYesterdayLast !== 0) {
      obj.yieldOfYesterdayLast = message.yieldOfYesterdayLast;
    }
    if (message.yieldOfIndicativeOpen !== undefined && message.yieldOfIndicativeOpen !== 0) {
      obj.yieldOfIndicativeOpen = message.yieldOfIndicativeOpen;
    }
    if (message.yieldOfSettlement !== undefined && message.yieldOfSettlement !== 0) {
      obj.yieldOfSettlement = message.yieldOfSettlement;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketYields>, I>>(base?: I): MarketYields {
    return MarketYields.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketYields>, I>>(object: I): MarketYields {
    const message = createBaseMarketYields();
    message.yieldOfOpenPrice = object.yieldOfOpenPrice ?? 0;
    message.yieldOfHighPrice = object.yieldOfHighPrice ?? 0;
    message.yieldOfLowPrice = object.yieldOfLowPrice ?? 0;
    message.yieldOfLastPrice = object.yieldOfLastPrice ?? 0;
    message.yieldOfClosePrice = object.yieldOfClosePrice ?? 0;
    message.yieldOfLastPriceNoSettlement = object.yieldOfLastPriceNoSettlement ?? 0;
    message.yieldOfYesterdaySettlement = object.yieldOfYesterdaySettlement ?? 0;
    message.yieldOfYesterdayLast = object.yieldOfYesterdayLast ?? 0;
    message.yieldOfIndicativeOpen = object.yieldOfIndicativeOpen ?? 0;
    message.yieldOfSettlement = object.yieldOfSettlement ?? 0;
    return message;
  },
};

function createBaseAtTheMoneyStrikeRequest(): AtTheMoneyStrikeRequest {
  return { optionMaturityId: "" };
}

export const AtTheMoneyStrikeRequest = {
  encode(message: AtTheMoneyStrikeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionMaturityId !== "") {
      writer.uint32(10).string(message.optionMaturityId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AtTheMoneyStrikeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAtTheMoneyStrikeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.optionMaturityId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AtTheMoneyStrikeRequest {
    return { optionMaturityId: isSet(object.optionMaturityId) ? globalThis.String(object.optionMaturityId) : "" };
  },

  toJSON(message: AtTheMoneyStrikeRequest): unknown {
    const obj: any = {};
    if (message.optionMaturityId !== "") {
      obj.optionMaturityId = message.optionMaturityId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AtTheMoneyStrikeRequest>, I>>(base?: I): AtTheMoneyStrikeRequest {
    return AtTheMoneyStrikeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AtTheMoneyStrikeRequest>, I>>(object: I): AtTheMoneyStrikeRequest {
    const message = createBaseAtTheMoneyStrikeRequest();
    message.optionMaturityId = object.optionMaturityId ?? "";
    return message;
  },
};

function createBaseAtTheMoneyStrikeReport(): AtTheMoneyStrikeReport {
  return { strike: 0 };
}

export const AtTheMoneyStrikeReport = {
  encode(message: AtTheMoneyStrikeReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.strike !== undefined && message.strike !== 0) {
      writer.uint32(8).sint32(message.strike);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AtTheMoneyStrikeReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAtTheMoneyStrikeReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.strike = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AtTheMoneyStrikeReport {
    return { strike: isSet(object.strike) ? globalThis.Number(object.strike) : 0 };
  },

  toJSON(message: AtTheMoneyStrikeReport): unknown {
    const obj: any = {};
    if (message.strike !== undefined && message.strike !== 0) {
      obj.strike = Math.round(message.strike);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AtTheMoneyStrikeReport>, I>>(base?: I): AtTheMoneyStrikeReport {
    return AtTheMoneyStrikeReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AtTheMoneyStrikeReport>, I>>(object: I): AtTheMoneyStrikeReport {
    const message = createBaseAtTheMoneyStrikeReport();
    message.strike = object.strike ?? 0;
    return message;
  },
};

function createBaseDetailedDOM(): DetailedDOM {
  return { isDetailedDomComplete: false, isStale: false, isSnapshot: false, priceLevels: [] };
}

export const DetailedDOM = {
  encode(message: DetailedDOM, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isDetailedDomComplete !== undefined && message.isDetailedDomComplete !== false) {
      writer.uint32(8).bool(message.isDetailedDomComplete);
    }
    if (message.isStale !== undefined && message.isStale !== false) {
      writer.uint32(16).bool(message.isStale);
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      writer.uint32(24).bool(message.isSnapshot);
    }
    for (const v of message.priceLevels) {
      DetailedDOMAtPrice.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetailedDOM {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailedDOM();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isDetailedDomComplete = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isStale = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isSnapshot = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.priceLevels.push(DetailedDOMAtPrice.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetailedDOM {
    return {
      isDetailedDomComplete: isSet(object.isDetailedDomComplete)
        ? globalThis.Boolean(object.isDetailedDomComplete)
        : false,
      isStale: isSet(object.isStale) ? globalThis.Boolean(object.isStale) : false,
      isSnapshot: isSet(object.isSnapshot) ? globalThis.Boolean(object.isSnapshot) : false,
      priceLevels: globalThis.Array.isArray(object?.priceLevels)
        ? object.priceLevels.map((e: any) => DetailedDOMAtPrice.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DetailedDOM): unknown {
    const obj: any = {};
    if (message.isDetailedDomComplete !== undefined && message.isDetailedDomComplete !== false) {
      obj.isDetailedDomComplete = message.isDetailedDomComplete;
    }
    if (message.isStale !== undefined && message.isStale !== false) {
      obj.isStale = message.isStale;
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      obj.isSnapshot = message.isSnapshot;
    }
    if (message.priceLevels?.length) {
      obj.priceLevels = message.priceLevels.map((e) => DetailedDOMAtPrice.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetailedDOM>, I>>(base?: I): DetailedDOM {
    return DetailedDOM.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetailedDOM>, I>>(object: I): DetailedDOM {
    const message = createBaseDetailedDOM();
    message.isDetailedDomComplete = object.isDetailedDomComplete ?? false;
    message.isStale = object.isStale ?? false;
    message.isSnapshot = object.isSnapshot ?? false;
    message.priceLevels = object.priceLevels?.map((e) => DetailedDOMAtPrice.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDetailedDOMAtPrice(): DetailedDOMAtPrice {
  return { scaledPrice: 0, side: 0, isSnapshot: false, orders: [] };
}

export const DetailedDOMAtPrice = {
  encode(message: DetailedDOMAtPrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scaledPrice !== undefined && message.scaledPrice !== 0) {
      writer.uint32(8).sint32(message.scaledPrice);
    }
    if (message.side !== 0) {
      writer.uint32(16).uint32(message.side);
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      writer.uint32(24).bool(message.isSnapshot);
    }
    for (const v of message.orders) {
      DetailedDOMOrder.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetailedDOMAtPrice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailedDOMAtPrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scaledPrice = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isSnapshot = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orders.push(DetailedDOMOrder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetailedDOMAtPrice {
    return {
      scaledPrice: isSet(object.scaledPrice) ? globalThis.Number(object.scaledPrice) : 0,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      isSnapshot: isSet(object.isSnapshot) ? globalThis.Boolean(object.isSnapshot) : false,
      orders: globalThis.Array.isArray(object?.orders)
        ? object.orders.map((e: any) => DetailedDOMOrder.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DetailedDOMAtPrice): unknown {
    const obj: any = {};
    if (message.scaledPrice !== undefined && message.scaledPrice !== 0) {
      obj.scaledPrice = Math.round(message.scaledPrice);
    }
    if (message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.isSnapshot !== undefined && message.isSnapshot !== false) {
      obj.isSnapshot = message.isSnapshot;
    }
    if (message.orders?.length) {
      obj.orders = message.orders.map((e) => DetailedDOMOrder.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetailedDOMAtPrice>, I>>(base?: I): DetailedDOMAtPrice {
    return DetailedDOMAtPrice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetailedDOMAtPrice>, I>>(object: I): DetailedDOMAtPrice {
    const message = createBaseDetailedDOMAtPrice();
    message.scaledPrice = object.scaledPrice ?? 0;
    message.side = object.side ?? 0;
    message.isSnapshot = object.isSnapshot ?? false;
    message.orders = object.orders?.map((e) => DetailedDOMOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDetailedDOMOrder(): DetailedDOMOrder {
  return {
    detailedDomOrderId: "",
    operation: 0,
    orderIndex: 0,
    scaledVolume: 0,
    volume: undefined,
    implied: false,
    contributorId: 0,
  };
}

export const DetailedDOMOrder = {
  encode(message: DetailedDOMOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.detailedDomOrderId !== "") {
      writer.uint32(10).string(message.detailedDomOrderId);
    }
    if (message.operation !== undefined && message.operation !== 0) {
      writer.uint32(16).uint32(message.operation);
    }
    if (message.orderIndex !== undefined && message.orderIndex !== 0) {
      writer.uint32(24).uint32(message.orderIndex);
    }
    if (message.scaledVolume !== undefined && message.scaledVolume !== 0) {
      writer.uint32(40).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(58).fork()).ldelim();
    }
    if (message.implied !== undefined && message.implied !== false) {
      writer.uint32(64).bool(message.implied);
    }
    if (message.contributorId !== undefined && message.contributorId !== 0) {
      writer.uint32(72).sint32(message.contributorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetailedDOMOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailedDOMOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detailedDomOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.operation = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.orderIndex = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.scaledVolume = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.volume = Decimal.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.implied = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.contributorId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetailedDOMOrder {
    return {
      detailedDomOrderId: isSet(object.detailedDomOrderId) ? globalThis.String(object.detailedDomOrderId) : "",
      operation: isSet(object.operation) ? globalThis.Number(object.operation) : 0,
      orderIndex: isSet(object.orderIndex) ? globalThis.Number(object.orderIndex) : 0,
      scaledVolume: isSet(object.scaledVolume) ? globalThis.Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      implied: isSet(object.implied) ? globalThis.Boolean(object.implied) : false,
      contributorId: isSet(object.contributorId) ? globalThis.Number(object.contributorId) : 0,
    };
  },

  toJSON(message: DetailedDOMOrder): unknown {
    const obj: any = {};
    if (message.detailedDomOrderId !== "") {
      obj.detailedDomOrderId = message.detailedDomOrderId;
    }
    if (message.operation !== undefined && message.operation !== 0) {
      obj.operation = Math.round(message.operation);
    }
    if (message.orderIndex !== undefined && message.orderIndex !== 0) {
      obj.orderIndex = Math.round(message.orderIndex);
    }
    if (message.scaledVolume !== undefined && message.scaledVolume !== 0) {
      obj.scaledVolume = Math.round(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      obj.volume = Decimal.toJSON(message.volume);
    }
    if (message.implied !== undefined && message.implied !== false) {
      obj.implied = message.implied;
    }
    if (message.contributorId !== undefined && message.contributorId !== 0) {
      obj.contributorId = Math.round(message.contributorId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetailedDOMOrder>, I>>(base?: I): DetailedDOMOrder {
    return DetailedDOMOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetailedDOMOrder>, I>>(object: I): DetailedDOMOrder {
    const message = createBaseDetailedDOMOrder();
    message.detailedDomOrderId = object.detailedDomOrderId ?? "";
    message.operation = object.operation ?? 0;
    message.orderIndex = object.orderIndex ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = (object.volume !== undefined && object.volume !== null)
      ? Decimal.fromPartial(object.volume)
      : undefined;
    message.implied = object.implied ?? false;
    message.contributorId = object.contributorId ?? 0;
    return message;
  },
};

function createBaseRFQ(): RFQ {
  return {
    exchangeRequestId: "",
    utcTimestamp: undefined,
    side: 0,
    volume: undefined,
    expirationUtcTimestamp: undefined,
  };
}

export const RFQ = {
  encode(message: RFQ, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeRequestId !== undefined && message.exchangeRequestId !== "") {
      writer.uint32(10).string(message.exchangeRequestId);
    }
    if (message.utcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.utcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(24).uint32(message.side);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(34).fork()).ldelim();
    }
    if (message.expirationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationUtcTimestamp), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RFQ {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRFQ();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.exchangeRequestId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.utcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.volume = Decimal.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expirationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RFQ {
    return {
      exchangeRequestId: isSet(object.exchangeRequestId) ? globalThis.String(object.exchangeRequestId) : "",
      utcTimestamp: isSet(object.utcTimestamp) ? fromJsonTimestamp(object.utcTimestamp) : undefined,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      expirationUtcTimestamp: isSet(object.expirationUtcTimestamp)
        ? fromJsonTimestamp(object.expirationUtcTimestamp)
        : undefined,
    };
  },

  toJSON(message: RFQ): unknown {
    const obj: any = {};
    if (message.exchangeRequestId !== undefined && message.exchangeRequestId !== "") {
      obj.exchangeRequestId = message.exchangeRequestId;
    }
    if (message.utcTimestamp !== undefined) {
      obj.utcTimestamp = message.utcTimestamp.toISOString();
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.volume !== undefined) {
      obj.volume = Decimal.toJSON(message.volume);
    }
    if (message.expirationUtcTimestamp !== undefined) {
      obj.expirationUtcTimestamp = message.expirationUtcTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RFQ>, I>>(base?: I): RFQ {
    return RFQ.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RFQ>, I>>(object: I): RFQ {
    const message = createBaseRFQ();
    message.exchangeRequestId = object.exchangeRequestId ?? "";
    message.utcTimestamp = object.utcTimestamp ?? undefined;
    message.side = object.side ?? 0;
    message.volume = (object.volume !== undefined && object.volume !== null)
      ? Decimal.fromPartial(object.volume)
      : undefined;
    message.expirationUtcTimestamp = object.expirationUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseRFQRequest(): RFQRequest {
  return {
    clientRequestId: 0,
    accountId: 0,
    contractId: 0,
    isManual: false,
    side: 0,
    size: undefined,
    executionUtcTimestamp: undefined,
    clientRegulatoryAlgorithmId: 0,
    expirationUtcTimestamp: undefined,
  };
}

export const RFQRequest = {
  encode(message: RFQRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientRequestId !== undefined && message.clientRequestId !== 0) {
      writer.uint32(8).uint32(message.clientRequestId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.contractId !== undefined && message.contractId !== 0) {
      writer.uint32(24).uint32(message.contractId);
    }
    if (message.isManual !== undefined && message.isManual !== false) {
      writer.uint32(32).bool(message.isManual);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(40).uint32(message.side);
    }
    if (message.size !== undefined) {
      Decimal.encode(message.size, writer.uint32(50).fork()).ldelim();
    }
    if (message.executionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.executionUtcTimestamp), writer.uint32(58).fork()).ldelim();
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== 0) {
      writer.uint32(64).uint32(message.clientRegulatoryAlgorithmId);
    }
    if (message.expirationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationUtcTimestamp), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RFQRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRFQRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.clientRequestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.contractId = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isManual = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.size = Decimal.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.executionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.clientRegulatoryAlgorithmId = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.expirationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RFQRequest {
    return {
      clientRequestId: isSet(object.clientRequestId) ? globalThis.Number(object.clientRequestId) : 0,
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? globalThis.Number(object.contractId) : 0,
      isManual: isSet(object.isManual) ? globalThis.Boolean(object.isManual) : false,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      size: isSet(object.size) ? Decimal.fromJSON(object.size) : undefined,
      executionUtcTimestamp: isSet(object.executionUtcTimestamp)
        ? fromJsonTimestamp(object.executionUtcTimestamp)
        : undefined,
      clientRegulatoryAlgorithmId: isSet(object.clientRegulatoryAlgorithmId)
        ? globalThis.Number(object.clientRegulatoryAlgorithmId)
        : 0,
      expirationUtcTimestamp: isSet(object.expirationUtcTimestamp)
        ? fromJsonTimestamp(object.expirationUtcTimestamp)
        : undefined,
    };
  },

  toJSON(message: RFQRequest): unknown {
    const obj: any = {};
    if (message.clientRequestId !== undefined && message.clientRequestId !== 0) {
      obj.clientRequestId = Math.round(message.clientRequestId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.contractId !== undefined && message.contractId !== 0) {
      obj.contractId = Math.round(message.contractId);
    }
    if (message.isManual !== undefined && message.isManual !== false) {
      obj.isManual = message.isManual;
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.size !== undefined) {
      obj.size = Decimal.toJSON(message.size);
    }
    if (message.executionUtcTimestamp !== undefined) {
      obj.executionUtcTimestamp = message.executionUtcTimestamp.toISOString();
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== 0) {
      obj.clientRegulatoryAlgorithmId = Math.round(message.clientRegulatoryAlgorithmId);
    }
    if (message.expirationUtcTimestamp !== undefined) {
      obj.expirationUtcTimestamp = message.expirationUtcTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RFQRequest>, I>>(base?: I): RFQRequest {
    return RFQRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RFQRequest>, I>>(object: I): RFQRequest {
    const message = createBaseRFQRequest();
    message.clientRequestId = object.clientRequestId ?? 0;
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    message.isManual = object.isManual ?? false;
    message.side = object.side ?? 0;
    message.size = (object.size !== undefined && object.size !== null) ? Decimal.fromPartial(object.size) : undefined;
    message.executionUtcTimestamp = object.executionUtcTimestamp ?? undefined;
    message.clientRegulatoryAlgorithmId = object.clientRegulatoryAlgorithmId ?? 0;
    message.expirationUtcTimestamp = object.expirationUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseRFQReport(): RFQReport {
  return {
    clientRequestId: 0,
    exchangeRequestId: "",
    statusCode: 0,
    details: undefined,
    effectiveRegulatoryAlgorithmId: 0,
  };
}

export const RFQReport = {
  encode(message: RFQReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientRequestId !== undefined && message.clientRequestId !== 0) {
      writer.uint32(8).uint32(message.clientRequestId);
    }
    if (message.exchangeRequestId !== undefined && message.exchangeRequestId !== "") {
      writer.uint32(18).string(message.exchangeRequestId);
    }
    if (message.statusCode !== undefined && message.statusCode !== 0) {
      writer.uint32(24).uint32(message.statusCode);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(34).fork()).ldelim();
    }
    if (message.effectiveRegulatoryAlgorithmId !== undefined && message.effectiveRegulatoryAlgorithmId !== 0) {
      writer.uint32(40).uint32(message.effectiveRegulatoryAlgorithmId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RFQReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRFQReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.clientRequestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exchangeRequestId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.statusCode = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.details = Text.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.effectiveRegulatoryAlgorithmId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RFQReport {
    return {
      clientRequestId: isSet(object.clientRequestId) ? globalThis.Number(object.clientRequestId) : 0,
      exchangeRequestId: isSet(object.exchangeRequestId) ? globalThis.String(object.exchangeRequestId) : "",
      statusCode: isSet(object.statusCode) ? globalThis.Number(object.statusCode) : 0,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      effectiveRegulatoryAlgorithmId: isSet(object.effectiveRegulatoryAlgorithmId)
        ? globalThis.Number(object.effectiveRegulatoryAlgorithmId)
        : 0,
    };
  },

  toJSON(message: RFQReport): unknown {
    const obj: any = {};
    if (message.clientRequestId !== undefined && message.clientRequestId !== 0) {
      obj.clientRequestId = Math.round(message.clientRequestId);
    }
    if (message.exchangeRequestId !== undefined && message.exchangeRequestId !== "") {
      obj.exchangeRequestId = message.exchangeRequestId;
    }
    if (message.statusCode !== undefined && message.statusCode !== 0) {
      obj.statusCode = Math.round(message.statusCode);
    }
    if (message.details !== undefined) {
      obj.details = Text.toJSON(message.details);
    }
    if (message.effectiveRegulatoryAlgorithmId !== undefined && message.effectiveRegulatoryAlgorithmId !== 0) {
      obj.effectiveRegulatoryAlgorithmId = Math.round(message.effectiveRegulatoryAlgorithmId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RFQReport>, I>>(base?: I): RFQReport {
    return RFQReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RFQReport>, I>>(object: I): RFQReport {
    const message = createBaseRFQReport();
    message.clientRequestId = object.clientRequestId ?? 0;
    message.exchangeRequestId = object.exchangeRequestId ?? "";
    message.statusCode = object.statusCode ?? 0;
    message.details = (object.details !== undefined && object.details !== null)
      ? Text.fromPartial(object.details)
      : undefined;
    message.effectiveRegulatoryAlgorithmId = object.effectiveRegulatoryAlgorithmId ?? 0;
    return message;
  },
};

function createBaseMarketState(): MarketState {
  return { tradingState: undefined, exchangeSpecifics: [] };
}

export const MarketState = {
  encode(message: MarketState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tradingState !== undefined) {
      TradingState.encode(message.tradingState, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.exchangeSpecifics) {
      ExchangeSpecificMarketState.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tradingState = TradingState.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exchangeSpecifics.push(ExchangeSpecificMarketState.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketState {
    return {
      tradingState: isSet(object.tradingState) ? TradingState.fromJSON(object.tradingState) : undefined,
      exchangeSpecifics: globalThis.Array.isArray(object?.exchangeSpecifics)
        ? object.exchangeSpecifics.map((e: any) => ExchangeSpecificMarketState.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MarketState): unknown {
    const obj: any = {};
    if (message.tradingState !== undefined) {
      obj.tradingState = TradingState.toJSON(message.tradingState);
    }
    if (message.exchangeSpecifics?.length) {
      obj.exchangeSpecifics = message.exchangeSpecifics.map((e) => ExchangeSpecificMarketState.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketState>, I>>(base?: I): MarketState {
    return MarketState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketState>, I>>(object: I): MarketState {
    const message = createBaseMarketState();
    message.tradingState = (object.tradingState !== undefined && object.tradingState !== null)
      ? TradingState.fromPartial(object.tradingState)
      : undefined;
    message.exchangeSpecifics = object.exchangeSpecifics?.map((e) => ExchangeSpecificMarketState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTradingState(): TradingState {
  return {
    exchangeState: 0,
    allowPlaceOrder: false,
    allowCancelOrder: false,
    allowModifyOrder: false,
    matchingEnabled: false,
  };
}

export const TradingState = {
  encode(message: TradingState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeState !== undefined && message.exchangeState !== 0) {
      writer.uint32(8).uint32(message.exchangeState);
    }
    if (message.allowPlaceOrder !== undefined && message.allowPlaceOrder !== false) {
      writer.uint32(16).bool(message.allowPlaceOrder);
    }
    if (message.allowCancelOrder !== undefined && message.allowCancelOrder !== false) {
      writer.uint32(24).bool(message.allowCancelOrder);
    }
    if (message.allowModifyOrder !== undefined && message.allowModifyOrder !== false) {
      writer.uint32(32).bool(message.allowModifyOrder);
    }
    if (message.matchingEnabled !== undefined && message.matchingEnabled !== false) {
      writer.uint32(40).bool(message.matchingEnabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.exchangeState = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.allowPlaceOrder = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allowCancelOrder = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.allowModifyOrder = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.matchingEnabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradingState {
    return {
      exchangeState: isSet(object.exchangeState) ? globalThis.Number(object.exchangeState) : 0,
      allowPlaceOrder: isSet(object.allowPlaceOrder) ? globalThis.Boolean(object.allowPlaceOrder) : false,
      allowCancelOrder: isSet(object.allowCancelOrder) ? globalThis.Boolean(object.allowCancelOrder) : false,
      allowModifyOrder: isSet(object.allowModifyOrder) ? globalThis.Boolean(object.allowModifyOrder) : false,
      matchingEnabled: isSet(object.matchingEnabled) ? globalThis.Boolean(object.matchingEnabled) : false,
    };
  },

  toJSON(message: TradingState): unknown {
    const obj: any = {};
    if (message.exchangeState !== undefined && message.exchangeState !== 0) {
      obj.exchangeState = Math.round(message.exchangeState);
    }
    if (message.allowPlaceOrder !== undefined && message.allowPlaceOrder !== false) {
      obj.allowPlaceOrder = message.allowPlaceOrder;
    }
    if (message.allowCancelOrder !== undefined && message.allowCancelOrder !== false) {
      obj.allowCancelOrder = message.allowCancelOrder;
    }
    if (message.allowModifyOrder !== undefined && message.allowModifyOrder !== false) {
      obj.allowModifyOrder = message.allowModifyOrder;
    }
    if (message.matchingEnabled !== undefined && message.matchingEnabled !== false) {
      obj.matchingEnabled = message.matchingEnabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingState>, I>>(base?: I): TradingState {
    return TradingState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradingState>, I>>(object: I): TradingState {
    const message = createBaseTradingState();
    message.exchangeState = object.exchangeState ?? 0;
    message.allowPlaceOrder = object.allowPlaceOrder ?? false;
    message.allowCancelOrder = object.allowCancelOrder ?? false;
    message.allowModifyOrder = object.allowModifyOrder ?? false;
    message.matchingEnabled = object.matchingEnabled ?? false;
    return message;
  },
};

function createBaseExchangeSpecificMarketState(): ExchangeSpecificMarketState {
  return { tag: "", value: "" };
}

export const ExchangeSpecificMarketState = {
  encode(message: ExchangeSpecificMarketState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== undefined && message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeSpecificMarketState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeSpecificMarketState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExchangeSpecificMarketState {
    return {
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ExchangeSpecificMarketState): unknown {
    const obj: any = {};
    if (message.tag !== undefined && message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeSpecificMarketState>, I>>(base?: I): ExchangeSpecificMarketState {
    return ExchangeSpecificMarketState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExchangeSpecificMarketState>, I>>(object: I): ExchangeSpecificMarketState {
    const message = createBaseExchangeSpecificMarketState();
    message.tag = object.tag ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseOptionCalculationParameters(): OptionCalculationParameters {
  return { optionMaturityId: "", strikeContractIds: [], contributorId: "" };
}

export const OptionCalculationParameters = {
  encode(message: OptionCalculationParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionMaturityId !== undefined && message.optionMaturityId !== "") {
      writer.uint32(10).string(message.optionMaturityId);
    }
    writer.uint32(18).fork();
    for (const v of message.strikeContractIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.contributorId !== undefined && message.contributorId !== "") {
      writer.uint32(26).string(message.contributorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.optionMaturityId = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.strikeContractIds.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.strikeContractIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contributorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationParameters {
    return {
      optionMaturityId: isSet(object.optionMaturityId) ? globalThis.String(object.optionMaturityId) : "",
      strikeContractIds: globalThis.Array.isArray(object?.strikeContractIds)
        ? object.strikeContractIds.map((e: any) => globalThis.Number(e))
        : [],
      contributorId: isSet(object.contributorId) ? globalThis.String(object.contributorId) : "",
    };
  },

  toJSON(message: OptionCalculationParameters): unknown {
    const obj: any = {};
    if (message.optionMaturityId !== undefined && message.optionMaturityId !== "") {
      obj.optionMaturityId = message.optionMaturityId;
    }
    if (message.strikeContractIds?.length) {
      obj.strikeContractIds = message.strikeContractIds.map((e) => Math.round(e));
    }
    if (message.contributorId !== undefined && message.contributorId !== "") {
      obj.contributorId = message.contributorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationParameters>, I>>(base?: I): OptionCalculationParameters {
    return OptionCalculationParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OptionCalculationParameters>, I>>(object: I): OptionCalculationParameters {
    const message = createBaseOptionCalculationParameters();
    message.optionMaturityId = object.optionMaturityId ?? "";
    message.strikeContractIds = object.strikeContractIds?.map((e) => e) || [];
    message.contributorId = object.contributorId ?? "";
    return message;
  },
};

function createBaseOptionCalculationRequest(): OptionCalculationRequest {
  return { requestId: 0, optionCalculationParameters: undefined, requestType: 0 };
}

export const OptionCalculationRequest = {
  encode(message: OptionCalculationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== undefined && message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.optionCalculationParameters !== undefined) {
      OptionCalculationParameters.encode(message.optionCalculationParameters, writer.uint32(18).fork()).ldelim();
    }
    if (message.requestType !== undefined && message.requestType !== 0) {
      writer.uint32(24).uint32(message.requestType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.optionCalculationParameters = OptionCalculationParameters.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.requestType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationRequest {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      optionCalculationParameters: isSet(object.optionCalculationParameters)
        ? OptionCalculationParameters.fromJSON(object.optionCalculationParameters)
        : undefined,
      requestType: isSet(object.requestType) ? globalThis.Number(object.requestType) : 0,
    };
  },

  toJSON(message: OptionCalculationRequest): unknown {
    const obj: any = {};
    if (message.requestId !== undefined && message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.optionCalculationParameters !== undefined) {
      obj.optionCalculationParameters = OptionCalculationParameters.toJSON(message.optionCalculationParameters);
    }
    if (message.requestType !== undefined && message.requestType !== 0) {
      obj.requestType = Math.round(message.requestType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationRequest>, I>>(base?: I): OptionCalculationRequest {
    return OptionCalculationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OptionCalculationRequest>, I>>(object: I): OptionCalculationRequest {
    const message = createBaseOptionCalculationRequest();
    message.requestId = object.requestId ?? 0;
    message.optionCalculationParameters =
      (object.optionCalculationParameters !== undefined && object.optionCalculationParameters !== null)
        ? OptionCalculationParameters.fromPartial(object.optionCalculationParameters)
        : undefined;
    message.requestType = object.requestType ?? 0;
    return message;
  },
};

function createBaseOptionCalculationValues(): OptionCalculationValues {
  return {
    strikeContractId: 0,
    scaledCoherentUnderlyingPrice: 0,
    impliedVolatility: 0,
    theov: 0,
    delta: 0,
    gamma: 0,
    vega: 0,
    theta: 0,
    rho: 0,
    deleted: false,
  };
}

export const OptionCalculationValues = {
  encode(message: OptionCalculationValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.strikeContractId !== undefined && message.strikeContractId !== 0) {
      writer.uint32(8).uint32(message.strikeContractId);
    }
    if (message.scaledCoherentUnderlyingPrice !== undefined && message.scaledCoherentUnderlyingPrice !== 0) {
      writer.uint32(16).sint32(message.scaledCoherentUnderlyingPrice);
    }
    if (message.impliedVolatility !== undefined && message.impliedVolatility !== 0) {
      writer.uint32(25).double(message.impliedVolatility);
    }
    if (message.theov !== undefined && message.theov !== 0) {
      writer.uint32(33).double(message.theov);
    }
    if (message.delta !== undefined && message.delta !== 0) {
      writer.uint32(41).double(message.delta);
    }
    if (message.gamma !== undefined && message.gamma !== 0) {
      writer.uint32(49).double(message.gamma);
    }
    if (message.vega !== undefined && message.vega !== 0) {
      writer.uint32(57).double(message.vega);
    }
    if (message.theta !== undefined && message.theta !== 0) {
      writer.uint32(65).double(message.theta);
    }
    if (message.rho !== undefined && message.rho !== 0) {
      writer.uint32(73).double(message.rho);
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      writer.uint32(80).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.strikeContractId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.scaledCoherentUnderlyingPrice = reader.sint32();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.impliedVolatility = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.theov = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.delta = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.gamma = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.vega = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.theta = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.rho = reader.double();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.deleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationValues {
    return {
      strikeContractId: isSet(object.strikeContractId) ? globalThis.Number(object.strikeContractId) : 0,
      scaledCoherentUnderlyingPrice: isSet(object.scaledCoherentUnderlyingPrice)
        ? globalThis.Number(object.scaledCoherentUnderlyingPrice)
        : 0,
      impliedVolatility: isSet(object.impliedVolatility) ? globalThis.Number(object.impliedVolatility) : 0,
      theov: isSet(object.theov) ? globalThis.Number(object.theov) : 0,
      delta: isSet(object.delta) ? globalThis.Number(object.delta) : 0,
      gamma: isSet(object.gamma) ? globalThis.Number(object.gamma) : 0,
      vega: isSet(object.vega) ? globalThis.Number(object.vega) : 0,
      theta: isSet(object.theta) ? globalThis.Number(object.theta) : 0,
      rho: isSet(object.rho) ? globalThis.Number(object.rho) : 0,
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
    };
  },

  toJSON(message: OptionCalculationValues): unknown {
    const obj: any = {};
    if (message.strikeContractId !== undefined && message.strikeContractId !== 0) {
      obj.strikeContractId = Math.round(message.strikeContractId);
    }
    if (message.scaledCoherentUnderlyingPrice !== undefined && message.scaledCoherentUnderlyingPrice !== 0) {
      obj.scaledCoherentUnderlyingPrice = Math.round(message.scaledCoherentUnderlyingPrice);
    }
    if (message.impliedVolatility !== undefined && message.impliedVolatility !== 0) {
      obj.impliedVolatility = message.impliedVolatility;
    }
    if (message.theov !== undefined && message.theov !== 0) {
      obj.theov = message.theov;
    }
    if (message.delta !== undefined && message.delta !== 0) {
      obj.delta = message.delta;
    }
    if (message.gamma !== undefined && message.gamma !== 0) {
      obj.gamma = message.gamma;
    }
    if (message.vega !== undefined && message.vega !== 0) {
      obj.vega = message.vega;
    }
    if (message.theta !== undefined && message.theta !== 0) {
      obj.theta = message.theta;
    }
    if (message.rho !== undefined && message.rho !== 0) {
      obj.rho = message.rho;
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationValues>, I>>(base?: I): OptionCalculationValues {
    return OptionCalculationValues.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OptionCalculationValues>, I>>(object: I): OptionCalculationValues {
    const message = createBaseOptionCalculationValues();
    message.strikeContractId = object.strikeContractId ?? 0;
    message.scaledCoherentUnderlyingPrice = object.scaledCoherentUnderlyingPrice ?? 0;
    message.impliedVolatility = object.impliedVolatility ?? 0;
    message.theov = object.theov ?? 0;
    message.delta = object.delta ?? 0;
    message.gamma = object.gamma ?? 0;
    message.vega = object.vega ?? 0;
    message.theta = object.theta ?? 0;
    message.rho = object.rho ?? 0;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseOptionCalculationReport(): OptionCalculationReport {
  return {
    requestId: 0,
    statusCode: 0,
    upToUtcTime: 0,
    interestRate: 0,
    scaledUnderlyingPrice: 0,
    values: [],
    details: undefined,
    isReportComplete: true,
  };
}

export const OptionCalculationReport = {
  encode(message: OptionCalculationReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== undefined && message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.statusCode !== undefined && message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.upToUtcTime !== undefined && message.upToUtcTime !== 0) {
      writer.uint32(24).int64(message.upToUtcTime);
    }
    if (message.interestRate !== undefined && message.interestRate !== 0) {
      writer.uint32(33).double(message.interestRate);
    }
    if (message.scaledUnderlyingPrice !== undefined && message.scaledUnderlyingPrice !== 0) {
      writer.uint32(40).sint32(message.scaledUnderlyingPrice);
    }
    for (const v of message.values) {
      OptionCalculationValues.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(58).fork()).ldelim();
    }
    if (message.isReportComplete !== undefined && message.isReportComplete !== true) {
      writer.uint32(64).bool(message.isReportComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.statusCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.upToUtcTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.interestRate = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.scaledUnderlyingPrice = reader.sint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.values.push(OptionCalculationValues.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.details = Text.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.isReportComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationReport {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      statusCode: isSet(object.statusCode) ? globalThis.Number(object.statusCode) : 0,
      upToUtcTime: isSet(object.upToUtcTime) ? globalThis.Number(object.upToUtcTime) : 0,
      interestRate: isSet(object.interestRate) ? globalThis.Number(object.interestRate) : 0,
      scaledUnderlyingPrice: isSet(object.scaledUnderlyingPrice) ? globalThis.Number(object.scaledUnderlyingPrice) : 0,
      values: globalThis.Array.isArray(object?.values)
        ? object.values.map((e: any) => OptionCalculationValues.fromJSON(e))
        : [],
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      isReportComplete: isSet(object.isReportComplete) ? globalThis.Boolean(object.isReportComplete) : true,
    };
  },

  toJSON(message: OptionCalculationReport): unknown {
    const obj: any = {};
    if (message.requestId !== undefined && message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.statusCode !== undefined && message.statusCode !== 0) {
      obj.statusCode = Math.round(message.statusCode);
    }
    if (message.upToUtcTime !== undefined && message.upToUtcTime !== 0) {
      obj.upToUtcTime = Math.round(message.upToUtcTime);
    }
    if (message.interestRate !== undefined && message.interestRate !== 0) {
      obj.interestRate = message.interestRate;
    }
    if (message.scaledUnderlyingPrice !== undefined && message.scaledUnderlyingPrice !== 0) {
      obj.scaledUnderlyingPrice = Math.round(message.scaledUnderlyingPrice);
    }
    if (message.values?.length) {
      obj.values = message.values.map((e) => OptionCalculationValues.toJSON(e));
    }
    if (message.details !== undefined) {
      obj.details = Text.toJSON(message.details);
    }
    if (message.isReportComplete !== undefined && message.isReportComplete !== true) {
      obj.isReportComplete = message.isReportComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationReport>, I>>(base?: I): OptionCalculationReport {
    return OptionCalculationReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OptionCalculationReport>, I>>(object: I): OptionCalculationReport {
    const message = createBaseOptionCalculationReport();
    message.requestId = object.requestId ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.upToUtcTime = object.upToUtcTime ?? 0;
    message.interestRate = object.interestRate ?? 0;
    message.scaledUnderlyingPrice = object.scaledUnderlyingPrice ?? 0;
    message.values = object.values?.map((e) => OptionCalculationValues.fromPartial(e)) || [];
    message.details = (object.details !== undefined && object.details !== null)
      ? Text.fromPartial(object.details)
      : undefined;
    message.isReportComplete = object.isReportComplete ?? true;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

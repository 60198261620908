import UserInfo from "../../../common/userinfo";
import ProductsNav from "../../../common/userinfo/ProductsNav";
import ToolsNav from "../../../common/userinfo/Tools";
import { Drawer, Switch } from "@mui/material";
import DrawerComponent from '@mui/material/Drawer';
import { ChevronLeft } from "@mui/icons-material";
import "./sidebar.css";
import { Widget } from "../../../../types";
import { useState } from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
  onRequestToAddNewWidget: (widget: Widget) => void
}

const Sidebar = ({ isOpen, onToggle, onRequestToAddNewWidget }: SidebarProps) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const drawerStyle = {
    '& .MuiModal-modal': {
      zIndex: 10,
    },
    '& .MuiModal-backdrop': {
      left: 180,
      top: 83,
    },
    '& .MuiDrawer-paper': {
      width: 420,
      boxSizing: 'border-box',
      backgroundColor: '#f4f4f4',
      left: 180,
      top: 83,
      boxShadow: 'none',
    },
  };
  const DrawerList = (
    <Box role="presentation" onClick={toggleDrawer(false)}>
      <List>
      <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="New Chart" />
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />

    </Box>
  );
  return (
    <>
      <nav>
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={onToggle}
          variant="persistent"
          sx={{
            width: isOpen ? 180 : 0,
            height: 'calc(100vh - 100px)',
            transition: "width 0.1s ease",
            "& .MuiDrawer-paper": {
              width: isOpen ? 180 : 0,
              top: 2,
              position: "relative",
              overflow: "hidden",
            },
          }}
        >
          <div className="sidebar-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 8px 6px 16px",
                borderBottom: "1px solid var(--divider, #0000001F)",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={onToggle}
            >
              <UserInfo />
              <ChevronLeft />
            </div>
            <ProductsNav handleAddChart={toggleDrawer(true)} onRequestToAddNewWidget={onRequestToAddNewWidget} />
            <ToolsNav/>
          </div>
        </Drawer>
        <DrawerComponent style={{ zIndex:10 }} sx={drawerStyle} open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </DrawerComponent>        
        <div className="switch-container">
          <Switch className="toggle-switch" />
          <span className="toggle-label">1-Click</span>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;

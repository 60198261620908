import { CQGService } from "./CQGService";
import { AccountsManager } from "./AccountsManager";
import { AccountSummariesManager } from "./AccountSummariesManager";
import { PositionsManager } from "./PositionsManager";
import { CQGServiceMessageManager } from "../message-managers/ServiceMessageManager";
import { CQGAuthUser } from "../models/CQGAuthUser";
import { TradeSubscriptionsManager } from "./TradeSubscriptionsManager";
import { OrdersManager } from "./OrdersManager";
import { ServiceWatcher } from "../service-watchers/ServiceWatcher";
import { AppMessageManager } from "../message-managers/AppMessageManager";
import { Account } from "../models/Account";
import { LogonResult, LogonResult_ResultCode } from "../proto/user_session_2";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";
import { AppMessageWatcher } from "../service-watchers/AppMessageWatcher";

export class CQGEnvironment {
  private static instance: CQGEnvironment | null = null;
  public static cqgAccountAuthInfo?: CQGAuthUser;

  public serviceMessageEventManager = new CQGServiceMessageEventManager();
  public cqgService: CQGService = new CQGService(this, this.serviceMessageEventManager);
  public serviceMessageManager: CQGServiceMessageManager = new CQGServiceMessageManager(this, this.cqgService, this.serviceMessageEventManager);

  public appMessageManager: AppMessageManager = new AppMessageManager();
  public accountsManager: AccountsManager = new AccountsManager(
    this.serviceMessageManager,
    this.serviceMessageEventManager,
  );

  public tradeSubscriptionsManager: TradeSubscriptionsManager = new TradeSubscriptionsManager(
    this.serviceMessageManager,
    this.serviceMessageEventManager,
  );

  public accountSummariesManager: AccountSummariesManager = new AccountSummariesManager(
    this.accountsManager,
    this.tradeSubscriptionsManager,
    this.serviceMessageEventManager
  );

  public positionsManager: PositionsManager = new PositionsManager(
    this.appMessageManager,
    this.tradeSubscriptionsManager,
    this.accountsManager,
    this.serviceMessageEventManager,
    this.accountSummariesManager,
  );

  public ordersManager: OrdersManager = new OrdersManager(
    this.accountsManager,
    this.tradeSubscriptionsManager,
    this.serviceMessageEventManager,
  );

  public serviceWatcher = new ServiceWatcher(this);
  public appMessageWatcher = new AppMessageWatcher(this);

  public baseTimeStr: string = "";
  public sessionToken: string | undefined = "";
  public isLoggedOn = false;
  public selectedAccount: Account | null = null;

  public static setCQGAccountAuthInfo(authInfo: CQGAuthUser) {
    CQGEnvironment.cqgAccountAuthInfo = authInfo;
  }

  public get accountAuthInfo() {
    return CQGEnvironment.cqgAccountAuthInfo;
  }

  constructor() {
    if (CQGEnvironment.instance) {
      return CQGEnvironment.instance;
    }

    CQGEnvironment.instance = this;
  }

  public get isCQGServiceReady() {
    return this.cqgService?.isReady;
  }

  public setLogonResult = (logonResult: LogonResult) => {
    var oldIsLoggedOn = this.isLoggedOn;
    this.baseTimeStr = logonResult.baseTime;
    this.sessionToken = logonResult.sessionToken;
    this.isLoggedOn = logonResult.resultCode === LogonResult_ResultCode.RESULT_CODE_SUCCESS;

    if (this.isLoggedOn !== oldIsLoggedOn) {
      if (!this.isLoggedOn) {
        this.setSelectedAccount(null);
      }
    }
  };

  setSelectedAccount = (account: Account | null) => {
    this.selectedAccount = account;
  };

  getSelectedAccount = () => {
    return this.selectedAccount;
  };

  public static get Instance(): CQGEnvironment {
    if (CQGEnvironment.instance) return CQGEnvironment.instance;

    return new CQGEnvironment();
  }
}

import * as WebAPI from "../proto/webapi_2";
import * as UserSession from "../proto/user_session_2";
import * as Metadata from "../proto/metadata_2";
import * as MarketData from "../proto/market_data_2";

import * as _ from "../../vendors/underscore-esm";

import { isArrayWithValues } from "../utils/lib";
import { CQGEnvironment } from "./CQGEnvironment";
import { CQGServiceMessageManager } from "../message-managers/ServiceMessageManager";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";
import { InstrumentsManager } from "./InstrumentsManager";
import { SymbolResolutionSubscription, SymbolResolutionSubscriptions } from "./SymbolResolutionSubscriptions";
import { InstrumentSubscriptions } from "./InstrumentSubscriptions";
import { Instrument } from "../models/Instrument";
import { WSErrorCodes } from "../constants/WSErrorCodes";

export class CQGService {
  private socket?: WebSocket;
  private isLoggedIn = false;
  private socketUrl = "wss://api.cqg.com";
  private isUserLogout = false;
  private static instance: CQGService | null = null;
  public onOpen?: (event: Event) => void;
  public onMessage?: (event: WebAPI.ServerMsg) => void;
  public onClose?: (event: CloseEvent) => void;
  public onError?: (event: Event) => void;
  private env!: CQGEnvironment;
  private serviceMessageEventManager!: CQGServiceMessageEventManager;

  constructor(env: CQGEnvironment, serviceMessageEventManager: CQGServiceMessageEventManager) {
    if (CQGService.instance) {
      return CQGService.instance;
    }

    this.env = env;
    this.serviceMessageEventManager = serviceMessageEventManager;
    CQGService.instance = this;

    this.initSocket();
  }

  private initSocket() {
    console.log("Connecting to WebSocket ...");
    this.socket = new WebSocket(this.socketUrl);
    this.socket.onopen = this.onWsOpen.bind(this);
    this.socket.onmessage = this.onWsMessage.bind(this);
    this.socket.onclose = this.onWsClose.bind(this);
    this.socket.onerror = this.onWsError.bind(this);
  }

  private closeSocket() {
    console.log("Closing connection ...");
    this.socket?.close();
    delete this.socket;
  }

  private onWsOpen(event: Event) {
    console.log("WebSocket connection opened");

    this.initiateLogon();

    if (this.onOpen) this.onOpen(event);
  }

  private async onWsMessage(event: MessageEvent) {
    const data = await event.data.arrayBuffer();
    const msg = WebAPI.ServerMsg.decode(new Uint8Array(data));

    logIncomingMessage(msg);

    if (!!msg.logonResult) {
      if (msg.logonResult?.resultCode === 0) {
        this.isLoggedIn = true;
        console.log("Logged in successfully!");

        this.env.setLogonResult(msg.logonResult);

        this.serviceMessageEventManager.serviceReadyEventEmit(msg.logonResult);
      }

      if (!!msg.logonResult && msg.logonResult?.resultCode !== 0) {
        console.log("Login failed!", msg.logonResult);
        this.isLoggedIn = false;
        this.socket?.close();
      }

      return;
    }

    if (msg?.loggedOff) {
      console.log("Logged Off ...", msg?.loggedOff);
      this.isLoggedIn = false;
      return;
    }

    if (!!msg?.collateralStatuses && isArrayWithValues(msg.collateralStatuses)) {
      this.serviceMessageEventManager.collateralStatusesEventEmit(msg.collateralStatuses);
    }

    if (!!msg?.tradeSnapshotCompletions && isArrayWithValues(msg.tradeSnapshotCompletions)) {
      this.serviceMessageEventManager.tradeSnapshotCompletionsEventEmit(msg.tradeSnapshotCompletions);
    }

    if (!!msg?.positionStatuses && isArrayWithValues(msg.positionStatuses)) {
      this.serviceMessageEventManager.positionStatusesEventEmit(msg.positionStatuses);
    }

    if (!!msg?.informationReports && isArrayWithValues(msg.informationReports)) {
      this.serviceMessageEventManager.informationReportsEventEmit(msg.informationReports);
    }

    if (!!msg?.marketDataSubscriptionStatuses && isArrayWithValues(msg.marketDataSubscriptionStatuses)) {
      this.serviceMessageEventManager.marketDataSubscriptionStatusesEventEmit(msg.marketDataSubscriptionStatuses);
    }

    if (!!msg?.realTimeMarketData && isArrayWithValues(msg.realTimeMarketData)) {
      this.serviceMessageEventManager.realTimeMarketDataEventEmit(msg.realTimeMarketData);
    }

    if (!!msg?.orderStatuses && isArrayWithValues(msg.orderStatuses)) {
      this.serviceMessageEventManager.orderStatusesEventEmit(msg?.orderStatuses);
    }

    if (!!msg?.tradeSubscriptionStatuses && isArrayWithValues(msg.tradeSubscriptionStatuses)) {
      this.serviceMessageEventManager.tradeSubscriptionStatusEventEmit(msg.tradeSubscriptionStatuses);
    }

    if (this.onMessage) this.onMessage(msg);
  }

  private onWsClose(event: CloseEvent) {
    const error = WSErrorCodes[event.code];
    console.log("WebSocket connection closed. Reason:", error, event);

    this.isLoggedIn = false;

    this.serviceMessageEventManager.connectionCloseEventEmit();

    if (!this.isUserLogout) {
      this.closeSocket();

      // If any cleanup is required outside.
      if (this.onClose) this.onClose(event);

      // console.log("Reconnecting ...");
      // this.initSocket();
    }
  }

  private onWsError(event: Event) {
    console.error("WebSocket error:", event);
    if (this.onError) this.onError(event);
  }

  public send(message: WebAPI.ClientMsg): Error | void {
    logOutgoingMessage(message);
    // console.log("Sending message:", message);
    const encoded = WebAPI.ClientMsg.encode(message).finish();
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(encoded);
    } else {
      return new Error("Connection is not ready");
    }
  }

  public close() {
    this.closeSocket();
  }

  public get isReady(): boolean {
    return this.isLoggedIn && this.socket?.readyState === WebSocket.OPEN;
  }

  private initiateLogon() {
    let clMsg = WebAPI.ClientMsg.create();
    let logonMsg = UserSession.Logon.create();
    logonMsg.userName = this.env.accountAuthInfo?.username;
    logonMsg.password = this.env.accountAuthInfo?.password;
    logonMsg.clientAppId = "CMEInstitute";
    logonMsg.clientVersion = "1.1.5038.15046";
    clMsg.logon = logonMsg;
    this.send(clMsg);
  }

  static resolveSymbols = (symbols: string[]) => {
    if (!Array.isArray(symbols)) {
      symbols = [symbols];
    }

    var subscriptions: SymbolResolutionSubscription[] = [];
    var newSubscriptions: SymbolResolutionSubscription[] = [];

    symbols.forEach((symbol) => {
      var subscription = SymbolResolutionSubscriptions.getBySymbol(symbol);
      if (!subscription) {
        subscription = SymbolResolutionSubscriptions.add(symbol, CQGServiceMessageManager.nextRequestId());
        newSubscriptions.push(subscription);
      }
      subscriptions.push(subscription);
    });

    if (newSubscriptions.length > 0) {
      var clMsg = WebAPI.ClientMsg.create();
      newSubscriptions.forEach((subscription) => {
        var informationRequest = WebAPI.InformationRequest.create();
        informationRequest.id = subscription.id;
        informationRequest.subscribe = true;

        var resolutionRequest = Metadata.SymbolResolutionRequest.create();
        resolutionRequest.symbol = subscription.symbol;

        informationRequest.symbolResolutionRequest = resolutionRequest;
        clMsg.informationRequests.push(informationRequest);
      });

      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    }
    return subscriptions;
  };

  // TODO: This function requires spliting and relocating to appropriate places.
  subscribeToInstruments = (contractIds: number[], level: number) => {
    if (!Array.isArray(contractIds)) {
      contractIds = [contractIds];
    }

    let requests: MarketData.MarketDataSubscription[] = [];
    contractIds.forEach((contractId) => {
      var subscription = InstrumentSubscriptions.Instance.getByContractId(contractId);
      if (subscription) {
        var subscrResult = subscription.addConsumer(level);
        if (subscrResult.newLevel > subscrResult.oldLevel) {
          requests.push({
            contractId: contractId,
            level: level,
          });
        }
      } else {
        InstrumentSubscriptions.Instance.add(contractId, level);
        requests.push({
          contractId: contractId,
          level: level,
        });
      }
    });

    if (requests.length > 0) {
      var clMsg = this.createMarketDataSubscriptionRequest(requests);
      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    }
  };

  unsubscribeFromInstruments = (contractIds: number[], level: number) => {
    if (!Array.isArray(contractIds)) {
      contractIds = [contractIds];
    }

    let requests: MarketData.MarketDataSubscription[] = [];
    _.forEach(_.uniq(contractIds), (contractId: number) => {
      let subscription = InstrumentSubscriptions.Instance.getByContractId(contractId);
      if (!subscription) {
        console.warn("Instrument not found for unsubscription: %d:%d.", contractId, level);
        return;
      }
      let subscrResult = subscription.removeConsumer(level);
      if (subscrResult.oldLevel !== subscrResult.newLevel) {
        requests.push({
          contractId: contractId,
          level: subscrResult.newLevel,
        });
        if (subscrResult.newLevel === MarketData.MarketDataSubscription_Level.LEVEL_NONE) {
          InstrumentSubscriptions.Instance.remove(contractId);
        }
      }
    });

    if (requests.length > 0) {
      const clMsg = this.createMarketDataSubscriptionRequest(requests);
      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    }
  };

  createMarketDataSubscriptionRequest = (requests: MarketData.MarketDataSubscription[]) => {
    if (!Array.isArray(requests)) requests = [requests];

    let clMsg = WebAPI.ClientMsg.create();

    requests.forEach((item) => {
      var request = MarketData.MarketDataSubscription.create();
      request.contractId = item.contractId;
      request.level = item.level;
      clMsg.marketDataSubscriptions.push(request);
    });

    return clMsg;
  };

  processMarketDataSubscriptionStatus = (marketDataSubscriptionStatus: MarketData.MarketDataSubscriptionStatus[]) => {
    InstrumentSubscriptions.Instance.processMarketDataSubscriptionStatus(marketDataSubscriptionStatus);
  };

  processRealTimeMarketData = (realTimeMarketData: MarketData.RealTimeMarketData[]): Instrument[] => {
    var instruments = InstrumentsManager.processRealTimeMarketData(realTimeMarketData);
    return instruments;

    // _.forEach(self.marketWatchers, function (watcher) {
    //   watcher(instruments);
    // });
  };

  //#region Market Watchers

  // TODO: Inspect and refactor this section.
  private marketWatchers = [];
  private _timeBarWatchers = [];
  private _timeBarRequestIdToRequest = {};

  registerMarketWatcher = (watcher: never) => {
    if (!this.marketWatchers.includes(watcher)) {
      this.marketWatchers.push(watcher);
    }
  };

  unregisterMarketWatcher = (watcher: never) => {
    if (this.marketWatchers.includes(watcher)) {
      this.marketWatchers = this.marketWatchers.filter((item) => item !== watcher);
    }
  };

  registerTimeBarWatcher = (watcher: never) => {
    if (!this._timeBarWatchers.includes(watcher)) {
      this._timeBarWatchers.push(watcher);
    }
  };

  //#endregion
}

// TODO: Temporary functions
const logIncomingMessage = (msg: WebAPI.ServerMsg) => {
  for (let key in msg) {
    if (!!msg[key as keyof WebAPI.ServerMsg]) {
      let val = msg[key as keyof WebAPI.ServerMsg];
      if (Array.isArray(val)) {
        if (val.length > 0) {
          // console.log("Service message: " + key, val);
        }
      } else {
        // console.log("Service message: " + key, val);
      }
    }
  }
};

const logOutgoingMessage = (msg: WebAPI.ClientMsg) => {
  for (let key in msg) {
    if (!!msg[key as keyof WebAPI.ClientMsg]) {
      let val = msg[key as keyof WebAPI.ClientMsg];
      if (Array.isArray(val)) {
        if (val.length > 0) {
          // console.log("Sending message: " + key, val);
        }
      } else {
        // console.log("Sending message: " + key, val);
      }
    }
  }
};

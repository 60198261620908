import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { Workspace } from "../../types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    border: "1px solid #000 !important",
    color: "#000 !important",
    borderRadius: "8px !important",
    width: "100%",
    height: "40px",
    position: "relative",
    zIndex: "1",
    fontFamily: "Mulish !important",
    padding: "10px 20px !important",
    opacity: "0.8",
    "&:before": {
      content: '""',
      display: "block",
      color: "#fff",
      height: "calc(100% - 2px)",
      width: "calc(100% - 2px)",
      position: "absolute",
      top: "1px",
      left: "1px",
      borderRadius: "9px",
      zIndex: "-1",
    },
    "&:hover": {
      opacity: "1",
      backgroundColor: "#ddd!important",
      color: "#333",
    },
    "&:disabled": {
      opacity: "0.5",
    },
  },
});

const ConfirmDialog = ({
  open,
  handleClose,
  onDelete,
  deleteWorkspace,
  loading,
  confirmText
}: {
  open: boolean;
  handleClose: () => void;
  onDelete: () => void;
  loading: boolean;
  deleteWorkspace?: Workspace | null;
  confirmText: string;
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          background: "#fff",
          color: "#000",
          padding: "20px",
          borderRadius: "8px",
        },
      }}
      className="draggableCancelSelector"
    >
      <DialogContent>
        <DialogContentText style={{ color: "#000" }} id="alert-dialog-description">
          {confirmText}
          {deleteWorkspace && ` workspace ${deleteWorkspace?.title}`}?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ gap: "12px" }}>
        <Button className={classes.root} onClick={handleClose}>
          No
        </Button>
        <Button className={classes.root} disabled={loading} onClick={loading ? undefined : onDelete} autoFocus>
          {loading ? "Loading..." : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

import { useCallback, useEffect, useState } from "react";
import { DockviewApi, DockviewReact, DockviewReadyEvent, SerializedDockview } from "dockview";
import { produce } from "immer";

import RightHeaderControls from "./RightHeaderControls";
// import LeftHeaderControls from "./LeftHeaderControls";

import LoadingSpinner from "./LoadingSpinner";
import { Watchlist, Widget, WidgetType, Workspace } from "../../types";
import { addPanel, addPanels, components } from "./utils";
import { useUpdateWorkspace } from "../../hooks/api/workspace";

import { useCreateWatchlist } from "../../hooks/api/watchlist";
import { Button } from "@mui/material";
import WidgetSelectionDialog from "../widget-selection/WidgetSelectionDialog";

interface DockviewProps {
  activeWorkspace: Workspace;
  workspaceLoading: boolean;
  newworkspaceWidgets: Widget[];
  onWorkSpaceUpdated: (workspace: Workspace) => void;
  newWidgetToAddToPanel: Widget | null;
  setNewWidgetToAddToPanel: React.Dispatch<React.SetStateAction<Widget | null>>;
}

const Dockview = ({
  activeWorkspace,
  workspaceLoading,
  newworkspaceWidgets,
  onWorkSpaceUpdated,
  newWidgetToAddToPanel,
  setNewWidgetToAddToPanel,
}: DockviewProps) => {
  const { updateWorkspaceAsync } = useUpdateWorkspace();
  const { createWatchlistAsync } = useCreateWatchlist();
  const [dockviewApi, setDockviewApi] = useState<DockviewApi | undefined>();
  const [showWidgetSelectionDialog, setShowWidgetSelectionDialog] = useState(false);
  const [hasValidLayout, setHasValidLayout] = useState(false);

  const updateWorkspaceToServer = useCallback(async () => {
    if (!dockviewApi) return;

    const layout: SerializedDockview = dockviewApi.toJSON();
    const updatedWorkspace = produce(activeWorkspace, (draft) => {
      if (draft) draft.data = layout;
    });
    const isValidLayout = layout?.grid && Object.keys(layout.panels || {}).length === 0 ? true : false;
    setHasValidLayout(isValidLayout);

    await updateWorkspaceAsync(updatedWorkspace as Workspace);
  }, [dockviewApi, activeWorkspace, updateWorkspaceAsync]);

  const onDidLayOutChange = useCallback(() => {
    updateWorkspaceToServer();
  }, [updateWorkspaceToServer]);

  const onReady = useCallback((event: DockviewReadyEvent) => {
    setDockviewApi(event.api);
  }, []);

  useEffect(() => {
    if (!dockviewApi) return;

    dockviewApi.clear();
    const { data } = activeWorkspace;

    if (data && data.grid) {
      try {
        dockviewApi.fromJSON(data);
        const panels = data.panels;
        const isValidLayout = Object.keys(panels).length === 0;
        setHasValidLayout(isValidLayout);
      } catch (e) {
        console.error("Failed to load workspace layout:", e);
      }
    } else {
      addPanels(activeWorkspace, dockviewApi, newworkspaceWidgets, undefined);
      updateWorkspaceToServer();
    }

    const disposable = dockviewApi.onDidLayoutChange(onDidLayOutChange);
    return () => disposable.dispose();
  }, [dockviewApi, activeWorkspace, newworkspaceWidgets, onDidLayOutChange, updateWorkspaceToServer]);

  const onWidgetsAdded = useCallback(() => {
    updateWorkspaceToServer();
  }, [updateWorkspaceToServer]);

  const addWidgetsToPanel = useCallback(
    async (widgets: Widget[]) => {
      if (!dockviewApi) return;
      dockviewApi.clear();
      try {
        addPanels(activeWorkspace, dockviewApi, widgets, undefined);
        try {
          const layout: SerializedDockview = dockviewApi.toJSON();
          const updatedWorkSpace = produce(activeWorkspace, (draft) => {
            if (draft) {
              draft.data = layout;
            }
          });

          onWorkSpaceUpdated(updatedWorkSpace);
          await updateWorkspaceAsync(updatedWorkSpace as Workspace);
        } catch (error) {
          console.error("Error updating workspace:", error);
        }
      } catch (error) {
        console.error("Failed to add widgets to panel:", error);
      }
    },
    [activeWorkspace, dockviewApi, onWorkSpaceUpdated, updateWorkspaceAsync],
  );

  const onClick = useCallback(
    (widgets: Widget[]) => {
      if (!dockviewApi) return;
      dockviewApi.clear();
      setShowWidgetSelectionDialog(false);
      widgets = JSON.parse(JSON.stringify(widgets));

      const watchlist = widgets.find((widget) => widget.WidgetType === WidgetType.Watchlist);
      if (watchlist && activeWorkspace.id) {
        createWatchlistAsync(activeWorkspace.id, (response: Watchlist) => {
          watchlist.widgetId = response.id;
          addWidgetsToPanel(widgets);
        });
      } else {
        addWidgetsToPanel(widgets);
      }
    },
    [activeWorkspace.id, addWidgetsToPanel, createWatchlistAsync, dockviewApi],
  );

  const onAddWidgetClick = useCallback(() => {
    setShowWidgetSelectionDialog(true);
  }, []);

  useEffect(() => {
    if (!dockviewApi || !newWidgetToAddToPanel) return;

    const clonedWidgets = JSON.parse(JSON.stringify(newWidgetToAddToPanel));
    const watchlist = clonedWidgets.WidgetType === WidgetType.Watchlist;

    if (watchlist && activeWorkspace.id) {
      createWatchlistAsync(activeWorkspace.id, (response: Watchlist) => {
        clonedWidgets.widgetId = response.id;
        addPanel(dockviewApi, clonedWidgets, activeWorkspace);
        onWidgetsAdded();
      });
    } else {
      addPanel(dockviewApi, clonedWidgets, activeWorkspace);
      onWidgetsAdded();
    }

    setNewWidgetToAddToPanel(null);
  }, [
    activeWorkspace,
    createWatchlistAsync,
    dockviewApi,
    newWidgetToAddToPanel,
    onWidgetsAdded,
    setNewWidgetToAddToPanel,
  ]);

  return (
    <>
      {workspaceLoading ? (
        <div className="dockview-workspace-loader">
          <LoadingSpinner />
        </div>
      ) : hasValidLayout ? (
        <div className="emptyWorkspace">
          <h3>Your workspace is empty</h3>
          <p>Add widgets to get started!</p>
          <Button variant="contained" onClick={onAddWidgetClick}>
            Add Widget
          </Button>
          {showWidgetSelectionDialog && (
            <WidgetSelectionDialog
              title="Select Widgets"
              show={true}
              onOkay={onClick}
              onCancel={() => setShowWidgetSelectionDialog(false)}
              isShowTitle={false}
            />
          )}
        </div>
      ) : (
        <DockviewReact
          components={components}
          rightHeaderActionsComponent={RightHeaderControls}
          // leftHeaderActionsComponent={(props) => LeftHeaderControls({ activeWorkspace, onWidgetsAdded, props })}
          watermarkComponent={undefined}
          onReady={onReady}
          className="dockview-theme-light"
          gap={8}
          defaultRenderer="always"
        />
      )}
    </>
  );
};

export default Dockview;

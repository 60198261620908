import { numberNullable, stringNullable } from "../types/Types";
import { Account } from "./Account";
import { Instrument } from "./Instrument";

export class AggregatedPosition {
    contractId: numberNullable;
    displaySymbol: stringNullable;
    displayLong: number | null = null;
    displayShort: number | null = null;
    displayOteMvo: number = 0;
    displayUpl: number = 0;
    displayMvo: number = 0;
    displayPrice: number | null = null;
    instrument: Instrument | undefined;
    account: Account;
    displayRpl: number = 0;
    size: number = 0;
    buys: number = 0;
    sells: number = 0;
  
    constructor(account: Account) {
      this.account = account;
    }
  }
  
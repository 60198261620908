import { Box, InputAdornment, TextField } from "@mui/material";
import { InstrumentMarketData, Product, Workspace } from "../../../types";
import "./AvailableProducts.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useFetchProducts } from "../../../hooks/api/product";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { MapInstrumentMarketData2 } from "../../../utils/utils";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { subscribeToInstruments, unsubscribeInstuments } from "../../../utils/subscriptions";
import { useDispatch, useSelector } from "react-redux";
import watchlistSelectors from "../../../redux/watchlist/watchlistSelector";
import { useFetchWorkspaceWatchlist, useUpdateWatchlist } from "../../../hooks/api/watchlist";
import LoadingSpinner from "../../dockview/LoadingSpinner";
import watchlistActions from "../../../redux/watchlist/watchlistActions";

const textFieldStyle = {
  "&.MuiTextField-root": {
    "& .MuiInputLabel-outlined": {
      color: "#000",
    },
    "& .MuiInputBase-inputSizeSmall": {
      color: "#000",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #000",
      },
    },
    "& .MuiInputBase-inputMultiline": {
      color: "#000",
    },
    "& .Mui-focused": {
      outline: "none",
      border: "none",
    },
  },
};

const AvailableProducts = (props: { selectedWorkspace?: Workspace | null; workspaceClassName?: string }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState<any>();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { products, loadProducts } = useFetchProducts();
  const { realTimeMarketData } = useRealTimeMarketData();
  const getWatchlists = useSelector(watchlistSelectors.getWatchlist);
  useEffect(() => {
    loadProducts();
  }, [loadProducts]);
  const watchListProducts = useMemo(() => {
    const allProducts = getWatchlists.map((list: {associatedProducts: string}) => list.associatedProducts?.split(","));
    return allProducts.flat();
  }, [getWatchlists]);

  useEffect(() => {
    setIsLoading(false);
  }, [getWatchlists]);

  const addProductToWatchlist = useCallback((id: number) => {
    setIsLoading(true);
    const isExist = watchListProducts.find((product: string) => parseInt(product) === id);
    if (isExist) {
      dispatch(watchlistActions.dispatchRemoveProduct(id));
      setIsLoading(false);
    } else {
      dispatch(watchlistActions.dispatchAddProduct(id));
      setIsLoading(false);
    }
  }, [dispatch, watchListProducts]);
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "title",
        headerName: "Symbol",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "askPrice",
        headerName: "Ask Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "askQty",
        headerName: "Ask Qty",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "bidPrice",
        headerName: "Bid Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "bidQty",
        headerName: "Bid Qty",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "change",
        headerName: "Change",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "changePercentage",
        headerName: "Change %",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "selected",
        headerName: "Selected",
        flex: 1,
        minWidth: 75,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const isAddedToWatchlist = watchListProducts.find((product: string) => parseInt(product) === params.id);
          return (
          <button className="starIcon" onClick={() => addProductToWatchlist(params.id as number)}>
            {isAddedToWatchlist ? <StarIcon fontSize="small" /> : <StarBorderIcon fontSize="small" />}
          </button>
        )},
      },
    ],
    [addProductToWatchlist, watchListProducts],
  );

  const assetProductsDictionary = useMemo(() => {
    if (products) {
      return products?.reduce((acc: any, product) => {
        if (product?.assetClass?.name in acc) {
          acc[product?.assetClass?.name] = [product, ...acc[product?.assetClass?.name]];
        } else {
          acc[product?.assetClass?.name] = [product];
        }
        return acc;
      }, {});
    }
  }, [products]);

  const productsAsPerChoosenAsset = useMemo(
    () => (selectedAssetType ? assetProductsDictionary?.[selectedAssetType] : products),
    [selectedAssetType, products, assetProductsDictionary],
  );
  const [watchlistData, setWatchlistData] = useState<InstrumentMarketData[]>([]);

  const filteredWatchlistData = useMemo(
    () =>
      watchlistData.filter((item: any) => {
        const inputSearchTextLower = searchQuery.toLowerCase();
        const nameInLowerCase = item.name?.toLowerCase() ?? '';
        const titleInLowerCase = item.title?.toLowerCase() ?? '';
  
        return inputSearchTextLower === "" ? true : nameInLowerCase.includes(inputSearchTextLower) || titleInLowerCase.includes(inputSearchTextLower);
      }),
    [watchlistData, searchQuery],
  );

  // console.log("realTimeMarketData", realTimeMarketData);

  useEffect(() => {
    const newWatchlistData: InstrumentMarketData[] = [];
    productsAsPerChoosenAsset.forEach(function (data: Product) {
      let index = watchlistData.findIndex((product: Product) => product.id === data.id);
      if (index !== -1) {
        newWatchlistData.push(watchlistData[index]);
      } else {
        newWatchlistData.push({ ...data });
      }
    });
    setWatchlistData(newWatchlistData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);

  useEffect(() => {
    if (watchlistData.length) {
      setWatchlistData(MapInstrumentMarketData2(watchlistData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  useEffect(() => {
    let resolvedInstruments: Instrument[] = [];
    subscribeToInstruments(productsAsPerChoosenAsset).then((symbols) => {
      resolvedInstruments = symbols;
    });
    return () => {
      unsubscribeInstuments(resolvedInstruments);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);

  const resetSearch = useCallback(() => {
    setSearchQuery("");
  }, []);

  const handleAssetTypeClick = useCallback((assetType: string) => {
    setSelectedAssetType(assetType);
    resetSearch();
  }, [resetSearch]);

  return (
    <div className="widget-parent-container">
        {isLoading && <div className="dockview-workspace-loader">
          <LoadingSpinner />
        </div>}
      <div className="available-product-container draggableCancelSelector widget-grid-container">
        <div className="available-product-left-panel">
          <TextField
            id="title"
            label="Search"
            size="small"
            className="basic-info-title-field available-product-search"
            sx={textFieldStyle}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className="asset-type-container">
            <div
              onClick={() => handleAssetTypeClick("")}
              className={selectedAssetType === "" ? "asset-type-selected asset-type" : "asset-type"}
            >
              All
            </div>
            {Object.keys(assetProductsDictionary)?.map((assetType, index) => {
              return (
                <div
                  onClick={() => handleAssetTypeClick(assetType)}
                  className={selectedAssetType === assetType ? "asset-type-selected asset-type" : "asset-type"}
                  key={index}
                >
                  {assetType}
                </div>
              );
            })}
          </div>
        </div>
        <div className="available-product-right-panel">
          <DataGridTable columns={columns} rows={filteredWatchlistData} />
        </div>
      </div>
    </div>
  );
};

export default AvailableProducts;

// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: api_limit_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Text } from "./common/shared_1";

export const protobufPackage = "api_limit_2";

/**
 * Naming convention:
 * *_RATE - maximum number of messages of the corresponding type
 *     per output period. Messages exceeding the limit are rejected.
 * *_REQUESTS_IN_PROCESSING - maximum number of requests of
 *     the corresponding type in processing till a snapshot received.
 *     Messages exceeding the limit are rejected.
 * *_SUBSCRIPTIONS - maximum number of subscriptions for updates.
 *     Messages exceeding the limit are rejected.
 * *_TO_CLOSE_CONNECTION limit violation leads to
 *     user disconnection from the server.
 */
export enum ApiLimit {
  /** API_LIMIT_UNSPECIFIED - Limit type unspecifed and must be ignored. */
  API_LIMIT_UNSPECIFIED = 0,
  /**
   * API_LIMIT_CONNECTION_RATE - Generic and session limits (1 - 99)
   * Max connection rate from one IP address
   */
  API_LIMIT_CONNECTION_RATE = 1,
  /** API_LIMIT_CONCURRENT_SUBSESSIONS - Max concurrent subsession per user */
  API_LIMIT_CONCURRENT_SUBSESSIONS = 2,
  /** API_LIMIT_CONNECTIONS_PER_IP - Number of maximum allowed simultaneous connections from single IP address */
  API_LIMIT_CONNECTIONS_PER_IP = 3,
  /**
   * API_LIMIT_SOCKET_UNREADY_PERIOD_TO_CLOSE_CONNECTION - Generic message related limits (100-199)
   * Maximum allowed duration (in seconds) of WebSocket unavailability
   * for outgoing messages.
   */
  API_LIMIT_SOCKET_UNREADY_PERIOD_TO_CLOSE_CONNECTION = 100,
  API_LIMIT_CLIENT_MESSAGES_RATE = 110,
  /** API_LIMIT_INFORMATION_REQUESTS_RATE - Information request limits (200-599) */
  API_LIMIT_INFORMATION_REQUESTS_RATE = 200,
  API_LIMIT_ACCOUNTS_REQUESTS_RATE = 210,
  API_LIMIT_SYMBOL_RESOLUTION_REQUESTS_RATE = 220,
  API_LIMIT_LAST_STATEMENT_BALANCES_RATE = 230,
  API_LIMIT_CURRENCY_RATES_REQUESTS_RATE = 240,
  API_LIMIT_SESSION_INFORMATION_REQUESTS_IN_PROCESSING = 250,
  API_LIMIT_SESSION_INFORMATION_SUBSCRIPTIONS = 251,
  API_LIMIT_HISTORICAL_ORDERS_REQUESTS_IN_PROCESSING = 260,
  /**
   * API_LIMIT_HISTORICAL_ORDERS_DAYS - Maximum allowed days back from now for historical orders requests.
   * Exceeding fails requests.
   */
  API_LIMIT_HISTORICAL_ORDERS_DAYS = 261,
  API_LIMIT_OPTION_MATURITY_LIST_REQUESTS_IN_PROCESSING = 270,
  API_LIMIT_OPTION_MATURITY_LIST_SUBSCRIPTIONS = 271,
  API_LIMIT_INSTRUMENT_GROUP_REQUESTS_IN_PROCESSING = 280,
  API_LIMIT_INSTRUMENT_GROUP_SUBSCRIPTIONS = 281,
  API_LIMIT_AT_THE_MONEY_STRIKE_REQUESTS_IN_PROCESSING = 290,
  API_LIMIT_AT_THE_MONEY_STRIKE_SUBSCRIPTIONS = 291,
  API_LIMIT_STRATEGY_DEFINITION_REQUESTS_RATE = 300,
  API_LIMIT_SESSION_TIME_RANGE_REQUESTS_IN_PROCESSING = 310,
  API_LIMIT_TRADING_DAY_TIME_RANGE_REQUESTS_IN_PROCESSING = 320,
  API_LIMIT_ORDER_ENTITLEMENT_REQUESTS_IN_PROCESSING = 330,
  API_LIMIT_SYMBOL_CATEGORY_LIST_REQUESTS_IN_PROCESSING = 340,
  API_LIMIT_SYMBOL_CATEGORY_LIST_SUBSCRIPTIONS = 341,
  API_LIMIT_SYMBOL_CATEGORY_REQUESTS_IN_PROCESSING = 350,
  API_LIMIT_SYMBOL_CATEGORY_SUBSCRIPTIONS = 351,
  API_LIMIT_SYMBOL_LIST_REQUESTS_IN_PROCESSING = 360,
  API_LIMIT_SYMBOL_LIST_SUBSCRIPTIONS = 361,
  API_LIMIT_SYMBOL_REQUESTS_RATE = 370,
  API_LIMIT_SYMBOL_REQUESTS_IN_PROCESSING = 371,
  API_LIMIT_ALGO_STRATEGY_DEFINITION_REQUESTS_IN_PROCESSING = 380,
  API_LIMIT_API_LIMITS_SUBSCRIPTIONS_AND_REQUESTS_IN_PROCESSING = 390,
  API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_RATE = 400,
  API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_IN_PROCESSING = 401,
  API_LIMIT_CONTRIBUTOR_METADATA_SUBSCRIPTIONS = 402,
  API_LIMIT_BROKERAGE_TRADING_FEATURE_ENTITLEMENT_REQUESTS_IN_PROCESSING = 410,
  API_LIMIT_ORDER_STATUS_REQUESTS_RATE = 420,
  API_LIMIT_PRODUCT_SEARCH_REQUESTS_RATE = 430,
  API_LIMIT_PRODUCT_SEARCH_REQUESTS_IN_PROCESSING = 431,
  API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_RATE = 440,
  API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_IN_PROCESSING = 441,
  API_LIMIT_MARKET_STATE_METADATA_REQUESTS_RATE = 450,
  API_LIMIT_MARKET_STATE_METADATA_REQUESTS_IN_PROCESSING = 451,
  API_LIMIT_MARKET_STATE_METADATA_SUBSCRIPTIONS = 452,
  API_LIMIT_INSTRUMENT_DEFINITION_REQUESTS_RATE = 460,
  API_LIMIT_EXCHANGE_METADATA_REQUESTS_IN_PROCESSING = 470,
  API_LIMIT_EXCHANGE_METADATA_SUBSCRIPTIONS = 471,
  API_LIMIT_ENTITLEMENT_REQUESTS_SUBSCRIPTIONS = 480,
  API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_RATE = 490,
  API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_IN_PROCESSING = 491,
  API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_SUBSCRIPTIONS = 492,
  API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_RATE = 500,
  API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_IN_PROCESSING = 501,
  API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_SUBSCRIPTIONS = 502,
  API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_RATE = 510,
  API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_IN_PROCESSING = 511,
  API_LIMIT_EXCHANGE_SECURITIES_SUBSCRIPTIONS = 512,
  /**
   * API_LIMIT_TRADE_ROUTING_ACCOUNTS - TradeSubscription (600-699)
   * Maximum account number per trader to process
   */
  API_LIMIT_TRADE_ROUTING_ACCOUNTS = 600,
  API_LIMIT_TRADE_SUBSCRIPTIONS = 610,
  /** API_LIMIT_TRADE_SUBSCRIPTIONS_ACCOUNTS - Maximum accounts to subscribe to */
  API_LIMIT_TRADE_SUBSCRIPTIONS_ACCOUNTS = 611,
  /** API_LIMIT_ORDER_REQUESTS_RATE - Order request (700-799) */
  API_LIMIT_ORDER_REQUESTS_RATE = 700,
  /** API_LIMIT_ORDER_REQUESTS_ACCOUNT_RATE - Rate per account */
  API_LIMIT_ORDER_REQUESTS_ACCOUNT_RATE = 701,
  /** API_LIMIT_MARKET_DATA_SUBSCRIPTION_RATE - Market data subscription (800 - 899) */
  API_LIMIT_MARKET_DATA_SUBSCRIPTION_RATE = 800,
  API_LIMIT_MARKET_DATA_SUBSCRIPTIONS = 801,
  /**
   * API_LIMIT_MARKET_DATA_DELAY_TO_CLOSE_CONNECTION - Market data quotes delay threshold (in seconds)
   * after which user will be disconnected.
   */
  API_LIMIT_MARKET_DATA_DELAY_TO_CLOSE_CONNECTION = 810,
  /** API_LIMIT_HISTORICAL_REQUESTS_IN_PROCESSING - Historical requests (1000-1299) */
  API_LIMIT_HISTORICAL_REQUESTS_IN_PROCESSING = 1000,
  API_LIMIT_HISTORICAL_SUBSCRIPTIONS = 1001,
  API_LIMIT_TIME_AND_SALES_REQUESTS_RATE = 1010,
  API_LIMIT_TIME_BAR_REQUESTS_RATE = 1020,
  /** API_LIMIT_VOLUME_PROFILE_REQUESTS_CONTRACT_AND_RANGE_RATE - Per contract and time range, not per connection */
  API_LIMIT_VOLUME_PROFILE_REQUESTS_CONTRACT_AND_RANGE_RATE = 1030,
  API_LIMIT_NON_TIMED_BAR_REQUESTS_RATE = 1040,
  /** API_LIMIT_RULES_IN_PROCESSING - Rules requests (1200-1299) */
  API_LIMIT_RULES_IN_PROCESSING = 1200,
  API_LIMIT_RULE_REQUESTS_RATE = 1201,
  /** API_LIMIT_RESERVED1 - Metadata admin request (1300-1399) */
  API_LIMIT_RESERVED1 = 1300,
  API_LIMIT_PUBLISH_UNPUBLISH_PREVIEW_CONTRACT_REQUESTS_RATE = 1301,
  API_LIMIT_PUBLISH_UNPUBLISH_PUBLISHED_CONTRACT_REQUESTS_RATE = 1302,
  API_LIMIT_GET_SECURITY_PARAMETERS_REQUESTS_RATE = 1303,
  API_LIMIT_SET_SECURITY_PARAMETERS_REQUESTS_RATE = 1304,
  API_LIMIT_GET_CONTRACT_PARAMETERS_REQUESTS_RATE = 1305,
  /** API_LIMIT_OTC_INSTANCES_SUBSCRIPTIONS - OTC request (1400-1499, 10000-10999) */
  API_LIMIT_OTC_INSTANCES_SUBSCRIPTIONS = 1400,
  API_LIMIT_HEDGE_BOOKS_SUBSCRIPTIONS = 1410,
  API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_RATE = 1420,
  API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_IN_PROCESSING = 1421,
  API_LIMIT_HEDGE_BOOK_DETAILS_SUBSCRIPTIONS = 1422,
  API_LIMIT_OFFSET_HEDGE_BALANCE_REQUESTS_RATE = 1430,
  API_LIMIT_CHANGE_OTC_STATE_REQUESTS_RATE = 1440,
  API_LIMIT_FILL_CASH_ORDER_REQUESTS_RATE = 1450,
  API_LIMIT_BALANCE_ITEMS_LINKS_REQUESTS_RATE = 1460,
  API_LIMIT_ARCHIVE_HEDGE_BALANCE_DETAILS_REQUESTS_RATE = 1470,
  API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_SUBSCRIPTIONS = 1480,
  API_LIMIT_UPDATE_TAIL_MANAGEMENT_CONFIGURATION_REQUESTS_RATE = 1490,
  API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_METADATA_REQUESTS_RATE = 10000,
  API_LIMIT_RESET_CUSTOM_BASIS_REQUESTS_RATE = 10010,
  /** API_LIMIT_RFQ_REQUESTS_ACCOUNT_RATE - RFQ request (1500-1599) */
  API_LIMIT_RFQ_REQUESTS_ACCOUNT_RATE = 1500,
  /** API_LIMIT_OPTION_CALCULATION_REQUESTS_RATE - Option calculation request (1600-1699) */
  API_LIMIT_OPTION_CALCULATION_REQUESTS_RATE = 1600,
  API_LIMIT_OPTION_CALCULATION_SUBSCRIPTIONS = 1601,
  /** API_LIMIT_RFQ_SUBSCRIPTION_REQUESTS_RATE - RFQ subscription request (1700-1799) */
  API_LIMIT_RFQ_SUBSCRIPTION_REQUESTS_RATE = 1700,
  UNRECOGNIZED = -1,
}

export function apiLimitFromJSON(object: any): ApiLimit {
  switch (object) {
    case 0:
    case "API_LIMIT_UNSPECIFIED":
      return ApiLimit.API_LIMIT_UNSPECIFIED;
    case 1:
    case "API_LIMIT_CONNECTION_RATE":
      return ApiLimit.API_LIMIT_CONNECTION_RATE;
    case 2:
    case "API_LIMIT_CONCURRENT_SUBSESSIONS":
      return ApiLimit.API_LIMIT_CONCURRENT_SUBSESSIONS;
    case 3:
    case "API_LIMIT_CONNECTIONS_PER_IP":
      return ApiLimit.API_LIMIT_CONNECTIONS_PER_IP;
    case 100:
    case "API_LIMIT_SOCKET_UNREADY_PERIOD_TO_CLOSE_CONNECTION":
      return ApiLimit.API_LIMIT_SOCKET_UNREADY_PERIOD_TO_CLOSE_CONNECTION;
    case 110:
    case "API_LIMIT_CLIENT_MESSAGES_RATE":
      return ApiLimit.API_LIMIT_CLIENT_MESSAGES_RATE;
    case 200:
    case "API_LIMIT_INFORMATION_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_INFORMATION_REQUESTS_RATE;
    case 210:
    case "API_LIMIT_ACCOUNTS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_ACCOUNTS_REQUESTS_RATE;
    case 220:
    case "API_LIMIT_SYMBOL_RESOLUTION_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_SYMBOL_RESOLUTION_REQUESTS_RATE;
    case 230:
    case "API_LIMIT_LAST_STATEMENT_BALANCES_RATE":
      return ApiLimit.API_LIMIT_LAST_STATEMENT_BALANCES_RATE;
    case 240:
    case "API_LIMIT_CURRENCY_RATES_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_CURRENCY_RATES_REQUESTS_RATE;
    case 250:
    case "API_LIMIT_SESSION_INFORMATION_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_SESSION_INFORMATION_REQUESTS_IN_PROCESSING;
    case 251:
    case "API_LIMIT_SESSION_INFORMATION_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_SESSION_INFORMATION_SUBSCRIPTIONS;
    case 260:
    case "API_LIMIT_HISTORICAL_ORDERS_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_HISTORICAL_ORDERS_REQUESTS_IN_PROCESSING;
    case 261:
    case "API_LIMIT_HISTORICAL_ORDERS_DAYS":
      return ApiLimit.API_LIMIT_HISTORICAL_ORDERS_DAYS;
    case 270:
    case "API_LIMIT_OPTION_MATURITY_LIST_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_OPTION_MATURITY_LIST_REQUESTS_IN_PROCESSING;
    case 271:
    case "API_LIMIT_OPTION_MATURITY_LIST_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_OPTION_MATURITY_LIST_SUBSCRIPTIONS;
    case 280:
    case "API_LIMIT_INSTRUMENT_GROUP_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_REQUESTS_IN_PROCESSING;
    case 281:
    case "API_LIMIT_INSTRUMENT_GROUP_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_SUBSCRIPTIONS;
    case 290:
    case "API_LIMIT_AT_THE_MONEY_STRIKE_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_AT_THE_MONEY_STRIKE_REQUESTS_IN_PROCESSING;
    case 291:
    case "API_LIMIT_AT_THE_MONEY_STRIKE_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_AT_THE_MONEY_STRIKE_SUBSCRIPTIONS;
    case 300:
    case "API_LIMIT_STRATEGY_DEFINITION_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_STRATEGY_DEFINITION_REQUESTS_RATE;
    case 310:
    case "API_LIMIT_SESSION_TIME_RANGE_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_SESSION_TIME_RANGE_REQUESTS_IN_PROCESSING;
    case 320:
    case "API_LIMIT_TRADING_DAY_TIME_RANGE_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_TRADING_DAY_TIME_RANGE_REQUESTS_IN_PROCESSING;
    case 330:
    case "API_LIMIT_ORDER_ENTITLEMENT_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_ORDER_ENTITLEMENT_REQUESTS_IN_PROCESSING;
    case 340:
    case "API_LIMIT_SYMBOL_CATEGORY_LIST_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_REQUESTS_IN_PROCESSING;
    case 341:
    case "API_LIMIT_SYMBOL_CATEGORY_LIST_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_SUBSCRIPTIONS;
    case 350:
    case "API_LIMIT_SYMBOL_CATEGORY_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_SYMBOL_CATEGORY_REQUESTS_IN_PROCESSING;
    case 351:
    case "API_LIMIT_SYMBOL_CATEGORY_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_SYMBOL_CATEGORY_SUBSCRIPTIONS;
    case 360:
    case "API_LIMIT_SYMBOL_LIST_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_SYMBOL_LIST_REQUESTS_IN_PROCESSING;
    case 361:
    case "API_LIMIT_SYMBOL_LIST_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_SYMBOL_LIST_SUBSCRIPTIONS;
    case 370:
    case "API_LIMIT_SYMBOL_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_SYMBOL_REQUESTS_RATE;
    case 371:
    case "API_LIMIT_SYMBOL_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_SYMBOL_REQUESTS_IN_PROCESSING;
    case 380:
    case "API_LIMIT_ALGO_STRATEGY_DEFINITION_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_ALGO_STRATEGY_DEFINITION_REQUESTS_IN_PROCESSING;
    case 390:
    case "API_LIMIT_API_LIMITS_SUBSCRIPTIONS_AND_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_API_LIMITS_SUBSCRIPTIONS_AND_REQUESTS_IN_PROCESSING;
    case 400:
    case "API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_RATE;
    case 401:
    case "API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_IN_PROCESSING;
    case 402:
    case "API_LIMIT_CONTRIBUTOR_METADATA_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_CONTRIBUTOR_METADATA_SUBSCRIPTIONS;
    case 410:
    case "API_LIMIT_BROKERAGE_TRADING_FEATURE_ENTITLEMENT_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_BROKERAGE_TRADING_FEATURE_ENTITLEMENT_REQUESTS_IN_PROCESSING;
    case 420:
    case "API_LIMIT_ORDER_STATUS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_ORDER_STATUS_REQUESTS_RATE;
    case 430:
    case "API_LIMIT_PRODUCT_SEARCH_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_PRODUCT_SEARCH_REQUESTS_RATE;
    case 431:
    case "API_LIMIT_PRODUCT_SEARCH_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_PRODUCT_SEARCH_REQUESTS_IN_PROCESSING;
    case 440:
    case "API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_RATE;
    case 441:
    case "API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_IN_PROCESSING;
    case 450:
    case "API_LIMIT_MARKET_STATE_METADATA_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_MARKET_STATE_METADATA_REQUESTS_RATE;
    case 451:
    case "API_LIMIT_MARKET_STATE_METADATA_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_MARKET_STATE_METADATA_REQUESTS_IN_PROCESSING;
    case 452:
    case "API_LIMIT_MARKET_STATE_METADATA_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_MARKET_STATE_METADATA_SUBSCRIPTIONS;
    case 460:
    case "API_LIMIT_INSTRUMENT_DEFINITION_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_INSTRUMENT_DEFINITION_REQUESTS_RATE;
    case 470:
    case "API_LIMIT_EXCHANGE_METADATA_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_EXCHANGE_METADATA_REQUESTS_IN_PROCESSING;
    case 471:
    case "API_LIMIT_EXCHANGE_METADATA_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_EXCHANGE_METADATA_SUBSCRIPTIONS;
    case 480:
    case "API_LIMIT_ENTITLEMENT_REQUESTS_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_ENTITLEMENT_REQUESTS_SUBSCRIPTIONS;
    case 490:
    case "API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_RATE;
    case 491:
    case "API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_IN_PROCESSING;
    case 492:
    case "API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_SUBSCRIPTIONS;
    case 500:
    case "API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_RATE;
    case 501:
    case "API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_IN_PROCESSING;
    case 502:
    case "API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_SUBSCRIPTIONS;
    case 510:
    case "API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_RATE;
    case 511:
    case "API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_IN_PROCESSING;
    case 512:
    case "API_LIMIT_EXCHANGE_SECURITIES_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_EXCHANGE_SECURITIES_SUBSCRIPTIONS;
    case 600:
    case "API_LIMIT_TRADE_ROUTING_ACCOUNTS":
      return ApiLimit.API_LIMIT_TRADE_ROUTING_ACCOUNTS;
    case 610:
    case "API_LIMIT_TRADE_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_TRADE_SUBSCRIPTIONS;
    case 611:
    case "API_LIMIT_TRADE_SUBSCRIPTIONS_ACCOUNTS":
      return ApiLimit.API_LIMIT_TRADE_SUBSCRIPTIONS_ACCOUNTS;
    case 700:
    case "API_LIMIT_ORDER_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_ORDER_REQUESTS_RATE;
    case 701:
    case "API_LIMIT_ORDER_REQUESTS_ACCOUNT_RATE":
      return ApiLimit.API_LIMIT_ORDER_REQUESTS_ACCOUNT_RATE;
    case 800:
    case "API_LIMIT_MARKET_DATA_SUBSCRIPTION_RATE":
      return ApiLimit.API_LIMIT_MARKET_DATA_SUBSCRIPTION_RATE;
    case 801:
    case "API_LIMIT_MARKET_DATA_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_MARKET_DATA_SUBSCRIPTIONS;
    case 810:
    case "API_LIMIT_MARKET_DATA_DELAY_TO_CLOSE_CONNECTION":
      return ApiLimit.API_LIMIT_MARKET_DATA_DELAY_TO_CLOSE_CONNECTION;
    case 1000:
    case "API_LIMIT_HISTORICAL_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_HISTORICAL_REQUESTS_IN_PROCESSING;
    case 1001:
    case "API_LIMIT_HISTORICAL_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_HISTORICAL_SUBSCRIPTIONS;
    case 1010:
    case "API_LIMIT_TIME_AND_SALES_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_TIME_AND_SALES_REQUESTS_RATE;
    case 1020:
    case "API_LIMIT_TIME_BAR_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_TIME_BAR_REQUESTS_RATE;
    case 1030:
    case "API_LIMIT_VOLUME_PROFILE_REQUESTS_CONTRACT_AND_RANGE_RATE":
      return ApiLimit.API_LIMIT_VOLUME_PROFILE_REQUESTS_CONTRACT_AND_RANGE_RATE;
    case 1040:
    case "API_LIMIT_NON_TIMED_BAR_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_NON_TIMED_BAR_REQUESTS_RATE;
    case 1200:
    case "API_LIMIT_RULES_IN_PROCESSING":
      return ApiLimit.API_LIMIT_RULES_IN_PROCESSING;
    case 1201:
    case "API_LIMIT_RULE_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_RULE_REQUESTS_RATE;
    case 1300:
    case "API_LIMIT_RESERVED1":
      return ApiLimit.API_LIMIT_RESERVED1;
    case 1301:
    case "API_LIMIT_PUBLISH_UNPUBLISH_PREVIEW_CONTRACT_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_PUBLISH_UNPUBLISH_PREVIEW_CONTRACT_REQUESTS_RATE;
    case 1302:
    case "API_LIMIT_PUBLISH_UNPUBLISH_PUBLISHED_CONTRACT_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_PUBLISH_UNPUBLISH_PUBLISHED_CONTRACT_REQUESTS_RATE;
    case 1303:
    case "API_LIMIT_GET_SECURITY_PARAMETERS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_GET_SECURITY_PARAMETERS_REQUESTS_RATE;
    case 1304:
    case "API_LIMIT_SET_SECURITY_PARAMETERS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_SET_SECURITY_PARAMETERS_REQUESTS_RATE;
    case 1305:
    case "API_LIMIT_GET_CONTRACT_PARAMETERS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_GET_CONTRACT_PARAMETERS_REQUESTS_RATE;
    case 1400:
    case "API_LIMIT_OTC_INSTANCES_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_OTC_INSTANCES_SUBSCRIPTIONS;
    case 1410:
    case "API_LIMIT_HEDGE_BOOKS_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_HEDGE_BOOKS_SUBSCRIPTIONS;
    case 1420:
    case "API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_RATE;
    case 1421:
    case "API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_IN_PROCESSING":
      return ApiLimit.API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_IN_PROCESSING;
    case 1422:
    case "API_LIMIT_HEDGE_BOOK_DETAILS_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_HEDGE_BOOK_DETAILS_SUBSCRIPTIONS;
    case 1430:
    case "API_LIMIT_OFFSET_HEDGE_BALANCE_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_OFFSET_HEDGE_BALANCE_REQUESTS_RATE;
    case 1440:
    case "API_LIMIT_CHANGE_OTC_STATE_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_CHANGE_OTC_STATE_REQUESTS_RATE;
    case 1450:
    case "API_LIMIT_FILL_CASH_ORDER_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_FILL_CASH_ORDER_REQUESTS_RATE;
    case 1460:
    case "API_LIMIT_BALANCE_ITEMS_LINKS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_BALANCE_ITEMS_LINKS_REQUESTS_RATE;
    case 1470:
    case "API_LIMIT_ARCHIVE_HEDGE_BALANCE_DETAILS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_ARCHIVE_HEDGE_BALANCE_DETAILS_REQUESTS_RATE;
    case 1480:
    case "API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_SUBSCRIPTIONS;
    case 1490:
    case "API_LIMIT_UPDATE_TAIL_MANAGEMENT_CONFIGURATION_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_UPDATE_TAIL_MANAGEMENT_CONFIGURATION_REQUESTS_RATE;
    case 10000:
    case "API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_METADATA_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_METADATA_REQUESTS_RATE;
    case 10010:
    case "API_LIMIT_RESET_CUSTOM_BASIS_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_RESET_CUSTOM_BASIS_REQUESTS_RATE;
    case 1500:
    case "API_LIMIT_RFQ_REQUESTS_ACCOUNT_RATE":
      return ApiLimit.API_LIMIT_RFQ_REQUESTS_ACCOUNT_RATE;
    case 1600:
    case "API_LIMIT_OPTION_CALCULATION_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_OPTION_CALCULATION_REQUESTS_RATE;
    case 1601:
    case "API_LIMIT_OPTION_CALCULATION_SUBSCRIPTIONS":
      return ApiLimit.API_LIMIT_OPTION_CALCULATION_SUBSCRIPTIONS;
    case 1700:
    case "API_LIMIT_RFQ_SUBSCRIPTION_REQUESTS_RATE":
      return ApiLimit.API_LIMIT_RFQ_SUBSCRIPTION_REQUESTS_RATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ApiLimit.UNRECOGNIZED;
  }
}

export function apiLimitToJSON(object: ApiLimit): string {
  switch (object) {
    case ApiLimit.API_LIMIT_UNSPECIFIED:
      return "API_LIMIT_UNSPECIFIED";
    case ApiLimit.API_LIMIT_CONNECTION_RATE:
      return "API_LIMIT_CONNECTION_RATE";
    case ApiLimit.API_LIMIT_CONCURRENT_SUBSESSIONS:
      return "API_LIMIT_CONCURRENT_SUBSESSIONS";
    case ApiLimit.API_LIMIT_CONNECTIONS_PER_IP:
      return "API_LIMIT_CONNECTIONS_PER_IP";
    case ApiLimit.API_LIMIT_SOCKET_UNREADY_PERIOD_TO_CLOSE_CONNECTION:
      return "API_LIMIT_SOCKET_UNREADY_PERIOD_TO_CLOSE_CONNECTION";
    case ApiLimit.API_LIMIT_CLIENT_MESSAGES_RATE:
      return "API_LIMIT_CLIENT_MESSAGES_RATE";
    case ApiLimit.API_LIMIT_INFORMATION_REQUESTS_RATE:
      return "API_LIMIT_INFORMATION_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_ACCOUNTS_REQUESTS_RATE:
      return "API_LIMIT_ACCOUNTS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_SYMBOL_RESOLUTION_REQUESTS_RATE:
      return "API_LIMIT_SYMBOL_RESOLUTION_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_LAST_STATEMENT_BALANCES_RATE:
      return "API_LIMIT_LAST_STATEMENT_BALANCES_RATE";
    case ApiLimit.API_LIMIT_CURRENCY_RATES_REQUESTS_RATE:
      return "API_LIMIT_CURRENCY_RATES_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_SESSION_INFORMATION_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_SESSION_INFORMATION_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_SESSION_INFORMATION_SUBSCRIPTIONS:
      return "API_LIMIT_SESSION_INFORMATION_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_HISTORICAL_ORDERS_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_HISTORICAL_ORDERS_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_HISTORICAL_ORDERS_DAYS:
      return "API_LIMIT_HISTORICAL_ORDERS_DAYS";
    case ApiLimit.API_LIMIT_OPTION_MATURITY_LIST_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_OPTION_MATURITY_LIST_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_OPTION_MATURITY_LIST_SUBSCRIPTIONS:
      return "API_LIMIT_OPTION_MATURITY_LIST_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_INSTRUMENT_GROUP_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_SUBSCRIPTIONS:
      return "API_LIMIT_INSTRUMENT_GROUP_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_AT_THE_MONEY_STRIKE_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_AT_THE_MONEY_STRIKE_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_AT_THE_MONEY_STRIKE_SUBSCRIPTIONS:
      return "API_LIMIT_AT_THE_MONEY_STRIKE_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_STRATEGY_DEFINITION_REQUESTS_RATE:
      return "API_LIMIT_STRATEGY_DEFINITION_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_SESSION_TIME_RANGE_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_SESSION_TIME_RANGE_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_TRADING_DAY_TIME_RANGE_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_TRADING_DAY_TIME_RANGE_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_ORDER_ENTITLEMENT_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_ORDER_ENTITLEMENT_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_SYMBOL_CATEGORY_LIST_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_SUBSCRIPTIONS:
      return "API_LIMIT_SYMBOL_CATEGORY_LIST_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_SYMBOL_CATEGORY_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_SYMBOL_CATEGORY_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_SYMBOL_CATEGORY_SUBSCRIPTIONS:
      return "API_LIMIT_SYMBOL_CATEGORY_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_SYMBOL_LIST_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_SYMBOL_LIST_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_SYMBOL_LIST_SUBSCRIPTIONS:
      return "API_LIMIT_SYMBOL_LIST_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_SYMBOL_REQUESTS_RATE:
      return "API_LIMIT_SYMBOL_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_SYMBOL_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_SYMBOL_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_ALGO_STRATEGY_DEFINITION_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_ALGO_STRATEGY_DEFINITION_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_API_LIMITS_SUBSCRIPTIONS_AND_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_API_LIMITS_SUBSCRIPTIONS_AND_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_RATE:
      return "API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_CONTRIBUTOR_METADATA_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_CONTRIBUTOR_METADATA_SUBSCRIPTIONS:
      return "API_LIMIT_CONTRIBUTOR_METADATA_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_BROKERAGE_TRADING_FEATURE_ENTITLEMENT_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_BROKERAGE_TRADING_FEATURE_ENTITLEMENT_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_ORDER_STATUS_REQUESTS_RATE:
      return "API_LIMIT_ORDER_STATUS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_PRODUCT_SEARCH_REQUESTS_RATE:
      return "API_LIMIT_PRODUCT_SEARCH_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_PRODUCT_SEARCH_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_PRODUCT_SEARCH_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_RATE:
      return "API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_SYMBOL_CATEGORY_LIST_BY_INSTRUMENT_TYPE_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_MARKET_STATE_METADATA_REQUESTS_RATE:
      return "API_LIMIT_MARKET_STATE_METADATA_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_MARKET_STATE_METADATA_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_MARKET_STATE_METADATA_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_MARKET_STATE_METADATA_SUBSCRIPTIONS:
      return "API_LIMIT_MARKET_STATE_METADATA_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_INSTRUMENT_DEFINITION_REQUESTS_RATE:
      return "API_LIMIT_INSTRUMENT_DEFINITION_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_EXCHANGE_METADATA_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_EXCHANGE_METADATA_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_EXCHANGE_METADATA_SUBSCRIPTIONS:
      return "API_LIMIT_EXCHANGE_METADATA_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_ENTITLEMENT_REQUESTS_SUBSCRIPTIONS:
      return "API_LIMIT_ENTITLEMENT_REQUESTS_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_RATE:
      return "API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_SUBSCRIPTIONS:
      return "API_LIMIT_INSTRUMENT_GROUP_BY_SECURITIES_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_RATE:
      return "API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_SUBSCRIPTIONS:
      return "API_LIMIT_INSTRUMENT_GROUP_BY_EXCHANGE_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_RATE:
      return "API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_EXCHANGE_SECURITIES_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_EXCHANGE_SECURITIES_SUBSCRIPTIONS:
      return "API_LIMIT_EXCHANGE_SECURITIES_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_TRADE_ROUTING_ACCOUNTS:
      return "API_LIMIT_TRADE_ROUTING_ACCOUNTS";
    case ApiLimit.API_LIMIT_TRADE_SUBSCRIPTIONS:
      return "API_LIMIT_TRADE_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_TRADE_SUBSCRIPTIONS_ACCOUNTS:
      return "API_LIMIT_TRADE_SUBSCRIPTIONS_ACCOUNTS";
    case ApiLimit.API_LIMIT_ORDER_REQUESTS_RATE:
      return "API_LIMIT_ORDER_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_ORDER_REQUESTS_ACCOUNT_RATE:
      return "API_LIMIT_ORDER_REQUESTS_ACCOUNT_RATE";
    case ApiLimit.API_LIMIT_MARKET_DATA_SUBSCRIPTION_RATE:
      return "API_LIMIT_MARKET_DATA_SUBSCRIPTION_RATE";
    case ApiLimit.API_LIMIT_MARKET_DATA_SUBSCRIPTIONS:
      return "API_LIMIT_MARKET_DATA_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_MARKET_DATA_DELAY_TO_CLOSE_CONNECTION:
      return "API_LIMIT_MARKET_DATA_DELAY_TO_CLOSE_CONNECTION";
    case ApiLimit.API_LIMIT_HISTORICAL_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_HISTORICAL_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_HISTORICAL_SUBSCRIPTIONS:
      return "API_LIMIT_HISTORICAL_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_TIME_AND_SALES_REQUESTS_RATE:
      return "API_LIMIT_TIME_AND_SALES_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_TIME_BAR_REQUESTS_RATE:
      return "API_LIMIT_TIME_BAR_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_VOLUME_PROFILE_REQUESTS_CONTRACT_AND_RANGE_RATE:
      return "API_LIMIT_VOLUME_PROFILE_REQUESTS_CONTRACT_AND_RANGE_RATE";
    case ApiLimit.API_LIMIT_NON_TIMED_BAR_REQUESTS_RATE:
      return "API_LIMIT_NON_TIMED_BAR_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_RULES_IN_PROCESSING:
      return "API_LIMIT_RULES_IN_PROCESSING";
    case ApiLimit.API_LIMIT_RULE_REQUESTS_RATE:
      return "API_LIMIT_RULE_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_RESERVED1:
      return "API_LIMIT_RESERVED1";
    case ApiLimit.API_LIMIT_PUBLISH_UNPUBLISH_PREVIEW_CONTRACT_REQUESTS_RATE:
      return "API_LIMIT_PUBLISH_UNPUBLISH_PREVIEW_CONTRACT_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_PUBLISH_UNPUBLISH_PUBLISHED_CONTRACT_REQUESTS_RATE:
      return "API_LIMIT_PUBLISH_UNPUBLISH_PUBLISHED_CONTRACT_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_GET_SECURITY_PARAMETERS_REQUESTS_RATE:
      return "API_LIMIT_GET_SECURITY_PARAMETERS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_SET_SECURITY_PARAMETERS_REQUESTS_RATE:
      return "API_LIMIT_SET_SECURITY_PARAMETERS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_GET_CONTRACT_PARAMETERS_REQUESTS_RATE:
      return "API_LIMIT_GET_CONTRACT_PARAMETERS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_OTC_INSTANCES_SUBSCRIPTIONS:
      return "API_LIMIT_OTC_INSTANCES_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_HEDGE_BOOKS_SUBSCRIPTIONS:
      return "API_LIMIT_HEDGE_BOOKS_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_RATE:
      return "API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_IN_PROCESSING:
      return "API_LIMIT_HEDGE_BOOK_DETAILS_REQUESTS_IN_PROCESSING";
    case ApiLimit.API_LIMIT_HEDGE_BOOK_DETAILS_SUBSCRIPTIONS:
      return "API_LIMIT_HEDGE_BOOK_DETAILS_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_OFFSET_HEDGE_BALANCE_REQUESTS_RATE:
      return "API_LIMIT_OFFSET_HEDGE_BALANCE_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_CHANGE_OTC_STATE_REQUESTS_RATE:
      return "API_LIMIT_CHANGE_OTC_STATE_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_FILL_CASH_ORDER_REQUESTS_RATE:
      return "API_LIMIT_FILL_CASH_ORDER_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_BALANCE_ITEMS_LINKS_REQUESTS_RATE:
      return "API_LIMIT_BALANCE_ITEMS_LINKS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_ARCHIVE_HEDGE_BALANCE_DETAILS_REQUESTS_RATE:
      return "API_LIMIT_ARCHIVE_HEDGE_BALANCE_DETAILS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_SUBSCRIPTIONS:
      return "API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_UPDATE_TAIL_MANAGEMENT_CONFIGURATION_REQUESTS_RATE:
      return "API_LIMIT_UPDATE_TAIL_MANAGEMENT_CONFIGURATION_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_METADATA_REQUESTS_RATE:
      return "API_LIMIT_TAIL_MANAGEMENT_CONFIGURATION_METADATA_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_RESET_CUSTOM_BASIS_REQUESTS_RATE:
      return "API_LIMIT_RESET_CUSTOM_BASIS_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_RFQ_REQUESTS_ACCOUNT_RATE:
      return "API_LIMIT_RFQ_REQUESTS_ACCOUNT_RATE";
    case ApiLimit.API_LIMIT_OPTION_CALCULATION_REQUESTS_RATE:
      return "API_LIMIT_OPTION_CALCULATION_REQUESTS_RATE";
    case ApiLimit.API_LIMIT_OPTION_CALCULATION_SUBSCRIPTIONS:
      return "API_LIMIT_OPTION_CALCULATION_SUBSCRIPTIONS";
    case ApiLimit.API_LIMIT_RFQ_SUBSCRIPTION_REQUESTS_RATE:
      return "API_LIMIT_RFQ_SUBSCRIPTION_REQUESTS_RATE";
    case ApiLimit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ApiLimitEntryStatusCode {
  /** API_LIMIT_ENTRY_STATUS_CODE_UNSPECIFIED - Entry not specified and must be ignored. */
  API_LIMIT_ENTRY_STATUS_CODE_UNSPECIFIED = 0,
  /** API_LIMIT_ENTRY_STATUS_CODE_SUCCESS - Value is populated. */
  API_LIMIT_ENTRY_STATUS_CODE_SUCCESS = 1,
  /**
   * API_LIMIT_ENTRY_STATUS_CODE_FAILURE - Failure codes (100+)
   * General failure, client should use the default value
   * (from proto file comments, descriptions, etc.)
   */
  API_LIMIT_ENTRY_STATUS_CODE_FAILURE = 101,
  /** API_LIMIT_ENTRY_STATUS_CODE_NOT_FOUND - Limit type not found or already deleted (value is not restricted). */
  API_LIMIT_ENTRY_STATUS_CODE_NOT_FOUND = 104,
  UNRECOGNIZED = -1,
}

export function apiLimitEntryStatusCodeFromJSON(object: any): ApiLimitEntryStatusCode {
  switch (object) {
    case 0:
    case "API_LIMIT_ENTRY_STATUS_CODE_UNSPECIFIED":
      return ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_UNSPECIFIED;
    case 1:
    case "API_LIMIT_ENTRY_STATUS_CODE_SUCCESS":
      return ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_SUCCESS;
    case 101:
    case "API_LIMIT_ENTRY_STATUS_CODE_FAILURE":
      return ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_FAILURE;
    case 104:
    case "API_LIMIT_ENTRY_STATUS_CODE_NOT_FOUND":
      return ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_NOT_FOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ApiLimitEntryStatusCode.UNRECOGNIZED;
  }
}

export function apiLimitEntryStatusCodeToJSON(object: ApiLimitEntryStatusCode): string {
  switch (object) {
    case ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_UNSPECIFIED:
      return "API_LIMIT_ENTRY_STATUS_CODE_UNSPECIFIED";
    case ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_SUCCESS:
      return "API_LIMIT_ENTRY_STATUS_CODE_SUCCESS";
    case ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_FAILURE:
      return "API_LIMIT_ENTRY_STATUS_CODE_FAILURE";
    case ApiLimitEntryStatusCode.API_LIMIT_ENTRY_STATUS_CODE_NOT_FOUND:
      return "API_LIMIT_ENTRY_STATUS_CODE_NOT_FOUND";
    case ApiLimitEntryStatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ApiLimitRequest {
  /**
   * Limit types to request.
   * This field is associated with ApiLimit enum type.
   * If empty, all supported limits will be returned.
   */
  limits: number[];
}

export interface ApiLimitEntry {
  /**
   * Limit type.
   * This field is associated with ApiLimit enum type.
   */
  limit?:
    | number
    | undefined;
  /**
   * Result status of the request.
   * This field is associated with ApiLimitEntryStatusCode enum type.
   */
  statusCode?:
    | number
    | undefined;
  /** Limit value. */
  value?:
    | number
    | undefined;
  /** Populated if the limit is a rate = value / period_sec. */
  periodSec?:
    | number
    | undefined;
  /** Limit description. */
  description?: Text | undefined;
}

export interface ApiLimitReport {
  /** Limit output entries. */
  limitEntries: ApiLimitEntry[];
}

function createBaseApiLimitRequest(): ApiLimitRequest {
  return { limits: [] };
}

export const ApiLimitRequest = {
  encode(message: ApiLimitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.limits) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiLimitRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiLimitRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.limits.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.limits.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiLimitRequest {
    return {
      limits: globalThis.Array.isArray(object?.limits) ? object.limits.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: ApiLimitRequest): unknown {
    const obj: any = {};
    if (message.limits?.length) {
      obj.limits = message.limits.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiLimitRequest>, I>>(base?: I): ApiLimitRequest {
    return ApiLimitRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiLimitRequest>, I>>(object: I): ApiLimitRequest {
    const message = createBaseApiLimitRequest();
    message.limits = object.limits?.map((e) => e) || [];
    return message;
  },
};

function createBaseApiLimitEntry(): ApiLimitEntry {
  return { limit: 0, statusCode: 0, value: 0, periodSec: 0, description: undefined };
}

export const ApiLimitEntry = {
  encode(message: ApiLimitEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.limit !== undefined && message.limit !== 0) {
      writer.uint32(8).uint32(message.limit);
    }
    if (message.statusCode !== undefined && message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.value !== undefined && message.value !== 0) {
      writer.uint32(24).uint32(message.value);
    }
    if (message.periodSec !== undefined && message.periodSec !== 0) {
      writer.uint32(32).uint32(message.periodSec);
    }
    if (message.description !== undefined) {
      Text.encode(message.description, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiLimitEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiLimitEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.statusCode = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.value = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.periodSec = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiLimitEntry {
    return {
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      statusCode: isSet(object.statusCode) ? globalThis.Number(object.statusCode) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      periodSec: isSet(object.periodSec) ? globalThis.Number(object.periodSec) : 0,
      description: isSet(object.description) ? Text.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ApiLimitEntry): unknown {
    const obj: any = {};
    if (message.limit !== undefined && message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.statusCode !== undefined && message.statusCode !== 0) {
      obj.statusCode = Math.round(message.statusCode);
    }
    if (message.value !== undefined && message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    if (message.periodSec !== undefined && message.periodSec !== 0) {
      obj.periodSec = Math.round(message.periodSec);
    }
    if (message.description !== undefined) {
      obj.description = Text.toJSON(message.description);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiLimitEntry>, I>>(base?: I): ApiLimitEntry {
    return ApiLimitEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiLimitEntry>, I>>(object: I): ApiLimitEntry {
    const message = createBaseApiLimitEntry();
    message.limit = object.limit ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.value = object.value ?? 0;
    message.periodSec = object.periodSec ?? 0;
    message.description = (object.description !== undefined && object.description !== null)
      ? Text.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseApiLimitReport(): ApiLimitReport {
  return { limitEntries: [] };
}

export const ApiLimitReport = {
  encode(message: ApiLimitReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.limitEntries) {
      ApiLimitEntry.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiLimitReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiLimitReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.limitEntries.push(ApiLimitEntry.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiLimitReport {
    return {
      limitEntries: globalThis.Array.isArray(object?.limitEntries)
        ? object.limitEntries.map((e: any) => ApiLimitEntry.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApiLimitReport): unknown {
    const obj: any = {};
    if (message.limitEntries?.length) {
      obj.limitEntries = message.limitEntries.map((e) => ApiLimitEntry.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiLimitReport>, I>>(base?: I): ApiLimitReport {
    return ApiLimitReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiLimitReport>, I>>(object: I): ApiLimitReport {
    const message = createBaseApiLimitReport();
    message.limitEntries = object.limitEntries?.map((e) => ApiLimitEntry.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

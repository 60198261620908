export class Deferred<T> {
  private _resolve!: (value: T | PromiseLike<T>) => void;
  private _reject!: (reason: any) => void;
  private _promise: Promise<T>;

  public state: string = "pending";

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  get promise(): Promise<T> {
    return this._promise;
  }

  resolve(value: T): void {
    this._resolve(value);
  }

  reject(reason?: any): void {
    this._reject(reason);
  }
}

// Example usage:
//   const defer = new Deferred<string>();

//   // Simulating an asynchronous operation
//   setTimeout(() => {
//     const success = true; // Simulated success
//     if (success) {
//       defer.resolve('Operation completed successfully.');
//     } else {
//       defer.reject('Operation failed.');
//     }
//   }, 1000);

//   // Using the deferred promise
//   defer.promise
//     .then(result => {
//       console.log('Success:', result);
//     })
//     .catch(error => {
//       console.error('Error:', error);
//     });

import React, { useCallback, useState } from "react";
import { Widget, Workspace } from "../../../../types";
import ConfirmDialog from "../../../shared/ConfirmDialog";
import AddNewWorkspace from "./workspace/AddNewWorkSpace";
import RenameDialog from "./RenameDialog";
import WorkspaceTab from "./WorkspaceTab";
import WorkspaceMenu from "./WorkspaceMenu";
import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

type WorkspaceTabsProps = {
  activeWorkspace?: Workspace;
  workSpaces: Workspace[];
  onActiveWorkSpaceChange: (workspace: Workspace) => void;
  onDeleteWorkspace: (workspace: Workspace) => Promise<void>;
  deleteWorkspaceLoading: boolean;
  handleNewWorkspaceCreated: (workspace: Workspace, widgets: Widget[]) => void;
  newWorkspace: boolean;
  setNewWorkspace: React.Dispatch<React.SetStateAction<boolean>>;
  showWidgetSelectionDialog: boolean;
  setShowWidgetSelectionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onNewWorkspaceRequested: () => void;
  handleRenameWorkspace: (workspace: Workspace, newTitle: string) => Promise<void>;
  updateWorkspaceLoaded: boolean;
  onSetDefaultWorkspace: (workspace: Workspace) => void;
  onDuplicateWorkspace: (workspace: Workspace) => void;
  isSidebarOpen: boolean;
  onSidebarToggle: () => void; 
};

const WorkspaceTabs = ({
  activeWorkspace,
  workSpaces,
  onActiveWorkSpaceChange,
  onDeleteWorkspace,
  deleteWorkspaceLoading,
  handleNewWorkspaceCreated,
  newWorkspace,
  setNewWorkspace,
  showWidgetSelectionDialog,
  setShowWidgetSelectionDialog,
  onNewWorkspaceRequested,
  handleRenameWorkspace,
  updateWorkspaceLoaded,
  onSetDefaultWorkspace,
  onDuplicateWorkspace,
  isSidebarOpen,
  onSidebarToggle
}: WorkspaceTabsProps) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [renameWorkspace, setRenameWorkspace] = useState<Workspace | null>(null);
  const [renameTitle, setRenameTitle] = useState<string>('');
  const [duplicateConfirmDialog, setDuplicateConfirmDialog] = useState(false)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, workspace: Workspace) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedWorkspace(workspace);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenDialog = () => {
    setOpenConfirmDialog(true);
    handleMenuClose();
  };

  const handleCloseDialog = useCallback(() => {
    setOpenConfirmDialog(false);
    setDuplicateConfirmDialog(false);
  },[]);

  const onHandleDeleteWorkspace = useCallback(async () => {
    if (selectedWorkspace) {
      await onDeleteWorkspace(selectedWorkspace);
      setSelectedWorkspace(null);
      handleCloseDialog();
    }
  }, [selectedWorkspace, onDeleteWorkspace, handleCloseDialog]);

  const handleRename = useCallback(
    (workspace: Workspace) => {
      setRenameTitle(workspace.title || "");
      setRenameWorkspace(workspace);
      setOpenRenameDialog(true);
      handleMenuClose();
    },
    [handleMenuClose],
  );

  const handleRenameSubmit = useCallback(
    async (newTitle: string) => {
      if (renameWorkspace) {
        await handleRenameWorkspace(renameWorkspace, newTitle);
        setOpenRenameDialog(false);
      }
    },
    [handleRenameWorkspace, renameWorkspace],
  );

  const handleRenameDialogClose = useCallback(() => {
    setOpenRenameDialog(false);
  }, []);

  const handleRenameClick = useCallback(() => {
    if (selectedWorkspace) {
      handleRename(selectedWorkspace);
    }
  }, [selectedWorkspace, handleRename]);

  const handleDuplicateClick = useCallback(() => {
    setDuplicateConfirmDialog(true);
    handleMenuClose();
  },[handleMenuClose]);
  
  const onHandleDuplicateWorkspace = useCallback(() => {
    if (selectedWorkspace) {
      onDuplicateWorkspace(selectedWorkspace);
      setDuplicateConfirmDialog(false);
    }
  },[selectedWorkspace, onDuplicateWorkspace]);

  const handleChangeDefault = () => {
    if(selectedWorkspace) {
      onSetDefaultWorkspace(selectedWorkspace);
      handleMenuClose();
    }
  }

  return (
    <>
      <div className="Tabs">
      {!isSidebarOpen && (
        <>
          <div style={{ backgroundColor: "#fff", borderRight: "1px solid var(--divider, rgba(0, 0, 0, 0.12))" }}>
            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "2px solid rgba(0, 0, 0, 0.12)"
            }}>
              <IconButton className="userMoreInfo" onClick={onSidebarToggle}>
                <ChevronRight />
              </IconButton>
            </div>
          </div>
        </>
      )}
        {workSpaces.map((workspace) => (
          <WorkspaceTab
            key={workspace.id}
            workspace={workspace}
            active={activeWorkspace?.id === workspace.id}
            onClick={() => {
              onActiveWorkSpaceChange(workspace);
              setNewWorkspace(false);
            }}
            onMenuOpen={(e) => handleMenuOpen(e, workspace)}
          />
        ))}
        <AddNewWorkspace
          newWorkspace={newWorkspace}
          setNewWorkspace={setNewWorkspace}
          showWidgetSelectionDialog={showWidgetSelectionDialog}
          setShowWidgetSelectionDialog={setShowWidgetSelectionDialog}
          onNewWorkspaceCreated={handleNewWorkspaceCreated}
          onNewWorkspaceRequested={onNewWorkspaceRequested}
        />
      </div>

      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={handleCloseDialog}
        onDelete={onHandleDeleteWorkspace}
        deleteWorkspace={selectedWorkspace}
        loading={deleteWorkspaceLoading}
        confirmText={"Are you sure you want to remove"}
      />

      {duplicateConfirmDialog && 
        <ConfirmDialog
          open={duplicateConfirmDialog}
          handleClose={handleCloseDialog}
          onDelete={onHandleDuplicateWorkspace}
          deleteWorkspace={selectedWorkspace}
          loading={deleteWorkspaceLoading}
          confirmText={"Are you sure you want to duplicate"}
        />
      }

      {openRenameDialog && (
        <RenameDialog
          open={openRenameDialog}
          onClose={handleRenameDialogClose}
          onRename={handleRenameSubmit}
          currentTitle={renameTitle}
          loading={!updateWorkspaceLoaded}
        />
      )}

      <WorkspaceMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onRenameClick={handleRenameClick}
        onDeleteClick={handleOpenDialog}
        onChangeDefaultClick={handleChangeDefault}
        onDuplicateClick={handleDuplicateClick}
      />
    </>
  );
};

export default WorkspaceTabs;
import "./productsNav.css";
import MenuItem from "../Menutem";
// import { Watch, ViewListRounded, ViewColumn, SwapHoriz, Newspaper } from "@mui/icons-material";
import { widgets } from "../../../workspace/mockData";
import { Widget } from "../../../../types";

// const items = [
//   { text: "Market", icon: ViewListRounded },
//   { text: "Watchlists", icon: Watch },
//   { text: "Chart", icon: "/images/icons/Chart.svg" },
//   { text: "Order Ticket", icon: "/images/icons/order-ticket.svg" },
//   { text: "Price Ladder", icon: ViewColumn },
//   { text: "Positions", icon: "/images/icons/Icon.svg" },
//   { text: "Orders", icon: SwapHoriz },
//   { text: "Performance", icon: "/images/icons/LeftContent.svg" },
//   { text: "News", icon: Newspaper },
// ];

const ProductList = ({ onRequestToAddNewWidget, handleAddChart }: ProductsNavProps) => {

  const addWidgetHandler = (widget: Widget) => {
    onRequestToAddNewWidget(widget);
  };

  return (
    <ul>
      {widgets.map((item, index) => {
        // if (item.WidgetType === "Chart") {
        //   return (
        //     <li key={index} style={{ cursor: "pointer" }} onClick={handleAddChart}>
        //     <MenuItem text={item.title} icon={item.icon} />
        //   </li>
        //   )
        // }
        if (['News', 'Price Ladder'].includes(item.WidgetType)) {
          return (
            <li key={index} style={{ cursor: "pointer" }}>
              <MenuItem text={item.title} icon={item.icon} />
            </li>
          );
        }
        return (
        <li key={index} style={{ cursor: "pointer" }} onClick={() => addWidgetHandler(item)}>
          <MenuItem text={item.title} icon={item.icon} />
        </li>
      )})}
    </ul>
  );
};
type ProductsNavProps = {onRequestToAddNewWidget: (widget: Widget) => void; handleAddChart: () => void};
const ProductsNav = ({ onRequestToAddNewWidget, handleAddChart }: ProductsNavProps) => (
  <div className="productsNav">
    <h3 className="heading">ADD WIDGETS</h3>
    <ProductList handleAddChart={handleAddChart} onRequestToAddNewWidget={onRequestToAddNewWidget} />
  </div>
);

export default ProductsNav;

import { useCallback, useState } from "react";
import { Product } from "../../types";
import axios, { AxiosError } from "axios";
import Notification from "../../components/shared/notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import productsActions from "../../redux/products/productActions";
import { getProducts } from "../../redux/products/productSelector";

export const useFetchProducts = () => {
  const savedProducts = useSelector(getProducts);
  const [products, setProducts] = useState<Product[]>(savedProducts);
  const dispatch = useDispatch();
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const loadProducts = useCallback(async () => {
    try {
      if (savedProducts.length < 1) {
        const response = await axios.get<Product[]>("products");
        setProducts(response.data);
        dispatch(productsActions.dispatchSetProduct(response.data));
      }
    } catch (error: any) {
      setError(error);
      setProducts([]);
      Notification.error(`Could not load products, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, []);

  return { products, error, loaded, loadProducts, setProducts };
};

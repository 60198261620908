import EventEmitter from "events";

export type OrderTypeNullable = "MKT" | "LMT" | "STP" | "STL" | "CROSS" | "UNRECOGNIZED" | null;

export type numberNullable = number | null | undefined;

export type stringNullable = string | null | undefined;

export type booleanNullable = boolean | null | undefined;

export type objectNullable = {} | object | null | undefined;

export type dateNullable = Date | null | undefined;

export class onServiceMessageEvent extends EventEmitter {}

export class onAppMessageEvent extends EventEmitter {}
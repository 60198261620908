import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { styled as muiStyled } from "@mui/material/styles";

const StyledGridOverlay = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

export const dataGridStyles = {
  '& .MuiDataGrid-cell': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
  },
  '& .MuiDataGrid-columnHeader': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '.MuiDataGrid-columnSeparator': {
    visibility: 'visible !important',
    opacity: '1 !important',
  },
  ".MuiDataGrid-columnSeparator--resizable:hover": {
    color: "rgb(0 0 0 / 40%)"
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none'
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none'
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
};

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box style={{ color: "#5A6874" }}>No Data</Box>
    </StyledGridOverlay>
  );
}

const DataGridTable = ({ columns, rows }: any) => {
  return (
    <Box sx={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}>
      <DataGrid
        rowHeight={42}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
        }}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        sx={dataGridStyles}
      />
    </Box>
  );
};

export default DataGridTable;

// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: webapi_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import {
  AccountLogoff,
  AccountLogoffResult,
  AccountLogon,
  AccountLogonResult,
  AccountPasswordChange,
  AccountPasswordChangeResult,
} from "./account_authorization_2";
import { ApiLimitReport, ApiLimitRequest } from "./api_limit_2";
import {
  NonTimedBarReport,
  NonTimedBarRequest,
  TimeAndSalesReport,
  TimeAndSalesRequest,
  TimeBarReport,
  TimeBarRequest,
  VolumeProfileReport,
  VolumeProfileRequest,
} from "./historical_2";
import { InstrumentDefinitionReport, InstrumentDefinitionRequest } from "./instrument_definition_2";
import {
  AtTheMoneyStrikeReport,
  AtTheMoneyStrikeRequest,
  MarketDataSubscription,
  MarketDataSubscriptionStatus,
  OptionCalculationReport,
  OptionCalculationRequest,
  RealTimeMarketData,
  RFQReport,
  RFQRequest,
} from "./market_data_2";
import {
  ContractMetadataReport,
  ContractMetadataRequest,
  ContributorMetadataReport,
  ContributorMetadataRequest,
  ExchangeMetadataReport,
  ExchangeMetadataRequest,
  ExchangeSecuritiesReport,
  ExchangeSecuritiesRequest,
  InstrumentGroupByExchangeReport,
  InstrumentGroupByExchangeRequest,
  InstrumentGroupBySecuritiesReport,
  InstrumentGroupBySecuritiesRequest,
  InstrumentGroupReport,
  InstrumentGroupRequest,
  MarketStateMetadataReport,
  MarketStateMetadataRequest,
  OptionMaturityListReport,
  OptionMaturityListRequest,
  SymbolResolutionReport,
  SymbolResolutionRequest,
} from "./metadata_2";
import { MetadataAdminRequest, MetadataAdminResult } from "./metadata_admin_2";
import {
  OrderEntitlementReport,
  OrderEntitlementRequest,
  OrderRequest,
  OrderRequestAck,
  OrderRequestReject,
  OrderStatus,
  OrderStatusReport,
  OrderStatusRequest,
} from "./order_2";
import { OtcReport, OtcRequest } from "./otc_1";
import { RuleRequest, RuleResult } from "./rules_1";
import {
  AlgoStrategyDefinitionReport,
  AlgoStrategyDefinitionRequest,
  StrategyDefinitionReport,
  StrategyDefinitionRequest,
} from "./strategy_2";
import {
  ProductSearchReport,
  ProductSearchRequest,
  SymbolCategoryListByInstrumentTypeReport,
  SymbolCategoryListByInstrumentTypeRequest,
  SymbolCategoryListReport,
  SymbolCategoryListRequest,
  SymbolCategoryReport,
  SymbolCategoryRequest,
  SymbolListReport,
  SymbolListRequest,
  SymbolReport,
  SymbolRequest,
} from "./symbol_browsing_2";
import {
  AccountSummaryStatus,
  BrokerageTradingFeatureEntitlementReport,
  BrokerageTradingFeatureEntitlementRequest,
  CareAutoActivationParametersReport,
  CareAutoActivationParametersRequest,
  CollateralStatus,
  HistoricalOrdersReport,
  HistoricalOrdersRequest,
  PositionStatus,
  TradeSnapshotCompletion,
  TradeSubscription,
  TradeSubscriptionStatus,
} from "./trade_routing_2";
import {
  AccountsReport,
  AccountsRequest,
  CurrencyRatesReport,
  CurrencyRatesRequest,
  LastStatementBalancesReport,
  LastStatementBalancesRequest,
} from "./trading_account_2";
import {
  SessionInformationReport,
  SessionInformationRequest,
  SessionTimeRangeReport,
  SessionTimeRangeRequest,
  TradingDayTimeRangeReport,
  TradingDayTimeRangeRequest,
} from "./trading_session_2";
import {
  ConcurrentConnectionJoin,
  LoggedOff,
  Logoff,
  Logon,
  LogonResult,
  LogonRoutineClient,
  LogonRoutineServer,
  ObtainDemoCredentials,
  ObtainDemoCredentialsResult,
  PasswordChange,
  PasswordChangeResult,
  PasswordChangeRoutineClient,
  PasswordChangeRoutineServer,
  Ping,
  Pong,
  RestoreOrJoinSession,
  RestoreOrJoinSessionResult,
  SetCollapsingLevelRequest,
  SetCollapsingLevelResult,
  UserMessage,
  UserSessionStateRequest,
  UserSessionStateResult,
} from "./user_session_2";

export const protobufPackage = "WebAPI_2";

export enum ProtocolVersionMajor {
  /**
   * PROTOCOL_VERSION_MAJOR - Major number change is required in the next cases (new 'proto' file is necessary):
   * 1) Protocol is not binary backward compatible with previous versions.
   * 2) Protocol has lots of changes, which affect to clients' compilation.
   */
  PROTOCOL_VERSION_MAJOR = 2,
  UNRECOGNIZED = -1,
}

export function protocolVersionMajorFromJSON(object: any): ProtocolVersionMajor {
  switch (object) {
    case 2:
    case "PROTOCOL_VERSION_MAJOR":
      return ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProtocolVersionMajor.UNRECOGNIZED;
  }
}

export function protocolVersionMajorToJSON(object: ProtocolVersionMajor): string {
  switch (object) {
    case ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR:
      return "PROTOCOL_VERSION_MAJOR";
    case ProtocolVersionMajor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProtocolVersionMinor {
  /**
   * PROTOCOL_VERSION_MINOR - Minor number is increased for binary backward compatible protocol versions when new messages and/ or fields are
   * added without removing/ changing any existing messages and fields (new 'proto' file is not created)
   */
  PROTOCOL_VERSION_MINOR = 189,
  UNRECOGNIZED = -1,
}

export function protocolVersionMinorFromJSON(object: any): ProtocolVersionMinor {
  switch (object) {
    case 189:
    case "PROTOCOL_VERSION_MINOR":
      return ProtocolVersionMinor.PROTOCOL_VERSION_MINOR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProtocolVersionMinor.UNRECOGNIZED;
  }
}

export function protocolVersionMinorToJSON(object: ProtocolVersionMinor): string {
  switch (object) {
    case ProtocolVersionMinor.PROTOCOL_VERSION_MINOR:
      return "PROTOCOL_VERSION_MINOR";
    case ProtocolVersionMinor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Client to Server message, must include at least one field.
 * Logon, LogonRoutineClient, RestoreOrJoinSession or ObtainDemoCredentials are required to be the first
 * and the only message after connection.
 * Client messages rate is limited, 1000 messages per 10 seconds by default.
 * If this rate is exceeded then a user message is sent with details and the connection is closed.
 * NOTE: This and other limits mentioned in this protocol are not guaranteed, they are given for reference only
 * and are subject to change without prior notice.
 */
export interface ClientMsg {
  /** Session level messages from client. */
  logon?: Logon | undefined;
  restoreOrJoinSession?: RestoreOrJoinSession | undefined;
  logoff?: Logoff | undefined;
  passwordChange?: PasswordChange | undefined;
  obtainDemoCredentials?: ObtainDemoCredentials | undefined;
  userSessionStateRequest?: UserSessionStateRequest | undefined;
  ping?: Ping | undefined;
  pong?: Pong | undefined;
  logonRoutineClient?: LogonRoutineClient | undefined;
  passwordChangeRoutineClient?: PasswordChangeRoutineClient | undefined;
  setCollapsingLevelRequest?:
    | SetCollapsingLevelRequest
    | undefined;
  /**
   * Requests for different information with optional subscription for updates.
   * There are the following limits for information requests:
   * 1) Information request rate is limited, 1000 requests per 100 seconds by default.
   * 2) Number of simultaneous information subscriptions is limited,
   *    a separate limit per information request type is used.
   * 3) Number of overall information requests per session is limited,
   *    a separate limit per information request type is used.
   */
  informationRequests: InformationRequest[];
  /**
   * Trade routing subscriptions from client.
   * There are the following limits for trade routing subscriptions:
   * 1) Number of simultaneous subscriptions is limited, 200 by default.
   * 2) Total number of either explicitly or implicitly subscribed accounts is limited, 5000 by default.
   *    Any subscription that leads to exceeding this limit is failed.
   */
  tradeSubscriptions: TradeSubscription[];
  /**
   * Order requests.
   * Request rate is limited, 300 orders per 10 seconds by default.
   * This is total limit for order actions amount all accounts within the session.
   * There is also per account limit which is 10 order requests per second by default.
   */
  orderRequests: OrderRequest[];
  /**
   * Market data subscriptions from client.
   * Subscriptions number is limited, 200 simultaneous subscriptions by default.
   * Subscriptions on options strikes (puts and calls) of the same option maturity
   * are considered as a single subscription within the limit.
   */
  marketDataSubscriptions: MarketDataSubscription[];
  /**
   * Historical data requests.
   * Number of simultaneous historical requests in processing is limited, 10 by default
   * (all types of historical requests together).
   * Number of simultaneous historical requests with subscription for updates is limited, 50 by default
   * (all types of historical requests together).
   */
  timeAndSalesRequests: TimeAndSalesRequest[];
  timeBarRequests: TimeBarRequest[];
  volumeProfileRequests: VolumeProfileRequest[];
  nonTimedBarRequests: NonTimedBarRequest[];
  /**
   * Rules requests.
   * Number of simultaneous defined rules is limited, 100 by default.
   * Rule requests rate is limited, 30 per 60 seconds by default.
   */
  ruleRequests: RuleRequest[];
  /** Messages for accounts that require separate authorization process. */
  accountLogonRequests: AccountLogon[];
  accountPasswordChangeRequests: AccountPasswordChange[];
  accountLogoffRequests: AccountLogoff[];
  /**
   * Meta-data related requests is an administrative message to manipulate
   * Security and instrument settings.
   * It requires a special permission.
   * Request rate is limited, 100 requests per 100 seconds by default.
   */
  metadataAdminRequests: MetadataAdminRequest[];
  /**
   * OTC related requests.
   * Used to receive data from OTC and manipulate OTC instances.
   */
  otcRequests: OtcRequest[];
  /**
   * RFQ requests.
   * Request rate is limited, 10 requests per second per account by default.
   */
  rfqRequests: RFQRequest[];
  /**
   * Option calculation requests.
   * Number of simultaneous option calculation requests with subscription for updates is limited, 10 by default.
   */
  optionCalculationRequests: OptionCalculationRequest[];
}

/** Server to Client message, server can include different fields in a single message. */
export interface ServerMsg {
  /** Session level messages from server. */
  logonResult?: LogonResult | undefined;
  restoreOrJoinSessionResult?: RestoreOrJoinSessionResult | undefined;
  concurrentConnectionJoinResults: ConcurrentConnectionJoin[];
  loggedOff?: LoggedOff | undefined;
  passwordChangeResult?: PasswordChangeResult | undefined;
  userMessages: UserMessage[];
  obtainDemoCredentialsResults: ObtainDemoCredentialsResult[];
  userSessionStateResults: UserSessionStateResult[];
  ping?: Ping | undefined;
  pong?: Pong | undefined;
  logonRoutineServer?: LogonRoutineServer | undefined;
  passwordChangeRoutineServer?: PasswordChangeRoutineServer | undefined;
  setCollapsingLevelResults: SetCollapsingLevelResult[];
  /** Reports with requested information. */
  informationReports: InformationReport[];
  /** Trade routing messages from server. */
  orderRequestRejects: OrderRequestReject[];
  orderRequestAcks: OrderRequestAck[];
  tradeSubscriptionStatuses: TradeSubscriptionStatus[];
  tradeSnapshotCompletions: TradeSnapshotCompletion[];
  orderStatuses: OrderStatus[];
  positionStatuses: PositionStatus[];
  collateralStatuses: CollateralStatus[];
  accountSummaryStatuses: AccountSummaryStatus[];
  /** Market data messages from server. */
  marketDataSubscriptionStatuses: MarketDataSubscriptionStatus[];
  realTimeMarketData: RealTimeMarketData[];
  /** / Historical data. */
  timeAndSalesReports: TimeAndSalesReport[];
  timeBarReports: TimeBarReport[];
  volumeProfileReports: VolumeProfileReport[];
  nonTimedBarReports: NonTimedBarReport[];
  /** Results of rules requests. */
  ruleResults: RuleResult[];
  /** Result messages for accounts that require separate authorization process. */
  accountLogonResults: AccountLogonResult[];
  accountPasswordChangeResults: AccountPasswordChangeResult[];
  accountLogoffResults: AccountLogoffResult[];
  /** Results of Meta-data change request. */
  metadataAdminResults: MetadataAdminResult[];
  /** Results of OTC request. */
  otcReports: OtcReport[];
  /** RFQ reports. */
  rfqReports: RFQReport[];
  /** Results of option calculation request. */
  optionCalculationReports: OptionCalculationReport[];
}

/**
 * Request for information.
 * There must be only one request type
 * (e.g. accountsRequest and lastStatementBalancesRequest can not be sent at the same time).
 */
export interface InformationRequest {
  /**
   * ID of a request and optional subscription that should be unique enough to match responses
   * and updates with corresponding requests.
   */
  id: number;
  /**
   * If set to true then corresponding reports are also sent when requested information is changed during the session.
   * If set to false then subscription is dropped.
   * If omitted then data is returned without changing subscription status.
   */
  subscribe?:
    | boolean
    | undefined;
  /**
   * Request for authorized accounts for trading.
   * Only one subscription is possible.
   * Request rate is limited, 100 per 24 hours by default.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  accountsRequest?:
    | AccountsRequest
    | undefined;
  /**
   * Request symbol resolution with optional subscription.
   * Request rate is limited, 10000 per 24 hours by default.
   * NOTE: subscription is essential to follow active contract switching.
   */
  symbolResolutionRequest?:
    | SymbolResolutionRequest
    | undefined;
  /**
   * Request for balances from the last statement for all authorized accounts.
   * Only one subscription is possible.
   * Request rate is limited, 100 per 24 hours by default.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  lastStatementBalancesRequest?:
    | LastStatementBalancesRequest
    | undefined;
  /**
   * Request for currency rates for all brokerages user's accounts belong to.
   * Only one subscription is possible.
   * Request rate is limited, 100 per 24 hours by default.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  currencyRatesRequest?:
    | CurrencyRatesRequest
    | undefined;
  /**
   * Request for session information.
   * Number of simultaneous requests in processing is limited, 10 by default.
   * Number of simultaneous session information update subscriptions is limited, 200 by default.
   * Request is accepted only if the user is not subscribed for this information at the moment.
   */
  sessionInformationRequest?:
    | SessionInformationRequest
    | undefined;
  /**
   * Request for a list of historical orders (including all transactions) according to a search criteria.
   * Number of simultaneous requests in processing is limited, 1 by default.
   * The request depth is limited, 30 days by default.
   * Subscription is not supported for this request.
   */
  historicalOrdersRequest?:
    | HistoricalOrdersRequest
    | undefined;
  /**
   * Request a list of option maturities.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   */
  optionMaturityListRequest?:
    | OptionMaturityListRequest
    | undefined;
  /**
   * Request a group of instruments.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   */
  instrumentGroupRequest?:
    | InstrumentGroupRequest
    | undefined;
  /**
   * Request at-the-money strike for the option maturity.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   */
  atTheMoneyStrikeRequest?:
    | AtTheMoneyStrikeRequest
    | undefined;
  /**
   * Request strategy definition with no subscription.
   * Request rate is limited, 10000 per 24 hours by default.
   */
  strategyDefinitionRequest?:
    | StrategyDefinitionRequest
    | undefined;
  /**
   * Request for session timerange information.
   * Number of simultaneous requests in processing is limited, 10 by default.
   * The request range is limited. 1 year/1000 sessions by default.
   * Subscription is NOT supported for this request, so subscribe flag will be ignored.
   */
  sessionTimerangeRequest?:
    | SessionTimeRangeRequest
    | undefined;
  /**
   * Request for trading day timerange information.
   * Number of simultaneous requests in processing is limited, 10 by default.
   * The request range is limited. 1 year/250 trading days by default.
   * Subscription is NOT supported for this request, so subscribe flag will be ignored.
   */
  tradingDayTimerangeRequest?:
    | TradingDayTimeRangeRequest
    | undefined;
  /**
   * Request of order entitlements.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Subscription is not supported for this request.
   */
  orderEntitlementRequest?:
    | OrderEntitlementRequest
    | undefined;
  /**
   * Request for a symbol category tree for a particular root. each category can have a list of sub-categories.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   * There can be only one subscription per category root for updates.
   */
  symbolCategoryListRequest?:
    | SymbolCategoryListRequest
    | undefined;
  /**
   * Request for a specific category by its ID.
   * Number of simultaneous requests in processing is limited, 1000 by default.
   * Number of simultaneous update subscriptions is limited, 1000 by default.
   */
  symbolCategoryRequest?:
    | SymbolCategoryRequest
    | undefined;
  /**
   * Request for a list of category symbols matching input instrument client type.
   * Requests rate is limited, 30 per 60 seconds by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Subscription is not supported for this request.
   */
  symbolCategoryListByInstrumentTypeRequest?:
    | SymbolCategoryListByInstrumentTypeRequest
    | undefined;
  /**
   * Request for a list of symbols according to a specified filter.
   * Number of simultaneous requests in processing is limited, 150 by default.
   * Number of simultaneous update subscriptions is limited, 150 by default.
   */
  symbolListRequest?:
    | SymbolListRequest
    | undefined;
  /**
   * Request for a specific symbol by its ID.
   * Request rate is limited, 10000 per 24 hours by default.
   * Number of simultaneous requests in processing is limited, 150 by default.
   * Subscription is not supported for this request.
   */
  symbolRequest?:
    | SymbolRequest
    | undefined;
  /** Request contract metadata by contract ID. */
  contractMetadataRequest?:
    | ContractMetadataRequest
    | undefined;
  /**
   * Request algo strategy definitions.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Subscription is not supported for this request.
   */
  algoStrategyDefinitionRequest?:
    | AlgoStrategyDefinitionRequest
    | undefined;
  /**
   * Request contributor metadata by contributor group ID.
   * Request rate is limited, 300 per 1 hour by default.
   * Number of simultaneous requests in processing is limited, 300 by default.
   * Number of simultaneous update subscriptions is limited, 300 by default.
   */
  contributorMetadataRequest?:
    | ContributorMetadataRequest
    | undefined;
  /**
   * WebAPI user limits request.
   * Number of subscriptions and simultaneous requests in processing is limited, 1 by default.
   */
  apiLimitRequest?:
    | ApiLimitRequest
    | undefined;
  /**
   * Request for trading feature entitlements.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Subscription is not supported for this request.
   */
  brokerageTradingFeatureEntitlementRequest?:
    | BrokerageTradingFeatureEntitlementRequest
    | undefined;
  /**
   * Request for a single order status.
   * Request rate is limited, 5 per 5 seconds by default.
   * Subscription is not supported for this request.
   */
  orderStatusRequest?:
    | OrderStatusRequest
    | undefined;
  /**
   * Request for a list of product symbols matching the search criteria.
   * Requests rate is limited, 30 per 60 seconds by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Subscription is not supported for this request.
   */
  productSearchRequest?:
    | ProductSearchRequest
    | undefined;
  /**
   * Request MarketState metadata by MarketState group ID.
   * Request rate is limited, 300 per 1 hour by default.
   * Number of simultaneous requests in processing is limited, 300 by default.
   * Number of simultaneous update subscriptions is limited, 300 by default.
   */
  marketStateMetadataRequest?:
    | MarketStateMetadataRequest
    | undefined;
  /**
   * Request (non-strategy) instrument definition with no subscription.
   * Request rate is limited, 10000 per 24 hours by default.
   */
  instrumentDefinitionRequest?:
    | InstrumentDefinitionRequest
    | undefined;
  /**
   * Request metadata of available exchanges.
   * Number of subscriptions and simultaneous requests in processing is limited, 1 per connection by default.
   */
  exchangeMetadataRequest?:
    | ExchangeMetadataRequest
    | undefined;
  /**
   * Request for a subscription on metadata for a group of instruments based on given list of securities.
   * Request rate is limited, 10000 per 24 hours by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   * Multiple subscriptions on the same set of securities are prohibited.
   */
  instrumentGroupBySecuritiesRequest?:
    | InstrumentGroupBySecuritiesRequest
    | undefined;
  /**
   * Request for a subscription on metadata for a group of instruments of a particular exchange.
   * Request rate is limited, 10000 per 24 hours by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   * Multiple subscriptions on the same exchange ID are prohibited.
   */
  instrumentGroupByExchangeRequest?:
    | InstrumentGroupByExchangeRequest
    | undefined;
  /**
   * Request for a list of securities for a particular exchange.
   * Request rate is limited, 300 per 1 hour by default.
   * Number of simultaneous requests in processing is limited, 300 by default.
   * Number of simultaneous update subscriptions is limited, 300 by default.
   */
  exchangeSecuritiesRequest?:
    | ExchangeSecuritiesRequest
    | undefined;
  /**
   * Request for care auto activation parameters for the user.
   * Only one subscription is possible.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  careAutoActivationParametersRequest?: CareAutoActivationParametersRequest | undefined;
}

/**
 * Report with requested information and subscription updates.
 * It will contain only one report type at the same time (related to id).
 */
export interface InformationReport {
  /** Report ID is equal to a corresponding request and subscription ID. */
  id: number;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete?:
    | boolean
    | undefined;
  /** This field is associated with StatusCode enum type. */
  statusCode: number;
  /** Possible details of status or failure. */
  textMessage?:
    | string
    | undefined;
  /** Report with trading accounts. */
  accountsReport?:
    | AccountsReport
    | undefined;
  /** Resolved symbol initial information or update. */
  symbolResolutionReport?:
    | SymbolResolutionReport
    | undefined;
  /** Report with balances from the last statement for all authorized accounts. */
  lastStatementBalancesReport?:
    | LastStatementBalancesReport
    | undefined;
  /** Report with currency rates per brokerage. */
  currencyRatesReport?:
    | CurrencyRatesReport
    | undefined;
  /** Report with session information. */
  sessionInformationReport?:
    | SessionInformationReport
    | undefined;
  /** Report with a list of historical orders according to specified parameters. */
  historicalOrdersReport?:
    | HistoricalOrdersReport
    | undefined;
  /** Report with a list of option maturities. */
  optionMaturityListReport?:
    | OptionMaturityListReport
    | undefined;
  /** Report with a group of instruments. */
  instrumentGroupReport?:
    | InstrumentGroupReport
    | undefined;
  /** Report with at-the-money strikes. */
  atTheMoneyStrikeReport?:
    | AtTheMoneyStrikeReport
    | undefined;
  /**
   * Report with the defined strategy metadata.
   * This field can be filled in case of STATUS_CODE_FAILURE, if the exchange response contains a strategy
   * that differs significantly from the requested one.
   * Example: user wants to define UDS(A - B), but opposite UDS(B - A) already exists on exchange.
   */
  strategyDefinitionReport?:
    | StrategyDefinitionReport
    | undefined;
  /** Report with session timerange information. */
  sessionTimerangeReport?:
    | SessionTimeRangeReport
    | undefined;
  /** Report with trading day timerange information. */
  tradingDayTimerangeReport?:
    | TradingDayTimeRangeReport
    | undefined;
  /** Report with order entitlements. */
  orderEntitlementReport?:
    | OrderEntitlementReport
    | undefined;
  /** Report with a symbol category tree for a particular root. */
  symbolCategoryListReport?:
    | SymbolCategoryListReport
    | undefined;
  /** Report with a symbol category. */
  symbolCategoryReport?:
    | SymbolCategoryReport
    | undefined;
  /** Report with a list of matched category symbols. */
  symbolCategoryListByInstrumentTypeReport?:
    | SymbolCategoryListByInstrumentTypeReport
    | undefined;
  /** Report with a list of found symbols. */
  symbolListReport?:
    | SymbolListReport
    | undefined;
  /** Report with a symbol. */
  symbolReport?:
    | SymbolReport
    | undefined;
  /** Report with the requested contract metadata. */
  contractMetadataReport?:
    | ContractMetadataReport
    | undefined;
  /** Report with algo strategies definitions. */
  algoStrategyDefinitionReport?:
    | AlgoStrategyDefinitionReport
    | undefined;
  /** Report with contributor metadata. */
  contributorMetadataReport?:
    | ContributorMetadataReport
    | undefined;
  /** Report with WebAPI limit values. */
  apiLimitReport?:
    | ApiLimitReport
    | undefined;
  /** Report with trading feature entitlements. */
  brokerageTradingFeatureEntitlementReport?:
    | BrokerageTradingFeatureEntitlementReport
    | undefined;
  /** Report with an order status. */
  orderStatusReport?:
    | OrderStatusReport
    | undefined;
  /** Report with a list of found product symbols. */
  productSearchReport?:
    | ProductSearchReport
    | undefined;
  /** Report with Market State/Status metadata. */
  marketStateMetadataReport?:
    | MarketStateMetadataReport
    | undefined;
  /** Report with the defined (non-strategy) instrument metadata. */
  instrumentDefinitionReport?:
    | InstrumentDefinitionReport
    | undefined;
  /** Report with exchange metadata */
  exchangeMetadataReport?:
    | ExchangeMetadataReport
    | undefined;
  /** Report with a group of instruments for a particular set of securities. */
  instrumentGroupBySecuritiesReport?:
    | InstrumentGroupBySecuritiesReport
    | undefined;
  /** Report with a group of instruments for a particular exchnage securities. */
  instrumentGroupByExchangeReport?:
    | InstrumentGroupByExchangeReport
    | undefined;
  /** Report with a list of securities for a particular exchange. */
  exchangeSecuritiesReport?:
    | ExchangeSecuritiesReport
    | undefined;
  /** Report with a list of care auto activation parameters. */
  careAutoActivationParametersReport?: CareAutoActivationParametersReport | undefined;
}

/** Optional status code for a request or subscription, status is only sent for initial status and status changes. */
export enum InformationReport_StatusCode {
  /**
   * STATUS_CODE_SUCCESS - / success codes  (0 - 99)
   * Request is processed without subscription
   * (subscription was not requested or can not be established, e.g. for deleted contracts).
   */
  STATUS_CODE_SUCCESS = 0,
  /** STATUS_CODE_SUBSCRIBED - Request is processed and subscription is established. */
  STATUS_CODE_SUBSCRIBED = 1,
  /** STATUS_CODE_DROPPED - The subscription is dropped by the client. */
  STATUS_CODE_DROPPED = 2,
  /** STATUS_CODE_UPDATE - Unsolicited information update because of subscription. */
  STATUS_CODE_UPDATE = 3,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is disconnected because of communication issues.
   * NOTE: Clients should not resubscribe in this case, the server will restore subscription with
   * sending SUBSCRIBED status once communication issues are resolved. Collection of items should
   * not be cleared once DISCONNECTED status is received, and further reports should be treated as
   * updates unless otherwise noted in request description.
   */
  STATUS_CODE_DISCONNECTED = 4,
  /**
   * STATUS_CODE_FAILURE - / failure codes (100+), subscription (if any) is dropped in case of failure.
   * General failure.
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Request parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_NOT_FOUND - Requested information is not found. */
  STATUS_CODE_NOT_FOUND = 104,
  /** STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION - The request rate limit has been violated. */
  STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION = 105,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The limit of subscriptions has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 106,
  /** STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION - The limit of active requests has been violated. */
  STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION = 107,
  /** STATUS_CODE_TOO_LARGE_RESPONSE - The response is too large to return. Try more specific request parameters to narrow response size if possible. */
  STATUS_CODE_TOO_LARGE_RESPONSE = 108,
  UNRECOGNIZED = -1,
}

export function informationReport_StatusCodeFromJSON(object: any): InformationReport_StatusCode {
  switch (object) {
    case 0:
    case "STATUS_CODE_SUCCESS":
      return InformationReport_StatusCode.STATUS_CODE_SUCCESS;
    case 1:
    case "STATUS_CODE_SUBSCRIBED":
      return InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED;
    case 2:
    case "STATUS_CODE_DROPPED":
      return InformationReport_StatusCode.STATUS_CODE_DROPPED;
    case 3:
    case "STATUS_CODE_UPDATE":
      return InformationReport_StatusCode.STATUS_CODE_UPDATE;
    case 4:
    case "STATUS_CODE_DISCONNECTED":
      return InformationReport_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case "STATUS_CODE_FAILURE":
      return InformationReport_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case "STATUS_CODE_INVALID_PARAMS":
      return InformationReport_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 104:
    case "STATUS_CODE_NOT_FOUND":
      return InformationReport_StatusCode.STATUS_CODE_NOT_FOUND;
    case 105:
    case "STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION":
      return InformationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION;
    case 106:
    case "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION":
      return InformationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 107:
    case "STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION":
      return InformationReport_StatusCode.STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION;
    case 108:
    case "STATUS_CODE_TOO_LARGE_RESPONSE":
      return InformationReport_StatusCode.STATUS_CODE_TOO_LARGE_RESPONSE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InformationReport_StatusCode.UNRECOGNIZED;
  }
}

export function informationReport_StatusCodeToJSON(object: InformationReport_StatusCode): string {
  switch (object) {
    case InformationReport_StatusCode.STATUS_CODE_SUCCESS:
      return "STATUS_CODE_SUCCESS";
    case InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED:
      return "STATUS_CODE_SUBSCRIBED";
    case InformationReport_StatusCode.STATUS_CODE_DROPPED:
      return "STATUS_CODE_DROPPED";
    case InformationReport_StatusCode.STATUS_CODE_UPDATE:
      return "STATUS_CODE_UPDATE";
    case InformationReport_StatusCode.STATUS_CODE_DISCONNECTED:
      return "STATUS_CODE_DISCONNECTED";
    case InformationReport_StatusCode.STATUS_CODE_FAILURE:
      return "STATUS_CODE_FAILURE";
    case InformationReport_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return "STATUS_CODE_INVALID_PARAMS";
    case InformationReport_StatusCode.STATUS_CODE_NOT_FOUND:
      return "STATUS_CODE_NOT_FOUND";
    case InformationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION:
      return "STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION";
    case InformationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return "STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION";
    case InformationReport_StatusCode.STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION:
      return "STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION";
    case InformationReport_StatusCode.STATUS_CODE_TOO_LARGE_RESPONSE:
      return "STATUS_CODE_TOO_LARGE_RESPONSE";
    case InformationReport_StatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseClientMsg(): ClientMsg {
  return {
    logon: undefined,
    restoreOrJoinSession: undefined,
    logoff: undefined,
    passwordChange: undefined,
    obtainDemoCredentials: undefined,
    userSessionStateRequest: undefined,
    ping: undefined,
    pong: undefined,
    logonRoutineClient: undefined,
    passwordChangeRoutineClient: undefined,
    setCollapsingLevelRequest: undefined,
    informationRequests: [],
    tradeSubscriptions: [],
    orderRequests: [],
    marketDataSubscriptions: [],
    timeAndSalesRequests: [],
    timeBarRequests: [],
    volumeProfileRequests: [],
    nonTimedBarRequests: [],
    ruleRequests: [],
    accountLogonRequests: [],
    accountPasswordChangeRequests: [],
    accountLogoffRequests: [],
    metadataAdminRequests: [],
    otcRequests: [],
    rfqRequests: [],
    optionCalculationRequests: [],
  };
}

export const ClientMsg = {
  encode(message: ClientMsg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logon !== undefined) {
      Logon.encode(message.logon, writer.uint32(802).fork()).ldelim();
    }
    if (message.restoreOrJoinSession !== undefined) {
      RestoreOrJoinSession.encode(message.restoreOrJoinSession, writer.uint32(810).fork()).ldelim();
    }
    if (message.logoff !== undefined) {
      Logoff.encode(message.logoff, writer.uint32(818).fork()).ldelim();
    }
    if (message.passwordChange !== undefined) {
      PasswordChange.encode(message.passwordChange, writer.uint32(826).fork()).ldelim();
    }
    if (message.obtainDemoCredentials !== undefined) {
      ObtainDemoCredentials.encode(message.obtainDemoCredentials, writer.uint32(834).fork()).ldelim();
    }
    if (message.userSessionStateRequest !== undefined) {
      UserSessionStateRequest.encode(message.userSessionStateRequest, writer.uint32(842).fork()).ldelim();
    }
    if (message.ping !== undefined) {
      Ping.encode(message.ping, writer.uint32(858).fork()).ldelim();
    }
    if (message.pong !== undefined) {
      Pong.encode(message.pong, writer.uint32(866).fork()).ldelim();
    }
    if (message.logonRoutineClient !== undefined) {
      LogonRoutineClient.encode(message.logonRoutineClient, writer.uint32(874).fork()).ldelim();
    }
    if (message.passwordChangeRoutineClient !== undefined) {
      PasswordChangeRoutineClient.encode(message.passwordChangeRoutineClient, writer.uint32(882).fork()).ldelim();
    }
    if (message.setCollapsingLevelRequest !== undefined) {
      SetCollapsingLevelRequest.encode(message.setCollapsingLevelRequest, writer.uint32(890).fork()).ldelim();
    }
    for (const v of message.informationRequests) {
      InformationRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.tradeSubscriptions) {
      TradeSubscription.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.orderRequests) {
      OrderRequest.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.marketDataSubscriptions) {
      MarketDataSubscription.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.timeAndSalesRequests) {
      TimeAndSalesRequest.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.timeBarRequests) {
      TimeBarRequest.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.volumeProfileRequests) {
      VolumeProfileRequest.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.nonTimedBarRequests) {
      NonTimedBarRequest.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.ruleRequests) {
      RuleRequest.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.accountLogonRequests) {
      AccountLogon.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.accountPasswordChangeRequests) {
      AccountPasswordChange.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.accountLogoffRequests) {
      AccountLogoff.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.metadataAdminRequests) {
      MetadataAdminRequest.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.otcRequests) {
      OtcRequest.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.rfqRequests) {
      RFQRequest.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.optionCalculationRequests) {
      OptionCalculationRequest.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientMsg {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          if (tag !== 802) {
            break;
          }

          message.logon = Logon.decode(reader, reader.uint32());
          continue;
        case 101:
          if (tag !== 810) {
            break;
          }

          message.restoreOrJoinSession = RestoreOrJoinSession.decode(reader, reader.uint32());
          continue;
        case 102:
          if (tag !== 818) {
            break;
          }

          message.logoff = Logoff.decode(reader, reader.uint32());
          continue;
        case 103:
          if (tag !== 826) {
            break;
          }

          message.passwordChange = PasswordChange.decode(reader, reader.uint32());
          continue;
        case 104:
          if (tag !== 834) {
            break;
          }

          message.obtainDemoCredentials = ObtainDemoCredentials.decode(reader, reader.uint32());
          continue;
        case 105:
          if (tag !== 842) {
            break;
          }

          message.userSessionStateRequest = UserSessionStateRequest.decode(reader, reader.uint32());
          continue;
        case 107:
          if (tag !== 858) {
            break;
          }

          message.ping = Ping.decode(reader, reader.uint32());
          continue;
        case 108:
          if (tag !== 866) {
            break;
          }

          message.pong = Pong.decode(reader, reader.uint32());
          continue;
        case 109:
          if (tag !== 874) {
            break;
          }

          message.logonRoutineClient = LogonRoutineClient.decode(reader, reader.uint32());
          continue;
        case 110:
          if (tag !== 882) {
            break;
          }

          message.passwordChangeRoutineClient = PasswordChangeRoutineClient.decode(reader, reader.uint32());
          continue;
        case 111:
          if (tag !== 890) {
            break;
          }

          message.setCollapsingLevelRequest = SetCollapsingLevelRequest.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.informationRequests.push(InformationRequest.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tradeSubscriptions.push(TradeSubscription.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orderRequests.push(OrderRequest.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.marketDataSubscriptions.push(MarketDataSubscription.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.timeAndSalesRequests.push(TimeAndSalesRequest.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.timeBarRequests.push(TimeBarRequest.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.volumeProfileRequests.push(VolumeProfileRequest.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.nonTimedBarRequests.push(NonTimedBarRequest.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ruleRequests.push(RuleRequest.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.accountLogonRequests.push(AccountLogon.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.accountPasswordChangeRequests.push(AccountPasswordChange.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.accountLogoffRequests.push(AccountLogoff.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.metadataAdminRequests.push(MetadataAdminRequest.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.otcRequests.push(OtcRequest.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.rfqRequests.push(RFQRequest.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.optionCalculationRequests.push(OptionCalculationRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientMsg {
    return {
      logon: isSet(object.logon) ? Logon.fromJSON(object.logon) : undefined,
      restoreOrJoinSession: isSet(object.restoreOrJoinSession)
        ? RestoreOrJoinSession.fromJSON(object.restoreOrJoinSession)
        : undefined,
      logoff: isSet(object.logoff) ? Logoff.fromJSON(object.logoff) : undefined,
      passwordChange: isSet(object.passwordChange) ? PasswordChange.fromJSON(object.passwordChange) : undefined,
      obtainDemoCredentials: isSet(object.obtainDemoCredentials)
        ? ObtainDemoCredentials.fromJSON(object.obtainDemoCredentials)
        : undefined,
      userSessionStateRequest: isSet(object.userSessionStateRequest)
        ? UserSessionStateRequest.fromJSON(object.userSessionStateRequest)
        : undefined,
      ping: isSet(object.ping) ? Ping.fromJSON(object.ping) : undefined,
      pong: isSet(object.pong) ? Pong.fromJSON(object.pong) : undefined,
      logonRoutineClient: isSet(object.logonRoutineClient)
        ? LogonRoutineClient.fromJSON(object.logonRoutineClient)
        : undefined,
      passwordChangeRoutineClient: isSet(object.passwordChangeRoutineClient)
        ? PasswordChangeRoutineClient.fromJSON(object.passwordChangeRoutineClient)
        : undefined,
      setCollapsingLevelRequest: isSet(object.setCollapsingLevelRequest)
        ? SetCollapsingLevelRequest.fromJSON(object.setCollapsingLevelRequest)
        : undefined,
      informationRequests: globalThis.Array.isArray(object?.informationRequests)
        ? object.informationRequests.map((e: any) => InformationRequest.fromJSON(e))
        : [],
      tradeSubscriptions: globalThis.Array.isArray(object?.tradeSubscriptions)
        ? object.tradeSubscriptions.map((e: any) => TradeSubscription.fromJSON(e))
        : [],
      orderRequests: globalThis.Array.isArray(object?.orderRequests)
        ? object.orderRequests.map((e: any) => OrderRequest.fromJSON(e))
        : [],
      marketDataSubscriptions: globalThis.Array.isArray(object?.marketDataSubscriptions)
        ? object.marketDataSubscriptions.map((e: any) => MarketDataSubscription.fromJSON(e))
        : [],
      timeAndSalesRequests: globalThis.Array.isArray(object?.timeAndSalesRequests)
        ? object.timeAndSalesRequests.map((e: any) => TimeAndSalesRequest.fromJSON(e))
        : [],
      timeBarRequests: globalThis.Array.isArray(object?.timeBarRequests)
        ? object.timeBarRequests.map((e: any) => TimeBarRequest.fromJSON(e))
        : [],
      volumeProfileRequests: globalThis.Array.isArray(object?.volumeProfileRequests)
        ? object.volumeProfileRequests.map((e: any) => VolumeProfileRequest.fromJSON(e))
        : [],
      nonTimedBarRequests: globalThis.Array.isArray(object?.nonTimedBarRequests)
        ? object.nonTimedBarRequests.map((e: any) => NonTimedBarRequest.fromJSON(e))
        : [],
      ruleRequests: globalThis.Array.isArray(object?.ruleRequests)
        ? object.ruleRequests.map((e: any) => RuleRequest.fromJSON(e))
        : [],
      accountLogonRequests: globalThis.Array.isArray(object?.accountLogonRequests)
        ? object.accountLogonRequests.map((e: any) => AccountLogon.fromJSON(e))
        : [],
      accountPasswordChangeRequests: globalThis.Array.isArray(object?.accountPasswordChangeRequests)
        ? object.accountPasswordChangeRequests.map((e: any) => AccountPasswordChange.fromJSON(e))
        : [],
      accountLogoffRequests: globalThis.Array.isArray(object?.accountLogoffRequests)
        ? object.accountLogoffRequests.map((e: any) => AccountLogoff.fromJSON(e))
        : [],
      metadataAdminRequests: globalThis.Array.isArray(object?.metadataAdminRequests)
        ? object.metadataAdminRequests.map((e: any) => MetadataAdminRequest.fromJSON(e))
        : [],
      otcRequests: globalThis.Array.isArray(object?.otcRequests)
        ? object.otcRequests.map((e: any) => OtcRequest.fromJSON(e))
        : [],
      rfqRequests: globalThis.Array.isArray(object?.rfqRequests)
        ? object.rfqRequests.map((e: any) => RFQRequest.fromJSON(e))
        : [],
      optionCalculationRequests: globalThis.Array.isArray(object?.optionCalculationRequests)
        ? object.optionCalculationRequests.map((e: any) => OptionCalculationRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ClientMsg): unknown {
    const obj: any = {};
    if (message.logon !== undefined) {
      obj.logon = Logon.toJSON(message.logon);
    }
    if (message.restoreOrJoinSession !== undefined) {
      obj.restoreOrJoinSession = RestoreOrJoinSession.toJSON(message.restoreOrJoinSession);
    }
    if (message.logoff !== undefined) {
      obj.logoff = Logoff.toJSON(message.logoff);
    }
    if (message.passwordChange !== undefined) {
      obj.passwordChange = PasswordChange.toJSON(message.passwordChange);
    }
    if (message.obtainDemoCredentials !== undefined) {
      obj.obtainDemoCredentials = ObtainDemoCredentials.toJSON(message.obtainDemoCredentials);
    }
    if (message.userSessionStateRequest !== undefined) {
      obj.userSessionStateRequest = UserSessionStateRequest.toJSON(message.userSessionStateRequest);
    }
    if (message.ping !== undefined) {
      obj.ping = Ping.toJSON(message.ping);
    }
    if (message.pong !== undefined) {
      obj.pong = Pong.toJSON(message.pong);
    }
    if (message.logonRoutineClient !== undefined) {
      obj.logonRoutineClient = LogonRoutineClient.toJSON(message.logonRoutineClient);
    }
    if (message.passwordChangeRoutineClient !== undefined) {
      obj.passwordChangeRoutineClient = PasswordChangeRoutineClient.toJSON(message.passwordChangeRoutineClient);
    }
    if (message.setCollapsingLevelRequest !== undefined) {
      obj.setCollapsingLevelRequest = SetCollapsingLevelRequest.toJSON(message.setCollapsingLevelRequest);
    }
    if (message.informationRequests?.length) {
      obj.informationRequests = message.informationRequests.map((e) => InformationRequest.toJSON(e));
    }
    if (message.tradeSubscriptions?.length) {
      obj.tradeSubscriptions = message.tradeSubscriptions.map((e) => TradeSubscription.toJSON(e));
    }
    if (message.orderRequests?.length) {
      obj.orderRequests = message.orderRequests.map((e) => OrderRequest.toJSON(e));
    }
    if (message.marketDataSubscriptions?.length) {
      obj.marketDataSubscriptions = message.marketDataSubscriptions.map((e) => MarketDataSubscription.toJSON(e));
    }
    if (message.timeAndSalesRequests?.length) {
      obj.timeAndSalesRequests = message.timeAndSalesRequests.map((e) => TimeAndSalesRequest.toJSON(e));
    }
    if (message.timeBarRequests?.length) {
      obj.timeBarRequests = message.timeBarRequests.map((e) => TimeBarRequest.toJSON(e));
    }
    if (message.volumeProfileRequests?.length) {
      obj.volumeProfileRequests = message.volumeProfileRequests.map((e) => VolumeProfileRequest.toJSON(e));
    }
    if (message.nonTimedBarRequests?.length) {
      obj.nonTimedBarRequests = message.nonTimedBarRequests.map((e) => NonTimedBarRequest.toJSON(e));
    }
    if (message.ruleRequests?.length) {
      obj.ruleRequests = message.ruleRequests.map((e) => RuleRequest.toJSON(e));
    }
    if (message.accountLogonRequests?.length) {
      obj.accountLogonRequests = message.accountLogonRequests.map((e) => AccountLogon.toJSON(e));
    }
    if (message.accountPasswordChangeRequests?.length) {
      obj.accountPasswordChangeRequests = message.accountPasswordChangeRequests.map((e) =>
        AccountPasswordChange.toJSON(e)
      );
    }
    if (message.accountLogoffRequests?.length) {
      obj.accountLogoffRequests = message.accountLogoffRequests.map((e) => AccountLogoff.toJSON(e));
    }
    if (message.metadataAdminRequests?.length) {
      obj.metadataAdminRequests = message.metadataAdminRequests.map((e) => MetadataAdminRequest.toJSON(e));
    }
    if (message.otcRequests?.length) {
      obj.otcRequests = message.otcRequests.map((e) => OtcRequest.toJSON(e));
    }
    if (message.rfqRequests?.length) {
      obj.rfqRequests = message.rfqRequests.map((e) => RFQRequest.toJSON(e));
    }
    if (message.optionCalculationRequests?.length) {
      obj.optionCalculationRequests = message.optionCalculationRequests.map((e) => OptionCalculationRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientMsg>, I>>(base?: I): ClientMsg {
    return ClientMsg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientMsg>, I>>(object: I): ClientMsg {
    const message = createBaseClientMsg();
    message.logon = (object.logon !== undefined && object.logon !== null) ? Logon.fromPartial(object.logon) : undefined;
    message.restoreOrJoinSession = (object.restoreOrJoinSession !== undefined && object.restoreOrJoinSession !== null)
      ? RestoreOrJoinSession.fromPartial(object.restoreOrJoinSession)
      : undefined;
    message.logoff = (object.logoff !== undefined && object.logoff !== null)
      ? Logoff.fromPartial(object.logoff)
      : undefined;
    message.passwordChange = (object.passwordChange !== undefined && object.passwordChange !== null)
      ? PasswordChange.fromPartial(object.passwordChange)
      : undefined;
    message.obtainDemoCredentials =
      (object.obtainDemoCredentials !== undefined && object.obtainDemoCredentials !== null)
        ? ObtainDemoCredentials.fromPartial(object.obtainDemoCredentials)
        : undefined;
    message.userSessionStateRequest =
      (object.userSessionStateRequest !== undefined && object.userSessionStateRequest !== null)
        ? UserSessionStateRequest.fromPartial(object.userSessionStateRequest)
        : undefined;
    message.ping = (object.ping !== undefined && object.ping !== null) ? Ping.fromPartial(object.ping) : undefined;
    message.pong = (object.pong !== undefined && object.pong !== null) ? Pong.fromPartial(object.pong) : undefined;
    message.logonRoutineClient = (object.logonRoutineClient !== undefined && object.logonRoutineClient !== null)
      ? LogonRoutineClient.fromPartial(object.logonRoutineClient)
      : undefined;
    message.passwordChangeRoutineClient =
      (object.passwordChangeRoutineClient !== undefined && object.passwordChangeRoutineClient !== null)
        ? PasswordChangeRoutineClient.fromPartial(object.passwordChangeRoutineClient)
        : undefined;
    message.setCollapsingLevelRequest =
      (object.setCollapsingLevelRequest !== undefined && object.setCollapsingLevelRequest !== null)
        ? SetCollapsingLevelRequest.fromPartial(object.setCollapsingLevelRequest)
        : undefined;
    message.informationRequests = object.informationRequests?.map((e) => InformationRequest.fromPartial(e)) || [];
    message.tradeSubscriptions = object.tradeSubscriptions?.map((e) => TradeSubscription.fromPartial(e)) || [];
    message.orderRequests = object.orderRequests?.map((e) => OrderRequest.fromPartial(e)) || [];
    message.marketDataSubscriptions =
      object.marketDataSubscriptions?.map((e) => MarketDataSubscription.fromPartial(e)) || [];
    message.timeAndSalesRequests = object.timeAndSalesRequests?.map((e) => TimeAndSalesRequest.fromPartial(e)) || [];
    message.timeBarRequests = object.timeBarRequests?.map((e) => TimeBarRequest.fromPartial(e)) || [];
    message.volumeProfileRequests = object.volumeProfileRequests?.map((e) => VolumeProfileRequest.fromPartial(e)) || [];
    message.nonTimedBarRequests = object.nonTimedBarRequests?.map((e) => NonTimedBarRequest.fromPartial(e)) || [];
    message.ruleRequests = object.ruleRequests?.map((e) => RuleRequest.fromPartial(e)) || [];
    message.accountLogonRequests = object.accountLogonRequests?.map((e) => AccountLogon.fromPartial(e)) || [];
    message.accountPasswordChangeRequests =
      object.accountPasswordChangeRequests?.map((e) => AccountPasswordChange.fromPartial(e)) || [];
    message.accountLogoffRequests = object.accountLogoffRequests?.map((e) => AccountLogoff.fromPartial(e)) || [];
    message.metadataAdminRequests = object.metadataAdminRequests?.map((e) => MetadataAdminRequest.fromPartial(e)) || [];
    message.otcRequests = object.otcRequests?.map((e) => OtcRequest.fromPartial(e)) || [];
    message.rfqRequests = object.rfqRequests?.map((e) => RFQRequest.fromPartial(e)) || [];
    message.optionCalculationRequests =
      object.optionCalculationRequests?.map((e) => OptionCalculationRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerMsg(): ServerMsg {
  return {
    logonResult: undefined,
    restoreOrJoinSessionResult: undefined,
    concurrentConnectionJoinResults: [],
    loggedOff: undefined,
    passwordChangeResult: undefined,
    userMessages: [],
    obtainDemoCredentialsResults: [],
    userSessionStateResults: [],
    ping: undefined,
    pong: undefined,
    logonRoutineServer: undefined,
    passwordChangeRoutineServer: undefined,
    setCollapsingLevelResults: [],
    informationReports: [],
    orderRequestRejects: [],
    orderRequestAcks: [],
    tradeSubscriptionStatuses: [],
    tradeSnapshotCompletions: [],
    orderStatuses: [],
    positionStatuses: [],
    collateralStatuses: [],
    accountSummaryStatuses: [],
    marketDataSubscriptionStatuses: [],
    realTimeMarketData: [],
    timeAndSalesReports: [],
    timeBarReports: [],
    volumeProfileReports: [],
    nonTimedBarReports: [],
    ruleResults: [],
    accountLogonResults: [],
    accountPasswordChangeResults: [],
    accountLogoffResults: [],
    metadataAdminResults: [],
    otcReports: [],
    rfqReports: [],
    optionCalculationReports: [],
  };
}

export const ServerMsg = {
  encode(message: ServerMsg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logonResult !== undefined) {
      LogonResult.encode(message.logonResult, writer.uint32(802).fork()).ldelim();
    }
    if (message.restoreOrJoinSessionResult !== undefined) {
      RestoreOrJoinSessionResult.encode(message.restoreOrJoinSessionResult, writer.uint32(810).fork()).ldelim();
    }
    for (const v of message.concurrentConnectionJoinResults) {
      ConcurrentConnectionJoin.encode(v!, writer.uint32(890).fork()).ldelim();
    }
    if (message.loggedOff !== undefined) {
      LoggedOff.encode(message.loggedOff, writer.uint32(818).fork()).ldelim();
    }
    if (message.passwordChangeResult !== undefined) {
      PasswordChangeResult.encode(message.passwordChangeResult, writer.uint32(826).fork()).ldelim();
    }
    for (const v of message.userMessages) {
      UserMessage.encode(v!, writer.uint32(834).fork()).ldelim();
    }
    for (const v of message.obtainDemoCredentialsResults) {
      ObtainDemoCredentialsResult.encode(v!, writer.uint32(842).fork()).ldelim();
    }
    for (const v of message.userSessionStateResults) {
      UserSessionStateResult.encode(v!, writer.uint32(850).fork()).ldelim();
    }
    if (message.ping !== undefined) {
      Ping.encode(message.ping, writer.uint32(858).fork()).ldelim();
    }
    if (message.pong !== undefined) {
      Pong.encode(message.pong, writer.uint32(866).fork()).ldelim();
    }
    if (message.logonRoutineServer !== undefined) {
      LogonRoutineServer.encode(message.logonRoutineServer, writer.uint32(874).fork()).ldelim();
    }
    if (message.passwordChangeRoutineServer !== undefined) {
      PasswordChangeRoutineServer.encode(message.passwordChangeRoutineServer, writer.uint32(882).fork()).ldelim();
    }
    for (const v of message.setCollapsingLevelResults) {
      SetCollapsingLevelResult.encode(v!, writer.uint32(898).fork()).ldelim();
    }
    for (const v of message.informationReports) {
      InformationReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.orderRequestRejects) {
      OrderRequestReject.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.orderRequestAcks) {
      OrderRequestAck.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    for (const v of message.tradeSubscriptionStatuses) {
      TradeSubscriptionStatus.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tradeSnapshotCompletions) {
      TradeSnapshotCompletion.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.orderStatuses) {
      OrderStatus.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.positionStatuses) {
      PositionStatus.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.collateralStatuses) {
      CollateralStatus.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.accountSummaryStatuses) {
      AccountSummaryStatus.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    for (const v of message.marketDataSubscriptionStatuses) {
      MarketDataSubscriptionStatus.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.realTimeMarketData) {
      RealTimeMarketData.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.timeAndSalesReports) {
      TimeAndSalesReport.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.timeBarReports) {
      TimeBarReport.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.volumeProfileReports) {
      VolumeProfileReport.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.nonTimedBarReports) {
      NonTimedBarReport.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.ruleResults) {
      RuleResult.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.accountLogonResults) {
      AccountLogonResult.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.accountPasswordChangeResults) {
      AccountPasswordChangeResult.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.accountLogoffResults) {
      AccountLogoffResult.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.metadataAdminResults) {
      MetadataAdminResult.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.otcReports) {
      OtcReport.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.rfqReports) {
      RFQReport.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    for (const v of message.optionCalculationReports) {
      OptionCalculationReport.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerMsg {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          if (tag !== 802) {
            break;
          }

          message.logonResult = LogonResult.decode(reader, reader.uint32());
          continue;
        case 101:
          if (tag !== 810) {
            break;
          }

          message.restoreOrJoinSessionResult = RestoreOrJoinSessionResult.decode(reader, reader.uint32());
          continue;
        case 111:
          if (tag !== 890) {
            break;
          }

          message.concurrentConnectionJoinResults.push(ConcurrentConnectionJoin.decode(reader, reader.uint32()));
          continue;
        case 102:
          if (tag !== 818) {
            break;
          }

          message.loggedOff = LoggedOff.decode(reader, reader.uint32());
          continue;
        case 103:
          if (tag !== 826) {
            break;
          }

          message.passwordChangeResult = PasswordChangeResult.decode(reader, reader.uint32());
          continue;
        case 104:
          if (tag !== 834) {
            break;
          }

          message.userMessages.push(UserMessage.decode(reader, reader.uint32()));
          continue;
        case 105:
          if (tag !== 842) {
            break;
          }

          message.obtainDemoCredentialsResults.push(ObtainDemoCredentialsResult.decode(reader, reader.uint32()));
          continue;
        case 106:
          if (tag !== 850) {
            break;
          }

          message.userSessionStateResults.push(UserSessionStateResult.decode(reader, reader.uint32()));
          continue;
        case 107:
          if (tag !== 858) {
            break;
          }

          message.ping = Ping.decode(reader, reader.uint32());
          continue;
        case 108:
          if (tag !== 866) {
            break;
          }

          message.pong = Pong.decode(reader, reader.uint32());
          continue;
        case 109:
          if (tag !== 874) {
            break;
          }

          message.logonRoutineServer = LogonRoutineServer.decode(reader, reader.uint32());
          continue;
        case 110:
          if (tag !== 882) {
            break;
          }

          message.passwordChangeRoutineServer = PasswordChangeRoutineServer.decode(reader, reader.uint32());
          continue;
        case 112:
          if (tag !== 898) {
            break;
          }

          message.setCollapsingLevelResults.push(SetCollapsingLevelResult.decode(reader, reader.uint32()));
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.informationReports.push(InformationReport.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderRequestRejects.push(OrderRequestReject.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.orderRequestAcks.push(OrderRequestAck.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeSubscriptionStatuses.push(TradeSubscriptionStatus.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tradeSnapshotCompletions.push(TradeSnapshotCompletion.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orderStatuses.push(OrderStatus.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.positionStatuses.push(PositionStatus.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.collateralStatuses.push(CollateralStatus.decode(reader, reader.uint32()));
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.accountSummaryStatuses.push(AccountSummaryStatus.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.marketDataSubscriptionStatuses.push(MarketDataSubscriptionStatus.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.realTimeMarketData.push(RealTimeMarketData.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.timeAndSalesReports.push(TimeAndSalesReport.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.timeBarReports.push(TimeBarReport.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.volumeProfileReports.push(VolumeProfileReport.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.nonTimedBarReports.push(NonTimedBarReport.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ruleResults.push(RuleResult.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.accountLogonResults.push(AccountLogonResult.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.accountPasswordChangeResults.push(AccountPasswordChangeResult.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.accountLogoffResults.push(AccountLogoffResult.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.metadataAdminResults.push(MetadataAdminResult.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.otcReports.push(OtcReport.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.rfqReports.push(RFQReport.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.optionCalculationReports.push(OptionCalculationReport.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerMsg {
    return {
      logonResult: isSet(object.logonResult) ? LogonResult.fromJSON(object.logonResult) : undefined,
      restoreOrJoinSessionResult: isSet(object.restoreOrJoinSessionResult)
        ? RestoreOrJoinSessionResult.fromJSON(object.restoreOrJoinSessionResult)
        : undefined,
      concurrentConnectionJoinResults: globalThis.Array.isArray(object?.concurrentConnectionJoinResults)
        ? object.concurrentConnectionJoinResults.map((e: any) => ConcurrentConnectionJoin.fromJSON(e))
        : [],
      loggedOff: isSet(object.loggedOff) ? LoggedOff.fromJSON(object.loggedOff) : undefined,
      passwordChangeResult: isSet(object.passwordChangeResult)
        ? PasswordChangeResult.fromJSON(object.passwordChangeResult)
        : undefined,
      userMessages: globalThis.Array.isArray(object?.userMessages)
        ? object.userMessages.map((e: any) => UserMessage.fromJSON(e))
        : [],
      obtainDemoCredentialsResults: globalThis.Array.isArray(object?.obtainDemoCredentialsResults)
        ? object.obtainDemoCredentialsResults.map((e: any) => ObtainDemoCredentialsResult.fromJSON(e))
        : [],
      userSessionStateResults: globalThis.Array.isArray(object?.userSessionStateResults)
        ? object.userSessionStateResults.map((e: any) => UserSessionStateResult.fromJSON(e))
        : [],
      ping: isSet(object.ping) ? Ping.fromJSON(object.ping) : undefined,
      pong: isSet(object.pong) ? Pong.fromJSON(object.pong) : undefined,
      logonRoutineServer: isSet(object.logonRoutineServer)
        ? LogonRoutineServer.fromJSON(object.logonRoutineServer)
        : undefined,
      passwordChangeRoutineServer: isSet(object.passwordChangeRoutineServer)
        ? PasswordChangeRoutineServer.fromJSON(object.passwordChangeRoutineServer)
        : undefined,
      setCollapsingLevelResults: globalThis.Array.isArray(object?.setCollapsingLevelResults)
        ? object.setCollapsingLevelResults.map((e: any) => SetCollapsingLevelResult.fromJSON(e))
        : [],
      informationReports: globalThis.Array.isArray(object?.informationReports)
        ? object.informationReports.map((e: any) => InformationReport.fromJSON(e))
        : [],
      orderRequestRejects: globalThis.Array.isArray(object?.orderRequestRejects)
        ? object.orderRequestRejects.map((e: any) => OrderRequestReject.fromJSON(e))
        : [],
      orderRequestAcks: globalThis.Array.isArray(object?.orderRequestAcks)
        ? object.orderRequestAcks.map((e: any) => OrderRequestAck.fromJSON(e))
        : [],
      tradeSubscriptionStatuses: globalThis.Array.isArray(object?.tradeSubscriptionStatuses)
        ? object.tradeSubscriptionStatuses.map((e: any) => TradeSubscriptionStatus.fromJSON(e))
        : [],
      tradeSnapshotCompletions: globalThis.Array.isArray(object?.tradeSnapshotCompletions)
        ? object.tradeSnapshotCompletions.map((e: any) => TradeSnapshotCompletion.fromJSON(e))
        : [],
      orderStatuses: globalThis.Array.isArray(object?.orderStatuses)
        ? object.orderStatuses.map((e: any) => OrderStatus.fromJSON(e))
        : [],
      positionStatuses: globalThis.Array.isArray(object?.positionStatuses)
        ? object.positionStatuses.map((e: any) => PositionStatus.fromJSON(e))
        : [],
      collateralStatuses: globalThis.Array.isArray(object?.collateralStatuses)
        ? object.collateralStatuses.map((e: any) => CollateralStatus.fromJSON(e))
        : [],
      accountSummaryStatuses: globalThis.Array.isArray(object?.accountSummaryStatuses)
        ? object.accountSummaryStatuses.map((e: any) => AccountSummaryStatus.fromJSON(e))
        : [],
      marketDataSubscriptionStatuses: globalThis.Array.isArray(object?.marketDataSubscriptionStatuses)
        ? object.marketDataSubscriptionStatuses.map((e: any) => MarketDataSubscriptionStatus.fromJSON(e))
        : [],
      realTimeMarketData: globalThis.Array.isArray(object?.realTimeMarketData)
        ? object.realTimeMarketData.map((e: any) => RealTimeMarketData.fromJSON(e))
        : [],
      timeAndSalesReports: globalThis.Array.isArray(object?.timeAndSalesReports)
        ? object.timeAndSalesReports.map((e: any) => TimeAndSalesReport.fromJSON(e))
        : [],
      timeBarReports: globalThis.Array.isArray(object?.timeBarReports)
        ? object.timeBarReports.map((e: any) => TimeBarReport.fromJSON(e))
        : [],
      volumeProfileReports: globalThis.Array.isArray(object?.volumeProfileReports)
        ? object.volumeProfileReports.map((e: any) => VolumeProfileReport.fromJSON(e))
        : [],
      nonTimedBarReports: globalThis.Array.isArray(object?.nonTimedBarReports)
        ? object.nonTimedBarReports.map((e: any) => NonTimedBarReport.fromJSON(e))
        : [],
      ruleResults: globalThis.Array.isArray(object?.ruleResults)
        ? object.ruleResults.map((e: any) => RuleResult.fromJSON(e))
        : [],
      accountLogonResults: globalThis.Array.isArray(object?.accountLogonResults)
        ? object.accountLogonResults.map((e: any) => AccountLogonResult.fromJSON(e))
        : [],
      accountPasswordChangeResults: globalThis.Array.isArray(object?.accountPasswordChangeResults)
        ? object.accountPasswordChangeResults.map((e: any) => AccountPasswordChangeResult.fromJSON(e))
        : [],
      accountLogoffResults: globalThis.Array.isArray(object?.accountLogoffResults)
        ? object.accountLogoffResults.map((e: any) => AccountLogoffResult.fromJSON(e))
        : [],
      metadataAdminResults: globalThis.Array.isArray(object?.metadataAdminResults)
        ? object.metadataAdminResults.map((e: any) => MetadataAdminResult.fromJSON(e))
        : [],
      otcReports: globalThis.Array.isArray(object?.otcReports)
        ? object.otcReports.map((e: any) => OtcReport.fromJSON(e))
        : [],
      rfqReports: globalThis.Array.isArray(object?.rfqReports)
        ? object.rfqReports.map((e: any) => RFQReport.fromJSON(e))
        : [],
      optionCalculationReports: globalThis.Array.isArray(object?.optionCalculationReports)
        ? object.optionCalculationReports.map((e: any) => OptionCalculationReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerMsg): unknown {
    const obj: any = {};
    if (message.logonResult !== undefined) {
      obj.logonResult = LogonResult.toJSON(message.logonResult);
    }
    if (message.restoreOrJoinSessionResult !== undefined) {
      obj.restoreOrJoinSessionResult = RestoreOrJoinSessionResult.toJSON(message.restoreOrJoinSessionResult);
    }
    if (message.concurrentConnectionJoinResults?.length) {
      obj.concurrentConnectionJoinResults = message.concurrentConnectionJoinResults.map((e) =>
        ConcurrentConnectionJoin.toJSON(e)
      );
    }
    if (message.loggedOff !== undefined) {
      obj.loggedOff = LoggedOff.toJSON(message.loggedOff);
    }
    if (message.passwordChangeResult !== undefined) {
      obj.passwordChangeResult = PasswordChangeResult.toJSON(message.passwordChangeResult);
    }
    if (message.userMessages?.length) {
      obj.userMessages = message.userMessages.map((e) => UserMessage.toJSON(e));
    }
    if (message.obtainDemoCredentialsResults?.length) {
      obj.obtainDemoCredentialsResults = message.obtainDemoCredentialsResults.map((e) =>
        ObtainDemoCredentialsResult.toJSON(e)
      );
    }
    if (message.userSessionStateResults?.length) {
      obj.userSessionStateResults = message.userSessionStateResults.map((e) => UserSessionStateResult.toJSON(e));
    }
    if (message.ping !== undefined) {
      obj.ping = Ping.toJSON(message.ping);
    }
    if (message.pong !== undefined) {
      obj.pong = Pong.toJSON(message.pong);
    }
    if (message.logonRoutineServer !== undefined) {
      obj.logonRoutineServer = LogonRoutineServer.toJSON(message.logonRoutineServer);
    }
    if (message.passwordChangeRoutineServer !== undefined) {
      obj.passwordChangeRoutineServer = PasswordChangeRoutineServer.toJSON(message.passwordChangeRoutineServer);
    }
    if (message.setCollapsingLevelResults?.length) {
      obj.setCollapsingLevelResults = message.setCollapsingLevelResults.map((e) => SetCollapsingLevelResult.toJSON(e));
    }
    if (message.informationReports?.length) {
      obj.informationReports = message.informationReports.map((e) => InformationReport.toJSON(e));
    }
    if (message.orderRequestRejects?.length) {
      obj.orderRequestRejects = message.orderRequestRejects.map((e) => OrderRequestReject.toJSON(e));
    }
    if (message.orderRequestAcks?.length) {
      obj.orderRequestAcks = message.orderRequestAcks.map((e) => OrderRequestAck.toJSON(e));
    }
    if (message.tradeSubscriptionStatuses?.length) {
      obj.tradeSubscriptionStatuses = message.tradeSubscriptionStatuses.map((e) => TradeSubscriptionStatus.toJSON(e));
    }
    if (message.tradeSnapshotCompletions?.length) {
      obj.tradeSnapshotCompletions = message.tradeSnapshotCompletions.map((e) => TradeSnapshotCompletion.toJSON(e));
    }
    if (message.orderStatuses?.length) {
      obj.orderStatuses = message.orderStatuses.map((e) => OrderStatus.toJSON(e));
    }
    if (message.positionStatuses?.length) {
      obj.positionStatuses = message.positionStatuses.map((e) => PositionStatus.toJSON(e));
    }
    if (message.collateralStatuses?.length) {
      obj.collateralStatuses = message.collateralStatuses.map((e) => CollateralStatus.toJSON(e));
    }
    if (message.accountSummaryStatuses?.length) {
      obj.accountSummaryStatuses = message.accountSummaryStatuses.map((e) => AccountSummaryStatus.toJSON(e));
    }
    if (message.marketDataSubscriptionStatuses?.length) {
      obj.marketDataSubscriptionStatuses = message.marketDataSubscriptionStatuses.map((e) =>
        MarketDataSubscriptionStatus.toJSON(e)
      );
    }
    if (message.realTimeMarketData?.length) {
      obj.realTimeMarketData = message.realTimeMarketData.map((e) => RealTimeMarketData.toJSON(e));
    }
    if (message.timeAndSalesReports?.length) {
      obj.timeAndSalesReports = message.timeAndSalesReports.map((e) => TimeAndSalesReport.toJSON(e));
    }
    if (message.timeBarReports?.length) {
      obj.timeBarReports = message.timeBarReports.map((e) => TimeBarReport.toJSON(e));
    }
    if (message.volumeProfileReports?.length) {
      obj.volumeProfileReports = message.volumeProfileReports.map((e) => VolumeProfileReport.toJSON(e));
    }
    if (message.nonTimedBarReports?.length) {
      obj.nonTimedBarReports = message.nonTimedBarReports.map((e) => NonTimedBarReport.toJSON(e));
    }
    if (message.ruleResults?.length) {
      obj.ruleResults = message.ruleResults.map((e) => RuleResult.toJSON(e));
    }
    if (message.accountLogonResults?.length) {
      obj.accountLogonResults = message.accountLogonResults.map((e) => AccountLogonResult.toJSON(e));
    }
    if (message.accountPasswordChangeResults?.length) {
      obj.accountPasswordChangeResults = message.accountPasswordChangeResults.map((e) =>
        AccountPasswordChangeResult.toJSON(e)
      );
    }
    if (message.accountLogoffResults?.length) {
      obj.accountLogoffResults = message.accountLogoffResults.map((e) => AccountLogoffResult.toJSON(e));
    }
    if (message.metadataAdminResults?.length) {
      obj.metadataAdminResults = message.metadataAdminResults.map((e) => MetadataAdminResult.toJSON(e));
    }
    if (message.otcReports?.length) {
      obj.otcReports = message.otcReports.map((e) => OtcReport.toJSON(e));
    }
    if (message.rfqReports?.length) {
      obj.rfqReports = message.rfqReports.map((e) => RFQReport.toJSON(e));
    }
    if (message.optionCalculationReports?.length) {
      obj.optionCalculationReports = message.optionCalculationReports.map((e) => OptionCalculationReport.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerMsg>, I>>(base?: I): ServerMsg {
    return ServerMsg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerMsg>, I>>(object: I): ServerMsg {
    const message = createBaseServerMsg();
    message.logonResult = (object.logonResult !== undefined && object.logonResult !== null)
      ? LogonResult.fromPartial(object.logonResult)
      : undefined;
    message.restoreOrJoinSessionResult =
      (object.restoreOrJoinSessionResult !== undefined && object.restoreOrJoinSessionResult !== null)
        ? RestoreOrJoinSessionResult.fromPartial(object.restoreOrJoinSessionResult)
        : undefined;
    message.concurrentConnectionJoinResults =
      object.concurrentConnectionJoinResults?.map((e) => ConcurrentConnectionJoin.fromPartial(e)) || [];
    message.loggedOff = (object.loggedOff !== undefined && object.loggedOff !== null)
      ? LoggedOff.fromPartial(object.loggedOff)
      : undefined;
    message.passwordChangeResult = (object.passwordChangeResult !== undefined && object.passwordChangeResult !== null)
      ? PasswordChangeResult.fromPartial(object.passwordChangeResult)
      : undefined;
    message.userMessages = object.userMessages?.map((e) => UserMessage.fromPartial(e)) || [];
    message.obtainDemoCredentialsResults =
      object.obtainDemoCredentialsResults?.map((e) => ObtainDemoCredentialsResult.fromPartial(e)) || [];
    message.userSessionStateResults =
      object.userSessionStateResults?.map((e) => UserSessionStateResult.fromPartial(e)) || [];
    message.ping = (object.ping !== undefined && object.ping !== null) ? Ping.fromPartial(object.ping) : undefined;
    message.pong = (object.pong !== undefined && object.pong !== null) ? Pong.fromPartial(object.pong) : undefined;
    message.logonRoutineServer = (object.logonRoutineServer !== undefined && object.logonRoutineServer !== null)
      ? LogonRoutineServer.fromPartial(object.logonRoutineServer)
      : undefined;
    message.passwordChangeRoutineServer =
      (object.passwordChangeRoutineServer !== undefined && object.passwordChangeRoutineServer !== null)
        ? PasswordChangeRoutineServer.fromPartial(object.passwordChangeRoutineServer)
        : undefined;
    message.setCollapsingLevelResults =
      object.setCollapsingLevelResults?.map((e) => SetCollapsingLevelResult.fromPartial(e)) || [];
    message.informationReports = object.informationReports?.map((e) => InformationReport.fromPartial(e)) || [];
    message.orderRequestRejects = object.orderRequestRejects?.map((e) => OrderRequestReject.fromPartial(e)) || [];
    message.orderRequestAcks = object.orderRequestAcks?.map((e) => OrderRequestAck.fromPartial(e)) || [];
    message.tradeSubscriptionStatuses =
      object.tradeSubscriptionStatuses?.map((e) => TradeSubscriptionStatus.fromPartial(e)) || [];
    message.tradeSnapshotCompletions =
      object.tradeSnapshotCompletions?.map((e) => TradeSnapshotCompletion.fromPartial(e)) || [];
    message.orderStatuses = object.orderStatuses?.map((e) => OrderStatus.fromPartial(e)) || [];
    message.positionStatuses = object.positionStatuses?.map((e) => PositionStatus.fromPartial(e)) || [];
    message.collateralStatuses = object.collateralStatuses?.map((e) => CollateralStatus.fromPartial(e)) || [];
    message.accountSummaryStatuses = object.accountSummaryStatuses?.map((e) => AccountSummaryStatus.fromPartial(e)) ||
      [];
    message.marketDataSubscriptionStatuses =
      object.marketDataSubscriptionStatuses?.map((e) => MarketDataSubscriptionStatus.fromPartial(e)) || [];
    message.realTimeMarketData = object.realTimeMarketData?.map((e) => RealTimeMarketData.fromPartial(e)) || [];
    message.timeAndSalesReports = object.timeAndSalesReports?.map((e) => TimeAndSalesReport.fromPartial(e)) || [];
    message.timeBarReports = object.timeBarReports?.map((e) => TimeBarReport.fromPartial(e)) || [];
    message.volumeProfileReports = object.volumeProfileReports?.map((e) => VolumeProfileReport.fromPartial(e)) || [];
    message.nonTimedBarReports = object.nonTimedBarReports?.map((e) => NonTimedBarReport.fromPartial(e)) || [];
    message.ruleResults = object.ruleResults?.map((e) => RuleResult.fromPartial(e)) || [];
    message.accountLogonResults = object.accountLogonResults?.map((e) => AccountLogonResult.fromPartial(e)) || [];
    message.accountPasswordChangeResults =
      object.accountPasswordChangeResults?.map((e) => AccountPasswordChangeResult.fromPartial(e)) || [];
    message.accountLogoffResults = object.accountLogoffResults?.map((e) => AccountLogoffResult.fromPartial(e)) || [];
    message.metadataAdminResults = object.metadataAdminResults?.map((e) => MetadataAdminResult.fromPartial(e)) || [];
    message.otcReports = object.otcReports?.map((e) => OtcReport.fromPartial(e)) || [];
    message.rfqReports = object.rfqReports?.map((e) => RFQReport.fromPartial(e)) || [];
    message.optionCalculationReports =
      object.optionCalculationReports?.map((e) => OptionCalculationReport.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInformationRequest(): InformationRequest {
  return {
    id: 0,
    subscribe: false,
    accountsRequest: undefined,
    symbolResolutionRequest: undefined,
    lastStatementBalancesRequest: undefined,
    currencyRatesRequest: undefined,
    sessionInformationRequest: undefined,
    historicalOrdersRequest: undefined,
    optionMaturityListRequest: undefined,
    instrumentGroupRequest: undefined,
    atTheMoneyStrikeRequest: undefined,
    strategyDefinitionRequest: undefined,
    sessionTimerangeRequest: undefined,
    tradingDayTimerangeRequest: undefined,
    orderEntitlementRequest: undefined,
    symbolCategoryListRequest: undefined,
    symbolCategoryRequest: undefined,
    symbolCategoryListByInstrumentTypeRequest: undefined,
    symbolListRequest: undefined,
    symbolRequest: undefined,
    contractMetadataRequest: undefined,
    algoStrategyDefinitionRequest: undefined,
    contributorMetadataRequest: undefined,
    apiLimitRequest: undefined,
    brokerageTradingFeatureEntitlementRequest: undefined,
    orderStatusRequest: undefined,
    productSearchRequest: undefined,
    marketStateMetadataRequest: undefined,
    instrumentDefinitionRequest: undefined,
    exchangeMetadataRequest: undefined,
    instrumentGroupBySecuritiesRequest: undefined,
    instrumentGroupByExchangeRequest: undefined,
    exchangeSecuritiesRequest: undefined,
    careAutoActivationParametersRequest: undefined,
  };
}

export const InformationRequest = {
  encode(message: InformationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.subscribe !== undefined && message.subscribe !== false) {
      writer.uint32(16).bool(message.subscribe);
    }
    if (message.accountsRequest !== undefined) {
      AccountsRequest.encode(message.accountsRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.symbolResolutionRequest !== undefined) {
      SymbolResolutionRequest.encode(message.symbolResolutionRequest, writer.uint32(34).fork()).ldelim();
    }
    if (message.lastStatementBalancesRequest !== undefined) {
      LastStatementBalancesRequest.encode(message.lastStatementBalancesRequest, writer.uint32(42).fork()).ldelim();
    }
    if (message.currencyRatesRequest !== undefined) {
      CurrencyRatesRequest.encode(message.currencyRatesRequest, writer.uint32(50).fork()).ldelim();
    }
    if (message.sessionInformationRequest !== undefined) {
      SessionInformationRequest.encode(message.sessionInformationRequest, writer.uint32(58).fork()).ldelim();
    }
    if (message.historicalOrdersRequest !== undefined) {
      HistoricalOrdersRequest.encode(message.historicalOrdersRequest, writer.uint32(106).fork()).ldelim();
    }
    if (message.optionMaturityListRequest !== undefined) {
      OptionMaturityListRequest.encode(message.optionMaturityListRequest, writer.uint32(114).fork()).ldelim();
    }
    if (message.instrumentGroupRequest !== undefined) {
      InstrumentGroupRequest.encode(message.instrumentGroupRequest, writer.uint32(122).fork()).ldelim();
    }
    if (message.atTheMoneyStrikeRequest !== undefined) {
      AtTheMoneyStrikeRequest.encode(message.atTheMoneyStrikeRequest, writer.uint32(130).fork()).ldelim();
    }
    if (message.strategyDefinitionRequest !== undefined) {
      StrategyDefinitionRequest.encode(message.strategyDefinitionRequest, writer.uint32(138).fork()).ldelim();
    }
    if (message.sessionTimerangeRequest !== undefined) {
      SessionTimeRangeRequest.encode(message.sessionTimerangeRequest, writer.uint32(146).fork()).ldelim();
    }
    if (message.tradingDayTimerangeRequest !== undefined) {
      TradingDayTimeRangeRequest.encode(message.tradingDayTimerangeRequest, writer.uint32(154).fork()).ldelim();
    }
    if (message.orderEntitlementRequest !== undefined) {
      OrderEntitlementRequest.encode(message.orderEntitlementRequest, writer.uint32(162).fork()).ldelim();
    }
    if (message.symbolCategoryListRequest !== undefined) {
      SymbolCategoryListRequest.encode(message.symbolCategoryListRequest, writer.uint32(170).fork()).ldelim();
    }
    if (message.symbolCategoryRequest !== undefined) {
      SymbolCategoryRequest.encode(message.symbolCategoryRequest, writer.uint32(218).fork()).ldelim();
    }
    if (message.symbolCategoryListByInstrumentTypeRequest !== undefined) {
      SymbolCategoryListByInstrumentTypeRequest.encode(
        message.symbolCategoryListByInstrumentTypeRequest,
        writer.uint32(266).fork(),
      ).ldelim();
    }
    if (message.symbolListRequest !== undefined) {
      SymbolListRequest.encode(message.symbolListRequest, writer.uint32(178).fork()).ldelim();
    }
    if (message.symbolRequest !== undefined) {
      SymbolRequest.encode(message.symbolRequest, writer.uint32(186).fork()).ldelim();
    }
    if (message.contractMetadataRequest !== undefined) {
      ContractMetadataRequest.encode(message.contractMetadataRequest, writer.uint32(202).fork()).ldelim();
    }
    if (message.algoStrategyDefinitionRequest !== undefined) {
      AlgoStrategyDefinitionRequest.encode(message.algoStrategyDefinitionRequest, writer.uint32(210).fork()).ldelim();
    }
    if (message.contributorMetadataRequest !== undefined) {
      ContributorMetadataRequest.encode(message.contributorMetadataRequest, writer.uint32(226).fork()).ldelim();
    }
    if (message.apiLimitRequest !== undefined) {
      ApiLimitRequest.encode(message.apiLimitRequest, writer.uint32(234).fork()).ldelim();
    }
    if (message.brokerageTradingFeatureEntitlementRequest !== undefined) {
      BrokerageTradingFeatureEntitlementRequest.encode(
        message.brokerageTradingFeatureEntitlementRequest,
        writer.uint32(242).fork(),
      ).ldelim();
    }
    if (message.orderStatusRequest !== undefined) {
      OrderStatusRequest.encode(message.orderStatusRequest, writer.uint32(250).fork()).ldelim();
    }
    if (message.productSearchRequest !== undefined) {
      ProductSearchRequest.encode(message.productSearchRequest, writer.uint32(258).fork()).ldelim();
    }
    if (message.marketStateMetadataRequest !== undefined) {
      MarketStateMetadataRequest.encode(message.marketStateMetadataRequest, writer.uint32(274).fork()).ldelim();
    }
    if (message.instrumentDefinitionRequest !== undefined) {
      InstrumentDefinitionRequest.encode(message.instrumentDefinitionRequest, writer.uint32(282).fork()).ldelim();
    }
    if (message.exchangeMetadataRequest !== undefined) {
      ExchangeMetadataRequest.encode(message.exchangeMetadataRequest, writer.uint32(290).fork()).ldelim();
    }
    if (message.instrumentGroupBySecuritiesRequest !== undefined) {
      InstrumentGroupBySecuritiesRequest.encode(message.instrumentGroupBySecuritiesRequest, writer.uint32(298).fork())
        .ldelim();
    }
    if (message.instrumentGroupByExchangeRequest !== undefined) {
      InstrumentGroupByExchangeRequest.encode(message.instrumentGroupByExchangeRequest, writer.uint32(306).fork())
        .ldelim();
    }
    if (message.exchangeSecuritiesRequest !== undefined) {
      ExchangeSecuritiesRequest.encode(message.exchangeSecuritiesRequest, writer.uint32(314).fork()).ldelim();
    }
    if (message.careAutoActivationParametersRequest !== undefined) {
      CareAutoActivationParametersRequest.encode(message.careAutoActivationParametersRequest, writer.uint32(322).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InformationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInformationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.subscribe = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountsRequest = AccountsRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.symbolResolutionRequest = SymbolResolutionRequest.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastStatementBalancesRequest = LastStatementBalancesRequest.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.currencyRatesRequest = CurrencyRatesRequest.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sessionInformationRequest = SessionInformationRequest.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.historicalOrdersRequest = HistoricalOrdersRequest.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.optionMaturityListRequest = OptionMaturityListRequest.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.instrumentGroupRequest = InstrumentGroupRequest.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.atTheMoneyStrikeRequest = AtTheMoneyStrikeRequest.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.strategyDefinitionRequest = StrategyDefinitionRequest.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.sessionTimerangeRequest = SessionTimeRangeRequest.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.tradingDayTimerangeRequest = TradingDayTimeRangeRequest.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.orderEntitlementRequest = OrderEntitlementRequest.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.symbolCategoryListRequest = SymbolCategoryListRequest.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.symbolCategoryRequest = SymbolCategoryRequest.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.symbolCategoryListByInstrumentTypeRequest = SymbolCategoryListByInstrumentTypeRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.symbolListRequest = SymbolListRequest.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.symbolRequest = SymbolRequest.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.contractMetadataRequest = ContractMetadataRequest.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.algoStrategyDefinitionRequest = AlgoStrategyDefinitionRequest.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.contributorMetadataRequest = ContributorMetadataRequest.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.apiLimitRequest = ApiLimitRequest.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.brokerageTradingFeatureEntitlementRequest = BrokerageTradingFeatureEntitlementRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.orderStatusRequest = OrderStatusRequest.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.productSearchRequest = ProductSearchRequest.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.marketStateMetadataRequest = MarketStateMetadataRequest.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.instrumentDefinitionRequest = InstrumentDefinitionRequest.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.exchangeMetadataRequest = ExchangeMetadataRequest.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.instrumentGroupBySecuritiesRequest = InstrumentGroupBySecuritiesRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.instrumentGroupByExchangeRequest = InstrumentGroupByExchangeRequest.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.exchangeSecuritiesRequest = ExchangeSecuritiesRequest.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.careAutoActivationParametersRequest = CareAutoActivationParametersRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InformationRequest {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      subscribe: isSet(object.subscribe) ? globalThis.Boolean(object.subscribe) : false,
      accountsRequest: isSet(object.accountsRequest) ? AccountsRequest.fromJSON(object.accountsRequest) : undefined,
      symbolResolutionRequest: isSet(object.symbolResolutionRequest)
        ? SymbolResolutionRequest.fromJSON(object.symbolResolutionRequest)
        : undefined,
      lastStatementBalancesRequest: isSet(object.lastStatementBalancesRequest)
        ? LastStatementBalancesRequest.fromJSON(object.lastStatementBalancesRequest)
        : undefined,
      currencyRatesRequest: isSet(object.currencyRatesRequest)
        ? CurrencyRatesRequest.fromJSON(object.currencyRatesRequest)
        : undefined,
      sessionInformationRequest: isSet(object.sessionInformationRequest)
        ? SessionInformationRequest.fromJSON(object.sessionInformationRequest)
        : undefined,
      historicalOrdersRequest: isSet(object.historicalOrdersRequest)
        ? HistoricalOrdersRequest.fromJSON(object.historicalOrdersRequest)
        : undefined,
      optionMaturityListRequest: isSet(object.optionMaturityListRequest)
        ? OptionMaturityListRequest.fromJSON(object.optionMaturityListRequest)
        : undefined,
      instrumentGroupRequest: isSet(object.instrumentGroupRequest)
        ? InstrumentGroupRequest.fromJSON(object.instrumentGroupRequest)
        : undefined,
      atTheMoneyStrikeRequest: isSet(object.atTheMoneyStrikeRequest)
        ? AtTheMoneyStrikeRequest.fromJSON(object.atTheMoneyStrikeRequest)
        : undefined,
      strategyDefinitionRequest: isSet(object.strategyDefinitionRequest)
        ? StrategyDefinitionRequest.fromJSON(object.strategyDefinitionRequest)
        : undefined,
      sessionTimerangeRequest: isSet(object.sessionTimerangeRequest)
        ? SessionTimeRangeRequest.fromJSON(object.sessionTimerangeRequest)
        : undefined,
      tradingDayTimerangeRequest: isSet(object.tradingDayTimerangeRequest)
        ? TradingDayTimeRangeRequest.fromJSON(object.tradingDayTimerangeRequest)
        : undefined,
      orderEntitlementRequest: isSet(object.orderEntitlementRequest)
        ? OrderEntitlementRequest.fromJSON(object.orderEntitlementRequest)
        : undefined,
      symbolCategoryListRequest: isSet(object.symbolCategoryListRequest)
        ? SymbolCategoryListRequest.fromJSON(object.symbolCategoryListRequest)
        : undefined,
      symbolCategoryRequest: isSet(object.symbolCategoryRequest)
        ? SymbolCategoryRequest.fromJSON(object.symbolCategoryRequest)
        : undefined,
      symbolCategoryListByInstrumentTypeRequest: isSet(object.symbolCategoryListByInstrumentTypeRequest)
        ? SymbolCategoryListByInstrumentTypeRequest.fromJSON(object.symbolCategoryListByInstrumentTypeRequest)
        : undefined,
      symbolListRequest: isSet(object.symbolListRequest)
        ? SymbolListRequest.fromJSON(object.symbolListRequest)
        : undefined,
      symbolRequest: isSet(object.symbolRequest) ? SymbolRequest.fromJSON(object.symbolRequest) : undefined,
      contractMetadataRequest: isSet(object.contractMetadataRequest)
        ? ContractMetadataRequest.fromJSON(object.contractMetadataRequest)
        : undefined,
      algoStrategyDefinitionRequest: isSet(object.algoStrategyDefinitionRequest)
        ? AlgoStrategyDefinitionRequest.fromJSON(object.algoStrategyDefinitionRequest)
        : undefined,
      contributorMetadataRequest: isSet(object.contributorMetadataRequest)
        ? ContributorMetadataRequest.fromJSON(object.contributorMetadataRequest)
        : undefined,
      apiLimitRequest: isSet(object.apiLimitRequest) ? ApiLimitRequest.fromJSON(object.apiLimitRequest) : undefined,
      brokerageTradingFeatureEntitlementRequest: isSet(object.brokerageTradingFeatureEntitlementRequest)
        ? BrokerageTradingFeatureEntitlementRequest.fromJSON(object.brokerageTradingFeatureEntitlementRequest)
        : undefined,
      orderStatusRequest: isSet(object.orderStatusRequest)
        ? OrderStatusRequest.fromJSON(object.orderStatusRequest)
        : undefined,
      productSearchRequest: isSet(object.productSearchRequest)
        ? ProductSearchRequest.fromJSON(object.productSearchRequest)
        : undefined,
      marketStateMetadataRequest: isSet(object.marketStateMetadataRequest)
        ? MarketStateMetadataRequest.fromJSON(object.marketStateMetadataRequest)
        : undefined,
      instrumentDefinitionRequest: isSet(object.instrumentDefinitionRequest)
        ? InstrumentDefinitionRequest.fromJSON(object.instrumentDefinitionRequest)
        : undefined,
      exchangeMetadataRequest: isSet(object.exchangeMetadataRequest)
        ? ExchangeMetadataRequest.fromJSON(object.exchangeMetadataRequest)
        : undefined,
      instrumentGroupBySecuritiesRequest: isSet(object.instrumentGroupBySecuritiesRequest)
        ? InstrumentGroupBySecuritiesRequest.fromJSON(object.instrumentGroupBySecuritiesRequest)
        : undefined,
      instrumentGroupByExchangeRequest: isSet(object.instrumentGroupByExchangeRequest)
        ? InstrumentGroupByExchangeRequest.fromJSON(object.instrumentGroupByExchangeRequest)
        : undefined,
      exchangeSecuritiesRequest: isSet(object.exchangeSecuritiesRequest)
        ? ExchangeSecuritiesRequest.fromJSON(object.exchangeSecuritiesRequest)
        : undefined,
      careAutoActivationParametersRequest: isSet(object.careAutoActivationParametersRequest)
        ? CareAutoActivationParametersRequest.fromJSON(object.careAutoActivationParametersRequest)
        : undefined,
    };
  },

  toJSON(message: InformationRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.subscribe !== undefined && message.subscribe !== false) {
      obj.subscribe = message.subscribe;
    }
    if (message.accountsRequest !== undefined) {
      obj.accountsRequest = AccountsRequest.toJSON(message.accountsRequest);
    }
    if (message.symbolResolutionRequest !== undefined) {
      obj.symbolResolutionRequest = SymbolResolutionRequest.toJSON(message.symbolResolutionRequest);
    }
    if (message.lastStatementBalancesRequest !== undefined) {
      obj.lastStatementBalancesRequest = LastStatementBalancesRequest.toJSON(message.lastStatementBalancesRequest);
    }
    if (message.currencyRatesRequest !== undefined) {
      obj.currencyRatesRequest = CurrencyRatesRequest.toJSON(message.currencyRatesRequest);
    }
    if (message.sessionInformationRequest !== undefined) {
      obj.sessionInformationRequest = SessionInformationRequest.toJSON(message.sessionInformationRequest);
    }
    if (message.historicalOrdersRequest !== undefined) {
      obj.historicalOrdersRequest = HistoricalOrdersRequest.toJSON(message.historicalOrdersRequest);
    }
    if (message.optionMaturityListRequest !== undefined) {
      obj.optionMaturityListRequest = OptionMaturityListRequest.toJSON(message.optionMaturityListRequest);
    }
    if (message.instrumentGroupRequest !== undefined) {
      obj.instrumentGroupRequest = InstrumentGroupRequest.toJSON(message.instrumentGroupRequest);
    }
    if (message.atTheMoneyStrikeRequest !== undefined) {
      obj.atTheMoneyStrikeRequest = AtTheMoneyStrikeRequest.toJSON(message.atTheMoneyStrikeRequest);
    }
    if (message.strategyDefinitionRequest !== undefined) {
      obj.strategyDefinitionRequest = StrategyDefinitionRequest.toJSON(message.strategyDefinitionRequest);
    }
    if (message.sessionTimerangeRequest !== undefined) {
      obj.sessionTimerangeRequest = SessionTimeRangeRequest.toJSON(message.sessionTimerangeRequest);
    }
    if (message.tradingDayTimerangeRequest !== undefined) {
      obj.tradingDayTimerangeRequest = TradingDayTimeRangeRequest.toJSON(message.tradingDayTimerangeRequest);
    }
    if (message.orderEntitlementRequest !== undefined) {
      obj.orderEntitlementRequest = OrderEntitlementRequest.toJSON(message.orderEntitlementRequest);
    }
    if (message.symbolCategoryListRequest !== undefined) {
      obj.symbolCategoryListRequest = SymbolCategoryListRequest.toJSON(message.symbolCategoryListRequest);
    }
    if (message.symbolCategoryRequest !== undefined) {
      obj.symbolCategoryRequest = SymbolCategoryRequest.toJSON(message.symbolCategoryRequest);
    }
    if (message.symbolCategoryListByInstrumentTypeRequest !== undefined) {
      obj.symbolCategoryListByInstrumentTypeRequest = SymbolCategoryListByInstrumentTypeRequest.toJSON(
        message.symbolCategoryListByInstrumentTypeRequest,
      );
    }
    if (message.symbolListRequest !== undefined) {
      obj.symbolListRequest = SymbolListRequest.toJSON(message.symbolListRequest);
    }
    if (message.symbolRequest !== undefined) {
      obj.symbolRequest = SymbolRequest.toJSON(message.symbolRequest);
    }
    if (message.contractMetadataRequest !== undefined) {
      obj.contractMetadataRequest = ContractMetadataRequest.toJSON(message.contractMetadataRequest);
    }
    if (message.algoStrategyDefinitionRequest !== undefined) {
      obj.algoStrategyDefinitionRequest = AlgoStrategyDefinitionRequest.toJSON(message.algoStrategyDefinitionRequest);
    }
    if (message.contributorMetadataRequest !== undefined) {
      obj.contributorMetadataRequest = ContributorMetadataRequest.toJSON(message.contributorMetadataRequest);
    }
    if (message.apiLimitRequest !== undefined) {
      obj.apiLimitRequest = ApiLimitRequest.toJSON(message.apiLimitRequest);
    }
    if (message.brokerageTradingFeatureEntitlementRequest !== undefined) {
      obj.brokerageTradingFeatureEntitlementRequest = BrokerageTradingFeatureEntitlementRequest.toJSON(
        message.brokerageTradingFeatureEntitlementRequest,
      );
    }
    if (message.orderStatusRequest !== undefined) {
      obj.orderStatusRequest = OrderStatusRequest.toJSON(message.orderStatusRequest);
    }
    if (message.productSearchRequest !== undefined) {
      obj.productSearchRequest = ProductSearchRequest.toJSON(message.productSearchRequest);
    }
    if (message.marketStateMetadataRequest !== undefined) {
      obj.marketStateMetadataRequest = MarketStateMetadataRequest.toJSON(message.marketStateMetadataRequest);
    }
    if (message.instrumentDefinitionRequest !== undefined) {
      obj.instrumentDefinitionRequest = InstrumentDefinitionRequest.toJSON(message.instrumentDefinitionRequest);
    }
    if (message.exchangeMetadataRequest !== undefined) {
      obj.exchangeMetadataRequest = ExchangeMetadataRequest.toJSON(message.exchangeMetadataRequest);
    }
    if (message.instrumentGroupBySecuritiesRequest !== undefined) {
      obj.instrumentGroupBySecuritiesRequest = InstrumentGroupBySecuritiesRequest.toJSON(
        message.instrumentGroupBySecuritiesRequest,
      );
    }
    if (message.instrumentGroupByExchangeRequest !== undefined) {
      obj.instrumentGroupByExchangeRequest = InstrumentGroupByExchangeRequest.toJSON(
        message.instrumentGroupByExchangeRequest,
      );
    }
    if (message.exchangeSecuritiesRequest !== undefined) {
      obj.exchangeSecuritiesRequest = ExchangeSecuritiesRequest.toJSON(message.exchangeSecuritiesRequest);
    }
    if (message.careAutoActivationParametersRequest !== undefined) {
      obj.careAutoActivationParametersRequest = CareAutoActivationParametersRequest.toJSON(
        message.careAutoActivationParametersRequest,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InformationRequest>, I>>(base?: I): InformationRequest {
    return InformationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InformationRequest>, I>>(object: I): InformationRequest {
    const message = createBaseInformationRequest();
    message.id = object.id ?? 0;
    message.subscribe = object.subscribe ?? false;
    message.accountsRequest = (object.accountsRequest !== undefined && object.accountsRequest !== null)
      ? AccountsRequest.fromPartial(object.accountsRequest)
      : undefined;
    message.symbolResolutionRequest =
      (object.symbolResolutionRequest !== undefined && object.symbolResolutionRequest !== null)
        ? SymbolResolutionRequest.fromPartial(object.symbolResolutionRequest)
        : undefined;
    message.lastStatementBalancesRequest =
      (object.lastStatementBalancesRequest !== undefined && object.lastStatementBalancesRequest !== null)
        ? LastStatementBalancesRequest.fromPartial(object.lastStatementBalancesRequest)
        : undefined;
    message.currencyRatesRequest = (object.currencyRatesRequest !== undefined && object.currencyRatesRequest !== null)
      ? CurrencyRatesRequest.fromPartial(object.currencyRatesRequest)
      : undefined;
    message.sessionInformationRequest =
      (object.sessionInformationRequest !== undefined && object.sessionInformationRequest !== null)
        ? SessionInformationRequest.fromPartial(object.sessionInformationRequest)
        : undefined;
    message.historicalOrdersRequest =
      (object.historicalOrdersRequest !== undefined && object.historicalOrdersRequest !== null)
        ? HistoricalOrdersRequest.fromPartial(object.historicalOrdersRequest)
        : undefined;
    message.optionMaturityListRequest =
      (object.optionMaturityListRequest !== undefined && object.optionMaturityListRequest !== null)
        ? OptionMaturityListRequest.fromPartial(object.optionMaturityListRequest)
        : undefined;
    message.instrumentGroupRequest =
      (object.instrumentGroupRequest !== undefined && object.instrumentGroupRequest !== null)
        ? InstrumentGroupRequest.fromPartial(object.instrumentGroupRequest)
        : undefined;
    message.atTheMoneyStrikeRequest =
      (object.atTheMoneyStrikeRequest !== undefined && object.atTheMoneyStrikeRequest !== null)
        ? AtTheMoneyStrikeRequest.fromPartial(object.atTheMoneyStrikeRequest)
        : undefined;
    message.strategyDefinitionRequest =
      (object.strategyDefinitionRequest !== undefined && object.strategyDefinitionRequest !== null)
        ? StrategyDefinitionRequest.fromPartial(object.strategyDefinitionRequest)
        : undefined;
    message.sessionTimerangeRequest =
      (object.sessionTimerangeRequest !== undefined && object.sessionTimerangeRequest !== null)
        ? SessionTimeRangeRequest.fromPartial(object.sessionTimerangeRequest)
        : undefined;
    message.tradingDayTimerangeRequest =
      (object.tradingDayTimerangeRequest !== undefined && object.tradingDayTimerangeRequest !== null)
        ? TradingDayTimeRangeRequest.fromPartial(object.tradingDayTimerangeRequest)
        : undefined;
    message.orderEntitlementRequest =
      (object.orderEntitlementRequest !== undefined && object.orderEntitlementRequest !== null)
        ? OrderEntitlementRequest.fromPartial(object.orderEntitlementRequest)
        : undefined;
    message.symbolCategoryListRequest =
      (object.symbolCategoryListRequest !== undefined && object.symbolCategoryListRequest !== null)
        ? SymbolCategoryListRequest.fromPartial(object.symbolCategoryListRequest)
        : undefined;
    message.symbolCategoryRequest =
      (object.symbolCategoryRequest !== undefined && object.symbolCategoryRequest !== null)
        ? SymbolCategoryRequest.fromPartial(object.symbolCategoryRequest)
        : undefined;
    message.symbolCategoryListByInstrumentTypeRequest =
      (object.symbolCategoryListByInstrumentTypeRequest !== undefined &&
          object.symbolCategoryListByInstrumentTypeRequest !== null)
        ? SymbolCategoryListByInstrumentTypeRequest.fromPartial(object.symbolCategoryListByInstrumentTypeRequest)
        : undefined;
    message.symbolListRequest = (object.symbolListRequest !== undefined && object.symbolListRequest !== null)
      ? SymbolListRequest.fromPartial(object.symbolListRequest)
      : undefined;
    message.symbolRequest = (object.symbolRequest !== undefined && object.symbolRequest !== null)
      ? SymbolRequest.fromPartial(object.symbolRequest)
      : undefined;
    message.contractMetadataRequest =
      (object.contractMetadataRequest !== undefined && object.contractMetadataRequest !== null)
        ? ContractMetadataRequest.fromPartial(object.contractMetadataRequest)
        : undefined;
    message.algoStrategyDefinitionRequest =
      (object.algoStrategyDefinitionRequest !== undefined && object.algoStrategyDefinitionRequest !== null)
        ? AlgoStrategyDefinitionRequest.fromPartial(object.algoStrategyDefinitionRequest)
        : undefined;
    message.contributorMetadataRequest =
      (object.contributorMetadataRequest !== undefined && object.contributorMetadataRequest !== null)
        ? ContributorMetadataRequest.fromPartial(object.contributorMetadataRequest)
        : undefined;
    message.apiLimitRequest = (object.apiLimitRequest !== undefined && object.apiLimitRequest !== null)
      ? ApiLimitRequest.fromPartial(object.apiLimitRequest)
      : undefined;
    message.brokerageTradingFeatureEntitlementRequest =
      (object.brokerageTradingFeatureEntitlementRequest !== undefined &&
          object.brokerageTradingFeatureEntitlementRequest !== null)
        ? BrokerageTradingFeatureEntitlementRequest.fromPartial(object.brokerageTradingFeatureEntitlementRequest)
        : undefined;
    message.orderStatusRequest = (object.orderStatusRequest !== undefined && object.orderStatusRequest !== null)
      ? OrderStatusRequest.fromPartial(object.orderStatusRequest)
      : undefined;
    message.productSearchRequest = (object.productSearchRequest !== undefined && object.productSearchRequest !== null)
      ? ProductSearchRequest.fromPartial(object.productSearchRequest)
      : undefined;
    message.marketStateMetadataRequest =
      (object.marketStateMetadataRequest !== undefined && object.marketStateMetadataRequest !== null)
        ? MarketStateMetadataRequest.fromPartial(object.marketStateMetadataRequest)
        : undefined;
    message.instrumentDefinitionRequest =
      (object.instrumentDefinitionRequest !== undefined && object.instrumentDefinitionRequest !== null)
        ? InstrumentDefinitionRequest.fromPartial(object.instrumentDefinitionRequest)
        : undefined;
    message.exchangeMetadataRequest =
      (object.exchangeMetadataRequest !== undefined && object.exchangeMetadataRequest !== null)
        ? ExchangeMetadataRequest.fromPartial(object.exchangeMetadataRequest)
        : undefined;
    message.instrumentGroupBySecuritiesRequest =
      (object.instrumentGroupBySecuritiesRequest !== undefined && object.instrumentGroupBySecuritiesRequest !== null)
        ? InstrumentGroupBySecuritiesRequest.fromPartial(object.instrumentGroupBySecuritiesRequest)
        : undefined;
    message.instrumentGroupByExchangeRequest =
      (object.instrumentGroupByExchangeRequest !== undefined && object.instrumentGroupByExchangeRequest !== null)
        ? InstrumentGroupByExchangeRequest.fromPartial(object.instrumentGroupByExchangeRequest)
        : undefined;
    message.exchangeSecuritiesRequest =
      (object.exchangeSecuritiesRequest !== undefined && object.exchangeSecuritiesRequest !== null)
        ? ExchangeSecuritiesRequest.fromPartial(object.exchangeSecuritiesRequest)
        : undefined;
    message.careAutoActivationParametersRequest =
      (object.careAutoActivationParametersRequest !== undefined && object.careAutoActivationParametersRequest !== null)
        ? CareAutoActivationParametersRequest.fromPartial(object.careAutoActivationParametersRequest)
        : undefined;
    return message;
  },
};

function createBaseInformationReport(): InformationReport {
  return {
    id: 0,
    isReportComplete: true,
    statusCode: 0,
    textMessage: "",
    accountsReport: undefined,
    symbolResolutionReport: undefined,
    lastStatementBalancesReport: undefined,
    currencyRatesReport: undefined,
    sessionInformationReport: undefined,
    historicalOrdersReport: undefined,
    optionMaturityListReport: undefined,
    instrumentGroupReport: undefined,
    atTheMoneyStrikeReport: undefined,
    strategyDefinitionReport: undefined,
    sessionTimerangeReport: undefined,
    tradingDayTimerangeReport: undefined,
    orderEntitlementReport: undefined,
    symbolCategoryListReport: undefined,
    symbolCategoryReport: undefined,
    symbolCategoryListByInstrumentTypeReport: undefined,
    symbolListReport: undefined,
    symbolReport: undefined,
    contractMetadataReport: undefined,
    algoStrategyDefinitionReport: undefined,
    contributorMetadataReport: undefined,
    apiLimitReport: undefined,
    brokerageTradingFeatureEntitlementReport: undefined,
    orderStatusReport: undefined,
    productSearchReport: undefined,
    marketStateMetadataReport: undefined,
    instrumentDefinitionReport: undefined,
    exchangeMetadataReport: undefined,
    instrumentGroupBySecuritiesReport: undefined,
    instrumentGroupByExchangeReport: undefined,
    exchangeSecuritiesReport: undefined,
    careAutoActivationParametersReport: undefined,
  };
}

export const InformationReport = {
  encode(message: InformationReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.isReportComplete !== undefined && message.isReportComplete !== true) {
      writer.uint32(16).bool(message.isReportComplete);
    }
    if (message.statusCode !== 0) {
      writer.uint32(24).uint32(message.statusCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(34).string(message.textMessage);
    }
    if (message.accountsReport !== undefined) {
      AccountsReport.encode(message.accountsReport, writer.uint32(42).fork()).ldelim();
    }
    if (message.symbolResolutionReport !== undefined) {
      SymbolResolutionReport.encode(message.symbolResolutionReport, writer.uint32(50).fork()).ldelim();
    }
    if (message.lastStatementBalancesReport !== undefined) {
      LastStatementBalancesReport.encode(message.lastStatementBalancesReport, writer.uint32(58).fork()).ldelim();
    }
    if (message.currencyRatesReport !== undefined) {
      CurrencyRatesReport.encode(message.currencyRatesReport, writer.uint32(66).fork()).ldelim();
    }
    if (message.sessionInformationReport !== undefined) {
      SessionInformationReport.encode(message.sessionInformationReport, writer.uint32(74).fork()).ldelim();
    }
    if (message.historicalOrdersReport !== undefined) {
      HistoricalOrdersReport.encode(message.historicalOrdersReport, writer.uint32(122).fork()).ldelim();
    }
    if (message.optionMaturityListReport !== undefined) {
      OptionMaturityListReport.encode(message.optionMaturityListReport, writer.uint32(130).fork()).ldelim();
    }
    if (message.instrumentGroupReport !== undefined) {
      InstrumentGroupReport.encode(message.instrumentGroupReport, writer.uint32(138).fork()).ldelim();
    }
    if (message.atTheMoneyStrikeReport !== undefined) {
      AtTheMoneyStrikeReport.encode(message.atTheMoneyStrikeReport, writer.uint32(146).fork()).ldelim();
    }
    if (message.strategyDefinitionReport !== undefined) {
      StrategyDefinitionReport.encode(message.strategyDefinitionReport, writer.uint32(154).fork()).ldelim();
    }
    if (message.sessionTimerangeReport !== undefined) {
      SessionTimeRangeReport.encode(message.sessionTimerangeReport, writer.uint32(162).fork()).ldelim();
    }
    if (message.tradingDayTimerangeReport !== undefined) {
      TradingDayTimeRangeReport.encode(message.tradingDayTimerangeReport, writer.uint32(170).fork()).ldelim();
    }
    if (message.orderEntitlementReport !== undefined) {
      OrderEntitlementReport.encode(message.orderEntitlementReport, writer.uint32(178).fork()).ldelim();
    }
    if (message.symbolCategoryListReport !== undefined) {
      SymbolCategoryListReport.encode(message.symbolCategoryListReport, writer.uint32(186).fork()).ldelim();
    }
    if (message.symbolCategoryReport !== undefined) {
      SymbolCategoryReport.encode(message.symbolCategoryReport, writer.uint32(234).fork()).ldelim();
    }
    if (message.symbolCategoryListByInstrumentTypeReport !== undefined) {
      SymbolCategoryListByInstrumentTypeReport.encode(
        message.symbolCategoryListByInstrumentTypeReport,
        writer.uint32(282).fork(),
      ).ldelim();
    }
    if (message.symbolListReport !== undefined) {
      SymbolListReport.encode(message.symbolListReport, writer.uint32(194).fork()).ldelim();
    }
    if (message.symbolReport !== undefined) {
      SymbolReport.encode(message.symbolReport, writer.uint32(202).fork()).ldelim();
    }
    if (message.contractMetadataReport !== undefined) {
      ContractMetadataReport.encode(message.contractMetadataReport, writer.uint32(218).fork()).ldelim();
    }
    if (message.algoStrategyDefinitionReport !== undefined) {
      AlgoStrategyDefinitionReport.encode(message.algoStrategyDefinitionReport, writer.uint32(226).fork()).ldelim();
    }
    if (message.contributorMetadataReport !== undefined) {
      ContributorMetadataReport.encode(message.contributorMetadataReport, writer.uint32(242).fork()).ldelim();
    }
    if (message.apiLimitReport !== undefined) {
      ApiLimitReport.encode(message.apiLimitReport, writer.uint32(250).fork()).ldelim();
    }
    if (message.brokerageTradingFeatureEntitlementReport !== undefined) {
      BrokerageTradingFeatureEntitlementReport.encode(
        message.brokerageTradingFeatureEntitlementReport,
        writer.uint32(258).fork(),
      ).ldelim();
    }
    if (message.orderStatusReport !== undefined) {
      OrderStatusReport.encode(message.orderStatusReport, writer.uint32(266).fork()).ldelim();
    }
    if (message.productSearchReport !== undefined) {
      ProductSearchReport.encode(message.productSearchReport, writer.uint32(274).fork()).ldelim();
    }
    if (message.marketStateMetadataReport !== undefined) {
      MarketStateMetadataReport.encode(message.marketStateMetadataReport, writer.uint32(290).fork()).ldelim();
    }
    if (message.instrumentDefinitionReport !== undefined) {
      InstrumentDefinitionReport.encode(message.instrumentDefinitionReport, writer.uint32(298).fork()).ldelim();
    }
    if (message.exchangeMetadataReport !== undefined) {
      ExchangeMetadataReport.encode(message.exchangeMetadataReport, writer.uint32(306).fork()).ldelim();
    }
    if (message.instrumentGroupBySecuritiesReport !== undefined) {
      InstrumentGroupBySecuritiesReport.encode(message.instrumentGroupBySecuritiesReport, writer.uint32(314).fork())
        .ldelim();
    }
    if (message.instrumentGroupByExchangeReport !== undefined) {
      InstrumentGroupByExchangeReport.encode(message.instrumentGroupByExchangeReport, writer.uint32(322).fork())
        .ldelim();
    }
    if (message.exchangeSecuritiesReport !== undefined) {
      ExchangeSecuritiesReport.encode(message.exchangeSecuritiesReport, writer.uint32(330).fork()).ldelim();
    }
    if (message.careAutoActivationParametersReport !== undefined) {
      CareAutoActivationParametersReport.encode(message.careAutoActivationParametersReport, writer.uint32(338).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InformationReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInformationReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isReportComplete = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.statusCode = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountsReport = AccountsReport.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.symbolResolutionReport = SymbolResolutionReport.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastStatementBalancesReport = LastStatementBalancesReport.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.currencyRatesReport = CurrencyRatesReport.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.sessionInformationReport = SessionInformationReport.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.historicalOrdersReport = HistoricalOrdersReport.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.optionMaturityListReport = OptionMaturityListReport.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.instrumentGroupReport = InstrumentGroupReport.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.atTheMoneyStrikeReport = AtTheMoneyStrikeReport.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.strategyDefinitionReport = StrategyDefinitionReport.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.sessionTimerangeReport = SessionTimeRangeReport.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.tradingDayTimerangeReport = TradingDayTimeRangeReport.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.orderEntitlementReport = OrderEntitlementReport.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.symbolCategoryListReport = SymbolCategoryListReport.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.symbolCategoryReport = SymbolCategoryReport.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.symbolCategoryListByInstrumentTypeReport = SymbolCategoryListByInstrumentTypeReport.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.symbolListReport = SymbolListReport.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.symbolReport = SymbolReport.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.contractMetadataReport = ContractMetadataReport.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.algoStrategyDefinitionReport = AlgoStrategyDefinitionReport.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.contributorMetadataReport = ContributorMetadataReport.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.apiLimitReport = ApiLimitReport.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.brokerageTradingFeatureEntitlementReport = BrokerageTradingFeatureEntitlementReport.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.orderStatusReport = OrderStatusReport.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.productSearchReport = ProductSearchReport.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.marketStateMetadataReport = MarketStateMetadataReport.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.instrumentDefinitionReport = InstrumentDefinitionReport.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.exchangeMetadataReport = ExchangeMetadataReport.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.instrumentGroupBySecuritiesReport = InstrumentGroupBySecuritiesReport.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.instrumentGroupByExchangeReport = InstrumentGroupByExchangeReport.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.exchangeSecuritiesReport = ExchangeSecuritiesReport.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.careAutoActivationParametersReport = CareAutoActivationParametersReport.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InformationReport {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      isReportComplete: isSet(object.isReportComplete) ? globalThis.Boolean(object.isReportComplete) : true,
      statusCode: isSet(object.statusCode) ? globalThis.Number(object.statusCode) : 0,
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      accountsReport: isSet(object.accountsReport) ? AccountsReport.fromJSON(object.accountsReport) : undefined,
      symbolResolutionReport: isSet(object.symbolResolutionReport)
        ? SymbolResolutionReport.fromJSON(object.symbolResolutionReport)
        : undefined,
      lastStatementBalancesReport: isSet(object.lastStatementBalancesReport)
        ? LastStatementBalancesReport.fromJSON(object.lastStatementBalancesReport)
        : undefined,
      currencyRatesReport: isSet(object.currencyRatesReport)
        ? CurrencyRatesReport.fromJSON(object.currencyRatesReport)
        : undefined,
      sessionInformationReport: isSet(object.sessionInformationReport)
        ? SessionInformationReport.fromJSON(object.sessionInformationReport)
        : undefined,
      historicalOrdersReport: isSet(object.historicalOrdersReport)
        ? HistoricalOrdersReport.fromJSON(object.historicalOrdersReport)
        : undefined,
      optionMaturityListReport: isSet(object.optionMaturityListReport)
        ? OptionMaturityListReport.fromJSON(object.optionMaturityListReport)
        : undefined,
      instrumentGroupReport: isSet(object.instrumentGroupReport)
        ? InstrumentGroupReport.fromJSON(object.instrumentGroupReport)
        : undefined,
      atTheMoneyStrikeReport: isSet(object.atTheMoneyStrikeReport)
        ? AtTheMoneyStrikeReport.fromJSON(object.atTheMoneyStrikeReport)
        : undefined,
      strategyDefinitionReport: isSet(object.strategyDefinitionReport)
        ? StrategyDefinitionReport.fromJSON(object.strategyDefinitionReport)
        : undefined,
      sessionTimerangeReport: isSet(object.sessionTimerangeReport)
        ? SessionTimeRangeReport.fromJSON(object.sessionTimerangeReport)
        : undefined,
      tradingDayTimerangeReport: isSet(object.tradingDayTimerangeReport)
        ? TradingDayTimeRangeReport.fromJSON(object.tradingDayTimerangeReport)
        : undefined,
      orderEntitlementReport: isSet(object.orderEntitlementReport)
        ? OrderEntitlementReport.fromJSON(object.orderEntitlementReport)
        : undefined,
      symbolCategoryListReport: isSet(object.symbolCategoryListReport)
        ? SymbolCategoryListReport.fromJSON(object.symbolCategoryListReport)
        : undefined,
      symbolCategoryReport: isSet(object.symbolCategoryReport)
        ? SymbolCategoryReport.fromJSON(object.symbolCategoryReport)
        : undefined,
      symbolCategoryListByInstrumentTypeReport: isSet(object.symbolCategoryListByInstrumentTypeReport)
        ? SymbolCategoryListByInstrumentTypeReport.fromJSON(object.symbolCategoryListByInstrumentTypeReport)
        : undefined,
      symbolListReport: isSet(object.symbolListReport) ? SymbolListReport.fromJSON(object.symbolListReport) : undefined,
      symbolReport: isSet(object.symbolReport) ? SymbolReport.fromJSON(object.symbolReport) : undefined,
      contractMetadataReport: isSet(object.contractMetadataReport)
        ? ContractMetadataReport.fromJSON(object.contractMetadataReport)
        : undefined,
      algoStrategyDefinitionReport: isSet(object.algoStrategyDefinitionReport)
        ? AlgoStrategyDefinitionReport.fromJSON(object.algoStrategyDefinitionReport)
        : undefined,
      contributorMetadataReport: isSet(object.contributorMetadataReport)
        ? ContributorMetadataReport.fromJSON(object.contributorMetadataReport)
        : undefined,
      apiLimitReport: isSet(object.apiLimitReport) ? ApiLimitReport.fromJSON(object.apiLimitReport) : undefined,
      brokerageTradingFeatureEntitlementReport: isSet(object.brokerageTradingFeatureEntitlementReport)
        ? BrokerageTradingFeatureEntitlementReport.fromJSON(object.brokerageTradingFeatureEntitlementReport)
        : undefined,
      orderStatusReport: isSet(object.orderStatusReport)
        ? OrderStatusReport.fromJSON(object.orderStatusReport)
        : undefined,
      productSearchReport: isSet(object.productSearchReport)
        ? ProductSearchReport.fromJSON(object.productSearchReport)
        : undefined,
      marketStateMetadataReport: isSet(object.marketStateMetadataReport)
        ? MarketStateMetadataReport.fromJSON(object.marketStateMetadataReport)
        : undefined,
      instrumentDefinitionReport: isSet(object.instrumentDefinitionReport)
        ? InstrumentDefinitionReport.fromJSON(object.instrumentDefinitionReport)
        : undefined,
      exchangeMetadataReport: isSet(object.exchangeMetadataReport)
        ? ExchangeMetadataReport.fromJSON(object.exchangeMetadataReport)
        : undefined,
      instrumentGroupBySecuritiesReport: isSet(object.instrumentGroupBySecuritiesReport)
        ? InstrumentGroupBySecuritiesReport.fromJSON(object.instrumentGroupBySecuritiesReport)
        : undefined,
      instrumentGroupByExchangeReport: isSet(object.instrumentGroupByExchangeReport)
        ? InstrumentGroupByExchangeReport.fromJSON(object.instrumentGroupByExchangeReport)
        : undefined,
      exchangeSecuritiesReport: isSet(object.exchangeSecuritiesReport)
        ? ExchangeSecuritiesReport.fromJSON(object.exchangeSecuritiesReport)
        : undefined,
      careAutoActivationParametersReport: isSet(object.careAutoActivationParametersReport)
        ? CareAutoActivationParametersReport.fromJSON(object.careAutoActivationParametersReport)
        : undefined,
    };
  },

  toJSON(message: InformationReport): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.isReportComplete !== undefined && message.isReportComplete !== true) {
      obj.isReportComplete = message.isReportComplete;
    }
    if (message.statusCode !== 0) {
      obj.statusCode = Math.round(message.statusCode);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.accountsReport !== undefined) {
      obj.accountsReport = AccountsReport.toJSON(message.accountsReport);
    }
    if (message.symbolResolutionReport !== undefined) {
      obj.symbolResolutionReport = SymbolResolutionReport.toJSON(message.symbolResolutionReport);
    }
    if (message.lastStatementBalancesReport !== undefined) {
      obj.lastStatementBalancesReport = LastStatementBalancesReport.toJSON(message.lastStatementBalancesReport);
    }
    if (message.currencyRatesReport !== undefined) {
      obj.currencyRatesReport = CurrencyRatesReport.toJSON(message.currencyRatesReport);
    }
    if (message.sessionInformationReport !== undefined) {
      obj.sessionInformationReport = SessionInformationReport.toJSON(message.sessionInformationReport);
    }
    if (message.historicalOrdersReport !== undefined) {
      obj.historicalOrdersReport = HistoricalOrdersReport.toJSON(message.historicalOrdersReport);
    }
    if (message.optionMaturityListReport !== undefined) {
      obj.optionMaturityListReport = OptionMaturityListReport.toJSON(message.optionMaturityListReport);
    }
    if (message.instrumentGroupReport !== undefined) {
      obj.instrumentGroupReport = InstrumentGroupReport.toJSON(message.instrumentGroupReport);
    }
    if (message.atTheMoneyStrikeReport !== undefined) {
      obj.atTheMoneyStrikeReport = AtTheMoneyStrikeReport.toJSON(message.atTheMoneyStrikeReport);
    }
    if (message.strategyDefinitionReport !== undefined) {
      obj.strategyDefinitionReport = StrategyDefinitionReport.toJSON(message.strategyDefinitionReport);
    }
    if (message.sessionTimerangeReport !== undefined) {
      obj.sessionTimerangeReport = SessionTimeRangeReport.toJSON(message.sessionTimerangeReport);
    }
    if (message.tradingDayTimerangeReport !== undefined) {
      obj.tradingDayTimerangeReport = TradingDayTimeRangeReport.toJSON(message.tradingDayTimerangeReport);
    }
    if (message.orderEntitlementReport !== undefined) {
      obj.orderEntitlementReport = OrderEntitlementReport.toJSON(message.orderEntitlementReport);
    }
    if (message.symbolCategoryListReport !== undefined) {
      obj.symbolCategoryListReport = SymbolCategoryListReport.toJSON(message.symbolCategoryListReport);
    }
    if (message.symbolCategoryReport !== undefined) {
      obj.symbolCategoryReport = SymbolCategoryReport.toJSON(message.symbolCategoryReport);
    }
    if (message.symbolCategoryListByInstrumentTypeReport !== undefined) {
      obj.symbolCategoryListByInstrumentTypeReport = SymbolCategoryListByInstrumentTypeReport.toJSON(
        message.symbolCategoryListByInstrumentTypeReport,
      );
    }
    if (message.symbolListReport !== undefined) {
      obj.symbolListReport = SymbolListReport.toJSON(message.symbolListReport);
    }
    if (message.symbolReport !== undefined) {
      obj.symbolReport = SymbolReport.toJSON(message.symbolReport);
    }
    if (message.contractMetadataReport !== undefined) {
      obj.contractMetadataReport = ContractMetadataReport.toJSON(message.contractMetadataReport);
    }
    if (message.algoStrategyDefinitionReport !== undefined) {
      obj.algoStrategyDefinitionReport = AlgoStrategyDefinitionReport.toJSON(message.algoStrategyDefinitionReport);
    }
    if (message.contributorMetadataReport !== undefined) {
      obj.contributorMetadataReport = ContributorMetadataReport.toJSON(message.contributorMetadataReport);
    }
    if (message.apiLimitReport !== undefined) {
      obj.apiLimitReport = ApiLimitReport.toJSON(message.apiLimitReport);
    }
    if (message.brokerageTradingFeatureEntitlementReport !== undefined) {
      obj.brokerageTradingFeatureEntitlementReport = BrokerageTradingFeatureEntitlementReport.toJSON(
        message.brokerageTradingFeatureEntitlementReport,
      );
    }
    if (message.orderStatusReport !== undefined) {
      obj.orderStatusReport = OrderStatusReport.toJSON(message.orderStatusReport);
    }
    if (message.productSearchReport !== undefined) {
      obj.productSearchReport = ProductSearchReport.toJSON(message.productSearchReport);
    }
    if (message.marketStateMetadataReport !== undefined) {
      obj.marketStateMetadataReport = MarketStateMetadataReport.toJSON(message.marketStateMetadataReport);
    }
    if (message.instrumentDefinitionReport !== undefined) {
      obj.instrumentDefinitionReport = InstrumentDefinitionReport.toJSON(message.instrumentDefinitionReport);
    }
    if (message.exchangeMetadataReport !== undefined) {
      obj.exchangeMetadataReport = ExchangeMetadataReport.toJSON(message.exchangeMetadataReport);
    }
    if (message.instrumentGroupBySecuritiesReport !== undefined) {
      obj.instrumentGroupBySecuritiesReport = InstrumentGroupBySecuritiesReport.toJSON(
        message.instrumentGroupBySecuritiesReport,
      );
    }
    if (message.instrumentGroupByExchangeReport !== undefined) {
      obj.instrumentGroupByExchangeReport = InstrumentGroupByExchangeReport.toJSON(
        message.instrumentGroupByExchangeReport,
      );
    }
    if (message.exchangeSecuritiesReport !== undefined) {
      obj.exchangeSecuritiesReport = ExchangeSecuritiesReport.toJSON(message.exchangeSecuritiesReport);
    }
    if (message.careAutoActivationParametersReport !== undefined) {
      obj.careAutoActivationParametersReport = CareAutoActivationParametersReport.toJSON(
        message.careAutoActivationParametersReport,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InformationReport>, I>>(base?: I): InformationReport {
    return InformationReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InformationReport>, I>>(object: I): InformationReport {
    const message = createBaseInformationReport();
    message.id = object.id ?? 0;
    message.isReportComplete = object.isReportComplete ?? true;
    message.statusCode = object.statusCode ?? 0;
    message.textMessage = object.textMessage ?? "";
    message.accountsReport = (object.accountsReport !== undefined && object.accountsReport !== null)
      ? AccountsReport.fromPartial(object.accountsReport)
      : undefined;
    message.symbolResolutionReport =
      (object.symbolResolutionReport !== undefined && object.symbolResolutionReport !== null)
        ? SymbolResolutionReport.fromPartial(object.symbolResolutionReport)
        : undefined;
    message.lastStatementBalancesReport =
      (object.lastStatementBalancesReport !== undefined && object.lastStatementBalancesReport !== null)
        ? LastStatementBalancesReport.fromPartial(object.lastStatementBalancesReport)
        : undefined;
    message.currencyRatesReport = (object.currencyRatesReport !== undefined && object.currencyRatesReport !== null)
      ? CurrencyRatesReport.fromPartial(object.currencyRatesReport)
      : undefined;
    message.sessionInformationReport =
      (object.sessionInformationReport !== undefined && object.sessionInformationReport !== null)
        ? SessionInformationReport.fromPartial(object.sessionInformationReport)
        : undefined;
    message.historicalOrdersReport =
      (object.historicalOrdersReport !== undefined && object.historicalOrdersReport !== null)
        ? HistoricalOrdersReport.fromPartial(object.historicalOrdersReport)
        : undefined;
    message.optionMaturityListReport =
      (object.optionMaturityListReport !== undefined && object.optionMaturityListReport !== null)
        ? OptionMaturityListReport.fromPartial(object.optionMaturityListReport)
        : undefined;
    message.instrumentGroupReport =
      (object.instrumentGroupReport !== undefined && object.instrumentGroupReport !== null)
        ? InstrumentGroupReport.fromPartial(object.instrumentGroupReport)
        : undefined;
    message.atTheMoneyStrikeReport =
      (object.atTheMoneyStrikeReport !== undefined && object.atTheMoneyStrikeReport !== null)
        ? AtTheMoneyStrikeReport.fromPartial(object.atTheMoneyStrikeReport)
        : undefined;
    message.strategyDefinitionReport =
      (object.strategyDefinitionReport !== undefined && object.strategyDefinitionReport !== null)
        ? StrategyDefinitionReport.fromPartial(object.strategyDefinitionReport)
        : undefined;
    message.sessionTimerangeReport =
      (object.sessionTimerangeReport !== undefined && object.sessionTimerangeReport !== null)
        ? SessionTimeRangeReport.fromPartial(object.sessionTimerangeReport)
        : undefined;
    message.tradingDayTimerangeReport =
      (object.tradingDayTimerangeReport !== undefined && object.tradingDayTimerangeReport !== null)
        ? TradingDayTimeRangeReport.fromPartial(object.tradingDayTimerangeReport)
        : undefined;
    message.orderEntitlementReport =
      (object.orderEntitlementReport !== undefined && object.orderEntitlementReport !== null)
        ? OrderEntitlementReport.fromPartial(object.orderEntitlementReport)
        : undefined;
    message.symbolCategoryListReport =
      (object.symbolCategoryListReport !== undefined && object.symbolCategoryListReport !== null)
        ? SymbolCategoryListReport.fromPartial(object.symbolCategoryListReport)
        : undefined;
    message.symbolCategoryReport = (object.symbolCategoryReport !== undefined && object.symbolCategoryReport !== null)
      ? SymbolCategoryReport.fromPartial(object.symbolCategoryReport)
      : undefined;
    message.symbolCategoryListByInstrumentTypeReport =
      (object.symbolCategoryListByInstrumentTypeReport !== undefined &&
          object.symbolCategoryListByInstrumentTypeReport !== null)
        ? SymbolCategoryListByInstrumentTypeReport.fromPartial(object.symbolCategoryListByInstrumentTypeReport)
        : undefined;
    message.symbolListReport = (object.symbolListReport !== undefined && object.symbolListReport !== null)
      ? SymbolListReport.fromPartial(object.symbolListReport)
      : undefined;
    message.symbolReport = (object.symbolReport !== undefined && object.symbolReport !== null)
      ? SymbolReport.fromPartial(object.symbolReport)
      : undefined;
    message.contractMetadataReport =
      (object.contractMetadataReport !== undefined && object.contractMetadataReport !== null)
        ? ContractMetadataReport.fromPartial(object.contractMetadataReport)
        : undefined;
    message.algoStrategyDefinitionReport =
      (object.algoStrategyDefinitionReport !== undefined && object.algoStrategyDefinitionReport !== null)
        ? AlgoStrategyDefinitionReport.fromPartial(object.algoStrategyDefinitionReport)
        : undefined;
    message.contributorMetadataReport =
      (object.contributorMetadataReport !== undefined && object.contributorMetadataReport !== null)
        ? ContributorMetadataReport.fromPartial(object.contributorMetadataReport)
        : undefined;
    message.apiLimitReport = (object.apiLimitReport !== undefined && object.apiLimitReport !== null)
      ? ApiLimitReport.fromPartial(object.apiLimitReport)
      : undefined;
    message.brokerageTradingFeatureEntitlementReport =
      (object.brokerageTradingFeatureEntitlementReport !== undefined &&
          object.brokerageTradingFeatureEntitlementReport !== null)
        ? BrokerageTradingFeatureEntitlementReport.fromPartial(object.brokerageTradingFeatureEntitlementReport)
        : undefined;
    message.orderStatusReport = (object.orderStatusReport !== undefined && object.orderStatusReport !== null)
      ? OrderStatusReport.fromPartial(object.orderStatusReport)
      : undefined;
    message.productSearchReport = (object.productSearchReport !== undefined && object.productSearchReport !== null)
      ? ProductSearchReport.fromPartial(object.productSearchReport)
      : undefined;
    message.marketStateMetadataReport =
      (object.marketStateMetadataReport !== undefined && object.marketStateMetadataReport !== null)
        ? MarketStateMetadataReport.fromPartial(object.marketStateMetadataReport)
        : undefined;
    message.instrumentDefinitionReport =
      (object.instrumentDefinitionReport !== undefined && object.instrumentDefinitionReport !== null)
        ? InstrumentDefinitionReport.fromPartial(object.instrumentDefinitionReport)
        : undefined;
    message.exchangeMetadataReport =
      (object.exchangeMetadataReport !== undefined && object.exchangeMetadataReport !== null)
        ? ExchangeMetadataReport.fromPartial(object.exchangeMetadataReport)
        : undefined;
    message.instrumentGroupBySecuritiesReport =
      (object.instrumentGroupBySecuritiesReport !== undefined && object.instrumentGroupBySecuritiesReport !== null)
        ? InstrumentGroupBySecuritiesReport.fromPartial(object.instrumentGroupBySecuritiesReport)
        : undefined;
    message.instrumentGroupByExchangeReport =
      (object.instrumentGroupByExchangeReport !== undefined && object.instrumentGroupByExchangeReport !== null)
        ? InstrumentGroupByExchangeReport.fromPartial(object.instrumentGroupByExchangeReport)
        : undefined;
    message.exchangeSecuritiesReport =
      (object.exchangeSecuritiesReport !== undefined && object.exchangeSecuritiesReport !== null)
        ? ExchangeSecuritiesReport.fromPartial(object.exchangeSecuritiesReport)
        : undefined;
    message.careAutoActivationParametersReport =
      (object.careAutoActivationParametersReport !== undefined && object.careAutoActivationParametersReport !== null)
        ? CareAutoActivationParametersReport.fromPartial(object.careAutoActivationParametersReport)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { useCallback, useEffect, useState } from "react";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import watchlistActions from "../../../redux/watchlist/watchlistActions";
import watchlistSelectors from "../../../redux/watchlist/watchlistSelector";

const useManageWatchlistFavorites = (workspaceId?: number) => {
  const dispatch = useDispatch();
  const [watchListProducts, setWatchListProducts] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const watchlists = useSelector(watchlistSelectors.getWatchlist);

  useEffect(() => {
    const allProducts = watchlists.map((list: { associatedProducts: string }) => list.associatedProducts?.split(","));
    setWatchListProducts(allProducts.flat().map((i: any) => i?.trim()));
  }, [watchlists]);

  useEffect(() => {
    setIsLoading(false);
  }, [watchlists]);

  // Function to add a product to the watchlist
  const addProduct = useCallback(
    async (id: number) => {
      const productId = id.toString()?.trim();
      setIsLoading(true);

      try {
        await dispatch(watchlistActions.dispatchAddProduct(id));
        setWatchListProducts((prev) =>
          produce(prev, (draft) => {
            draft.push(productId);
          }),
        );
      } catch (error) {
        console.error("Error adding product to watchlist:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  // Function to remove a product from the watchlist
  const removeProduct = useCallback(
    async (id: number) => {
      const productId = id.toString()?.trim();
      setIsLoading(true);

      try {
        await dispatch(watchlistActions.dispatchRemoveProduct(id));
        setWatchListProducts((prev) => prev.filter((item) => item !== productId));
      } catch (error) {
        console.error("Error removing product from watchlist:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  // Event handler to toggle product in the watchlist
  const handleFavoriteToggle = useCallback(
    (id: number) => async () => {
      const productId = id.toString()?.trim();
      const isProductInWatchlist = watchListProducts.includes(productId);

      if (isProductInWatchlist) {
        await removeProduct(id);
      } else {
        await addProduct(id);
      }
    },
    [addProduct, removeProduct, watchListProducts],
  );

  return {
    watchListProducts,
    isLoading,
    handleFavoriteToggle,
  };
};

export default useManageWatchlistFavorites;

export const getWatchlist = (state) => state.watchlist.watchlist;
export const getRemovedProduct = (state) => state.watchlist.removeProduct;
export const getAddProduct = (state) => state.watchlist.addProduct;
const watchlistSelectors = {
  getWatchlist,
  getRemovedProduct,
  getAddProduct,
};

export default watchlistSelectors;

import { Fcm } from "./Fcm";
import * as TradingAccount from "../proto/trading_account_2";
import { OrderState } from "./OrderState";
import { TimeUtil } from "../utils/TimeUtil";
import { dateNullable } from "../types/Types";
import { AccountSummary } from "./AccountSummary";
import { PurchaseAndSalesGroup } from "./PurchaseAndSalesGroup";
import { Position } from "./Position";

export class Account {
  id: number;
  name: string;
  brokerageAccountId: string;
  lastStatementDate: dateNullable = null;

  orders: OrderState[] = [];
  accountSummaryTotal: AccountSummary | null = null;
  positionDataRequested = false;
  orderDataRequested = false;
  collateralDataRequested = false;

  positions: Position[] = [];
  purchaseAndSalesGroups: PurchaseAndSalesGroup[] = [];
  accountSummaries: AccountSummary[] = [];

  fcm: Fcm;

  constructor(fcm: Fcm, accountData: TradingAccount.Account) {
    this.fcm = fcm;
    this.id = accountData.accountId;
    this.name = accountData.name;
    this.brokerageAccountId = accountData.brokerageAccountId;
    this.lastStatementDate = TimeUtil.toOverchukUtcBasedDate(accountData.lastStatementDate);
  }

  getFcm = () => {
    return this.fcm;
  };

  getOrders = () => {
    return this.orders;
  };

  addOrders = (newOrders: OrderState[]) => {
    this.orders = this.orders.concat(newOrders);
  };

  removeOrder = (order: OrderState) => {
    var index = this.orders.indexOf(order);
    if (index >= 0) {
      this.orders.splice(index, 1);
    }
  };

  clearOrders = () => {
    this.orders.splice(0);
  };

  getPositions = () => {
    return this.positions;
  };

  addPositions = (newPositions: Position[]) => {
    this.positions = this.positions.concat(newPositions);
  };

  removePosition = (position: any) => {
    var index = this.positions.indexOf(position);
    if (index >= 0) {
      this.positions.splice(index, 1);
    }
  };

  clearPositions = () => {
    this.positions.splice(0);
  };

  getPurchaseAndSalesGroups = () => {
    return this.purchaseAndSalesGroups;
  };

  addPurchaseAndSalesGroups = (newGroups: PurchaseAndSalesGroup[]) => {
    this.purchaseAndSalesGroups = this.purchaseAndSalesGroups.concat(newGroups);
  };
  clearPurchaseAndSalesGroups = () => {
    this.purchaseAndSalesGroups.splice(0);
  };

  getAccountSummaries = () => {
    return this.accountSummaries;
  };
}

// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: user_attribute_2.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "user_attribute_2";

/** User defined attribute. */
export interface UserAttribute {
  /** Attribute name. */
  name: string;
  /** Attribute value (optional for modification if attribute is deleted). */
  value?:
    | string
    | undefined;
  /** True if attribute has to be deleted during modify operation. */
  deleted?: boolean | undefined;
}

/** Modification of order's user attributes. */
export interface ModifyUserAttributes {
  /** ID of the order chain. */
  chainOrderId: string;
  /** ID of the order's account. */
  accountId: number;
  /**
   * List of attributes to modify.
   * See Order.user_attribute for additional description.
   */
  userAttributes: UserAttribute[];
  /**
   * Flag used by the client for marking the user attributes checked/unchecked.
   * Empty means to leave the flag the same as it was.
   */
  isChecked?: boolean | undefined;
}

function createBaseUserAttribute(): UserAttribute {
  return { name: "", value: "", deleted: false };
}

export const UserAttribute = {
  encode(message: UserAttribute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAttribute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserAttribute {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
    };
  },

  toJSON(message: UserAttribute): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    if (message.deleted !== undefined && message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAttribute>, I>>(base?: I): UserAttribute {
    return UserAttribute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserAttribute>, I>>(object: I): UserAttribute {
    const message = createBaseUserAttribute();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseModifyUserAttributes(): ModifyUserAttributes {
  return { chainOrderId: "", accountId: 0, userAttributes: [], isChecked: false };
}

export const ModifyUserAttributes = {
  encode(message: ModifyUserAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    for (const v of message.userAttributes) {
      UserAttribute.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.isChecked !== undefined && message.isChecked !== false) {
      writer.uint32(32).bool(message.isChecked);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyUserAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyUserAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userAttributes.push(UserAttribute.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isChecked = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModifyUserAttributes {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      userAttributes: globalThis.Array.isArray(object?.userAttributes)
        ? object.userAttributes.map((e: any) => UserAttribute.fromJSON(e))
        : [],
      isChecked: isSet(object.isChecked) ? globalThis.Boolean(object.isChecked) : false,
    };
  },

  toJSON(message: ModifyUserAttributes): unknown {
    const obj: any = {};
    if (message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.userAttributes?.length) {
      obj.userAttributes = message.userAttributes.map((e) => UserAttribute.toJSON(e));
    }
    if (message.isChecked !== undefined && message.isChecked !== false) {
      obj.isChecked = message.isChecked;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyUserAttributes>, I>>(base?: I): ModifyUserAttributes {
    return ModifyUserAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModifyUserAttributes>, I>>(object: I): ModifyUserAttributes {
    const message = createBaseModifyUserAttributes();
    message.chainOrderId = object.chainOrderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.userAttributes = object.userAttributes?.map((e) => UserAttribute.fromPartial(e)) || [];
    message.isChecked = object.isChecked ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
